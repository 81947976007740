import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context for the form state
const FormContext821D = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContext821D);
}

// Define the initial state
const initialState = {
    isDetained: null,
    requestType: null,
    hasResided: null,
    departedUSA: null,
    convictions: null,
    servedInArmedForces: null,
    expirationDate: '',
    arrestedUS: null,
    arrestedOtherCountry: null,
    engagedInTerroristActivities: null,
    memberOfAGang: null,
    actsInvolvingTortureGenocide: null,
    killedAnyPerson: null,
    severelyInjuredAnyPerson: null,
    sexualContactWithForcedOrThreatenedPerson: null,
    usedUnderAge15InHostilities: null,
    removalProceedings: null,
    removalProceedingsStatus: 'Currently in proceedings (active)',
    attorneyAcknowledgement: false,
    mostRecentDateOfProceedings: '', // Date input state
    locationOfProceedings: "",
    givenName: '', // Text input for given name
    middleName: '', // Text input for middle name
    familyName: '', // Text input for family name
    usedOtherNames: null,
    dateOfBirth: '', // Text input for date of birth
    townOrCity: '', // Text input for town or city
    stateOrProvince: '', // Text input for state or province
    sex: null,
    ethnicity: null,
    race: {
      white: false,
      asian: false,
      blackAfricanAmerican: false,
      americanIndianAlaskaNative: false,
      hawaiianPacificIslander: false,
    },
    feet: null,
  inches: null,
  eyeColor: '', // Add Eye Color state property
  hairColor: '', 
  maritalStatus: '', 
  selectedCountry: '',
  selectedCountryOfResidence:"",
  initialEntryDate: '',
  statusOnJune152012: 'No Status',
  issuedI94Record: 'No', 
  lastEntryDate: '', 
  lastEntryLocation: '', 
  lastEntryImmigrationStatus:"",
  lastEntryImmigrationStatusforother:"",
  alienRegistrationNumber: '', // Initialize with an empty string
  uscisOnlineAccountNumber: '', 
  leftUSPeriodSinceLastI821D: null,
  LeftWithoutAdvanceParole :"",
  hadPassport:"",
  countryoFPassport:'',
  passportNumber: '',
  passportExpirationDate: '', 
  borderCrossingCard: '', // Initial state for Border Crossing Card (Yes/No)
  borderCrossingCardNumber: '', 
  
  };

// Create a provider component to manage the state
export function FormProviderFor861D({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text': // Include input type "text"
        case 'number': // Include input type "number"
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one': // Handle select option
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      };
    });
  };

  return (
    <FormContext821D.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange }}>
      {children}
    </FormContext821D.Provider>
  );
}
