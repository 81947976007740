import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const RadioComponent = ({ label }) => {
  return (
    <div className='mt-10'>
      <label className='flex bg-blue-600 text-white p-4 rounded-md'>
        {label}
      </label>
      <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
        <label>
          <input
            type='radio'
            name='i94RecordReceived'
            value='Yes'
            onChange={() => {}}
          />
          Yes
        </label>
        <label>
          <input
            type='radio'
            name='i94RecordReceived'
            value='No'
            onChange={() => {}}
          />
          No
        </label>
      </div>
    </div>
  )
}

const FinalDetails = () => {
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  console.log(formData.whoPreparedThisApplication)

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/CreateMyFiles")
  }
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Final Details
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 13 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin mb-2 ml-2'>
            You're almost done! Just a few more details.
          </span>
        </div>
        <form action=' ' className='mt-8 w-11/12'>
          <h1 className='flex text-blue-600 font-semibold text-xl'>
            Application for Permanent Residence
          </h1>
          <span className='mt-2 font-thin mb-2'>
            At which USCIS office will you apply for Adjustment of Status to
            permanent resident? Select the local office where you want to
            interview. Find the closest USCIS office.
          </span>

          <div className='flex items-center ml-4 mb-4 mt-4'>
            <label className='text-blue-500 w-[300px]'>City or Town</label>
            <span className='flex text-blue-600'>
              <input
                id='permanentCityOrTown'
                name='permanentCityOrTown'
                type='text'
                value={formData.permanentCityOrTown}
                onChange={handleInputChange}
                className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
              />
            </span>
          </div>
          <div className='flex items-center ml-4'>
            <label className='text-blue-500 w-[300px]'>Country</label>
            <span className='flex text-blue-600'>
              <select
                id='permanenetCountry'
                name='permanenetCountry'
                value={formData.permanenetCountry ?? ""}
                onChange={handleInputChange}
                className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
              >
                <option value=''>Please select...</option>
                {code.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </span>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              On the day this application is filed, the Applicant will be:
            </label>
            <div className='flex flex-col bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='applicantsAge'
                  value='age 13 or younger'
                  onChange={handleInputChange}
                  checked={formData.applicantsAge === ""}
                />
                age 13 or younger
              </label>
              <label>
                <input
                  type='radio'
                  name='applicantsAge'
                  value='age 14 to 78'
                  onChange={handleInputChange}
                  checked={formData.applicantsAge === ""}
                />
                age 14 to 78
              </label>
              <label>
                <input
                  type='radio'
                  name='applicantsAge'
                  value='age 79 or older'
                  onChange={handleInputChange}
                  checked={formData.applicantsAge === ""}
                />
                age 79 or older
              </label>
            </div>
          </div>

          {formData.applicantsAge === "age 13 or younger" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Will the child Applicant file Form I-485 at the same time as
                  at least one parent?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='applicantSameAsAtleastOneParent'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={
                        formData.applicantSameAsAtleastOneParent === "Yes"
                      }
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='applicantSameAsAtleastOneParent'
                      value='No'
                      onChange={handleInputChange}
                      checked={
                        formData.applicantSameAsAtleastOneParent === "No"
                      }
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Accommodations for Individuals with Disabilities and Impairments
          </h1>

          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Are you requesting an accommodation because of your disabilities
              and/or impairments?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='accomodationBecauseOfDisabilities'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.accomodationBecauseOfDisabilities === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='accomodationBecauseOfDisabilities'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.accomodationBecauseOfDisabilities === "No"}
                />
                No
              </label>
            </div>
          </div>

          {formData.accomodationBecauseOfDisabilities === "Yes" ? (
            <>
              <h1 className='text-blue-600  text-xl font-semibold mt-4'>
                Check any applicable box and provide information on the
                disability(ies) and/or impairments:
              </h1>
              <div className='ml-4'>
                <input
                  name='isDeaf'
                  type='checkbox'
                  value={formData.isDeaf ?? ""}
                  onChange={handleInputChange}
                />
                <label htmlFor='isDeaf' className='text-blue-500 ml-4'>
                  I am deaf or hard of hearing.
                </label>
              </div>

              {formData.isDeaf ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Describe the accommodation(s) that you are requesting. If
                      requesting a sign-language interpreter, indicate which
                      language (e.g. American Sign Language).
                    </label>
                    <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                      <textarea name='' id='' cols='90' rows='3'></textarea>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className='ml-4'>
                <input
                  name='isBlind'
                  type='checkbox'
                  value={formData.isBlind ?? ""}
                  onChange={handleInputChange}
                />
                <label htmlFor='' className='text-blue-500 ml-4'>
                  I am blind or have low vision.
                </label>
              </div>

              {formData.isBlind ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Describe the accommodation(s) that you are requesting:
                    </label>
                    <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                      <textarea name='' id='' cols='90' rows='3'></textarea>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className='ml-4'>
                <input
                  name='otherDisability'
                  type='checkbox'
                  value={formData.otherDisability ?? ""}
                  onChange={handleInputChange}
                />
                <label htmlFor='otherDisability' className='text-blue-500 ml-4'>
                  I have another type of disability(ies) and/or impairment(s).
                </label>
              </div>

              {formData.otherDisability ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Describe the nature of the disability and/or impairment
                      and accommodation you are requesting.
                    </label>
                    <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                      <textarea name='' id='' cols='90' rows='3'></textarea>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {/* Interpreter */}
          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold mt-4'>
            Interpreter
          </h1>

          <span className='mt-2 font-thin'>
            If the Applicant received help from anyone to translate questions
            and/or instructions, indicate here. Even if the Petitioner is the
            person who translated, please indicate "Yes.
          </span>

          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Did you receive help from an interpreter to prepare this
              application?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='recieveAnyHelpFormInterpreter'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.recieveAnyHelpFormInterpreter === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='recieveAnyHelpFormInterpreter'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.recieveAnyHelpFormInterpreter === "Yes"}
                />
                No
              </label>
            </div>
          </div>
          <RadioComponent label='Did you receive help from an interpreter to prepare this application?' />

          <div className='ml-4'>
            <input
              name='iamtheApplicant'
              type='checkbox'
              value={formData.iamtheApplicant ?? ""}
              onChange={handleInputChange}
            />
            <label htmlFor='iamtheApplicant' className='text-blue-500 ml-4'>
              I am the applicant, and I can read and understand English, and
              have read and understand every question and instruction on this
              application and my answer to every question.
            </label>
          </div>

          {/* Preparer */}

          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold mt-4'>
            Preparer
          </h1>

          <span className='mt-2 font-thin'>
            If the Applicant received help from another person to prepare this
            application, indicate here. Even if the I-130 Petitioner is the
            person who prepared this application, it's appropriate to add the
            preparer's information.
          </span>

          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Who prepared this application?
            </label>
            <div className='flex flex-col bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='whoPreparedThisApplication'
                  value='applicant'
                  onChange={handleInputChange}
                />
                I am the applicant, and I prepared this application.
              </label>
              <label>
                <input
                  type='radio'
                  name='whoPreparedThisApplication'
                  value="preparer"
                  onChange={handleInputChange}
                />
                I am not an attorney or accredited representative but have
                prepared this application on behalf of the applicant and with
                the applicant's consent.
              </label>
              <label>
                <input
                  type='radio'
                  name='whoPreparedThisApplication'
                  value="attorney"
                  onChange={handleInputChange}
                />
                I am an attorney or accredited representative of the applicant
                that prepared this application.
              </label>
            </div>
          </div>

          {formData.whoPreparedThisApplication === "attorney" ? <>
          
          {/* Conditiona: I am an attorney */}
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              My representation of the applicant in this case:
            </label>
            <div className='flex flex-col bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='clientRepresentation'
                  value="extends"
                  onChange={handleInputChange}
                />
                Extends beyond the preparation of this application.
              </label>
              <label>
                <input
                  type='radio'
                  name='i94RecordReceived'
                  value='doesnotextend'
                  onChange={handleInputChange}
                />
                Does not extend beyond the preparation of this application.
              </label>
              <label>
                <input
                  type='radio'
                  name='i94RecordReceived'
                  value='attorneyandaccredited'
                  onChange={handleInputChange}
                />
                I am an attorney or accredited representative of the applicant
                that prepared this application.
              </label>
            </div>
          </div>
          </>:<></>}

            {formData.whoPreparedThisApplication === "applicant" ? <></>:<>
            {/* Conditional: Not an Attorney */}
          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Preparer's Information
            </h1>

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Given Name (First Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerFirstName'
                  value={formData.preparerFirstName}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Family Name (Last Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerMiddleName'
                  value={formData.preparerMiddleName}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Business or Organization Name
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerLastName'
                  value={formData.preparerLastName}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>

          {/* Conditional: Preparers Address */}
          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Preparer's Address
            </h1>

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Street Number and Name
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerAddress'
                  value={formData.preparerAddress}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Is there an apartment, suite or floor?
              </label>
              <span className='flex text-blue-600'>
                <select
                  id='apartment'
                  name='apartment'
                  value={formData.apartment}
                  onChange={handleInputChange}
                  className='w-[100px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>No</option>
                  <option>apt</option>
                  <option>ste</option>
                  <option>flr</option>
                </select>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>City or town</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerCityOrTown'
                  value={formData.preparerCityOrTown}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Country</label>
              <span className='flex text-blue-600'>
                <select
                  id='preparerCountry'
                  name='preparerCountry'
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                  onChange={handleInputChange}
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                State (or Territory)
              </label>
              <span className='flex text-blue-600'>
                <select
                  id='preparerStateOrTerritory'
                  name='preparerStateOrTerritory'
                  value={formData.preparerStateOrTerritory}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Zip Code</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  id='preparerZipCode'
                  name='preparerZipCode'
                  placeholder='#####'
                  value={formData.preparerZipCode}
                  onChange={handleInputChange}
                  className='w-[200px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>
          {/* Conditional: Preparers Contact Info Section */}
          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Preparer's Contact Information
            </h1>

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Daytime Phone Number
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerDaytimePhoneNo'
                  value={formData.preparerDaytimePhoneNo}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Mobile Phone Number
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='preparerPhoneNo'
                  value={formData.preparerPhoneNo}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Email</label>
              <span className='flex text-blue-600'>
                <input
                  type='email'
                  name='preparerEmail'
                  value={formData.preparerEmail}
                  placeholder='user@example.com'
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>
            </>}

          

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default FinalDetails
