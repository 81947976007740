

import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/Context131A';



const Eligibility131A = () => {
    const navigate =useNavigate()
    const { formData, handleInputChange } = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
       navigate("/BasicInformation")
    };
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
         
              <h2 className='text-blue-700 text-xl font-semibold'>Immigration Application</h2>
  

              <div>
      <div className='mt-10 '>
        <label className='bg-blue-500 flex text-white p-4 rounded'>Are you currently in the United States?</label>
         <div className='flex bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="inTheUnitedStates"
            value="Yes"
            checked={formData.inTheUnitedStates === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="inTheUnitedStates"
            value="No"
            checked={formData.inTheUnitedStates === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        </div>
      </div>
     { formData.inTheUnitedStates === 'Yes'
         &&
     <div className='bg-red-200 rounded p-2 flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[30px] h-[30px]'/>
        <p> <span className='font-bold'>Sorry. Based on your answer, you cannot continue. </span>This application may only be used by a person who is physically outside the United States. If you are presently inside the U.S., use Form I-90 to renew/replace a green card or use Form I-131 to replace a reentry permit.</p>
      </div>}

     {formData.inTheUnitedStates === 'No'
     &&
     <div className='mt-10 '>
        <label  className='bg-blue-500 flex text-white p-4 rounded'>Are you a permanent resident?</label>
        <div className='flex flex-col bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="permanentResidentStatus"
            value="Yes, I am a lawful permanent resident"
            checked={formData.permanentResidentStatus === 'Yes, I am a lawful permanent resident'}
            onChange={handleInputChange}
          />
          Yes, I am a lawful permanent resident
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="permanentResidentStatus"
            value="Yes, I am a conditional permanent resident"
            checked={formData.permanentResidentStatus === 'Yes, I am a conditional permanent resident'}
            onChange={handleInputChange}
          />
          Yes, I am a conditional permanent resident
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="permanentResidentStatus"
            value="No, I have a different immigration status"
            checked={formData.permanentResidentStatus === 'No, I have a different immigration status'}
            onChange={handleInputChange}
          />
          No, I have a different immigration status
        </label>
        </div>
      </div>}

      <div className='mt-10'>
  <label className='bg-blue-600 text-white p-4 rounded flex '>
    Are you replacing a Permanent Resident Card or Reentry Permit?
  </label>
  <div className='flex bg-blue-100 p-4 rounded flex-col'>
  <label className='flex gap-2 text-blue-600'>
    <input
      type='radio'
      name='replacingGreenCard'
      value='Permanent Resident Card (green card)'
      checked={formData.replacingGreenCard === 'Permanent Resident Card (green card)'}
      onChange={handleInputChange}
    />
    Permanent Resident Card (green card)
  </label>
  <label className='flex gap-2 text-blue-600'>
    <input
      type='radio'
      name='replacingGreenCard'
      value='Reentry Permit'
      checked={formData.replacingGreenCard === 'Reentry Permit'}
      onChange={handleInputChange}
    />
    Reentry Permit
  </label>
  </div>
</div>

{formData.replacingGreenCard === 'Reentry Permit'
    &&
    <div className='mt-10'>
  <label className='bg-blue-500 flex flex-col text-white p-4'>
    Select the reason for your application:
  </label>
  <div className='flex flex-col bg-blue-100 p-4 rounded text-blue-600'>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonApplication'
      value='My previous Reentry Permit has been lost.'
      checked={formData.reasonForApplication === 'My previous Reentry Permit has been lost.'}
      onChange={handleInputChange}
    />
    My previous Reentry Permit has been lost.
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonApplication'
      value='My previous Reentry Permit has been stolen.'
      checked={formData.reasonForApplication === 'My previous Reentry Permit has been stolen.'}
      onChange={handleInputChange}
    />
    My previous Reentry Permit has been stolen.
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonApplication'
      value='My previous Reentry Permit has been destroyed.'
      checked={formData.reasonApplication === 'My previous Reentry Permit has been destroyed.'}
      onChange={handleInputChange}
    />
    My previous Reentry Permit has been destroyed.
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonApplication'
      value='My existing Reentry Permit has been damaged.'
      checked={formData.reasonApplication === 'My existing Reentry Permit has been damaged.'}
      onChange={handleInputChange}
    />
    My existing Reentry Permit has been damaged.
  </label>
  </div>
</div>}



{formData.replacingGreenCard === 'Permanent Resident Card (green card)'
    &&
    <div className='mt-10 gap-4'>
  <label className='bg-blue-500  flex p-4 rounded  text-white'>
    Select the reason for your application:
  </label>

  <div className='bg-blue-100  p-4  flex flex-col text-blue-700 '>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonoFApplication'
      value='My previous Permanent Resident Card has been lost, stolen, or destroyed.'
      checked={formData.reasonoFApplication === 'My previous Permanent Resident Card has been lost, stolen, or destroyed.'}
      onChange={handleInputChange}
    />
    My previous Permanent Resident Card has been lost, stolen, or destroyed.
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonoFApplication'
      value='My previous Permanent Resident Card was issued but never received.'
      checked={formData.reasonoFApplication === 'My previous Permanent Resident Card was issued but never received.'}
      onChange={handleInputChange}
    />
    My previous Permanent Resident Card was issued but never received.
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonoFApplication'
      value='My existing Permanent Resident Card has been damaged.'
      checked={formData.reasonoFApplication === 'My existing Permanent Resident Card has been damaged.'}
      onChange={handleInputChange}
    />
    My existing Permanent Resident Card has been damaged.
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='reasonoFApplication'
      value='My existing Permanent Resident Card has already expired.'
      checked={formData.reasonoFApplication === 'My existing Permanent Resident Card has already expired.'}
      onChange={handleInputChange}
    />
    My existing Permanent Resident Card has already expired.
  </label>
  </div>
</div>}

     { formData.permanentResidentStatus === 'No, I have a different immigration status'
     &&
     <div className='mt-10'>
        <label  className='bg-blue-500 flex text-white p-4 rounded'>Select the reason for your application:</label>
        <div className='flex flex-col bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="reasonForApplication"
            value="My existing Advance Parole Document has been lost, stolen, or destroyed."
            checked={formData.reasonForApplication === 'My existing Advance Parole Document has been lost, stolen, or destroyed.'}
            onChange={handleInputChange}
          />
          My existing Advance Parole Document has been lost, stolen, or destroyed.
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="reasonForApplication"
            value="My existing Advance Parole Document has been damaged."
            checked={formData.reasonForApplication === 'My existing Advance Parole Document has been damaged.'}
            onChange={handleInputChange}
          />
          My existing Advance Parole Document has been damaged.
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="reasonForApplication"
            value="My existing Employment Authorization Document (with travel endorsement) has been lost, stolen, or destroyed."
            checked={formData.reasonForApplication === 'My existing Employment Authorization Document (with travel endorsement) has been lost, stolen, or destroyed.'}
            onChange={handleInputChange}
          />
          My existing Employment Authorization Document (with travel endorsement) has been lost, stolen, or destroyed.
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="reasonForApplication"
            value="My existing Employment Authorization Document (with travel endorsement) has been damaged."
            checked={formData.reasonForApplication === 'My existing Employment Authorization Document (with travel endorsement) has been damaged.'}
            onChange={handleInputChange}
          />
          My existing Employment Authorization Document (with travel endorsement) has been damaged.
        </label>
        </div>
      </div>}

     { (formData.reasonForApplication === 'My existing Employment Authorization Document (with travel endorsement) has been damaged.' || formData.reasonForApplication === 'My existing Employment Authorization Document (with travel endorsement) has been lost, stolen, or destroyed.' 
      || formData.reasonForApplication === 'My existing Advance Parole Document has been damaged.'|| formData.reasonForApplication === 'My existing Advance Parole Document has been lost, stolen, or destroyed.')
     
     &&
     <div className='mt-10 '>
        <label  className='bg-blue-500 flex text-white p-4 rounded'>Does your Advance Parole Document continue to be valid and unexpired?</label>
          <div className='flex bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="advanceParoleValid"
            value="Yes"
            checked={formData.advanceParoleValid === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="advanceParoleValid"
            value="No"
            checked={formData.advanceParoleValid === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        </div>
      </div>}

      {formData.advanceParoleValid === 'Yes'
        &&
        <div className='mt-10'>
        <label  className='bg-blue-500 flex text-white p-4 rounded'>Are you NOW, or were you EVER, in exclusion, deportation, removal, or rescission proceedings?</label>
         <div className='flex bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="inProceedings"
            value="Yes"
            checked={formData.inProceedings === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="inProceedings"
            value="No"
            checked={formData.inProceedings === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        </div>
      </div>
      }

{formData.inProceedings === 'Yes'
    &&
    <>
<div className='bg-orange-200 p-2 flex flex-col rounded gap-6'>
        <div className='flex gap-2 '>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
            <p> <span className='font-bold'>  We found a problem. Based on your answer, you cannot continue.</span>  Your situation is more complicated than most. Because your Advance Parole is expired, we prefer that you contact an experienced immigration attorney.</p>
        </div>
        <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]' />
            <p className='text-blue-600'>Help me find an immigration attorney</p>
        </div>
    </div>

<div className='flex  gap-4 bg-green-200 p-2 rounded w-[700px] ml-auto'>

  <label className='flex gap-2 text-slate-700'>
  I acknowledge that I have spoken to an attorney and/or want to continue anyway
    <input
      type='checkbox'
      name='acknowledgeAttorney'
      checked={formData.acknowledgeAttorney}
      onChange={handleInputChange}
    />
  
  </label>
</div>
</>}








      {formData.inProceedings === 'No'
        &&
        <div className='mt-10'>
        <label  className='bg-blue-500 flex text-white p-4 rounded'>Since your admission to the United States in your current immigration status, have you ever been charged with any misdemeanor or felony crime?</label>
         <div className='flex bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="chargedWithCrime"
            value="Yes"
            checked={formData.chargedWithCrime === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="chargedWithCrime"
            value="No"
            checked={formData.chargedWithCrime === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        </div>
      </div>
}
      <div className='mt-10'>
        <label  className='bg-blue-500 flex text-white p-4 rounded'>Was your Advance Parole revoked?</label>
         <div className='flex bg-blue-100 p-4 rounded'>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="advanceParoleRevoked"
            value="Yes"
            checked={formData.advanceParoleRevoked === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2 text-blue-600'>
          <input
            type="radio"
            name="advanceParoleRevoked"
            value="No"
            checked={formData.advanceParoleRevoked === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        </div>
      </div>
      <p className='bg-slate-200 p-2 rounded'>Indicate if you received a Notice of Revocation or have had the underlying immigration status denied in any way.</p>
    </div>
    {formData.advanceParoleRevoked === 'Yes'
        &&
        <div className='bg-red-200 p-2 flex flex-col rounded gap-6'>
        <div className='flex gap-2 '>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
            <p> <span className='font-bold'>  We found a problem. Based on your answer, you cannot continue.</span>  Your situation is more complicated than most. Because your Advance Parole is expired, we prefer that you contact an experienced immigration attorney.</p>
        </div>
        <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]' />
            <p className='text-blue-600'>Help me find an immigration attorney</p>
        </div>
    </div>
}

   {formData.advanceParoleRevoked === 'No'
   &&
   <div className='mt-10 bg-green-300 flex gap-2  p-2 rounded'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className='w-[40px] h-[30px]'/>
        <p>  <span className='font-bold'> Congratulations, you're likely eligible to file Form I-131A, Application for Travel Document (Carrier Documentation)</span>
 <br />Please click on "Next Page" below to continue your application.</p>
    </div>}
             
  
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
        
      </div>
    )
}

export default Eligibility131A