import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
// import Select from "react-select";
import stateData from "./ports";
import form from "../../Assets/icon.png";
import Fotter from "../../components/Footer";

import "./style.css";
import { useNavigate } from "react-router-dom";

const I90 = () => {
  const navigate=useNavigate()
  const reasonOptions = [
    {
      value: "expire",
      label: "My existing card will expire in 6 months or has already expired",
    },
    {
      value: "lost",
      label: "My previous card has been lost, stolen, or destroyed",
    },
    {
      value: "issued",
      label: "My previous card was issued but never received",
    },
    {
      value: "mutilated",
      label: "My existing card has been mutilated",
    },
    {
      value: "incorrect",
      label: "My existing card has incorrect data because of DHS error",
    },
    {
      value: "biographic",
      label:
        "My name or other biographic information has been legally changed since issuance of my existing card",
    },
    {
      value: "14thbirthday",
      label: "I have reached my 14th birthday and am registering as required",
    },
    {
      value: "commuterstatus",
      label: "I am a permanent resident who is taking up commuter status",
    },
    {
      value: "convertedstatus",
      label: "I have been automatically converted to permanent resident status",
    },
    {
      value: "priorcard",
      label: "I have a prior edition of the Alien Registration Card",
    },
    {
      value: "replace",
      label:
        "I am applying to replace my current Permanent Resident Card for a reason that is not specified above",
    },
  ];
  
  const [selectedOption, setSelectedOption] = useState("");
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);
  const [inUSAnswer, setInUSAnswer] = useState("");
  const [misdemeanor, setmisdemeanor] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedWhy, setSelectedWhy] = useState("");
  const [selectexpire, setselectexpire] = useState("");
  const [isEligibilityYes, setIsEligibilityYes] = useState("");
  const [isEligibility, setIsEligibility] = useState();
  const [status, setstatus] = useState();
  const [complete, setComplete] = useState();
  const [exclusion, setExclusionAnswer] = useState("");
  const [Temporary, setTemporaryOption] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [felonycrime, setIsCheckfelonycrime] = useState(false);
  const [isstatus, checkStatus] = useState(false);
  const [existingCard, setExistingCard] = useState("");
  const [Turning14, setTurning14] = useState("");

  const [selectedState, setSelectedState] = useState(null);
  const [Expire90, setExpire90] = useState(null);

  const options = stateData.map((state) => ({
    value: state.name,
    label: state.name,
  }));

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);

    // Reset child or related states based on selectedOption change
    setShowAdditionalDiv(e.target.value === "none"); // Directly set based on selected value
    setInUSAnswer("");
    setSelectedReason("");
    setSelectedWhy("");
    setselectexpire("");
    setIsEligibility("");
    setExclusionAnswer("");
    setmisdemeanor("");
    setstatus("");
    setIsCheckboxChecked(false);
    setIsCheckfelonycrime(false);
    checkStatus(false);
    setComplete("");
    setTemporaryOption("");
    setExistingCard("");
    setTurning14("");
    setExpire90("");
  };
  const handlecomplete = (e) => {
    setComplete(e.target.value);
    // If there are other states related to exclusion, reset them here
  };
  const handleTemporary = (e) => {
    setTemporaryOption(e.target.value);
    // If there are other states related to exclusion, reset them here
  };
  const handleTurning14 = (e) => {
    // Handle the user's selection here
    setTurning14(e.target.value);
  };
  const handleInUSAnswerChange = (e) => {
    setInUSAnswer(e.target.value);
    // If there are other states dependent on inUSAnswer, reset them here
  };
  const handleExistingCard = (event) => {
    setExistingCard(event.target.value);
  };
  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
    // If there are states dependent on selectedReason, reset them here
  };
  const handlestatus = (e) => {
    setstatus(e.target.value);
    // If there are states dependent on selectedReason, reset them here
  };

  const handleEligbility = (e) => {
    setIsEligibility(e.target.value);
    setIsEligibilityYes(e.target.value === "yes");
    // If there are other states related to eligibility, reset them here
  };

  const handlemisdemeanor = (e) => {
    setmisdemeanor(e.target.value);
    // If there are states dependent on misdemeanor, reset them here
  };

  const handleExpire = (e) => {
    setselectexpire(e.target.value);
    // If there are states dependent on selectexpire, reset them here
  };
const handleExpire90 =(e)=>{
  setExpire90(e.target.value);
}
  console.log("exclusion:", exclusion);
  
  
  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            Application to Replace Permanent Resident Card
          </span>
          <div className="h-full flex justify-end">
            {" "}
            {/* Added 'flex' and 'justify-end' */}
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>

        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2>First, let's determine your eligibility to file Form I-90</h2>
        </div>
        <div className="flex flex-col border w-full pb-10 bg-slate-100 bgc">
          <div className="flex justify-between items-center">
            <span className="mt-4 font-bold text-xl ml-2 text-black section">
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;Basis of
              Application
            </span>
          </div>
          <br />
          <div>
            <div className="mt-2 font-thin ml-2 text ndRendring">
              Select your current immigration status:
              <span className="text-red-500">*</span>
            </div>
            <div className="form">
              <input
                type="radio"
                name="immigrationStatus"
                value="permanent"
                id="permanentRadio"
                onChange={handleOptionChange}
              />
              <label htmlFor="permanentRadio">I am a permanent resident</label>
              <hr />
              <input
                type="radio"
                name="immigrationStatus"
                value="commuter"
                id="commuterRadio"
                onChange={handleOptionChange}
              />
              <label htmlFor="commuterRadio">
                I am a permanent resident in commuter status
              </label>
              <hr />
              <input
                type="radio"
                name="immigrationStatus"
                value="conditional"
                id="conditionalRadio"
                onChange={handleOptionChange}
              />
              <label htmlFor="conditionalRadio">
                I am a conditional permanent resident
              </label>
              <hr />
              <input
                type="radio"
                name="immigrationStatus"
                value="none"
                id="noneRadio"
                onChange={handleOptionChange}
              />
              <label htmlFor="noneRadio">
                I have never had permanent resident status
              </label>
              <hr />
              <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
              Most applicants are permanent residents. Explain more >>
                </div>
            </div>
          </div>
          {selectedOption === "permanent" || selectedOption === "commuter" || selectedOption==='conditional'? (
            <div className="additional-div">
              <div className="bg-blue-500 text-white font-bold p-2 ndRendring text">
                Are you currently in the United States?{" "}
                <span className="text-red-500">*</span>
              </div>
              <div className="form ">
                <input
                  type="radio"
                  name="inUS"
                  value="yes"
                  id="inUSYes"
                  onChange={handleInUSAnswerChange}
                />
                <label htmlFor="inUSYes">Yes</label> &nbsp; &nbsp;
                <input
                  type="radio"
                  name="inUS"
                  value="no"
                  id="inUSNo"
                  onChange={handleInUSAnswerChange}
                />
                <label htmlFor="inUSNo">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                This application must be filed while in the United States.
                </div>
                


              </div>
              {(selectedOption === "permanent" && inUSAnswer === "yes") ||
              (selectedOption === "commuter" && inUSAnswer === "yes") ? (
                <div className="additional-div">
                  <div className="mt-2 font-thin ml-2 text">
                    Select the reason for renewing your card:
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="form">
                    {reasonOptions.map((option, index) => (
                      <div key={option.value}>
                        <input
                          type="radio"
                          name="reason"
                          value={option.value}
                          id={`reasonRadio${index}`}
                          onChange={handleReasonChange}
                        />
                        <label htmlFor={`reasonRadio${index}`}>
                          {option.label}
                        </label>
                        {index < reasonOptions.length - 1 && (
                          <hr
                            style={{
                              backgroundColor: "#D2E4F4",
                              height: "1px",
                              border: "none",
                            }}
                          />
                        )}
                      </div>
                      
                    ))}<div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>

                   Select the most relevant reason for preparing this application. Explain more >>
              </div>
                  </div>
                </div>
              ) : null}

              {selectedOption === "permanent" && inUSAnswer === "no" ? (
                <div className="bg-red-200 error">
                  <div className="icon-and-message">
                    <span className="icon">
                      <img src={crosspic} alt="" width="72" height="32" />
                    </span>
                    <p className="message ml-2">
                      <span className="font-bold">
                        You must file Form I-90 while in the United States.
                      </span>
                      If you are presently outside the United States and your
                      green card is lost, stolen, or expired, you may need to
                      file Form I-131A, Application for Travel Document (Carrier
                      Documentation), so that you can return to the United
                      States. For more information, this article will explain
                      your options.
                    </p>
                  </div>
                </div>
              ) : null}

              {selectedOption === "expire" ? (
                <div className="additional-div">
                  <div className=" text-white font-bold p-2 ndRendring text">
                    Have you EVER been in exclusion, deportation, or removal
                    proceedings or ordered removed from the United States?{" "}
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="form">
                    <input
                      type="radio"
                      name="inUS"
                      value="yes"
                      id="EligibilityYes"
                      onChange={handleExpire}
                    />
                    <label htmlFor="inUSYes">Yes</label>
                    <input
                      type="radio"
                      name="inUS"
                      value="no"
                      id="EligibilityNo"
                      onChange={handleExpire}
                    />
                    <label htmlFor="inUSNo">No</label>
                  </div>
                </div>
              ) : null}

              {selectedOption === "commuter" && inUSAnswer === "no" ? (
                <div className="additional-div">
                  <div className="mt-2 font-thin ml-2 text">
                    &nbsp;&nbsp; &nbsp; &nbsp; Select the reason for renewing
                    your card:
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="form">
                    {reasonOptions.map((option, index) => (
                      <div key={option.value}>
                        <input
                          type="radio"
                          name="reason"
                          value={option.value}
                          id={`reasonRadio${index}`}
                          onChange={handleReasonChange}
                        />
                        <label htmlFor={`reasonRadio${index}`}>
                          {option.label}
                        </label>
                        {index < reasonOptions.length - 1 && (
                          <hr
                            style={{
                              backgroundColor: "#D2E4F4",
                              height: "1px",
                              border: "none",
                            }}
                          />
                        )}
                      </div>
                      
                    ))}
                   <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>

                   Select the most relevant reason for preparing this application. Explain more >>
              </div>
                  </div>
                 
                </div>
              ) : null}
            </div>
          ) : null}

          {inUSAnswer === "yes" && selectedOption==='conditional' ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                Does your Permanent Resident Card expire in less than 90 days?
                (i.e. on or before 01/13/2024)
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="cardExpiry"
                  value="yes"
                  id="CardExpiryYes"
                  onChange={handleExpire90}
                />
                <label htmlFor="CardExpiryYes">Yes</label>
                <input
                  type="radio"
                  name="cardExpiry"
                  value="no"
                  id="CardExpiryNo"
                  onChange={handleExpire90}
                />
                <label htmlFor="CardExpiryNo">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>

                If your conditional status expires before 01/13/2024 choose "Yes"                </div>
              </div>
              
            </div>
          ) : null}
          {inUSAnswer==='no'&& selectedOption==='conditional'?(
            <div className="bg-red-200 error">
            <div className="icon-and-message">
              <span className="icon">
                <img src={crosspic} alt="" width="72" height="72" />
              </span>
              <p className="message ml-2">
                <span className="font-bold">
                You must file Form I-90 while in the United States.                </span>
                If you are presently outside the United States and your green card is lost, stolen or expired, you may need to file Form I-131A, Application for Travel Document (Carrier Documentation), so that you can return to the United States. For more information, this article will explain your options.
              </p>
            </div>
           
          </div>
          ):null}
          {Expire90==='yes'?(
           
              <div className="bg-red-200 error">
                <div className="icon-and-message">
                  <span className="icon">
                    <img src={crosspic} alt="" width="32" height="32" />
                  </span>
                  <p className="message ml-2">
                    <span className="font-bold">
                    If your conditional residence (and conditional green card) is expiring within the next 90 days, do not file this application.                    </span>
                    Instead, file:

                  </p>
                </div>
                <div className="icon-and-message">
                  <span className="icon">
                    <img src={form} alt="" width="32" height="32" />
                  </span>
                  <p className="message ml-2">
                    <span className="font-bold" style={{color:' #0072bc'}}>
                    Form I-751, Petition to Remove Conditions on Residence                    </span>
                    if you became a conditional resident through marriage to a U.S. citizen or permanent resident; or
                  </p>
                </div>
                <div className="icon-and-message">
                  <span className="icon">
                    <img src={form} alt="" width="32" height="32" />
                  </span>
                  <p className="message ml-2">
                    <span className="font-bold" style={{color:' #0072bc'}}>
                    Form I-829 Petition by Entrepreneur to Remove Conditions                                  </span>
                    if you became a conditional resident based on a financial investment in a U.S. business.
                  </p>
                </div>
              </div>
            ):null}
          
          {Expire90==='no'?(
          <div>
              <br />
              <br />

  
  <div>
    <div className="mt-2 font-thin ml-2 text ndRendring">
      Why do you want to use this application?
      <span className="text-red-500">*</span>
    </div>
    <div className="form">
      <input
        type="radio"
        name="reason"
        value="lost"
        id="lostRadio"
        onChange={handleReasonChange}
      />
      <label htmlFor="lostRadio">My previous card has been lost, stolen, or destroyed.</label>
      <hr />
      <input
        type="radio"
        name="reason"
        value="issued"
        id="notReceivedRadio"
        onChange={handleReasonChange}
      />
      <label htmlFor="notReceivedRadio">My previous card was issued but never received.</label>
      <hr />
      <input
        type="radio"
        name="reason"
        value="mutilated"
        id="mutilatedRadio"
        onChange={handleReasonChange}
      />
      <label htmlFor="mutilatedRadio">My existing card has been mutilated.</label>
      <hr />
      <input
        type="radio"
        name="reason"
        value="incorrect"
        id="incorrectDataRadio"
        onChange={handleReasonChange}
      />
      <label htmlFor="incorrectDataRadio">My existing card has incorrect data because of DHS error.</label>
      <hr />
      <input
        type="radio"
        name="reason"
        value="biographic"
        id="nameChangeRadio"
        onChange={handleReasonChange}
      />
      <label htmlFor="nameChangeRadio">My name or other biographic information has been legally changed since issuance of my existing card.</label>
      <hr />
      <input
        type="radio"
        name="reason"
        value="renewal"
        id="renewalRadio"
        onChange={handleReasonChange}
      />
      <label htmlFor="renewalRadio">I want to renew my green card.</label>
    </div>
  </div>
</div>

):null}
{selectedReason==='renewal'?(
   <div className="bg-red-200 error">
   <div className="icon-and-message">
     <span className="icon">
       <img src={crosspic} alt="" width="32" height="32" />
     </span>
     <p className="message ml-2">
       <span className="font-bold">
       If your conditional residence (and conditional green card) is expiring within the next 90 days, do not file this application.                    </span>
       Instead, file:

     </p>
   </div>
   <div className="icon-and-message">
     <span className="icon">
       <img src={form} alt="" width="32" height="32" />
     </span>
     <p className="message ml-2">
       <span className="font-bold" style={{color:' #0072bc'}}>
       Form I-751, Petition to Remove Conditions on Residence                    </span>
       if you became a conditional resident through marriage to a U.S. citizen or permanent resident; or
     </p>
   </div>
   <div className="icon-and-message">
     <span className="icon">
       <img src={form} alt="" width="32" height="32" />
     </span>
     <p className="message ml-2">
       <span className="font-bold" style={{color:' #0072bc'}}>
       Form I-829 Petition by Entrepreneur to Remove Conditions                                  </span>
       if you became a conditional resident based on a financial investment in a U.S. business.
     </p>
   </div>
 </div>
):null}
          {selectedReason === "issued" ? (
            <div
              style={{
                backgroundColor: "#E7E7E7",
                color: "#888",
                width: "89%",
                marginLeft: "6%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "1%",
                borderRadius: "5px",
                marginTop: "2%",
              }}
            >
              <img
                src={warn}
                alt="Warning"
                style={{
                  width: "34px",
                  height: "34px",
                  marginRight: "1%",
                }}
              />
              <div>
                <p>
                  If you are filing Form I-90 because you never received your
                  Permanent Resident Card (green card), you do not have to pay a
                  filing fee as long as you meet ALL these criteria:
                  <br />
                  <br />
                  <ul className="list">
                    <li>USCIS mailed the card over 30 days ago;</li>
                    <li>It was returned to USCIS as undeliverable;</li>
                    <li>
                      You have not moved from the address you provided USCIS.
                    </li>
                  </ul>
                  <br />
                  If you do not meet ALL these criteria, go back and select{" "}
                  <b>"My card has been lost, stolen, or destroyed."</b>
                </p>
              </div>
            </div>
          ) : null}

          {showAdditionalDiv && (
            <div className="bg-red-200 error">
              <div className="icon-and-message">
                <span className="icon">
                  <img src={crosspic} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold">
                    Sorry. Based on your answers, you cannot continue.
                  </span>
                  This application is used by permanent residents to apply for a
                  replacement or renewal of their existing Permanent Resident
                  Cards.
                </p>
              </div>
              <div className="icon-and-message">
                <span className="icon">
                  <img src={Searchicon} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold">
                    Sorry. Based on your answers, you cannot continue.
                  </span>
                  Learn more about family-based immigration or try our
                  Immigration Form Finder.
                </p>
              </div>
            </div>
          )}
          {selectedReason === "lost" ||
          selectedReason === "issued" ||
          selectedReason === "mutilated" ? (
            <div>
              <br />

              <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
              <div className="additional-div">
                <div
                  className="mt-4 font-bold text-xl ml-2 text-black section"
                  style={{ padding: "2%", width: "70%" }}
                >
                  &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; Temporary Green
                  Card
                </div>
                <div
                  style={{
                    backgroundColor: "#E7E7E7",
                    color: "#888",
                    width: "89%",
                    marginLeft: "6%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1%",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src={warn}
                    alt="Warning"
                    style={{
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p>
                    Exclusion, deportation, and removal proceedings are similar
                    terms to describe a situation that you must appear before an
                    immigration judge, and your immigration status is being
                    challenged. Explain more.
                  </p>
                </div>

                <br />
                <div className="text-white font-bold p-2 ndRendring text">
                  Would you like us to include directions for getting temporary
                  proof of permanent residence?
                  <span className="text-red-500">*</span>
                </div>
                <div className="form">
                  <input
                    type="radio"
                    name="TUS"
                    value="tyes"
                    id="temporaryYes"
                    onChange={handleTemporary}
                  />{" "}
                  {/* Fixed the function name here */}
                  <label htmlFor="inUSYes">Yes</label>
                  <input
                    type="radio"
                    name="TUS"
                    value="tno"
                    id="temporaryNo"
                    onChange={handleTemporary}
                  />{" "}
                  {/* Fixed the function name here */}
                  <label htmlFor="inUSNo">No</label>
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="info"
                  >
                    Indicate if you would like information about getting
                    temporary proof of permanent residence until your new card
                    arrives.
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {selectedReason === "commuterstatus" ? (
            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
                Select the Port of Entry (POE) where you will regularly pass
                through to reach your employment in the United States:
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <br />
                <div className="combobox">
                  <select
                    id="B5"
                    name="B5"
                    className="form-control"
                    aria-required="true"
                    required="required"
                    size="15"
                    value={selectedState}
                    onChange={handleStateChange} // Make sure this event handler is correctly defined
                  >
                    <option value="">Select a state...</option>
                    {stateData.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <br />
              </div>
            </div>
          ) : null}
          {selectedReason === "14thbirthday" ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                My existing card:
                <span className="text-red-500">*</span>
              </div>
              <div
                className="form"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="radio-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="existingCard"
                    value="expire16"
                    id="expireAfter16"
                    onChange={handleExistingCard}
                  />
                  <label htmlFor="expireAfter16">
                    Will expire <u>after</u> my 16th birthday.
                  </label>
                </div>
                <hr style={{ backgroundColor: "#D2E4F4", height: "2px" }} />{" "}
                {/* Separator Line */}
                <div
                  className="radio-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="existingCard"
                    value="expireBefore16"
                    id="expireBefore16"
                    onChange={handleExistingCard}
                  />
                  <label htmlFor="expireBefore16">
                    Will expire <u>before</u> my 16th birthday.
                  </label>
                </div>
                <hr style={{ backgroundColor: "#D2E4F4", height: "2px" }} />{" "}
                {/* Separator Line */}
                <div
                  className="radio-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="existingCard"
                    value="expired"
                    id="alreadyExpired"
                    onChange={handleExistingCard}
                  />
                  <label htmlFor="alreadyExpired">Has already expired.</label>
                </div>
              </div>
            </div>
          ) : null}
          {existingCard === "expire16" || existingCard === "expireBefore16" ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                When using this category, you must file this form within 30 days
                after turning 14 years of age.
                <span className="text-red-500">*</span>
              </div>
              <div
                className="form"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="radio-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="turning14"
                    value="moreThan30Days"
                    id="moreThan30Days"
                    onChange={handleTurning14}
                  />
                  <label htmlFor="moreThan30Days">
                    My 14th birthday was more than 30 days ago.
                  </label>
                </div>
                <hr style={{ backgroundColor: "#D2E4F4", height: "2px" }} />{" "}
                {/* Separator Line */}
                <div
                  className="radio-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="turning14"
                    value="lessThan30Days"
                    id="lessThan30Days"
                    onChange={handleTurning14}
                  />
                  <label htmlFor="lessThan30Days">
                    My 14th birthday was less than 30 days ago.
                  </label>
                </div>
                <hr style={{ backgroundColor: "#D2E4F4", height: "2px" }} />{" "}
                {/* Separator Line */}
                <div
                  className="radio-container"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="radio"
                    name="turning14"
                    value="notTurned14Yet"
                    id="notTurned14Yet"
                    onChange={handleTurning14}
                  />
                  <label htmlFor="notTurned14Yet">
                    I have not turned 14 yet.
                  </label>
                </div>
              </div>
            </div>
          ) : null}
          {Turning14 === "moreThan30Days" ? (
            <div className=" error">
              <div
                className="icon-and-message"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <span
                  className="icon"
                  style={{ width: "60px", height: "40px", marginTop: "0" }}
                >
                  <img
                    src={crosspic}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </span>
                <p className="message ml-2">
                  To use this category, you must file within 30 days of turning
                  14 years of age. Because you are beyond this timeframe, you
                  must go back and select the reason,
                  <span className="font-bold">
                    <i>
                      {" "}
                      “I am applying to replace my current permanent resident
                      card for a reason that is not specified above.”{" "}
                    </i>{" "}
                  </span>
                </p>
              </div>
            </div>
          ) : null}
          {Turning14 === "notTurned14Yet" ? (
            <div
              style={{
                backgroundColor: "#E7E7E7",
                color: "#888",
                width: "89%",
                marginLeft: "6%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "1%",
                borderRadius: "5px",
                marginTop: "2%",
              }}
            >
              <img
                src={warn}
                alt="Warning"
                style={{
                  width: "34px",
                  height: "34px",
                  marginRight: "1%",
                }}
              />
              <div>
                <p>
                  To use this category, you must file within 30 days of turning
                  14 years of age. You may prepare this application early{" "}
                  <b>
                    <u>but do not file today.</u>
                  </b>
                  File it on the applicant’s 14th birthday or{" "}
                  <u>within 30 days</u> thereof.
                </p>
              </div>
            </div>
          ) : null}
          {existingCard === "expired" ? (
            <div className=" error">
              <div className="icon-and-message">
                <span className="icon">
                  <img src={crosspic} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  If your card has expired, you must go back and select
                  <span className="font-bold">
                    <i>
                      {" "}
                      “My existing card will expire within 6 months or has
                      already expired.”{" "}
                    </i>{" "}
                  </span>
                </p>
              </div>
            </div>
          ) : null}

          {selectedReason === "expire" ||
          selectedReason === "lost" ||
          selectedReason === "issued" ||
          selectedReason === "mutilated" ||
          selectedReason === "incorrect" ||
          selectedReason === "biographic" ||
          selectedReason === "14thbirthday" ||
          selectedReason === "commuterstatus" ||
          selectedReason === "convertedstatus" ||
          selectedReason === "priorcard" ||
          selectedReason === "replace" ? (
            <div>
              <br />
              <hr style={{ backgroundColor: "#0072bc", height: "2px" }} />
              <div className="additional-div">
                <span className="mt-4 font-bold text-xl ml-2 text-black section">
                  &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                  &nbsp;Eligibility
                </span>
                <br />
                <div className="text-white font-bold p-2 ndRendring text">
                  Have you EVER been in exclusion, deportation, or removal
                  proceedings or ordered removed from the United States?{" "}
                  <span className="text-red-500">*</span>
                </div>
                <div className="form">
                  <input
                    type="radio"
                    name="inUS"
                    value="yes"
                    id="EligibilityYes"
                    onChange={handleTemporary}
                  />{" "}
                  {/* Fixed the function name here */}
                  <label htmlFor="inUSYes">Yes</label>
                  <input
                    type="radio"
                    name="inUS"
                    value="no"
                    id="EligibilityNo"
                    onChange={handleTemporary}
                  />{" "}
                  {/* Fixed the function name here */}
                  <label htmlFor="inUSNo">No</label>
                  <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                    Exclusion, deportation, and removal proceedings are similar
                    terms to describe a situation that you must appear before an
                    immigration judge and your immigration status is being
                    challenged. Explain more
                  </div>
                </div>
              </div>
            </div>
          ) : null}

{Temporary=='yes' ? (
            <div className=" error1">
              <div className="icon-and-message">
                <span className="icon">
                  <img src={crosspic} alt="" width="62" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold">
                    Your answer to this question suggests that you have a
                    history which may require the assistance of a legal
                    professional{" "}
                  </span>
                  We want the best result for you, and therefore CitizenPath
                  suggests that you consult with an experienced immigration
                  attorney that can provide you with additional guidance and
                  representation.{" "}
                </p>
                <span className="text-red-500">*</span>
              </div>
              <div className="icon-and-message">
                <span className="icon">
                  <img src={Searchicon} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold"></span>
                  Help me find an immigration attorney{" "}
                </p>
              </div>
              <div
                className="checkbox-message"
                style={{
                  background: "#b4d7b8",
                  height: "40px",
                  borderRadius: "0px 0px 3px 3px",
               
                }}
              >
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  />
                  I acknowledge that I have spoken to an attorney and / or want
                  to continue anyway
                </label>
              </div>
            </div>
          ) : null}

          {Temporary === "no" ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                Since you were granted permanent residence, have you EVER been
                charged with any misdemeanor or felony crime?
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="ionUS"
                  value="yess"
                  id="EligibilityYess"
                  onChange={handlemisdemeanor}
                />
                <label htmlFor="inUSYes">Yes</label>
                <input
                  type="radio"
                  name="ionUS"
                  value="noo"
                  id="EligibilityNoo"
                  onChange={handlemisdemeanor}
                />
                <label htmlFor="inUSNo">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Exclusion, deportation, and removal proceedings are similar
                  terms to describe a situation that you must appear before an
                  immigration judge and your immigration status is being
                  challenged. Explain more
                </div>
              </div>
            </div>
          ) : null}
          {isCheckboxChecked ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                Since you were granted permanent residence, have you EVER been
                charged with any misdemeanor or felony crime?
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="iinUS"
                  value="yess"
                  id="EligibilityYess"
                  onChange={handlemisdemeanor}
                />
                <label htmlFor="inUSYes">Yes</label>
                <input
                  type="radio"
                  name="iinUS"
                  value="noo"
                  id="EligibilityNoo"
                  onChange={handlemisdemeanor}
                />
                <label htmlFor="inUSNo">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Exclusion, deportation, and removal proceedings are similar
                  terms to describe a situation that you must appear before an
                  immigration judge and your immigration status is being
                  challenged. Explain more
                </div>
              </div>
            </div>
          ) : null}

          {misdemeanor === "yess" ? (
            <div className="error1">
              <div className="icon-and-message">
                <span className="icon">
                  <img src={crosspic} alt="" width="62" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold">
                    Your answer to this question suggests that you have a
                    history which may require the assistance of a legal
                    professional
                  </span>
                  We want the best result for you, and therefore CitizenPath
                  suggests that you consult with an experienced immigration
                  attorney that can provide you with additional guidance and
                  representation.
                </p>
              </div>
              <div className="icon-and-message">
                <span className="icon">
                  <img src={Searchicon} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold"></span>
                  Help me find an immigration attorney
                </p>
              </div>
              <div
                className="checkbox-message"
                style={{
                  background: "#b4d7b8",
                  height: "40px",
                  borderRadius: "0px 0px 3px 3px",
                }}
              >
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => setIsCheckfelonycrime(e.target.checked)}
                  />
                  I acknowledge that I have spoken to an attorney and / or want
                  to continue anyway
                </label>
              </div>
            </div>
          ) : null}

          {felonycrime || misdemeanor === "noo" ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                Since you were granted permanent residence, have you EVER filed
                Form I-407, Abandonment by Alien of Status, as Lawful Permanent
                Resident, or otherwise been judged to have abandoned your
                status? <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="ioonUS"
                  value="yess"
                  id="EligibilityYess"
                  onChange={handlestatus}
                />
                <label htmlFor="inUSYes">Yes</label>
                <input
                  type="radio"
                  name="ioonUS"
                  value="noo"
                  id="EligibilityNoo"
                  onChange={handlestatus}
                />
                <label htmlFor="inUSNo">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Exclusion, deportation, and removal proceedings are similar
                  terms to describe a situation that you must appear before an
                  immigration judge and your immigration status is being
                  challenged. Explain more
                </div>
              </div>
            </div>
          ) : null}

          {status === "yess" ? (
            <div className=" error1">
              <div className="icon-and-message">
                <span className="icon">
                  <img src={crosspic} alt="" width="62" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold">
                    Your answer to this question suggests that you have a
                    history which may require the assistance of a legal
                    professional{" "}
                  </span>
                  Any type of misdemeanor or felony arrest can affect the
                  success of your application. Even if you have been arrested
                  for a DUI (driving under the influence) or minor crime, you
                  should truthfully answer this question. Explain more >>
                </p>
              </div>
              <div className="icon-and-message">
                <span className="icon">
                  <img src={Searchicon} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold"></span>
                  Help me find an immigration attorney{" "}
                </p>
              </div>
              <div
                className="checkbox-message"
                style={{
                  background: "#b4d7b8",
                  height: "40px",
                  borderRadius: "0px 0px 3px 3px",
                }}
              >
                <label>
                  <input
                    type="checkbox"
                    onChange={(e) => checkStatus(e.target.checked)}
                  />
                  I acknowledge that I have spoken to an attorney and / or want
                  to continue anyway
                </label>
              </div>
            </div>
          ) : null}
          {status === "noo" || isstatus ? (
            <div className="additional-div">
              <div className="text-white font-bold p-2 ndRendring text">
                Have you ever served honorably in the U.S. armed forces?
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="ionUSS"
                  value="yess"
                  id="EligibilityYess"
                  onChange={handlecomplete}
                />
                <label htmlFor="inUSYes">Yes</label>
                <input
                  type="radio"
                  name="ionUSS"
                  value="noo"
                  id="EligibilityNoo"
                  onChange={handlecomplete}
                />
                <label htmlFor="inUSNo">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Exclusion, deportation, and removal proceedings are similar
                  terms to describe a situation that you must appear before an
                  immigration judge and your immigration status is being
                  challenged. Explain more
                </div>
              </div>
            </div>
          ) : null}               <br /> 
               <br /> 

          {complete === "yess" || complete === "noo" ? (
          <div
              style={{
                backgroundColor: "#92D3A8",
                padding: "10px",
                margin: "10px",
                color: 'rgba(60, 60, 60, 0.7)',
                textAlign: "center",
                borderRadius: "9px",
                width: "90%",
                marginLeft: "6%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="icon">
                  <img src={sucess} alt="" width="32" height="32" />
                </span>
                <div style={{ marginLeft: "10px", textAlign: "left" }}>
                  <p style={{ color: "#006400", fontWeight: "bold",                color: 'rgba(60, 60, 60, 0.7)',
 }}>
                    Congratulations, you're likely eligible to file Form I-90 to
                    replace your Permanent Resident Card.
                  </p>
                  <p>
                    Please click on "Next Page" below to continue your
                    application.
                  </p>
                </div>
              </div>
              
            </div>
          ) : null}
          <br />
          <div className="w-full ">
  <button
    type="button"
    className="float-left previous text-white px-4 py-2 rounded m-4" style={{background:'rgb(187, 187, 187)'}}
  >
    ← PREV
  </button>
  <button
    type="button"
    className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-4"
    onClick={()=>navigate("/I901")}
  >
    NEXT →
  </button> &nbsp;&nbsp;&nbsp;
</div>


        </div>
        <FormFotter />

      </div>
      <Fotter />

    </div>
  );
};

export default I90;
