
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/Context131A';


const BasicInformation131A = () => {
    const navigate =useNavigate()
    const {  formData, handleInputChange, code} = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
       navigate("/travelinfo131A")
    };
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
               

               <div>
              <h2 className='text-blue-700 text-xl font-semibold'>Immigration Application</h2>
              <p className='text-sm'>Provide your full legal name. Your legal name is most likely the name on your passport unless it has been changed by a legal action such as a marriage or court order. Do not provide a nickname. Spell out middle names completely and do not use just the initial. If you do not have a middle name, you may leave this field blank.</p>
              </div>
              <div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Name of Applicant</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="nameOfApplicant"
    value={formData.nameOfApplicant}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Middle Name(s)</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="middleNames"
    value={formData.middleNames}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Family Name (Last Name)</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="lastName"
    value={formData.lastName}
    onChange={handleInputChange}
  />
</div>


<div className='mt-10'>
  <label className='bg-blue-700 text-white flex  rounded p-4'>
    Has your name legally changed since receiving your most recent Advance Parole Document?
  </label>
  <div className="flex bg-blue-100  rounded p-4">
  <label className='flex gap-2 text-blue-600'>
    <input
      type='radio'
      name='nameLegallyChanged'
      value='Yes'
      checked={formData.nameLegallyChanged === 'Yes'}
      onChange={handleInputChange}
    />
    Yes
  </label>
  <label className='flex gap-2 text-blue-600'>
    <input
      type='radio'
      name='nameLegallyChanged'
      value='No'
      checked={formData.nameLegallyChanged === 'No'}
      onChange={handleInputChange}
    />
    No
  </label>
  </div>
</div>



     { formData.nameLegallyChanged === 'No'
     
     &&
     <>    
    <hr className='h-1 bg-blue-600  w-full mt-10'/>

    <div>
      <h1 className='text-blue-600 font-semibold text-xl'>U.S. Mailing Address</h1>
      <p className='text-sm'>You may list a valid residence, APO, C/O, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
    </div>

    <div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>In Care of Name (if any)</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='inCareOfName'
    value={formData.inCareOfName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>Street Number and Name</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='streetNumberAndName'
    value={formData.streetNumberAndName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>City or Town</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='cityOrTown'
    value={formData.cityOrTown}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>ZIP Code</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='zipCode'
    value={formData.zipCode}
    onChange={handleInputChange}
  />
</div>






    <div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>
    Is there an apartment, suite, or floor?
  </label>
  <select
    className='w-[100px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6'
    name='apartment'
    value={formData.apartment}
    onChange={handleInputChange}
  >
    <option value='No'>No</option>
    <option value='Ste'>Ste</option>
    <option value='Flr'>Flr</option>
    <option value='Apt'>Apt</option>
  </select>
</div>

{formData.apartment !== "No"
   &&
  <div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>Enter the number here:</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='number'
    value={formData.number}
    onChange={handleInputChange}
  />
</div>}

<div className='flex mt-10 gap-3'>
  <label htmlFor='stateOrTerritory' className='w-[200px] text-blue-500'>
    State (or Territory):
  </label>
  <select
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-14'
    id='stateOrTerritory'
    name='stateOrTerritory'
    value={formData.stateOrTerritory}
    onChange={handleInputChange}
  >
    <option value=''>Select a State or Territory</option>
    {code.map((stateOrTerritory) => (
      <option key={stateOrTerritory} value={stateOrTerritory}>
        {stateOrTerritory}
      </option>
    ))}
  </select>
</div>
<div className='flex mt-10 gap-4'>
  <label className='text-blue-500'>
    Is your current mailing address the same as your U.S. physical address?
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='mailingAddressSameAsPhysical'
      value='Yes'
      checked={formData.mailingAddressSameAsPhysical === 'Yes'}
      onChange={handleInputChange}
    />
    Yes
  </label>
  <label className='flex gap-2'>
    <input
      type='radio'
      name='mailingAddressSameAsPhysical'
      value='No'
      checked={formData.mailingAddressSameAsPhysical === 'No'}
      onChange={handleInputChange}
    />
    No
  </label>
</div>
</> } 

<hr  className='bg-blue-600 mt-10 h-1'/>

<div>
  <h1 className='text-blue-600 font-semibold text-xl'>U.S. Physical Address</h1>
  <p>Your physical address is the location where you live in the United States. Please provide a complete address. Every U.S. address includes a street address with a number and street name. You may or may not have an apartment number. All addresses should include the city, state and ZIP code.</p>
</div>


<h1 className='text-blue-600 font-semibold text-xl mt-10'>Current Physical Address</h1>
<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>Street Number and Name:</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='currentPhysicalAddressStreetNumberAndName'
    value={formData.currentPhysicalAddressStreetNumberAndName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>City or Town:</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='currentPhysicalAddressCityOrTown'
    value={formData.currentPhysicalAddressCityOrTown}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>ZIP Code:</label>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='currentPhysicalAddressZipCode'
    value={formData.currentPhysicalAddressZipCode}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>Apartment:</label>
  <select
    className='w-[100px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    name='currentPhysicalAddressApartment'
    value={formData.currentPhysicalAddressApartment}
    onChange={handleInputChange}
  >
    <option value='No'>No</option>
    <option value='Ste'>Ste</option>
    <option value='Flr'>Flr</option>
    <option value='Apt'>Apt</option>
  </select>
</div>


{formData.currentPhysicalAddressApartment !== 'No'
  &&
  <div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>Enter the number here:</label>
  <input
    className='w-[100px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6'
    type='text'
    name='currentPhysicalAddressNumber'
    value={formData.currentPhysicalAddressNumber}
    onChange={handleInputChange}
  />
</div>}

<div className='flex gap-10 mt-10'>
  <label className='w-[200px] text-blue-500'>State (or Territory):</label>
    <select
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-14'
    id='stateOrTerritory'
    name='stateOrTerritory'
    value={formData.stateOrTerritory}
    onChange={handleInputChange}
  >
    <option value=''>Select a State or Territory</option>
    {code.map((stateOrTerritory) => (
      <option key={stateOrTerritory} value={stateOrTerritory}>
        {stateOrTerritory}
      </option>
    ))}
  </select>
  
  
</div>


<hr className='bg-blue-100 h-1 w-full '/>

<div className='mt-10'>
  <h1 className='text-blue-600 font-semibold text-xl'>Contact Information</h1>
   <p className='text-sm'>Enter phone numbers and an email address where the U.S. embassy or consulate may reach you.

</p>
</div>
<div  className='flex mt-10'>
      <label htmlFor="countrySelect" className='w-[200px] text-blue-500' >Day time phone number</label>
   
      <select
        id="countrySelect"
        value={formData.otherInformationdaytimePhoneNumber}
        onChange={handleInputChange}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={formData.PHnumber}
        onChange={handleInputChange}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
    
    </div>

    <div className='flex  mt-10'>
      <label htmlFor="countrySelect" className='w-[200px] text-blue-500'>Day time phone number</label>
      
      <select
        id="countrySelect"
        value={formData.otherInformationdaytimePhoneNumber}
        onChange={handleInputChange}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={formData.PHnumber}
        onChange={handleInputChange}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
  
    </div>

    <div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">E-mail</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-6"
    type="email"
    name="email"
    value={formData.email}
    onChange={handleInputChange}
  />
</div>


<hr  className='bg-blue-600 h-1 mt-10' />




<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Alien Registration Number (A-Number)</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-6"
    type="text"
    name="alienRegistrationNumber"
    value={formData.alienRegistrationNumber}
    onChange={handleInputChange}
  />
</div>



<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Social Security Number</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-6"
    type="text"
    name="socialSecurityNumber"
    value={formData.socialSecurityNumber}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">USCIS Online Account Number (if any)</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-6"
    type="text"
    name="uscisOnlineAccountNumber"
    value={formData.uscisOnlineAccountNumber}
    onChange={handleInputChange}
  />
</div>
<hr  className='h-1 bg-blue-600 mt-10'/>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Date of Birth</label>
  <input
    className="w-[300px] border outline-none border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-6"
    type="date"
    name="dateOfBirth"
    value={formData.dateOfBirth}
    onChange={handleInputChange}
  />
</div>


<div className='flex mt-10 gap-2'>
      <label htmlFor="countryOfBirth" className="w-[200px] text-blue-500">Country of Birth:</label>
      <select
 className="w-[300px] outline-none border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-10"
        id="countryOfBirth"
        name="countryOfBirth"
        value={formData.countryOfBirth}
        onChange={handleInputChange}
      >
        <option value="">Select a Country</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
    </div>

    <div className='flex mt-10 gap-30'>
  <label className='text-blue-500'>Gender:</label>
  <label className='text-blue-600 '>
    <input
      type="radio"
      name="gender"
      value="Male"
      checked={formData.gender === 'Male'}
      onChange={handleInputChange}
    />
    Male
  </label>
  <label className='text-blue-600 '>
    <input
      type="radio"
      name="gender"
      value="Female"
      checked={formData.gender === 'Female'}
      onChange={handleInputChange}
    />
    Female
  </label>
</div>
<hr  className='bg-blue-600 h-1 mt-10'/>

<div className='mt-10'>
  <h1 className='text-blue-600 text-xl font-semibold'>Citizenship or Nationality</h1>
  <p className='text-sm'>Select the name of the country where you are currently a citizen or national. If you are stateless, select the name of the country where you were last a citizen or national. If you are a citizen or national of more than one country, select the name of the country that issued your last passport.</p>
</div>


<div className='flex mt-10 gap-2'>
      <label htmlFor="countryOfCitizenship" className="w-[200px] text-blue-500">Country of Citizenship:</label>
      <select
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded ml-6"
        id="countryOfCitizenship"
        name="countryOfCitizenship"
        value={formData.countryOfCitizenship}
        onChange={handleInputChange}
      >
        <option value="">Select a Country</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
    </div>


                 
  
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
        
      </div>
    )
}

export default BasicInformation131A