import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const MoralCharacter = () => {
  const navigate = useNavigate()

  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/MoralCharacter2")
  }

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's General Eligibility and Inadmissibility
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 9 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            The applicant should answer each of the following questions honestly
            and accurately. If you believe a truthful answer could create a
            problem on the application or you are unsure of your answer, we
            encourage the applicant to speak to an immigration attorney before
            filing this application.
          </span>
        </div>
        <form action='' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been denied admission to the United States?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='deniedAddmission'
                  value="Yes"
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='deniedAddmission'
                  value="No"
                  onChange={handleInputChange}
                />
                No
              </label>
            </div>
          </div>

          {formData.deniedAddmission === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER been denied a visa to the United States?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='denidedVisa'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='denidedVisa'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.denidedVisa === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER worked in the United States without
                  authorization?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='workedInUsWithoutAuth'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='workedInUsWithoutAuth'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.workedInUsWithoutAuth === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER violated the terms or conditions of your
                  nonimmigrant status?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='violatedTermsAndCond'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='violatedTermsAndCond'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.violatedTermsAndCond === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Are you presently or have you EVER been in removal, exclusion,
                  rescission, or deportation proceedings?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='deportationProcessing'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='deportationProcessing'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.deportationProcessing === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER been issued a final order of exclusion,
                  deportation, or removal?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='issuedFinalOrder'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='issuedFinalOrder'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.issuedFinalOrder === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER had a prior final order of exclusion,
                  deportation, or removal reinstated?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='priorIssuedFinalOrder'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='priorIssuedFinalOrder'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.priorIssuedFinalOrder === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER held lawful permanent resident status which was
                  later rescinded?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='lawfulPermanentResident'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='lawfulPermanentResident'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.lawfulPermanentResident === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER been granted voluntary departure by an
                  immigration officer or an immigration judge but failed to
                  depart within the allotted time?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='voluntaryDeparture'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='voluntaryDeparture'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.voluntaryDeparture === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER applied for any kind of relief or protection
                  from removal, exclusion, or deportation?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='appliedForRelief'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='appliedForRelief'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.voluntaryDeparture === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Have you EVER been a J nonimmigrant exchange visitor who was
                  subject to the two-year foreign residence requirement?
                </label>
                <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='nonImmigrantExchangeVisitor'
                      value="Yes"
                      onChange={handleInputChange}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='nonImmigrantExchangeVisitor'
                      value="No"
                      onChange={handleInputChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <hr className='h-1 bg-blue-600 text-white mt-10' />
          <h1 className='flex text-blue-600 font-semibold text-xl'>
            Group 2: Criminal Acts and Violations
          </h1>
          <span className='mt-2 font-thin'>
            You must answer "Yes" to any question that applies to you, even if
            your records were sealed or otherwise cleared, or even if anyone,
            including a judge, law enforcement officer, or attorney, told you
            that you no longer have a record. You must also answer "Yes" to the
            following questions whether the action or offense occurred here in
            the United States or anywhere else in the world.
          </span>

{formData.nonImmigrantExchangeVisitor === "No" ? <>
<div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been arrested, cited, charged, or detained for any
              reason by any law enforcement official (including but not limited
              to any U.S. immigration official or any official of the U.S. armed
              forces or U.S. Coast Guard)?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='arrested'
                  value="Yes"
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='arrested'
                  value="No"
                  onChange={handleInputChange}
                />
                No
              </label>
            </div>
          </div>
</>:<></>}

{formData.arrested === "No" ? <>
<div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER committed a crime of any kind (even if you were not
              arrested, cited, charged with, or tried for that crime)?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='cimmittedCrime'
                  value="Yes"
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='cimmittedCrime'
                  value="No"
                  onChange={handleInputChange}
                />
                No
              </label>
            </div>
          </div>
</>:<></>}
          
          {formData.cimmittedCrime === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER pled guilty to or been convicted of a crime or
              offense (even if the violation was subsequently expunged or sealed
              by a court, or if you were granted a pardon, amnesty, a
              rehabilitation decree, or other act of clemency)?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='pledGuilty'
                  value="Yes"
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='pledGuilty'
                  value="No"
                  onChange={handleInputChange}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}

          {formData.pledGuilty === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been ordered punished by a judge or had conditions
              imposed on you that restrained your liberty (such as a prison
              sentence, suspended sentence, house arrest, parole, alternative
              sentencing, drug or alcohol treatment, rehabilitative programs or
              classes, probation, or community service)?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='punished'
                  value="Yes"
                  onChange={handleInputChange}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='punished'
                  value="No"
                  onChange={handleInputChange}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
                {formData.punished === "No" ? <>
                <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been a defendant or the accused in a criminal
              proceeding (including pre-trial diversion, deferred prosecution,
              deferred adjudication, or any withheld adjudication)?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='defendantOrAccused'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.defendantOrAccused === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='defendantOrAccused'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.defendantOrAccused === "No"}
                />
                No
              </label>
            </div>
          </div>
                </>:<></>}

                {formData.defendantOrAccused === "No" ? <>
                <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER violated (or attempted or conspired to violate) any
              controlled substance law or regulation of a state, the United
              States, or a foreign country?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='controlledSubstanceViolation'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.controlledSubstanceViolation === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='controlledSubstanceViolation'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.controlledSubstanceViolation === "No"}
                />
                No
              </label>
            </div>
          </div>
                </>:<></>}
          
          
          {formData.controlledSubstanceViolation === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been convicted of two or more offenses (other than
              purely political offenses) for which the combined sentences to
              confinement were five years or more?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='multipleConvictions'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.multipleConvictions === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='multipleConvictions'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.multipleConvictions === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          

          {formData.multipleConvictions === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER illicitly (illegally) trafficked or benefited from
              the trafficking of any controlled substances, such as chemicals,
              illegal drugs, or narcotics?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='controlledSubstanceTrafficking'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.controlledSubstanceTrafficking === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='controlledSubstanceTrafficking'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.controlledSubstanceTrafficking === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.controlledSubstanceTrafficking === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER knowingly aided, abetted, assisted, conspired, or
              colluded in the illicit trafficking of any illegal narcotic or
              other controlled substances?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='aidedTrafficking'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.aidedTrafficking === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='aidedTrafficking'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.aidedTrafficking === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.aidedTrafficking === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Are you the spouse, son, or daughter of a foreign national who
              illicitly trafficked or aided (or otherwise abetted, assisted,
              conspired, or colluded) in the illicit trafficking of a controlled
              substance, such as chemicals, illegal drugs, or narcotics and you
              obtained, within the last five years, any financial or other
              benefit from the illegal activity of your spouse or parent,
              although you knew or reasonably should have known that the
              financial or other benefit resulted from the illicit activity of
              your spouse or parent?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='familyMemberTraffickingBenefit'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.familyMemberTraffickingBenefit === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='familyMemberTraffickingBenefit'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.familyMemberTraffickingBenefit === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.familyMemberTraffickingBenefit === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER engaged in prostitution or are you coming to the
              United States to engage in prostitution?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='prostitutionEngagement'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.prostitutionEngagement === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='prostitutionEngagement'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.prostitutionEngagement === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.prostitutionEngagement === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER directly or indirectly procured (or attempted to
              procure) or imported prostitutes or persons for the purpose of
              prostitution?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='prostitutionProcurement'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.prostitutionProcurement === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='prostitutionProcurement'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.prostitutionProcurement === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.prostitutionProcurement === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER received any proceeds or money from prostitution?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='prostitutionProceedsReceived'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.prostitutionProceedsReceived === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='prostitutionProceedsReceived'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.prostitutionProceedsReceived === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.prostitutionProceedsReceived === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Do you intend to engage in illegal gambling or any other form of
              commercialized vice, such as prostitution, bootlegging, or the
              sale of child pornography, while in the United States?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='illegalActivitiesIntent'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.illegalActivitiesIntent === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='illegalActivitiesIntent'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.illegalActivitiesIntent === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.illegalActivitiesIntent === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER, while serving as a foreign government official,
              been responsible for or directly carried out violations of
              religious freedoms?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='religiousFreedomViolations'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.religiousFreedomViolations === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='religiousFreedomViolations'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.religiousFreedomViolations === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          {formData.religiousFreedomViolations === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER induced by force, fraud, or coercion (or otherwise
              been involved in) the trafficking of persons for commercial sex
              acts?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='traffickingOfPersons'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.traffickingOfPersons === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='traffickingOfPersons'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.traffickingOfPersons === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          {formData.traffickingOfPersons === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER trafficked a person into involuntary servitude,
              peonage, debt bondage, or slavery? Trafficking includes
              recruiting, harboring, transporting, providing, or obtaining a
              person for labor or services through the use of force, fraud, or
              coercion.
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='involuntaryServitudeTrafficking'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.involuntaryServitudeTrafficking === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='involuntaryServitudeTrafficking'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.involuntaryServitudeTrafficking === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}

          {formData.involuntaryServitudeTrafficking === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER knowingly aided, abetted, assisted, conspired, or
              colluded with others in trafficking persons for commercial sex
              acts or involuntary servitude, peonage, debt bondage, or slavery?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='traffickingCollusion'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.traffickingCollusion === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='traffickingCollusion'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.traffickingCollusion === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.traffickingCollusion === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Are you the spouse, son, or daughter of a foreign national who
              engaged in the trafficking of persons and have received or
              obtained, within the last five years, any financial or other
              benefits from the illicit activity of your spouse or your parent,
              although you knew or reasonably should have known that this
              benefit resulted from the illicit activity of your spouse or
              parent?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='familyTraffickingBenefit'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.familyTraffickingBenefit === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='familyTraffickingBenefit'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.familyTraffickingBenefit === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          
          {formData.familyTraffickingBenefit === "No" ? <>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER engaged in money laundering or have you EVER
              knowingly aided, assisted, conspired, or colluded with others in
              money laundering or do you seek to enter the United States to
              engage in such activity?
            </label>
            <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='moneyLaundering'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.moneyLaundering === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='moneyLaundering'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.moneyLaundering === "No"}
                />
                No
              </label>
            </div>
          </div>
          </>:<></>}
          

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default MoralCharacter
