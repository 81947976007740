import Header from "./components/Header";
import "./App.css";
import FormFotter from "./components/FormFotter";
import Footer from "./components/Footer.jsx";
import { FormProvider } from "./UseContext/UseContext";
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import PageOneFormTwo from "./Pages/PageOneFormTwo";
import PageOneFormThree from "./Pages/PageOneFormThree";
import Freedom from "./components/Freedom.jsx";
import FormOne from "./Pages/Page3/FormOne";
import Form2 from "./Pages/Page3/FormOne.jsx";
import PageOneForm from "./Pages/PageOneForm";
import { PageThreeDataProvider } from "./UseContext/useContextPageThree";
import Eligiblity from "./Pages/I-129F/Eligiblity";
import { FormProviderI129F } from "./UseContext/UseContextForI-I29F";
import Pinfo from "./Pages/I-129F/Pinfo";
import InfoaboutParent from "./Pages/I-129F/InfoaboutParent";
import PetitionerMailingAddress from "./Pages/I-129F/PetitionerMailingAddress";
// import Form3 from "./Pages/form2/Form3.jsx"

// import Eligibilityforoneseven from "./Pages/I-1765/Eligibilityforoneseven";
import { PermissionProvider } from "./UseContext/ContextFor1762";
import ApplicantInformation from "./Pages/I-1765/ApplicantInformation";
import ImmigranteHistory from "./Pages/I-1765/ImmigrantHistory"


import Form3 from "./Pages/AR-11/AR-11.jsx"
import Eligibilityforoneseven from "./Pages/I-1765/Eligibilityforoneseven";

import I90 from "./Pages/I-90/I-90.jsx"
import I901 from "./Pages/I-90/I-902nd.jsx"
import AddressInformation from "./Pages/I-1765/AddressInformation";
import FinalDetail from "./Pages/I-1765/FinalDetail";
import EligiblityI821D from "./Pages/I821-D/EligiblityI821D";
import PublicDafetyInfo from "./Pages/I821-D/PublicDafetyInfo";
import ApplicantProfile from "./Pages/I821-D/ApplicantProfile";
import ImmigrationHtry821D from "./Pages/I821-D/ImmigrationHtry821D";
import TravelInformation from "./Pages/I821-D/TravelInformation";
import Eligibility864 from "./Pages/I-864/Eligibility864";
import Petitioner864 from "./Pages/I-864/Petitioner864";
import BasicInformation from "./Pages/I-864/BasicInformation";
import PrincipleImmigrantInfo from "./Pages/I-864/PrincipleImmigrantInfo";
import OtherFamilyMember from "./Pages/I-864/OtherFamilyMember";
import EligibilityI30 from "./Pages/I-130/EligibilityI30";
import TravelInformation130 from "./Pages/I-130/TravelInformation130";
import ApplicantInformation130 from "./Pages/I-130/ApplicantInformation130";
import EndPage from "./Pages/I-130/EndPage";
import Eligibility131 from "./Pages/I-131/Eligibility131";
import TravelInformation131 from "./Pages/I-131/TravelInformation131";
import ApplicatInfromation131 from "./Pages/I-131/ApplicatInfromation131";
import EndPage131 from "./Pages/I-131/EndPage131";
import SecureCheckout from "./components/SecureCheckout";
import FreedomOfinformation from "./Pages/G-639/FreedomOfinformation";
import SubjectInformation from "./Pages/G-639/SubjectInformation";
import SubjectFile from "./Pages/G-639/SubjectFile";
import Eligibility131A from "./Pages/I-131A/Eligibility131A";
import BasicInformation131A from "./Pages/I-131A/BasicInformation131A";
import TravelInformation131A from "./Pages/I-131A/TravelInformation131A";
import FinalDetail131A from "./Pages/I-131A/FinalDetail131A";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import Profile from "./Pages/Profile/profile.jsx";
import Profiler from "./Pages/Profile/Profiler";
import I9013 from "./Pages/I-90/I-903rd.jsx"
import I9014 from "./Pages/I-90/I-904.jsx"
import I905 from "./Pages/I-90/I-905.jsx"
import I864 from "./Pages/I-864/I864.jsx"
import Eligibility565 from "./Pages/N-565/Eligibility565";
import I8642 from "./Pages/I-864/I8642.jsx"
import I8643 from "./Pages/I-864/I8643.jsx"
import ApplicationInfoN565 from "./Pages/N-565/ApplicationInfoN565";
import I8645 from "./Pages/I-864/I8645.jsx"
import I8646 from "./Pages/I-864/I8646.jsx"
import I8647 from "./Pages/I-864/I8647.jsx"
import AR11 from "./Pages/AR-11/AR-11.jsx"
import { FormProvider11 } from './Pages/I-864/FormContext.js';
import OriginalCertificate from "./Pages/N-565/OriginalCertificate";
import LastPage from "./Pages/N-565/LastPage";
import Eligibility134 from "./Pages/I-134/Eligibility134.jsx";
import BenificiaryInformation from "./Pages/I-134/BenificiaryInformation.jsx";
import PrepareAndInterpreter from "./Pages/I-134/PrepareAndInterpreter.jsx";
import FinancialSupport from "./Pages/I-134/FinancialSupport.jsx";


import I8648 from "./Pages/I-864/I8648.jsx";
import I8649 from "./Pages/I-864/I8649.jsx";
import Eligibility751 from "./Pages/I-751/Eligibility751.jsx";
import InformationAboutYou from "./Pages/I-751/InformationAboutYou.jsx";
import Progress from "./Pages/I-751/Progress.jsx";
import Halfway from "./Pages/I-751/Halfway.jsx";
import ChildrenInformation from "./Pages/I-751/ChildrenInformation.jsx";
import PreparerFor751 from "./Pages/I-751/PreparerFor751.jsx";
import EligibilityN400 from "./Pages/N-400/EligibilityN400.jsx";
import TimeAbroad from "./Pages/N-400/TimeAbroad.jsx";
import PetionerEmploymentHistory from "./Pages/I-129F/PetionerEmploymentHistory.jsx";
import BenificairyInformationI129F from "./Pages/I-129F/BenificairyInformationI129F.jsx";
import BenificiaryFamilyI129F from "./Pages/I-129F/BenificiaryFamilyI129F.jsx";
import BenificiaryAddressHistory from "./Pages/I-129F/BenificiaryAddressHistory.jsx";
import HasholdCalculation from "./Pages/I-864/HasholdCalculation.jsx";
import FederalIncomeTax from "./Pages/I-864/FederalIncomeTax.jsx";
import AapplicatInfromationN400 from "./Pages/N-400/AapplicatInfromationN400.jsx";
import Eligibility485 from "./Pages/I-485/Eligibility485.jsx";
import ApplicantInformation485 from "./Pages/I-485/ApplicantInformation485.jsx";
import ApplicantImmigrationHistory from "./Pages/I-485/ApplicantImmigrationHistory.jsx";
import ApplicationAddresHistory from "./Pages/I-485/ApplicationAddresHistory.jsx";
import ApplicantInformationInformation from "./Pages/I-485/ApplicantInformationInformation.jsx";
import ApplicationFamilyInfo from "./Pages/I-485/ApplicationFamilyInfo.jsx";
import ApplicationFamilyInfo2 from "./Pages/I-485/ApplicationFamilyInfo2.jsx";
import MoralCharacter from "./Pages/I-485/MoralCharacter.jsx";
import MoralCharacter2 from "./Pages/I-485/MoralCharacter2.jsx";
import MoralCharacter3 from "./Pages/I-485/MoralCharacter3.jsx";
import ApplicantsParticipation from "./Pages/I-485/ApplicantsParticipation.jsx";
import FinalDetails from "./Pages/I-485/FinalDetails.jsx";
import CreateMyFiles from "./Pages/I-485/CreateMyFiles.jsx";



function App() {

  const handleLogin = (provider) => {
    window.location.href = `http://localhost:5000/api/auth/${provider}`;
  }
  return (
    <div className="App">

      <ToastContainer autoClose={3000} />
      {/* <FormProvider> */}
      {/* <PermissionProvider> */}
      {/* <PageThreeDataProvider>
          */}
      {/* <PageThreeDataProvider> */}
      {/* <FormProviderI129F> */}
      {/* <FormProvider11> */}
      <BrowserRouter>

        <Routes>
          <Route path="/FormOne" element={<FormOne />} />
          <Route path="/" element={<Freedom />} />
          <Route path="/pageoneformtwo" element={<PageOneFormTwo />} />
          <Route path="/FormThree" element={<PageOneFormThree />} />
          {/* <Route path="/freedom" element={<Freedom />} /> */}
          <Route path="/Form2" element={<Form2 />} />
          {/* <Route path="/Form3" element={<Form3 />} /> */}
          <Route path="/Form3" element={<Form3 />} />
          <Route path="/profile" element={<Profiler />} />
          <Route path="/I90" element={<I90 />} />
          <Route path="/I901" element={<I901 />} />
          <Route path="/AR11" element={<AR11 />} />

          <Route path="/FormOne" element={<FormOne />} />
          <Route path="/firtspage" element={<PageOneForm />} />
          <Route path="/pageoneformtwo" element={<PageOneFormTwo />} />
          <Route path="/FormThree" element={<PageOneFormThree />} />
          <Route path="/freedom" element={<Freedom />} />
          <Route path="/Form2" element={<Form2 />} />
          <Route path="/Form3" element={<Form3 />} />
          <Route path="/profile" element={<Profiler />} />
          <Route path="/I90" element={<I90 />} />
          <Route path="/I901" element={<I901 />} />
          <Route path="/I9013" element={<I9013 />} />
          <Route path="/I9014" element={<I9014 />} />
          <Route path="/I905" element={<I905 />} />
          <Route path="/I864" element={<I864 />} />
          <Route path="/I8643" element={<I8643 />} />
          <Route path="/I8642" element={<I8642 />} />
          <Route path="/I8645" element={<I8645 />} />
          <Route path="/I8646" element={<I8646 />} />
          <Route path="/I8647" element={<I8647 />} />
          <Route path="/I8648" element={<I8648 />} />
          <Route path="/I8649" element={<I8649 />} />

          <Route path="/eligible" element={<Eligiblity />} />
          <Route path="/Pinfo" element={< Pinfo />} />
          <Route path="/infoaboutparent" element={<InfoaboutParent />} />
          <Route path="/PetitionerMailingAddress" element={<PetitionerMailingAddress />} />



          <Route path="/Eligibilityforoneseven" element={<Eligibilityforoneseven />} />
          <Route path="/Applicantinfo" element={<ApplicantInformation />} />
          <Route path="/ImmigranteHistory" element={<ImmigranteHistory />} />
          <Route path="/ApplicatInfo" element={<AddressInformation />} />
          <Route path="/finaldetail" element={<FinalDetail />} />



          <Route path="/Eligiblityfor821D" element={<EligiblityI821D />} />
          <Route path="/publicsafty" element={<PublicDafetyInfo />} />
          <Route path="/ApplicantProfile" element={<ApplicantProfile />} />
          <Route path="/Immigration821" element={<ImmigrationHtry821D />} />
          <Route path="/TravelInfo" element={<TravelInformation />} />




          {/* <Route path="/Eligibility864" element={<Eligibility864 />} />
          <Route path="/Petitioner864" element={<Petitioner864 />} />
          <Route path="/basicinfo" element={<BasicInformation />} />
          <Route path="/ImmigrantInfo" element={<PrincipleImmigrantInfo />} />
          <Route path="/otherMember" element={<OtherFamilyMember />} /> */}



            
            <Route path="/eligibleI29F" element={<Eligiblity/>}/>
            <Route  path="/Pinfo"   element={< Pinfo/>}/>
            <Route path="/infoaboutparent" element={<InfoaboutParent/>}/>
            <Route path="/PetitionerMailingAddress" element={<PetitionerMailingAddress/>}/>
            <Route path ="/PetionerEmployemnetHistory" element={<PetionerEmploymentHistory/>}/>
            <Route  path="/BenificiaryInformationI129F" element={<BenificairyInformationI129F/>}/>
            <Route path ="/BenificaryFamilyI129F"        element={<BenificiaryFamilyI129F/>}/>
            <Route path="/BeniciaryAdressHistory"         element={<BenificiaryAddressHistory/>}/>
            
            
            
            <Route path="/Eligibilityforoneseven" element={<Eligibilityforoneseven/>}/>
            <Route path="/Applicantinfo"         element={<ApplicantInformation/>}/>
            <Route path="/ImmigranteHistory" element={<ImmigranteHistory/>}/>
            <Route  path="/ApplicatInfo"      element={<AddressInformation/>}/>
            <Route path="/finaldetail"          element={<FinalDetail/>}/>
            
            
            
            {/* <Route path="/Eligiblityfor821D"  element={<EligiblityI821D/>}/>
            <Route path="/publicsafty"         element={<PublicDafetyInfo/>}/>
            <Route path="/ApplicantProfile"     element={<ApplicantProfile/>}/>  
            <Route path="/Immigration821"          element={<ImmigrationHtry821D/>}/>
            <Route path="/TravelInfo"               element={<TravelInformation/>}/>
             */}
            
            
            
             <Route path="/Eligibility864"           element={<Eligibility864/>}/>
             <Route path="/Petitioner864"            element={<Petitioner864/>}/>
             <Route path="/basicinfo"                 element={<BasicInformation/>}/>
             <Route path="/ImmigrantInfo"              element={<PrincipleImmigrantInfo/>}/>
             <Route path="/otherMember"               element={<OtherFamilyMember/>}/>
             <Route path='/householdCalculation'        element={<HasholdCalculation/>}/>
             <Route path='/FederalIncomeTax'             element={<FederalIncomeTax/>}/>
              
              
              
              <Route path="/EligibiltyI30"             element={<EligibilityI30/>}/>
              <Route path="/travel130"                 element={<TravelInformation130/>}/>
               <Route path="/Applicantinfo130"          element={<ApplicantInformation130/>}/>
               <Route path="/endpage"                    element={<EndPage/>}/>
               
               

               <Route path="/Eligibility485"                element={<Eligibility485/>}/>
               <Route path="/ApplicantInformation485"       element={<ApplicantInformation485/>}/>
               <Route path="/ApplicationImmigrationHistory"  element={<ApplicantImmigrationHistory/>}/>
               <Route path="/ApplicationAddresHistory"        element={<ApplicationAddresHistory/>}/>
               <Route path="/ApplicantInformationHistory485"            element={<ApplicantInformationInformation/>}/>
               <Route path="/ApplicationFamilyInfo" element={<ApplicationFamilyInfo />}/>
               <Route path="/ApplicationFamilyInfo2" element={<ApplicationFamilyInfo2 />}/>
               <Route path="/MoralCharacter" element={<MoralCharacter />}/>
               <Route path="/MoralCharacter2" element={<MoralCharacter2 />}/>
               <Route path="/MoralCharacter3" element={<MoralCharacter3 />}/>
               <Route path="/ApplicantsParticaption" element={<ApplicantsParticipation />}/>
               <Route path="/FinalDetails" element={<FinalDetails />}/>
               <Route path="/CreateMyFiles" element={<CreateMyFiles />}/>
               
               
               <Route path="/EligibiltyI31"             element={<Eligibility131/>}/>
              <Route path="/travel131"                 element={<TravelInformation131/>}/>
               <Route path="/Applicantinfo131"          element={<ApplicatInfromation131/>}/>
               <Route path="/endpage131"                    element={<EndPage131/>}/>
              
              
              
              
               <Route path="/securecheckout"               element={<SecureCheckout/>}/>
              
              <Route path="/freedomofinformation"          element={<FreedomOfinformation/>}/>
               <Route path="/subjectinformation"              element={<SubjectInformation/>}/>
              <Route path="/SubjectFile"                     element={<SubjectFile/>}/>
              
              
              
               <Route path="/Eligibility131A"                 element={<Eligibility131A/>}/>
               <Route  path="/BasicInformation"               element={<BasicInformation131A/>}/>
               <Route path="/travelinfo131A"                    element={<TravelInformation131A/>}/>
               <Route path="/finaldetail131A"                        element={<FinalDetail131A/>}/>
               
               
               
               
               <Route path='/eligibilityn565'                      element={<Eligibility565/>}/>
                <Route path='/applicationinfon565'                element={<ApplicationInfoN565/>}/>
                <Route path='/originalcertificate'               element={<OriginalCertificate/>}/>
                <Route path='/lastpage'                         element={<LastPage/>}/>
                
                
                
                <Route path='/eligibility134'                    element={<Eligibility134/>}/>
                <Route path='/beneficiaryinformation'               element={<BenificiaryInformation/>}/>
                 <Route path='/prepearerandinterpreter'             element={<PrepareAndInterpreter/>}/>
                 <Route path='/financialsupport'                     element={<FinancialSupport/>}/>

                 <Route path='/eligibility751'                       element={<Eligibility751/>}/>
                 <Route path='/informationabout'                      element={<InformationAboutYou/>}/>
                 <Route path='/progress'                              element={<Progress/>}/>
                 <Route path='/halfway'                               element={<Halfway/>}/>
                 <Route path='/child'                                 element={<ChildrenInformation/>}/>
                 <Route path='/preparer751'                           element={<PreparerFor751/>}/>
                
                
                
                <Route path='/eligibiltyn400'                          element={<EligibilityN400/>}/>
               {/* <Route path='/abroadtime'                               element={<TimeAbroad/>}/> */}
               <Route path="/ApplicantInformationN400"                   element={<AapplicatInfromationN400/>}/>
         
         
          <Route path="/EligibiltyI30" element={<EligibilityI30 />} />
          <Route path="/travel130" element={<TravelInformation130 />} />
          <Route path="/Applicantinfo130" element={<ApplicantInformation130 />} />
          <Route path="/endpage" element={<EndPage />} />




          <Route path="/EligibiltyI31" element={<Eligibility131 />} />
          <Route path="/travel131" element={<TravelInformation131 />} />
          <Route path="/Applicantinfo131" element={<ApplicatInfromation131 />} />
          <Route path="/endpage131" element={<EndPage131 />} />




          <Route path="/securecheckout" element={<SecureCheckout />} />

          <Route path="/freedomofinformation" element={<FreedomOfinformation />} />
          <Route path="/subjectinformation" element={<SubjectInformation />} />
          <Route path="/SubjectFile" element={<SubjectFile />} />



          <Route path="/Eligibility131A" element={<Eligibility131A />} />
          <Route path="/BasicInformation" element={<BasicInformation131A />} />
          <Route path="/travelinfo131A" element={<TravelInformation131A />} />
          <Route path="/finaldetail131A" element={<FinalDetail131A />} />




          {/* <Route path='/eligibilityn565' element={<Eligibility565 />} />
          <Route path='/applicationinfon565' element={<ApplicationInfoN565 />} />
          <Route path='/originalcertificate' element={<OriginalCertificate />} />
          <Route path='/lastpage' element={<LastPage />} /> */}



          <Route path='/eligibility134' element={<Eligibility134 />} />
          <Route path='/beneficiaryinformation' element={<BenificiaryInformation />} />
          <Route path='/prepearerandinterpreter' element={<PrepareAndInterpreter />} />
          <Route path='/financialsupport' element={<FinancialSupport />} />

          {/* <Route path='/eligibility751' element={<Eligibility751 />} />
          <Route path='/informationabout' element={<InformationAboutYou />} />
          <Route path='/progress' element={<Progress />} />
          <Route path='/halfway' element={<Halfway />} />
          <Route path='/child' element={<ChildrenInformation />} />
          <Route path='/preparer751' element={<PreparerFor751 />} /> */}



          <Route path='/eligibiltyn400' element={<EligibilityN400 />} />
          {/* <Route path='/abroadtime' element={<TimeAbroad />} /> */}

        </Routes>
      </BrowserRouter>
      {/* </FormProvider11>
        </FormProviderI129F> */}
      {/* </PageThreeDataProvider>
      </PermissionProvider> */}
      {/* </FormProvider> */}
      <Footer />
    </div>
  );
}

export default App;