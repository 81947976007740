import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';

const OtherFamilyMember = () => {

     const navigate=useNavigate()
    const { formData, handleInputChange ,code ,nameSets ,handleAddAnother ,handleRemove ,handleChange , handleSubmitI864} = useFormContext();

    console.log(formData.principalImmigrantGivenName)
    console.log(formData.principalImmigrantapartment)


   const HandleSubmit =(e)=>{
        e.preventDefault()
        navigate("/householdCalculation")
   }
return (
  <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
  <Header />
  <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
  <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
        <span className='mt-4 font-bold text-xl ml-2 text-black'>
        Address Information
        </span>
        <div className='flex justify-between items-center gap-4'>
          <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
            <button className='mr-2'>Page 1 of 3</button>
            <button><MdArrowDropDown /></button>
          </div>
          <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
            <AiOutlineShoppingCart />
          </div>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>
      First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
      </span>
    </div>
    <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

    <div>
      <div  className='flex flex-col  mt-10'>
        <label htmlFor="sponsoringAdditionalFamilyMembers" className=" bg-blue-500  flex p-4 text-white ">Will you be sponsoring any additional family members?</label>
        <div className='flex bg-blue-100 gap-5 p-4 text-blue-700'>
          <label>
            <input
              type="radio"
              id="sponsoringAdditionalFamilyMembersYes"
              name="sponsoringAdditionalFamilyMembers"
              value="Yes"
              checked={formData.sponsoringAdditionalFamilyMembers === 'Yes'}
              onChange={handleInputChange}
            /> Yes
          </label>
          <label>
            <input
              type="radio"
              id="sponsoringAdditionalFamilyMembersNo"
              name="sponsoringAdditionalFamilyMembers"
              value="No"
              checked={formData.sponsoringAdditionalFamilyMembers === 'No'}
              onChange={handleInputChange}
            /> No
          </label>
        </div>
      </div>

      
        <div>
          <div className='flex flex-col mt-10'>
            <label htmlFor="immigratingAtTheSameTime" className=" bg-blue-500  flex p-4 text-white">Will the additional family members immigrate at the same time or within six months of the principal immigrant?</label>
            <div  className='flex bg-blue-100 gap-5 p-4 text-blue-700'>
              <label>
                <input
                  type="radio"
                  id="immigratingAtTheSameTimeYes"
                  name="immigratingAtTheSameTime"
                  value="Yes"
                  checked={formData.immigratingAtTheSameTime === 'Yes'}
                  onChange={handleInputChange}
                /> Yes
              </label>
              <label>
                <input
                  type="radio"
                  id="immigratingAtTheSameTimeNo"
                  name="immigratingAtTheSameTime"
                  value="No"
                  checked={formData.immigratingAtTheSameTime === 'No'}
                  onChange={handleInputChange}
                /> No
              </label>
            </div>
          </div>

          <div className='flex flex-col mt-10'>
            <label htmlFor="numberOfAdditionalFamilyMembers" className=" bg-blue-500  flex p-4 text-white">How many additional family members will you sponsor?</label>
            <div className='flex bg-blue-100 gap-5 p-4 text-blue-700'>
            <select
            className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
              id="numberOfAdditionalFamilyMembers"
              name="numberOfAdditionalFamilyMembers"
              value={formData.numberOfAdditionalFamilyMembers}
              onChange={handleInputChange}
            >
              <option value="None">None</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="MoreThan10">More than 10</option>
            </select>
          </div>
          </div>
        </div>
    </div>

             
    <div className="mt-10">
      {nameSets.map((nameSet, index) => (
        <div key={nameSet.id} className="bg-slate-50 rounded border border-blue-200 mt-10">
          {index > 0 && (
            <div className="flex w-full justify-between ml-2">
              <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
              <button
                type="button"
                onClick={() => handleRemove(nameSet.id)}
                className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
              >
                Remove
                <img src={crosspic} alt="" />
              </button>
            </div>
          )}

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`givenName-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Given Name (First Name): <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`givenName-${nameSet.id}`}
              name={`givenName-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.givenName || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'givenName')}
            />
          </div>

          <div  className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`middleName-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Middle Name (First Name): <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`middleName-${nameSet.id}`}
              name={`middleName-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.middleName || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'middleName')}
            />
          </div>

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`familyName-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Family Name (Last Name): <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`familyName-${nameSet.id}`}
              name={`familyName-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.familyName || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'familyName')}
            />
          </div>

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`relationship-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Relationship to Immigrant: <span className="text-red-500">*</span>
            </label>
            <select
              id={`relationship-${nameSet.id}`}
              name={`relationship-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.relationship || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'relationship')}
            >
              <option value="">Select Relationship</option>
              <option value="Spouse">Spouse</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
            </select>
          </div>

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`dob-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Date of Birth: <span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              id={`dob-${nameSet.id}`}
              name={`dob-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.dob || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'dob')}
            />
          </div>

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`alienRegistrationNumber-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Alien Registration Number: <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`alienRegistrationNumber-${nameSet.id}`}
              name={`alienRegistrationNumber-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.alienRegistrationNumber || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'alienRegistrationNumber')}
            />
          </div>

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`socialSecurityNumber-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              Social Security Number: <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`socialSecurityNumber-${nameSet.id}`}
              name={`socialSecurityNumber-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.socialSecurityNumber || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'socialSecurityNumber')}
            />
          </div>

          <div className='flex flex-col md:flex-row mt-10 gap-2'>
            <label htmlFor={`uscisOnlineAccountNumber-${nameSet.id}`} className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
              USCIS Online Account Number: <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`uscisOnlineAccountNumber-${nameSet.id}`}
              name={`uscisOnlineAccountNumber-${nameSet.id}`}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
              value={nameSet.uscisOnlineAccountNumber || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'uscisOnlineAccountNumber')}
            />
          </div>

          {/* ... Other input fields ... */}
        </div>
      ))}

      {nameSets.length === 0 && (
        <div>
          <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
          <span className="text-gray-500">Add Other</span>
        </div>
      )}

      <button
        type="button"
        onClick={handleAddAnother}
        className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
      >
        Add Another Name
      </button>
    </div>







      
   


    <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            onChange={handleSubmitI864}
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>



   
  </form>
  <FormFotter />

    </div>
    </div>

)
}

export default OtherFamilyMember
