

import React, { useContext } from 'react'
import Header from '../../components/Header'
import FormFotter from '../../components/FormFotter'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { ContextFor1762 } from '../../UseContext/ContextFor1762';
import { countryCodes } from './Options';
import { BsArrowRight } from 'react-icons/bs';

const FinalDetail = () => {

    const {previouslyFiledI765, // Expose the state
    handlePreviouslyFiledI765Change,
    receivedHelpFromInterpreter, 
    handleReceivedHelpFromInterpreterChange,
       interpreterLanguage,
         interpreterGivenName,
         interpreterFamilyName,
         interpreterBusinessOrOrganization,
         handleChangeForInterprete,
         interpreterStreetNumberName,
         interpreterApartmentSuiteFloor,
         interpreterCityOrTown,
         interpreterCountry,
         updateAddress,
         selectedInterpreterCountry,
         interpretercountries,
         interpreterPostalCode,
         interpreterProvince,
         handleChangetimephonenumber,
         daytimephonenumber,
         handlePhoneNumberchange,
         phonenumber,
         handleChangeInterpreterLanguage,
         handleChangeInterpreterGivenName,
         handleChangeInterpreterFamilyName,
         handleChangeInterpreterBusinessOrOrganization,
         handleChangeInterpreterStreetNumberName,
         handleChangeInterpreterApartmentSuiteFloor,
         handleChangeInterpreterCityOrTown,
         handleChangeInterpreterPostalCode,
         handleChangeInterpreterProvince,
         handleSelectedInterpreterCountry,
         handlenumber,
         number,
         handleMobileNumberChange,
         mobilephonenumber,
         handlePhoneNumberschange,
         phonenumbers,
         handleChangeEmailAddress,
         emailAddress,
         handleChangeInterpreted,
         isInterpreted,
         handlePreparerTypeChange,
         preparerType,
         representationType,
         handleRepresentationTypeChange,
         handleUscisOnlineAccountNumberChange,
         uscisOnlineAccountNumbers,
         preparerFamilyName,
         handlePreparerFamilyNameChange,
         preparerGivenName,
         handlePreparerGivenNameChange,
         preparerBusinessOrOrganization,
         handlePreparerBusinessOrOrganizationChange,
         handleAttachFormG28Change,
         attachFormG28,
         handlePreparerCityOrTownChange,
         handlePreparerStreetNumberNameChange,
         handlePreparerHasApartmentChange,
         handlePreparerPostalCodeChange,
         handlePreparerProvinceChange,
         preparerHasApartment,
         preparerPostalCode,
         preparerProvince,
         preparerCountry,
         preparerCityOrTown,
         handlePreparerCountryChange,
         preparerStreetNumberName,
         handleDaytimePhoneNumberChanger,
         handleMobilePhoneNumberChanger,
         preparersContactInfo
    
    

         
        
         
         


} = useContext(ContextFor1762)

  console.log(interpretercountries)

    const handleChange = (e) => {
        const value = e.target.value;
        handlePreviouslyFiledI765Change(value);
      };

      const handleChangeReceivedHelpFromInterpreter = (e) => {
        const value = e.target.value;
        handleReceivedHelpFromInterpreterChange(value);
      };

      const handleChangeForInterpreter = (e) => {
        const value = e.target.value;
        const name = e.target.name; // Get the input field name
        updateAddress(name, value); // Pass the name and value to the updateAddress function
      };
      
    
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
        <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
        <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
        <div className='h-full'>
          <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
          <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
        </div>
      </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        Provide your current mailing address and contact information.
        </span>
      </div>
          
      <form action=" " className='mt-8 w-11/12  '>

        <div className=''>
            <h1 className='text-blue-700 font font-semibold text-xl'>Previous Application</h1>
        </div>
      <div className='flex flex-col  mt-5'>

      <div className="p-4 rounded text-white bg-blue-700">
      Have you previously filed Form I-765?
              <span className="text-red-500">*</span>
            </div>
    <div className='flex gap-2 p-4 bg-blue-100'>
    
        <label className='flex gap-1 text-blue-700'>
          <input
            type="radio"
            name="previouslyFiledI765"
            value="Yes"
            checked={previouslyFiledI765 === 'Yes'}
            onChange={handleChange}
          />
          Yes
        </label>

      <div>
        <label className='flex gap-1 text-blue-700'>
          <input
            type="radio"
            name="previouslyFiledI765"
            value="No"
            checked={previouslyFiledI765 === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
      </div>
      <p className='p-2 bg-slate-200'>If you have ever applied for employment authorization, you should indicate “Yes."</p>
    </div>


    <hr className='h-1 bg-blue-700 w-full mt-10' />

<div className='mt-5'>
    <h1 className='flex text-blue-700 font-semibold text-xl'>Interpreter</h1>
</div>

<div className='mt-10'>
      <div className="p-4 rounded text-white bg-blue-700">
        Did you receive help from an interpreter to prepare this application?
        <span className="text-red-500">*</span>
      </div>
      <div className="flex gap-2 p-4 bg-blue-100">
        <label className="flex gap-1 text-blue-700">
          <input
            type="radio"
            name="receivedHelpFromInterpreter"
            value="Yes"
            checked={receivedHelpFromInterpreter === 'Yes'}
            onChange={handleChangeReceivedHelpFromInterpreter}
          />
          Yes
        </label>
        <label className="flex gap-1 text-blue-700">
          <input
            type="radio"
            name="receivedHelpFromInterpreter"
            value="No"
            checked={receivedHelpFromInterpreter === 'No'}
            onChange={handleChangeReceivedHelpFromInterpreter}
          />
          No
        </label>
      </div>
    </div>


    <div className=''>
   

        <div className='flex flex-col gap-10  mt-10'>
       
          <label className='w-[600px] text-blue-700 flex '>
            Which language was used to translate into English?
            <span className="text-red-500">*</span>
            <input
             className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
              type="text"
              name="interpreterLanguage"
              value={interpreterLanguage}
              onChange={(e) =>  handleChangeInterpreterLanguage( e.target.value)}
            />
          </label>
          <label className='w-[600px] text-blue-700 flex '>
            Given Name (First Name)
            <span className="text-red-500">*</span>
            <input
             className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-20'
              type="text"
              name="interpreterGivenName"
              value={interpreterGivenName}
              onChange={(e)=>handleChangeInterpreterGivenName(e.target.value)}
            />
          </label>
          <label   className='w-[600px] text-blue-700 flex '>
            Family Name (Last Name)
            <span className="text-red-500">*</span>
            <input
             className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-20'
              type="text"
              name="interpreterFamilyName"
              value={interpreterFamilyName}
              onChange={(e)=>handleChangeInterpreterFamilyName( e.target.value)}
            />
          </label>
          <label   className='w-[600px] text-blue-700 flex '>
            Business or Organization (if any)
            <span className="text-red-500">*</span>
            <input
             className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
              type="text"
              name="interpreterBusinessOrOrganization"
              value={interpreterBusinessOrOrganization}
              onChange={(e) =>handleChangeInterpreterBusinessOrOrganization(e.target.value)
              }
            />
          </label>
        </div>
         
        </div>
           

           <div>
            <h1 className='font-semibold text-blue-700 text-xl mt-10'>Interpreter's Contact Information</h1>
            <p>This contact information is so that USCIS can reach you. Your mobile phone number and/or email address will also be used so that CitizenPath can create a complimentary Form G-1145 for you. By filing G-1145 with your adjustment of status application, USCIS will text and/or email when they have accepted the application for processing</p>
           </div>




           <div>
    </div>




    <div>
      <div className='flex mt-10'>
        <div  className='w-[250px] text-blue-700 flex '>
        Street Number and Name:  <span className="text-red-500">*</span>
        </div>
        <input
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
          type="text"
          name="interpreterStreetNumberName"
          value={interpreterStreetNumberName}
          onChange={(e)=>handleChangeInterpreterStreetNumberName(e.target.value)}
        />
      </div>
      <div className="flex mt-10">
  <div className="w-[250px] text-blue-700 flex">
    Is there an apartment, suite, or floor?
    <span className="text-red-500">*</span>
  </div>
  <select
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-8 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8"
    name="interpreterApartmentSuiteFloor"
    value={interpreterApartmentSuiteFloor}
    onChange={(e)=>handleChangeInterpreterApartmentSuiteFloor(e.target.value)}
  >
    <option value="">Select an option</option>
    <option value="No">No</option>
    <option value="Apt">Apt</option>
    <option value="Slr">Slr</option>
    <option value="Slr">Flr</option>
  </select>
</div>


<div className='flex mt-10'>
        <div className='w-[250px] text-blue-700 flex '>
     Enter Number Here <span className="text-red-500">*</span>
        </div>
        <input
          className='w-[64px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
          type="number"
          name="interpreterCityOrTown"
          value={number}
          onChange={(e)=>handlenumber(e.target.value)}
        />
      </div>
     
      <div className='flex mt-10'>
        <div className='w-[250px] text-blue-700 flex '>
        City or Town:  <span className="text-red-500">*</span>
        </div>
        <input
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
          type="text"
          name="interpreterCityOrTown"
          value={interpreterCityOrTown}
          onChange={(e)=>handleChangeInterpreterCityOrTown(e.target.value)}
        />
      </div>
     
<div className='flex mt-10'>
<div className='w-[250px] text-blue-700  '>
  Country:
  </div>
  <select
   className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
    name="interpreterCountry"
    value={selectedInterpreterCountry}
    onChange={(e)=>handleSelectedInterpreterCountry(e.target.value)}
  >
    <option value="">Select a country</option>
    {interpretercountries.map((country, index) => (
      <option key={index} value={country.common}>
        {country.common}
      </option>
    ))}
  </select>
</div>


 <div className='flex  mt-10'>
    <div className='w-[250px] text-blue-700  '>
    Province:
  </div>
     
        <input
            className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
          type="text"
          name="interpreterProvince"
          value={interpreterProvince}
          onChange={(e)=>handleChangeInterpreterProvince(e.target.value)}
        />
      </div>
      <div className='flex mt-10'>
      <div className='w-[250px] text-blue-700  '>
      Postal Code:
        </div>
       
        <input
        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
          type="text"
          name="interpreterPostalCode"
          value={interpreterPostalCode}
          onChange={(e)=>handleChangeInterpreterPostalCode(e.target.value)}
        />
      </div>
    </div>

   

   <div className='mt-10'>
    <h1 className='text-blue-500 text-semibold text-sm'>Interpreter's Contact Information</h1>
    <p className='text-sm'>This contact information is so that USCIS can reach you. Your mobile phone number and/or email address will also be used so that CitizenPath can create a complimentary Form G-1145 for you. By filing G-1145 with your adjustment of status application, USCIS will text and/or email when they have accepted the application for processing.</p>
   </div>


      


    <div className='mt-10'>
      <label htmlFor="countrySelect" className='w-[250px] text-blue-700' >Day time phone number</label>
      <select
        id="countrySelect"
        value={daytimephonenumber}
        onChange={(e) => handleChangetimephonenumber(e.target.value)}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-28'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={phonenumber}
        onChange={(e)=>handlePhoneNumberchange(e.target.value)}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
    </div>

    <div className='mt-10'>
      <label htmlFor="countrySelect" className='w-[250px] text-blue-700' >Day time phone number</label>
      <select
        id="countrySelect"
        value={mobilephonenumber}
        onChange={(e) => handleMobileNumberChange(e.target.value)}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-28'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={phonenumbers}
        onChange={(e)=>handlePhoneNumberschange(e.target.value)}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
    </div>


    <div className="flex mt-10">
  <div className="w-[250px] text-blue-700 flex">
    Email Address (if any):
  </div>
  <input
    type="email"
    name="emailAddress"
    value={emailAddress}
    onChange={(e) => handleChangeEmailAddress(e.target.value)}
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8"
  />
</div>


<div className="flex mt-10 gap-2 items-center justify-center">
<input
    type="checkbox"
    name="isInterpreted"
    checked={isInterpreted}
    onChange={(e) => handleChangeInterpreted(e.target.checked)}
    className="ml-4"
  />
  <label className=" text-blue-700 flex">
    The interpreter named above read to me every question and instruction on this application and my answer to every question in a language in which I am fluent. I understood all of this information as interpreted.
  </label>
 
</div>



<div className="flex mt-10 flex-col">
  <label className="bg-blue-700 rounded p-4 text-white">
    Who prepared this application?
  </label>
  <div className='flex flex-col gap-2 bg-blue-100 w-full rounded p-4'>
    <label className='text-blue-500'>
      <input
        type="radio"
        name="preparerType"
        value="self"
        checked={preparerType === "self"}
        onChange={handlePreparerTypeChange}
      /> I am the applicant, and I prepared this application.
    </label>
    <label className='text-blue-500'>
      <input
        type="radio"
        name="preparerType"
        value="preparedByApplicant"
        checked={preparerType === "preparedByApplicant"}
        onChange={handlePreparerTypeChange}
      /> I am not an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent.
    </label>
    <label className='text-blue-500'>
      <input
        type="radio"
        name="preparerType"
        value="attorneyOrRepresentative"
        checked={preparerType === "attorneyOrRepresentative"}
        onChange={handlePreparerTypeChange}
      /> I am an attorney or accredited representative of the applicant that prepared this application.
    </label>
  </div>
</div>
    
<div className="flex mt-10 flex-col">
  <label className="text-white bg-blue-700 p-4 ">
    My representation of the applicant in this case:
  </label>
  <div className='flex flex-col p-4 bg-blue-100 rounded'>
    <label className='text-blue-500'>
      <input
      
        type="radio"
        name="representationType"
        value="extends"
        checked={representationType === "extends"}
        onChange={handleRepresentationTypeChange}
      /> Extends beyond the preparation of this application.
    </label>
    <label className='text-blue-500'>
      <input
        type="radio"
        name="representationType"
        value="doesNotExtend"
        checked={representationType === "doesNotExtend"}
        onChange={handleRepresentationTypeChange}
      /> Does not extend beyond the preparation of this application.
    </label>
  </div>
</div>
        


<div className="flex flex-col gap-10 mt-10">
<div className='w-[250px] text-blue-700  '>
    
        </div>
  <label className="text-blue-700 flex gap-10">
   Given Name (First Name)
    <input
       className=' border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-32'
      type="text"
      name="preparerGivenName"
      value={preparerGivenName}
      onChange={handlePreparerGivenNameChange}
    />
  </label>
  <label className="text-blue-700 flex">
  <div className='w-[250px] text-blue-700  '>
  Family Name (Last Name)
        </div>
  
    <input
     className=' border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-28'
      type="text"
      name="preparerFamilyName"
      value={preparerFamilyName}
      onChange={handlePreparerFamilyNameChange}
    />
  </label>
  <label className="text-blue-700 flex">
  <div className='w-[250px] text-blue-700  '>
  Business or Organization (if any)
        </div>
   
    <input
       className=' border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-28'
      type="text"
      name="preparerBusinessOrOrganization"
      value={preparerBusinessOrOrganization}
      onChange={handlePreparerBusinessOrOrganizationChange}
    />
  </label>
  <label className="text-blue-700 flex ">
  <div className='w-[250px] text-blue-700  '>
  USCIS Online Account Number
        </div>
 
    <input
      className=' border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16
      py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-28'
      type="text"
      name="uscisOnlineAccountNumber"
      value={uscisOnlineAccountNumbers}
      onChange={handleUscisOnlineAccountNumberChange}
    />
  </label>
</div>



<div className="mt-10 flex gap-20">
  <label className="text-blue-700 flex">
    Will you attach Form G-28 to the application?
  </label>
  <div className="flex items-center  gap-2 text-blue-600">
    <label className="radio-label">
      <input
        type="radio"
        name="attachFormG28"
        value="Yes"
        checked={attachFormG28 === 'Yes'}
        onChange={handleAttachFormG28Change}
      />
      Yes
    </label>
    <label className="radio-label">
      <input
        type="radio"
        name="attachFormG28"
        value="No"
        checked={attachFormG28 === 'No'}
        onChange={handleAttachFormG28Change}
      />
      No
    </label>
  </div>
</div>


<div className="mt-10">
  <div className="flex">
    <div className="w-[250px] text-blue-700 flex">
      Street Number and Name:
    </div>
    <input
     className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      type="text"
      name="preparerStreetNumberName"
      value={preparerStreetNumberName}
      onChange={handlePreparerStreetNumberNameChange}
    />
  </div>

  <div className="flex mt-10">
    <div className="w-[250px] text-blue-700 flex">
      Is there an apartment, suite or floor?
      <span className="text-red-500">*</span>
    </div>
    <select
      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      name="preparerHasApartment"
      value={preparerHasApartment}
      onChange={handlePreparerHasApartmentChange}
    >
      <option value="No">No</option>
      <option value="Yes">Yes</option>
    </select>
  </div>

  <div className="flex mt-10">
    <div className="w-[250px] text-blue-700 flex">City or Town:</div>
    <input
      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      type="text"
      name="preparerCityOrTown"
      value={preparerCityOrTown}
      onChange={handlePreparerCityOrTownChange}
    />
  </div>

  <div className="flex mt-10">
    <div className="w-[250px] text-blue-700 flex">Country:</div>
    <input
     className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      type="text"
      name="preparerCountry"
      value={preparerCountry}
      onChange={handlePreparerCountryChange}
    />
  </div>

  <div className="flex mt-10">
    <div className="w-[250px] text-blue-700 flex">Province:</div>
    <input
      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      type="text"
      name="preparerProvince"
      value={preparerProvince}
      onChange={handlePreparerProvinceChange}
    />
  </div>

  <div className="flex mt-10">
    <div className="w-[250px] text-blue-700 flex">Postal Code:</div>
    <input
      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-20 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      type="text"
      name="preparerPostalCode"
      value={preparerPostalCode}
      onChange={handlePreparerPostalCodeChange}
    />
  </div>
</div>


<div className='mt-10'>
      <label htmlFor="countrySelect" className='w-[250px] text-blue-700'>
        Daytime Phone Number
      </label>
      <select
        id="countrySelect"
        value={preparersContactInfo.daytimePhoneNumber}
        onChange={(e) => handleDaytimePhoneNumberChanger(e.target.value)}
        className='w-[100px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-28'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
            {country.code} {country.name}
          </option>
        ))}
      </select>
      <input
        value={preparersContactInfo.daytimePhoneNumber}
        onChange={(e) => handleDaytimePhoneNumberChanger(e.target.value)}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
      />
    </div>

    <div className='mt-10'>
      <label htmlFor="countrySelectMobile" className='w-[250px] text-blue-700'>
        Mobile Phone Number (if any)
      </label>
      <select
        id="countrySelectMobile"
        value={preparersContactInfo.mobilePhoneNumber}
        onChange={(e) => handleMobilePhoneNumberChanger(e.target.value)}
        className='w-[100px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-28'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
            {country.code} {country.name}
          </option>
        ))}
      </select>
      <input
        value={preparersContactInfo.mobilePhoneNumber}
        onChange={(e) =>handleMobilePhoneNumberChanger(e.target.value)}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
      />
      
    </div>



        <hr className='h-1 bg-blue-700 w-full mt-10'/>

        <div className='flex  text-white mt-24  items center w-[100px] h-[40px]  ml-auto'>
            <div className='bg-green-600 flex items-center  justify-center  py-2 rounded-xl shadow  '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>

             
        </form>
        <FormFotter />



      </div>
      </div>
  )
}

export default FinalDetail