import React, { createContext, useContext, useReducer } from 'react';

const FormDataContext = createContext();  // Add this line



const initialState = {
  FormOne: {
    alienNumber: false,
    alienFile: false,
    i130Approval: false,
    arrivalDepartureRecords: false,
    other: false,
    givenName: '', // Initial value for givenName
    middleName: '', // Initial value for middleName
    familyName: '' 
  },
  recordsRequest: '',
  purposeRequest: '',
  personRequest: 'myself',
  childRequest: '', // Added for the child question
  deceasedRequest: '', // Added for the deceased question
  powerOfAttorneyRequest: "",
  subjectRecord: '',
  nameSets: [],
  street: '',
  aptSuiteFloor: 'no', // default value for the select
  number: '',
  cityTown: '',
  zip: '',
  stateTerritory: "",
  dayTimePhoneNumber: '', // Initial value for the first phone number
  MobilePhoneNumber: '', 
  email: '', // Initial value for email
  expeditedConditions: {
    circumstances: false,
    urgency: false,
    anotherCircumstances: false,
    anotherUrgency: false,
  },
  isAorGVisaHolder: null,
  // ... other form states
};

export const ACTIONS = {
  SET_CHECKBOX: 'SET_CHECKBOX',
  SET_RECORDS_REQUEST: 'SET_RECORDS_REQUEST',
  SET_PURPOSE_REQUEST: 'SET_PURPOSE_REQUEST',
  SET_PERSON_REQUEST: 'SET_PERSON_REQUEST',
  SET_CHILD_REQUEST: 'SET_CHILD_REQUEST', // Added for the child question
  SET_DECEASED_REQUEST: 'SET_DECEASED_REQUEST', // Added for the deceased question
  SET_POWER_REQUEST: 'SET_POWER_REQUEST',
  SET_GIVEN_NAME: 'SET_GIVEN_NAME', // New action for setting givenName
  SET_MIDDLE_NAME: 'SET_MIDDLE_NAME', // New action for setting middleName
  SET_FAMILY_NAME: 'SET_FAMILY_NAME',
  SET_Subject_record: 'SET_Subject_Record',
  ADD_NAME_SET: 'ADD_NAME_SET',
  REMOVE_NAME_SET: 'REMOVE_NAME_SET',
  UPDATE_NAME_FIELD: 'UPDATE_NAME_FIELD',
  SET_STREET: 'SET_STREET',
  SET_APT_SUITE_FLOOR: 'SET_APT_SUITE_FLOOR',
  SET_NUMBER: 'SET_NUMBER',
  SET_CITY_TOWN: 'SET_CITY_TOWN',
  SET_ZIP: 'SET_ZIP',
  SET_STATE_TERRITORY: 'SET_STATE_TERRITORY',
  SET_DAY_TIME_PHONE_NUMBER: 'SET_DAY_TIME_PHONE_NUMBER',
  SET_DAY_TIME_MOBILE_NUMBER: 'SET_DAY_TIME_MOBILE_NUMBER',
  SET_EMAIL: 'SET_EMAIL',
  TOGGLE_EXPEDITED_CONDITION: 'TOGGLE_EXPEDITED_CONDITION',
  SET_A_OR_G_VISA: 'SET_A_OR_G_VISA',

 
};


// Modify reducer
const formReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_CHECKBOX:
      return {
        ...state,
        FormOne: {
          ...state.FormOne,
          [action.payload.type]: action.payload.value,
        },
      };
    case ACTIONS.SET_RECORDS_REQUEST:
      return { ...state, recordsRequest: action.payload };
    case ACTIONS.SET_PURPOSE_REQUEST:
      return { ...state, purposeRequest: action.payload };
      case ACTIONS.SET_PERSON_REQUEST:
      return { ...state, personRequest: action.payload };
      case ACTIONS.SET_CHILD_REQUEST:
      return { ...state, childRequest: action.payload };
    case ACTIONS.SET_DECEASED_REQUEST:
      return { ...state, deceasedRequest: action.payload };
    case ACTIONS.SET_POWER_REQUEST:
      return { ...state, powerOfAttorneyRequest: action.payload };
      case ACTIONS.SET_Subject_record:
        return { ...state, subjectRecord: action.payload };
        case 'ADD_NAME_SET':
      return {
        ...state,
        nameSets: [
          ...state.nameSets,
          { id: Date.now(), givenName: '', middleName: '', familyName: '' },
        ],
      };
        
        case ACTIONS.REMOVE_NAME_SET:
          return {
            ...state,
            nameSets: state.nameSets.filter((nameSet) => nameSet.id !== action.payload),
          };
        case ACTIONS.UPDATE_NAME_FIELD:
          return {
            ...state,
            nameSets: state.nameSets.map((nameSet) =>
              nameSet.id === action.payload.id
                ? { ...nameSet, [action.payload.field]: action.payload.value }
                : nameSet
            ),
          };
      case ACTIONS.SET_GIVEN_NAME:
        return {
          ...state,
          FormOne: {
            ...state.FormOne,
            givenName: action.payload,
          },
        };
      case ACTIONS.SET_MIDDLE_NAME:
        return {
          ...state,
          FormOne: {
            ...state.FormOne,
            middleName: action.payload,
          },
        };
      case ACTIONS.SET_FAMILY_NAME:
        return {
          ...state,
          FormOne: {
            ...state.FormOne,
            familyName: action.payload,
          },
        };
        case ACTIONS.SET_STREET:
          return {
            ...state,
            street: action.payload,
          };
    
        case ACTIONS.SET_APT_SUITE_FLOOR:
          return {
            ...state,
            aptSuiteFloor: action.payload,
          };
    
        case ACTIONS.SET_NUMBER:
          return {
            ...state,
            number: action.payload,
          };
    
        case ACTIONS.SET_CITY_TOWN:
          return {
            ...state,
            cityTown: action.payload,
          };
    
        case ACTIONS.SET_ZIP:
          return {
            ...state,
            zip: action.payload,
          };
    
        case ACTIONS.SET_STATE_TERRITORY:
          return {
            ...state,
            stateTerritory: action.payload,
          };
          case ACTIONS.SET_STATE_TOWN:
      return {
        ...state,
        stateTown: action.payload,
      }
      case ACTIONS.SET_DAY_TIME_PHONE_NUMBER:
      return {
        ...state,
        dayTimePhoneNumber: action.payload,
      };
    case ACTIONS.SET_DAY_TIME_MOBILE_NUMBER:
      return {
        ...state,
        MobilePhoneNumber: action.payload,
      };
      case ACTIONS.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case ACTIONS.TOGGLE_EXPEDITED_CONDITION:
      return {
        ...state,
        expeditedConditions: {
          ...state.expeditedConditions,
          [action.payload]: !state.expeditedConditions[action.payload],
        },
      };
      case ACTIONS.SET_A_OR_G_VISA:
      return {
        ...state,
        isAorGVisaHolder: action.payload,
      };
    // ... other cases
    
    // ... other cases
  
          
    
    default:
      return state;
  }
};

export const PageThreeDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  return (
    <FormDataContext.Provider value={{ state, dispatch }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormDataContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormDataProvider');
  }
  return context;
};
