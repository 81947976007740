import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';
// Create a context for the form state
const FormContextI31 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContextI31);
}

// Define the initial state
const initialState = {
    filingLocation: '', // 'inside' or 'outside'
    inProceedings: '', // 'yes' or 'no'
    underProceedings: '', // 'yes' or 'no'
    chargedWithCrime: '', // 'yes' or 'no'
    travelDocument: '', // 'advanceParole', 'reentryPermit', 'refugeeTravel', 'humanitarianParole', 'dontKnow'
    isPermanentResident: '', // 'yes' or 'no'
    hasRefugeeStatus: '', // 'grantedRefugee', 'grantedAsylum', 'no'
    uVisaStatus: '', // 'yes' or 'no'
    i821Status: '', // 'yes' or 'no'
    lifeActStatus: '', 
    basis: '',
    currentStatus: '',
    immigrationStatus: '',
    isAdjustingStatus: '',
    obtainedPRFromRefugeeOrAsylum: '',
    spokenToAttorney: false,
    issuedReentryPermitOrTravelDoc: '',
    knowsMostRecentDocumentDate:true   ,
    travelDocumentExpired: '',
    tripDate: '',
    expectedDaysOutsideUS: '', 
    tripPurpose:"",
    intendedCountries:"",
    refugeeOrAsyleeCountry:"",
    planToTravel: '', 
     returnedToCountry: '', // To store the answer (Yes/No)
  appliedForEntryPermit: '', // To store the answer (Yes/No)
  receivedBenefitFromCountry: '', // To store the answer (Yes/No)
  reacquiredNationality: '', // To store the answer (Yes/No)
  acquiredNewNationality: '', // To store the answer (Yes/No)
  grantedStatusInOtherCountry: '',
  firstName: '',
  middleName: '',
  lastName: '',
  inCareOfName: '',
  streetName: '',
  city: '',
  zipCode: '',
  enteredNumber: '',
  selectedOption:"",
  sex: '', // 'Male' or 'Female'
  alienRegistrationNumber: '',
  socialSecurityNumber: '',
  CountryofCitizenship:"",
  CountryofBirth:"",
  DateofBirth:"",
  PHnumber:"",
  otherInformationdaytimePhoneNumber:"",
  Pnumber:"",
  otherInformationday:"",
  emailAddress: '', 
  urgentNeedForTravelDocument: '',
  preparedByApplicant: '',
  representApplicantBeyondPreparation: '', 
  preparerInformationGivenName: '',
  preparerInformationFamilyName: '',
  preparerInformationBusinessOrOrganization: '',
  preparersMailingAddressStateOrTerritory: '',
  preparersMailingAddressCountry: '',
  preparersMailingAddressApartmentSuiteFloor: 'No',
  enterTheNumberHere: '',
  TimePhoneNumber:"",
  Mbnumber:"",
  email: '',




  
  };

// Create a provider component to manage the state
export function FormProviderForI31({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text': // Include input type "text"
        case 'number': // Include input type "number"
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one': // Handle select option
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      };
    });
  };

  const mapFormFields = () => {
    let mapping = {};

    // Map the values for the provided fields in Form1 and Form2 to the given PDF fields
    mapping["form1[0].#pageSet[0].Page1[0].PDF417BarCode1[0]"] = '';
    mapping["form1[0].#subform[0].Line1a_FamilyName[0]"] = formData.firstName;
    mapping["form1[0].#subform[0].Line1b_GivenName[0]"] =formData.givenName;
    mapping["form1[0].#subform[0].Line1c_MiddleName[0]"] =formData.middleName;
    mapping["form1[0].#subform[4].Line5_Email[0]"] =formData.emailAddress;
  
    // mapping["form1[0].#subform[1].Pt2Line14_StreetNumberName[0]"] =formData.streetName
    // mapping["form1[0].#subform[1].Pt2Line14_AptSteFlrNumber[0]]"] =formData.preparersMailingAddressApartmentSuiteFloor
    // mapping["form1[0].#subform[1].Pt2Line14_CityOrTown[0]"] =formData.emailAddress
    // mapping["form1[0].#subform[1].Pt2Line14_State[0]"] =formData.selectedOption
 
    // mapping["form1[0].#subform[8].Pt6Line5_Email[0]"] =formData.email

    // mapping["form1[0].#subform[8].Pt6Line3_DaytimePhoneNumber[0]"] =formData.Pnumber
    // mapping["form1[0].#subform[9].Pt8Line1b_PreparerGivenName[0]"] =formData.preparerInformationGivenName
    // mapping["form1[0].#subform[9].Pt8Line2_BusinessName[0]"] =formData. preparerInformationFamilyName
    // mapping["form1[0].#subform[9].Pt7Line4_InterpreterDaytimeTelephone[0]"] =formData. Mbnumber

    mapping["form1[0].#subform[0].AttorneyStateLicenseNumber[0]"] =formData.spokenToAttorney
    mapping["form1[0].#subform[0].#area[1].Line3_AlienNumber[0]"] =formData.alienRegistrationNumber
   
    mapping["form1[0].#subform[0].Line2a_InCareofName[0]"] =formData.inCareOfName
    mapping["form1[0].#subform[0].Line8_DateOfBirth[0]"] =formData.DateofBirth
    mapping["form1[0].#subform[0].Line4_CountryOfBirth[0]"] =formData.CountryofBirth
    mapping["form1[0].#subform[0].Line5_CountryOfCitizenship[0]"] =formData.CountryofCitizenship
    // mapping["form1[0].#subform[1].#area[4].Line2g_DaytimePhoneNumber1[0]"] = "+97-134564322"

    // ... (add the rest from the previous answer)



   
    mapping["form1[0].#subform[1].Line2_ExpectedLengthTrip[0]"] ="26"
    mapping["form1[0].#subform[2].Line1a_Purpose[0]"] =formData.tripPurpose
 
    mapping["form1[0].#subform[0].Line2d_CityOrTown[0]"] =formData.city
    mapping["form1[0].#subform[0].Line2h_Province[0]"] ="kPK"
    // mapping["form1[0].#subform[0].Line2f_ZipCode[0]]"] =formData.zipCode
    mapping["form1[0].#subform[0].AttorneyStateLicenseNumber[0]"] =formData.socialSecurityNumber
    mapping["form1[0].#subform[0].Line2c_AptSteFlrNumber[0]"] = "No"
  

    mapping["form1[0].#subform[0].Line6_ClassofAdmission[0]"] = "Visitor"
    mapping["form1[0].#subform[0].Line2b_StreetNumberName[0]"] = formData.streetName
    mapping["form1[0].#subform[0].Line7_Female[0]"] = (formData.sex ==="Female") ? true : false
    // mapping["form1[0].#subform[0].Line7_Male[0]]"] = (formData.sex ==="male") ? true : false
    mapping["form1[0].#subform[1].Line2a_FamilyName[0]"] = formData.preparerInformationFamilyName
    mapping["form1[0].#subform[1].Line2b_GivenName[0]"] = formData.preparerInformationGivenName
    mapping["form1[0].#subform[1].Line2i_StreetNumberName[0]"] = formData.preparersMailingAddressStateOrTerritory
    mapping["form1[0].#subform[1].Line2j_AptSteFlrNumber[0]"] = formData.representApplicantBeyondPreparation
    mapping["form1[0].#subform[1].Line2k_CityOrTown[0]"] = formData.preparersMailingAddressCountry
    mapping["form1[0].#subform[1].Line2m_ZipCode[0]"] =  "2134532"
    mapping["form1[0].#subform[1].Line2l_State[0]"] =  "Alaska"
    mapping["form1[0].#subform[4].Line3h_Country[0]"] = "USA"
    mapping["form1[0].#subform[4].#area[7].Line2_DaytimePhoneNumber1[0]"] = "316"
    mapping["form1[0].#subform[4].#area[7].Line2_DaytimePhoneNumber2[0]"] = "972"
    mapping["form1[0].#subform[4].#area[8].Line4_DaytimePhoneNumber1[0]]"] = "3333"
    mapping["form1[0].#subform[4].#area[8].Line4_DaytimePhoneNumber2[0]"] = "1234"
    mapping["form1[0].#subform[4].Line3d_State[0]"] = "CA"
    mapping["form1[0].#subform[3].Line3b_No[0]"] = "Ext12"
    mapping["form1[0].#subform[3].Line3c_Yes[0]"] = "Yes"
    mapping["form1[0].#subform[3].Line1_OneTrip[0]"] = "No"
    mapping["form1[0].#subform[3].Line4a_No[1]"] = "Yes"
    mapping["form1[0].#subform[3].Line4b_Yes[0]"] = "No"
    mapping["form1[0].#subform[3].Line4c_No[0]"] = "Yes"
    mapping["form1[0].#subform[3].Line4c_Yes[0]"] = "No"
    mapping["form1[0].#subform[3].Line4b_No[0]"] = "Yes"
    mapping["form1[0].#subform[3].Line4a_Yes[1]"] = "No"
    mapping["form1[0].#subform[3].Line3c_No[0]"] = "Yes"
    mapping["form1[0].#subform[3].Line3b_Yes[0]"] = "No"
    mapping["form1[0].#subform[3].Line3_AddressPart2[0]"] = "No"
    mapping["form1[0].#subform[3].Line4_AddressBelow[0"] = "No"
    mapping["form1[0].#subform[3].Line2_No1[0]"] = "No"
    mapping["form1[0].#subform[3].Line3a_Yes1[0]"] = "No"
    mapping["form1[0].#subform[3].Line3a_No1[0]"] = "No"
    mapping["form1[0].#subform[3].Line2_Yes1[0]"] = "No"
    mapping["form1[0].#subform[3].Line4c_Unit[0]"] = "No"
    mapping["form1[0].#subform[3].Line4c_Unit[1]"] = "No"
    mapping["form1[0].#subform[3].Line4c_Unit[2]"] = "No"
    mapping["form1[0].#subform[3].Line1_OAW[0]"] = "No"
    mapping["form1[0].#subform[3].Line1_OAW[1]"] = "No"
    mapping["form1[0].#subform[4].Line3b_Unit[0]"] = "No"
    mapping["form1[0].#subform[4].Line3b_Unit[1]"] = "No"


    // mapping["form1[0].Page2[0].Pt2Line7_CityOrTown[0]"] = cityOrTown;
    // mapping["form1[0].Page2[0].Pt2Line7_State[0]"] = provinceOrState;
    // mapping["form1[0].Page2[0].Pt2Line7_ZipCode[0]"] = ''; // You didn't provide a ZIP state field
    // mapping["form1[0].Page2[0].Pt2Line7_StreetNumberName[0]"] = ''; // Street number not provided
    // mapping["form1[0].Page2[0].Line14_Checkbox_No[0]"] = (hasBeenArrested === 'No') ? true : false;
    // mapping["form1[0].Page2[0].Line14_Checkbox_Yes[0]"] = (hasBeenArrested === 'Yes') ? true : false;
    // mapping["form1[0].Page2[0].Line7_AlienNumber[0]"] = aNumber;
    // mapping["form1[0].Page2[0].Line8_ElisAccountNumber[0]"] = uscisOnlineAccountNumber;
    // mapping["form1[0].Page2[0].Line9_Checkbox[0]"] = (unknownSsn === true) ? true : false;
    // mapping["form1[0].Page2[0].Line9_Checkbox[1]"] = (replaceSsnCard === 'Yes') ? true : false;
    // // ... (continue with the rest of the checkboxes, set them based on your React states)
    // mapping["form1[0].Page2[0].Line15a_FamilyName[0]"] = fathersFamilyName;
    // mapping["form1[0].Page2[0].Line15b_GivenName[0]"] = fathersGivenName;
    // mapping["form1[0].Page2[0].Line16a_FamilyName[0]"] = mothersFamilyName;
    // mapping["form1[0].Page2[0].Line16b_GivenName[0]"] = mothersGivenName;
    // mapping["form1[0].Page2[0].Line17b_CountryOfBirth[0]"] = selectedCountry;

    // // ... (and continue with the rest of your fields)

    return mapping;
  };




  const handleSubmitI131 = async () => {
    const data = mapFormFields();
    console.log(data);
     console.log("uzair")
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "I-131",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };


  return (
    <FormContextI31.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitI131  }}>
      {children}
    </FormContextI31.Provider>
  );
}




