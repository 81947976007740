import React from 'react'
import { useFormContext } from '../../UseContext/ContextFor821D';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const ApplicantProfile = () => {

    const navigate =useNavigate()


    const FormSubmit=(e)=>{
         e.preventDefault()

         navigate("/Immigration821")
    }

    const { formData, handleInputChange ,code } = useFormContext();
    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
    
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
            Provide your current mailing address and contact information.
            </span>
          </div>
          <form action=" " className='mt-14 w-11/12' onSubmit={FormSubmit}>
            

<label className=' flex gap-10   w-full  text-blue-600 rounded p-4 '>
  Given Name (First Name):

<div >
  <input
   className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
    type="text"
    name="givenName"
    value={formData.givenName}
    onChange={handleInputChange}
  />
</div>
</label>

<label className='flex  gap-24 rounded p-4 text-blue-600 w-full'>
  Middle Name(s):

<div>
  <input
   className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
    type="text"
    name="middleName"
    value={formData.middleName}
    onChange={handleInputChange}
  />
</div>
</label>


<label className='flex  gap-10 w-full rounded p-4 text-blue-600 '>
  Family Name (Last Name):

<div>
  <input
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
    type="text"
    name="familyName"
    value={formData.familyName}
    onChange={handleInputChange}
  />
</div>
</label>


<hr className='mt-10 bg-blue-800  h-1 w-full'/>


<div className='mt-5'>
    <h1 className='text-blue-700  font-semibold text-xl'>Other Name(s)</h1>
    <p className='text-sm'>Provide any and all other names that you have ever used, including maiden names, names from a previous marriage, aliases or nicknames. Explain more >></p>
</div>

<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Have you used any other names since birth?
</label>
<div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
  <input

    type="radio"
    name="usedOtherNames"
    value="Yes"
    checked={formData.usedOtherNames === 'Yes'}
    onChange={handleInputChange}
  /> Yes
  <input
    type="radio"
    name="usedOtherNames"
    value="No"
    checked={formData.usedOtherNames === 'No'}
    onChange={handleInputChange}
  /> No
</div>
<p className='bg-slate-200 p-2'>Indicate if you've ever been known by another name, alias or nick name.</p>


<hr className='mt-10 bg-blue-800  h-1 w-full'/>

<div className='mt-10'>
    <h1 className='text-blue-700 text-xl font-semibold'>Birth Information</h1>
    <p className='text-sm'>Enter your date of birth and the location where you were born.</p>
</div>

<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Date of Birth 
</label>
<div className='bg-blue-100 p-4 w-full'>
<input
   className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
  type="text"
  name="dateOfBirth"
  value={formData.dateOfBirth}
  onChange={handleInputChange}
  placeholder="dd/mm/yyyy"
/>
</div>
<p className='bg-slate-200 p-2'>Enter the date that you were born.</p>

<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Town or City
</label>
<div className='bg-blue-100 p-4 w-full'>
<input
className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
  type="text"
  name="townOrCity"
  value={formData.townOrCity}
  onChange={handleInputChange}
  placeholder="Enter Town or City"
/>
</div>
<p className='bg-slate-200 p-2'>Enter the town or city where you were born.</p>

<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  State or Province (if any)
</label>
<div className='bg-blue-100 p-4 w-full'>
<input
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
  type="text"
  name="stateOrProvince"
  value={formData.stateOrProvince}
  onChange={handleInputChange}
  placeholder="Enter State or Province"
/>
</div>
<p className='bg-slate-200 p-2'>Enter the state or province where you were born.</p>


<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>Country</label>
<div className='p-4 bg-blue-100'>
<select
  className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
  name="selectedCountry"
  value={formData.selectedCountry || ''}
  onChange={handleInputChange}
>
  <option value="">Select a Country</option>
  {code.map((countryName) => (
    <option key={countryName} value={countryName}>
      {countryName}
    </option>
  ))}
</select>
</div>
<p className='bg-slate-200 p-2'> Select the country where you were born.</p>

<hr className='mt-10 bg-blue-800  h-1 w-full'/>



<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Sex
  </label>
  <div  className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
    <input
      type="radio"
      name="sex"
      value="Male"
      checked={formData.sex === 'Male'}
      onChange={handleInputChange}
    /> Male
    <input
      type="radio"
      name="sex"
      value="Female"
      checked={formData.sex === 'Female'}
      onChange={handleInputChange}
    /> Female
  </div>
  <p className='bg-slate-200 p-4'>Even if you are Hispanic or Latino, you are still required to select a race below. Explain more >></p>


<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Ethnicity
  </label>
  <div  className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
    <input
      type="radio"
      name="ethnicity"
      value="Hispanic or Latino"
      checked={formData.ethnicity === 'Hispanic or Latino'}
      onChange={handleInputChange}
    /> Hispanic or Latino
    <input
      type="radio"
      name="ethnicity"
      value="Not Hispanic or Latino"
      checked={formData.ethnicity === 'Not Hispanic or Latino'}
      onChange={handleInputChange}
    /> Not Hispanic or Latino
  </div>


  <label className='flex  bg-blue-700  p-4 text-white mt-10'>
  Race (select all applicable boxes)
  </label>
  <div className='flex p-4 bg-blue-100 gap-12'>
    <div>
    <input
      type="checkbox"
      name="race"
      value="white"
      checked={formData.race.white}
      onChange={handleInputChange}
    /> White
    </div>

    <div>
    <input
      type="checkbox"
      name="race"
      value="asian"
      checked={formData.race.asian}
      onChange={handleInputChange}
    /> Asian
    </div>

    <div>
    <input
      type="checkbox"
      name="race"
      value="blackAfricanAmerican"
      checked={formData.race.blackAfricanAmerican}
      onChange={handleInputChange}
    /> Black or African American

</div>

<div>
    <input
      type="checkbox"
      name="race"
      value="americanIndianAlaskaNative"
      checked={formData.race.americanIndianAlaskaNative}
      onChange={handleInputChange}
    /> American Indian or Alaska Native

</div>

<div className=''>
    <input
      type="checkbox"
      name="race"
      value="hawaiianPacificIslander"
      checked={formData.race.hawaiianPacificIslander}
      onChange={handleInputChange}
    /> Native Hawaiian or Other Pacific Islander
  </div>
  </div> 


  <label className='flex  bg-blue-700  p-4 text-white mt-10'>
  Eye Color
  </label>

  <div  className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500 gap-5'>
  <label key="Black" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Black"
    checked={formData.eyeColor === "Black"}
    onChange={handleInputChange}
  />
   Black
</label>

<label key="Blue" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Blue"
    checked={formData.eyeColor === "Blue"}
    onChange={handleInputChange}
  />
   Blue
</label>

<label key="Brown" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Brown"
    checked={formData.eyeColor === "Brown"}
    onChange={handleInputChange}
  />
   Brown
</label>

<label key="Gray" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Gray"
    checked={formData.eyeColor === "Gray"}
    onChange={handleInputChange}
  />
  Gray
</label>

<label key="Green" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Green"
    checked={formData.eyeColor === "Green"}
    onChange={handleInputChange}
  />
   Green
</label>

<label key="Hazel" className=''>
  
  <input
    type="radio"
    name="eyeColor"
    value="Hazel"
    checked={formData.eyeColor === "Hazel"}
    onChange={handleInputChange}
  />
   Hazel
</label>

<label key="Maroon" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Maroon"
    checked={formData.eyeColor === "Maroon"}
    onChange={handleInputChange}
  />
   Maroon
</label>

<label key="Pink" className=''>

  <input
    type="radio"
    name="eyeColor"
    value="Pink"
    checked={formData.eyeColor === "Pink"}
    onChange={handleInputChange}
  />
    Pink
</label>

<label key="Unknown/Other" className=''>
 
  <input
    type="radio"
    name="eyeColor"
    value="Unknown/Other"
    checked={formData.eyeColor === "Unknown/Other"}
    onChange={handleInputChange}
  />
     Unknown/Other
</label>

</div>

<label className='flex bg-blue-700 p-4 text-white mt-10'>
  Hair Color
</label>

<div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500 gap-5'>
  <label key="Bald">
    <input
      type="radio"
      name="hairColor"
      value="Bald (no hair)"
      checked={formData.hairColor === "Bald (no hair)"}
      onChange={handleInputChange}
    />
    Bald (no hair)
  </label>

  <label key="Black">
    <input
      type="radio"
      name="hairColor"
      value="Black"
      checked={formData.hairColor === "Black"}
      onChange={handleInputChange}
    />
    Black
  </label>

  <label key="Blond">
    <input
      type="radio"
      name="hairColor"
      value="Blond"
      checked={formData.hairColor === "Blond"}
      onChange={handleInputChange}
    />
    Blond
  </label>

  <label key="Brown">
    <input
      type="radio"
      name="hairColor"
      value="Brown"
      checked={formData.hairColor === "Brown"}
      onChange={handleInputChange}
    />
    Brown
  </label>

  <label key="Gray">
    <input
      type="radio"
      name="hairColor"
      value="Gray"
      checked={formData.hairColor === "Gray"}
      onChange={handleInputChange}
    />
    Gray
  </label>

  <label key="Red">
    <input
      type="radio"
      name="hairColor"
      value="Red"
      checked={formData.hairColor === "Red"}
      onChange={handleInputChange}
    />
    Red
  </label>

  <label key="Sandy">
    <input
      type="radio"
      name="hairColor"
      value="Sandy"
      checked={formData.hairColor === "Sandy"}
      onChange={handleInputChange}
    />
    Sandy
  </label>

  <label key="White">
    <input
      type="radio"
      name="hairColor"
      value="White"
      checked={formData.hairColor === "White"}
      onChange={handleInputChange}
    />
    White
  </label>
</div>

<label className="flex bg-blue-600 w-full rounded p-4 text-white mt-10">
  Marital Status
</label>

<div className="bg-blue-100 p-4 w-full">
  <select
    name="maritalStatus"
    value={formData.maritalStatus}
    onChange={handleInputChange}
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10"
  >
    <option value="single">Single</option>
    <option value="married">Married</option>
    <option value="divorced">Divorced</option>
    <option value="widow">Widow</option>
  </select>
</div>


<div className='flex  text-white mt-32 w-[100px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>


       

        </form>
        <FormFotter />
    
          </div>
          </div>
      )
}

export default ApplicantProfile
