// // FormContext.js
// import React, { createContext, useContext, useReducer } from 'react';

// // Create a context
// const FormContext = createContext();

// // Initial state for your form data
// const initialState = {
//   formData: {
//     residency: "",
//     support: "",
//     uscitizen: "",
//     relationship: "",
//     jointsponsors: "",
//     age18: "",
//     spouseFilingI485: "",
//     servedInUSArmedForces: "",
//     activeDutyUSArmedForces: "",
//     hasSocialSecurityNumber: "",
//     isDomiciledInUS: "",
//     intendToReestablishDomicile: "",
//   },
//   showError: true,
// };

// // Define the reducer function to update form data and showError
// const formReducer = (state, action) => {
//   switch (action.type) {
//     case 'UPDATE_FORM':
//       return {
//         ...state,
//         formData: {
//           ...state.formData,
//           ...action.payload,
//         },
//       };
//     case 'TOGGLE_ERROR':
//       return {
//         ...state,
//         showError: !state.showError,
//       };
//     default:
//       return state;
//   }
// };

// // Create a custom provider component
// function FormProvider11({ children }) {
//   const [formState, dispatch] = useReducer(formReducer, initialState);

//   return (
//     <FormContext.Provider value={{ formState, dispatch }}>
//       {children}
//     </FormContext.Provider>
//   );
// }

// // Create a custom hook for using the form context
// function useFormContext() {
//   return useContext(FormContext);
// }

// export { FormProvider11, useFormContext };




import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context for the form state
const FormContext864BForms = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContext864BForms);
}

// Define the initial state
const initialState = {
  formData: {
    residency: "",
    support: "",
    uscitizen: "",
    relationship: "",
    jointsponsors: "",
    age18: "",
    spouseFilingI485: "",
    servedInUSArmedForces: "",
    activeDutyUSArmedForces: "",
    hasSocialSecurityNumber: "",
    isDomiciledInUS: "",
    intendToReestablishDomicile: "",
    Child:"",
    bussniesname:"",
//-------
    name:"",
    Middle:"",
    Family:"",
    care:"",
    streetnum:"",
    appartmentmail:"",
    numb:"",
    Cities:"",
    Country:"",
    State:"",
    Zips:"",
    Province:"",
    postal:"",
    maillingAddress:"",
    //physicaladdress
    Phystreetnum:"",
    Phyappartmentmail:"",
    Phynumb:"",
    PhyCities:"",
    PhyCountry:"",
    PhyState:"",
    PhyZips:"",
    PhyProvince:"",
    Phypostal:"",
    countryCitizen:"",
    email:"",
    Daytimephone:"",
    mobilephone:"",
    dob:"",
    countryofBirth:"",
    stOrprov:"",
    townBirth:"",
    AlienNum:"",
    SSNum:"",
    AccNum:"",
    //----------------
    Pfirstname:"",
    PMiddlename:"",
    PFamilyname:"",
    Principalcare:"",
    Principalstreetnum:"",
    Principalappartmentmail:"",
    Principalnumb:"",
    PrincipalCities:"",
    PrincipalCountry:"",
    PrincipalState:"",
    PrincipalZips:"",
    PrincipalProvince:"",
    Principalpostal:"",
    PrincipalcountryCitizen:"",
    Principaldob:"",
    immigratethrough:"",
    PrincipalDaytimephone:"",
    PSSNum:"",
    PAlienNum:"",
    PAccNum:"",
    childrenImigrate:"",
    /////-----------
    currently:"",
    Unemployed:"",
    Employer:"",
    Occupation:"",
    secondjob:"",
    secondjobdoing:"",
    selfOccupation:"",
    Employer2:"",
    income:"",
    nontaxable:"",
    marriedsponsor:"",
    childrens:"",
    dependents:"",
    Retired:"",
    sponsored:"",
    persons:"",
    exceeded:"",
    ffname:"",
    Middlenamehousehold:"",
    FamilyHoushold:"",
    relationshiphousehold:"",
    immigrants :"",
    householdsize:"",
    Income:"",
    usingjointsponsor:"",
/////
filedtax:"",
filefederaltax:"",
year:"",
taxincome:"",

year2:"",
taxincome2:"",
year3:"",
taxincome3:"",
///
affidavit:"",
prepared:"",
lang:"",
Ifirstname:"",
IMiddlename:"",
IFamilyname:"",
Interpreterstreetnum:"",
Interpreterappartmentmail:"",
Interpreternumb:"",
InterpreterCities:"",
InterpreterCountry:"",
 InterpreterState:"",
    InterpreterZips:"",
    InterpreterProvince:"",
    Interpreterpostal:"",
    InterpretercountryCitizen:"",
    Daytimephone:"",
    mobilephone:"",
    email:"",
    ischecked:false,
    Preparerfirstname:"",
    PreparerMiddlename:"",
    PreparerFamilyname:"",
    Preparerstreetnum:"",
    Preparerappartmentmail:"",
    Preparernumb:"",
    PreparerCities:"",
    PreparerCountry:"",
    PreparerState:"",
     PreparerZips:"",
        PreparerProvince:"",
        Preparerpostal:"",
        PreparercountryCitizen:"",
        PreparerDaytimephone:"",
        Preparermobilephone:"",
        Prepareremail:"",
        representation:"",
        Attorney:"",
        USCISAccount:""
  },



  
  };

// Create a provider component to manage the state
export function FormProviderFor864BForm({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);
  const [selectedCount, setSelectedCount] = useState(0);
  



  const handleSelectionChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setSelectedCount(count);

    // Initialize nameSets array based on the selected count
    const newSets = Array.from({ length: count }, (_, index) => ({ id: index + 1 }));
    setNameSets(newSets);
  };




  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };
  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text': // Include input type "text"
        case 'number': // Include input type "number"
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one': // Handle select option
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      };
    });
  };
 
  return (
    <FormContext864BForms.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange  ,handleSelectionChange ,selectedCount}}>
      {children}
    </FormContext864BForms.Provider>
  );
}