import React from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useState } from "react";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import picc from "../I-864/pic.png";
import picc2 from "../I-864/pic2.png";
import info from "../I-864/../../Assets/info.png";

import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";

const I8647= () => {
   const navigate =useNavigate()

  const { formData, handleInputChange ,code ,territories , nameSets ,handleAddAnother ,handleRemove ,handleChange ,selectedCount ,handleSelectionChange } =useFormContext()
  // const [selectedCount, setSelectedCount] = useState(0);
    const [showError, setShowError] = useState(false);
  
    // const handleSelectionChange = (event) => {
    //   const count = parseInt(event.target.value, 10);
    //   setSelectedCount(count);
    //   setShowError(false);
    // };
  
 
const handleSubmit=(e)=>{
e.preventDefault()
// console.log(formData)
}
    return(
    
        <div className="w-full">
          <Header />
          <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
          <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            USCIS Form I-864 - Affidavit of Support Package
          </span>
          <div className="h-full flex justify-end">
            {" "}
            {/* Added 'flex' and 'justify-end' */}
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>
        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2 style={{ marginBottom: "-1%" }}>
            {" "}
            Sponsor's Household

          </h2>
          <span className="txttop " style={{ color: "#888" }}>
            <p className=" p-0">
                &nbsp;&nbsp;

              Next, we need to get some information about your household size and household income to ensure your affidavit will sufficiently <br />  &nbsp;&nbsp; cover the intending immigrant(s).

            </p>
          </span>
        </div>
        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">

        <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Sponsor's Employment and Income

</b>
              </h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
              Enter your current, individual annual income that you are using to meet the requirements of this affidavit and indicate the total in the space provided. If you receive income from an employer and self-employment, you may list both. Please list the employer first.
             <a href="#"> What counts as income >>

                </a> </p>
            </div>
          </div>

        <form  onSubmit={handleSubmit}  className="mt-6 w-full ">
        <div className="additional-div">

        <div className="mt-2 font-thin ml-2 text ndRendring">
        I am currently:

                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="currently"
                  value="Employed with a company"
                  id="lostRadio"
                  onChange={handleInputChange}
                  checked={formData.currently === "Employed with a company"}
                />
                <label htmlFor="lostRadio">
                Employed with a company            </label>
                <hr />
                <input
                  type="radio"
                  name="currently"
                  value="Self Employed"
                  id="notReceivedRadio"
                  onChange={handleInputChange}
                  checked={formData.currently === "Self Employed"}
                />
                <label htmlFor="notReceivedRadio">
Self Employed
                </label>
                <hr />
                <input
                  type="radio"
                  name="currently"
                  value="Retired"
                  id="mutilatedRadio"
                  onChange={handleInputChange}
                  checked={formData.currently === "Retired"}
                />
                <label htmlFor="mutilatedRadio">
                Retired

                </label>
                <hr />
                <input
                  type="radio"
                  name="currently"
                  value="Unemployed"
                  id="mutilatedRadio1"
                  onChange={handleInputChange}
                  checked={formData.currently === "Unemployed"}
                />
                <label htmlFor="mutilatedRadio1">
                Unemployed
                </label>
              </div>
              </div>
{ formData.currently==="Self Employed"?(
  <div><br />
  <div className="mt-2 font-thin ml-2 text ndRendring">
  Occupation                <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="text"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="selfOccupation"
                      value={formData.selfOccupation}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
     <br />
     <br />
                  </div>   
  </div>
):null}

{ formData.currently==="Retired"?(
  <div><br />
  <div className="mt-2 font-thin ml-2 text ndRendring">
  Retired Since                <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="date"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="Retired"
                      value={formData.Retired}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
     <br />
     <br />
                  </div>   
  </div>
):null}

{ formData.currently==="Unemployed"?(
  <div><br />
  <div className="mt-2 font-thin ml-2 text ndRendring">
  Unemployed Since <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="date"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="Unemployed"
                      value={formData.Unemployed}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
     <br />
     <br />
                  </div>   
  </div>
):null}
{formData.currently==="Employed with a company"?(
  <div className="additional-div">

  <div className="mt-2 font-thin ml-2 text ndRendring">
  Name of Employer
                <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="text"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="Employer"
                      value={formData.Employer}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
     <br />
     <br />
                  </div>    
                  
                 <br /> 
                  <div className="mt-2 font-thin ml-2 text ndRendring">
                  Occupation                <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="text"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="Occupation"
                      value={formData.Occupation}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
          <br />
     <br />
                  </div>         
                  <br />

                <div className=" text-white font-bold p-2 ndRendring text">
                Do you have a second job that provides income?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="secondjob"
                  value="Yes"
                  id="secondjobinUSYess"
                  onChange={handleInputChange}
                  checked={formData.secondjob === "Yes"}
                />
                <label htmlFor="secondjobinUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="secondjob"
                  value="No"
                  id="secondjobinUSNos"
                  onChange={handleInputChange}
                  checked={formData.secondjob === "No"}
                />
                <label htmlFor="secondjobinUSNos">No</label>
              </div>


              {formData.secondjob==="Yes"?(
                <div>
<br />
<div className=" text-white font-bold p-2 ndRendring text">
In my second job, I am:
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="secondjobdoing"
                  value="Employed with a company"
                  id="secondjobinUSYesss"
                  onChange={handleInputChange}
                  checked={formData.secondjobdoing === "Employed with a company"}
                />
                <label htmlFor="secondjobinUSYesss">Employed with a company</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="secondjobdoing"
                  value="Self-employed"
                  id="secondjobinUSNoss"
                  onChange={handleInputChange}
                  checked={formData.secondjobdoing === "Self-employed"}
                />
                <label htmlFor="secondjobinUSNoss">Self-employed</label>
              </div>
                </div>
              ):null}


{formData.secondjobdoing==="Self-employed"|| formData.currently==="Self Employed"?(
  <div><br />
  <div className="mt-2 font-thin ml-2 text ndRendring">
  Occupation                <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="text"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="selfOccupation"
                      value={formData.selfOccupation}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
     <br />
     <br />
                  </div>   
  </div>
):null}
{formData.secondjobdoing==="Employed with a company"?(
  <div><br />
  <div className="mt-2 font-thin ml-2 text ndRendring">
  Name of Employer 2
                <span className="text-red-500">*</span>
                  </div>
  
                  <div className="form ">
                    <input
                      type="text"
                      className="form-control form-control-sm mt-3 ml-3"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="Employer2"
                      value={formData.Employer2}
                      onChange={handleInputChange}
                      maxLength={20}
                    />
     <br />
     <br />
                  </div>   
  </div>
):null}
                      </div>

                  
):null}
<div className="additional-div">

<div className="mt-2 font-thin ml-2 text ndRendring">
My total current individual income is:                  <span className="text-red-500">*</span>
                </div>

                <div className="form ">
                  <input
                    type="number"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="income"
                    value={formData.income}
                    onChange={handleInputChange}
                    maxLength={8}
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
Enter your total individual income from all sources (that you will earn this year). Enter whole U.S. dollars. Do not enter decimals, commas, or cents.  <a href="#">What counts as income >></a>          
       </div>
                </div>                </div>


<div className="additional-div">

                <div className=" text-white font-bold p-2 ndRendring text">
                Is any portion of your current individual income from non-taxable sources?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="nontaxable"
                  value="Yes"
                  id="inUSYess"
                  onChange={handleInputChange}
                  checked={formData.nontaxable === "Yes"}
                />
                <label htmlFor="inUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="nontaxable"
                  value="No"
                  id="inUSNos"
                  onChange={handleInputChange}
                  checked={formData.nontaxable === "No"}
                />
                <label htmlFor="inUSNos">No</label>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
Your non-taxable income won't show up on tax returns. If you answer "Yes" we'll provide you some additional information in your filing  <br />instructions. <a href="#">Explain more >></a> 

                  </div>
              </div> </div>
<br /><br />
              <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
              <div className="flex flex-col items-right space-y-0 p-2">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16">
                  <b>Sponsor's Household Size
</b>
                </h3>
              </span>
              <div className="text-left">
                <p className="ptag ml-16">
                <b>Important Note:</b> In this section, be careful not to count any member of your household more than once. If you have already listed a family member on this form, do not count them again here.
                </p>
              </div>
            </div>

            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              Are you currently married?
                <span className="text-red-500">*</span>
              </div>
              <div className="form p-2">
                <input
                  type="radio"
                  name="marriedsponsor"
                  value="Yes"
                  id="inUSYessa"
                  onChange={handleInputChange}
                  checked={formData.marriedsponsor === "Yes"}
                />
                <label htmlFor="inUSYessa">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="marriedsponsor"
                  value="No"
                  id="inUSNosa"
                  onChange={handleInputChange}
                  checked={formData.marriedsponsor === "No"}
                />
                <label htmlFor="inUSNosa">No</label>
              </div>
            </div>
           
            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              How many dependent children do you have? 
                              <span className="text-red-500">*</span>
              </div>
              <div className="form ">
              <select
                  class="form-control1"
                  aria-required="true"
                  required
                  name="childerns"
                  value={formData.childerns}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="childern"
                  className="form-control mt-3 ml-3"
                  style={{ width: "30%" }}

                >
                  <option value="Please Select">Please Select</option>
                  <option value="None">None</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>

                </select>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
Count all minor children unless you have already listed the child previously on this form  <u>Explain mopre{">>"} </u>          
       </div>
              </div>
              
            </div>

            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              How many additional dependents do you have? 
                              <span className="text-red-500">*</span>
              </div>
              <div className="form ">
              <select
                  class="form-control1"
                  aria-required="true"
                  required
                  name="dependents"
                  value={formData.dependents}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="dependents"
                  className="form-control mt-3 ml-3"
                  style={{ width: "30%" }}

                >
                  <option value="Please Select">Please Select</option>
                  <option value="None">None</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>

                </select>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >Count any other person who is claimed as a dependent on your most recent tax return <a href="#" 
                 >Explain mopre{">>"}</a>
       </div>
              </div>
              
            </div>
            <div className="additional-div">
            <div className=" text-white font-bold p-2 ndRendring text">
            Have you sponsored any other persons on Form I-864 or Form I-864EZ who are now lawful permanent residents?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="sponsored"
                  value="Yes"
                  id="yess"
                  onChange={handleInputChange}
                  checked={formData.sponsored  === "Yes"}
                />
                <label htmlFor="yess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="sponsored"
                  value="No"
                  id="nooo"
                  onChange={handleInputChange}
                  checked={formData.sponsored  === "No"}
                />
                <label htmlFor="nooo">No</label>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
Do not count the same person twice. For example, if you previously petitioned a permanent resident spouse, the spouse has already been accounted for. Do not count them here again.

                  </div>
              </div>
            </div>
            {formData.sponsored==="Yes"?(
              <div><div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              Select the number of persons:
                              <span className="text-red-500">*</span>
              </div>
              <div className="form ">
              <select
                  class="form-control1"
                  aria-required="true"
                  required
                  name="persons"
                  value={formData.persons}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="dependents"
                  className="form-control mt-3 ml-3"
                  style={{ width: "30%" }}

                >
                  <option value="Please Select">Please Select</option>
                  <option value="None">None</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>

                </select>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >Count any other person who is claimed as a dependent on your most recent tax return <a href="#" 
                 >Explain mopre{">>"}</a>
       </div>
              </div>
              
            </div></div>
            ):null}
        <br />
       
        <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
        <br />
        <div className="additional-div" style={{width:"90%",marginLeft:"5%"} }>

<img src={picc} alt="" srcset="" />
<br />
{formData.income>50000?(
 <div> <div
style={{
  backgroundColor: "#92D3A8",
  padding: "10px",
  margin: "10px",
  color: 'rgba(60, 60, 60, 0.7)',
  textAlign: "center",
  borderRadius: "9px",
  width: "90%",
  marginLeft: "6%",
}}
>
<div style={{ display: "flex", alignItems: "center" }}>
  <span className="icon">
    <img src={sucess} alt="" width="62" height="62" />
  </span>
  <div style={{ marginLeft: "10px", textAlign: "left" }}>
    <p style={{ color: "#006400", fontWeight: "bold",                color: 'rgba(60, 60, 60, 0.7)',
}}>
Good News!    </p>
    <p>
    Your income exceeds the minimum income requirement to support your household size. For a complete listing of minimum income requirements by household size, please visit the HHS Poverty Guidelines for Affidavit of Support. You may now add additional household members' income or continue to the next page.
    </p>
  </div>
</div>

</div>
<div className="additional-div">
            <div className=" text-white font-bold p-2 ndRendring text">
            Even though you have exceeded the minimum income requirements, would you like to report income from additional household members, or use a joint sponsor?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="exceeded"
                  value="Yes"
                  id="yess1"
                  onChange={handleInputChange}
                  checked={formData.exceeded   === "Yes"}
                />
                <label htmlFor="yess1">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="exceeded"
                  value="No"
                  id="nooo1"
                  onChange={handleInputChange}
                  checked={formData.exceeded   === "No"}
                />
                <label htmlFor="nooo1">No</label>
           <br /> <br />
              </div>
            </div>
</div>
):null}
</div>
{formData.exceeded ==="Yes"?(
  <div>
        <br />
        <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
        <div className="flex flex-col items-right space-y-0 p-2">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16">
                  <b>Option A: Use Income From Household Members

</b>
                </h3>
              </span>
              <div className="text-left">
                <p className="ptag ml-16">
                Persons that live in the same principal residence as you may combine their income with yours to increase your total household income. <u>Explain more >></u> 


                </p>
              </div>
            </div>
            <div className="additional-div">
                        <div className=" text-white font-bold p-2 ndRendring text">

      <label>Select the number of household members who will combine their income with yours:</label><span className="text-red-500">*</span> </div>
      <div>
      <div className="form">
        <select
          className="form-control1"
          aria-required="true"
          required
          name="dependents"
          onChange={handleSelectionChange}
          id="dependents"
          style={{ width: "30%" }}
          value={selectedCount}
        >
          <option value={0}>Please Select</option>
          <option value={0}>None</option>
          {[1, 2, 3, 4].map((count) => (
            <option key={count} value={count}>
              {count}
            </option>
          ))}
        </select>
        <br />
        <br />
        <div
          style={{ backgroundColor: "#E7E7E7", color: "#888" }}
          className="mt-2"
        >
          If you do not have any household members that can contribute to household income, select "None."
        </div>
      </div>
      <br />

      <div className="mt-20">
        {nameSets.map((nameSet, index) => (
       <div>   
    <div key={nameSet.id} className="formruntime">
            {index > -1 && (
        <div className="flex w-full justify-between ml-2">
          {/* <span className="text-gray-500"></span> */}
          <h3 className="heading33 ml-12">Household Member {index+1}</h3>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4"
          >
            Remove
          </button>
        </div>
      )}

            {/* Input Fields for Given Name, Family Name, and Last Name */}
            <div className="forms">
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                Given Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={`givenName-${nameSet.id}`}
                name={`givenName-${nameSet.id}`}
                className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
              />
            </div>
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-700">
                Family Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={`familyName-${nameSet.id}`}
                name={`familyName-${nameSet.id}`}
                className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
              />
            </div>
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`lastName-${nameSet.id}`} className="text-blue-700">
                Last Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={`lastName-${nameSet.id}`}
                name={`lastName-${nameSet.id}`}
                className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
              />
            </div>
            <label htmlFor="family">
Relationship to Sponsor:<span className="text-red-500">*</span>{" "}
</label>
&nbsp; &nbsp;
<select class="form-control1"
aria-required="true"
required
name={`relationshiphousehold-${nameSet.id}`}

id={`relationshiphousehold-${nameSet.id}`}
autocomplete="section-P5_Physical_Address_Domestic address-level1"
id="dependents"
className="form-control mt-3 ml-3"
style={{ width: "30%" }}>
<option value="" selected="selected">Please Select...</option><option value="Spouse">Spouse</option><option value="Son">Son</option><option value="Daughter">Daughter</option><option value="Mother">Mother</option><option value="Father">Father</option><option value="Sister">Sister</option><option value="Brother">Brother</option><option value="Other">Other</option></select>
<br />      <br />      

<div className="w-40">
<div className="w-40">
<label htmlFor="family">
Is this person also one of the intending immigrants that you want to sponsor?
<span className="text-red-500">*</span>
</label>
</div>
<div className="radioContainer">
<input
type="radio"
name="immigrants"
value="Yes"
id="inUSYessad"
onChange={handleInputChange}
checked={formData.immigrants  === "Yes"}
/>  
<label htmlFor="inUSYessad">Yes</label>
&nbsp;&nbsp;&nbsp;<input
type="radio"
name="immigrants"
value="No"
id="inUSNosad"
onChange={handleInputChange}
checked={formData.immigrants  === "No"}
/>
<label htmlFor="inUSNosad">No</label>
</div>
{formData.immigrants==="Yes"?(
  <div>  <div>

<label htmlFor="family">
Does this person have a spouse and/or children that will immigrate at the same time or within 6 months?<span className="text-red-500">*</span>
</label>
</div>
<div className="radioContainer">
<input
type="radio"
name="immigrants"
value="Yes"
id="inUSYessadss"
onChange={handleInputChange}
checked={formData.immigrants  === "Yes"}
/>  
<label htmlFor="inUSYessadss">Yes</label>
&nbsp;&nbsp;&nbsp;<input
type="radio"
name="immigrants"
value="No"
id="inUSNosads"
onChange={handleInputChange}
checked={formData.immigrants  === "No"}
/>
<label htmlFor="inUSNosads">No</label>
</div></div>


):null}
<br />
<div className="w-40">
<label htmlFor="family" >
Have you previously counted this person in your household size?
<span className="text-red-500">*</span>
</label>

<div className="mt-1  lbl2">
<input
type="radio"
id="previouslyCountedYes"
name="householdsize"
value="Yes"
className="mr-2"
onChange={handleInputChange}
checked={formData.householdsize === "Yes"}
/>
<label htmlFor="previouslyCountedYes" >
Yes
</label>

<input
type="radio"
id="previouslyCountedNos"
name="householdsize"
value="No"
className="ml-4 mr-2"
onChange={handleInputChange}

checked={formData.householdsize === "No"}
/>
<label htmlFor="previouslyCountedNos" >
No
</label>
<br />
<u>Explain more>></u>


</div>


</div>
</div>
<br />
<label htmlFor="Income">
Current Income:<span className="text-red-500">*</span>{" "}
</label>
&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;                     &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;


<input

type="text"
id={`Income-${nameSet.id}`}
name={`Income-${nameSet.id}`}

style={{
padding: "5px",
marginTop: "5px",
marginBottom: "5px",
borderRadius: "5px",
}}
/>
    </div>
          
          </div>
          <br />      <br />
</div>
        ))}

     
<div className="coll error">
                <div className="icon-and-message">
                
                <img src={info} alt="" srcset="" style={{width:"2%"}} />
   <p className="message ml-2">
                    <span className="font-bold">
                  <b>For Your Information: </b>            </span>
                  For your information: Each household member listed above will need to complete a Form I-864A that will be filed with this primary Form I-864. Some exceptions apply if the person is also one of the intending immigrants. We'll also help you prepare Form I-864A if necessary.                   </p>
                </div>
              </div>  
        <button
          type="button"
          onClick={handleAddAnother}
          className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black mr-4"
        >
          Add Another Name
        </button>
      </div>
     

    </div>


    
    </div>
<br />
    <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
<div className="additional-div" style={{width:"90%",marginLeft:"5%"} }>

<img src={picc2} alt="" srcset="" />  </div>  
<hr style={{ backgroundColor: "#0072bc", height: "3px" }} />

<div className="flex flex-col items-right space-y-0 p-2">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16">
                  <b>Option B: Use A Joint Sponsor


</b>
                </h3>
              </span>
              <div className="text-left">
                <p className="ptag ml-16">
                You also have the option of using a joint sponsor. A joint sponsor can be someone who is not related to you and doesn't live with you. Generally, you do not need a joint sponsor if household exceeds the income requirement. A joint sponsor may be your only option if your income is not meeting the requirement. <u>Explain more >></u>

                </p>
              </div>
            </div>
<div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              Will you be using a joint sponsor?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="usingjointsponsor"
                  value="Yes"
                  id="inUSYess"
                  onChange={handleInputChange}
                  checked={formData.usingjointsponsor === "Yes"}
                />
                <label htmlFor="inUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="usingjointsponsor"
                  value="No"
                  id="inUSNos"
                  onChange={handleInputChange}
                  checked={formData.usingjointsponsor === "No"}
                />
                <label htmlFor="inUSNos">No</label>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
A joint sponsor can be someone who is not related to you and doesn't live with you. Explain more >>

                  </div>
              </div>
              
            </div>
<br />
            {formData.usingjointsponsor==="Yes"?(
                <div className="coll error">
                <div className="icon-and-message">
                
                <img src={info} alt="" srcset="" style={{width:"2%"}} />
   <p className="message ml-2">
                    <span className="font-bold">
                  <b>For Your Information: </b>            </span>
                  To use a joint sponsor, you must finish completing this I-864 affidavit. Then, the joint sponsor must prepare a separate affidavit. We will provide more detailed directions in your filing instructions.                     </p>
                </div>
              </div>  
            ):null}
</div>
):null}

<br /><br />

          <div className="w-full ml-8">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded mt-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
                onClick={()=>navigate()}
              >
                NEXT → 
              </button>
            </div>           
</form>
        </div>
        <FormFotter />
    </div>
    </div>

    );

};
export default I8647;