


import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context751';
import  crosspic from "../../Assets/crosspic.png"

const InformationAboutYou = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/progress") 
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
    <div className='flex flex-col rounded-full'>
  <div className='p-4 flex flex-col md:flex-row gap-10'>
    <label htmlFor="firstName" className='text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center md:text-left'>
      Given Name (First Name)
    </label>
    <input
      className="w-full md:w-auto border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded mt-2 md:ml-6"
      type="text"
      id="firstName"
      name="firstName"
      value={formData.firstName}
      onChange={handleInputChange}
    />
  </div>

  <div className='p-4 flex flex-col md:flex-row gap-10'>
    <label htmlFor="middleNames" className='text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center md:text-left'>
      Middle Name(s)
    </label>
    <input
      type="text"
      id="middleNames"
      name="middleNames"
      value={formData.middleNames}
      onChange={handleInputChange}
      className="w-full md:w-auto border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded mt-2 md:ml-6"
    />
  </div>

  <div className='p-4 flex flex-col md:flex-row gap-10'>
    <label htmlFor="lastName" className='text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center md:text-left'>
      Family Name (Last Name)
    </label>
    <input
      type="text"
      id="lastName"
      name="lastName"
      value={formData.lastName}
      onChange={handleInputChange}
      className="w-full md:w-auto border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded mt-2 md:ml-6"
    />
  </div>
</div>



    <div className='flex flex-col  rounded-full mt-10'>
    
        <label className='bg-blue-700 flex text-white rounded p-4'>
          Have you ever used another name (such as a maiden name)?
        </label>
        <div className='flex gap-4 p-4 bg-blue-100'>
          <label htmlFor="usedAnotherNameYes" className='text-blue-700 flex items-center gap-1'>
            <input
              type="radio"
              id="usedAnotherNameYes"
              name="usedAnotherName"
              value="Yes"
              checked={formData.usedAnotherName === 'Yes'}
              onChange={handleInputChange}
              className='form-radio  text-blue-500'
            />
            Yes
          </label>
          <label htmlFor="usedAnotherNameNo" className='text-blue-700 flex items-center gap-1'>
            <input
              type="radio"
              id="usedAnotherNameNo"
              name="usedAnotherName"
              value="No"
              checked={formData.usedAnotherName === 'No'}
              onChange={handleInputChange}
              className='form-radio text-blue-500'
            />
            No
          </label>
        </div>
        <p className='p-2 bg-slate-200'>
        Indicate if your current name (as listed above) is different than the name printed on your most recent Permanent Resident Card.

        </p>
     
    </div>



{formData.usedAnotherName === 'Yes'
&&
    <div className="mt-10">
      {nameSets.map((nameSet, index) => (
        <div key={nameSet.id} className="bg-slate-50 rounded border border-blue-200 mt-10">
          {index > 0 && (
            <div className="flex w-full justify-between ml-2">
              <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
              <button
                type="button"
                onClick={() => handleRemove(nameSet.id)}
                className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
              >
                Remove
                <img src={crosspic} alt="" />
              </button>
            </div>
          )}

          <div className="mb-4 flex mt-6 ml-2">
            <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700 w-[180px]">
              Given Name (First Name): <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`givenName-${nameSet.id}`}
              name={`givenName-${nameSet.id}`}
              className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
              value={nameSet.givenName || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'givenName')}
            />
          </div>

          <div className="mb-4 flex mt-6 ml-2">
            <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700 w-[180px]">
              Middle Name (First Name): <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`middleName-${nameSet.id}`}
              name={`middleName-${nameSet.id}`}
              className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
              value={nameSet.middleName || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'middleName')}
            />
          </div>

          <div className="mb-4 flex mt-6 ml-2">
            <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-700 w-[180px]">
              Family Name (Last Name): <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id={`familyName-${nameSet.id}`}
              name={`familyName-${nameSet.id}`}
              className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
              value={nameSet.familyName || ''}
              onChange={(e) => handleChange(e, nameSet.id, 'familyName')}
            />
          </div>


          {/* ... Other input fields ... */}
        </div>
      ))}

      {nameSets.length === 0 && (
        <div>
          <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
          <span className="text-gray-500">Add Other</span>
        </div>
      )}

      <button
        type="button"
        onClick={handleAddAnother}
        className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
      >
        Add Another Name
      </button>
    </div>
}



<hr className='h-1 bg-blue-600 w-full mt-10'/>




<div className="mt-10">
  {nameSets.map((nameSet, index) => (
    <div key={nameSet.id} className="bg-slate-50 rounded border border-blue-200 mt-10">
      {index > 0 && (
        <div className="flex w-full justify-between ml-2">
          <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
          >
            Remove
            <img src={crosspic} alt="" />
          </button>
        </div>
      )}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`streetNumberName-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    Street Number and Name: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`streetNumberName-${nameSet.id}`}
    name={`streetNumberName-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded mt-2 md:ml-2"
    value={nameSet.streetNumberName || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'streetNumberName')}
  />
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`aptSuiteFloor-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    Is there an apartment, suite, or floor?: <span className="text-red-500">*</span>
  </label>
  <select
    id={`aptSuiteFloor-${nameSet.id}`}
    name={`aptSuiteFloor-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.aptSuiteFloor || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'aptSuiteFloor')}
  >
    <option value="">Select Option</option>
    <option value="No">No</option>
    <option value="Apt">Apt</option>
    <option value="Suite">Suite</option>
    <option value="Floor">Floor</option>
  </select>
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`cityOrTown-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    City or Town: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`cityOrTown-${nameSet.id}`}
    name={`cityOrTown-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.cityOrTown || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'cityOrTown')}
  />
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`stateOrTerritory-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    State (or Territory): <span className="text-red-500">*</span>
  </label>
  <select
    id={`stateOrTerritory-${nameSet.id}`}
    name={`stateOrTerritory-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.stateOrTerritory || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'stateOrTerritory')}
  >
    <option value="">Select a Country</option>
    {code.map((countryName) => (
      <option key={countryName} value={countryName}>
        {countryName}
      </option>
    ))}
  </select>
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`zipCode-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    ZIP Code: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`zipCode-${nameSet.id}`}
    name={`zipCode-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.zipCode || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'zipCode')}
  />
</div>

      {/* ... Other input fields ... */}
    </div>
  ))}

  {nameSets.length === 0 && (
    <div>
      <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
      <span className="text-gray-500">Add Other</span>
    </div>
  )}
</div>



<div className="flex flex-col  mt-10">
      <div className="">
        <label className="bg-blue-700   text-white rounded  p-4 flex ">
          Have you resided at any other address since you became a conditional resident?
        </label>
        <div className="flex gap-4 bg-blue-100 p-4">
          <label htmlFor="residedAtOtherAddressYes" className="text-blue-700 flex items-center gap-1">
            <input
              type="radio"
              id="residedAtOtherAddressYes"
              name="residedAtOtherAddress"
              value="Yes"
              checked={formData.residedAtOtherAddress === 'Yes'}
              onChange={handleInputChange}
              className="form-radio  text-blue-500"
            />
            Yes
          </label>
          <label htmlFor="residedAtOtherAddressNo" className="text-blue-700 flex items-center gap-1">
            <input
              type="radio"
              id="residedAtOtherAddressNo"
              name="residedAtOtherAddress"
              value="No"
              checked={formData.residedAtOtherAddress === 'No'}
              onChange={handleInputChange}
              className="form-radio  text-blue-500"
            />
            No
          </label>
        </div>
      </div>

    



    {formData.residedAtOtherAddress === 'Yes'
        &&
        <>
        <div className="flex flex-col  rounded-full  mt-10">
      <div className="">
        <label htmlFor="moveDate" className="bg-blue-700 text-white  rounded flex p-4 ">
          When did you move to your current address?
        </label>
    <div className='flex bg-blue-100 p-4'>
        <input
          type="date"
          id="moveDate"
          name="moveDate"
          value={formData.moveDate}
          onChange={handleInputChange}
          className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
        />
        </div>
      </div>

      {/* Additional Form Fields and Components as needed */}
    </div>


    <div className='bg-slate-200 p-2 rounded flex gap-2 mt-10'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Info_30.png" alt=""  className='w-[40px] h-[40px]'/>
        <p>List each address that you have resided since becoming a conditional resident. Enter the dates at each address.</p>
    </div>




    <div className="mt-10">
  {nameSets.map((nameSet, index) => (
    <div key={nameSet.id} className="bg-slate-50 rounded border border-blue-200 mt-10">
      {index > 0 && (
        <div className="flex w-full justify-between ml-2">
          <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
          >
            Remove
            <img src={crosspic} alt="" />
          </button>
        </div>
      )}

      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor={`streetNumberName2-${nameSet.id}`} className="text-blue-700 w-[180px]">
          Street Number and Name: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id={`streetNumberName2-${nameSet.id}`}
          name={`streetNumberName2-${nameSet.id}`}
          className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
          value={nameSet.streetNumberName2 || ''}
          onChange={(e) => handleChange(e, nameSet.id, 'streetNumberName2')}
        />
      </div>

      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor={`aptSuiteFloor2-${nameSet.id}`} className="text-blue-700 w-[180px]">
          Is there an apartment, suite, or floor?: <span className="text-red-500">*</span>
        </label>
        <select
          id={`aptSuiteFloor2-${nameSet.id}`}
          name={`aptSuiteFloor2-${nameSet.id}`}
          className="-[260px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
          value={nameSet.aptSuiteFloor2 || ''}
          onChange={(e) => handleChange(e, nameSet.id, 'aptSuiteFloor2')}
        >
          <option value="">Select Option</option>
          <option value="No">No</option>
          <option value="Apt">Apt</option>
          <option value="Suite">Suite</option>
          <option value="Floor">Floor</option>
        </select>
      </div>

      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor={`cityOrTown2-${nameSet.id}`} className="text-blue-700 w-[180px]">
          City or Town: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id={`cityOrTown2-${nameSet.id}`}
          name={`cityOrTown2-${nameSet.id}`}
          className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
          value={nameSet.cityOrTown2 || ''}
          onChange={(e) => handleChange(e, nameSet.id, 'cityOrTown2')}
        />
      </div>

      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor={`stateOrTerritory2-${nameSet.id}`} className="text-blue-700 w-[180px]">
          State (or Territory): <span className="text-red-500">*</span>
        </label>
        <select
          id={`stateOrTerritory2-${nameSet.id}`}
          name={`stateOrTerritory2-${nameSet.id}`}
          className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
          value={nameSet.stateOrTerritory2 || ''}
          onChange={(e) => handleChange(e, nameSet.id, 'stateOrTerritory2')}
        >
          <option value="">Select a State or Territory</option>
          {/* Map through the states or territories code */}
        </select>
      </div>

      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor={`zipCode2-${nameSet.id}`} className="text-blue-700 w-[180px]">
          ZIP Code: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id={`zipCode2-${nameSet.id}`}
          name={`zipCode2-${nameSet.id}`}
          className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
          value={nameSet.zipCode2 || ''}
          onChange={(e) => handleChange(e, nameSet.id, 'zipCode2')}
        />
      </div>

      {/* ... Other input fields ... */}
    </div>
  ))}

  {nameSets.length === 0 && (
    <div>
      <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
      <span className="text-gray-500">Add Other</span>
    </div>
  )}
</div>

</>
}



<div className="mt-10">
        <label className="bg-blue-700 text-white mt-10 rounded flex p-4">
          Is your mailing address different than the Current Physical Address that you listed above?
        </label>
        <div className="flex gap-4 bg-blue-100  rounded p-4">
          <label htmlFor="differentMailingAddressYes" className="text-blue-700 flex items-center gap-1">
            <input
              type="radio"
              id="differentMailingAddressYes"
              name="differentMailingAddress"
              value="Yes"
              checked={formData.differentMailingAddress === 'Yes'}
              onChange={handleInputChange}
              className="form-radio  text-blue-500"
            />
            Yes
          </label>
          <label htmlFor="differentMailingAddressNo" className="text-blue-700 flex items-center gap-1">
            <input
              type="radio"
              id="differentMailingAddressNo"
              name="differentMailingAddress"
              value="No"
              checked={formData.differentMailingAddress === 'No'}
              onChange={handleInputChange}
              className="form-radio  text-blue-500"
            />
            No
          </label>
        </div>
      </div>

      {/* Additional Form Fields and Components as needed */}
    </div>


{formData.differentMailingAddress === 'Yes'
&&
<div className="mt-10">
  {nameSets.map((nameSet, index) => (
    <div key={nameSet.id} className="bg-slate-50 rounded border border-blue-200 mt-10">
      {index > 0 && (
        <div className="flex w-full justify-between ml-2">
          <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
          >
            Remove
            <img src={crosspic} alt="" />
          </button>
        </div>
      )}

      <div className='p-2'>
        <h1 className='text-xl font-semiboild text-blue-600'>Mailing Address</h1>
        <p className='text-sm'>Your mailing address is where you prefer to receive mail. If your mailing address is different than your current physical address, list your complete mailing address in the spaces provided. This may be a Post Office address (P.O. Box) if that is how you receive your mail.

</p>
      </div>



      <div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`streetNumberName3-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    Street Number and Name: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`streetNumberName3-${nameSet.id}`}
    name={`streetNumberName3-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded mt-2 md:ml-2"
    value={nameSet.streetNumberName3 || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'streetNumberName3')}
  />
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`aptSuiteFloor3-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    Is there an apartment, suite, or floor?: <span className="text-red-500">*</span>
  </label>
  <select
    id={`aptSuiteFloor3-${nameSet.id}`}
    name={`aptSuiteFloor3-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.aptSuiteFloor3 || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'aptSuiteFloor3')}
  >
    <option value="">Select Option</option>
    <option value="No">No</option>
    <option value="Apt">Apt</option>
    <option value="Suite">Suite</option>
    <option value="Floor">Floor</option>
  </select>
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`cityOrTown3-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    City or Town: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`cityOrTown3-${nameSet.id}`}
    name={`cityOrTown3-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.cityOrTown3 || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'cityOrTown3')}
  />
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`stateOrTerritory2-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    State (or Territory): <span className="text-red-500">*</span>
  </label>
  <select
    id={`stateOrTerritory3-${nameSet.id}`}
    name={`stateOrTerritory3-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.stateOrTerritory3 || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'stateOrTerritory3')}
  >
    <option value="">Select a State or Territory</option>
    {/* Map through the states or territories code */}
  </select>
</div>

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`zipCode2-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] mb-2 md:mb-0 text-center md:text-left">
    ZIP Code: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`zipCode3-${nameSet.id}`}
    name={`zipCode3-${nameSet.id}`}
    className="w-full md:w-auto border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={nameSet.zipCode3 || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'zipCode3')}
  />
</div>

      {/* ... Other input fields ... */}
    </div>
  ))}

  {nameSets.length === 0 && (
    <div>
      <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
      <span className="text-gray-500">Add Other</span>
    </div>
  )}
</div>}


<hr  className='bg-blue-600 h-1 mt-10'/>




<div className="flex flex-col mt-10 ">
      <label htmlFor="maritalStatus" className="p-4 bg-blue-600 text-white rounded">
        What is your current marital status?
      </label>
      <div className="flex flex-col md:flex-row gap-4 p-4 bg-blue-100">
        <label htmlFor="married" className="text-blue-700 flex gap-1">
          <input
            type="radio"
            name="maritalStatus"
            id="married"
            value="Married"
            checked={formData.maritalStatus === 'Married'}
            onChange={handleInputChange}
          />
          Married
        </label>
        <label htmlFor="single" className="text-blue-700 flex gap-1">
          <input
            type="radio"
            name="maritalStatus"
            id="single"
            value="Single"
            checked={formData.maritalStatus === 'Single'}
            onChange={handleInputChange}
          />
          Single
        </label>
        <label htmlFor="divorced" className="text-blue-700 flex gap-1">
          <input
            type="radio"
            name="maritalStatus"
            id="divorced"
            value="Divorced"
            checked={formData.maritalStatus === 'Divorced'}
            onChange={handleInputChange}
          />
          Divorced
        </label>
        <label htmlFor="widowed" className="text-blue-700 flex gap-1">
          <input
            type="radio"
            name="maritalStatus"
            id="widowed"
            value="Widowed"
            checked={formData.maritalStatus === 'Widowed'}
            onChange={handleInputChange}
          />
          Widowed
        </label>
      </div>
      <p className='bg-slate-200 p-2'>Select the marital status you have on the date you are filing this petition. If you are separated, select "Married." Separation means that you are living apart from your spouse, but you're still legally married.</p>
      
      
      
      
      <div className="mb-4 flex flex-col mt-6">
        <label htmlFor="dateOfMarriage" className="bg-blue-700 text-white p-4 rounded">
          Date of Marriage:
        </label>
    <div className='bg-blue-100 p-4 rounded'>
        <input
          className="border  w-full md:w-[300px] border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
          type="date"
          id="dateOfMarriage"
          name="dateOfMarriage"
         
          value={formData.dateOfMarriage}
          onChange={handleInputChange}
        />
        </div>
        <p className='bg-slate-200 p-2'>Enter the place (city, state, country) of your current marriage.</p>
      </div>

      <div className="mb-4 flex flex-col mt-10">
  <label htmlFor="placeOfMarriage" className="bg-blue-700 text-white p-4 rounded">
    Place of Marriage:
  </label>
  <div className='bg-blue-100 text-white p-4'>
    <input
      type="text"
      id="placeOfMarriage"
      name="placeOfMarriage"
      className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
      value={formData.placeOfMarriage}
      onChange={handleInputChange}
    />
  </div>
</div>

      <div className="mb-4 flex flex-col mt-10">
        <label htmlFor="isDifferentMarriage" className="bg-blue-700 text-white p-4">
          Is your current marriage different than the marriage through which conditional residence status was obtained?
        </label>
        <div className="flex flex-col md:flex-row bg-blue-100 gap-2 p-4">
          <label htmlFor="yesDifferentMarriage" className="text-blue-700">
            <input
              type="radio"
              id="yesDifferentMarriage"
              name="isDifferentMarriage"
              value="Yes"
              checked={formData.isDifferentMarriage}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label htmlFor="noDifferentMarriage" className="text-blue-700">
            <input
              type="radio"
              id="noDifferentMarriage"
              name="isDifferentMarriage"
              value="No"
              checked={!formData.isDifferentMarriage}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>

      {/* Additional Form Fields and Components as needed */}
    </div>
        
            
    





<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>``



    
   </form>
   <FormFotter />

</div>
</div>

  )
  
}

export default InformationAboutYou