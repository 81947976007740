
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextG639';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';

const SubjectInformation = () => {
    const { formData, handleInputChange ,code  ,handleSubmitG639} = useFormContext();

    console.log(formData.requestType)
     const navigate =useNavigate()
   const HandleSubmit =(e)=>{
        e.preventDefault()
        navigate("/SubjectFile")
   }
return (
  <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
  <Header />
  <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
    <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
      <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
      <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
      <div className='h-full'>
        <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
        <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
      </div>
    </div>

    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
        <span className='mt-4 font-bold text-xl ml-2 text-black'>
        Address Information
        </span>
        <div className='flex justify-between items-center gap-4'>
          <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
            <button className='mr-2'>Page 1 of 3</button>
            <button><MdArrowDropDown /></button>
          </div>
          <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
            <AiOutlineShoppingCart />
          </div>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>
      First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
      </span>
    </div>
    <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>


       <div className="">
         <h1 className="text-blue-600 text-xl font-semibold">Subject's Information</h1>
       </div>

<label htmlFor="dateOfBirth" className='flex bg-blue-600 text-white rounded p-4 mt-10'>Date of Birth</label>
<div className='flex bg-blue-100 p-4'>
<input
className=" w-[300px]  mr-2 border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
  type="date"
  id="dateOfBirth" // Add an "id" attribute
  name="dateOfBirth"
  value={formData.dateOfBirth}
  onChange={handleInputChange}
/>
</div>
<p className='bg-slate-200'>Enter the birth date for the Subject of Record.</p>


<div className='mt-10  flex flex-col'>

<label htmlFor="countryofbirth"  className="flex  bg-blue-600 text-white p-4 rounded">Country of Birth</label>
<div className='flex bg-blue-100 p-4 rounded'>
<select
className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
  id="countryofbirth"
  name="countryofbirth"
  value={formData.countryofbirth}
  onChange={handleInputChange}
>
   <option value="">Select a Country</option>
  {code.map((countryName) => (
    <option key={countryName} value={countryName}>
      {countryName}
    </option>
  ))}

</select>
</div>
</div>
<p className='bg-slate-200'>Select the birth country for the Subject of Record or select "Other" to provide another country name.</p>


<div className='flex mt-10 gap-10'>
<div className=' flex-1'>
<label htmlFor="alienRegistrationNumber" className='bg-blue-600 text-white p-4 flex rounded'>Alien Registration Number</label>
<div className='flex bg-blue-100 p-4 rounded'>
<span className="bg-slate-300 flex items-center justif-center p-2 rounded">A-</span>
<input
 className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded"
  type="text"
  id="alienRegistrationNumber"
  name="alienRegistrationNumber"
  value={formData.alienRegistrationNumber}
  onChange={handleInputChange}
/>
</div>
</div>
<div className='flex-1'>
    <img src="https://citizenpath.com/cpwp/wp-content/uploads/2021/08/PRC-ANumber-New-Zoom.jpg" alt="" className='w-[500px] h-[200px]' />
</div>


</div>


   <hr className="h-1 bg-blue-600 mt-10"/>
  


   <div className="mt-10">
  <h1 className="text-blue-600 text-xl font-semibold">Subject of Record</h1>
   <p className="text-sm">Indicate the Subject's full name at the time of entry into the United States. If the Subject entered the country lawfully, refer to the I-94 Arrival/Departure Record or passport that was used to enter the U.S. If the Subject entered without documentation and was caught at the border, use the name or alias that was used at that time. Spell out names completely and do not use just the initial.</p>
   </div>


   <div className='flex gap-10 mt-10'>
 <label className="w-[200px]  text-blue-500">Given Name (First Name)</label>
 <input
   className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
   type="text"
   name="SubjectfirstName"
   value={formData.SubjectfirstName}
   onChange={handleInputChange}
 />
</div>

   <div className='flex gap-10 mt-10'>
 <label className="w-[200px]  text-blue-500">Middle Name</label>
 <input
className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
   type="text"
   name="SubjectmiddleName"
   value={formData.SubjectmiddleName}
   onChange={handleInputChange}
 />
</div>

<div className='flex gap-10 mt-10'> 
 <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
 <input
   className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
   type="text"
   name="SubjectlastName"
   value={formData.SubjectlastName}
   onChange={handleInputChange}
 />
</div>
<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">I-94 Admission Number</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="i94AdmissionNumber"
    value={formData.i94AdmissionNumber}
    onChange={handleInputChange}
  />
  
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Passport or Travel Document Number</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="passportOrTravelDocumentNumber"
    value={formData.passportOrTravelDocumentNumber}
    onChange={handleInputChange}
  />
 
</div>







<div className='flex  text-white mt-32   w-[100px] h-[50px] ml-auto'>
          <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
            <button
              type="submit"
              className='text-white   rounded'
              onClick={handleSubmitG639}
            >
              Next
            </button>
            <BsArrowRight />
          </div>
        </div>
        <div className='flex justify-end mt-2'>
          <span>or <span className='text-blue-500'>Save Your progress?</span></span>
        </div>


   
  </form>
  <FormFotter />

    </div>
    </div>

)
}

export default SubjectInformation