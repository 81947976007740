

import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextG639';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';

const SubjectFile = () => {
    const { formData, handleInputChange ,code ,nameSets ,handleAddAnother ,handleRemove ,handleChange } = useFormContext();

    console.log(formData.requestType)

   const HandleSubmit =(e)=>{
        e.preventDefault()
   }
return (
  <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
  <Header />
  <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
    <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
      <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
      <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
      <div className='h-full'>
        <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
        <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
      </div>
    </div>

    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
        <span className='mt-4 font-bold text-xl ml-2 text-black'>
        Address Information
        </span>
        <div className='flex justify-between items-center gap-4'>
          <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
            <button className='mr-2'>Page 1 of 3</button>
            <button><MdArrowDropDown /></button>
          </div>
          <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
            <AiOutlineShoppingCart />
          </div>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>
      First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
      </span>
    </div>
    <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

     <div>
        <h1 className='text-blue-600 text-xl font-semibold'>Application, Petition, or Request Receipt Number</h1>
        <p className='text-sm'>Enter the USCIS receipt number that corresponds to any application, petition, or request the Subject has previously filed with USCIS. The 13-digit receipt number generally begins with three letters and ends with 10 numbers. (e.g. SRC 0123456789)</p>
     </div>

     <div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Receipt Number</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="receiptNumber"
    value={formData.receiptNumber}
    onChange={handleInputChange}
  />
</div>
<hr  className='bg-blue-600 mt-10 w-full h-1'/>

<div>
        <h1 className='text-blue-600 text-xl font-semibold'>USCIS Online Account Number</h1>
        <p className='text-sm'>A USCIS online account number is not the same as an A-Number. If the subject of this request was issued a USCIS online account number, enter it in the space provided. If there is no online account number or you do not know, you may leave it blank.</p>
     </div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px] text-blue-500">Account Number (if any)</label>
  <input
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    type="text"
    name="accountNumber"
    value={formData.accountNumber}
    onChange={handleInputChange}
  />
</div>


<hr  className='bg-blue-600 w-full h-1 mt-10'/>

<div className='mt-10'>
        <h1 className='text-blue-600 text-xl font-semibold'>Subject's Father</h1>
        
    
    
     </div>
     

     {!formData.fatherNameUnknown && (
  <div>
    <div className='flex gap-10 mt-10'>
      <label className="w-[200px]  text-blue-500">Given Name (First Name)</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        name="FatherfirstName"
        value={formData.FatherfirstName}
        onChange={handleInputChange}
      />
    </div>

    <div className='flex gap-10 mt-10'>
      <label className="w-[200px]  text-blue-500">Middle Name</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        name="FathermiddleName"
        value={formData.FathermiddleName}
        onChange={handleInputChange}
      />
    </div>

    <div className='flex gap-10 mt-10'> 
      <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        name="FatherlastName"
        value={formData.FatherlastName}
        onChange={handleInputChange}
      />
    </div>
  </div>
)}

<div className='flex mt-10 gap-4'>
  <label className='flex gap-2 text-blue-500'>
    <input
      type="checkbox"
      name="fatherNameUnknown"
      checked={formData.fatherNameUnknown}
      onChange={handleInputChange}
    />
    Father's name is unknown
  </label>
</div>


<hr  className='bg-blue-600 w-full h-1 mt-10'/>

<div className='mt-10'>
        <h1 className='text-blue-600 text-xl font-semibold'>Subject's Mother</h1>
        
    
    
     </div>
     

     {!formData.MotherNameUnknown
     && (
  <div>
    <div className='flex gap-10 mt-10'>
      <label className="w-[200px]  text-blue-500">Given Name (First Name)</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        name="MotherfirstName"
        value={formData.MotherfirstName}
        onChange={handleInputChange}
      />
    </div>

    <div className='flex gap-10 mt-10'>
      <label className="w-[200px]  text-blue-500">Middle Name</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        name="MothermiddleName"
        value={formData.MothermiddleName}
        onChange={handleInputChange}
      />
    </div>

    <div className='flex gap-10 mt-10'> 
      <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        name="MotherlastName"
        value={formData.MotherlastName}
        onChange={handleInputChange}
      />
    </div>
  </div>
)}

<div className='flex mt-10 gap-4'>
  <label className='flex gap-2 text-blue-500'>
    <input
      type="checkbox"
      name="MotherNameUnknown"
      checked={formData.MotherNameUnknown}
      onChange={handleInputChange}
    />
    Mother's name is unknown
  </label>
</div>

<hr className='flex bg-blue-600 mt-10 w-full' />


<div className='mt-10'>
    <h1 className='text-blue-600 text-xl font-semibold'>other family member</h1>
    <p className='text-sm'>If you have additional close family members who went through the U.S. immigration system, you may list them here. Listing other family members may help USCIS locate your records. This is optional.

Do you have other family mem</p>
</div>


<div className=' mt-10 '>
  <label className='bg-blue-600  flex text-white p-4 rounded'>
    Do you have other family members you want to list?
  </label>
  <div className='flex bg-blue-100 p-4 rounded gap-4'>
  <label className='flex gap-2 text-blue-600'>
    <input
      type="radio"
      name="haveOtherFamilyMembers"
      value="Yes"
      checked={formData.haveOtherFamilyMembers === 'Yes'}
      onChange={handleInputChange}
    />
    Yes
  </label>
  <label className='flex gap-2 text-blue-600'>
    <input
      type="radio"
      name="haveOtherFamilyMembers"
      value="No"
      checked={formData.haveOtherFamilyMembers === 'No'}
      onChange={handleInputChange}
    />
    No
  </label>
  </div>
</div>

{formData.haveOtherFamilyMembers === 'Yes'
    &&
    
    <div className="mt-10">
                {nameSets.map((nameSet, index) => (
                  <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 mt-10'>
                    {index > 0 && (
                      <div className='flex w-full justify-between ml-2'>
                        <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
                        <button
                          type="button"
                          onClick={() => handleRemove(nameSet.id)}
                          className=" flex  items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
                        >
                          Remove
                          <img src={crosspic} alt="" />
                        </button>
                      </div>
                    )}

                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                        Given Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`givenName-${nameSet.id}`}
                        name={`givenName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        value={nameSet.givenName || ''}
                        onChange={(e) => handleChange(e, nameSet.id, 'givenName')}
                      />
                    </div>

                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700">
                        Middle Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`middleName-${nameSet.id}`}
                        name={`middleName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        value={nameSet.middleName || ''}
                        onChange={(e) => handleChange(e, nameSet.id, 'middleName')}
                      />
                    </div>

                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-700">
                        Family  Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`familyName-${nameSet.id}`}
                        name={`familyName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-11'
                        value={nameSet.familyName || ''}
                        onChange={(e) => handleChange(e, nameSet.id, 'familyName')}
                      />
                    </div>

                    {/* ... Other input fields ... */}

                  </div>
                ))}

                {nameSets.length === 0 && (
                  <div>
                    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                    <span className="text-gray-500">Add Other</span>
                  </div>
                )}

                <button
                  type="button"
                  onClick={handleAddAnother}
                  className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
                >
                  Add Another Name
                </button>
              </div>
}










<div className='flex  text-white mt-32   w-[100px] h-[50px] ml-auto'>
          <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
            <button
              type="submit"
              className='text-white   rounded'
           
            >
              Next
            </button>
            <BsArrowRight />
          </div>
        </div>
        <div className='flex justify-end mt-2'>
          <span>or <span className='text-blue-500'>Save Your progress?</span></span>
        </div>


   
  </form>
  <FormFotter />

    </div>
    </div>

)
}

export default SubjectFile