import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { FormProvider } from "./Formcontext.js"; // Import your context

import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
// import Select from "react-select";
import stateData from "./ports";
import form from "../../Assets/icon.png";
import "./style.css";
import { useNavigate } from "react-router-dom";



const I902nd = () => {
   
  const navigate=useNavigate()
  const [name, setName] = useState("");
  const [Middle, setMiddle] = useState("");
  const [Family, setFamily] = useState("");

  const [nameFocused, setNameFocused] = useState(false); 
  const [middleFocused, setMiddleFocused] = useState(false); 
  const [FamilyFocused, setFamilyFocused] = useState(false); 

  const [namePRC, setNamePRC] = useState("");
  const [MiddlePRC, setMiddlePRC] = useState("");
  const [FamilyPRC, setFamilyPRC] = useState("");

  const [nameFocusedPRC, setNameFocusedPRC] = useState(false); 
  const [middleFocusedPRC, setMiddleFocusedPRC] = useState(false); 
  const [FamilyFocusedPRC, setFamilyFocusedPRC] = useState(false); 
  const maxLength = 18;
  const [Permanent, setPermanent] = useState("");
  const[street,setStreet]=useState("");
  const[streetFocused,setStreetFocused]=useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [appartment, setappartment] = useState('');
  const[num,setnum]=useState("");
  const[numFocused,setnumFocused]=useState(false);

  const[numb,setnumb]=useState("");
  const[numbFocused,setnumbFocused]=useState(false);

  const[City,setCity]=useState("");
  const[CityFocused,setCityFocused]=useState(false);
  const[Countries,setCountries]=("");

  const[Zip,setZip]=useState("");
  const[ZipFocused,setZipFocused]=useState(false);
//Mailing Address
const [care, setcare] = useState("");
const [Focusedcare, Focusedsetcare] = useState(false);

  const[streetnum,setStreetnum]=useState("");
  const[Focusedstreetnumb,StreetnumbFocused]=useState(false);

  const [appartmentmail, setappartmentmail] = useState("");
  const[Cites,setCites]=useState("");
  const[CitiesFocused,setCitiesFocused]=useState(false);
  const[State,setState]=useState("");
const[Country,setCountry]=("");
  const[Zips,setZips]=useState("");
  const[ZipsFocused,setZipsFocused]=useState(false);
  
  const[mailAdress,setmailAdress]=useState(false);

  const handlePermanent = (e) => {
    setPermanent(e.target.value);
  };
  const handlemail = (e) => {
    setmailAdress(e.target.value);
  };

  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            Application to Replace Permanent Resident Card
          </span>
          <div className="h-full flex justify-end">
            {" "}
            {/* Added 'flex' and 'justify-end' */}
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>
        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2>Let's get started with some basic information about you</h2>
        </div>
        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
          <div className="flex justify-between items-center">
            <span className="mt-4 font-bold text-xl ml-2 text-black section">
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
              &nbsp;Applicant's Current Legal Name
            </span>
          </div>
          <p id="P4_Applicant_Name-HH">
            Provide your current legal name. This is the name that will be
            printed on your new Permanent Resident Card (green card). Spell out
            middle names completely and do not use just the initial. If you do
            not have a middle name, you may leave this field empty.{" "}
            <a href="#">Explain more &gt;&gt;</a>
          </p>
          <div className="forms">
            <label htmlFor="givenName">
              Given Name (First Name) : <span className="text-red-500">*</span>
            </label>
            &nbsp; &nbsp;{" "}
            <input
              id="givenName"
              type="text"
              maxLength={maxLength}
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{
                padding: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
              onFocus={() => setNameFocused(true)} // Set focused state to true on focus
              onBlur={() => setNameFocused(false)}
            />
            &nbsp;{" "}
            {nameFocused && ` ${maxLength - name.length} characters remaining`}
            <br />
            <label htmlFor="Middle">
              Middle Name(s) :<span className="text-red-500">*</span>{" "}
              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
            </label>
            &nbsp; &nbsp;{" "}
            <input
              id="Middle"
              type="text"
              maxLength={maxLength}
              value={Middle}
              onChange={(e) => setMiddle(e.target.value)}
              onFocus={() => setMiddleFocused(true)}
              onBlur={() => setMiddleFocused(false)}
              style={{
                padding: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            />
            &nbsp;{" "}
            {middleFocused &&
              ` ${maxLength - Middle.length} characters remaining`}
            <br />
            <label htmlFor="family">
              Family Name (Last Name) :<span className="text-red-500">*</span>{" "}
            </label>
            &nbsp; &nbsp;{" "}
            <input
              id="family"
              type="text"
              maxLength={maxLength}
              value={Family}
              onChange={(e) => setFamily(e.target.value)}
              onFocus={() => setFamilyFocused(true)}
              onBlur={() => setFamilyFocused(false)}
              style={{
                padding: "5px",
                marginTop: "5px",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            />
            &nbsp;{" "}
            {FamilyFocused &&
              ` ${maxLength - Family.length} characters remaining`}
          </div>

          <div className="additional-div">
            <div className="text-white font-bold p-2 ndRendring text">
              Has your name legally changed since your most recent Permanent
              Resident Card (green card) was issued?
              <span className="text-red-500">*</span>
            </div>
            <div className="form">
              <input
                type="radio"
                name="Card "
                value="yes"
                id="issuedYes"
                onChange={handlePermanent}
              />
              <label htmlFor="issuedYes">Yes</label> &nbsp; &nbsp;
              <input
                type="radio"
                name="Card "
                value="never"
                id="issuednever"
                onChange={handlePermanent}
              />
              <label htmlFor="issuednever">
                {" "}
                I never received my previous card
              </label>{" "}
              &nbsp; &nbsp;
              <input
                type="radio"
                name="Card "
                value="no"
                id="issuedno"
                onChange={handlePermanent}
              />
              <label htmlFor="issuedno">No</label>
              <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                Indicate if your current name (as listed above) is different
                than the name printed on your most recent Permanent Resident
                Card.
              </div>
            </div>
          </div>
          <br />
          {Permanent === "yes" ? (
            <div className="flex flex-col">
              <div className="flex justify-between items-center mb-0">
                {" "}
                {/* Added 'mb-4' for margin-bottom */}
                <span className="text-xl ml-0 text-black section2">
                  <h3 className="heading3">
                    Type your name exactly as it appears on your Permanent
                    Resident Card
                  </h3>
                </span>
              </div>
              <p id="P4_Applicant_Name-HH1">
                If you changed your name, you will be required to submit a copy
                of the legal documents that resulted in the name change.
                Examples include a registered copy of your marriage certificate,
                divorce decree, adoption decree, or other court-issued
                documents.
              </p>
              <div className="forms">
                <label htmlFor="Fn">
                  Given Name (First Name) :{" "}
                  <span className="text-red-500">*</span>
                </label>
                &nbsp; &nbsp;{" "}
                <input
                  id="Fn"
                  type="text"
                  maxLength={maxLength}
                  value={namePRC}
                  onChange={(e) => setNamePRC(e.target.value)}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                  onFocus={() => setNameFocusedPRC(true)} // Set focused state to true on focus
                  onBlur={() => setNameFocusedPRC(false)}
                />
                &nbsp;{" "}
                {nameFocusedPRC &&
                  ` ${maxLength - namePRC.length} characters remaining`}
                <br />
                <label htmlFor="mn">
                  Middle Name(s) :<span className="text-red-500">*</span>{" "}
                  &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                </label>
                &nbsp; &nbsp;{" "}
                <input
                  id="mn"
                  type="text"
                  maxLength={maxLength}
                  value={MiddlePRC}
                  onChange={(e) => setMiddlePRC(e.target.value)}
                  onFocus={() => setMiddleFocusedPRC(true)}
                  onBlur={() => setMiddleFocusedPRC(false)}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                &nbsp;{" "}
                {middleFocusedPRC &&
                  ` ${maxLength - MiddlePRC.length} characters remaining`}
                <br />
                <label htmlFor="Fnn">
                  Family Name (Last Name) :
                  <span className="text-red-500">*</span>{" "}
                </label>
                &nbsp; &nbsp;{" "}
                <input
                  id="Fnn"
                  type="text"
                  maxLength={maxLength}
                  value={FamilyPRC}
                  onChange={(e) => setFamilyPRC(e.target.value)}
                  onFocus={() => setFamilyFocusedPRC(true)}
                  onBlur={() => setFamilyFocusedPRC(false)}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                &nbsp;{" "}
                {FamilyFocusedPRC &&
                  ` ${maxLength - FamilyPRC.length} characters remaining`}
              </div>
            </div>
          ) : null}
          <br />
          <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
          <div className="flex flex-col">
            <div className="flex justify-between items-center mb-0">
              {" "}
              {/* Added 'mb-4' for margin-bottom */}
              <span className="text-xl ml-0 text-black section2 text-bold-500">
                <h3 className="heading3 ">
                  <b>Physical Address</b>
                </h3>
              </span>
            </div>
            <p id="P4_Applicant_Name-HH1">
              If you changed your name, you will be required to submit a copy of
              the legal documents that resulted in the name change. Examples
              include a registered copy of your marriage certificate, divorce
              decree, adoption decree, or other court-issued documents.
            </p>
            <div className="forms">
              <label htmlFor="streetName">
                Street Number and Name: <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <input
                id="streetName"
                type="text"
                maxLength={maxLength}
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
                onFocus={() => setStreetFocused(true)} // Set focused state to true on focus
                onBlur={() => setStreetFocused(false)}
              />
              &nbsp;{" "}
              {streetFocused && ` ${25 - street.length} characters remaining`}
              <br />  <br />
 <label htmlFor="appartment">
 Is there an apartment, suite <br /> or floor? <span className="text-red-500">*</span>{" "} &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; 
              &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;
              </label>
              <select class="form-control1"
                aria-required="true"
                required="required"
                value={appartment}
                onChange={(e)=>setappartment(e.target.value)}
                autocomplete="section-P5_Physical_Address_Domestic address-level1" 
                id="appartment">
                <option value="no">No</option>
                <option value="Apt">Apt</option>
                <option value="Ste">Ste</option>
                <option value="Flr">Flr</option>

              </select>
              <br />            {appartment !== 'no' ? (
              
    <div className="num-input-wrapper">
        <br />    <label htmlFor="num">
        Enter the number here:
 <span className="text-red-500">*</span>
        </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;
        <input
                id="num"
                type="text"
                maxLength={maxLength}
                value={num}
                onChange={(e) => setnum(e.target.value)}
                onFocus={() => setnumFocused(true)}
                onBlur={() => setnumFocused(false)}
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                  width:"10%"
                }}
              />{numFocused &&
                ` ${5 - num.length} characters remaining`}
    </div>
    //-----------------------------------------------------------------------
):null}
<br />
              <label htmlFor="city">
              City or Town : <span className="text-red-500">* </span>{" "}
              </label>  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; 
              &nbsp; &nbsp;{" "}
              <input
                id="city"
                type="text"
                maxLength={maxLength}
                value={City}
                onChange={(e) => setCity(e.target.value)}
                onFocus={() => setCityFocused(true)}
                onBlur={() => setCityFocused(false)}
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp;{" "}
              {CityFocused &&
                ` ${20 - City.length} characters remaining`}
              <br />  <br />
              <label htmlFor="countSelect">
              Country  :<span className="text-red-500">*</span>{" "}
              </label>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select id="countSelect"
               value={Countries}
               onChange={(e) => setCountries(e.target.value)}
                class="form-control"
                aria-required="true"
                required="required"
                autocomplete="section-P5_Physical_Address_Domestic address-level1"
              >
                <option value="US">United States</option>
                <option value="Mexico">Mexico</option>
                <option value="Canada">Canada</option>



              </select>
              <br /><br />
           
              <label htmlFor="stateSelect">
              State (or Territory) :<span className="text-red-500">*</span>{" "}
              </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select
               id="stateSelect"
               value={selectedState}
               onChange={(e) => setSelectedState(e.target.value)}
                class="form-control"
                aria-required="true"
                required="required"
                autocomplete="section-P5_Physical_Address_Domestic address-level1"
              >
                <option value="" selected="selected">
                  Please select...
                </option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District of Columbia">
                  District of Columbia
                </option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
                <option value="U.S. Armed Forces - America (AA)">
                  U.S. Armed Forces - America (AA)
                </option>
                <option value="U.S. Armed Forces - Europe (AE)">
                  U.S. Armed Forces - Europe (AE)
                </option>
                <option value="U.S. Armed Forces - Pacific (AP)">
                  U.S. Armed Forces - Pacific (AP)
                </option>
                <option value="American Samoa">American Samoa</option>
                <option value="Guam">Guam</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Palau">Palau</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Swains Island">Swains Island</option>
                <option value="Virgin Islands">Virgin Islands</option>
              </select> 

              <br />  <br />
             
              <label htmlFor="Zip">
              ZIP Code : <span className="text-red-500">* </span>{" "}
              </label>  &nbsp; &nbsp;  &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; 
              &nbsp; &nbsp;{" "}
              <input
                id="Zip"
                type="text"
                maxLength={maxLength}
                value={Zip}
                onChange={(e) => setZip(e.target.value)}
                onFocus={() => setZipFocused(true)}
                onBlur={() => setZipFocused(false)}
                placeholder="####"
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                  width:"10%"
                }}
              />
              &nbsp;{" "}
              {ZipFocused &&
                ` ${5 - Zip.length} characters remaining`}
            </div>
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <div className="flex flex-col">
            <div className="flex justify-between items-center mb-0">
              {" "}
              {/* Added 'mb-4' for margin-bottom */}
              <span className="text-xl ml-0 text-black section2 text-bold-500">
                <h3 className="heading3 ">
                  <b>Mailing  Address</b>
                </h3>
              </span>
              
            </div>
            <div
              style={{
                backgroundColor: "#E7E7E7",
                color: "#888",
                width: "89%",
                marginLeft: "6%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "1%",
                borderRadius: "5px",
                marginTop: "2%",
              }}
            >
              <img
                src={warn}
                alt="Warning"
                style={{
                  width: "34px",
                  height: "34px",
                  marginRight: "1%",
                }}
              />
              <div>
                <p>
                 <b>Alien Commuters: </b> If you are currently a permanent resident in commuter status who continues to reside in a foreign contiguous territory, you may provide a foreign mailing address. If you are an alien commuter who will take up actual residence in the United States, provide your U.S. mailing address. USCIS will not mail a Permanent Resident Card to a foreign mailing address, only to a U.S. mailing address. USCIS will send your Permanent Resident Card to the appropriate U.S. port of entry for pick up. 
                  
                </p>
              </div>
            </div>
            <div className="additional-div">
                  <div className=" text-white font-bold p-2 ndRendring text">
                  Is your mailing address different than your physical address?

                    <span className="text-red-500">*</span>
                  </div>
                  <div className="form">
                    <input
                      type="radio"
                      name="inUS"
                      value="yes"
                      id="inUSYess"
                      onChange={handlemail}
                    />
                   

                    <label htmlFor="inUSYess">Yes</label> &nbsp;                    &nbsp;
                    &nbsp;
                    <input
                      type="radio"
                      name="inUS"
                      value="no"
                      id="inUSNos"
                      onChange={handlemail}
                    />
                    <label htmlFor="inUSNos">No</label>
                    <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>

                    Indicate if you prefer to receive mail at a different address than the one listed above.
</div>
                  </div>
                  
                </div>
                {mailAdress==='yes'?(
                <div className="flex flex-col">
              <div className="flex justify-between items-center mb-0">
                {" "}
                {/* Added 'mb-4' for margin-bottom */}
                <span className="text-xl ml-0 text-black section2">
                  <h3 className="heading3">
                  Mailing Address

                  </h3>
                </span>
              </div>
              <p id="P4_Applicant_Name-HH1">
              You may list a valid residence, APO, C/O, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.


              </p>
              <div className="forms">
              <label htmlFor="Caree">
      In Care of Name: <span className="text-red-500">*</span>
    </label>
    &nbsp; &nbsp;    &nbsp; &nbsp;    &nbsp; &nbsp;    &nbsp; &nbsp;
    &nbsp; &nbsp;   


    <input
      id="Caree"
      type="text"
      value={care}
      onChange={(e) => setcare(e.target.value)}
      style={{
        padding: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        borderRadius: "5px",
      }}
      onFocus={() => Focusedsetcare(true)} 
      onBlur={() => Focusedsetcare(false)}  
    />
    &nbsp; 
    {Focusedcare && ` ${maxLength - care.length} characters remaining`}  
    <br />  <br />
              <label htmlFor="streetNamee">
                Street Number and Name: <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <input
                id="streetNamee"
                type="text"
                maxLength={maxLength}
                value={streetnum}
                onChange={(e) => setStreetnum(e.target.value)}
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
                onFocus={() => StreetnumbFocused(true)} // Set focused state to true on focus
                onBlur={() => StreetnumbFocused(false)}
              />
              &nbsp;{" "}
              {Focusedstreetnumb && ` ${25 - streetnum.length} characters remaining`}
              <br />  <br />
 <label htmlFor="appartment">
 Is there an apartment, suite <br /> or floor? <span className="text-red-500">*</span>{" "} &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; 
              &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp;
              </label>
              <select class="form-control1"
                aria-required="true"
                required="required"
                value={appartmentmail}
                onChange={(e)=>setappartmentmail(e.target.value)}
                autocomplete="section-P5_Physical_Address_Domestic address-level1" 
                id="appartment">
                <option value="no">No</option>
                <option value="Apt">Apt</option>
                <option value="Ste">Ste</option>
                <option value="Flr">Flr</option>

              </select>
              <br />            {appartmentmail !== 'no' ? (
              
              <div className="num-input-wrapper">
                  <br />    <label htmlFor="numb">
                  Enter the number here:
           <span className="text-red-500">*</span>
                  </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;
                  <input
                          id="numb"
                          type="text"
                          maxLength={maxLength}
                          value={numb}
                          onChange={(e) => setnumb(e.target.value)}
                          onFocus={() => setnumbFocused(true)}
                          onBlur={() => setnumbFocused(false)}
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                            width:"10%"
                          }}
                        />{numbFocused &&
                          ` ${5 - numb.length} characters remaining`}
              </div>
              //-----------------------------------------------------------------------
          ):null}
          <br />
              <label htmlFor="cityy">
              City or Town : <span className="text-red-500">* </span>{" "}
              </label>  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; 
              &nbsp; &nbsp;{" "}
              <input
                id="cityy"
                type="text"
                maxLength={maxLength}
                value={Cites}
                onChange={(e) => setCites(e.target.value)}
                onFocus={() => setCitiesFocused(true)}
                onBlur={() => setCitiesFocused(false)}
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp;{" "}
              {CitiesFocused &&
                ` ${20 - Cites.length} characters remaining`}
              <br />  <br />
              <label htmlFor="countSelects">
              Country : <span className="text-red-500"> * </span>{" "}
              </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select id="countSelects"
               value={Country}
               onChange={(e) => setCountry(e.target.value)}
                class="form-control"
                aria-required="true"
                required="required"
                autocomplete="section-P5_Physical_Address_Domestic address-level1"
              >
                <option value="US">United States</option>
                <option value="Mexico">Mexico</option>
                <option value="Canada">Canada</option>



              </select>
              <br /><br />
           
              <label htmlFor="stateSelecst">
              State (or Territory):<span className="text-red-500">*</span>{" "}
              </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <select
               id="stateSelects"
               value={State}
               onChange={(e) => setState(e.target.value)}
                class="form-control"
                aria-required="true"
                required="required"
                autocomplete="section-P5_Physical_Address_Domestic address-level1"
              >
                <option value="" selected="selected">
                  Please select...
                </option>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="Arizona">Arizona</option>
                <option value="Arkansas">Arkansas</option>
                <option value="California">California</option>
                <option value="Colorado">Colorado</option>
                <option value="Connecticut">Connecticut</option>
                <option value="Delaware">Delaware</option>
                <option value="District of Columbia">
                  District of Columbia
                </option>
                <option value="Florida">Florida</option>
                <option value="Georgia">Georgia</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Idaho">Idaho</option>
                <option value="Illinois">Illinois</option>
                <option value="Indiana">Indiana</option>
                <option value="Iowa">Iowa</option>
                <option value="Kansas">Kansas</option>
                <option value="Kentucky">Kentucky</option>
                <option value="Louisiana">Louisiana</option>
                <option value="Maine">Maine</option>
                <option value="Maryland">Maryland</option>
                <option value="Massachusetts">Massachusetts</option>
                <option value="Michigan">Michigan</option>
                <option value="Minnesota">Minnesota</option>
                <option value="Mississippi">Mississippi</option>
                <option value="Missouri">Missouri</option>
                <option value="Montana">Montana</option>
                <option value="Nebraska">Nebraska</option>
                <option value="Nevada">Nevada</option>
                <option value="New Hampshire">New Hampshire</option>
                <option value="New Jersey">New Jersey</option>
                <option value="New Mexico">New Mexico</option>
                <option value="New York">New York</option>
                <option value="North Carolina">North Carolina</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Ohio">Ohio</option>
                <option value="Oklahoma">Oklahoma</option>
                <option value="Oregon">Oregon</option>
                <option value="Pennsylvania">Pennsylvania</option>
                <option value="Rhode Island">Rhode Island</option>
                <option value="South Carolina">South Carolina</option>
                <option value="South Dakota">South Dakota</option>
                <option value="Tennessee">Tennessee</option>
                <option value="Texas">Texas</option>
                <option value="Utah">Utah</option>
                <option value="Vermont">Vermont</option>
                <option value="Virginia">Virginia</option>
                <option value="Washington">Washington</option>
                <option value="West Virginia">West Virginia</option>
                <option value="Wisconsin">Wisconsin</option>
                <option value="Wyoming">Wyoming</option>
                <option value="U.S. Armed Forces - America (AA)">
                  U.S. Armed Forces - America (AA)
                </option>
                <option value="U.S. Armed Forces - Europe (AE)">
                  U.S. Armed Forces - Europe (AE)
                </option>
                <option value="U.S. Armed Forces - Pacific (AP)">
                  U.S. Armed Forces - Pacific (AP)
                </option>
                <option value="American Samoa">American Samoa</option>
                <option value="Guam">Guam</option>
                <option value="Northern Mariana Islands">
                  Northern Mariana Islands
                </option>
                <option value="Palau">Palau</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Swains Island">Swains Island</option>
                <option value="Virgin Islands">Virgin Islands</option>
              </select> 

              <br />  <br />
             
              <label htmlFor="Zips">
              ZIP Code : <span className="text-red-500">*</span>{" "}
              </label>  &nbsp; &nbsp;  &nbsp;&nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; 
              &nbsp; &nbsp;{" "}
              <input
                id="Zips"
                type="text"
                maxLength={maxLength}
                value={Zips}
                onChange={(e) => setZips(e.target.value)}
                onFocus={() => setZipsFocused(true)}
                onBlur={() => setZipsFocused(false)}
                placeholder="####"
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                  width:"10%"
                }}
              />
              &nbsp;{" "}
              {ZipsFocused &&
                ` ${5 - Zips.length} characters remaining`}
            </div>
            </div>):null}

               
          </div> 
              
               </div>
               <div className="w-full ">
  <button
    type="button"
    className="float-left previous text-white px-4 py-2 rounded m-4" style={{background:'rgb(187, 187, 187)'}}
  >
    ← PREV
  </button>
  <br />
  <button
    type="button"
    className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-4"
    onClick={()=>navigate("/I9013")}
  >
    NEXT →
  </button> &nbsp;&nbsp;&nbsp;
</div>
        </div>

        <FormFotter />
      </div>
      <Fotter />
    </div>
  );
};

export default I902nd;
