


import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/Context131A';

const FinalDetail131A = () => {
    const navigate =useNavigate()
    const {  formData, handleInputChange, code ,handleSubmitG131A} = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
       navigate("")
    };
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
               

               <div>
              <h2 className='text-blue-700 text-xl font-semibold'>Current Travel Details</h2>
              <p className='text-sm'>Provide your full legal name. Your legal name is most likely the name on your passport unless it has been changed by a legal action such as a marriage or court order. Do not provide a nickname. Spell out middle names completely and do not use just the initial. If you do not have a middle name, you may leave this field blank.</p>
              </div>
               
                                <div className="mt-10 gap-4">
                    <label className="text-blue-500">Did you receive help from an interpreter to prepare this application?</label>
                    <div className="flex gap-2">
                      <label htmlFor="yesInterpreter" className="flex items-center text-blue-500">
                        <input
                          type="radio"
                          id="yesInterpreter"
                          name="receivedHelpFromInterpreter"
                          value="Yes"
                          checked={formData.receivedHelpFromInterpreter === "Yes"}
                          onChange={handleInputChange}
                        />
                        Yes
                      </label>
                      <label htmlFor="noInterpreter" className="flex items-center text-blue-500">
                        <input
                          type="radio"
                          id="noInterpreter"
                          name="receivedHelpFromInterpreter"
                          value="No"
                          checked={formData.receivedHelpFromInterpreter === "No"}
                          onChange={handleInputChange}
                        />
                        No
                      </label>
                    </div>
                  </div>

                  <div className="mt-10 gap-4">
  <label className="text-blue-500">Which foreign language was used?</label>
  <input
    type="text"
    value={FormData.foreignLanguage}
    onChange={handleInputChange}
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded"
  />
</div>

<div className="mt-10 gap-4">
  <label className="text-blue-500">Interpreter's Given Name (First Name)</label>
  <input
    type="text"
    value={FormData.interpreterGivenName}
    onChange={handleInputChange}
    className="border border-gray-300 hover:border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded"
  />
</div>

<div className="mt-10 gap-4">
  <label className="text-blue-500">Interpreter's Family Name (Last Name)</label>
  <input
    type="text"
    value={FormData.interpreterFamilyName}
    onChange={handleInputChange}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded"
  />
</div>

<div className="mt-10 gap-4">
  <label className="text-blue-500">Business or Organization Name of Interpreter</label>
  <input
    type="text"
    value={FormData.interpreterBusinessName}
    onChange={handleInputChange}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded"
  />
</div>


            
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                  onCanPlay={handleSubmitG131A}
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
        
      </div>
    )
}

export default FinalDetail131A