


import React from 'react'
import { useFormContext } from '../../UseContext/ContextN565';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';

const OriginalCertificate = () => {

    const navigate=useNavigate()
    const { formData, handleInputChange , code ,nameSets ,handleAddAnother ,handleRemove ,handleChange } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()

         navigate("/lastpage")
    }

    console.log(formData.doNotKnowDateOfIssuance)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Certificate Details

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            If you cannot locate your certificate or do not have a copy to reference, you may enter "Unknown."
            </span>

            <span className='mt-4 font-bold text-xl ml-2 text-black mt-10'>
            Name in Which the Document Was Issued:
              </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

         <div className='flex flex-col gap-4'>
            <h1 className='font-semibold text-xl text-blue-600 '>Applicant's Current Legal Name</h1>
            <p className='text-sm'>Your current legal name is the name that you use now. For many people, it's the name on your birth certificate unless it has been changed by a legal action such as a marriage or court order. Do not provide a nickname. Spell out middle names completely and do not use just the initial. If you do not have a middle name, you may leave this field empty. Explain more</p>
         </div>
            
              
    <div className='flex flex-col'>
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Given Name (First Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='givenName'
        value={formData.wasisseuedgivenName}
        onChange={handleInputChange}
      />
      </div>
     
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Middle Name(s)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='middleNames'
        value={formData.wasisseuedmiddleNames}
        onChange={handleInputChange}
      />
      </div>

       <div className='flex mt-10 gap-2'>
      <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='familyName'
        value={formData.wasisseuedfamilyName}
        onChange={handleInputChange}
      />
      </div>
    </div>



    <div classNumber='mt-10'>
      <label htmlFor="certificateNumber"  className='bg-blue-700 mt-10 text-white flex p-4 '>Certificate Number</label>
      <div className='bg-blue-100 p-4  flex '>
      <input
        type="text"
        id="certificateNumber"
        name="certificateNumber"
        value={formData.certificateNumber}
        onChange={handleInputChange}
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
      />
      </div>
      <p className='bg-slate-200 p-2'>The certificate number is generally a red 6- to 8-digit alpha numeric number.</p>
       </div>
       <div classNumber='mt-10'>
      <label htmlFor="placeOfIssuance" className='bg-blue-700 mt-10 text-white flex p-4 '>Place of Issuance</label>
       <div className='bg-blue-100 p-4  flex '>
      <input
        type="text"
        id="placeOfIssuance"
        name="placeOfIssuance"
        value={formData.placeOfIssuance}
        onChange={handleInputChange}
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
      />
      </div>
      <p className='bg-slate-200 p-2'>Enter the City, State of the Oath Ceremony, USCIS office, or court where the document was issued.</p>
    </div>



    <div className='mt-10'>
  <label htmlFor="dateOfIssuance" className='bg-blue-700 mt-10 text-white flex p-4'>
    Date of Issuance:
  </label>

  <div className='bg-blue-100 p-4 flex flex-col gap-5'>
    <input
      className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
      type="date"
      id="dateOfIssuance"
      name="dateOfIssuance"
      value={formData.dateOfIssuance}
      onChange={handleInputChange}
      style={{ display: formData.doNotKnowDateOfIssuance ? 'none' : 'block' }}
    />
    <div className='ml-10 flex gap-2'>
      <input
        type="checkbox"
        id="doNotKnowDateOfIssuance"
        name="doNotKnowDateOfIssuance"
        checked={formData.doNotKnowDateOfIssuance}
        onChange={handleInputChange}
      />
      <label htmlFor="doNotKnowDateOfIssuance" className='text-blue-700 gap-2'>
        I do not know the date of issuance:
      </label>
    </div>
  </div>
</div>


    <div  className=' mt-10   '>
    
      <label htmlFor="dateOfBirthOnCertificate" className='bg-blue-700 mt-10 text-white flex p-4 '>Date of Birth on Certificate:</label>
        <div className='bg-blue-100 p-4  flex  flex-col  gap-4'>
      <input
        className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
        type="date"
        id="dateOfBirthOnCertificate"
        name="dateOfBirthOnCertificate"
        value={formData.dateOfBirthOnCertificate}
        onChange={handleInputChange}
      />
      </div>
      <p className='bg-slate-200 p-2'>
          Use the date of birth printed on your certificate.</p>

    </div>

   



       
    <div className=' mt-10 '>
      <label htmlFor="previousCountryOfCitizenship"  className='bg-blue-700 mt-10 text-white flex p-4 '>Previous Country of Citizenship:</label>
       <div className='bg-blue-100 p-4  flex '>
      <select
        className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
        id="previousCountryOfCitizenship"
        name="previousCountryOfCitizenship"
        value={formData.previousCountryOfCitizenship}
        onChange={handleInputChange}
      >
        <option value=''>Select a country</option>
        {code.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
      </div>
      <p className='bg-slate-200 p-2'>Select the country where you were a national or citizen prior to obtaining U.S. citizenship.</p>
    </div>




    <div className='mt-10'>
  <label htmlFor='aNumber' className='bg-blue-500 text-white flex w-full p-4 rounded'>
    Alien Registration Number (A-Number):
  </label>
  <div className="flex bg-blue-100 p-4 flex-col gap-2 ">
  <div className='flex  items-center'>
  <span className='flex justify-center items-center bg-slate-400 p-2  w-[40px]'   style={{ display: formData.doNotKnowANumber ? 'none' : 'block' }}>A-</span>
  <input
    className='w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded'
    type='number'
    id='aNumber'
    name='aNumber'
    value={formData.aNumber}
    onChange={handleInputChange}
    style={{ display: formData.doNotKnowANumber ? 'none' : 'block' }}
  />
  </div>
  <div className='flex ml-5 '>
  <label className='flex gap-2 text-blue-600'>
  <input
    type='checkbox'
    id='doNotKnowANumber'
    name='doNotKnowANumber'
    checked={formData.doNotKnowANumber}
    onChange={handleInputChange}
    
  />
   I do not know my A-Number
   </label>
</div>
  </div>
  <p className='bg-slate-200 p-2'>Also known as an A-Number, your Alien Registration Number is an 8- or 9-digit number that can be found on your certificate. How to find this >></p>
</div>

{formData.doNotKnowANumber
    &&
    <div className='bg-slate-200 p-2 flex gap-2 '>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt="" className='w-[40px] h-[40px]'/>
    <p> <span className='font-bold'>Be Advised: </span>You may file Form N-565 without your A-Number, but it may increase your processing time to receive a new certificate. We recommend that you obtain your A-Number through an InfoPass appointment if possible. How to find this >></p>
</div>
}
       


<hr  className='bg-blue-700 mt-10 h-1'/>

<div className='mt-10'>
    <h1 className='text-blue-600 text-sl font-semibold'>Circumstances of Certificate Loss</h1>
    <p className='font-sm'>As part of your application package, you are required to describe the circumstances of the loss. Provide a short statement here (to be included on the form). Our filing instructions will help explain how to write a more detailed sworn statement on a separate sheet of paper if you do not have a police report.</p>
</div>


<div className='mt-10'>
  <label htmlFor="certificateCircumstances" className="bg-blue-500 text-white flex w-full p-4 rounded">
    Explain the circumstances of how, when, and where the certificate was lost, stolen, or destroyed:
  </label>
<div className='flex bg-blue-100 p-4'>
  <textarea
    id="certificateCircumstances"
    name="certificateCircumstances"
    value={formData.certificateCircumstances}
    onChange={handleInputChange}
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-4 rounded ml-6 w-full"
  ></textarea>
  </div>
</div>
  

  <hr className='bg-blue-600 h-1 mt-10'/>

  <div className='mt-10'>
    <h1 className='text-blue-600 text-sl font-semibold'>Basis for Name Change</h1>
    <p className='font-sm'>As part of your application package, you are required to provide evidence of your legal name change.</p>
</div>


<div className='mt-10'>
  <label className="bg-blue-500 text-white flex w-full p-4 rounded">
    Which documentation do you have as evidence of your legal name change?
  </label>
<div  className='flex bg-blue-100 p-4 flex-col gap-2'>
  <label className='flex gap-2 text-blue-700'>
  
    <input
      type='radio'
      name='evidenceOfNameChange'
      value='Marriage Certificate'
      checked={formData.evidenceOfNameChange === 'Marriage Certificate'}
      onChange={handleInputChange}
    />
      Marriage Certificate
  </label>

  <label className='flex gap-2 text-blue-700'>
   
    <input
      type='radio'
      name='evidenceOfNameChange'
      value='Divorce Decree'
      checked={formData.evidenceOfNameChange === 'Divorce Decree'}
      onChange={handleInputChange}
    />
     Divorce Decree
  </label>

  <label className='flex gap-2 text-blue-700'>
   
    <input
      type='radio'
      name='evidenceOfNameChange'
      value='Annulment Decree'
      checked={formData.evidenceOfNameChange === 'Annulment Decree'}
      onChange={handleInputChange}
    />
     Annulment Decree
  </label>

  <label className='flex gap-2 text-blue-700'>
    
    <input
      type='radio'
      name='evidenceOfNameChange'
      value='Court Order'
      checked={formData.evidenceOfNameChange === 'Court Order'}
      onChange={handleInputChange}
    />
    Court Order
  </label>
  </div>
</div>


<div className='mt-10'>
  <label htmlFor="dateOfChangeOnDocument" className='bg-blue-500 text-white flex w-full p-4 rounded'>
    Date of Change (on document):
  </label>
  <div className='bg-blue-100 p-4  flex'>
  <input
    type="date"
    id="dateOfChangeOnDocument"
    name="dateOfChangeOnDocument"
    value={formData.dateOfChangeOnDocument}
    onChange={handleInputChange}
    className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
  />
  </div>
</div>


<h1 className='text-blue-700 font-semibold text-xl mt-10'>My new legal name is:</h1>

              
<div className='flex flex-col'>
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Given Name (First Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='givenName'
        value={formData.NewgivenName}
        onChange={handleInputChange}
      />
      </div>
     
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Middle Name(s)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='middleNames'
        value={formData.NewmiddleNames}
        onChange={handleInputChange}
      />
      </div>

       <div className='flex mt-10 gap-2'>
      <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='familyName'
        value={formData.NewfamilyName}
        onChange={handleInputChange}
      />
      </div>
    </div>




























<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default OriginalCertificate