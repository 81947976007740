import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { ContextFor1762 } from '../../UseContext/ContextFor1762';
import { useContext } from 'react';
import crosspic from "../../Assets/crosspic.png"
import { Options } from "./Options.js"
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';



const Eligibilityforoneseven = () => {

  const navigate = useNavigate()

  const {
    permissionType,
    updatePermissionType,
    expiryOption,
    updateExpiryOption,
    applicationReason,
    updateApplicationReason,
    isAtLeastFifteen,
    updateIsAtLeastFifteen,
    isFilingAsylum,
    updateIsFilingAsylum,
    hasBeenArrested,
    updateHasBeenArrested,
    acknowledgeAttorney,
    updateAcknowledgeAttorney,
    servedInMilitary,
    updateServedInMilitary,
    givenFalseInformation,
    updateGivenFalseInformation,
    updateSelectedOption,
    selectedGroup,
    selectedValue,
    fathersGivenName,
    updateFathersGivenName,
    fathersFamilyName,
    updateFathersFamilyName,
    mothersGivenName,
    updateMothersGivenName,
    mothersFamilyName,
    updateMothersFamilyName,

  } = useContext(ContextFor1762);

  const handlePermissionChange = (event) => {
    updatePermissionType(event.target.value);
  };

  const handleExpiryChange = (option) => {
    updateExpiryOption(option);
  };

  const handleApplicationReasonChange = (reason) => {
    updateApplicationReason(reason);
  };

  const handleIsAtLeastFifteenChange = (value) => {
    updateIsAtLeastFifteen(value);
  };
  const handleIsFilingAsylumChange = (value) => {
    updateIsFilingAsylum(value);
  };

  const handleHasBeenArrestedChange = (value) => {
    updateHasBeenArrested(value);
  };

  const handleAcknowledgeAttorneyChange = () => {
    updateAcknowledgeAttorney();
  };

  const handleServedInMilitaryChange = (value) => {
    updateServedInMilitary(value);
  };
  const handleGivenFalseInformationChange = (value) => {
    updateGivenFalseInformation(value);
  };

  const handleRadioChange = (value) => {
    updateSelectedOption({
      selectedGroup: value,
      selectedValue: value,
    });

    console.log("selectedValue", value);
  };


  const handleFormSubmit = (e) => {
    e.preventDefault();


    // Log the states
    console.log('permissionType:', permissionType);
    console.log('expiryOption:', expiryOption);
    console.log('applicationReason:', applicationReason);
    console.log('isAtLeastFifteen:', isAtLeastFifteen);
    console.log('isFilingAsylum:', isFilingAsylum);
    console.log('hasBeenArrested:', hasBeenArrested);
    console.log('acknowledgeAttorney:', acknowledgeAttorney);
    console.log('servedInMilitary:', servedInMilitary);
    console.log('givenFalseInformation:', givenFalseInformation);
    console.log('selectedGroup:', selectedGroup);
    console.log('selectedValue:', selectedValue);
    // ... log other states


    navigate("/Applicantinfo")
  }




  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>




      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>

          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>

        </div>




        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
            <span className='mt-4 font-bold text-xl ml-2 text-black '>
              First, let’s determine your eligibility to file Form I-765
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2 '>
                <button className='mr-2'>Page 1 of 3</button>
                <button><MdArrowDropDown /></button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>These questions will help confirm your eligibility for employment authorization. When granted approval on Form I-765, you will receive an Employment Authorization Document (EAD), also known as a work permit.
          </span>
        </div>

        <form action=" " className='mt-14 w-11/12  ' onSubmit={handleFormSubmit}>

          <div className="p-4 rounded text-white bg-blue-700">
            <span>I am applying for:  <span className="text-red-500">*</span></span>
          </div>
          <div className='flex gap-3  bg-blue-50 p-5 flex-col' >
            <label className='flex gap-2 text-blue-600'>
              <input
                type="radio"
                value="initialPermission"
                checked={permissionType === 'initialPermission'}
                onChange={handlePermissionChange}
              />
              Initial permission to accept employment
            </label>
            <hr className='bg-blue-100 h-0.5' />

            <label className='flex gap-2 text-blue-600'>
              <input
                type="radio"
                value="replacementPermission"
                checked={permissionType === 'replacementPermission'}
                onChange={handlePermissionChange}
              />
              Replacement (of lost/stolen/damaged employment authorization document)
            </label>
            <hr className='bg-blue-100 h-0.5' />
            <label className='flex gap-2 text-blue-600'>
              <input
                type="radio"
                value="renewalPermission"
                checked={permissionType === 'renewalPermission'}
                onChange={handlePermissionChange}
              />
              Renewal of my permission to accept employment
            </label>
          </div>
          <p className='bg-slate-200 p-2'>Indicate the type of application you want to prepare. <span className='text-blue-400'>Explain more >></span></p>


          {permissionType === 'renewalPermission' && (
            <>
              <div className="p-4 rounded text-white bg-blue-500  mt-10">
                <span>I am applying for:  <span className="text-red-500">*</span></span>
              </div>


              <div className='flex gap-3 bg-blue-100 p-5 flex-col '>
                <label className='flex gap-2 text-blue-600'>
                  <input
                    type="radio"
                    value="expired"
                    checked={expiryOption === 'expired'}
                    onChange={() => handleExpiryChange('expired')}

                  />
                  It already expired
                </label>
                <hr className='bg-blue-200 h-0.5' />

                <label className='flex gap-2 text-blue-600'>
                  <input
                    type="radio"
                    value="before"
                    checked={expiryOption === 'before'}
                    onChange={() => handleExpiryChange('before')}

                  />
                  It expires before 04/12/2024
                </label>
                <hr className='bg-blue-200 h-0.5' />

                <label className='flex gap-2 text-blue-700'>
                  <input
                    type="radio"
                    value="after"
                    checked={expiryOption === 'after'}
                    onChange={() => handleExpiryChange('after')}

                  />
                  It expires after 04/12/2024
                </label>
              </div>
              <p className='bg-slate-200 p-2'>The expiration date is on the front of your card</p>
            </>
          )}

          {expiryOption === 'after' && <div className='bg-orange-200 p-2 flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt="" className='w-[50] h-[30]' />
            <p> <span className='font-bold'>Generally, you should not file for a renewal EAD more than 180 days before your original EAD expires.</span>
              You may complete the form now. However, do not mail your application until 180 days before the card expires.</p>
          </div>
          }
          {(permissionType === 'initialPermission' || expiryOption === 'expired' || expiryOption === 'before' || expiryOption === 'after') && (
            <>
              <hr className='bg-blue-400 h-1 mt-32' />

              <div className='mt-5'>
                <h3 className='text-blue-600 text-2xl font-semibold'>Eligibilty Criteria</h3>

                <p className='mt-1'>To determine if you are eligible to file an Application for Employment Authorization, USCIS wants you to identify the category you belong to. Your eligibility category also affects your filing instructions.</p>


                <p className='mt-10'>Renewal/replacement candidates can find the category on their existing Employment Authorization Cards. <span className='text-blue-600'>How to find this >></span> </p>



                <div className="p-4 rounded text-white bg-blue-700 mt-10">
                  <span>What is your reason for filing Form I-765? <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-3 bg-blue-100 p-5 flex-col'>
                  <label className='flex gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="greenCardApplication"
                      checked={applicationReason === 'greenCardApplication'}
                      onChange={() => handleApplicationReasonChange('greenCardApplication')}
                    />
                    I am applying for a green card with Form I-485
                  </label>
                  <hr className='bg-blue-200 h-0.5' />

                  <label className='flex gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="DACAApplication"
                      checked={applicationReason === 'DACAApplication'}
                      onChange={() => handleApplicationReasonChange('DACAApplication')}
                    />
                    I am applying for DACA or was already granted DACA
                  </label>
                  <hr className='bg-blue-200 h-0.5' />

                  <label className='flex gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="otherReason"
                      checked={applicationReason === 'otherReason'}
                      onChange={() => handleApplicationReasonChange('otherReason')}
                    />
                    Another reason
                  </label>
                </div>
                <p className='bg-slate-200 p-2'>Indicate the basis of your eligibility for an EAD</p>

              </div>

              {applicationReason === 'DACAApplication' && (<div className='flex  flex-col  gap-4 p-2  bg-red-200 '>
                <div className='flex gap-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                  <p>If you are filing an initial or renewal application for Deferred Action for Childhood Arrivals (DACA), we encourage you to use our DACA application package. It includes Forms I-821D, I-765, and the I-765 worksheet (all required forms for a DACA application).</p>
                </div>
                <div className='flex gap-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" className='h-[30px] w-[30px]' />
                  <p className='font-bold text-blue-500'>Start DACA Application.</p>
                </div>

              </div>)}



              {applicationReason === 'greenCardApplication' && (<div className='flex flex-col mt-14'>
                <div className="p-4 rounded text-white bg-blue-700">
                  <span>Are you atleast 18 <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-4 p-4 bg-blue-100'>
                  <label className='flex  gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="yes"
                      checked={isAtLeastFifteen === 'yes'}
                      onChange={() => handleIsAtLeastFifteenChange('yes')}
                    />
                    Yes
                  </label>
                  <label className='flex gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="no"
                      checked={isAtLeastFifteen === 'no'}
                      onChange={() => handleIsAtLeastFifteenChange('no')}
                    />
                    No
                  </label>
                </div>


                <div className='flex  text-blue-600 mt-10 flex-col'>
                  <div className="p-4 rounded text-white bg-blue-700">
                    <span>Are you filing Form I-485 on the basis of asylum or refugee status? <span className="text-red-500">*</span></span>
                  </div>
                  <div className='flex gap-4 p-4 bg-blue-100'>
                    <label className='flex gap-2 text-blue-600'>
                      <input
                        type="radio"
                        value="yes"
                        checked={isFilingAsylum === 'yes'}
                        onChange={() => handleIsFilingAsylumChange('yes')}
                      />
                      Yes
                    </label>
                    <label className='flex gap-2 text-blue-600'>
                      <input
                        type="radio"
                        value="no"
                        checked={isFilingAsylum === 'no'}
                        onChange={() => handleIsFilingAsylumChange('no')}
                      />
                      No
                    </label>
                  </div>
                  <p className='bg-slate-200 p-2'>  Asylees and refugees file under a different eligibility category. <span className='text-blue-500'> Explain More >> </span></p>
                </div>
                {isFilingAsylum === 'yes' && (
                  <div className='bg-red-200 p-2'>
                    <div className='flex gap-3  justify-between'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                      <p><span className='font-bold'>We found a problem. Based on your answer, you cannot continue. </span>If you were admitted as a refugee or granted asylum and are now filing Form I-485 to adjust status to permanent resident on that basis, do not file Form I-765 under eligibility category (c)(9) as an adjustment of status applicant. Instead, go back and select your relevant category:</p>
                    </div>
                    <div className='flex gap-3 mt-4 items-center'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                      <p>Use (a)(5) for asylum</p>
                    </div>
                    <div className='flex gap-3 mt-4 items-center'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                      <p>Use (a)(3) for refugee status</p>
                    </div>
                  </div>)}


                {isFilingAsylum === 'no' && (
                  <>
                    <div className='flex  text-blue-600 flex-col mt-10'>
                      <div className="p-4 rounded text-white bg-blue-700">
                        <span>Have you EVER been arrested or charged with any misdemeanor or felony crime in the United States?<span className="text-red-500">*</span></span>
                      </div>
                      <div className='flex gap-4 bg-blue-100 p-4'>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="yes"
                            checked={hasBeenArrested === 'yes'}
                            onChange={() => handleHasBeenArrestedChange('yes')}
                          />
                          Yes
                        </label>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="no"
                            checked={hasBeenArrested === 'no'}
                            onChange={() => handleHasBeenArrestedChange('no')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className='bg-slate-200 p-2'>Traffic violations do not need to be included unless they are alcohol or drug related. <span className="text-blue-500"
                    > Explain More >></span>
                    </p>
                  </>
                )}

                {hasBeenArrested === 'yes' && (
                  <>
                    <div className='bg-yellow-200 p-2'>
                      <div>
                        <div className='flex gap-3  justify-between'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                          <p><span className='font-bold'>Your answer to this question suggests that you have a history which may require the assistance of a legal professional. </span>We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.
                          </p>
                        </div>
                        <div className='flex gap-3 mt-4 items-center'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Search_60.png" alt="" className='h-[30px] w-[30px]' />
                          <p className='font-bold text-blue-500'>Help me find an immigration attorney</p>
                        </div>


                      </div>



                    </div>
                    <div className='flex gap-2 text-black   justify-end bg-green-200  w-[610px] ml-auto rounded-md p-2 '>
                      <label className='flex gap-2'>
                        I acknowledge that I have spoken to an attorney and/or want to continue anyway
                        <input
                          type="checkbox"
                          checked={acknowledgeAttorney}
                          onChange={handleAcknowledgeAttorneyChange}
                        />
                      </label>
                    </div>
                  </>
                )}

                {hasBeenArrested === 'no' && (
                  <div className='flex  text-blue-600 flex-col mt-10'>
                    <div className="p-4 rounded text-white bg-blue-700">
                      <span>Have you EVER given false or misleading information to any U.S. government official while applying for any immigration benefit or to prevent deportation, exclusion, or removal?<span className="text-red-500">*</span></span>
                    </div>
                    <div className='flex gap-4 p-4 bg-blue-100'>
                      <label className='flex gap-2 text-blue-600'>
                        <input
                          type="radio"
                          value="yes"
                          checked={givenFalseInformation === 'yes'}
                          onChange={() => handleGivenFalseInformationChange('yes')}
                        />
                        Yes
                      </label>
                      <label className='flex gap-2 text-blue-600'>
                        <input
                          type="radio"
                          value="no"
                          checked={givenFalseInformation === 'no'}
                          onChange={() => handleGivenFalseInformationChange('no')}
                        />
                        No
                      </label>
                    </div>

                  </div>)}
                {givenFalseInformation === 'no' && (
                  <>
                    <div className='flex  text-blue-600 flex-col mt-10'>
                      <div className="p-4 rounded text-white bg-blue-700">
                        <span>Have you or an immediate family member ever served honorably in the U.S. armed forces?<span className="text-red-500">*</span></span>
                      </div>
                      <div className='flex gap-4 bg-blue-100 p-4'>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="yes"
                            checked={servedInMilitary === 'yes'}
                            onChange={() => handleServedInMilitaryChange('yes')}
                          />
                          Yes
                        </label>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="no"
                            checked={servedInMilitary === 'no'}
                            onChange={() => handleServedInMilitaryChange('no')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className='bg-slate-200 p-2'>Select "Yes" only if you, your spouse, your parent or your child served honorably in the U.S. Army, Marines, Navy, Air Force, Space Force or Coast Guard. Explain more >> <span className="text-blue-500"
                    > Explain More >></span></p>


                  </>
                )


                }

                {servedInMilitary === 'no'
                  && (<div className='flex gap-2 bg-green-300 items-center p-2 rounded mt-20'>
                    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" />
                    <p> <span className='font-bold'>Congratulations, you're likely eligible to apply for employment authorization. </span>
                      <br />   Please click on "Next Page" below to continue your application.</p>
                  </div>)}






              </div>


              )}
            </>
          )}









          {applicationReason === 'otherReason'
            &&

            (
              <>
                <div className="p-4 rounded text-white bg-blue-500  mt-10">
                  <span>Which eligibility category do you belong to?  <span className="text-red-500">*</span></span>
                </div>

                <div className='flex flex-col gap-4 bg-blue-100 p-4'>
                  {Options.map((option, index) => (
                    <div key={index}>
                      {option.title ? (
                        <>
                          <span className='font-bold text-blue-500'>{option.title}</span>
                          <hr className="bg-blue-200 h-0.5" />
                        </>
                      ) : (
                        <>
                          <label className='flex gap-2 text-blue-500'>
                            <input
                              type="radio"
                              name="radioGroup" // Use the same name for all radio buttons
                              value={option.label}
                              checked={selectedGroup === option.label}
                              onChange={() => handleRadioChange(option.label)}
                            />
                            <span className="ml-2">{option.code}</span>
                            {/* Add an empty span for spacing */}
                            <span className="ml-8"></span>
                            <span>{option.label}</span>
                          </label>
                          <hr className="bg-blue-200 h-0.5" />
                        </>
                      )}
                    </div>
                  ))}



                </div>
              </>
            )}

          {(selectedGroup === 'Adjustment of Status Applicant'
            || selectedGroup === 'Registry Applicant Based on Continuous Residence Since January 1, 1972'
            || selectedGroup === "Refugee"
            || selectedGroup === "Paroled as a Refugee"
            || selectedGroup === "Asylee (Granted Asylum)"
            || selectedGroup === "Granted Withholding of Deportation or Removal"
            || selectedGroup === "Asylum Applicant (with a pending asylum application)Who Filed for Asylum on or After January 4, 1995"
            || selectedGroup === "Citizen of Micronesia, the Marshall Islands, or Palau"
            || selectedGroup === " Deferred Enforced Departure (DED)"
            || selectedGroup === "NACARA Section 203 Applicants Who Are Eligible to Apply for NACARA Relief With USCIS"
            || selectedGroup === "Dependent of TECRO E-1 Nonimmigrant"
            || selectedGroup === "F-1 Student Seeking Pre-Completion Optional Practical Training"
            || selectedGroup === " F-1 Student Seeking Post-Completion Optional Practical Training"
            || selectedGroup === "F-1 Student Seeking 24-month Extension for STEM Students"
            || selectedGroup === "F-1 Student Offered Off-Campus Employment by Qualifying Organization"
            || selectedGroup === "F-1 Student Seeking Off-Campus Employment Due to Economic Hardship"
            || selectedGroup === "J-2 Spouse or Minor Child of an Exchange Visitor"
            || selectedGroup === "M-1 Student Seeking Post-Completion OPT After Completing Studies"
            || selectedGroup === " Nonimmigrant Domestic Servant of a U.S. Citizen"
            || selectedGroup === "Spouse of an E-1 Treaty Trader, E-2 Treaty Investor, or E-3 Special Occupation"
            || selectedGroup === " Spouse of an L-1 Intracompany Transferee"
            || selectedGroup === "Spouse of an H-1B Nonimmigrant (H-4 Dependent)"
            || selectedGroup === "K-3 Nonimmigrant Spouse of U.S. Citizen or K-4 Dependent"
            || selectedGroup === "V-1, V-2 or V-3 Nonimmigrant"
            || selectedGroup === " N-8 or N-9 Nonimmigrant"
            || selectedGroup === "Applicant for Cancellation of Removal"
            || selectedGroup === "Parole"
            || selectedGroup === "U-1 Nonimmigrant"
            || selectedGroup === "U-2, U-3, U-4 or U-5"
            || selectedGroup === "VAWA Self-Petitioners"
            || selectedGroup === "Applicant for Commonwealth of the Northern Mariana Islands (CNMI) Long-Term Resident Status"
            || selectedValue === "VAWA Self-Petitioners"
            || selectedValue === "T-2, T-3, T-4, T-5 or T-6 Nonimmigrant"

          ) && (
              <>
                <div className="p-4 rounded text-white bg-blue-700 mt-10">
                  <span>Are you at least 18 <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-4 p-4 bg-blue-100'>
                  <label className='flex  gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="yes"
                      checked={isAtLeastFifteen === 'yes'}
                      onChange={() => handleIsAtLeastFifteenChange('yes')}
                    />
                    Yes
                  </label>
                  <label className='flex gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="no"
                      checked={isAtLeastFifteen === 'no'}
                      onChange={() => handleIsAtLeastFifteenChange('no')}
                    />
                    No
                  </label>
                </div>

                <div className='flex  text-blue-600 mt-10 flex-col'>
                  <div className="p-4 rounded text-white bg-blue-700">
                    <span>Are you filing Form I-485 on the basis of asylum or refugee status? <span className="text-red-500">*</span></span>
                  </div>
                  <div className='flex gap-4 p-4 bg-blue-100'>
                    <label className='flex gap-2 text-blue-600'>
                      <input
                        type="radio"
                        value="yes"
                        checked={isFilingAsylum === 'yes'}
                        onChange={() => handleIsFilingAsylumChange('yes')}
                      />
                      Yes
                    </label>
                    <label className='flex gap-2 text-blue-600'>
                      <input
                        type="radio"
                        value="no"
                        checked={isFilingAsylum === 'no'}
                        onChange={() => handleIsFilingAsylumChange('no')}
                      />
                      No
                    </label>
                  </div>
                  <p className='bg-slate-200 p-2'>  Asylees and refugees file under a different eligibility category. <span className='text-blue-500'> Explain More >> </span></p>
                </div>

                {isFilingAsylum === 'yes' && (
                  <div className='bg-red-200 p-2'>
                    <div className='flex gap-3 justify-between'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                      <p><span className='font-bold'>We found a problem. Based on your answer, you cannot continue. </span>If you were admitted as a refugee or granted asylum and are now filing Form I-485 to adjust status to permanent resident on that basis, do not file Form I-765 under eligibility category (c)(9) as an adjustment of status applicant. Instead, go back and select your relevant category:</p>
                    </div>
                    <div className='flex gap-3 mt-4 items-center'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                      <p>Use (a)(5) for asylum</p>
                    </div>
                    <div className='flex gap-3 mt-4 items-center'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                      <p>Use (a)(3) for refugee status</p>
                    </div>
                  </div>
                )}

                {isFilingAsylum === 'no' && (
                  <>
                    <div className='flex text-blue-600 flex-col mt-10'>
                      <div className="p-4 rounded text-white bg-blue-700">
                        <span>Have you EVER been arrested or charged with any misdemeanor or felony crime in the United States?<span className="text-red-500">*</span></span>
                      </div>
                      <div className='flex gap-4 bg-blue-100 p-4'>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="yes"
                            checked={hasBeenArrested === 'yes'}
                            onChange={() => handleHasBeenArrestedChange('yes')}
                          />
                          Yes
                        </label>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="no"
                            checked={hasBeenArrested === 'no'}
                            onChange={() => handleHasBeenArrestedChange('no')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className='bg-slate-200 p-2'>Traffic violations do not need to be included unless they are alcohol or drug related. <span className="text-blue-500"> Explain More >></span></p>
                  </>
                )}

                {hasBeenArrested === 'yes' && (
                  <>
                    <div className='bg-yellow-200 p-2'>
                      <div>
                        <div className='flex gap-3 justify-between'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                          <p><span className='font-bold'>Your answer to this question suggests that you have a history which may require the assistance of a legal professional. </span>We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
                        </div>
                        <div className='flex gap-3 mt-4 items-center'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Search_60.png" alt="" className='h-[30px] w-[30px]' />
                          <p className='font-bold text-blue-500'>Help me find an immigration attorney</p>
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-2 text-black justify-end bg-green-200 w-[610px] ml-auto rounded-md p-2 '>
                      <label className='flex gap-2'>
                        I acknowledge that I have spoken to an attorney and/or want to continue anyway
                        <input
                          type="checkbox"
                          checked={acknowledgeAttorney}
                          onChange={handleAcknowledgeAttorneyChange}
                        />
                      </label>
                    </div>
                  </>
                )}

                {hasBeenArrested === 'no' && (
                  <div className='flex text-blue-600 flex-col mt-10'>
                    <div className="p-4 rounded text-white bg-blue-700">
                      <span>Have you EVER given false or misleading information to any U.S. government official while applying for any immigration benefit or to prevent deportation, exclusion, or removal?<span className="text-red-500">*</span></span>
                    </div>
                    <div className='flex gap-4 p-4 bg-blue-100'>
                      <label className='flex gap-2 text-blue-600'>
                        <input
                          type="radio"
                          value="yes"
                          checked={givenFalseInformation === 'yes'}
                          onChange={() => handleGivenFalseInformationChange('yes')}
                        />
                        Yes
                      </label>
                      <label className='flex gap-2 text-blue-600'>
                        <input
                          type="radio"
                          value="no"
                          checked={givenFalseInformation === 'no'}
                          onChange={() => handleGivenFalseInformationChange('no')}
                        />
                        No
                      </label>
                    </div>
                  </div>
                )}

                {givenFalseInformation === 'no' && (
                  <>
                    <div className='flex text-blue-600 flex-col mt-10'>
                      <div className="p-4 rounded text-white bg-blue-700">
                        <span>Have you or an immediate family member ever served honorably in the U.S. armed forces?<span className="text-red-500">*</span></span>
                      </div>
                      <div className='flex gap-4 bg-blue-100 p-4'>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="yes"
                            checked={servedInMilitary === 'yes'}
                            onChange={() => handleServedInMilitaryChange('yes')}
                          />
                          Yes
                        </label>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="no"
                            checked={servedInMilitary === 'no'}
                            onChange={() => handleServedInMilitaryChange('no')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className='bg-slate-200 p-2'>Select "Yes" only if you, your spouse, your parent or your child served honorably in the U.S. Army, Marines, Navy, Air Force, Space Force or Coast Guard. Explain more >> <span className="text-blue-500"> Explain More >></span></p>

                    {(servedInMilitary === 'no' || servedInMilitary === 'yes') && (
                      <div className='flex gap-2 bg-green-300 items-center p-2 rounded mt-20'>
                        <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" />
                        <p> <span className='font-bold'>Congratulations, you're likely eligible to apply for employment authorization. </span>
                          <br />   Please click on "Next Page" below to continue your application.</p>
                      </div>)}


                  </>
                )}
                {givenFalseInformation === 'yes' && (
                  <>
                    <div className='bg-yellow-200 p-2'>
                      <div>
                        <div className='flex gap-3 justify-between'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                          <p><span className='font-bold'>Your answer to this question suggests that you have a history which may require the assistance of a legal professional. </span>We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
                        </div>
                        <div className='flex gap-3 mt-4 items-center'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Search_60.png" alt="" className='h-[30px] w-[30px]' />
                          <p className='font-bold text-blue-500'>Help me find an immigration attorney</p>
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-2 text-black justify-end bg-green-200 w-[610px] ml-auto rounded-md p-2 '>
                      <label className='flex gap-2'>
                        I acknowledge that I have spoken to an attorney and/or want to continue anyway
                        <input
                          type="checkbox"
                          checked={acknowledgeAttorney}
                          onChange={handleAcknowledgeAttorneyChange}
                        />
                      </label>
                    </div>
                  </>
                )}
              </>


            )}

          {selectedGroup === "NIW Physician - Renewal EAD for National Interest Waiver Physicians" &&
            <div className='flex gap-4 bg-red-200 p-2 rounded'>
              <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[32px] h-[32px]' />
              <p>You may only request a renewal of your EAD with this eligibility category. If you wish to apply for a renewal EAD as a National Interest Waiver Physician, please go to the intial question on this page and change it to "renewal."</p>
            </div>
          }

          {selectedGroup === "Consideration of Deferred Action for Childhood Arrivals (DACA)" &&
            <div className='bg-red-200 p-2'>
              <div className='flex gap-3  justify-between'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                <p>If you are filing an initial or renewal application for Deferred Action for Childhood Arrivals (DACA), we encourage you to use our DACA application package. It includes Forms I-821D, I-765, and the I-765 worksheet (all required forms for a DACA application).</p>
              </div>
              <div className='flex gap-3 mt-4 items-center'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                <p className='font-bold text-blue-500'>Start DACA Application</p>
              </div>
            </div>


          }


          {selectedGroup === "Dependent of A-1 or A-2 Foreign Government Officials"
            || selectedGroup === "Dependent of G-1, G-3 or G-4 Nonimmigrant"
            || selectedGroup === "Dependent of NATO-1 Through NATO-6"

            &&
            <div className='bg-red-200 p-2'>
              <div className='flex gap-3  justify-between'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue.</span> Although this is a valid eligibility category, CitizenPath does support the category at this time. Due to the complexities of obtaining employment authorization for this category, we recommend that you use an experienced immigration attorney.</p>
              </div>
              <div className='flex gap-3 mt-4 items-center'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" />
                <p className='font-bold text-blue-500'>Help me find an immigration attorney</p>
              </div>
            </div>
          }

          {selectedGroup === " B-1 Nonimmigrant Who Is the Personal or Domestic Servant of a Nonimmigrant Employe"
            || selectedGroup === "Spouse of an E-2 CNMI Investor"
            || selectedGroup === "Principal Beneficiary of an Approved Employment Petition Facing Compelling Circumstances"
            || selectedGroup === "Legalization Temporary Resident Pursuant to INA Sections 245A or 210"
            || selectedGroup === "Applicant for Legalization Pursuant to INA Section 210"
            || selectedGroup === "Applicant for Legalization Pursuant to INA 245A"
            || selectedGroup === "Final Order of Deportation or Removal, including Deferral of Removal under CAT"
            || selectedGroup === "LIFE Legalization Applicant"
            &&
            <div className='bg-red-200 p-2'>
              <div className='flex gap-3  justify-between'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue</span> Although this is a valid eligibility category, CitizenPath does support the category at this time. Due to the complexities of obtaining employment authorization for this category, we recommend that you use an experienced immigration attorney.</p>
              </div>
              <div className='flex gap-3 mt-4 items-center'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" />
                <p className='font-bold text-blue-500'>Help me find an immigration attorney</p>
              </div>
            </div>


          }

          {selectedGroup === "K-1 Nonimmigrant Fiancé(e) of U.S. Citizen or K-2 Dependent"

            &&
            <div className='flex gap-4 bg-red-200 p-2 rounded'>
              <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[32px] h-[32px]' />
              <p>If you were admitted to the United States as a K-1 fiancé or K-2 child of a fiancé, it is generally more practical to apply for an EAD based on your adjustment of status application. If you are filing Form I-485 or have already filed Form I-485, you are eligible as an "adjustment of status applicant" and will pay no USCIS fee. Go back and select the (c)(9) category.</p>
            </div>
          }
          {selectedGroup === "Family Unity Program"

            &&
            <div className='flex gap-4 bg-red-200 p-2 rounded'>
              <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[32px] h-[32px]' />
              <p>If you were admitted to the United States as a K-1 fiancé or K-2 child of a fiancé, it is generally more practical to apply for an EAD based on your adjustment of status application. If you are filing Form I-485 or have already filed Form I-485, you are eligible as an "adjustment of status applicant" and will pay no USCIS fee. Go back and select the (c)(9) category.</p>
            </div>
          }
          {selectedGroup === "LIFE Family Unity"

            &&
            <div className='flex gap-4 bg-red-200 p-2 rounded'>
              <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[32px] h-[32px]' />
              <p>If you were admitted to the United States as a K-1 fiancé or K-2 child of a fiancé, it is generally more practical to apply for an EAD based on your adjustment of status application. If you are filing Form I-485 or have already filed Form I-485, you are eligible as an "adjustment of status applicant" and will pay no USCIS fee. Go back and select the (c)(9) category.</p>
            </div>
          }


          {selectedGroup === "U-1 Nonimmigrant"
            &&
            (
              <>
                <div className='flex gap-4 bg-orange-200 p-2 rounded'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt="" className='w-[32px] h-[32px]' />
                  <p>U-1 Nonimmigrants: If you are currently residing in the United States and your Form I-918, Petition for U Nonimmigrant Status, is approved, you will receive employment authorization incident to status and USCIS will send you an EAD as evidence of that authorization. You do not need to file Form I-765. If you resided outside the United States when your Form I-918 was approved, you must file Form I-765 with USCIS when you enter the United States</p>
                </div>

                <div className="p-4 rounded text-white bg-blue-700">
                  <span>Are you at least 18 <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-4 p-4 bg-blue-100'>
                  <label className='flex  gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="yes"
                      checked={isAtLeastFifteen === 'yes'}
                      onChange={() => handleIsAtLeastFifteenChange('yes')}
                    />
                    Yes
                  </label>
                  <label className='flex gap-2 text-blue-600'>
                    <input
                      type="radio"
                      value="no"
                      checked={isAtLeastFifteen === 'no'}
                      onChange={() => handleIsAtLeastFifteenChange('no')}
                    />
                    No
                  </label>
                </div>

                <div className='flex text-blue-600 mt-10 flex-col'>
                  <div className="p-4 rounded text-white bg-blue-700">
                    <span>Are you filing Form I-485 on the basis of asylum or refugee status? <span className="text-red-500">*</span></span>
                  </div>
                  <div className='flex gap-4 p-4 bg-blue-100'>
                    <label className='flex gap-2 text-blue-600'>
                      <input
                        type="radio"
                        value="yes"
                        checked={isFilingAsylum === 'yes'}
                        onChange={() => handleIsFilingAsylumChange('yes')}
                      />
                      Yes
                    </label>
                    <label className='flex gap-2 text-blue-600'>
                      <input
                        type="radio"
                        value="no"
                        checked={isFilingAsylum === 'no'}
                        onChange={() => handleIsFilingAsylumChange('no')}
                      />
                      No
                    </label>
                  </div>
                  <p className='bg-slate-200 p-2'>Asylees and refugees file under a different eligibility category. <span className='text-blue-500'> Explain More >> </span></p>
                </div>

                {isFilingAsylum === 'yes' && (
                  <div className='bg-red-200 p-2'>
                    <div className='flex gap-3 justify-between'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                      <p><span className='font-bold'>We found a problem. Based on your answer, you cannot continue. </span>If you were admitted as a refugee or granted asylum and are now filing Form I-485 to adjust status to permanent resident on that basis, do not file Form I-765 under eligibility category (c)(9) as an adjustment of status applicant. Instead, go back and select your relevant category:</p>
                    </div>
                    <div className='flex gap-3 mt-4 items-center'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                      <p>Use (a)(5) for asylum</p>
                    </div>
                    <div className='flex gap-3 mt-4 items-center'>
                      <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" />
                      <p>Use (a)(3) for refugee status</p>
                    </div>
                  </div>
                )}

                {isFilingAsylum === 'no' && (
                  <>
                    <div className='flex text-blue-600 flex-col mt-10'>
                      <div className="p-4 rounded text-white bg-blue-700">
                        <span>Have you EVER been arrested or charged with any misdemeanor or felony crime in the United States?<span className="text-red-500">*</span></span>
                      </div>
                      <div className='flex gap-4 bg-blue-100 p-4'>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="yes"
                            checked={hasBeenArrested === 'yes'}
                            onChange={() => handleHasBeenArrestedChange('yes')}
                          />
                          Yes
                        </label>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="no"
                            checked={hasBeenArrested === 'no'}
                            onChange={() => handleHasBeenArrestedChange('no')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className='bg-slate-200 p-2'>Traffic violations do not need to be included unless they are alcohol or drug related. <span className="text-blue-500"> Explain More >></span></p>
                  </>
                )}

                {hasBeenArrested === 'yes' && (
                  <>
                    <div className='bg-yellow-200 p-2'>
                      <div>
                        <div className='flex gap-3 justify-between'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='h-[30px] w-[30px]' />
                          <p><span className='font-bold'>Your answer to this question suggests that you have a history which may require the assistance of a legal professional. </span>We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
                        </div>
                        <div className='flex gap-3 mt-4 items-center'>
                          <img src="https://forms.citizenpath.com/assets/img/Icon_Search_60.png" alt="" className='h-[30px] w-[30px]' />
                          <p className='font-bold text-blue-500'>Help me find an immigration attorney</p>
                        </div>
                      </div>
                    </div>
                    <div className='flex gap-2 text-black justify-end bg-green-200 w-[610px] ml-auto rounded-md p-2 '>
                      <label className='flex gap-2'>
                        I acknowledge that I have spoken to an attorney and/or want to continue anyway
                        <input
                          type="checkbox"
                          checked={acknowledgeAttorney}
                          onChange={handleAcknowledgeAttorneyChange}
                        />
                      </label>
                    </div>
                  </>
                )}

                {hasBeenArrested === 'no' && (
                  <div className='flex text-blue-600 flex-col mt-10'>
                    <div className="p-4 rounded text-white bg-blue-700">
                      <span>Have you EVER given false or misleading information to any U.S. government official while applying for any immigration benefit or to prevent deportation, exclusion, or removal?<span className="text-red-500">*</span></span>
                    </div>
                    <div className='flex gap-4 p-4 bg-blue-100'>
                      <label className='flex gap-2 text-blue-600'>
                        <input
                          type="radio"
                          value="yes"
                          checked={givenFalseInformation === 'yes'}
                          onChange={() => handleGivenFalseInformationChange('yes')}
                        />
                        Yes
                      </label>
                      <label className='flex gap-2 text-blue-600'>
                        <input
                          type="radio"
                          value="no"
                          checked={givenFalseInformation === 'no'}
                          onChange={() => handleGivenFalseInformationChange('no')}
                        />
                        No
                      </label>
                    </div>
                  </div>
                )}

                {givenFalseInformation === 'no' && (
                  <>
                    <div className='flex text-blue-600 flex-col mt-10'>
                      <div className="p-4 rounded text-white bg-blue-700">
                        <span>Have you or an immediate family member ever served honorably in the U.S. armed forces?<span className="text-red-500">*</span></span>
                      </div>
                      <div className='flex gap-4 bg-blue-100 p-4'>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="yes"
                            checked={servedInMilitary === 'yes'}
                            onChange={() => handleServedInMilitaryChange('yes')}
                          />
                          Yes
                        </label>
                        <label className='flex gap-2 text-blue-600'>
                          <input
                            type="radio"
                            value="no"
                            checked={servedInMilitary === 'no'}
                            onChange={() => handleServedInMilitaryChange('no')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className='bg-slate-200 p-2'>Select "Yes" only if you, your spouse, your parent or your child served honorably in the U.S. Army, Marines, Navy, Air Force, Space Force or Coast Guard. Explain more >> <span className="text-blue-500"> Explain More >></span></p>

                    {(servedInMilitary === 'no' || servedInMilitary === 'yes') && (
                      <div className='flex gap-2 bg-green-300 items-center p-2 rounded mt-20'>
                        <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" />
                        <p> <span className='font-bold'>Congratulations, you're likely eligible to apply for employment authorization. </span>
                          <br />   Please click on "Next Page" below to continue your application.</p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}

          <div className='flex  text-white mt-32'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>




        </form>
        <FormFotter />
      </div>
    </div>

  )
}

export default Eligibilityforoneseven