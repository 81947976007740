
import React, { useState } from 'react';
import Header from '../components/Header';
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import tikpik from '../Assets/tikpic.png';
import crosspic from '../Assets/crosspic.png';
import FormFotter from '../components/FormFotter';
import { useFormContext } from '../UseContext/UseContext';
import { useNavigate } from 'react-router-dom';
import marker from "../Assets/marker.PNG"
import beforeMarker from "../Assets/beforeMarker.png"



const Freedom = () => {

     const navigate=useNavigate()
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
      setClicked(!clicked);
    };
    
    const handleSubmit = (e) => {
      e.preventDefault();
      navigate('/EligibiltyI30');
    };

  return ( 
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto '>
           <Header />
           <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
           <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

{/* <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
  <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
    <span className='mt-4 font-bold text-xl ml-2 text-black '>
      First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
    </span>
    <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
      <button className='mr-2'>Page 1 of 3</button>
      <button><MdArrowDropDown /></button>
    </div>
  </div>
  <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
</div> */}

<form action=" " className='mt-4 w-5/6' onSubmit={handleSubmit}>
  <p className="font-sans text-base font-normal leading-6 text-slate-500">
    CitizenPath is a private company. We are not a part of U.S. Citizenship and Immigration Services (USCIS) or any other government agency. Your access to and use of this site is subject to additional <a href="" className='text-blue-700'>Terms of Use.</a>
  </p>

  <p className="font-sans text-base font-normal leading-6 text-slate-500 mt-8">
    CitizenPath’s self-directed immigration software helps you correctly navigate the immigration process. The service makes the process easy and helps you identify potential problems before you pay.  <span className='font-bold'> Form G-639 can be completed on the <span className='text-blue-600'>USCIS website</span>  for no cost</span>. Our FOIA Request Package costs $39 and includes: 
  </p>

  <div className='flex gap-2 mt-8'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10" />
    <p className='font-sans text-base font-normal leading-6 text-slate-500'><span className='font-bold'> Access to Attorney-Reviewed Software –</span> designed to make the process easy and identify problems</p>
  </div>

  <div className='flex gap-2 mt-8'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10" />
    <p className='font-sans text-base font-normal leading-6 text-slate-500'><span className='font-bold'>Form G-639, Freedom of Information Act Request – </span>official USCIS form prepared and ready to sign</p>
  </div>

  {/* ... (repeat for other images) */}

  <div className='flex gap-2 mt-8  font-sans text-base font-normal leading-6'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10" />
    <p className='font-sans text-base font-normal leading-6 text-slate-500'><span className='font-bold'>Access to customer support</span></p>
  </div>

  <div className='flex gap-2 mt-8  font-sans text-base font-normal leading-6'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10" />
    <p className='font-sans text-base font-normal leading-6 text-slate-500'><span className='font-bold'>Access to support articles, sample cover letters and affidavits</span></p>
  </div>

  <div className='flex gap-2 mt-8  font-sans text-base font-normal leading-6'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className="w-6 h-6 sm:w-8 sm:h-8 md:w-10 md:h-10" />
    <p className='font-sans text-base font-normal leading-6 text-slate-500 hover:cursor-pointer'><span className='font-bold'>Money-back guarantee your application will be approved</span></p>
  </div>

  <p className='font-sans text-base font-normal leading-6 text-slate-500 mt-4'>Upon completion, you have responsibilities:</p>

  <ul className='mt-4 ml-4'>
    <li className='flex items-center gap-2'>
      <img src={marker} alt="" />
      <p className='font-sans text-base  leading-6  font-bold text-blue-700 '> <u className='hover:cursor-text'>You</u> must mail your signed form directly to USCIS and</p>
    </li>
    <li className='flex items-center gap-2'>
      <img src={marker} alt="" />
      <p className='font-sans text-base  leading-6 font-bold text-blue-700 '> <u>You</u> must pay any <u className='hover:cursor-pointer'> USCIS fees </u>related to this form</p>
    </li>
  </ul>

  <div className={`flex  gap-3 sm:flex-row  text-center justify-center items-center py-4 mx-auto mt-5 rounded-full sm:w-[80%] md:w-[70%] lg:w-[60%] ${clicked ? 'bg-green-600' : 'bg-[#ace1c5] hover:bg-green-600'}`} onClick={handleClick}>
  {!clicked && <img src={beforeMarker} alt="" className='mb-2 sm:mb-0' />}
  {clicked && <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className='mb-2 sm:mb-0' />}
  <p className='font-sans text-base leading-6 text-white'>
    I understand and agree. Let's get started.
  </p>
</div>

  <div className='flex text-white mt-24 items center w-[100px] h-[40px] ml-auto'>
    <div className='bg-green-600 flex items-center justify-center py-2 rounded-xl shadow'>
      <button type="submit" className='text-white rounded'>
        Next
      </button>
      <BsArrowRight />
    </div>
  </div>

  <div className='flex justify-end mt-2'>
    <span>or <span className='text-blue-500'>Save Your progress?</span></span>
  </div>
</form>
        <FormFotter />
      </div>
    </div>


  )
}

export default Freedom
