import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const ApplicationFamilyInfo = () => {
  const navigate = useNavigate()
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/ApplicationFamilyInfo2")
  }

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's Family Information
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 7 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            Please provide information about your marital history and family
            members.
          </span>
        </div>
        <form action='' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          {/* start */}

          <div className='mt-10'>
            <label className='bg-blue-600  text-white p-4 flex'>
              How many times have you been married?
            </label>
            <div className='flex gap-4 bg-blue-100 p-4 text-blue-600'>
              <select
                id='howManyTimesHaveYouBeenMarried'
                name='howManyTimesHaveYouBeenMarried'
                value={formData.howManyTimesHaveYouBeenMarried ?? 0}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
              >
                <option value=''>Please Select</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
              </select>
            </div>
          </div>

          <hr className='h-1 bg-blue-600 text-white mt-10' />

          <h1 className='flex text-blue-600 font-semibold text-xl'>
            Applicant's Current Marriage
          </h1>
          <h1 className='flex text-blue-600 font-semibold text-xl mt-4'>
            Date & Place of Marriage
          </h1>

          <p>Provide the date and place of your current marriage.</p>

          <div className='mt-4 grid gap-4'>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Date of Marriage
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='date'
                  name='dateOfMarriage'
                  value={formData.dateOfMarriage}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>Country</label>
              <span className='flex text-blue-600'>
                <select
                  id='countryOfCitizenship'
                  name='countryOfCitizenship'
                  value={formData.countryOfCitizenship}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                State or Province
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='stateOrProvince'
                  value={formData.stateOrProvince}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>City</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='city'
                  value={formData.city}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>

          <hr className='h-1 bg-gray-100 text-white mt-4 mb-4' />

          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Current Spouse's Name
            </h1>

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Given Name (First Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseName'
                  value={formData.currentSpouseName}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Middle Name (if applicable)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseMiddleName'
                  value={formData.currentSpouseMiddleName}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Family Name (Last Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseLastName'
                  value={formData.currentSpouseLastName}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>

          {/* Current Spouse's Birth Details Section */}
          <hr className='h-1 bg-gray-100 text-white mt-4 mb-4' />

          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Current Spouse's Birth Details
            </h1>

            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Date of Birth</label>
              <span className='flex text-blue-600'>
                <input
                  type='date'
                  name='currentSpouseDOB'
                  value={formData.currentSpouseDOB}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>City</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseCity'
                  value={formData.currentSpouseCity}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                State or Province
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseStateOrProvince'
                  value={formData.currentSpouseStateOrProvince}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Country</label>
              <span className='flex text-blue-600'>
                <select
                  id='currentSpouseCitizenship'
                  name='currentSpouseCitizenship'
                  value={formData.currentSpouseCitizenship}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>

          {/* Current Spouse's Other Information */}

          <hr className='h-1 bg-gray-100 text-white mt-4 mb-4' />

          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Current Spouse's Other Information
            </h1>

            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Alien Registration Number (A-Number)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseAlienRegestrationNumber'
                  placeholder='########'
                  value={formData.currentSpouseAlienRegestrationNumber}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px] text-wrap'>
                Is your current spouse a member of the U.S. armed forces or U.S.
                Coast Guard?
              </label>
              <span className='flex ml-4'>
                <input
                  id='currentSpouseIsUsArmedForcesMember'
                  type='radio'
                  name='currentSpouseIsUsArmedForcesMember'
                  value={formData.currentSpouseIsUsArmedForcesMember}
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='IsUsArmedForcesMember'>Yes</label>
              </span>
              <span className='flex'>
                <input
                  id='currentSpouseIsUsArmedForcesMember'
                  type='radio'
                  name='currentSpouseIsUsArmedForcesMember'
                  value={formData.currentSpouseIsUsArmedForcesMember}
                  onChange={handleInputChange}
                  className='ml-9'
                />
                <label htmlFor='IsUsArmedForcesMember'>No</label>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                State or Province
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='currentSpouseStateOrProvince2'
                  value={formData.currentSpouseStateOrProvince2}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>

          {/* Children Section */}

          <hr className='h-1 bg-gray-100 text-white mt-4 mb-4' />

          <div className='mt-4 grid'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Children
            </h1>

            <div className=''>
              <label>
                List ALL of your living children, regardless of age, immigration
                status, or where they live.
              </label>
              <div className='mt-10'>
                <label className='bg-blue-600  text-white p-4 flex'>
                  How many children do you have?
                </label>
                <div className='gap-4 bg-blue-100 p-4 text-blue-600'>
                  <select
                    id='childrenCount'
                    name='childrenCount'
                    value={formData.childrenCount}
                    onChange={handleInputChange}
                    className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                  >
                    <option value=''>Please Select</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>More than 12</option>
                  </select>
                </div>
              </div>
            </div>

            {formData?.childrenCount > 0 ? (
              <div className='mt-4 grid gap-4 bg-gray-100 p-4'>
                <h1 className='flex text-blue-600 font-semibold text-xl'>
                  Child
                </h1>

                <div className='flex items-center ml-4'>
                  <label className='w-[300px] text-blue-500'>
                    Given Name (First Name)
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='text'
                      name='childFirstName'
                      value={formData.childFirstName}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    Middle Name (if applicable)
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='text'
                      name='childMiddleName'
                      value={formData.childMiddleName}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    Family Name (Last Name)
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='text'
                      name='childLastName'
                      value={formData.childLastName}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    Date of Birth
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='date'
                      name='childDOB'
                      value={formData.childDOB}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    Relationship
                  </label>
                  <span className='flex text-blue-600'>
                  <select
                  id='childRelationship'
                  name='childRelationship'
                  value={formData.childRelationship}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                    <option>Son</option>
                    <option>Daughter</option>
                </select>
                  </span>
                </div>
                <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Alien Registration Number (A-Number)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='childAlienNumber'
                  placeholder='########'
                  value={formData.childAlienNumber}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>Country Of birth</label>
              <span className='flex text-blue-600'>
                <select
                  id='childCountryOfBirth'
                  name='childCountryOfBirth'
                  value={formData.childCountryOfBirth}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px] text-wrap'>
              Is this child unmarried AND under the age of 21 years?
              </label>
              <span className='flex ml-4'>
                <input
                  id='childUnmarriedAndUnder21'
                  type='radio'
                  name='childUnmarriedAndUnder21'
                  value={formData.childUnmarriedAndUnder21}
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='childUnmarriedAndUnder21'>Yes</label>
              </span>
              <span className='flex'>
                <input
                  id='childUnmarriedAndUnder21'
                  type='radio'
                  name='childUnmarriedAndUnder21'
                  value={formData.childUnmarriedAndUnder21}
                  onChange={handleInputChange}
                  className='ml-9'
                />
                <label htmlFor='childUnmarriedAndUnder21'>No</label>
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px] text-wrap'>
              Is this child applying with you?              </label>
              <span className='flex ml-4'>
                <input
                  id='childAppylingWithYou'
                  type='radio'
                  name='childAppylingWithYou'
                  value={formData.childAppylingWithYou}
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='childAppylingWithYou'>Yes</label>
              </span>
              <span className='flex'>
                <input
                  id='childAppylingWithYou'
                  type='radio'
                  name='childAppylingWithYou'
                  value={formData.childAppylingWithYou}
                  onChange={handleInputChange}
                  className='ml-9'
                />
                <label htmlFor='childAppylingWithYou'>No</label>
              </span>
            </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default ApplicationFamilyInfo
