


import React from 'react'

import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context134';  
 
 const BenificiaryInformation = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code ,territories , nameSets ,handleAddAnother ,handleRemove ,handleChange } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault() 
          navigate("/prepearerandinterpreter")
    }

    console.log(formData.selectedTerritory)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>


    <div className=''>
        <h1 className='text-blue-600 font-semibold text-xl'>Beneficiary's Current Legal Name</h1>
        <p className='text-sm'>Provide the beneficiary's full legal name. Spell out middle names completely and do not use just the initial. If this person does not have a middle name, you may leave this field empty. Explain more</p>
    </div>
            
     <div className='flex flex-col mt-10'>
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Given Name (First Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='givenName'
        value={formData.givenName}
        onChange={handleInputChange}
      />
      </div>
     
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Middle Name(s)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='middleNames'
        value={formData.middleNames}
        onChange={handleInputChange}
      />
      </div>

       <div className='flex mt-10 gap-2'>
      <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='familyName'
        value={formData.familyName}
        onChange={handleInputChange}
      />
      </div>
    </div>
       

       <hr className='bg-blue-600 mt-10 h-1'/>

       <div className='mt-10'>
        <h1 className='font-semibold text-xl text-blue-600'>Beneficiary's Other Names</h1>
        <p>Provide all other names the beneficiary has ever used, including aliases, maiden name, and nicknames. Explain more</p>
       </div>

    <div className='flex flex-col mt-10'>
      <label className='flex bg-blue-600 p-4 text-white rounded'>
        Have you used any other names since birth?
      </label>
      <div className='flex gap-10 p-4 bg-blue-100'>
        <label htmlFor='usedOtherNamesYes' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='usedOtherNamesSinceBirth'
            id='usedOtherNamesYes'
            value='Yes'
            checked={formData.usedOtherNamesSinceBirth === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label htmlFor='usedOtherNamesNo' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='usedOtherNamesSinceBirth'
            id='usedOtherNamesNo'
            value='No'
            checked={formData.usedOtherNamesSinceBirth === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
      <p className='bg-slate-200 p-4'>
           Indicate if you have ever used another name such as nicknames, aliases or a maiden name. Explain more </p>
    </div>
  
   {formData.usedOtherNamesSinceBirth === 'No'
   &&
    <div className="mt-20">
      {nameSets.map((nameSet, index) => (
        <>
        
          <div key={nameSet.id} className=' bg-slate-50 rounded border border-blue-200'>
            {index > 0 && (
              <div className='flex w-full justify-between ml-2'>
                <span className="text-gray-500">{`Add Other ${index}`}</span>
                <button
                  type="button"
                  onClick={() => handleRemove(nameSet.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4"
                >
                  Remove
                </button>
              </div>
            )}

            {/* Select Option for Countries */}
          

            {/* Input Field for Given Name */}
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                Given Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={`givenName-${nameSet.id}`}
                name={`givenName-${nameSet.id}`}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4'
              />
            </div>

            {/* Input Field for Family Name */}
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-700">
                Family Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={`familyName-${nameSet.id}`}
                name={`familyName-${nameSet.id}`}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2'
              />
            </div>

            {/* Input Field for Last Name */}
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`lastName-${nameSet.id}`} className="text-blue-700">
                Last Name: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id={`lastName-${nameSet.id}`}
                name={`lastName-${nameSet.id}`}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4'
              />
            </div>

            {/* Additional Input Fields */}
            {/* ... (Marriage Date, etc.) */}
          </div>
        </>
      ))}

      {nameSets.length === 0 && (
        <div>
          <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
          <span className="text-gray-500">Add Other</span>
        </div>
      )}

      <button
        type="button"
        onClick={handleAddAnother}
        className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
      >
        Add Another Name
      </button>
    </div>}
      

    <hr  className='bg-blue-600 h-1 mt-10'/>


<div className='mt-10'>
 <h1  className='text-blue-600  font-semibold text-xl'>Beneficiary's Mailing Address</h1>
 <p className='text-sm'>Enter a home address or other address where the beneficiary can safely receive mail. This may be a Post Office address (P.O. Box) if that is how he/she receives mail. If mail will be sent to someone other than the beneficiary, please include an “In Care of Name” as a part of the mailing address.</p>
</div>


<div className='flex mt-10 gap-2 flex-col'>
 <div className='flex mt-10 gap-2'>
   <label className="w-[200px] text-blue-500">In Care of Name (if any)</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.inCareOfName'
     value={formData.inCareOfName}
     onChange={handleInputChange}
   />
   </div>

   <div className='flex mt-10 gap-2'>

   <label className="w-[200px] text-blue-500">Street Number and Name</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.streetNumberAndName'
     value={formData.streetNumberAndName}
     onChange={handleInputChange}
   />
</div>

<div className='flex mt-2 gap-2'>
   <label className='w-[200px] text-blue-500'>
     Is there an apartment, suite, or floor?
   </label>
   <select
     className='w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6'
     name='apartmentSuiteFloor'
     value={formData.apartmentSuiteFloor}
     onChange={handleInputChange}
   >
     <option value='No'>No</option>
     <option value='Apt'>Apt</option>
     <option value='Flr'>Flr</option>
     <option value='Ste'>Ste</option>
   </select>
 </div>

{formData.apartmentSuiteFloor !== 'No'
 &&
 <div className='flex mt-10 gap-2'>
   <label className="w-[200px] text-blue-500">Enter the number here:</label>
   <input
     className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.enterNumber'
     value={formData.enterNumber}
     onChange={handleInputChange}
   />
   </div>}

   <div className='flex mt-10 gap-2'>

   <label className="w-[200px] text-blue-500">City or Town</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.cityOrTown'
     value={formData.cityOrTown}
     onChange={handleInputChange}
   />
   </div>

   
   <div className='flex mt-10 gap-2'>
 
     <label className="w-[200px] text-blue-500">
       Country
     </label>
     <select
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
       value={formData.country}
       onChange={handleInputChange}
     >
       <option value=''>Select a country</option>
       {code.map((Country, index) => (
         <option key={index} value={Country}>
           {Country}
         </option>
       ))}
     </select>
   </div>
   <div className='flex mt-10 gap-2'>
     <label  className="w-[200px] text-blue-500">
       State (or Territory)
     </label>
     <select
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
       onChange={handleInputChange}
     >
       <option value=''>Select a state or territory</option>
       {code.map((state, index) => (
         <option key={index} value={state}>
           {state}
         </option>
       ))}
     </select>
   </div>

   <div className='flex mt-10 gap-2'>

   <label className="w-[200px] text-blue-500">ZIP Code</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.zipCode'
     value={formData.zipCode}
     onChange={handleInputChange}
   />
   </div>
 </div>


 <div className="mt-10">
      <label htmlFor="isPhysicalAddressSameAsMailing" className="bg-blue-700 text-white flex p-4">
        Is the beneficiary's physical address the same as the mailing address listed above?
      </label>
      <div className="bg-blue-100 p-4 flex  gap-5">
        <div className='flex gap-2'>
          <input
            type="radio"
            id="physicalAddressSameAsMailingYes"
            name="isPhysicalAddressSameAsMailing"
            value="Yes"
            checked={formData.isPhysicalAddressSameAsMailing === 'Yes'}
            onChange={handleInputChange}
          />
          <label htmlFor="physicalAddressSameAsMailingYes" className='text-blue-600'>Yes</label>
        </div>
        <div className='flex gap-2'>
          <input
            type="radio"
            id="physicalAddressSameAsMailingNo"
            name="isPhysicalAddressSameAsMailing"
            value="No"
            checked={formData.isPhysicalAddressSameAsMailing === 'No'}
            onChange={handleInputChange}
          />
          <label htmlFor="physicalAddressSameAsMailingNo" className='text-blue-600'>No</label>
        </div>
      </div>
    </div>

    <hr  className='bg-blue-600 mt-10 h-1'/>


    
<div className='mt-10'>
 <h1  className='text-blue-600  font-semibold text-xl'>Beneficiary's Physical Address</h1>
 <p className='text-sm'>Enter the address where the beneficiary lives.

</p>
</div>


<div className='flex mt-10 gap-2 flex-col'>
 <div className='flex mt-10 gap-2'>
   <label className="w-[200px] text-blue-500">In Care of Name (if any)</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.inCareOfName'
     value={formData.beniinCareOfName}
     onChange={handleInputChange}
   />
   </div>

   <div className='flex mt-10 gap-2'>

   <label className="w-[200px] text-blue-500">Street Number and Name</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.streetNumberAndName'
     value={formData.benistreetNumberAndName}
     onChange={handleInputChange}
   />
</div>

<div className='flex mt-2 gap-2'>
   <label className='w-[200px] text-blue-500'>
     Is there an apartment, suite, or floor?
   </label>
   <select
     className='w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6'
     name='apartmentSuiteFloor'
     value={formData.beniapartmentSuiteFloor}
     onChange={handleInputChange}
   >
     <option value='No'>No</option>
     <option value='Apt'>Apt</option>
     <option value='Flr'>Flr</option>
     <option value='Ste'>Ste</option>
   </select>
 </div>

{formData.apartmentSuiteFloor !== 'No'
 &&
 <div className='flex mt-10 gap-2'>
   <label className="w-[200px] text-blue-500">Enter the number here:</label>
   <input
     className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.enterNumber'
     value={formData.benienterNumber}
     onChange={handleInputChange}
   />
   </div>}

   <div className='flex mt-10 gap-2'>

   <label className="w-[200px] text-blue-500">City or Town</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.cityOrTown'
     value={formData.benicityOrTown}
     onChange={handleInputChange}
   />
   </div>

   
   <div className='flex mt-10 gap-2'>
 
     <label className="w-[200px] text-blue-500">
       Country
     </label>
     <select
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
       value={formData.benicountry}
       onChange={handleInputChange}
     >
       <option value=''>Select a country</option>
       {code.map((Country, index) => (
         <option key={index} value={Country}>
           {Country}
         </option>
       ))}
     </select>
   </div>
   <div className='flex mt-10 gap-2'>
     <label  className="w-[200px] text-blue-500">
       State (or Territory)
     </label>
     <select
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
       onChange={handleInputChange}
     >
       <option value=''>Select a state or territory</option>
       {code.map((state, index) => (
         <option key={index} value={state}>
           {state}
         </option>
       ))}
     </select>
   </div>

   <div className='flex mt-10 gap-2'>

   <label className="w-[200px] text-blue-500">ZIP Code</label>
   <input
     className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
     type='text'
     name='applicantMailingAddress.zipCode'
     value={formData.benizipCode}
     onChange={handleInputChange}
   />
   </div>
 </div>

<hr  className='flex bg-blue-600  h-1 mt-10'/>

  <h1 className='flex text-blue-600  font-semibold text-xl mt-10'>Nationality and Travel Information</h1>


  <div className='flex flex-col gap-5'>
    <div>
      <label htmlFor="countryOfCitizenship" className="w-[200px] text-blue-500">Country of Citizenship or Nationality:</label>
      <input
         className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="text"
        id="countryOfCitizenship"
        name="countryOfCitizenship"
        value={formData.countryOfCitizenship}
        onChange={handleInputChange}
      />
      </div>
     { !formData.departureUponApproval
     &&
     <div className='flex gap-16'>
    
      <label htmlFor="dateFrom" className="w-[200px] text-blue-500">Date From:</label>
      <input
       className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="date"
        id="dateFrom"
        name="dateFrom"
        value={formData.dateFrom}
        onChange={handleInputChange}
      />
      </div>}


      <div className="flex mt-10  gap-2">
      <div className="">
        <div className="">
          <input
            type="checkbox"
            id="departureUponApproval"
            name="departureUponApproval"
            checked={formData.departureUponApproval}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <label htmlFor="departureUponApproval" className="text-blue-600 ">
        Beneficiary will depart upon approval:
      </label>
    </div>
      <div className='flex gap-16 mt-10'>
      <label htmlFor="dateTo"  className="w-[200px] text-blue-500">Date To:</label>
      <input
        className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="date"
        id="dateTo"
        name="dateTo"
        value={formData.dateTo}
        onChange={handleInputChange}
       
      />
      </div>
    </div>


   
   <hr className='flex h-1 bg-blue-600 mt-10'/>
   <div className="mt-10">

  <label htmlFor="aNumber" className="text-white flex bg-blue-600 p-4 ">
    Alien Registration Number (A-Number):
  </label>
  <div className='flex bg-blue-100 p-4 '>
  <span className='bg-slate-400 flex p-3 items-center h-10 mt-2'>A</span>
  <input
    type="text"
    id="aNumber"
    name="aNumber"
    value={formData.aNumber}
    onChange={handleInputChange}
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded mt-2"
  />
  </div>
</div>


<div className="mt-10 flex flex-col">
  <label htmlFor="maritalStatus" className="text-white bg-blue-600 p-4 rounded">
    What is the beneficiary's marital status?
  </label>
  <div className='flex p-4 bg-blue-100  flex-col'>
  <div className="mt-2">
    <input
      type="radio"
      id="singleStatus"
      name="maritalStatus"
      value="Single and never married"
      checked={formData.maritalStatus === "Single and never married"}
      onChange={handleInputChange}
    />
    <label htmlFor="singleStatus" className="ml-2 text-blue-600">Single and never married</label>
  </div>

  <div className="mt-2">
    <input
      type="radio"
      id="marriedStatus"
      name="maritalStatus"
      value="Married"
      checked={formData.maritalStatus === "Married"}
      onChange={handleInputChange}
    />
    <label htmlFor="marriedStatus"className="ml-2 text-blue-600">Married</label>
  </div>

  <div className="mt-2">
    <input
      type="radio"
      id="divorcedStatus"
      name="maritalStatus"
      value="Divorced"
      checked={formData.maritalStatus === "Divorced"}
      onChange={handleInputChange}
    />
    <label htmlFor="divorcedStatus" className="ml-2 text-blue-600">Divorced</label>
  </div>

  <div className="mt-2">
    <input
      type="radio"
      id="widowedStatus"
      name="maritalStatus"
      value="Widowed"
      checked={formData.maritalStatus === "Widowed"}
      onChange={handleInputChange}
    />
    <label htmlFor="widowedStatus" className="ml-2 text-blue-600">Widowed</label>
  </div>

  <div className="mt-2">
    <input
      type="radio"
      id="legallySeparatedStatus"
      name="maritalStatus"
      value="Legally separated"
      checked={formData.maritalStatus === "Legally separated"}
      onChange={handleInputChange}
    />
    <label htmlFor="legallySeparatedStatus" className="ml-2 text-blue-600">Legally separated</label>
  </div>

  <div className="mt-2">
    <input
      type="radio"
      id="marriageAnnulledStatus"
      name="maritalStatus"
      value="Marriage annulled"
      checked={formData.maritalStatus === "Marriage annulled"}
      onChange={handleInputChange}
    />
    <label htmlFor="marriageAnnulledStatus" className="ml-2 text-blue-600">Marriage annulled</label>
  </div>
  </div>
</div>
            
            
  





                <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
 }
 
 export default BenificiaryInformation