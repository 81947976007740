import React from 'react'
import { useFormContext } from '../../UseContext/ContextFor821D';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';


const EligiblityI821D = () => {

    const navigate=useNavigate()

   
    const { formData, handleInputChange } = useFormContext();

    const FormSubmit=(e)=>{
        e.preventDefault()

        navigate("/publicsafty")
    }

    return (

        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
    
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
            Provide your current mailing address and contact information.
            </span>
          </div>
          <form action=" " className='mt-14 w-11/12' onSubmit={FormSubmit}>
          <div>
          <label className='flex bg-blue-600 w-full rounded p-4 text-white'>
            Are you currently in immigration detention?
            </label>
            <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
              <input
                type="radio"
                name="isDetained"
                value="Yes"
                checked={formData.isDetained === 'Yes'}
                onChange={handleInputChange}
              /> Yes
              <input
                type="radio"
                name="isDetained"
                value="No"
                checked={formData.isDetained === 'No'}
                onChange={handleInputChange}
              /> No
            </div>

           {formData.isDetained === 'Yes'
            &&
           <div className='bg-red-200 flex gap-2 flex-col '>
                <div className='flex gap-2  items-center ml-2' >
                <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[30px] h-[30px]' />
                <p>We found a problem. Based on your answer, you cannot continue. If you are currently in immigration detention, you may not request consideration of DACA or renewal of DACA from USCIS. CitizenPath recommends that you contact an experienced immigration attorney.</p>
                </div>
                <div className='flex gap-2 items-center ml-2'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]' />
                <p className='text-blue-500'>Find an immigration attorney</p>
                </div>
            
            </div>}
        
    
         {formData.isDetained === 'No' &&
         <>
         <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        
            I am requesting:
            </label>
            <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500  flex-col '>
                <div>
              <input
                type="radio"
                name="requestType"
                value="Initial request for consideration of DACA"
                checked={formData.requestType === 'Initial request for consideration of DACA'}
                onChange={handleInputChange}
              /> Initial request for consideration of DACA
              </div>
              <div>
              <input
                type="radio"
                name="requestType"
                value="Renewal request for consideration of DACA"
                checked={formData.requestType === 'Renewal request for consideration of DACA'}
                onChange={handleInputChange}
              /> Renewal request for consideration of DACA
              </div>
            </div>
            </>
            }
       
    
          <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
            Have you continuously resided in the U.S. since June 15, 2007, and up to the present time?
            </label>
            <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
              <input
                type="radio"
                name="hasResided"
                value="Yes"
                checked={formData.hasResided === 'Yes'}
                onChange={handleInputChange}
              /> Yes
              <input
                type="radio"
                name="hasResided"
                value="No"
                checked={formData.hasResided === 'No'}
                onChange={handleInputChange}
              /> No
            </div>
            <p className='bg-slate-200 p-2'>You must have been living in the U.S. and consider the U.S. your home since June 15, 2007. Explain more >>
                   </p>
      
    
          <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
            Did you depart the United States on or after August 15, 2012, without advance parole?
            </label>
            <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
              <input
                type="radio"
                name="departedUSA"
                value="Yes"
                checked={formData.departedUSA === 'Yes'}
                onChange={handleInputChange}
              /> Yes
              <input
                type="radio"
                name="departedUSA"
                value="No"
                checked={formData.departedUSA === 'No'}
                onChange={handleInputChange}
              /> No
            </div>
            <p className='bg-slate-200 p-2'>You can find your expiration date on your approval paperwork or the front of your Employment Authorization Card (work permit).</p>
    
    
          <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
            Have you been convicted of a felony, significant misdeanor, three or more other misdeanors, or post a threat to national security or public safety?
            </label>
            <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
              <input
                type="radio"
                name="convictions"
                value="Yes"
                checked={formData.convictions === 'Yes'}
                onChange={handleInputChange}
              /> Yes
              <input
                type="radio"
                name="convictions"
                value="No"
                checked={formData.convictions === 'No'}
                onChange={handleInputChange}
              /> No
            </div>
            <p className='bg-slate-200 p-2'>Generally, you must have had an advance parole document to leave the U.S. temporarily and return.</p>
       
    
          <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
            Have you ever served honorably in the U.S. armed forces?
            </label>
            <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
              <input
                type="radio"
                name="servedInArmedForces"
                value="Yes"
                checked={formData.servedInArmedForces === 'Yes'}
                onChange={handleInputChange}
              /> Yes
              <input
                type="radio"
                name="servedInArmedForces"
                value="No"
                checked={formData.servedInArmedForces === 'No'}
                onChange={handleInputChange}
              /> No
            </div>
            <p className='bg-slate-200 p-2'>You can find your expiration date on your approval paperwork or the front of your Employment Authorization Card (work permit).</p>
    
    
          <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
            My DACA status expires on:
            </label>
            <div className='bg-blue-100 w-full p-4'>
              <input
                className="w-[250px]  mr-2 border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
                type="date"
                name="expirationDate"
                value={formData.expirationDate}
                onChange={handleInputChange}
              />
            </div>
            <p className='bg-slate-200 p-2'>You can find your expiration date on your approval paperwork or the front of your Employment Authorization Card (work permit).</p>

            <div className='flex gap-2 bg-slate-100'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt="" className='w-[40px] h-[40px]'/>
                <p>Your most recent period of Deferred Action for Childhood Arrivals has already expired. You may submit an application to renew (if one year has not passed since your DACA expired). It's recommended that you file the application as soon as possible. More on When to Renew DACA</p>
            </div>
     
        </div>

        <div className='flex  text-white mt-32 w-[100px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>
        </form>
        <FormFotter />
  
          </div>
          </div>


 


        
      )
}

export default EligiblityI821D