import React, { useContext } from 'react'
import Header from '../../components/Header'
import FormFotter from '../../components/FormFotter'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { ContextFor1762 } from '../../UseContext/ContextFor1762';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const AddressInformation = () => {

    const navigate=useNavigate()

     const { 
        inCareOfName,
        streetNumberName,
        apartmentSuiteFloor,
        enterNumber,
        cityOrTownFormFour,
        handleInCareOfNameChange,
        handleStreetNumberNameChange,
        handleApartmentSuiteFloorChange,
        handleEnterNumberChange,
        handleCityOrTownChangeFormFour,
        selectedStateFormFour,
        handleChangeState,
        countriesFormFour,
        zipCode, handleChangeZipCode,
        isMailingAddressSame, handleChangeMailingAddress,
        handleChangeUsPhysicalAddress,
        handleChangeUsPhysicalApartmentSuiteFloor,
        handleChangeUsPhysicalEnterNumber,
        handleChangeUsPhysicalCityOrTown,
        handleChangeUsPhysicalSelectedState,
        handleChangeUsPhysicalZipCode,
        usPhysicalAddress,
        usPhysicalApartmentSuiteFloor,
        usPhysicalEnterNumber,
        usPhysicalCityOrTown,
        usPhysicalSelectedState,
        usPhysicalZipCode,
        countryCodes,
        selectedCountryCode,
        handleCountryCodeChange,
        daytimePhoneNumber,
        handleDaytimePhoneNumberChange,
        mobilePhoneNumber,
        handleMobilePhoneNumberChange,

    
    }= useContext(ContextFor1762)
  
    console.log(countriesFormFour)

    const handleSubmit=()=>{
        navigate("/finaldetail")
    }
  
    return (

    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
        <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
        <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
        <div className='h-full'>
          <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
          <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
        </div>
      </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        Provide your current mailing address and contact information.
        </span>
      </div>
      <form action=" " className='mt-14 w-11/12' onSubmit={handleSubmit} >
      <div className='flex flex-col gap-8'>
        <div className='flex '>
      <label htmlFor="inCareOfName" className='w-[200px] text-blue-500'>In Care of Name <span className="text-red-500">*</span></label>
      <input
        type="text"
        id="inCareOfName"
        value={inCareOfName}
        onChange={(e) => handleInCareOfNameChange(e.target.value)}
        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>
      <div className='flex'>
      <label htmlFor="streetNumberName" className='w-[200px]  text-blue-500'>Street Number and Name<span className="text-red-500">*</span></label>
      <input
        type="text"
        id="streetNumberName"
        value={streetNumberName}
        onChange={(e) => handleStreetNumberNameChange(e.target.value)}
        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>

      <div className="flex">
  <label htmlFor="apartmentSuiteFloor" className="w-[200px]  text-blue-500">
    Is there an apartment, suite, or floor?
    <span className="text-red-500">*</span>
  </label>
  <select
    id="apartmentSuiteFloor"
    value={apartmentSuiteFloor}
    onChange={(e) => handleApartmentSuiteFloorChange(e.target.value)}
    className=" w-[70px]  py-2 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8"
  >
    <option value="No">No</option>
    <option value="apt">apt</option>
    <option value="flr">flr</option>
    <option value="Ste">Ste</option>
  </select>
</div>


     {apartmentSuiteFloor !=="No" &&
      <div className='flex'>
      <label htmlFor="enterNumber" className='w-[200px]  text-blue-500' >Enter the number here <span className="text-red-500">*</span></label>
      <input
        type="text"
        id="enterNumber"
        value={enterNumber}
        onChange={(e) => handleEnterNumberChange(e.target.value)}
        className='w-[70px]  py-2 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>}
   
     <div className='flex'>
      <label htmlFor="cityOrTown" className='w-[200px]  text-blue-500'>City or Town <span className="text-red-500">*</span></label>
      <input
        type="text"
        id="cityOrTown"
        value={cityOrTownFormFour}
        onChange={(e) => handleCityOrTownChangeFormFour(e.target.value)}
        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>

      <div className="flex">
  <label htmlFor="state" className="w-[200px]  text-blue-500">
    State (or Territory)
    <span className="text-red-500">*</span>
  </label>
  <select
    id="state"
    value={selectedStateFormFour}
    onChange={(e) => handleChangeState(e.target.value)}
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
  >
    <option value="">Please select...</option>
    {countriesFormFour.map((country, index) => (
      <option key={index} value={country.common}>
        {country.common}
      </option>
    ))}
  </select>
</div>

<div className='flex gap-2'>
    <label htmlFor="zipCode" className="w-[200px]  text-blue-500">
      ZIP 
      <span className="text-red-500">*</span>
  </label>
      <input
        type="text"
        id="zipCode"
        value={zipCode}
        onChange={(e) => handleChangeZipCode(e.target.value)}
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      />
    </div>

    </div>


     


    <div   className='flex flex-col mt-10'>
       <label htmlFor="zipCode" className="w-full text-white bg-blue-500 p-4 rounded">
       Is the above mailing address also your current physical address?
      <span className="text-red-500">*</span>
         </label>
    <div className='flex gap-2 p-4 bg-blue-100'>
      <div className='flex gap-2'>
        <input
          type="radio"
          id="mailingAddressRadioYes"
          value="Yes"
          checked={isMailingAddressSame === "Yes"}
          onChange={() => handleChangeMailingAddress("Yes")}
        />
        <label htmlFor="mailingAddressRadioYes">Yes</label>
      </div>
      <div className='flex gap-2'>
        <input
          type="radio"
          id="mailingAddressRadioNo"
          value="No"
          checked={isMailingAddressSame === "No"}
          onChange={() => handleChangeMailingAddress("No")}
        />
        <label htmlFor="mailingAddressRadioNo">No</label>
      </div>
      </div>
      <p className='bg-slate-200 p-2'>Your physical address is the residence where you live.</p>
    </div>


   


    {isMailingAddressSame === "No"
    &&
<>
    <hr  className='h-1 bg-blue-500 w-full  mt-10'/>
       <div className='flex flex-col gap-8 mt-10'>
      <div className='flex'>
      <label htmlFor="streetNumberName" className='w-[200px]  text-blue-500'>Street Number and Name<span className="text-red-500">*</span></label>
      <input
        type="text"
        id="streetNumberName"
        value={usPhysicalAddress}
        onChange={(e) => handleChangeUsPhysicalAddress(e.target.value)}
        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>

      <div className="flex">
  <label htmlFor="apartmentSuiteFloor" className="w-[200px]  text-blue-500">
    Is there an apartment, suite, or floor?
    <span className="text-red-500">*</span>
  </label>
  <select
    id="apartmentSuiteFloor"
    value={usPhysicalApartmentSuiteFloor}
    onChange={(e) => handleChangeUsPhysicalApartmentSuiteFloor(e.target.value)}
    className=" w-[70px]  py-2 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8"
  >
    <option value="No">No</option>
    <option value="apt">apt</option>
    <option value="flr">flr</option>
    <option value="Ste">Ste</option>
  </select>
</div>


     {usPhysicalApartmentSuiteFloor !=="No" &&
      <div className='flex'>
      <label htmlFor="enterNumber" className='w-[200px]  text-blue-500' >Enter the number here <span className="text-red-500">*</span></label>
      <input
        type="text"
        id="enterNumber"
        value={usPhysicalEnterNumber}
        onChange={(e) => handleChangeUsPhysicalEnterNumber(e.target.value)}
        className='w-[70px]  py-2 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>}
   
     <div className='flex'>
      <label htmlFor="cityOrTown" className='w-[200px]  text-blue-500'>City or Town <span className="text-red-500">*</span></label>
      <input
        type="text"
        id="cityOrTown"
        value={usPhysicalCityOrTown}
        onChange={(e) => handleChangeUsPhysicalCityOrTown(e.target.value)}
        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
      />
      </div>

      <div className="flex">
  <label htmlFor="state" className="w-[200px]  text-blue-500">
    State (or Territory)
    <span className="text-red-500">*</span>
  </label>
  <select
    id="state"
    value={usPhysicalSelectedState}
    onChange={(e) => handleChangeUsPhysicalSelectedState(e.target.value)}
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
  >
    <option value="">Please select...</option>
    {countriesFormFour.map((country, index) => (
      <option key={index} value={country.common}>
        {country.common}
      </option>
    ))}
  </select>
</div>

<div className='flex gap-2'>
    <label htmlFor="zipCode" className="w-[200px]  text-blue-500">
      ZIP 
      <span className="text-red-500">*</span>
  </label>
      <input
        type="text"
        id="zipCode"
        value={usPhysicalZipCode}
        onChange={(e) => handleChangeUsPhysicalZipCode(e.target.value)}
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      />
    </div>

    </div>
    </>
    }


    <hr  className='h-1 bg-blue-500 mt-10'/>
       


       

    <div className='flex  text-white mt-24  items center w-[100px] h-[40px]  ml-auto'>
            <div className='bg-green-600 flex items-center  justify-center  py-2 rounded-xl shadow  '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>

    

     
  




        </form>
        <FormFotter />

       



      </div>
</div>
  
  )
}

export default AddressInformation