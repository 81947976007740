import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';


const Petitioner864 = () => {
    const { formData, handleInputChange ,code } = useFormContext();
          const navigate= useNavigate()
     const HandleSubmit =(e)=>{
          e.preventDefault()
          navigate("/basicinfo")
     }

    console.log(formData.intendingImmigrantRelationship)
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
    <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
        </span>
      </div>
      <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

     

    <div className='flex flex-col'>
        <h1 className='flex font-semibold text-xl text-blue-700'>Petitioner</h1>
        <p className='text-sm'>The petitioner is the person that filed the immigrant petition. Forms I-130, I-140, and I-129F are examples of immigrant petitions.</p>
    </div>

    <div className='mt-10'>
      <p className='bg-blue-700 flex  text-white p-4'>Select the petitioner's citizenship or residency:</p>
      <div className='flex flex-col bg-blue-100 p-4 text-blue-700'>
      <label>
        <input
          type="radio"
          name="petitionerCitizenship"
          value="U.S. citizen"
          checked={formData.petitionerCitizenship === 'U.S. citizen'}
          onChange={handleInputChange}
        />
        U.S. citizen
      </label>
      <label>
        <input
          type="radio"
          name="petitionerCitizenship"
          value="U.S. national"
          checked={formData.petitionerCitizenship === 'U.S. national'}
          onChange={handleInputChange}
        />
        U.S. national
      </label>
      <label>
        <input
          type="radio"
          name="petitionerCitizenship"
          value="Lawful permanent resident"
          checked={formData.petitionerCitizenship === 'Lawful permanent resident'}
          onChange={handleInputChange}
        />
        Lawful permanent resident
      </label>
      </div>
    </div>

    {(formData.petitionerCitizenship === 'U.S. national' || formData.petitionerCitizenship === 'Lawful permanent resident' )
         &&
        
        <div className='mt-10'>
      <p className='flex bg-blue-700 text-white p-4'>The intending immigrant is the petitioner's:</p>
      <div className='flex flex-col text-blue-700  p-4   bg-blue-100'>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationshipform2 "
          value="Spouse"
          checked={formData.intendingImmigrantRelationshipform2 === 'Spouse'}
          onChange={handleInputChange}
        />
        Spouse
      </label>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationshipform2 "
          value="Child (under age 21)"
          checked={formData.intendingImmigrantRelationshipform2 === 'Child (under age 21)'}
          onChange={handleInputChange}
        />
        Child (under age 21)
      </label>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationshipform2"
          value="Adult son/daughter"
          checked={formData.intendingImmigrantRelationshipform2 === 'Adult son/daughter'}
          onChange={handleInputChange}
        />
        Adult son/daughter
      </label>
      </div>
     </div>}


    {formData.petitionerCitizenship === 'U.S. citizen'
    &&
    
    <div className='mt-10'>
      <p className='flex bg-blue-700 p-4 text-white'>The intending immigrant is the petitioner's:</p>
      <div className='flex flex-col bg-blue-100 p-4 gap-2 text-blue-600'>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationship"
          value="Spouse"
          checked={formData.intendingImmigrantRelationship === 'Spouse'}
          onChange={handleInputChange}
        />
        Spouse
      </label>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationship"
          value="Child (under age 21)"
          checked={formData.intendingImmigrantRelationship === 'Child (under age 21)'}
          onChange={handleInputChange}
        />
        Child (under age 21)
      </label>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationship"
          value="Adult son/daughter"
          checked={formData.intendingImmigrantRelationship === 'Adult son/daughter'}
          onChange={handleInputChange}
        />
        Adult son/daughter
      </label>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationship"
          value="Parent (mother/father)"
          checked={formData.intendingImmigrantRelationship === 'Parent (mother/father)'}
          onChange={handleInputChange}
        />
        Parent (mother/father)
      </label>
      <label>
        <input
          type="radio"
          name="intendingImmigrantRelationship"
          value="Sibling (brother/sister)"
          checked={formData.intendingImmigrantRelationship === 'Sibling (brother/sister)'}
          onChange={handleInputChange}
        />
        Sibling (brother/sister)
      </label>
      </div>
    </div>
}

   












<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>



     
    </form>
    <FormFotter />

      </div>
      </div>
  
  )
}

export default Petitioner864
