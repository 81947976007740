




import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';

const HasholdCalculation = () => {
    const { formData, handleInputChange ,code } = useFormContext();
    const navigate = useNavigate()

     const HandleSubmit =(e)=>{
          e.preventDefault()
          navigate("/FederalIncomeTax")
     }

     const dependentsOptions = [
      'Please select',
      'None',
      ...Array.from({ length: 12 }, (_, i) => `${i + 1}`),
    ];

    const householdMembersOptions = ['None', ...Array.from({ length: 4 }, (_, i) => `${i + 1}`)];
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
    <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
        </span>
      </div>
      <form action=" " className='mt-14 w-11/12' onSubmit={HandleSubmit}>
      
      <h1 className='flex text-blue-600 '>
      Sponsor's Employment and Income
      </h1>
      <p>
      Enter your current, individual annual income that you are using to meet the requirements of this affidavit and indicate the total in the space provided. If you receive income from an employer and self-employment, you may list both. Please list the employer first. What counts as income
      </p>


      <div className='mt-10'>
        <p className='bg-blue-600 p-4 rounded-md text-white'>I am currently:</p>
    <div className='flex bg-blue-100 p-4 flex-col text-blue-600 gap-2'>
      <label className='flex gap-2'>
        <input
          type="radio"
          name="employmentStatus"
          value="Employed with a company"
          checked={formData.employmentStatus === 'Employed with a company'}
          onChange={handleInputChange}
        />
        Employed with a company
      </label>

      <label className='flex gap-2' >
        <input
          type="radio"
          name="employmentStatus"
          value="Self Employed"
          checked={formData.employmentStatus === 'Self Employed'}
          onChange={handleInputChange}
        />
        Self Employed
      </label>

      <label className='flex gap-2'>
        <input
          type="radio"
          name="employmentStatus"
          value="Retired"
          checked={formData.employmentStatus === 'Retired'}
          onChange={handleInputChange}
        />
        Retired
      </label>

      <label className='flex gap-2'>
        <input
          type="radio"
          name="employmentStatus"
          value="Unemployed"
          checked={formData.employmentStatus === 'Unemployed'}
          onChange={handleInputChange}
        />
        Unemployed
      </label>
      </div>
    </div>



   

      <div className='mt-4'>
        <label htmlFor="employerName" className='flex p-4 bg-blue-600 rounded-md text-white '>Name of Employer</label>
       <div className='flex p-4 bg-blue-100 '>
        <input
          type="text"
          id="employerName"
          name="employerName"
          value={formData.employerName}
          onChange={handleInputChange}
          className="w-full md:w-[300px]border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-2"
        />
    </div>
      </div>

      <div className='mt-4'>
        <label htmlFor="occupation" className='flex bg-blue-600 p-4 rounded-md text-white '>Occupation</label>
      <div>
        <div  className='flex p-4 bg-blue-100  '>
        <input
        
          type="text"
          id="occupation"
          name="occupation"
          value={formData.occupation}
          onChange={handleInputChange}
          className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
        />
        </div>
    </div>
      </div>

      <div className='mt-10'>
        
        <p className='bg-blue-600 text-white p-4 rounded-md'>Do you have a second job that provides income?</p>
        <div className='flex gap-5 bg-blue-100 p-4'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="hasSecondJob"
            value="Yes"
            checked={formData.hasSecondJob === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="hasSecondJob"
            value="No"
            checked={formData.hasSecondJob === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        </div>
      </div>
 



      <div className='mt-10'>
      <p className='bg-blue-600 text-white p-4 rounded-md'>In my second job, I am:</p>
    <div className='flex p-4 bg-blue-100  gap-2  rounded-md text-blue-600 '>
        <label className='flex gap-2'>
        <input
          type="radio"
          name="secondJobStatus"
          value="Employed with a company"
          checked={formData.secondJobStatus === 'Employed with a company'}
          onChange={handleInputChange}
          
        />
        Employed with a company
      </label>

      <label className='flex gap-2'>
        <input
          type="radio"
          name="secondJobStatus"
          value="Self-employed"
          checked={formData.secondJobStatus === 'Self-employed'}
          onChange={handleInputChange}
        />
        Self-employed
      </label>
      </div>

      
      
      <div className='mt-10'>
        <label htmlFor="employerName2" className='flex bg-blue-600 text-white p-4 rounded-md'>Name of Employer 2</label>
        <div  className='flex p-4 bg-blue-100  gap-2  rounded-md text-blue-600'>
        <input
          type="text"
          id="employerName2"
          name="employerName2"
          value={formData.employerName2}
          onChange={handleInputChange}
          className="w-full md:[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-2"
        />
        </div>
      </div>

      <div className='mt-10'>
        <label htmlFor="totalIncome" className='flex bg-blue-600 text-white p-4 rounded-md'>My total current individual income is:</label>
        <div  className='flex p-4 bg-blue-100  gap-2  rounded-md text-blue-600'>
        <input
          type="text"
          id="totalIncome"
          name="totalIncome"
          value={formData.totalIncome}
          onChange={handleInputChange}
          className="w-full md:w-300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-2"
        />
        </div>
      </div>

      <div className='mt-10'>
        <p className='bg-blue-600 text-white p-4 rounded-md'>Is any portion of your current individual income from non-taxable sources?</p>
        <div className='flex p-4 bg-blue-100  gap-2  rounded-md text-blue-600'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="nonTaxableIncome"
            value="Yes"
            checked={formData.nonTaxableIncome === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="nonTaxableIncome"
            value="No"
            checked={formData.nonTaxableIncome === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
      </div>
    </div>


    <hr  className='h-1 bg-blue-600 mt-10'/>
    <h1 className='flex text-blue-600 mt-4 font-semibold text-xl'>Sponsor's Household Size</h1>
    <p>Important Note: In this section, be careful not to count any member of your household more than once. If you have already listed a family member on this form, do not count them again here.

</p>
     

<div className='mt-10'>
      <p className='bg-blue-600 text-white p-4 rounded-md'>
        Is any portion of your current individual income from non-taxable sources?
      </p>
      <div className='flex p-4 bg-blue-100 gap-2 rounded-md text-blue-600'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="nonTaxableIncome"
            value="Yes"
            checked={formData.nonTaxableIncome === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="nonTaxableIncome"
            value="No"
            checked={formData.nonTaxableIncome === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>




    <div  className='flex flex-col mt-10'>
      <label htmlFor="country"  className=" bg-blue-500  flex p-4 text-white ">How many dependent children do you have</label>
      <div className='flex bg-blue-100 p-4'>
      <select
        className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
        id="country"
        name="country"
        value={formData.dependentChildrenCountOptions}
        onChange={handleInputChange}
      >
        <option value="">Select a Country</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
      </div>
      </div>


      <div className='flex flex-col mt-10'>
      <label htmlFor="additionaldependents" className="bg-blue-500 flex p-4 text-white">
        How many additional dependents do you have?
      </label>
      <div className='flex bg-blue-100 p-4'>
        <select
          className="w-full md:[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
          id="additionaldependents"
          name="additionaldependents"
          value={formData.additionaldependents}
          onChange={handleInputChange}
        >
          {dependentsOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>



      <div className='mt-4'>
      <p className='bg-blue-600 text-white p-4 rounded-md'>
        Have you sponsored any other persons on Form I-864 or Form I-864EZ who are now lawful permanent residents?
      </p>
      <div className='flex p-4 bg-blue-100 gap-2 rounded-md text-blue-600'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="sponsoredOtherPersons"
            value="Yes"
            checked={formData.sponsoredOtherPersons === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="sponsoredOtherPersons"
            value="No"
            checked={formData.sponsoredOtherPersons === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>


    <div className='flex flex-col mt-10'>
      <label htmlFor="householdMembersIncomeCombination" className="bg-blue-500 flex p-4 text-white">
        Select the number of household members who will combine their income with yours:
      </label>
      <div className='flex bg-blue-100 p-4'>
        <select
          className="w-full md-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
          id="householdMembersIncomeCombination"
          name="householdMembersIncomeCombination"
          value={formData.householdMembersIncomeCombination}
          onChange={handleInputChange}
        >
          {householdMembersOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </div>

   <hr  className="h-1 bg-blue-600 mt-10 w-full"/>

   <h1 className='text-blue-600 text-xl font-semibold mt-10'>Option B: Use A Joint Sponsor</h1>
   <p className='text-sm '>You also have the option of using a joint sponsor. A joint sponsor can be someone who is not related to you and doesn't live with you. Generally, you do not need a joint sponsor if household exceeds the income requirement. A joint sponsor may be your only option if your income is not meeting the requirement. Explain more 
      Will you be using a joint sponsor?</p>

    <div className='mt-10'>
      <p className='bg-blue-600 text-white p-4 rounded-md'>
        Will you be using a joint sponsor?
      </p>
      <div className='flex p-4 bg-blue-100 gap-2 rounded-md text-blue-600'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="usingJointSponsor"
            value="Yes"
            checked={formData.usingJointSponsor === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="usingJointSponsor"
            value="No"
            checked={formData.usingJointSponsor === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>
   

















    <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>


     
    </form>
    <FormFotter />

      </div>
      </div>
  
  )
}

export default HasholdCalculation