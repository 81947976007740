import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const ApplicationFamilyInfo2 = () => {
  const navigate = useNavigate()
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()


  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/MoralCharacter")
  }
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's Parents
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 8 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            Enter information about your parents. Each parent may be a
            biological, adoptive or step parent. This includes same-sex parents.
            If you made a reasonable effort to obtain the information and do not
            know the answer to a question, "Unknown" is an acceptable answer.
          </span>
        </div>
        <form action='' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          {/* parent 1 section */}

          <h1 className='flex text-blue-600 font-semibold text-xl'>
            Parent #1 (Mother)
          </h1>
          <h1 className='flex text-blue-600 font-semibold text-xl mt-4'>
            Parent's Current Legal Name
          </h1>

          <div className='mt-4 grid gap-4'>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Given Name (First Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='motherGivenName'
                  value={formData.motherGivenName ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>Middle Name</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='motherMiddleName'
                  value={formData.motherMiddleName ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Family Name (Last Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='motherFamilyName'
                  value={formData.motherFamilyName ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Did your parent have a different name at birth?
              </label>
              <span className='flex'>
                <input
                  id='parentsDifferentBirthName'
                  type='radio'
                  name='parentsDifferentBirthName'
                  value='Yes'
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='differentBirthName'>Yes</label>
              </span>
              <span className='flex'>
                <input
                  id='parentsDifferentBirthName'
                  type='radio'
                  name='parentsDifferentBirthName'
                  value='No'
                  onChange={handleInputChange}
                  className='ml-9'
                />
                <label htmlFor='differentBirthName'>No</label>
              </span>
            </div>
            {formData?.parentsDifferentBirthName === "Yes" ? (
              <>
                <div className='mt-4 grid gap-4'>
                  <h1 className='flex text-blue-600 font-semibold text-xl'>
                  Parent's Name at Birth
                  </h1>

                  <div className='flex items-center ml-4'>
                    <label className='w-[300px] text-blue-500'>
                      Given Name (First Name)
                    </label>
                    <span className='flex text-blue-600'>
                      <input
                        type='text'
                        name='parentsFirstNameAtBirth'
                        value={formData.parentsFirstNameAtBirth}
                        onChange={handleInputChange}
                        className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                      />
                    </span>
                  </div>
                  <div className='flex items-center ml-4'>
                    <label className='text-blue-500 w-[300px]'>
                      Middle Name (if applicable)
                    </label>
                    <span className='flex text-blue-600'>
                      <input
                        type='text'
                        name='parentsMiddleNameAtBirth'
                        value={formData.parentsMiddleNameAtBirth}
                        onChange={handleInputChange}
                        className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                      />
                    </span>
                  </div>
                  <div className='flex items-center ml-4'>
                    <label className='text-blue-500 w-[300px]'>
                      Family Name (Last Name)
                    </label>
                    <span className='flex text-blue-600'>
                      <input
                        type='text'
                        name='parentsLastNameAtBirth'
                        value={formData.parentsLastNameAtBirth}
                        onChange={handleInputChange}
                        className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                      />
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className='flex ml-4'>
              <label className='w-[300px] text-blue-500'>Sex</label>
              <div>
                <span className='flex'>
                  <input
                    id='motherGender'
                    type='radio'
                    name='motherGender'
                    value={formData.motherGender ?? ""}
                    onChange={handleInputChange}
                    className=''
                  />
                  <label htmlFor='IsUsArmedForcesMember'>Male</label>
                </span>
                <span className='flex'>
                  <input
                    id='motherGender'
                    type='radio'
                    name='motherGender'
                    value={formData.motherGender ?? ""}
                    onChange={handleInputChange}
                    className=''
                  />
                  <label htmlFor='IsUsArmedForcesMember'>Female</label>
                </span>
              </div>
            </div>

            {formData.dontKnowMotherDOB ? (<>
            
            </>): <>
            
            <div className='flex ml-4'>
              <label className='w-[300px] text-blue-500'>Date of Birth</label>
              <span className='flex'>
                <input
                  id='motherDOB'
                  type='date'
                  name='motherDOB'
                  value={formData.motherDOB}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>

            </>}

            

            <div className='ml-4'>
              <input
                name="dontKnowMotherDOB"
                type='checkbox'
                value={formData.dontKnowMotherDOB ?? ""}
                onChange={handleInputChange}
              />
              <label htmlFor='dontKnowMotherDOB' className='text-blue-500 ml-4'>
                I don't know the date of birth
              </label>
            </div>
          </div>

          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              City and Country of Birth
            </h1>

            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>City or Town</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='motherCityOrTown'
                  value={formData.motherCityOrTown ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Country</label>
              <span className='flex text-blue-600'>
                <select
                  id='motherCountryOfBirth'
                  name='motherCountryOfBirth'
                  value={formData.motherCountryOfBirth ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          </div>

          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              City and Country of Parent 1's Residence
            </h1>

            <div className='flex ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Is this parent deceased?
              </label>
              <div>
                <span className='flex'>
                  <input
                    id='isMotherDeceased'
                    type='radio'
                    name='isMotherDeceased'
                    value="Yes"
                    onChange={handleInputChange}
                    className=''
                  />
                  <label htmlFor='isMotherDeceased'>Yes</label>
                </span>
                <span className='flex'>
                  <input
                    id='isMotherDeceased'
                    type='radio'
                    name='isMotherDeceased'
                    value="No"
                    onChange={handleInputChange}
                    className=''
                  />
                  <label htmlFor='isMotherDeceased'>No</label>
                </span>
              </div>
            </div>

                  {formData.isMotherDeceased === "No" ? <>


                  <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>City or Town</label>
              <span className='flex text-blue-600'>
                <input
                  id='cityOrTownOfMother'
                  name='cityOrTownOfMother'
                  type='text'
                  value={formData.cityOrTownOfMother}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
                  
                  <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Country</label>
              <span className='flex text-blue-600'>
                <select
                  id='countryOfMother'
                  name='countryOfMother'
                  value={formData.countryOfMother}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
                  
                  </>:<></>}


          </div>

          {/* Parent #2 (Father) Information */}

          <hr className='h-1 bg-blue-500 text-white mt-4 mb-4' />

          <div className='mt-4 grid gap-4'>
            <h1 className='flex text-blue-600 font-semibold text-xl'>
              Parent #2 (Father)
            </h1>
            <h1 className='flex text-blue-600 font-semibold text-xl mt-4'>
              Parent's Current Legal Name
            </h1>

            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Given Name (First Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='fatherGivenFirstName'
                  placeholder=''
                  value={formData.fatherGivenFirstName ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px] text-wrap'>
                Middle Name
              </label>
              <span className='flex'>
                <input
                  type='text'
                  name='fatherMiddleName'
                  placeholder=''
                  value={formData.fatherMiddleName ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
            <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>
                Family Name (Last Name)
              </label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='fatherLastName'
                  value={formData.fatherLastName ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>
                Did your parent have a different name at birth?
              </label>
              <span className='flex'>
                <input
                  id='fatherDifferentBirthName'
                  type='radio'
                  name='fatherDifferentBirthName'
                  value="Yes"
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='fatherDifferentBirthName'>Yes</label>
              </span>
              <span className='flex'>
                <input
                  id='fatherDifferentBirthName'
                  type='radio'
                  name='fatherDifferentBirthName'
                  value="No"
                  onChange={handleInputChange}
                  className='ml-9'
                />
                <label htmlFor='fatherDifferentBirthName'>No</label>
              </span>
            </div>

            {formData?.fatherDifferentBirthName === "Yes" ? (
              <>
                <div className='mt-4 grid gap-4'>
                  <h1 className='flex text-blue-600 font-semibold text-xl'>
                  Parent's Name at Birth
                  </h1>

                  <div className='flex items-center ml-4'>
                    <label className='w-[300px] text-blue-500'>
                      Given Name (First Name)
                    </label>
                    <span className='flex text-blue-600'>
                      <input
                        type='text'
                        name='fatherFirstNameAtBirth'
                        value={formData.fatherFirstNameAtBirth}
                        onChange={handleInputChange}
                        className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                      />
                    </span>
                  </div>
                  <div className='flex items-center ml-4'>
                    <label className='text-blue-500 w-[300px]'>
                      Middle Name (if applicable)
                    </label>
                    <span className='flex text-blue-600'>
                      <input
                        type='text'
                        name='fatherMiddleNameAtBirth'
                        value={formData.fatherMiddleNameAtBirth}
                        onChange={handleInputChange}
                        className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                      />
                    </span>
                  </div>
                  <div className='flex items-center ml-4'>
                    <label className='text-blue-500 w-[300px]'>
                      Family Name (Last Name)
                    </label>
                    <span className='flex text-blue-600'>
                      <input
                        type='text'
                        name='fatherLastNameAtBirth'
                        value={formData.fatherLastNameAtBirth}
                        onChange={handleInputChange}
                        className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                      />
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>Sex</label>
              <div>
                <span className='flex'>
                  <input
                    id='fatherGender'
                    type='radio'
                    name='fatherGender'
                    value={formData.fatherGender ?? ""}
                    onChange={handleInputChange}
                    className=''
                  />
                  <label htmlFor='fatherGender'>Male</label>
                </span>
                <span className='flex'>
                  <input
                    id='fatherGender'
                    type='radio'
                    name='fatherGender'
                    value={formData.fatherGender ?? ""}
                    onChange={handleInputChange}
                    className=''
                  />
                  <label htmlFor='fatherGender'>Female</label>
                </span>
              </div>
            </div>

            

            {formData.dontKnowFatherDOB ? (<>
            
            </>): <>
            
            <div className='flex ml-4'>
              <label className='w-[300px] text-blue-500'>Date of Birth</label>
              <span className='flex'>
                <input
                  id='fatherDOB'
                  type='date'
                  name='fatherDOB'
                  value={formData.fatherDOB ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>

            </>}


            <div className='ml-4'>
              <input
                name= 'dontKnowFatherDOB'
                type='checkbox'
                value={formData.dontKnowFatherDOB ?? ""}
                onChange={handleInputChange}
              />
              <label htmlFor='' className='text-blue-500 ml-4'>
                I don't know the date of birth
              </label>
            </div>

            <h1 className='flex text-blue-600 font-semibold text-xl mt-4'>
              City and Country of Birth
            </h1>

            <div className='flex items-center ml-4'>
              <label className='w-[300px] text-blue-500'>City or Town</label>
              <span className='flex text-blue-600'>
                <input
                  type='text'
                  name='fatherCityOrTown'
                  value={formData.fatherCityOrTown ?? ""}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
          </div>

          <div className='flex items-center ml-4'>
            <label className='text-blue-500 w-[300px]'>Country</label>
            <span className='flex text-blue-600'>
              <select
                id='fatherCountryOfBirth'
                name='fatherCountryOfBirth'
                value={formData.fatherCountryOfBirth ?? ""}
                onChange={handleInputChange}
                className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-4'
              >
                <option value=''>Please select...</option>
                {code.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </span>
          </div>

          <h1 className='flex text-blue-600 font-semibold text-xl mt-4 mb-4'>
            City and Country of Parent 2's Residence
          </h1>
          <div className='flex ml-4 mb-4'>
            <label className='text-blue-500 w-[300px]'>
              Is this parent deceased?
            </label>
            <div>
              <span className='flex'>
                <input
                  id='isFatherDeceased'
                  type='radio'
                  name='isFatherDeceased'
                  value="Yes"
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='isFatherDeceased'>Yes</label>
              </span>
              <span className='flex'>
                <input
                  id='isFatherDeceased'
                  type='radio'
                  name='isFatherDeceased'
                  value="No"
                  onChange={handleInputChange}
                  className=''
                />
                <label htmlFor='isFatherDeceased'>No</label>
              </span>
            </div>
          </div>

          {formData.isFatherDeceased === "No" ? <>


                  <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>City or Town</label>
              <span className='flex text-blue-600'>
                <input
                  id='cityOrTownOfFather'
                  name='cityOrTownOfFather'
                  type='text'
                  value={formData.cityOrTownOfFather}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                />
              </span>
            </div>
                  
                  <div className='flex items-center ml-4'>
              <label className='text-blue-500 w-[300px]'>Country</label>
              <span className='flex text-blue-600'>
                <select
                  id='countryOfFather'
                  name='countryOfFather'
                  value={formData.countryOfFather}
                  onChange={handleInputChange}
                  className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-4'
                >
                  <option value=''>Please select...</option>
                  {code.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </span>
            </div>
                  
                  </>:<></>}

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default ApplicationFamilyInfo2
