import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContext751 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContext751);
}

// Define the initial state
const initialState = {
    
    conditionalResidenceType: '',
    filingOption: '',
    filingOption2: '',
    greenCardExpirationDate: '',
    acknowledgeAttorney: false,
    livingOverseas: '',
  removalProceedings: '',
  acknowledgeAttorney2:"",
  feePaid: '',
  arrestsOrCrimes: '',
  acknowledgeAttorney3:false,



  firstName: '',
  middleNames: '',
  lastName: '',
  usedAnotherName: '',

  residedAtOtherAddress: '',
  differentMailingAddress: '',
  moveDate: '',

  ethnicity: '',
  race: '',
  countryOfBirth: '',
  dateOfBirth: '',


  countryOfCitizenship: '',
  alienRegistrationNumber: '',
  socialSecurityNumber: '',
  uscisOnlineAccountNumber: '',

  daytimePhoneNumber: '',
  mobilePhoneNumber: '',
  email: '',
  spouseGivenName: '',
  spouseMiddleName: '',
  spouseFamilyName: '',
  spouseDateOfBirth: '',
  spouseANumber: '',
  spouseSSN: '',


  haveChildren: '',


  requestingAccommodation: "",
  forMyDisabilities: "",
  forSpouseDisabilities: "",
  forChildDisabilities: "",
  deafOrHardOfHearing: false,
  blindOrSightImpaired: false,
  otherDisabilities: false,
  usedInterpreter: 'No',



  interpreterUsed: false,
  interpreterLanguage: '',
  interpreterGivenName: '',
  interpreterFamilyName: '',
  interpreterBusinessOrOrganizationName: '',
  interpreterStreetNumberAndName: '',
  interpreterHasApartmentSuiteFloor: false,
  interpreterApartmentSuiteFloor: '',
  interpreterCityOrTown: '',
  interpreterCountry: '',
  interpreterStateOrTerritory: '',
  interpreterZipCode: '',

  
  };

// Create a provider component to manage the state
export function FormProviderFor751({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
 // Handle change for all form inputs
const handleInputChange = (e) => {
  const { name, value, type, checked } = e.target;

  setFormData((prevData) => {
    switch (type) {
      case 'radio':
        return {
          ...prevData,
          [name]: value,
        };
      case 'date':
        return {
          ...prevData,
          [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
        };
      case 'text':
      case 'number':
        return {
          ...prevData,
          [name]: value,
        };
      case 'checkbox':
        if (name.startsWith('race.')) {
          // If it's a race checkbox, update the nested state
          const raceField = name.split('.')[1];
          return {
            ...prevData,
            race: {
              ...prevData.race,
              [raceField]: checked,
            },
          };
        }
        return {
          ...prevData,
          [name]: checked,
        };
      case 'select-one':
        return {
          ...prevData,
          [name]: value,
        };
      default:
        return {
          ...prevData,
          [name]: value,
        };
    }
  });
};


const mapFormFields = () => {
  let mapping = {};

  mapping["form1[0].#subform[0].P1_Line2a_FamilyName[0]"] = "RandomFamilyName2";
  mapping["form1[0].#subform[0].P1_Line2b_GivenName[0]"] = "RandomGivenName2";
  mapping["form1[0].#subform[0].P1_Line2c_MiddleName[0]"] = "RandomMiddleName2";
  mapping["form1[0].#subform[0].P1_Line3a_FamilyName[0]"] = "RandomFamilyName3";
  mapping["form1[0].#subform[0].P1_Line3b_GivenName[0]"] = "RandomGivenName3";
  mapping["form1[0].#subform[0].P1_Line3c_MiddleName[0]"] = "RandomMiddleName3";
  mapping["form1[0].#subform[0].P1_Line4_DateOfBirth[0]"] = "2023-01-01";
  mapping["form1[0].#subform[0].P1_Line11_DateOfMarriage[0]"] = "2023-02-14";
  mapping["form1[0].#subform[0].P1_Line12_PlaceOfMarriage[0]"] = "RandomMarriagePlace";
  mapping["form1[0].#subform[0].P1_Line13_DateMarriageEnded[0]"] = "2023-03-31";
  mapping["form1[0].#subform[0].P1_Line14_CRExpiresOn[0]"] = "2023-12-31";
  mapping["form1[0].#subform[0].P1_Line7_AlienNumber[0]"] = "22222";
  mapping["form1[0].#subform[0].Part1_Line10_MaritalStatus[0]"] = true;
  mapping["form1[0].#subform[0].Part1_Line10_MaritalStatus[1]"] = false;
  mapping["form1[0].#subform[0].Part1_Line10_MaritalStatus[2]"] = true;
  mapping["form1[0].#subform[0].Part1_Line10_MaritalStatus[3]"] = false;
  mapping["form1[0].#subform[0].P1_Line9_AcctIdentifier[0]"] = "RandomIdent";
  mapping["form1[0].#subform[0].P1_Line6_CountryOfCitizenship[0]"] = "RandomCitizenship";
  mapping["form1[0].#subform[0].P1_Line8_SSN[0]"] = "Random22";
  mapping["form1[0].#subform[0].P1_Line5_CountryOfBirth[0]"] = "RandomCountry";
  mapping["form1[0].#subform[1].Line17a_InCareofName[0]"] = "222342222";
mapping["form1[0].#subform[1].Line17d_City_Town[0]"] = "Ran1890";
mapping["form1[0].#subform[1].Line17b_Street_Number_Name[0]"] = "Ra9012347890";
mapping["form1[0].#subform[1].Line17c_Apt_Ste_Flr_Number[0]"] = "Apt123";
mapping["form1[0].#subform[1].Line17c_Unit[0]"] = true;
mapping["form1[0].#subform[1].Pt1Line15f_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[1].Pt1Line15e_State[0]"] = "RandomState";
mapping["form1[0].#subform[1].Line17c_Unit[1]"] = false;
mapping["form1[0].#subform[1].Line17c_Unit[2]"] = true;
mapping["form1[0].#subform[1].Line16_Checkbox[0]"] = true;
mapping["form1[0].#subform[1].Line16_Checkbox[1]"] = false;
mapping["form1[0].#subform[1].Line17_Checkbox[0]"] = true;
mapping["form1[0].#subform[1].Line18_Checkbox[0]"] = false;
mapping["form1[0].#subform[1].Line19_Checkbox[0]"] = true;
mapping["form1[0].#subform[1].Line19_Checkbox[1]"] = false;
mapping["form1[0].#subform[1].Line18_Checkbox[1]"] = true;
mapping["form1[0].#subform[1].Line17_Checkbox[1]"] = false;
mapping["form1[0].#subform[1].Pt1Line17_InCareofName[0]"] = "RandomInCareofName12345678901234567890";
mapping["form1[0].#subform[1].Pt1Line17_CityOrTown[0]"] = "RandomCityTown12345678901234567890";
mapping["form1[0].#subform[1].Pt1Line17_StreetNumberName[0]"] = "RandomStreetNumberName1234567890";
mapping["form1[0].#subform[1].Pt1Line17_AptSteFlrNumber[0]"] = "Apt456";
mapping["form1[0].#subform[1].Pt1Line17_ZipCode[0]"] = "67890";
mapping["form1[0].#subform[1].Pt1Line17_State[0]"] = "RandomState";
mapping["form1[0].#subform[2].NextField[0]"] = "NextValue";
mapping["form1[0].#subform[2].AnotherField[0]"] = "AnotherValue";
mapping["form1[0].#subform[2].AdditionalField[0]"] = "AdditionalValue"
mapping["form1[0].#subform[N].FieldN[0]"] = "ValueN";
mapping["form1[0].#subform[1].Pt1Line17_Unit[0]"] = false;
mapping["form1[0].#subform[1].Pt1Line17_Unit[1]"] = true;
mapping["form1[0].#subform[1].Pt1Line17_Unit[2]"] = true;
mapping["form1[0].#subform[1].Line20_Checkbox[0]"] = false;
mapping["form1[0].#subform[1].Line20_Checkbox[1]"] = true;
mapping["form1[0].#subform[1].Line21_Checkbox[0]"] = true;
mapping["form1[0].#subform[1].Line21_Checkbox[1]"] = false;
mapping["form1[0].#subform[1].Line22_Checkbox[0]"] = false;
mapping["form1[0].#subform[1].Line22_Checkbox[1]"] = true;
mapping["form1[0].#subform[1].P3_Line8_HeightFeet[0]"] = "5";
mapping["form1[0].#subform[1].P3_Line8_HeightInches[0]"] = "8";
mapping["form1[0].#subform[1].P3_Line9_HeightInches1[0]"] = "1";
mapping["form1[0].#subform[1].P3_Line9_HeightInches2[0]"] = "2";
mapping["form1[0].#subform[1].P3_Line9_HeightInches3[0]"] = "3";
mapping["form1[0].#subform[1].P3_checkbox6[0]"] = true;
mapping["form1[0].#subform[1].P3_checkbox6[1]"] = false;
mapping["form1[0].#subform[1].P3_checkbox7_Hawaiian[0]"] = true;
mapping["form1[0].#subform[1].P3_checkbox7_Indian[0]"] = false;
mapping["form1[0].#subform[1].P3_checkbox7_White[0]"] = true;
mapping["form1[0].#subform[1].P3_checkbox7_Asian[0]"] = false;
mapping["form1[0].#subform[1].P3_checkbox7_Black[0]"] = true;
mapping["form1[0].#subform[1].P3_checkbox10[0]"] = false;
mapping["form1[0].#subform[1].P3_checkbox10[1]"] = true;
mapping["form1[0].#subform[1].P3_checkbox10[2]"] = false;
mapping["form1[0].#subform[1].P3_checkbox10[3]"] = true;
mapping["form1[0].#subform[1].P3_checkbox10[4]"] = false;
mapping["form1[0].#subform[1].P3_checkbox10[5]"] = true;
mapping["form1[0].#subform[1].P3_checkbox10[6]"] = false;
mapping["form1[0].#subform[1].P3_checkbox10[7]"] = true;
mapping["form1[0].#subform[1].P3_checkbox10[8]"] = false;
mapping["form1[0].#subform[1].P3_checkbox11[0]"] = true;
mapping["form1[0].#subform[1].P3_checkbox11[1]"] = false;
mapping["form1[0].#subform[1].P3_checkbox11[2]"] = true;
mapping["form1[0].#subform[1].P3_checkbox11[3]"] = false;
mapping["form1[0].#subform[1].P3_checkbox11[4]"] = true;
mapping["form1[0].#subform[1].P3_checkbox11[5]"] = false;
mapping["form1[0].#subform[1].P3_checkbox11[6]"] = true;
mapping["form1[0].#subform[1].P3_checkbox11[7]"] = false;
mapping["form1[0].#subform[1].P3_checkbox11[8]"] = true;
mapping["form1[0].#subform[2].Pt3Line1[0]"] = true;
mapping["form1[0].#subform[2].Pt3Line1[1]"] = false;
mapping["form1[0].#subform[2].Pt3Line1c[0]"] = true;
mapping["form1[0].#subform[2].Pt3Line1d[0]"] = false;
mapping["form1[0].#subform[2].Pt3Line1e[0]"] = true;
mapping["form1[0].#subform[2].Pt3Line1f[0]"] = false;
mapping["form1[0].#subform[2].Pt3Line1g[0]"] = true;
mapping["form1[0].#subform[2].Part4_Relationship[0]"] = false;
mapping["form1[0].#subform[2].Part4_Relationship[1]"] = true;
mapping["form1[0].#subform[2].Pt4Line2b_GivenName2[0]"] = "RandomGivenName2";
mapping["form1[0].#subform[2].Pt4Line2a_FamilyName2[0]"] = "RandomFamilyName2";
mapping["form1[0].#subform[2].Pt4Line2c_MiddleName2[0]"] = "RandomMiddleName2";
mapping["form1[0].#subform[2].Line3_DateOfBirth[0]"] = "RandomDOB";
mapping["form1[0].#subform[2].Line4_SSN[0]"] = "123456789";
mapping["form1[0].#subform[2].Line5_AlienNumber[0]"] = "987654321";
mapping["form1[0].#subform[2].Pt4Line6_CityOrTown[0]"] = "RandomCityTown12345678901234567890";
mapping["form1[0].#subform[2].Pt4Line6_StreetNumberName[0]"] = "RandomStreet123456789012345678901234567890";
mapping["form1[0].#subform[2].Pt4Line6_AptSteFlrNumber[0]"] = "Apt123";
mapping["form1[0].#subform[2].Pt4Line6_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[2].Pt4Line6_State[0]"] = "RandomState";
mapping["form1[0].#subform[2].Pt4Line6_PostalCode[0]"] = "987654321";
mapping["form1[0].#subform[2].Pt4Line6_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[2].Pt4Line6_Unit[0]"] = true;
mapping["form1[0].#subform[2].Pt4Line6_Unit[1]"] = false;
mapping["form1[0].#subform[2].Pt4Line6_Unit[2]"] = true;
mapping["form1[0].#subform[2].Pt4Line6_Province[0]"] = "RandomProvince12345678901234567890";
// ... (continue with other mappings)

mapping["form1[0].#subform[2].Line1b_GivenName3[0]"] = "RandomGivenName3";
mapping["form1[0].#subform[2].Line1a_FamilyName3[0]"] = "RandomFamilyName3";
mapping["form1[0].#subform[2].Line1c_MiddleName3[0]"] = "RandomMiddleName3";
mapping["form1[0].#subform[2].Line2_DateOfBirth2[0]"] = "RandomDOB2";
mapping["form1[0].#subform[2].Line3_AlienNumber[0]"] = "987654321";
mapping["form1[0].#subform[2].Part5Line5[0]"] = true;
mapping["form1[0].#subform[2].Part5Line6[0]"] = false;
mapping["form1[0].#subform[2].Part5Line6[1]"] = true;
mapping["form1[0].#subform[2].Part5Line5[1]"] = false;
mapping["form1[0].#subform[3].Pt5Line6_StreetNumberName[0]"] = "RandomStreet456789012345678901234567890";
mapping["form1[0].#subform[3].Pt5Line6_AptSteFlrNumber[0]"] = "Apt456";
mapping["form1[0].#subform[3].Pt5Line6_CityOrTown[0]"] = "RandomCityTown12345678901234567890";
mapping["form1[0].#subform[3].Pt5Line6_Unit[0]"] = true;
mapping["form1[0].#subform[3].Pt5Line6_Unit[1]"] = false;
mapping["form1[0].#subform[3].Pt5Line6_Unit[2]"] = true;
mapping["form1[0].#subform[3].Pt5Line6_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[3].Pt5Line6_State[0]"] = "RandomState2";
mapping["form1[0].#subform[3].Pt5Line6_PostalCode[0]"] = "123456789";
mapping["form1[0].#subform[3].Pt5Line6_Country[0]"] = "RandomCountry2";
mapping["form1[0].#subform[3].Pt5Line6_Province[0]"] = "RandomProvince2";
mapping["form1[0].#subform[3].Line13b_GivenName[0]"] = "RandomGivenName4";
mapping["form1[0].#subform[3].Line13a_FamilyName[0]"] = "RandomFamilyName4";
mapping["form1[0].#subform[3].Line13c_MiddleName[0]"] = "RandomMiddleName4";
mapping["form1[0].#subform[3].Line14_DateOfBirth[0]"] = "RandomDOB4";
mapping["form1[0].#subform[3].Line15_AlienNumber[0]"] = "123456789";
mapping["form1[0].#subform[3].Part5Line11[0]"] = true;
mapping["form1[0].#subform[3].Part5Line12[0]"] = false;
mapping["form1[0].#subform[3].Part5Line12[1]"] = true;
mapping["form1[0].#subform[3].Part5Line11[1]"] = false;
mapping["form1[0].#subform[3].Pt5Line12_StreetNumberName[0]"] = "RandomStreet56789012345678901234567890";
mapping["form1[0].#subform[3].Pt5Line12_AptSteFlrNumber[0]"] = "Apt567";
mapping["form1[0].#subform[3].Pt5Line12_CityOrTown[0]"] = "RandomCityTown23456789012345678901";
mapping["form1[0].#subform[3].Pt5Line12_Unit[0]"] = true;
// ... (continue with other mappings)

mapping["form1[0].#subform[3].Pt5Line12_Unit[1]"] = false;
mapping["form1[0].#subform[3].Pt5Line12_Unit[2]"] = true;
mapping["form1[0].#subform[3].Pt5Line12_ZipCode[0]"] = "98765";
mapping["form1[0].#subform[3].Pt5Line12_State[0]"] = "RandomState3";
mapping["form1[0].#subform[3].Pt5Line12_PostalCode[0]"] = "543216789";
mapping["form1[0].#subform[3].Pt5Line12_Country[0]"] = "RandomCountry3";
mapping["form1[0].#subform[3].Pt5Line12_Province[0]"] = "RandomProvince3";
mapping["form1[0].#subform[3].Line13b_GivenName[1]"] = "RandomGivenName5";
mapping["form1[0].#subform[3].Line13a_FamilyName[1]"] = "RandomFamilyName5";
mapping["form1[0].#subform[3].Line13c_MiddleName[1]"] = "RandomMiddleName5";
mapping["form1[0].#subform[3].Line14_DateOfBirth[1]"] = "RandomDOB5";
mapping["form1[0].#subform[3].Line15_AlienNumber[1]"] = "987654321";
mapping["form1[0].#subform[3].Part5Line17[0]"] = true;
mapping["form1[0].#subform[3].Part5Line18[0]"] = false;
mapping["form1[0].#subform[3].Part5Line18[1]"] = true;
mapping["form1[0].#subform[3].Part5Line17[1]"] = false;
mapping["form1[0].#subform[3].Pt5Line18_StreetNumberName[0]"] = "RandomStreet7890123456789012345678901234567890";
mapping["form1[0].#subform[3].Pt5Line18_AptSteFlrNumber[0]"] = "Apt789";
mapping["form1[0].#subform[3].Pt5Line18_CityOrTown[0]"] = "RandomCityTown56789012345678901234";
mapping["form1[0].#subform[3].Pt5Line18_Unit[0]"] = true;
mapping["form1[0].#subform[3].Pt5Line18_Unit[1]"] = false;
mapping["form1[0].#subform[3].Pt5Line18_Unit[2]"] = true;
mapping["form1[0].#subform[3].Pt5Line18_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[3].Pt5Line18_State[0]"] = "RandomState4";
mapping["form1[0].#subform[3].Pt5Line18_PostalCode[0]"] = "123456789";
mapping["form1[0].#subform[3].Pt5Line18_Country[0]"] = "RandomCountry4";
mapping["form1[0].#subform[3].Pt5Line18_Province[0]"] = "RandomProvince4";
mapping["form1[0].#subform[3].Line13b_GivenName[2]"] = "RandomGivenName6";
// ... (continue with other mappings)

mapping["form1[0].#subform[4].Line13a_FamilyName[3]"] = "RandomFamilyName7";
mapping["form1[0].#subform[4].Line13c_MiddleName[3]"] = "RandomMiddleName7";
mapping["form1[0].#subform[4].Line14_DateOfBirth[3]"] = "RandomDOB7";
mapping["form1[0].#subform[4].Line15_AlienNumber[3]"] = "876543210";
mapping["form1[0].#subform[4].Part5Line29[0]"] = true;
mapping["form1[0].#subform[4].Part5Line30[0]"] = false;
mapping["form1[0].#subform[4].Part5Line30[1]"] = true;
mapping["form1[0].#subform[4].Part5Line29[1]"] = false;
mapping["form1[0].#subform[4].Pt5Line30_StreetNumberName[0]"] = "RandomStreet2345678901234567890123456789012";
mapping["form1[0].#subform[4].Pt5Line30_AptSteFlrNumber[0]"] = "Apt456";
mapping["form1[0].#subform[4].Pt5Line30_CityOrTown[0]"] = "RandomCity78901234567890123456";
mapping["form1[0].#subform[4].Pt5Line30_Unit[0]"] = true;
mapping["form1[0].#subform[4].Pt5Line30_Unit[1]"] = false;
mapping["form1[0].#subform[4].Pt5Line30_Unit[2]"] = true;
mapping["form1[0].#subform[4].Pt5Line30_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[4].Pt5Line30_State[0]"] = "RandomState6";
mapping["form1[0].#subform[4].Pt5Line30_PostalCode[0]"] = "987654321";
mapping["form1[0].#subform[4].Pt5Line30_Country[0]"] = "RandomCountry6";
mapping["form1[0].#subform[4].Pt5Line30_Province[0]"] = "RandomProvince6";
mapping["form1[0].#subform[4].Part6Line1[0]"] = true;
mapping["form1[0].#subform[4].Part6Line2[0]"] = false;
mapping["form1[0].#subform[4].Part6Line3[0]"] = true;
mapping["form1[0].#subform[4].Part6Line3[1]"] = false;
mapping["form1[0].#subform[4].Part6Line2[1]"] = true;
mapping["form1[0].#subform[4].Part6Line1[1]"] = false;
mapping["form1[0].#subform[4].Pt6Line4_DeafOrHardOfHearing[0]"] = "RandomDeafOrHardOfHearing6";
mapping["form1[0].#subform[4].Pt6Line4a_chbx[0]"] = true;
mapping["form1[0].#subform[4].Pt6Line4b_chbx[0]"] = false;
mapping["form1[0].#subform[4].Pt6Line4_BlindOrSightImpaired[0]"] = "RandomBlindOrSightImpaired6";
mapping["form1[0].#subform[4].Pt6Line4_AccommodationRequested[0]"] = "RandomAccommodationRequested6";
// ... (continue with other mappings)

mapping["form1[0].#subform[7].Pt9Line3_Unit[2]"] = true;
mapping["form1[0].#subform[8].P7_Line3c_CityTown[0]"] = "RandomCity34567890";
mapping["form1[0].#subform[8].Pt9Line3_StreetNumberName[0]"] = "RandomStreet890123456789012345678901234567890";
mapping["form1[0].#subform[8].Pt10Line3_AptSteFlrNumber[0]"] = "Apt456";
mapping["form1[0].#subform[8].P7_Line3f_Province[0]"] = "RandomProvince89012345";
mapping["form1[0].#subform[8].P7_Line3e_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[8].P7_Line3d_State[0]"] = "RandomState8";
mapping["form1[0].#subform[8].P7_Line3h_Country[0]"] = "RandomCountry8";
mapping["form1[0].#subform[8].P7_Line3g_PostalCode[0]"] = "987654321";
mapping["form1[0].#subform[8].Pt10Line3_Unit[0]"] = true;
mapping["form1[0].#subform[8].Pt10Line3_Unit[1]"] = false;
mapping["form1[0].#subform[8].Pt10Line3_Unit[2]"] = true;
mapping["form1[0].#subform[8].P7_checkbox7[0]"] = true;
mapping["form1[0].#subform[8].P7_checkbox7[1]"] = false;
mapping["form1[0].#subform[8].Pt10Item7b_Extends[0]"] = true;
mapping["form1[0].#subform[8].Pt10Item7b_NotExtend[0]"] = false;
mapping["form1[0].#subform[9].P7_Line8a_SignatureofPreparer[0]"] = "R";
mapping["form1[0].#subform[9].P7_Line8b_DateofSignature[0]"] = "RandomDate";
mapping["form1[0].#subform[10].P8_Line3d_AdditionalInfo[0]"] = "RandomAdditionalInfo";
mapping["form1[0].#subform[10].P8_Line3a_PageNumber[0]"] = "12";
mapping["form1[0].#subform[10].P8_Line3b_PartNumber[0]"] = "Part456";
mapping["form1[0].#subform[10].P8_Line3c_ItemNumber[0]"] = "Item789";
mapping["form1[0].#subform[10].Pt1Line1a_FamilyName[1]"] = "RandomFamilyName8";
mapping["form1[0].#subform[10].Pt1Line1b_GivenName[1]"] = "RandomGivenName8";
mapping["form1[0].#subform[10].Pt1Line1c_MiddleName[1]"] = "RandomMiddleName8";
mapping["form1[0].#subform[10].P8_Line4d_AdditionalInfo[0]"] = "RandomAdditionalInfo8";
mapping["form1[0].#subform[10].P8_Line4a_PageNumber[0]"] = "34";
mapping["form1[0].#subform[10].P8_Line4b_PartNumber[0]"] = "Part890";
mapping["form1[0].#subform[10].P8_Line4c_ItemNumber[0]"] = "Item123";
mapping["form1[0].#subform[10].P8_Line7a_PageNumber[0]"] = "56";
mapping["form1[0].#subform[10].P8_Line7b_PartNumber[0]"] = "Part789";
mapping["form1[0].#subform[10].P8_Line7c_ItemNumber[0]"] = "Item456";
mapping["form1[0].#subform[10].P8_Line5a_PageNumber[0]"] = "78";
mapping["form1[0].#subform[10].P8_Line5b_PartNumber[0]"] = "Part012";
mapping["form1[0].#subform[10].P8_Line5c_ItemNumber[0]"] = "Item345";
mapping["form1[0].#subform[10].P8_Line7d_AdditionalInfo[0]"] = "RandomAdditionalInfo8";
mapping["form1[0].#subform[10].P8_Line5d_AdditionalInfo[0]"] = "RandomAdditionalInfo8";
mapping["form1[0].#subform[10].P8_Line6a_PageNumber[0]"] = "90";
  
  return mapping;
};




const handleSubmitI751= async () => {
  const data = mapFormFields();
  console.log(data);
   console.log("uzair")
  // Now, you can call your API with this data 
  try {
    const response = await axiosRequest.post("/fillForm", {
      "formName": "I-751",
      "isCompleted": true,
      "userData": data
    });
    console.log(response);
    alert("Form submitted successfully!");
  }
  catch (error) {
    console.log(error);
    alert(error.message);
  }
};



  return (
    <FormContext751.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitI751 }}>
      {children}
    </FormContext751.Provider>
  );
}
