import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';
// Create a context for the form state
const FormContextI30 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContextI30);
}

// Define the initial state
const initialState = {
    filingLocation: '', // 'inside' or 'outside'
    inProceedings: '', // 'yes' or 'no'
    underProceedings: '', // 'yes' or 'no'
    chargedWithCrime: '', // 'yes' or 'no'
    travelDocument: '', // 'advanceParole', 'reentryPermit', 'refugeeTravel', 'humanitarianParole', 'dontKnow'
    isPermanentResident: '', // 'yes' or 'no'
    hasRefugeeStatus: '', // 'grantedRefugee', 'grantedAsylum', 'no'
    uVisaStatus: '', // 'yes' or 'no'
    i821Status: '', // 'yes' or 'no'
    lifeActStatus: '', 
    basis: '',
    currentStatus: '',
    immigrationStatus: '',
    isAdjustingStatus: '',
    obtainedPRFromRefugeeOrAsylum: '',
    spokenToAttorney: false,
    issuedReentryPermitOrTravelDoc: '',
    knowsMostRecentDocumentDate:true   ,
    travelDocumentExpired: '',
    tripDate: '',
    expectedDaysOutsideUS: '', 
    tripPurpose:"",
    intendedCountries:"",
    refugeeOrAsyleeCountry:"",
    planToTravel: '', 
     returnedToCountry: '', // To store the answer (Yes/No)
  appliedForEntryPermit: '', // To store the answer (Yes/No)
  receivedBenefitFromCountry: '', // To store the answer (Yes/No)
  reacquiredNationality: '', // To store the answer (Yes/No)
  acquiredNewNationality: '', // To store the answer (Yes/No)
  grantedStatusInOtherCountry: '',
  firstName: '',
  middleName: '',
  lastName: '',
  inCareOfName: '',
  streetName: '',
  city: '',
  zipCode: '',
  enteredNumber: '',
  selectedOption:"",
  sex: '', // 'Male' or 'Female'
  alienRegistrationNumber: '',
  socialSecurityNumber: '',
  CountryofCitizenship:"",
  CountryofBirth:"",
  DateofBirth:"",
  PHnumber:"",
  otherInformationdaytimePhoneNumber:"",
  Pnumber:"",
  otherInformationday:"",
  emailAddress: '', 
  urgentNeedForTravelDocument: '',
  preparedByApplicant: '',
  representApplicantBeyondPreparation: '', 
  preparerInformationGivenName: '',
  preparerInformationFamilyName: '',
  preparerInformationBusinessOrOrganization: '',
  preparersMailingAddressStateOrTerritory: '',
  preparersMailingAddressCountry: '',
  preparersMailingAddressApartmentSuiteFloor: 'No',
  enterTheNumberHere: '',
  TimePhoneNumber:"",
  Mbnumber:"",
  email: '',
  
  };

// Create a provider component to manage the state
export function FormProviderForI30({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text': // Include input type "text"
        case 'number': // Include input type "number"
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one': // Handle select option
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      };
    });
  };

 



  

  return (
    <FormContextI30.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange }}>
      {children}
    </FormContextI30.Provider>
  );
}
