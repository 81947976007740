
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/ContextN400';



const EligibilityN400 = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/ApplicantInformationN400")
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            
     <div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    Are either of your parents U.S. citizens?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <input
      type="radio"
      id="parentUSCitizenYes"
      name="parentUSCitizen"
      value="Yes"
      checked={formData.parentUSCitizen === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="parentUSCitizenYes" className="text-blue-700 mr-2">
      Yes
    </label>

    <input
      type="radio"
      id="parentUSCitizenNo"
      name="parentUSCitizen"
      value="No"
      checked={formData.parentUSCitizen === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="parentUSCitizenNo" className="text-blue-700">
      No
    </label>
  </div>
</div>

<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    Did your parent become a U.S. citizen before your 18th birthday?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <input
      type="radio"
      id="parentUSCitizenBefore18Yes"
      name="parentUSCitizenBefore18"
      value="Yes"
      checked={formData.parentUSCitizenBefore18 === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="parentUSCitizenBefore18Yes" className="text-blue-700 mr-2">
      Yes
    </label>

    <input
      type="radio"
      id="parentUSCitizenBefore18No"
      name="parentUSCitizenBefore18"
      value="No"
      checked={formData.parentUSCitizenBefore18 === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="parentUSCitizenBefore18No" className="text-blue-700">
      No
    </label>
  </div>
</div>

<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    Are you at least 18 years old?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <input
      type="radio"
      id="isAtLeast18Yes"
      name="isAtLeast18"
      value="Yes"
      checked={formData.isAtLeast18 === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="isAtLeast18Yes" className="text-blue-700 mr-2">
      Yes
    </label>

    <input
      type="radio"
      id="isAtLeast18No"
      name="isAtLeast18"
      value="No"
      checked={formData.isAtLeast18 === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="isAtLeast18No" className="text-blue-700">
      No
    </label>
  </div>
</div>



<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    What is your current status?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <select
      id="currentStatus"
      name="currentStatus"
      className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
      value={formData.currentStatus}
      onChange={handleInputChange}
    >
      <option value="">Select an option</option>
      <option value="U.S. permanent resident">U.S. permanent resident</option>
      <option value="U.S. national">U.S. national</option>
      <option value="Other">Other</option>
    </select>
  </div>
</div>

<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    Where were you born?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    {/* Assuming 'placeOfBirth' is a text input */}
    <input
      type="text"
      id="placeOfBirth"
      name="placeOfBirth"
      className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-2"
      value={formData.placeOfBirth}
      onChange={handleInputChange}
    />
  </div>
</div>

<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    Have you become a resident of a state within the United States?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <input
      type="radio"
      id="becomeResidentOfStateYes"
      name="becomeResidentOfState"
      value="Yes"
      checked={formData.becomeResidentOfState === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="becomeResidentOfStateYes" className="text-blue-700 mr-2">
      Yes
    </label>

    <input
      type="radio"
      id="becomeResidentOfStateNo"
      name="becomeResidentOfState"
      value="No"
      checked={formData.becomeResidentOfState === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="becomeResidentOfStateNo" className="text-blue-700">
      No
    </label>
  </div>
</div>



<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    In the last 5 years, have you taken any trips outside the U.S. that lasted 6 months or more?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <input
      type="radio"
      id="tripsOutsideUSYes"
      name="tripsOutsideUSLast6Months"
      value="Yes"
      checked={formData.tripsOutsideUSLast6Months === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="tripsOutsideUSYes" className="text-blue-700 mr-2">
      Yes
    </label>

    <input
      type="radio"
      id="tripsOutsideUSNo"
      name="tripsOutsideUSLast6Months"
      value="No"
      checked={formData.tripsOutsideUSLast6Months === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="tripsOutsideUSNo" className="text-blue-700">
      No
    </label>
  </div>
</div>

<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700 flex f mr-4 text-white p-4 rounded">
    Have you been physically present in the United States for at least 30 months during the previous 5 years?
  </label>
  <div className="flex bg-blue-100 p-4 rounded">
    <input
      type="radio"
      id="physicallyPresentUSYes"
      name="physicallyPresentInUSLast30Months"
      value="Yes"
      checked={formData.physicallyPresentInUSLast30Months === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="physicallyPresentUSYes" className="text-blue-700 mr-2">
      Yes
    </label>

    <input
      type="radio"
      id="physicallyPresentUSNo"
      name="physicallyPresentInUSLast30Months"
      value="No"
      checked={formData.physicallyPresentInUSLast30Months === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="physicallyPresentUSNo" className="text-blue-700">
      No
    </label>
  </div>
</div>


      




       <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default EligibilityN400