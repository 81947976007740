import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContextG639 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContextG639);
}

// Define the initial state
const initialState = {
   
    requestType:"",
    recordsRequest: '',
    purposeofrequest:"",
    requestingInformation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    usedDifferentName: '',
    streetNumberAndName: '', // Global state for Street Number and Name
    cityOrTown: '', // Global state for City or Town
    zipCode: "",
    country:"" ,
    state :"" ,
    apartment: 'No',
    number:"",
    inCareName:"",
    TimePhoneNumber:"",
    Mbnumber:"",
    MobileNumber:"",
    Mnumber:"",
    email: '',
    urgentNeed: '',
    circumstancesImminentThreat: false,
    urgencyToInformPublic: false,
    lossOfDueProcessRights: false,
    widespreadMediaInterest: false,
    dateOfBirth: '',
    countryofbirth:"",
    alienRegistrationNumber: '',
    SubjectlastName:"",
    SubjectmiddleName:"",
    SubjectfirstName:"",
    i94AdmissionNumber: '',
    passportOrTravelDocumentNumber: '',
    receiptNumber: '',
    accountNumber: '',
    FatherlastName:"",
    FathertmiddleName:"",
    FatherfirstName:"",
    fatherNameUnknown: false,
    MotherlastName:"",
    MothertmiddleName:"",
    MotherfirstName:"",
    MotherNameUnknown: false,
    haveOtherFamilyMembers: ""
  
  };

// Create a provider component to manage the state
export function FormProviderForG639({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text':
        case 'number':
        case 'textarea':
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: checked, // Toggle the value based on the checkbox's checked status
          };
        case 'select-one':
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      }
    });
  };


  
  const mapFormFields = () => {
    let mapping = {};

    // Map the values for the provided fields in Form1 and Form2 to the given PDF fields
    mapping["form1[0].#subform[2].Pt1Line1A_Checkbox[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line1B_Checkbox[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line1C_Checkbox[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line1D_Checkbox[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line1D_TORAmendOrCorrect[0]"] = "TORAmend123";
mapping["form1[0].#subform[2].Pt1Line1E_Checkbox[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line1E_TORAmendorCorrectforAnother[0]"] = "AnotherTOR123";
mapping["form1[0].#subform[2].Pt1Line1F_Checkbox[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line1F_TOROtherRecords[0]"] = "OtherRecords123";
mapping["form1[0].#subform[2].Pt1Line2_CheckboxA[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2A_RequestorDateSigned[0]"] = "2023-11-10";
mapping["form1[0].#subform[2].Pt1Line2_CheckboxB[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxC[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2C_RequestorDateSigned[0]"] = "2023-11-15";
mapping["form1[0].#subform[2].Pt1Line2_CheckboxD[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxE[0]"] = true;
mapping["form1[0].#subform[2].Pt2Line2E_RequestorDateSigned[0]"] = "2023-11-20";
mapping["form1[0].#subform[2].Pt1Line2_CheckboxF[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxG[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxH[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxI[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxJ[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxK[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxL[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxM[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxN[0]"] = false;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxO[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2_CheckboxP[0]"] = false;
mapping["form1[0].#subform[2].Pt2Line2P_RequestorDateSigned[0]"] = "2023-11-25";
mapping["form1[0].#subform[2].Pt1Line2_CheckboxQ[0]"] = true;
mapping["form1[0].#subform[2].Pt1Line2_Description[0]"] = "Description123";
mapping["form1[0].#subform[3].Pt1Line3_CheckboxA[0]"] = true;
mapping["form1[0].#subform[3].Pt1Line3_CheckboxB[0]"] = false;
mapping["form1[0].#subform[3].Pt1Line3_CheckboxC[0]"] = true;
mapping["form1[0].#subform[3].Pt1Line3_CheckboxD[0]"] = false;
mapping["form1[0].#subform[3].Pt1Line5_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].#area[0].Pt2Line1A_ANumber[0]"] = "A12345678";
mapping["form1[0].#subform[3].#area[1].Pt2Line1B_ANumber[0]"] = "B87654321";
mapping["form1[0].#subform[3].#area[2].Pt2Line1C_ANumber[0]"] = "C98765432";
mapping["form1[0].#subform[3].Pt2Line2_DateOfBirth[0]"] = "1990-01-01";
mapping["form1[0].#subform[3].Pt2Line3_CountryOfBirth[0]"] = "USA";
mapping["form1[0].#subform[4].Pt2Line4A_ReceiptNumber[0]"] = "1234567890123";
mapping["form1[0].#subform[4].Pt2Line4B_ReceiptNumber[0]"] = "9876543210987";
mapping["form1[0].#subform[4].Pt2Line4C_ReceiptNumber[0]"] = "4567890123456";
mapping["form1[0].#subform[4].Pt2Line5_FamilyName[0]"] = "Smith";
mapping["form1[0].#subform[4].Pt2Line5_GivenName[0]"] = "John";
mapping["form1[0].#subform[4].Pt2Line5_MiddleName[0]"] = "Robert";
mapping["form1[0].#subform[4].Pt2Line6A_FamilyName[0]"] = "Johnson";
mapping["form1[0].#subform[4].Pt2Line6A_GivenName[0]"] = "Emily";
mapping["form1[0].#subform[4].Pt2Line6A_MiddleName[0]"] = "Grace";
mapping["form1[0].#subform[4].Pt2Line6B_FamilyName[0]"] = "Williams";
mapping["form1[0].#subform[4].Pt2Line6B_GivenName[0]"] = "David";
mapping["form1[0].#subform[4].Pt2Line6B_MiddleName[0]"] = "Michael";
mapping["form1[0].#subform[4].Pt2Line6C_FamilyName[0]"] = "Brown";
mapping["form1[0].#subform[4].Pt2Line6C_GivenName[0]"] = "Emma";
mapping["form1[0].#subform[4].Pt2Line6C_MiddleName[0]"] = "Sophia";
mapping["form1[0].#subform[4].Pt2Line7_FamilyName[0]"] = "Jones";
mapping["form1[0].#subform[4].Pt2Line7_GivenName[0]"] = "Olivia";
mapping["form1[0].#subform[4].Pt2Line7_MiddleName[0]"] = "Daniel";
mapping["form1[0].#subform[4].Pt2Line8_StreetNumberName[0]"] = "123 Main St";
mapping["form1[0].#subform[4].Pt2Line8_Unit[0]"] = true;
mapping["form1[0].#subform[4].Pt2Line8_Unit[1]"] = false;
mapping["form1[0].#subform[4].Pt2Line8_Unit[2]"] = true;
mapping["form1[0].#subform[4].Pt2Line8_AptSteFlrNumber[0]"] = "Apt 456";
mapping["form1[0].#subform[4].Pt2Line8_CityOrTown[0]"] = "Anytown";
mapping["form1[0].#subform[4].Pt2Line8_State[0]"] = "California";
mapping["form1[0].#subform[4].Pt2Line8_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[4].Pt2Line8_Province[0]"] = "SampleProvince";
mapping["form1[0].#subform[4].Pt2Line8_PostalCode[0]"] = "123456789";
mapping["form1[0].#subform[4].Pt2Line8_Country[0]"] = "SampleCountry";
mapping["form1[0].#subform[4].Pt2Line8_DaytimeTelephone[0]"] = "123-456-7890";
mapping["form1[0].#subform[4].Pt2Line8_EmailAddress[0]"] = "sample@email.com";
mapping["form1[0].#subform[5].Pt2Line9_FamilyName[0]"] = "Smith";
mapping["form1[0].#subform[5].Pt2Line9_GivenName[0]"] = "John";
mapping["form1[0].#subform[5].Pt2Line9_MiddleName[0]"] = "Doe";
mapping["form1[0].#subform[5].Pt2Line10_FamilyName[0]"] = "Johnson";
mapping["form1[0].#subform[5].Pt2Line10_FamilyName[1]"] = "Williams";
mapping["form1[0].#subform[5].Pt2Line10_GivenName[0]"] = "Alice";
mapping["form1[0].#subform[5].Pt2Line10_MiddleName[0]"] = "Marie";
mapping["form1[0].#subform[5].Pt2Line11A_FamilyName[0]"] = "Brown";
mapping["form1[0].#subform[5].Pt2Line11A_GivenName[0]"] = "Michael";
mapping["form1[0].#subform[5].Pt2Line11A_MiddleName[0]"] = "James";
mapping["form1[0].#subform[5].Pt2Line11B_FamilyName[0]"] = "Davis";
mapping["form1[0].#subform[5].Pt2Line11B_GivenName[0]"] = "Emma";
mapping["form1[0].#subform[5].Pt2Line11B_MiddleName[0]"] = "Grace";
mapping["form1[0].#subform[5].Pt2Line11C_FamilyName[0]"] = "Clark";
mapping["form1[0].#subform[5].Pt2Line11C_GivenName[0]"] = "Oliver";
mapping["form1[0].#subform[5].Pt2Line11C_MiddleName[0]"] = "William";
mapping["form1[0].#subform[6].Pt3_RequestorCheckbox[0]"] = true;
mapping["form1[0].#subform[6].Pt3Line1_SignatureofRequestor[0]"] = "RequestorSignature";
mapping["form1[0].#subform[6].Pt3Line1_RequestorDateSigned[0]"] = "2023-11-10";
mapping["form1[0].#subform[6].Pt4Line1_FamilyName[0]"] = "Miller";
mapping["form1[0].#subform[6].Pt4Line1_GivenName[0]"] = "Ella";
mapping["form1[0].#subform[6].Pt4Line1_MiddleName[0]"] = "Sophia";
mapping["form1[0].#subform[6].Pt4Line2_InCareofName[0]"] = "InCareof";
mapping["form1[0].#subform[6].Pt4Line2_StreetNumberName[0]"] = "123 Street";
mapping["form1[0].#subform[6].Pt4Line2_Unit[0]"] = true;
mapping["form1[0].#subform[6].Pt4Line2_Unit[1]"] = false;
mapping["form1[0].#subform[6].Pt4Line2_Unit[2]"] = true;
mapping["form1[0].#subform[6].Pt4Line2_AptSteFlrNumber[0]"] = "Apt 456";
mapping["form1[0].#subform[6].Pt4Line2_CityOrTown[0]"] = "Cityville";
mapping["form1[0].#subform[6].Pt4Line2_State[0]"] = "New York";
mapping["form1[0].#subform[6].Pt4Line2_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[6].Pt4Line2_Province[0]"] = "SampleProvince2";
mapping["form1[0].#subform[6].Pt4Line2_PostalCode[0]"] = "987654321";
mapping["form1[0].#subform[6].Pt4Line2_Country[0]"] = "SampleCountry2";
mapping["form1[0].#subform[6].Pt4Line2_DaytimeTelephone[0]"] = "555-123-4567";
mapping["form1[0].#subform[6].Pt4Line2_EmailAddress[0]"] = "another@email.com";
mapping["form1[0].#subform[7].Pt4Line3_CheckboxA[0]"] = true;
mapping["form1[0].#subform[7].Pt4Line3_CheckboxB[0]"] = false;
mapping["form1[0].#subform[7].Pt4Line3_CheckboxC[0]"] = true;
 // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[7].Pt4Line3_CheckboxD[0]"] = false;
mapping["form1[0].#subform[7].Pt4Line3_ExplanationD[0]"] = "ExplanationD";
mapping["form1[0].#subform[7].Pt4Line3_CheckboxE[0]"] = true;
mapping["form1[0].#subform[7].Pt4Line3_CheckboxF[0]"] = false;
mapping["form1[0].#subform[7].Pt4Line3_ExplanationF[0]"] = "ExplanationF";
mapping["form1[0].#subform[7].Pt4Line4a_FamilyName[0]"] = "Taylor";
mapping["form1[0].#subform[7].Pt4Line4b_GivenName[0]"] = "Liam";
mapping["form1[0].#subform[7].Pt4Line4c_MiddleName[0]"] = "Christopher";
mapping["form1[0].#subform[7].Pt4Line4b_DateOfBirth[0]"] = "2000-01-01";
mapping["form1[0].#subform[7].Pt4Line4c_CountryOfBirth[0]"] = "US";
mapping["form1[0].#subform[7].P4_Option1_Checkbox1[0]"] = true;
mapping["form1[0].#subform[7].P4_Line5_SignatureofSubjectOfRecord[0]"] = "S";
mapping["form1[0].#subform[7].P4_Line5_DateofSignature[0]"] = "2023-11-10";
mapping["form1[0].#subform[8].P4_Line6_SignatureofSubjectOfRecord[0]"] = "S";
mapping["form1[0].#subform[8].P4_Line7_DateofSignature[0]"] = "2023-11-10";
mapping["form1[0].#subform[8].P4_Line8_Subscribe[0]"] = "SubscribeText";
mapping["form1[0].#subform[8].P4_Line9_SignatureofNotary[0]"] = "N";
mapping["form1[0].#subform[8].P4_Line10_NotaryPhoneNumber[0]"] = "123-456-7890";
mapping["form1[0].#subform[8].P4_Line11_DateCommissionExpires[0]"] = "2023-12-31";
mapping["form1[0].#subform[9].Pt2Line5_FamilyName[1]"] = "Johnson";
mapping["form1[0].#subform[9].Pt2Line5_GivenName[1]"] = "Sophia";
mapping["form1[0].#subform[9].Pt2Line5_MiddleName[1]"] = "Grace";
mapping["form1[0].#subform[9].#area[3].Pt2Line1A_ANumber[1]"] = "A12345678";
mapping["form1[0].#subform[9].P5_Line3a_PageNumber[0]"] = "01";
mapping["form1[0].#subform[9].P5_Line3b_PartNumber[0]"] = "PART01";
mapping["form1[0].#subform[9].P5_Line3c_ItemNumber[0]"] = "ITEM01";
mapping["form1[0].#subform[9].P5_Line3d_AdditionalInfo[0]"] = "AdditionalInfo3D";
mapping["form1[0].#subform[9].P5_Line4a_PageNumber[0]"] = "02";
mapping["form1[0].#subform[9].P5_Line4b_PartNumber[0]"] = "PART02";
mapping["form1[0].#subform[9].P5_Line4c_ItemNumber[0]"] = "ITEM02";
mapping["form1[0].#subform[9].P5_Line5a_PageNumber[0]"] = "03";
mapping["form1[0].#subform[9].P5_Line5b_PartNumber[0]"] = "PART03";
mapping["form1[0].#subform[9].P5_Line5c_ItemNumber[0]"] = "ITEM03";
mapping["form1[0].#subform[9].P5_Line5d_AdditionalInfo[0]"] = "AdditionalInfo5D";
mapping["form1[0].#subform[9].P5_Line6a_PageNumber[0]"] = "04";
mapping["form1[0].#subform[9].P5_Line6b_PartNumber[0]"] = "PART04";
mapping["form1[0].#subform[9].P5_Line6c_ItemNumber[0]"] = "ITEM04";
mapping["form1[0].#subform[9].P5_Line6d_AdditionalInfo[0]"] = "AdditionalInfo6D";
mapping["form1[0].#subform[9].P5_Line7a_PageNumber[0]"] = "05";
mapping["form1[0].#subform[9].P5_Line7b_PartNumber[0]"] = "PART05";
mapping["form1[0].#subform[9].P5_Line7c_ItemNumber[0]"] = "ITEM05";
mapping["form1[0].#subform[9].P5_Line7d_AdditionalInfo[0]"] = "AdditionalInfo7D";
mapping["form1[0].#subform[9].P5_Line7d_AdditionalInfo[1]"] = "AdditionalInfo7D_2";

// ... (continue with other mappings)

    
    return mapping;
  };




  const handleSubmitG639 = async () => {
    const data = mapFormFields();
    console.log(data);
     console.log("uzair")
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "G-639",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };





  return (
    <FormContextG639.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange, handleSubmitG639 }}>
      {children}
    </FormContextG639.Provider>
  );
}
