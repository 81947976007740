import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";

const I8646 = () => {
  const navigate=useNavigate()
  const { formData, handleInputChange } = useFormContext();
  const maxLength = 50;
  if (!formData.name) formData.name = "";
  if (!formData.Middle) formData.Middle = "";
  if (!formData.Family) formData.Family = "";
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };

return(
    <div className="w-full">
    <Header />
    <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
    <div
        className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
        style={{ backgroundColor: "#0072bc" }}
      >
        <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
          verified and security
        </span>
        <span className="font-bold text-white text-xl formheaderfont">
          USCIS Form I-864 - Affidavit of Support Package
        </span>
        <div className="h-full flex justify-end">
          {" "}
          {/* Added 'flex' and 'justify-end' */}
          <button
            className="text-white px-2 bg-blue-900 h-full border border-black"
            style={{ textAlign: "left" }}
          >
            Save
          </button>
          <button className="text-white px-2 bg-blue-900 h-full">Help</button>
        </div>
      </div>
   
      <div
        className="progress-description pt-3 pt-lg-0"
        id="progress-description"
      >
        <h2 style={{ marginBottom: "-1%" }}>
          {" "}
          Before we begin, we need to know the type of qualifying relationship that the petitioner has with the principal immigrant.
        </h2>

      </div>
      <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
 
      <form onSubmit={handleSubmit}  className="mt-6 w-full ">
      <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              Does the principal immigrant have a spouse and/or children that will also immigrate to the United States?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="childrenImigrate"
                  value="Yes"
                  id="inUSYess"
                  onChange={handleInputChange}
                  checked={formData.childrenImigrate === "Yes"}
                />
                <label htmlFor="inUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="childrenImigrate"
                  value="No"
                  id="inUSNos"
                  onChange={handleInputChange}
                  checked={formData.childrenImigrate === "No"}
                />
                <label htmlFor="inUSNos">No</label>
                <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                   If you answer "Yes," we will provide you a reminder in your filing instructions.

                  </div>
              </div>
              
            </div>
            <br />
          


<br />
<br />

            <div className="w-full ml-8">
            <button
              type="button"
              className="float-left previous text-white px-4 py-2 rounded mt-4"
              style={{ background: "rgb(187, 187, 187)" }}
            >
              ← PREV
            </button>
            <br />
            <button
              type="submit"
              className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
              onClick={()=>navigate("/I8647")}
            >
              NEXT →
            </button>
          </div>
      </form >

      </div>
      <FormFotter />

    </div>
 
              </div>
);}
export default I8646;