



import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import FormFotter from '../../components/FormFotter';
import Header from '../../components/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from '../../UseContext/UseContextForI-I29F';

const BenificiaryAddressHistory = () => {
    const { state, setFormState } = useFormContext();
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState('SET_BENEFICIARY_MAILING_ADDRESS', {
          field: name,
          payload: value,
        });
      };

      const handleChangenew = (e) => {
        const { name, value } = e.target;
        setFormState(name, value);
      };

      

  


    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
          <Header />
          <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
    
              <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
              <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
              <div className='h-full'>
                <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
                <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
              </div>
            </div>
    
    
    
    
    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
        <span className='mt-4 font-bold text-xl ml-2 text-black '>
          First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
        </span>
        <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
          <button className='mr-2'>Page 1 of 3</button>
          <button><MdArrowDropDown /></button>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
    </div>
    
            <form action=" " className='mt-2 w-11/12  '>
            <h2 className='text-blue-500  font-bold text-xl font-sans mt-20'>Current Employment</h2>
              <p className='font-thin'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
                  
                  
    

     <div className='flex gap-4  flex-col'>
      <h2>Beneficiary's Mailing Address</h2>
    <div className='flex  '>
      <label className='flex text-blue-600 w-[200px]'> 
        In Care of Name:
        </label>
        <input
          type="text"
          name="inCareOfName"
          value={state.beneficiaryMailingAddress.inCareOfName}
          onChange={handleChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
     </div>
     <div className='flex'>
      <label className='flex text-blue-600 w-[200px]'>
        Street Number and Name:
        </label>
        <input
          type="text"
          name="streetNumberAndName"
          value={state.beneficiaryMailingAddress.streetNumberAndName}
          onChange={handleChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
    </div>
      <div className='flex'>
      <label className='flex text-blue-600 w-[200px]'>
        City or Town:
        </label>
        <input
          type="text"
          name="cityOrTown"
          value={state.beneficiaryMailingAddress.cityOrTown}
          onChange={handleChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
    </div>
     
     <div className='flex'>
      <label className='flex text-blue-600 w-[200px]'>
        Province:
        </label>
        <input
          type="text"
          name="province"
          value={state.beneficiaryMailingAddress.province}
          onChange={handleChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
    </div>
    
    <div className='flex'>
      <label className='flex text-blue-600 w-[200px]'>
        Postal Code:
        </label>
        <input
          type="text"
          name="postalCode"
          value={state.beneficiaryMailingAddress.postalCode}
          onChange={handleChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
    </div>
    </div>



    <div className='flex flex-col'>
    <h2 >Beneficiary's Address Details</h2>
      <label  className='flex bg-blue-600  p-4 w-full  text-white rounded'>
        Is the mailing address the same as the beneficiary's current physical address?
      </label>
      <div className='flex  bg-blue-100 p-4 gap-10 text-blue-600'>
        <label>
          <input
            type="radio"
            name="isMailingSameAsPhysical"
            value="Yes"
            checked={state.isMailingSameAsPhysical === 'Yes'}
            onChange={handleChange}
          
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="isMailingSameAsPhysical"
            value="No"
            checked={state.isMailingSameAsPhysical === 'No'}
            onChange={handleChange}
          />
          No
        </label>
      </div>
  
        <label className='flex bg-blue-600  p-4 w-full  text-white rounded mt-10'>
          Date beneficiary began living at the address above:
          </label>
          <div className='flex bg-blue-100  p-4 w-full  text-white rounded'>
          <input
            type="date"
            name="dateBeganLivingAtAddress"
            value={state.dateBeganLivingAtAddress}
            onChange={handleChange}
            className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
          />
        </div>
    </div>

     


                    

        
    
                     
    
    
                 
                 
    
    
          <button
            type="button"
            className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
          >
            Add Another Name
          </button>
  
                  
            </form>
            <FormFotter />
          </div>
        </div>
      )
}

export default BenificiaryAddressHistory