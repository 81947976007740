// PermissionContext.js
import React, { createContext, useState } from 'react';
import { MdFunctions } from 'react-icons/md';
import { useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

const ContextFor1762 = createContext();

const PermissionProvider = ({ children }) => {
  const [permissionType, setPermissionType] = useState('');
  const [expiryOption, setExpiryOption] = useState('');
  const [applicationReason, setApplicationReason] = useState('');
  const [isAtLeastFifteen, setIsAtLeastFifteen] = useState('');
  const [isFilingAsylum, setIsFilingAsylum] = useState('');
  const [hasBeenArrested, setHasBeenArrested] = useState('');
  const [acknowledgeAttorney, setAcknowledgeAttorney] = useState(false);
  const [servedInMilitary, setServedInMilitary] = useState('');
  const [givenFalseInformation, setGivenFalseInformation] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  // Form 2  States

  const [givenName, setGivenName] = useState('');
const [middleName, setMiddleName] = useState('');
const [familyName, setFamilyName] = useState('');
  const [haveUsedOtherNames, setHaveUsedOtherNames] = useState('');
  const [nameSets, setNameSets] = useState([{ id: 1 }]);
  const [cityOrTown, setCityOrTown] = useState('');
  const [provinceOrState, setProvinceOrState] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [sex, setSex] = useState('');
  const [maritalStatus, setMaritalStatus] = useState('');
  const [uscisOnlineAccountNumber, setUscisOnlineAccountNumber] = useState('');
  const [aNumber, setANumber] = useState('');
  const [ssnIssued, setSsnIssued] = useState('');
  const [ssn, setSsn] = useState('');
  const [unknownSsn, setUnknownSsn] = useState(false);
  const [replaceSsnCard, setReplaceSsnCard] = useState('');
  const [authorizeDisclosureToSSA, setAuthorizeDisclosureToSSA] = useState('');
  const [fathersGivenName, setFathersGivenName] = useState('');
  const [fathersFamilyName, setFathersFamilyName] = useState('');
  const [mothersGivenName, setMothersGivenName] = useState('');
  const [mothersFamilyName, setMothersFamilyName] = useState('');



  // form 3
  const [nameSetsCountry, setNameSetsCountry] = useState([{id:1}]);
  const [countryNames, setCountryNames] = useState([]); 
  const [selectedOption, setSelectedOption] = useState('Passport'); 
  const [travelDocument, setTravelDocument] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [countryOfIssuance, setCountryOfIssuance] = useState([]);
  const [selectedSecondCountry, setSelectedSecondCountry] = useState('');
  const [i94Number, setI94Number] = useState('');
  const [selectedImmigrantStatusOption, setselectedImmigrantStatusOption] = useState('');
  const [lastArrivalDate, setLastArrivalDate] = useState('');
  const [overstayedVisa, setOverstayedVisa] = useState('');
  const [selectedState, setSelectedState] = useState(''); 
  const [selectedOptionForState, setSelectedOptionForState] = useState('');
  const [recentImmgigrationStatus, setRecentImmgigrationStatus] = useState('');
  const [recentImmgigrationStatusSecond, setRecentImmgigrationStatusSecond] = useState('');

   //Form 4
   const [inCareOfName, setInCareOfName] = useState('');
   const [streetNumberName, setStreetNumberName] = useState('');
   const [apartmentSuiteFloor, setApartmentSuiteFloor] = useState('No');
   const [enterNumber, setEnterNumber] = useState('');
   const [cityOrTownFormFour, setCityOrTownFormFour] = useState('');
   const [selectedStateFormFour, setSelectedStateFormFour] = useState('');
   const [countriesFormFour, setCountriesFormFour] = useState([]);
   const [zipCode, setZipCode] = useState('');
   const [isMailingAddressSame, setIsMailingAddressSame] = useState("")
   const [usPhysicalAddress, setUsPhysicalAddress] = useState('');
    const [usPhysicalApartmentSuiteFloor, setUsPhysicalApartmentSuiteFloor] = useState('No');
    const [usPhysicalEnterNumber, setUsPhysicalEnterNumber] = useState('');
    const [usPhysicalCityOrTown, setUsPhysicalCityOrTown] = useState('');
    const [usPhysicalSelectedState, setUsPhysicalSelectedState] = useState('');
    const [usPhysicalCountries, setUsPhysicalCountries] = useState([]);
    const [usPhysicalZipCode, setUsPhysicalZipCode] = useState('');
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('+1');
    const [daytimePhoneNumber, setDaytimePhoneNumber] = useState('');
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState('');
  

    // last form

    const [previouslyFiledI765, setPreviouslyFiledI765] = useState('');
    const [receivedHelpFromInterpreter, setReceivedHelpFromInterpreter] = useState('');
    const [interpreterLanguage, setInterpreterLanguage] = useState('');
    const [interpreterGivenName, setInterpreterGivenName] = useState('');
    const [interpreterFamilyName, setInterpreterFamilyName] = useState('');
    const [interpreterBusinessOrOrganization, setInterpreterBusinessOrOrganization] = useState('');
    const [interpreterStreetNumberName, setInterpreterStreetNumberName] = useState('');
    const [interpreterApartmentSuiteFloor, setInterpreterApartmentSuiteFloor] = useState('');
    const [number, setnumber] = useState('');
    const [interpreterCityOrTown, setInterpreterCityOrTown] = useState('');
    const [selectedInterpreterCountry, setSelectedInterpreterCountry] = useState(''); 
    const [interpretercountries,setCountriesinterpreter] = useState([]);
    const [interpreterProvince, setInterpreterProvince] = useState('');
     const [interpreterPostalCode, setInterpreterPostalCode] = useState('');
     const [daytimephonenumber, setdaytimephonenumber] = useState('+1');
     const [mobilephonenumber, setmobilephonenumber] = useState('+1');
     const [phonenumber, setphonenumber] = useState('');
     const [phonenumbers, setphonenumbers] = useState('');
     const [emailAddress, setEmailAddress] = useState('');
     const [isInterpreted, setIsInterpreted] = useState(false);
     const [preparerType, setPreparerType] = useState(""); 
     const [representationType, setRepresentationType] = useState("extends"); 
     
      const [preparerGivenName, setPreparerGivenName] = useState('');
      const [preparerFamilyName, setPreparerFamilyName] = useState('');
      const [preparerBusinessOrOrganization, setPreparerBusinessOrOrganization] = useState('');
      const [uscisOnlineAccountNumbers, setUscisOnlineAccountNumbers] = useState('')
      const [attachFormG28, setAttachFormG28] = useState(''); 
      const [preparerStreetNumberName, setPreparerStreetNumberName] = useState('');
      const [preparerHasApartment, setPreparerHasApartment] = useState('No'); // Default value is 'No'
      const [preparerCityOrTown, setPreparerCityOrTown] = useState('');
      const [preparerCountry, setPreparerCountry] = useState('');
      const [preparerProvince, setPreparerProvince] = useState('');
      const [preparerPostalCode, setPreparerPostalCode] = useState('');    
      const [preparersContactInfo, setPreparersContactInfo] = useState({
        daytimePhoneNumber: '',
        mobilePhoneNumber: '',
      });
      
      // Handle change for both daytime and mobile phone numbers
    



    
  

 
 

  



  const updatePermissionType = (type) => {
    setPermissionType(type);
  };

  const updateExpiryOption = (option) => {
    setExpiryOption(option);
  };

  const updateApplicationReason = (reason) => {
    setApplicationReason(reason);
  };

  const updateIsAtLeastFifteen = (value) => {
    setIsAtLeastFifteen(value);
  };

  const updateIsFilingAsylum = (value) => {
    setIsFilingAsylum(value);
  };

  const updateHasBeenArrested = (value) => {
    setHasBeenArrested(value);
  };

  const updateAcknowledgeAttorney = () => {
    setAcknowledgeAttorney(!acknowledgeAttorney);
  };

  const updateServedInMilitary = (value) => {
    setServedInMilitary(value);
  };

  const updateGivenFalseInformation = (value) => {
    setGivenFalseInformation(value);
  };

  const updateSelectedOption = ({ selectedGroup, selectedValue }) => {
    setSelectedGroup(selectedGroup);
    setSelectedValue(selectedValue);
  };


  //  form2  dFunctions

  const updateGivenName = (name) => {
    setGivenName(name);
  };

  const updateMiddleName = (name) => {
    setMiddleName(name);
  };

  const updateFamilyName = (name) => {
    setFamilyName(name);
  };

  const updateHaveUsedOtherNames = (value) => {
    setHaveUsedOtherNames(value);
  };

  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };



  const updateCityOrTown = (value) => {
    setCityOrTown(value);
  };

  const updateProvinceOrState = (value) => {
    setProvinceOrState(value);
  };

  const updateDateOfBirth = (value) => {
    setDateOfBirth(value);
  };
  const updateANumber = (value) => {
    setANumber(value);
  };


useEffect(() => {
  const fetchCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const data = await response.json();
      const countryNames = data.map((country) => country.name.common);
      setCountries(countryNames);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  fetchCountries();
}, []);





useEffect(() => {
  const fetchCountries = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const data = await response.json();
      
      const countryNames = data.map((country) => country.name.common);
      setCountryOfIssuance(countryNames);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  fetchCountries();
}, []);




useEffect(() => {

  fetch('https://restcountries.com/v3.1/all')
    .then((response) => response.json())
    .then((data) => {
      const countryData = data.map((country) => country.name);
      setCountriesFormFour(countryData);
      setUsPhysicalCountries(countryData)
      setCountriesinterpreter(countryData)
      
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, []);


useEffect(() => {
  // Fetch country codes from the provided API
  fetch('https://datahub.io/core/country-codes/r/country-codes.json')
    .then((response) => response.json())
    .then((data) => {
      // Assuming the API response is an array of objects with 'Name' and 'Code' fields.
      const codes = data.map((country) => ({
        name: country.Name,
        alpha2Code: country.Code,
      }));
      setCountryCodes(codes);
    })
    .catch((error) => {
      console.error('Error fetching country codes:', error);
    });
}, []);





  const updateSelectedCountry = (country) => {
    setSelectedCountry(country);
  };
  const updateSex = (selectedSex) => {
    setSex(selectedSex);
  };

  const updateSsnIssued = (value) => {
    setSsnIssued(value);
  };



  const updateMaritalStatus = (status) => {
    setMaritalStatus(status);
  };
  const updateUscisOnlineAccountNumber = (number) => {
    setUscisOnlineAccountNumber(number);
  };


  const updateSsn = (value) => {
    setSsn(value);
  };

  const updateUnknownSsn = () => {
    setUnknownSsn(!unknownSsn);
  };


  const updateReplaceSsnCard = (value) => {
    setReplaceSsnCard(value);
  };

  const updateAuthorizeDisclosureToSSA = (value) => {
    setAuthorizeDisclosureToSSA(value);
  };

  const updateFathersGivenName = (name) => {
    setFathersGivenName(name);
  };

  const updateFathersFamilyName = (name) => {
    setFathersFamilyName(name);
  };

  const updateMothersGivenName = (name) => {
    setMothersGivenName(name);
  };

  const updateMothersFamilyName = (name) => {
    setMothersFamilyName(name);
  };


  // form3








  const handleAddAnotherCountry = () => {
    const newId = nameSetsCountry.length + 1;
    setNameSetsCountry([...nameSetsCountry, { id: newId, state: '' }]);
  };

  const handleRemoveCountry = (id) => {
    const updatedNameSets = nameSetsCountry.filter((nameSet) => nameSet.id !== id);
    setNameSetsCountry(updatedNameSets);
  };

  const updateStateCountry = (id, value) => {
    setNameSetsCountry((prevNameSets) =>
      prevNameSets.map((nameSet) => (nameSet.id === id ? { ...nameSet, state: value } : nameSet))
    );
  };

  const setGlobalCountryNames = (names) => {
    setCountryNames(names);
  };
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const updateLastArrivalDate = (date) => {
    setLastArrivalDate(date);
  };

  const updateOverstayedVisa = (value) => {
    setOverstayedVisa(value);
  };

  const updateSelectedState = (state) => {
    setSelectedState(state);
  };

  const handleChangeForState = (newOption) => {
    setSelectedOptionForState(newOption);
  };
  const handleChangeForimmigrationstatus = (value) => {
    setselectedImmigrantStatusOption(value);
  };

  const handelChangeRecentImmgigrationStatus =(value)=>{
    setRecentImmgigrationStatus(value)
  }
  const handelChangeRecentImmgigrationStatusSecond =(value)=>{
    setRecentImmgigrationStatusSecond(value)
  }

  //Form Four
  
  const handleInCareOfNameChange = (newValue) => {
    setInCareOfName(newValue);
  };

  const handleStreetNumberNameChange = (newValue) => {
    setStreetNumberName(newValue);
  };

  const handleApartmentSuiteFloorChange = (newValue) => {
    setApartmentSuiteFloor(newValue);
  };

  const handleEnterNumberChange = (newValue) => {
    setEnterNumber(newValue);
  };

  const handleCityOrTownChangeFormFour = (newValue) => {
    setCityOrTownFormFour(newValue);
  };

  const handleChangeState = (newState) => {
    setSelectedStateFormFour(newState);
  };

  const handleChangeZipCode = (newZipCode) => {
    setZipCode(newZipCode);
  };

  const handleChangeMailingAddress = (newValue) => {
    setIsMailingAddressSame(newValue);
  };

  const handleChangeUsPhysicalAddress = (newValue) => {
    setUsPhysicalAddress(newValue);
  };
  
  const handleChangeUsPhysicalApartmentSuiteFloor = (newValue) => {
    setUsPhysicalApartmentSuiteFloor(newValue);
  };
  
  const handleChangeUsPhysicalEnterNumber = (newValue) => {
    setUsPhysicalEnterNumber(newValue);
  };
  
  const handleChangeUsPhysicalCityOrTown = (newValue) => {
    setUsPhysicalCityOrTown(newValue);
  };
  
  const handleChangeUsPhysicalSelectedState = (newValue) => {
    setUsPhysicalSelectedState(newValue);
  };
  
  const handleChangeUsPhysicalZipCode = (newValue) => {
    setUsPhysicalZipCode(newValue);
  };

  const handleCountryCodeChange = (newCode) => {
    setSelectedCountryCode(newCode);
  };

  const handleDaytimePhoneNumberChange = (newPhoneNumber) => {
    setDaytimePhoneNumber(newPhoneNumber);
  };

  const handleMobilePhoneNumberChange = (newPhoneNumber) => {
    setMobilePhoneNumber(newPhoneNumber);
  };

  const handleMobileNumberChange = (newPhoneNumber) => {
    setmobilephonenumber(newPhoneNumber);
  };


  // form Four

  const handlePreviouslyFiledI765Change = (value) => {
    setPreviouslyFiledI765(value);
  };
  const handleReceivedHelpFromInterpreterChange = (value) => {
    setReceivedHelpFromInterpreter(value);
  };


  // const handleChangeForInterpreter = (field, value) => {
  //   // Update the state based on the field name and new value
  //   switch (field) {
   
  //   case 'interpreterLanguage':
  //     setInterpreterLanguage(value);
  //     break;
  //     case 'interpreterGivenName':
  //       setInterpreterGivenName(value);
  //       break;
  //     case 'interpreterFamilyName':
  //       setInterpreterFamilyName(value);
  //       break;
  //     case 'interpreterBusinessOrOrganization':
  //       setInterpreterBusinessOrOrganization(value);
  //       break;
  //     // Add other cases for handling more fields if needed
  //     default:
  //       break;
  //   }
  // };
  const handleChangeInterpreterLanguage = (value) => {
    setInterpreterLanguage(value);
  };

  const handleChangeInterpreterGivenName = (value) => {
    setInterpreterGivenName(value);
  };

  const handleChangeInterpreterFamilyName = (value) => {
    setInterpreterFamilyName(value);
  };

  const handleChangeInterpreterBusinessOrOrganization = (value) => {
    setInterpreterBusinessOrOrganization(value);
  };





  const handleChangeInterpreterStreetNumberName = (value) => {
    setInterpreterStreetNumberName(value);
  };

  const handleChangeInterpreterApartmentSuiteFloor = (value) => {
    setInterpreterApartmentSuiteFloor(value);
  };

  const handleChangeInterpreterCityOrTown = (value) => {
    setInterpreterCityOrTown(value);
  };

  const handleChangeInterpreterProvince = (value) => {
    setInterpreterProvince(value);
  };

  const handleChangeInterpreterPostalCode = (value) => {
    setInterpreterPostalCode(value);
  };
  

  const handleChangetimephonenumber = (value) => {
    setdaytimephonenumber(value);
  };

  const handlePhoneNumberchange =(value)=>{
    setphonenumber(value)
  }
  const handlePhoneNumberschange =(value)=>{
    setphonenumbers(value)
  }



  const handleSelectedInterpreterCountry=(value)=>{
    setSelectedInterpreterCountry(value)
  }
  const handlenumber=(value)=>{
    setnumber(value)
  }

  const handleChangeEmailAddress = (value) => {
    setEmailAddress(value);
  }

  const handleChangeInterpreted = (isChecked) => {
    setIsInterpreted(isChecked);
  };
  
  const handlePreparerTypeChange = (e) => {
    setPreparerType(e.target.value);
  };
  
const handleRepresentationTypeChange = (e) => {
  setRepresentationType(e.target.value);
};

const handlePreparerGivenNameChange = (e) => {
  setPreparerGivenName(e.target.value);
};

const handlePreparerFamilyNameChange = (e) => {
  setPreparerFamilyName(e.target.value);
};

const handlePreparerBusinessOrOrganizationChange = (e) => {
  setPreparerBusinessOrOrganization(e.target.value);
};

const handleUscisOnlineAccountNumberChange = (e) => {
  setUscisOnlineAccountNumbers(e.target.value);
};
const handleAttachFormG28Change = (e) => {
  setAttachFormG28(e.target.value);
};

const handlePreparerStreetNumberNameChange = (e) => {
  setPreparerStreetNumberName(e.target.value);
};

const handlePreparerHasApartmentChange = (e) => {
  setPreparerHasApartment(e.target.value);
};

const handlePreparerCityOrTownChange = (e) => {
  setPreparerCityOrTown(e.target.value);
};

const handlePreparerCountryChange = (e) => {
  setPreparerCountry(e.target.value);
};

const handlePreparerProvinceChange = (e) => {
  setPreparerProvince(e.target.value);
};

const handlePreparerPostalCodeChange = (e) => {
  setPreparerPostalCode(e.target.value);
};

const handleDaytimePhoneNumberChanger = (value) => {
  setPreparersContactInfo({
    ...preparersContactInfo,
    daytimePhoneNumber: value,
  });
};

const handleMobilePhoneNumberChanger = (value) => {
  setPreparersContactInfo({
    ...preparersContactInfo,
    mobilePhoneNumber: value,
  });
};
  // const handleSubmitssion = () => {
  //   const data = {
  //     "form1[0].Page1[0].Line1a_FamilyName[0]": familyName,
  //     "form1[0].Page1[0].Line1b_GivenName[0]": givenName,
  //     "form1[0].Page1[0].Line1c_MiddleName[0]": middleName,
  //     "form1[0].Page1[0].Line2a_OtherNamesUsed[0]": haveUsedOtherNames,
  //     "form1[0].Page1[0].Line2b_OtherNamesUsed[0]": haveUsedOtherNames,
  //   }

  // }

  const mapFormFields = () => {
    let mapping = {};

    // Map the values for the provided fields in Form1 and Form2 to the given PDF fields
    mapping["form1[0].#pageSet[0].Page1[0].PDF417BarCode1[0]"] = '';
    mapping["form1[0].Page1[0].Line1a_FamilyName[0]"] = familyName;
    mapping["form1[0].Page1[0].Line1b_GivenName[0]"] = givenName;
    mapping["form1[0].Page1[0].Line1c_MiddleName[0]"] = middleName;
    // ... (add the rest from the previous answer)

    // Mapping for the newly provided fields
    mapping["form1[0].Page2[0].Pt2Line7_CityOrTown[0]"] = cityOrTown;
    mapping["form1[0].Page2[0].Pt2Line7_State[0]"] = provinceOrState;
    mapping["form1[0].Page2[0].Pt2Line7_ZipCode[0]"] = ''; // You didn't provide a ZIP state field
    mapping["form1[0].Page2[0].Pt2Line7_StreetNumberName[0]"] = ''; // Street number not provided
    mapping["form1[0].Page2[0].Line14_Checkbox_No[0]"] = (hasBeenArrested === 'No') ? true : false;
    mapping["form1[0].Page2[0].Line14_Checkbox_Yes[0]"] = (hasBeenArrested === 'Yes') ? true : false;
    mapping["form1[0].Page2[0].Line7_AlienNumber[0]"] = aNumber;
    mapping["form1[0].Page2[0].Line8_ElisAccountNumber[0]"] = uscisOnlineAccountNumber;
    mapping["form1[0].Page2[0].Line9_Checkbox[0]"] = (unknownSsn === true) ? true : false;
    mapping["form1[0].Page2[0].Line9_Checkbox[1]"] = (replaceSsnCard === 'Yes') ? true : false;
    // ... (continue with the rest of the checkboxes, set them based on your React states)
    mapping["form1[0].Page2[0].Line15a_FamilyName[0]"] = fathersFamilyName;
    mapping["form1[0].Page2[0].Line15b_GivenName[0]"] = fathersGivenName;
    mapping["form1[0].Page2[0].Line16a_FamilyName[0]"] = mothersFamilyName;
    mapping["form1[0].Page2[0].Line16b_GivenName[0]"] = mothersGivenName;
    mapping["form1[0].Page2[0].Line17b_CountryOfBirth[0]"] = selectedCountry;

    // ... (and continue with the rest of your fields)

    return mapping;
  };




  const handleSubmitI762 = async () => {
    const data = mapFormFields();
    console.log(data);
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "i-765",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }

  };


  return (
    <ContextFor1762.Provider
      value={{
        permissionType,
        updatePermissionType,
        expiryOption,
        updateExpiryOption,
        applicationReason,
        updateApplicationReason,
        isAtLeastFifteen,
        updateIsAtLeastFifteen,
        isFilingAsylum,
        updateIsFilingAsylum,
        hasBeenArrested,
        updateHasBeenArrested,
        acknowledgeAttorney,
        updateAcknowledgeAttorney,
        servedInMilitary,
        updateServedInMilitary,
        givenFalseInformation,
        updateGivenFalseInformation,
        selectedGroup,
        selectedValue,
        updateSelectedOption,
        givenName,
        updateGivenName,
        middleName,
        updateMiddleName,
        familyName,
        updateFamilyName,
        haveUsedOtherNames,
        updateHaveUsedOtherNames,
        nameSets,
        handleAddAnother,
        handleRemove,
        setNameSets,
        cityOrTown,
        updateCityOrTown,
        provinceOrState,
        updateProvinceOrState,
        dateOfBirth,
        updateDateOfBirth,
        countries,
        selectedCountry,
        updateSelectedCountry,
        sex,
        updateSex,
        maritalStatus,
        updateMaritalStatus,
        uscisOnlineAccountNumber,
        updateUscisOnlineAccountNumber,
        aNumber,
        updateANumber,
        ssnIssued,
        updateSsnIssued,
        ssn,
        updateSsn,
        unknownSsn,
        updateUnknownSsn,
        replaceSsnCard,
        updateReplaceSsnCard,
        replaceSsnCard,
        updateReplaceSsnCard,
        authorizeDisclosureToSSA,
        updateAuthorizeDisclosureToSSA,
        fathersGivenName,
        updateFathersGivenName,
        fathersFamilyName,
        updateFathersFamilyName,
        mothersGivenName,
        updateMothersGivenName,
        mothersFamilyName,
        updateMothersFamilyName,
        // form3
        nameSetsCountry,
        handleAddAnotherCountry,
        handleRemoveCountry,
        updateStateCountry,
        countryNames, // Include countryNames in the context
        setGlobalCountryNames,
        setNameSetsCountry,
        handleOptionChange,
        selectedOption,
        travelDocument,
        setTravelDocument,
        expirationDate,
        setExpirationDate,
        countryOfIssuance,
        setCountryOfIssuance,
        handleSubmitI762,
        selectedSecondCountry,
         setSelectedSecondCountry,
        i94Number, 
        setI94Number,
        lastArrivalDate,
        updateLastArrivalDate,
        overstayedVisa,
        updateOverstayedVisa,
        selectedState,
        updateSelectedState,
        selectedOptionForState,
        handleChangeForState,
        selectedImmigrantStatusOption,
        handleChangeForimmigrationstatus,
        handelChangeRecentImmgigrationStatus,
        recentImmgigrationStatus,
        handelChangeRecentImmgigrationStatusSecond,
        recentImmgigrationStatusSecond,

        //Form 4

        inCareOfName,
        streetNumberName,
        apartmentSuiteFloor,
        enterNumber,
        cityOrTownFormFour,
        handleInCareOfNameChange,
        handleStreetNumberNameChange,
        handleApartmentSuiteFloorChange,
        handleEnterNumberChange,
        handleCityOrTownChangeFormFour,
        selectedStateFormFour,
        handleChangeState,
        countriesFormFour,
        zipCode,
         handleChangeZipCode ,
         isMailingAddressSame,
          handleChangeMailingAddress,
          handleChangeUsPhysicalAddress,
          handleChangeUsPhysicalApartmentSuiteFloor,
          handleChangeUsPhysicalEnterNumber,
          handleChangeUsPhysicalCityOrTown,
          handleChangeUsPhysicalSelectedState,
          handleChangeUsPhysicalZipCode,
          usPhysicalAddress,
          usPhysicalApartmentSuiteFloor,
          usPhysicalEnterNumber,
          usPhysicalCityOrTown,
          usPhysicalSelectedState,
          usPhysicalZipCode,
          usPhysicalCountries,
          countryCodes,
          selectedCountryCode,
          handleCountryCodeChange,
          daytimePhoneNumber,
          handleDaytimePhoneNumberChange,
          mobilePhoneNumber,
          handleMobilePhoneNumberChange,

          // last form

          previouslyFiledI765, 
        handlePreviouslyFiledI765Change,
        receivedHelpFromInterpreter,
         handleReceivedHelpFromInterpreterChange,
         interpreterLanguage,
         interpreterGivenName,
         interpreterFamilyName,
         interpreterBusinessOrOrganization,
         interpreterStreetNumberName,
         interpreterApartmentSuiteFloor,
         interpreterCityOrTown,
         selectedInterpreterCountry,
         interpretercountries,
         interpreterPostalCode,
         interpreterProvince,
         handleChangetimephonenumber,
         daytimephonenumber,
         handlePhoneNumberchange,
         phonenumber,
         handleChangeInterpreterLanguage,
         handleChangeInterpreterGivenName,
         handleChangeInterpreterFamilyName,
         handleChangeInterpreterBusinessOrOrganization,
         handleChangeInterpreterStreetNumberName,
         handleChangeInterpreterApartmentSuiteFloor,
         handleChangeInterpreterCityOrTown,
         handleChangeInterpreterPostalCode,
         handleChangeInterpreterProvince,
         handleSelectedInterpreterCountry,
         handlenumber,
         number,
         handleMobileNumberChange,
         mobilephonenumber,
         handlePhoneNumberschange,
         phonenumbers,
         handleChangeEmailAddress,
         emailAddress,
         handleChangeInterpreted,
         isInterpreted,
         handlePreparerTypeChange,
         preparerType,
         representationType,
         handleRepresentationTypeChange,
         handleUscisOnlineAccountNumberChange,
         uscisOnlineAccountNumbers,
         preparerFamilyName,
         handlePreparerFamilyNameChange,
         preparerGivenName,
         handlePreparerGivenNameChange,
         preparerBusinessOrOrganization,
         handlePreparerBusinessOrOrganizationChange,
         handleAttachFormG28Change,
         attachFormG28,
         handlePreparerCityOrTownChange,
         handlePreparerStreetNumberNameChange,
         handlePreparerHasApartmentChange,
         handlePreparerPostalCodeChange,
         handlePreparerProvinceChange,
         handlePreparerCountryChange,
         preparerHasApartment,
         preparerPostalCode,
         preparerProvince,
         handlePreparerCountryChange,
         preparerCountry,
         preparerCityOrTown,
         handlePreparerCountryChange,
         preparerStreetNumberName,
         handleDaytimePhoneNumberChanger,
         handleMobilePhoneNumberChanger,
         preparersContactInfo
        
        
     
         

      }}
    >
      {children}
    </ContextFor1762.Provider>
  );
};

export { ContextFor1762, PermissionProvider };
