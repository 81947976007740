import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";

const I8649 = () => {
  const navigate=useNavigate()
  const { formData, handleInputChange } = useFormContext();
  const maxLength = 50;
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };

return(
    <div className="w-full">
    <Header />
    <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
    <div
        className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
        style={{ backgroundColor: "#0072bc" }}
      >
        <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
          verified and security
        </span>
        <span className="font-bold text-white text-xl formheaderfont">
          USCIS Form I-864 - Affidavit of Support Package
        </span>
        <div className="h-full flex justify-end">
          {" "}
          {/* Added 'flex' and 'justify-end' */}
          <button
            className="text-white px-2 bg-blue-900 h-full border border-black"
            style={{ textAlign: "left" }}
          >
            Save
          </button>
          <button className="text-white px-2 bg-blue-900 h-full">Help</button>
        </div>
      </div>
   
      <div
        className="progress-description pt-3 pt-lg-0"
        id="progress-description"
      >
        <h2 style={{ marginBottom: "-1%" }}>
          {" "}
          Interpreter & Preparer
        </h2>
<p className="ml-3">This is the final page. You're almost done.
</p>
      </div>
      <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
 
      <form onSubmit={handleSubmit}  className="mt-6 w-full ">
      <div className="flex flex-col items-right space-y-0 p-0">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>      Interpreter


</b>
              </h3>
            </span>
       
          </div>


      <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              Did you receive help from an interpreter to prepare this affidavit?
              </div>
              <div className="form">
                <p className="p-2"></p>
                <input
                  type="radio"
                  name="affidavit"
                  value="Yes"
                  id="inUSYess"
                  onChange={handleInputChange}
                  checked={formData.affidavit === "Yes"}
                />
                <label htmlFor="inUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="affidavit"
                  value="No"
                  id="inUSNos"
                  onChange={handleInputChange}
                  checked={formData.affidavit === "No"}
                />
                <label htmlFor="inUSNos">No</label>
                {/* <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                   If you answer "Yes," we will provide you a reminder in your filing instructions.


                  </div> */}
                  <br />
                  <br />

              </div>
              
            </div>
<br />

            {formData.affidavit==="Yes"?(
<div >
<div><h3 class="heading33 ml-12">Interpreter's Information
</h3></div>

<div className="flex justify-left ">
      <div className="max-w-md  forms">
        <div className="flex ">
          <label htmlFor="line1"  >
          Which language was used to translate into English ? 
          <span className="text-red-500">*</span>&nbsp;   </label>
          <input
            class="form-control"
            aria-required="true"
            required
            name="lang"
            value={formData.lang}
            onChange={handleInputChange}
            autocomplete="section-P5_Physical_Address_Domestic address-level1"
            id="line1"
            style={{ width: "100%",borderRadius:"5px"}}

            className="flex-grow border px-2 py-1 focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
          />
        </div>

    
      </div>
      
    </div>
    <div className="forms">
              <label htmlFor="givenName">
                Given Name (First Name): <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <input
                id="givenName"
                type="text"
                name="Ifirstname"
                maxLength={18}
                value={formData.Ifirstname} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp; <br />
              <label htmlFor="Middle">
                Middle Name(s):<span className="text-red-500">*</span>{" "}
                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </label>
              &nbsp; &nbsp;
              <input
                id="Middle"
                type="text"
                name="IMiddlename"
                maxLength={18}
                value={formData.IMiddlename} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              <br />
              <label htmlFor="family">
              Firm or Organization Name <br /> (if any)<span className="text-red-500">*</span>{" "}
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
            
              &nbsp; &nbsp;&nbsp;
              

              

              <input
                id="family"
                type="text"
                name="IFamilyname"
                maxLength={28}
                value={formData.IFamilyname} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <br />
            <h3 class="heading33 ml-12">
            Interpreter’s Mailing Address
                </h3>

                <div className="flex flex-col">
              <div className="forms">
               
             
                <label htmlFor="streetNamee">
                  Street Number and Name:{" "}
                  <span className="text-red-500">*</span>
                </label>
                &nbsp; &nbsp;
                <input
                  id="streetNamee"
                  type="text"
                  name="Interpreterstreetnum"
                  maxLength={maxLength}
                  value={formData.Interpreterstreetnum}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                <br /> <br />
                <label htmlFor="appartment">
                  Is there an apartment, suite <br /> or floor?{" "}
                  <span className="text-red-500">*</span> &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </label>
                <select
                  class="form-control1"
                  aria-required="true"
                  required="required"
                  name="Interpreterappartmentmail"
                  value={formData.Interpreterappartmentmail}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="appartment"
                >
                  <option value="no">No</option>
                  <option value="Apt">Apt</option>
                  <option value="Ste">Ste</option>
                  <option value="Flr">Flr</option>
                </select>
                <br />{" "}
                {formData.Interpreterappartmentmail === "Apt" ||
                formData.Interpreterappartmentmail === "Ste" ||
                formData.Interpreterappartmentmail === "Flr" ? (
                  <div className="num-input-wrapper">
                    <br />{" "}
                    <label htmlFor="numb">
                      Enter the number here:
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                    <input
                      id="numb"
                      type="text"
                      name="Interpreternumb"
                      maxLength={15}
                      value={formData.Interpreternumb}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        width: "10%",
                      }}
                    />
                  </div>
                ) : //-----------------------------------------------------------------------
                null}
                <br />
                <label htmlFor="cityy">
                  City or Town : <span className="text-red-500">* </span>{" "}
                </label>{" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;{" "}
                <input
                  id="cityy"
                  type="text"
                  name="InterpreterCities"
                  maxLength={maxLength}
                  value={formData.InterpreterCities}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                &nbsp; <br /> <br />
                <label htmlFor="countSelects">
                  Country : <span className="text-red-500"> * </span>{" "}
                </label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <select
                  id="countSelects"
                  value={formData.InterpreterCountry}
                  onChange={handleInputChange}
                  class="form-control"
                  name="InterpreterCountry"
                  aria-required="true"
                  required="required"
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                >
                  <option value="US">United States</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Canada">Canada</option>
                </select>
                <br />
                <br />
                {formData.PrincipalCountry === "US" ? (
                  <div>
                    <label htmlFor="stateSelecst">
                      State (or Territory):
                      <span className="text-red-500">*</span>{" "}
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      id="stateSelects"
                      name="InterpreterState"
                      value={formData.InterpreterState}
                      onChange={handleInputChange}
                      class="form-control"
                      aria-required="true"
                      required="required"
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                    >
                      <option value="" selected="selected">
                        Please select...
                      </option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                      <option value="U.S. Armed Forces - America (AA)">
                        U.S. Armed Forces - America (AA)
                      </option>
                      <option value="U.S. Armed Forces - Europe (AE)">
                        U.S. Armed Forces - Europe (AE)
                      </option>
                      <option value="U.S. Armed Forces - Pacific (AP)">
                        U.S. Armed Forces - Pacific (AP)
                      </option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Guam">Guam</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Palau">Palau</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Swains Island">Swains Island</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                    </select>
                    <br /> <br />
                    <label htmlFor="Zips">
                      ZIP Code : <span className="text-red-500">*</span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="Zips"
                      type="text"
                      name="InterpreterZips"
                      maxLength={8}
                      value={formData.InterpreterZips}
                      onChange={handleInputChange}
                      placeholder="####"
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        width: "10%",
                      }}
                    />
                  </div>
                ) : null}
                {formData.InterpreterCountry === "Mexico" ||
                formData.InterpreterCountry === "Canada" ? (
                  <div>
                    <label htmlFor="Province">
                      Province : <span className="text-red-500">* </span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="Province"
                      type="text"
                      name="InterpreterProvince"
                      maxLength={20}
                      value={formData.InterpreterProvince}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    &nbsp; <br /> <br />
                    <label htmlFor="post">
                      Postal Code <span className="text-red-500">* </span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="text"
                      className="p-1"
                      id="post"
                      name="Interpreterpostal"
                      value={formData.Interpreterpostal}
                      onChange={handleInputChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
<br />
            <div><h3 class="heading33 ml-12">Interpreter's Information
</h3></div>
<div className="forms">
              <label htmlFor="Daytime">
                Daytime Phone Number
                <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{" "}
              <input
                id="Daytime"
                name="Daytimephone"
                type="tel"
                value={formData.Daytimephone}
                onChange={handleInputChange}
                pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                className="p-2 mt-2 mb-2 rounded border"
                placeholder="(123) 456-7890"
                required="required"
              />
              <br />
              <label htmlFor="mobphone">
                Mobile Phone Number <i>(if any)</i>
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;
              <input
                id="mobphone"
                name="mobilephone"
                type="tel"
                value={formData.mobilephone}
                onChange={handleInputChange}
                pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                className="p-2 mt-2 mb-2 rounded border"
                placeholder="(123) 456-7890"
              />
              <br />
              <label htmlFor="mobphone">
                E-mail<i> (if any)</i>
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
              <input
                id="emails"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                className="p-2 mt-2 mb-2 rounded border "
                placeholder="user@example.com"
                style={{ width: "29%" }}
              />
            </div>
            <div className="forms flex items-center">
                <label>
                  <input
                    type="checkbox"
                    name="ischecked"
                    required="required"
                    checked={formData.ischecked}
                    onChange={handleInputChange}
                  />
                  &nbsp;
The interpreter named above read to me each and every question and instruction on this form as well as my answer to every question in a language in which I am fluent.
 I understand each and every question and instruction on this form, as well as my answer to each question.
                  <span className="text-red-500">*</span>
                </label>
              </div>
              {!formData.ischecked && (
                <span className="text-red-500 " style={{ marginLeft: "30%" }}>
                  This field is required.
                </span>
              )}
</div>

            ):null}

            {formData.affidavit==="No"?(
                <div>
                     <div className="forms flex items-center">
                <label>
                  <input
                    type="checkbox"
                    name="ischecked"
                    required="required"
                    checked={formData.ischecked}
                    onChange={handleInputChange}
                  />
                  &nbsp;
                  I can read and understand English, and have read and understand each and every question and instruction on this form, as well as my answer to <br />every question.
                  <span className="text-red-500">*</span>
                </label>
              </div>
              {!formData.ischecked && (
                <span className="text-red-500 " style={{ marginLeft: "30%" }}>
                  This field is required.
                </span>
              )}
                </div>
            ):null}
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />

            <div className="flex flex-col items-right space-y-0 p-0">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>      Preparer



</b>
              </h3>
            </span>
       
          </div>
          <div className="additional-div">

<div className="mt-2 font-thin ml-2 text ndRendring">
Who prepared this Form I-864, Affidavit of Support?

        <span className="text-red-500">*</span>
      </div>
      <div className="form">
        <input
          type="radio"
          name="prepared"
          value="sponsor and prepared this affidavit"
          id="lostRadio"
          onChange={handleInputChange}
          checked={formData.prepared === "sponsor and prepared this affidavit"}
        />
        <label htmlFor="lostRadio">
        I am a sponsor, and I prepared this affidavit.            </label>
        <hr />
        <input
          type="radio"
          name="prepared"
          value="accredited representative but have prepared this affidavit on behalf of the sponsor"
          id="notReceivedRadio"
          onChange={handleInputChange}
          checked={formData.prepared === "accredited representative but have prepared this affidavit on behalf of the sponsor"}
        />
        <label htmlFor="notReceivedRadio">
        I am not an attorney or accredited representative but have prepared this affidavit on behalf of the sponsor and with the sponsor's consent.        </label>
        <hr />
        <input
          type="radio"
          name="prepared"
          value=" accredited representative"
          id="mutilatedRadio"
          onChange={handleInputChange}
          checked={formData.prepared === " accredited representative"}
        />
        <label htmlFor="mutilatedRadio">
        I am an attorney or accredited representative of the sponsor that prepared this affidavit.

        </label>
        <hr />
        
      </div>
      </div>

      {formData.prepared===" accredited representative"?(
           <div className="additional-div">

           <div className="mt-2 font-thin ml-2 text ndRendring">
           My representation of the sponsor in this case:
           
                   <span className="text-red-500">*</span>
                 </div>
                 <div className="form">
                   <input
                     type="radio"
                     name="representation"
                     value="Extends beyond the preparation of this affidavit."
                     id="a"
                     onChange={handleInputChange}
                     checked={formData.representation === "Extends beyond the preparation of this affidavit."}
                   />
                   <label htmlFor="a">
                   Extends beyond the preparation of this affidavit.
            </label>
                   <hr />
                   <input
                     type="radio"
                     name="representation"
                     value="Does not extend Does not extend beyond the preparation of this affidavit."
                     id="aa"
                     onChange={handleInputChange}
                     checked={formData.representation === "Does not extend Does not extend beyond the preparation of this affidavit."}
                   />
                   <label htmlFor="aa">
                   Does not extend Does not extend beyond the preparation of this affidavit.
                                       </label>
                   <hr />
                  
               
                   
                 </div>
                 </div>
      ):null}
{formData.prepared==="accredited representative but have prepared this affidavit on behalf of the sponsor"||formData.representation === "Does not extend Does not extend beyond the preparation of this affidavit."||formData.representation==="Extends beyond the preparation of this affidavit."?(
    <div >
        <br /> <br />
    <div><h3 class="heading33 ml-12"> <b>Preparer's  Information</b>
    </h3></div>
    

        <div className="forms">
                  <label htmlFor="givenNamec">
                    Given Name (First Name): <span className="text-red-500">*</span>
                  </label>
                  &nbsp; &nbsp;
                  <input
                    id="givenNamec"
                    type="text"
                    name="Preparerfirstname"
                    maxLength={18}
                    value={formData.Preparerfirstname} // Replace with your formData field
                    onChange={handleInputChange} // Add the appropriate field name
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      borderRadius: "5px",
                    }}
                  />
                  &nbsp; <br />
                  <label htmlFor="Middlec">
                    Middle Name(s):<span className="text-red-500">*</span>{" "}
                    &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  </label>
                  &nbsp; &nbsp;
                  <input
                    id="Middlec"
                    type="text"
                    name="PreparerMiddlename"
                    maxLength={28}
                    value={formData.PreparerMiddlename} // Replace with your formData field
                    onChange={handleInputChange} // Add the appropriate field name
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      borderRadius: "5px",
                    }}
                  />
                  <br />
                  <label htmlFor="familyc">
                  Firm or Organization Name <br /> (if any)<span className="text-red-500">*</span>{" "}
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;
                
                  &nbsp; &nbsp;&nbsp;
             
                  <input
                    id="familyc"
                    type="text"
                    name="PreparerFamilyname"
                    maxLength={28}
                    value={formData.PreparerFamilyname} // Replace with your formData field
                    onChange={handleInputChange} // Add the appropriate field name
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      borderRadius: "5px",
                    }}
                  />
                  {formData.representation==="Extends beyond the preparation of this affidavit."?(

<div>

&nbsp; <br />
                  <label htmlFor="Attorney">
                  Attorney State Bar Number<span className="text-red-500">*</span>
                  </label>
                  &nbsp; &nbsp;
                  <input
                    id="Attorney"
                    type="text"
                    name="Attorney"
                    maxLength={28}
                    value={formData.Attorney} // Replace with your formData field
                    onChange={handleInputChange} // Add the appropriate field name
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      borderRadius: "5px",
                    }}
                  />
                  <br />

                  <br />
                  <label htmlFor=" USCISAccount">
                  USCIS Online Account <br /> Number<span className="text-red-500">*</span>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "} &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </label>
                 
                  <input
                    id=" USCISAccount"
                    type="text"
                    name=" USCISAccount"
                    maxLength={12}
                    value={formData. USCISAccount} // Replace with your formData field
                    onChange={handleInputChange} // Add the appropriate field name
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      borderRadius: "5px",
                    }}
                    placeholder="#########"

                  />
                  <br />
</div>
                  ):null}
                </div>
                <br />
                <h3 class="heading33 ml-12">
               <b> Preparer’s Mailing Address</b>
                    </h3>
    
                    <div className="flex flex-col">
                  <div className="forms">
                   
                 
                    <label htmlFor="streetNameea">
                      Street Number and Name:{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    &nbsp; &nbsp;
                    <input
                      id="streetNameea"
                      type="text"
                      name="Preparerstreetnum"
                      maxLength={maxLength}
                      value={formData.Interpreterstreetnum}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    <br /> <br />
                    <label htmlFor="Preparerappartment">
                      Is there an apartment, suite <br /> or floor?{" "}
                      <span className="text-red-500">*</span> &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp;
                    </label>
                    <select
                      class="form-control1"
                      aria-required="true"
                      required="required"
                      name="Preparerappartmentmail"
                      value={formData.Preparerappartmentmail}
                      onChange={handleInputChange}
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                      id="Preparerappartment"
                    >
                      <option value="no">No</option>
                      <option value="Apt">Apt</option>
                      <option value="Ste">Ste</option>
                      <option value="Flr">Flr</option>
                    </select>
                    <br />{" "}
                    {formData.Preparerappartmentmail === "Apt" ||
                    formData.Preparerappartmentmail === "Ste" ||
                    formData.Preparerappartmentmail === "Flr" ? (
                      <div className="num-input-wrapper">
                        <br />{" "}
                        <label htmlFor="numba">
                          Enter the number here:
                          <span className="text-red-500">*</span>
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                        <input
                          id="numba"
                          type="text"
                          name="Preparernumb"
                          maxLength={15}
                          value={formData.Preparernumb}
                          onChange={handleInputChange}
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                            width: "10%",
                          }}
                        />
                      </div>
                    ) : //-----------------------------------------------------------------------
                    null}
                    <br />
                    <label htmlFor="cityys">
                      City or Town : <span className="text-red-500">* </span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;{" "}
                    <input
                      id="cityys"
                      type="text"
                      name="PreparerCities"
                      maxLength={maxLength}
                      value={formData.PreparerCities}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    &nbsp; <br /> <br />
                    <label htmlFor="countSelectsa">
                      Country : <span className="text-red-500"> * </span>{" "}
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      id="countSelectsa"
                      value={formData.PreparerCountry}
                      onChange={handleInputChange}
                      class="form-control"
                      name="PreparerCountry"
                      aria-required="true"
                      required="required"
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                    >
                      <option value="US">United States</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Canada">Canada</option>
                    </select>
                    <br />
                    <br />
                    {formData.PreparerCountry === "US" ? (
                      <div>
                        <label htmlFor="stateSelecsts">
                          State (or Territory):
                          <span className="text-red-500">*</span>{" "}
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <select
                          id="stateSelecsts"
                          name="PreparerState"
                          value={formData.PreparerState}
                          onChange={handleInputChange}
                          class="form-control"
                          aria-required="true"
                          required="required"
                          autocomplete="section-P5_Physical_Address_Domestic address-level1"
                        >
                          <option value="" selected="selected">
                            Please select...
                          </option>
                          <option value="Alabama">Alabama</option>
                          <option value="Alaska">Alaska</option>
                          <option value="Arizona">Arizona</option>
                          <option value="Arkansas">Arkansas</option>
                          <option value="California">California</option>
                          <option value="Colorado">Colorado</option>
                          <option value="Connecticut">Connecticut</option>
                          <option value="Delaware">Delaware</option>
                          <option value="District of Columbia">
                            District of Columbia
                          </option>
                          <option value="Florida">Florida</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Idaho">Idaho</option>
                          <option value="Illinois">Illinois</option>
                          <option value="Indiana">Indiana</option>
                          <option value="Iowa">Iowa</option>
                          <option value="Kansas">Kansas</option>
                          <option value="Kentucky">Kentucky</option>
                          <option value="Louisiana">Louisiana</option>
                          <option value="Maine">Maine</option>
                          <option value="Maryland">Maryland</option>
                          <option value="Massachusetts">Massachusetts</option>
                          <option value="Michigan">Michigan</option>
                          <option value="Minnesota">Minnesota</option>
                          <option value="Mississippi">Mississippi</option>
                          <option value="Missouri">Missouri</option>
                          <option value="Montana">Montana</option>
                          <option value="Nebraska">Nebraska</option>
                          <option value="Nevada">Nevada</option>
                          <option value="New Hampshire">New Hampshire</option>
                          <option value="New Jersey">New Jersey</option>
                          <option value="New Mexico">New Mexico</option>
                          <option value="New York">New York</option>
                          <option value="North Carolina">North Carolina</option>
                          <option value="North Dakota">North Dakota</option>
                          <option value="Ohio">Ohio</option>
                          <option value="Oklahoma">Oklahoma</option>
                          <option value="Oregon">Oregon</option>
                          <option value="Pennsylvania">Pennsylvania</option>
                          <option value="Rhode Island">Rhode Island</option>
                          <option value="South Carolina">South Carolina</option>
                          <option value="South Dakota">South Dakota</option>
                          <option value="Tennessee">Tennessee</option>
                          <option value="Texas">Texas</option>
                          <option value="Utah">Utah</option>
                          <option value="Vermont">Vermont</option>
                          <option value="Virginia">Virginia</option>
                          <option value="Washington">Washington</option>
                          <option value="West Virginia">West Virginia</option>
                          <option value="Wisconsin">Wisconsin</option>
                          <option value="Wyoming">Wyoming</option>
                          <option value="U.S. Armed Forces - America (AA)">
                            U.S. Armed Forces - America (AA)
                          </option>
                          <option value="U.S. Armed Forces - Europe (AE)">
                            U.S. Armed Forces - Europe (AE)
                          </option>
                          <option value="U.S. Armed Forces - Pacific (AP)">
                            U.S. Armed Forces - Pacific (AP)
                          </option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Guam">Guam</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Palau">Palau</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Swains Island">Swains Island</option>
                          <option value="Virgin Islands">Virgin Islands</option>
                        </select>
                        <br /> <br />
                        <label htmlFor="Zipss">
                          ZIP Code : <span className="text-red-500">*</span>{" "}
                        </label>{" "}
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                        <input
                          id="Zipss"
                          type="text"
                          name="PreparerZips"
                          maxLength={8}
                          value={formData.PreparerZips}
                          onChange={handleInputChange}
                          placeholder="####"
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                            width: "10%",
                          }}
                        />
                      </div>
                    ) : null}
                    {formData.PreparerCountry === "Mexico" ||
                    formData.PreparerCountry === "Canada" ? (
                      <div>
                        <label htmlFor="Provinces">
                          Province : <span className="text-red-500">* </span>{" "}
                        </label>{" "}
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                        <input
                          id="Provinces"
                          type="text"
                          name="PreparerProvince"
                          maxLength={20}
                          value={formData.PreparerProvince}
                          onChange={handleInputChange}
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        &nbsp; <br /> <br />
                        <label htmlFor="posts">
                          Postal Code <span className="text-red-500">* </span>
                        </label>{" "}
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                          type="text"
                          className="p-1"
                          id="posts"
                          name="Preparerpostal"
                          value={formData.Preparerpostal}
                          onChange={handleInputChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
    <br />
                <div><h3 class="heading33 ml-12">Preparer's Information
    </h3></div>
    <div className="forms">
                  <label htmlFor="Daytimes">
                    Daytime Phone Number
                    <span className="text-red-500">*</span>
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <input
                    id="Daytimes"
                    name="PreparerDaytimephone"
                    type="tel"
                    value={formData.PreparerDaytimephone}
                    onChange={handleInputChange}
                    pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                    className="p-2 mt-2 mb-2 rounded border"
                    placeholder="(123) 456-7890"
                    required="required"
                  />
                  <br />
                  <label htmlFor="mobphones">
                    Mobile Phone Number <i>(if any)</i>
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;
                  <input
                    id="mobphones"
                    name="Preparermobilephone"
                    type="tel"
                    value={formData.Preparermobilephone}
                    onChange={handleInputChange}
                    pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                    className="p-2 mt-2 mb-2 rounded border"
                    placeholder="(123) 456-7890"
                  />
                  <br />
                  <label htmlFor="emailss">
                    E-mail<i> (if any)</i>
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                  <input
                    id="emailss"
                    name="Prepareremail"
                    type="email"
                    value={formData.Prepareremail}
                    onChange={handleInputChange}
                    className="p-2 mt-2 mb-2 rounded border "
                    placeholder="user@example.com"
                    style={{ width: "29%" }}
                  />
                </div>
              
                  
    </div>
    
):null}
            <br />
          


<br />
<br />

            <div className="w-full ml-8">
            <button
              type="button"
              className="float-left previous text-white px-4 py-2 rounded mt-4"
              style={{ background: "rgb(187, 187, 187)" }}
            >
              ← PREV
            </button>
            <br />
            <button
              type="submit"
              className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
              onClick={()=>navigate()}
            >
              NEXT →
            </button>
          </div>
      </form >

      </div>
      <FormFotter />

    </div>
 
              </div>
);}
export default I8649;