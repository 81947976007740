import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context for the form state
const FormContext90Forms = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContext90Forms);
}

// Define the initial state
const initialState = {
  formData: {
    Daytimephone: "",
        mobilephone: "",
        email: "",
        ssnum: "",
        ssnumfoc: false,
        accnum: "",
        accnumfoc: false,
        help: "",
        language: "",
        FirstName: "",
        LastName: "",
        BusinessName: "",
        mailAdress: "no",
        care: "",
        streetnum: "",
        streetnumfoc: false,
        ischecked:false,
        appartmentmail: "no",
        numb: "",
        numbfoc:false,
        Cites: "",
        CitiesFocused:false,
        Country: "",
        State: "",
        Statefoc: false,
        province:"",
        focprovince:false,
        postcode: "",
        postcodefoc:false,
        InterpreterNumber:"" ,
        InterpreterNumber2:"" ,
        Interpreteremail:"",
        Whoprepared:"",
        Zip:"",
// Preparer's Information
PrepFirstName: "",
PrepLastName: "",
PrepBusinessName: "",

// Mailing Address
Prepstreetnum: "",
Prepappartmentmail: "no",
Prepapartment: "",
PrepNum: "",
focPrepNum: false,

PrepCites: "",
focPrepCites:false,

PrepCountry: "US",
PrepState: "PS",
Prepprovince: "",
Preppostcode: "",
PrepZip:"",
focPrepZip:false,
focPreppostcode:false,
focPrepprovince:false,
focStatefoc:false,

PrepNumb1:"",
focPrepNumb1:false,
PrepNumb2:"",
focPrepNumb2:false,
prepemail:"",
requesting :"",

representation:"",
deaf:false,
blind:false,
disability:false
,
deafreason:"",
blindreason:"",
disabilityreason:""


  },



  
  };

// Create a provider component to manage the state
export function FormProviderFor90Form({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);
  const [selectedCount, setSelectedCount] = useState(0);
  



  const handleSelectionChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setSelectedCount(count);

    // Initialize nameSets array based on the selected count
    const newSets = Array.from({ length: count }, (_, index) => ({ id: index + 1 }));
    setNameSets(newSets);
  };




  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };
  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text': // Include input type "text"
        case 'number': // Include input type "number"
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one': // Handle select option
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      };
    });
  };
 
  return (
    <FormContext90Forms.Provider value={{ formData, handleInputChange}}>
      {children}
    </FormContext90Forms.Provider>
  );
}