


import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context751';
import  crosspic from "../../Assets/crosspic.png"


const PreparerFor751 = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitI751} = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("")
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>


     <div>
      {/* Are you requesting an accommodation? */}
      <div className="mb-4 flex flex-col mt-10">
        <label className="bg-blue-700  text-white p-4 rounded">
          Are you requesting an accommodation because of a disability and/or impairment?
        </label>
        <div className="flex bg-blue-100  p-4">
          <label htmlFor="requestingAccommodationYes" className="text-blue-700">
            <input
              type="radio"
              id="requestingAccommodationYes"
              name="requestingAccommodation"
              value="Yes"
              checked={formData.requestingAccommodation === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label htmlFor="requestingAccommodationNo" className="text-blue-700 ml-4">
            <input
              type="radio"
              id="requestingAccommodationNo"
              name="requestingAccommodation"
              value="No"
              checked={formData.requestingAccommodation === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>

      {/* Because of my disability(ies) and/or impairment(s) */}
      <div className="flex flex-col  mt-10">
        <label className="bg-blue-700  text-white p-4 rounded">
          Because of my disability(ies) and/or impairment(s).
        </label>
        <div className="flex bg-blue-100 p-4">
          <label htmlFor="forMyDisabilitiesYes" className="text-blue-700">
            <input
              type="radio"
              id="forMyDisabilitiesYes"
              name="forMyDisabilities"
              value="Yes"
              checked={formData.forMyDisabilities === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label htmlFor="forMyDisabilitiesNo" className="text-blue-700 ml-4">
            <input
              type="radio"
              id="forMyDisabilitiesNo"
              name="forMyDisabilities"
              value="No"
              checked={formData.forMyDisabilities === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>

      {/* For my spouse because of his or her disability(ies) and/or impairments */}
      <div className=" flex flex-col mt-10 ">
        <label className="bg-blue-700  text-white p-4 rounded">
          For my spouse because of his or her disability(ies) and/or impairments.
        </label>
        <div className="flex  bg-blue-100 p-4 rounded">
          <label htmlFor="forSpouseDisabilitiesYes" className="text-blue-700">
            <input
              type="radio"
              id="forSpouseDisabilitiesYes"
              name="forSpouseDisabilities"
              value="Yes"
              checked={formData.forSpouseDisabilities === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label htmlFor="forSpouseDisabilitiesNo" className="text-blue-700 ml-4">
            <input
              type="radio"
              id="forSpouseDisabilitiesNo"
              name="forSpouseDisabilities"
              value="No"
              checked={formData.forSpouseDisabilities === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>

      {/* For my included child(ren) because of his or her (their) disability(ies) and/or impairments */}
      <div className="flex  flex-col mt-10">
        <label className="bg-blue-700 text-white p-4 rounded ">
          For my included child(ren) because of his or her (their) disability(ies) and/or impairments.
        </label>
        <div className="flex bg-blue-100 p-4">
          <label htmlFor="forChildDisabilitiesYes" className="text-blue-700">
            <input
              type="radio"
              id="forChildDisabilitiesYes"
              name="forChildDisabilities"
              value="Yes"
              checked={formData.forChildDisabilities === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label htmlFor="forChildDisabilitiesNo" className="text-blue-700 ml-4">
            <input
              type="radio"
              id="forChildDisabilitiesNo"
              name="forChildDisabilities"
              value="No"
              checked={formData.forChildDisabilities === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>

      {/* Deaf or hard of hearing checkbox */}
      <div className="mb-4 flex mt-10">
        <label className="text-blue-700 w-[300px]">
          Deaf or hard of hearing
        </label>
        <div className="flex items-center ml-2">
          <input
            type="checkbox"
            id="deafOrHardOfHearing"
            name="deafOrHardOfHearing"
            checked={formData.deafOrHardOfHearing}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {/* Blind or sight-impaired checkbox */}
      <div className="mb-4 flex mt-6">
        <label className="text-blue-700 w-[300px]">
          Blind or sight-impaired
        </label>
        <div className="flex items-center ml-2">
          <input
            type="checkbox"
            id="blindOrSightImpaired"
            name="blindOrSightImpaired"
            checked={formData.blindOrSightImpaired}
            onChange={handleInputChange}
          />
        </div>
      </div>

      {/* Other type of disability(ies) and/or impairment(s) checkbox */}
      <div className="mb-4 flex mt-6">
        <label className="text-blue-700 w-[300px]">
          Other type of disability(ies) and/or impairment(s)
        </label>
        <div className="flex items-center ml-2">
          <input
            type="checkbox"
            id="otherDisabilities"
            name="otherDisabilities"
            checked={formData.otherDisabilities}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>


    <hr className='bg-blue-600 h-1 mt-10'/>



    <div className="mb-4 flex flex-col mt-10">
      <label className="bg-blue-700  text-white p-4">
        Did the conditional resident or the petitioning spouse receive help from an interpreter to prepare this petition?
      </label>
      <div className="flex flex-col gap-2 bg-blue-100 p-4">
        {/* Option: Yes, only the conditional resident used an interpreter. */}
        <label htmlFor="interpreterUsedResident" className="text-blue-700">
          <input
            type="radio"
            id="interpreterUsedResident"
            name="usedInterpreter"
            value="YesResident"
            checked={formData.usedInterpreter === 'YesResident'}
            onChange={handleInputChange}
          />
          Yes, only the conditional resident used an interpreter.
        </label>

        {/* Option: Yes, only the petitioning spouse used an interpreter. */}
        <label htmlFor="interpreterUsedSpouse" className="text-blue-700 ">
          <input
            type="radio"
            id="interpreterUsedSpouse"
            name="usedInterpreter"
            value="YesSpouse"
            checked={formData.usedInterpreter === 'YesSpouse'}
            onChange={handleInputChange}
          />
          Yes, only the petitioning spouse used an interpreter.
        </label>

        {/* Option: Yes, both used an interpreter. */}
        <label htmlFor="interpreterUsedBoth" className="text-blue-700 ">
          <input
            type="radio"
            id="interpreterUsedBoth"
            name="usedInterpreter"
            value="YesBoth"
            checked={formData.usedInterpreter === 'YesBoth'}
            onChange={handleInputChange}
          />
          Yes, both used an interpreter.
        </label>

        {/* Option: No */}
        <label htmlFor="interpreterUsedNo" className="text-blue-700 ">
          <input
            type="radio"
            id="interpreterUsedNo"
            name="usedInterpreter"
            value="No"
            checked={formData.usedInterpreter === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>

      


<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
               onClick={handleSubmitI751}
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default PreparerFor751