import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContextN400 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContextN400);
}

// Define the initial state
const initialState = {
    parentUSCitizen: '',
    parentUSCitizenBefore18: '',
    isAtLeast18: '',
    currentStatus: '',
    placeOfBirth: '',
    becomeResidentOfState: '',
    tripsOutsideUSLast6Months: '',
  physicallyPresentInUSLast30Months: '',
  tripsOutsideUSLast5Years: '',
    
  
  };

// Create a provider component to manage the state
export function FormProviderForN400({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate
              ? e.target.valueAsDate.toISOString().slice(0, 10)
              : '',
          };
        case 'text':
        case 'number':
        case 'textarea': // Include textarea input type
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: checked,
          };
        case 'select-one':
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      }
    });
  };


  const mapFormFields = () => {
    let mapping = {};

    // Map the values for the provided fields in Form1 and Form2 to the given PDF fields
    mapping["form1[0].#subform[16].P14_Line3_Unit[2]"] = true;
mapping["form1[0].#subform[16].P14_Line3_Country[0]"] = "United States";
mapping["form1[0].#subform[16].P14_Line3_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[16].P14_Line3_ZipLastFour[0]"] = "6789";
mapping["form1[0].#subform[16].P14_Line1_nterpreterGivenName[0]"] = "John";
mapping["form1[0].#subform[16].P14_Line1_nterpreterFamilyName[0]"] = "Doe";
mapping["form1[0].#subform[17].P14_Line5_EmailAddress[0]"] = "john.doe@example.com";
mapping["form1[0].#subform[17].P14_Line4_Telephone[0]"] = "5551234567";
mapping["form1[0].#pageSet[0].Page1[0].PDF417BarCode1[0]"] = "ExampleValue1";
mapping["form1[0].#pageSet[0].Page1[1].PDF417BarCode1[0]"] = "ExampleValue2";
// ... (continue with other barcodes)
mapping["form1[0].#subform[0].Part1_Eligibility[0]"] = true;
mapping["form1[0].#subform[0].Part1_Eligibility[1]"] = false;
mapping["form1[0].#subform[0].Part1_Eligibility[2]"] = true;
mapping["form1[0].#subform[0].Part1_Eligibility[3]"] = false;
mapping["form1[0].#subform[0].Part1_Eligibility[4]"] = true;
mapping["form1[0].#subform[0].Part1Line5_OtherExplain[0]"] = "ExampleDropdownValue";
mapping["form1[0].#subform[0].P2_Line1_MiddleName[0]"] = "John";
mapping["form1[0].#subform[0].P2_Line1_GivenName[0]"] = "Marie";
mapping["form1[0].#subform[0].P2_Line1_FamilyName[0]"] = "Doe";
mapping["form1[0].#subform[0].Line2_FamilyName[0]"] = "Smith";
mapping["form1[0].#subform[0].Line2_GivenName[0]"] = "Alice";
mapping["form1[0].#subform[0].Line2_MiddleName[0]"] = "Bob";
mapping["form1[0].#subform[0].#area[0].Line1_AlienNumber[0]"] = "123456789";
mapping["form1[0].#subform[0].P1_Line1c[0]"] = "ExampleDropdownValue";
mapping["form1[0].#subform[1].Part2Line4a_MiddleName[0]"] = "Michael";
mapping["form1[0].#subform[1].Part2Line4a_GivenName[0]"] = "Olivia";
mapping["form1[0].#subform[1].Part2Line4a_FamilyName[0]"] = "Doe";
mapping["form1[0].#subform[1].P2_Line4a_NameChange[0]"] = true;
mapping["form1[0].#subform[1].P2_Line4a_NameChange[1]"] = false;
mapping["form1[0].#subform[1].P2_Line9_DateBecamePermanentResident[0]"] = "2023-11-15";
mapping["form1[0].#subform[1].P2_Line8_DateOfBirth[0]"] = "1990-05-20";
mapping["form1[0].#subform[1].P2_Line10_CountryOfBirth[0]"] = "USA";
mapping["form1[0].#subform[1].P2_Line11_CountryOfNationality[0]"] = "USA";
mapping["form1[0].#subform[1].P2_Line7_Gender[0]"] = true;
mapping["form1[0].#subform[1].P2_Line7_Gender[1]"] = false;
mapping["form1[0].#subform[1].P2_Line12_claimdisability[0]"] = true;
mapping["form1[0].#subform[1].P2_Line12_claimdisability[1]"] = false;
mapping["form1[0].#subform[1].P2_Line13A[0]"] = true;
mapping["form1[0].#subform[1].P2_Line13A[1]"] = false;
mapping["form1[0].#subform[1].P2_Line13B[0]"] = true;
mapping["form1[0].#subform[1].P2_Line13B[1]"] = false;
mapping["form1[0].#subform[1].P2_Line13C[0]"] = true;
mapping["form1[0].#subform[1].P2_Line13C[1]"] = false;
mapping["form1[0].#subform[1].#area[1].Line1_AlienNumber[1]"] = "B98765432";
mapping["form1[0].#subform[1].P3_Line1_Deaf[0]"] = true;
mapping["form1[0].#subform[1].P3_Line1_Accommodation[0]"] = true;
mapping["form1[0].#subform[1].P3_Line1_Accommodation[1]"] = false;
mapping["form1[0].#subform[1].P3_Line1_DeafAcc[0]"] = "Sign language interpreter provided.";
mapping["form1[0].#subform[1].P3_Line1_Blind[0]"] = false;
mapping["form1[0].#subform[1].P3_Line1_BlindAcc[0]"] = "Braille materials provided.";
mapping["form1[0].#subform[1].Line3_MiddleName2[0]"] = "Grace";
mapping["form1[0].#subform[1].Line3_GivenName2[0]"] = "Emily";
mapping["form1[0].#subform[1].Line3_FamilyName2[0]"] = "Smith";
mapping["form1[0].#subform[1].Line3_MiddleName1[0]"] = "Michael";
mapping["form1[0].#subform[1].Line3_GivenName1[0]"] = "Sophia";
mapping["form1[0].#subform[1].Line3_FamilyName1[0]"] = "Johnson";
mapping["form1[0].#subform[1].P2_Line6_USCISELISAcctNumber[0]"] = "A9876543210";
mapping["form1[0].#subform[2].#area[2].Line1_AlienNumber[2]"] = "C87654321";
mapping["form1[0].#subform[2].P5_Line1a_Province[0]"] = "Ontario";
// ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[2].P5_Line1a_StreetName[0]"] = "Maple Street";
mapping["form1[0].#subform[2].P5_Line1a_State[0]"] = "Ontario";
mapping["form1[0].#subform[2].P5_Line1a_County[0]"] = "York";
mapping["form1[0].#subform[2].P5_Line1a_From[0]"] = "2022-01-01";
mapping["form1[0].#subform[2].P5_Line1a_To[0]"] = "2023-01-01";
mapping["form1[0].#subform[2].P5_Line1a_Number[0]"] = "123";
mapping["form1[0].#subform[2].P5_Line1a_Unit[0]"] = true;
mapping["form1[0].#subform[2].P5_Line1a_Unit[1]"] = false;
mapping["form1[0].#subform[2].P5_Line1a_Unit[2]"] = true;
mapping["form1[0].#subform[2].P5_Line1a_ZipLastFour[0]"] = "1234";
mapping["form1[0].#subform[2].P5_Line1a_Country[0]"] = "Canada";
mapping["form1[0].#subform[2].P5_Line1a_PostalCode[0]"] = "M5G 1E6";
mapping["form1[0].#subform[2].P4_Line1_Telephone[0]"] = "1234567890";
mapping["form1[0].#subform[2].P4_Line2_Telephone[0]"] = "9876543210";
// ... (continue with other fields)

// ... (continue with other mappings)
// ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[2].P4_Line3_Telephone[0]"] = "5556667777";
mapping["form1[0].#subform[2].P4_Line4_Telephone[0]"] = "1112223333";
mapping["form1[0].#subform[2].P4_Line5_Email[0]"] = "emily.smith@example.com";
mapping["form1[0].#subform[2].P5_Line1b_City[0]"] = "Vancouver";
mapping["form1[0].#subform[2].P5_Line1b_State[0]"] = "British Columbia";
mapping["form1[0].#subform[2].P5_Line1b_PostalCode[0]"] = "V6B 2W9";
mapping["form1[0].#subform[2].P5_Line1b_Province[0]"] = "British Columbia";
mapping["form1[0].#subform[2].P5_Line1b_StreetName[0]"] = "Pine Street";
mapping["form1[0].#subform[2].P5_Line1b_InCareOfName[0]"] = "John Doe";
mapping["form1[0].#subform[2].P5_Line1b_Number[0]"] = "456";
mapping["form1[0].#subform[2].P5_Line1b_Unit[0]"] = false;
mapping["form1[0].#subform[2].P5_Line1b_Unit[1]"] = true;
mapping["form1[0].#subform[2].P5_Line1b_Unit[2]"] = false;
mapping["form1[0].#subform[2].P5_Line1b_ZipLastFour[0]"] = "5678";
mapping["form1[0].#subform[2].P5_Line1b_Country[0]"] = "Canada";
mapping["form1[0].#subform[2].P5_Line1b_County[0]"] = "Metro Vancouver";
mapping["form1[0].#subform[2].P3_Line1_OtherAcc[0]"] = "123 Main Street";
mapping["form1[0].#subform[2].P3_Line1_Other[0]"] = true;
// ... (continue with other fields)

// ... (continue with other mappings)




mapping["form1[0].#subform[3].P5_Line1d_From[0]"] = "2022-03-01";
mapping["form1[0].#subform[3].P5_Line1d_To[0]"] = "2023-03-01";
mapping["form1[0].#subform[3].Pt5Line1d_AptSteFlrNumber[0]"] = "Unit 456";
mapping["form1[0].#subform[3].P5_Line1d_Unit[0]"] = false;
mapping["form1[0].#subform[3].P5_Line1d_Unit[1]"] = true;
mapping["form1[0].#subform[3].P5_Line1d_Unit[2]"] = false;
mapping["form1[0].#subform[3].P5_Line1d_State[0]"] = "British Columbia";
mapping["form1[0].#subform[3].P5_Line1d_County[0]"] = "Metro Vancouver";
mapping["form1[0].#subform[3].P5_Line1d_City[0]"] = "Vancouver";
mapping["form1[0].#subform[3].P5_Line1d_ZipLastFour[0]"] = "2345";
mapping["form1[0].#subform[3].P5_Line1d_PostalCode[0]"] = "V2Y2";
mapping["form1[0].#subform[3].P5_Line1d_Province[0]"] = "British Columbia";
mapping["form1[0].#subform[3].P5_Line1d_Country[0]"] = "Canada";
mapping["form1[0].#subform[3].P5_Line1e_StreetName[0]"] = "Elm Street";
mapping["form1[0].#subform[3].P5_Line1e_From[0]"] = "2022-04-01";
mapping["form1[0].#subform[3].P5_Line1e_To[0]"] = "2023-04-01";
mapping["form1[0].#subform[3].P5_Line1e_Number[0]"] = "789";
mapping["form1[0].#subform[3].P5_Line1e_Unit[0]"] = true;
mapping["form1[0].#subform[3].P5_Line1e_Unit[1]"] = false;
mapping["form1[0].#subform[3].P5_Line1e_Unit[2]"] = true;
mapping["form1[0].#subform[3].P5_Line1e_State[0]"] = "British Columbia";
mapping["form1[0].#subform[3].P5_Line1e_County[0]"] = "Metro Vancouver";
mapping["form1[0].#subform[3].P5_Line1e_City[0]"] = "Vancouver";
mapping["form1[0].#subform[3].P5_Line1e_ZipLastFour[0]"] = "8765";
mapping["form1[0].#subform[3].P5_Line1e_PostalCode[0]"] = "V6A 3C6";
mapping["form1[0].#subform[3].P5_Line1e_Province[0]"] = "British Columbia";
mapping["form1[0].#subform[3].P5_Line1e_Country[0]"] = "Canada";
mapping["form1[0].#subform[3].P6_Line2[0]"] = true;
mapping["form1[0].#subform[3].P6_Line2[1]"] = false;
mapping["form1[0].#subform[3].P6_Line1[0]"] = false;
mapping["form1[0].#subform[3].P6_Line1[1]"] = true;
// ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[4].#area[4].Line1_AlienNumber[4]"] = "E54321098";
mapping["form1[0].#subform[4].P6_Line2c_DateofBirth[0]"] = "1990-05-15";
mapping["form1[0].#subform[4].P6_Line2b_Country[0]"] = "USA";
mapping["form1[0].#subform[4].P6_Line2d_DateBecameCitizen[0]"] = "2023-05-15";
mapping["form1[0].#subform[4].P6_Line2e_MothersAnumber[0]"] = "ABC123456";
mapping["form1[0].#subform[4].P6_Line3[0]"] = true;
mapping["form1[0].#subform[4].P6_Line3[1]"] = false;
mapping["form1[0].#subform[4].P6_Line3_MiddleName[0]"] = "John";
mapping["form1[0].#subform[4].P6_Line3a_GivenName[0]"] = "Jane";
mapping["form1[0].#subform[4].P6_Line3a_FamilyName[0]"] = "Doe";
mapping["form1[0].#subform[4].P6_Line3c_DateofBirth[0]"] = "1990-08-20";
mapping["form1[0].#subform[4].P6_Line3b_CountryofBirth[0]"] = "USA";
mapping["form1[0].#subform[4].P6_Line3d_DateBecameCitizen[0]"] = "2020-01-01";
mapping["form1[0].#subform[4].#area[5].P6_Line3e_FatherAnumber[0]"] = "XYZ987654";
mapping["form1[0].#subform[4].P7_Line3_HeightFeet[0]"] = "6";
mapping["form1[0].#subform[4].P7_Line3_HeightInches[0]"] = "2";
mapping["form1[0].#subform[4].P7_Line2_Race[0]"] = true;
mapping["form1[0].#subform[4].P7_Line2_Race[1]"] = false;
mapping["form1[0].#subform[4].P7_Line2_Race[2]"] = true;
mapping["form1[0].#subform[4].P7_Line2_Race[3]"] = false;
mapping["form1[0].#subform[4].P7_Line2_Race[4]"] = true;
mapping["form1[0].#subform[4].P7_Line1_Ethnicity[0]"] = false;
mapping["form1[0].#subform[4].P7_Line1_Ethnicity[1]"] = true;
mapping["form1[0].#subform[4].P7_Line4_Pounds1[0]"] = "1";
mapping["form1[0].#subform[4].P7_Line4_Pounds2[0]"] = "5";
mapping["form1[0].#subform[4].P7_Line4_Pounds3[0]"] = "8";
mapping["form1[0].#subform[4].P7_Line5_Eye[0]"] = true;
mapping["form1[0].#subform[4].P7_Line5_Eye[1]"] = false;
mapping["form1[0].#subform[4].P7_Line5_Eye[2]"] = true;
mapping["form1[0].#subform[4].P7_Line5_Eye[3]"] = false;
mapping["form1[0].#subform[4].P7_Line5_Eye[4]"] = true;
mapping["form1[0].#subform[4].P7_Line5_Eye[5]"] = false;
mapping["form1[0].#subform[4].P7_Line5_Eye[6]"] = true;
mapping["form1[0].#subform[4].P7_Line5_Eye[7]"] = false;
mapping["form1[0].#subform[4].P7_Line5_Eye[8]"] = true;
mapping["form1[0].#subform[4].P7_Line6_Hair[0]"] = true;
mapping["form1[0].#subform[4].P7_Line6_Hair[1]"] = false;
mapping["form1[0].#subform[4].P7_Line6_Hair[2]"] = true;
mapping["form1[0].#subform[4].P7_Line6_Hair[3]"] = false;
mapping["form1[0].#subform[4].P7_Line6_Hair[4]"] = true;
mapping["form1[0].#subform[4].P7_Line6_Hair[5]"] = false;
mapping["form1[0].#subform[4].P7_Line6_Hair[6]"] = true;
mapping["form1[0].#subform[4].P7_Line6_Hair[7]"] = false;
mapping["form1[0].#subform[4].P7_Line6_Hair[8]"] = true;
mapping["form1[0].#subform[4].P6_Line2a_MiddleName[0]"] = "Michael";

// ... (continue with other fields)

// ... (continue with other mappings)








mapping["form1[0].#subform[4].P6_Line2a_GivenName[0]"] = "Robert";
mapping["form1[0].#subform[4].P6_Line2a_FamilyName[0]"] = "Johnson";
mapping["form1[0].#subform[5].#area[6].Line1_AlienNumber[5]"] = "DEF789012";
mapping["form1[0].#subform[5].P8_Line1_City[0]"] = "Los Angeles";
mapping["form1[0].#subform[5].P8_Line1_State[0]"] = "California";
mapping["form1[0].#subform[5].P8_Line1_ZipCode[0]"] = "90001";
mapping["form1[0].#subform[5].P8_Line1_ZipLastFour[0]"] = "1234";
mapping["form1[0].#subform[5].P8_Line1_Number[0]"] = "123";
mapping["form1[0].#subform[5].P8_Line1_Unit[0]"] = true;
mapping["form1[0].#subform[5].P8_Line1_Unit[1]"] = false;
mapping["form1[0].#subform[5].P8_Line1_Unit[2]"] = true;
mapping["form1[0].#subform[5].P8_Line1_EmployerStreetName[0]"] = "Main Street";
mapping["form1[0].#subform[5].P8_Line1_EmployerName[0]"] = "ABC Corporation";
mapping["form1[0].#subform[5].P8_Line1_From[0]"] = "2022-03-10";
mapping["form1[0].#subform[5].P8_Line1_To[0]"] = "2022-12-31";
mapping["form1[0].#subform[5].P8_Line1_Occupation[0]"] = "Software Engineer";
mapping["form1[0].#subform[5].P8_Line2_EmployerName[0]"] = "XYZ Industries";
mapping["form1[0].#subform[5].P8_Line2_City[0]"] = "San Francisco";
mapping["form1[0].#subform[5].P8_Line2_State[0]"] = "California";
mapping["form1[0].#subform[5].P8_Line2_ZipCode[0]"] = "94105";
mapping["form1[0].#subform[5].P8_Line2_StreetName[0]"] = "Broadway";
mapping["form1[0].#subform[5].P8_Line2_Number[0]"] = "456";
mapping["form1[0].#subform[5].P8_Line2_Unit[0]"] = false;
mapping["form1[0].#subform[5].P8_Line2_Unit[1]"] = true;
mapping["form1[0].#subform[5].P8_Line2_Unit[2]"] = false;
mapping["form1[0].#subform[5].P8_Line2_ZipLastFour[0]"] = "6789";
mapping["form1[0].#subform[5].P8_Line1_PostalCode[0]"] = "M5V 2A3";
mapping["form1[0].#subform[5].P8_Line1_Province[0]"] = "Ontario";
mapping["form1[0].#subform[5].P8_Line2_PostalCode[0]"] = "94110";
mapping["form1[0].#subform[5].P8_Line2_Province[0]"] = "California";
mapping["form1[0].#subform[5].P8_Line1_Country[0]"] = "Canada";
mapping["form1[0].#subform[5].P8_Line2_From[0]"] = "2022-01-15";
mapping["form1[0].#subform[5].P8_Line2_To[0]"] = "2022-11-30";
mapping["form1[0].#subform[5].P8_Line2_Occupation[0]"] = "Data Scientist";
mapping["form1[0].#subform[5].P8_Line2_Country[0]"] = "USA";
mapping["form1[0].#subform[5].P8_Line3_EmployerName[0]"] = "LMN Tech Solutions";
mapping["form1[0].#subform[5].P8_Line3_EmployerStreetName[0]"] = "Oak Street";
mapping["form1[0].#subform[5].P8_Line3_Number[0]"] = "789";
mapping["form1[0].#subform[5].P8_Line3_Unit[0]"] = false;
mapping["form1[0].#subform[5].P8_Line3_Unit[1]"] = true;
mapping["form1[0].#subform[5].P8_Line3_Unit[2]"] = false;
mapping["form1[0].#subform[5].P8_Line3_To[0]"] = "2022-09-15";
mapping["form1[0].#subform[5].P8_Line3_From[0]"] = "2022-06-20";
mapping["form1[0].#subform[5].P8_Line3_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[5].P8_Line3_State[0]"] = "California";
mapping["form1[0].#subform[5].P8_Line3_City[0]"] = "San Diego";
mapping["form1[0].#subform[5].P8_Line3_ZipLastFour[0]"] = "9876";
mapping["form1[0].#subform[5].P8_Line3_PostalCode[0]"] = "M5V2A3";
mapping["form1[0].#subform[5].P8_Line3_Province[0]"] = "British Columbia";
mapping["form1[0].#subform[5].P8_Line3_Occupation[0]"] = "Engineer";
mapping["form1[0].#subform[5].P8_Line3_Country[0]"] = "Canada";
mapping["form1[0].#subform[6].#area[7].Line1_AlienNumber[6]"] = "XYZ456789";
mapping["form1[0].#subform[6].P9_Line3_DateReturn1[0]"] = "2022-07-15";
mapping["form1[0].#subform[6].P9_Line3_Total2[0]"] = "120";
mapping["form1[0].#subform[6].P9_Line3_DateReturn3[0]"] = "2022-08-25";
mapping["form1[0].#subform[6].P9_Line3_Total4[0]"] = "90";
mapping["form1[0].#subform[6].P9_Line3_DateReturn5[0]"] = "2022-09-30";
mapping["form1[0].#subform[6].P9_Line3_Total6[0]"] = "150";
mapping["form1[0].#subform[6].P9_Line3_Countries6[0]"] = "Germany";
mapping["form1[0].#subform[6].P9_Line3_Last6[0]"] = true;
mapping["form1[0].#subform[6].P9_Line3_Last6[1]"] = false;
mapping["form1[0].#subform[6].P9_Line3_DateLeft6[0]"] = "2022-10-10";
mapping["form1[0].#subform[6].P9_Line3_DateReturn6[0]"] = "2022-11-20";
mapping["form1[0].#subform[6].P9_Line3_DateLeft5[0]"] = "2022-12-01";
mapping["form1[0].#subform[6].P9_Line3_Last5[0]"] = false;
mapping["form1[0].#subform[6].P9_Line3_Last5[1]"] = true;
mapping["form1[0].#subform[6].P9_Line3_Countries5[0]"] = "Japan";
mapping["form1[0].#subform[6].P9_Line3_Total5[0]"] = "80";
mapping["form1[0].#subform[6].P9_Line3_Countries4[0]"] = "Brazil";
mapping["form1[0].#subform[6].P9_Line3_Last4[0]"] = true;
mapping["form1[0].#subform[6].P9_Line3_Last4[1]"] = false;
mapping["form1[0].#subform[6].P9_Line3_DateLeft4[0]"] = "2023-01-15";
mapping["form1[0].#subform[6].P9_Line3_DateReturn4[0]"] = "2023-02-28";
mapping["form1[0].#subform[6].P9_Line3_DateLeft3[0]"] = "2023-03-10";
mapping["form1[0].#subform[6].P9_Line3_Last3[0]"] = false;
mapping["form1[0].#subform[6].P9_Line3_Last3[1]"] = true;
mapping["form1[0].#subform[6].P9_Line3_Countries3[0]"] = "Australia";
mapping["form1[0].#subform[6].P9_Line3_Total3[0]"] = "100";
mapping["form1[0].#subform[6].P9_Line3_Countries2[0]"] = "India";


mapping["form1[0].#subform[6].P9_Line3_Last2[0]"] = true;
mapping["form1[0].#subform[6].P9_Line3_Last2[1]"] = false;
mapping["form1[0].#subform[6].P9_Line3_DateLeft2[0]"] = "2023-04-05";
mapping["form1[0].#subform[6].P9_Line3_DateReturn2[0]"] = "2023-05-20";
mapping["form1[0].#subform[6].P9_Line3_DateLeft1[0]"] = "2023-06-01";
mapping["form1[0].#subform[6].P9_Line3_Last1[0]"] = false;
mapping["form1[0].#subform[6].P9_Line3_Last1[1]"] = true;
mapping["form1[0].#subform[6].P9_Line3_Countries1[0]"] = "France";
mapping["form1[0].#subform[6].P9_Line3_Total1[0]"] = "75";
mapping["form1[0].#subform[6].P9_Line2_Trips[0]"] = "3";
mapping["form1[0].#subform[6].P9_Line1_Days[0]"] = "180";
mapping["form1[0].#subform[6].Part9Line3_TimesMarried[0]"] = "2";
mapping["form1[0].#subform[6].P10_Line1_MaritalStatus[0]"] = true;
mapping["form1[0].#subform[6].P10_Line1_MaritalStatus[1]"] = false;
mapping["form1[0].#subform[6].P10_Line1_MaritalStatus[2]"] = true;
mapping["form1[0].#subform[6].P10_Line1_MaritalStatus[3]"] = false;
mapping["form1[0].#subform[6].P10_Line1_MaritalStatus[4]"] = true;
mapping["form1[0].#subform[6].P10_Line4a_MiddleName[0]"] = "Alex";
mapping["form1[0].#subform[6].P10_Line4a_GivenName[0]"] = "Emma";
mapping["form1[0].#subform[6].P10_Line4a_FamilyName[0]"] = "Smith";
mapping["form1[0].#subform[6].P10_Line1_MaritalStatus[5]"] = false;
mapping["form1[0].#subform[6].P10_Line4b_MiddleName[0]"] = "James";
mapping["form1[0].#subform[6].P10_Line4b_GivenName[0]"] = "Olivia";
mapping["form1[0].#subform[6].P10_Line4b_FamilyName[0]"] = "Johnson";
mapping["form1[0].#subform[6].P10_Line4c_FamilyName[0]"] = "Johnson";
mapping["form1[0].#subform[6].P10_Line4c_GivenName[0]"] = "Robert";
mapping["form1[0].#subform[6].P10_Line4c_MiddleName[0]"] = "Michael";
mapping["form1[0].#subform[6].P10_Line2_Forces[0]"] = true;
mapping["form1[0].#subform[6].P10_Line2_Forces[1]"] = false;
mapping["form1[0].#subform[6].P10_Line4d_DateofBirth[0]"] = "1990-05-15";
mapping["form1[0].#subform[6].P10_Line4e_DateEnterMarriage[0]"] = "2010-08-25";
mapping["form1[0].#subform[7].#area[8].Line1_AlienNumber[7]"] = "ABC987654";
mapping["form1[0].#subform[7].P10_Line4f_StreetName[0]"] = "Maple Street";
mapping["form1[0].#subform[7].P10_Line4f_Province[0]"] = "Ontario";
mapping["form1[0].#subform[7].P10_Line4f_PostalCode[0]"] = "M4B2V5";
mapping["form1[0].#subform[7].P10_Line4f_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[7].P10_Line4f_State[0]"] = "New York";
mapping["form1[0].#subform[7].P10_Line4f_City[0]"] = "Toronto";
mapping["form1[0].#subform[7].P10_Line7c_Immigration[1]"] = false;
mapping["form1[0].#subform[7].P10_Line7c_Other[0]"] = "Special Status";
mapping["form1[0].#subform[7].P10_Line5a_When[0]"] = true;
mapping["form1[0].#subform[7].P10_Line5a_When[1]"] = false;
mapping["form1[0].#subform[7].#area[9].P10_Line7a_Anumber[0]"] = "XYZ123456";
mapping["form1[0].#subform[7].P10_Line7a_Country[0]"] = "USA";
mapping["form1[0].#subform[7].P10_Line8a_MiddleName[0]"] = "Elizabeth";
mapping["form1[0].#subform[7].P10_Line8a_GivenName[0]"] = "John";
mapping["form1[0].#subform[7].P10_Line8a_FamilyName[0]"] = "Doe";
mapping["form1[0].#subform[9].P11_Line2b_MiddleName[0]"] = "John";
mapping["form1[0].#subform[9].P11_Line2b_GivenName[0]"] = "Doe";
mapping["form1[0].#subform[9].P11_Line2b_FamilyName[0]"] = "Smith";
mapping["form1[0].#subform[9].P11_Line2b_DOB[0]"] = "01/15/1985";
mapping["form1[0].#subform[9].P11_Line2b_StreetNumber[0]"] = "456 Oak St";
mapping["form1[0].#subform[9].P11_Line2b_Province[0]"] = "New York";
mapping["form1[0].#subform[9].P11_Line2b_PostalCode[0]"] = "10001";
mapping["form1[0].#subform[9].P11_Line2b_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[9].P11_Line2b_State[0]"] = "NY";
mapping["form1[0].#subform[9].P11_Line2b_City[0]"] = "New York City";
mapping["form1[0].#subform[9].P11_Line2b_County[0]"] = "Manhattan";
mapping["form1[0].#subform[9].P11_Line2b_Number[0]"] = "789";
mapping["form1[0].#subform[9].P11_Line2b_Unit[0]"] = false;
mapping["form1[0].#subform[9].P11_Line2b_Unit[1]"] = true;
mapping["form1[0].#subform[9].P11_Line2b_Unit[2]"] = false;
mapping["form1[0].#subform[9].P11_Line2b_ZipLastFour[0]"] = "4321";
mapping["form1[0].#subform[9].P11_Line2b_Relationship[0]"] = "Family";
mapping["form1[0].#subform[11].P12_Line9b_Purpose3[0]"] = "Business";
mapping["form1[0].#subform[11].P12_Line9b_Purpose2[0]"] = "Vacation";
mapping["form1[0].#subform[11].P12_Line9b_Purpose1[0]"] = "Conference";
mapping["form1[0].#subform[11].P12_Line9b_From1[0]"] = "John Doe";
mapping["form1[0].#subform[11].P12_Line9b_From2[0]"] = "Jane Smith";

   // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[11].P12_Line9b_From3[0]"] = "Company XYZ";
mapping["form1[0].#subform[11].P12_Line9b_From4[0]"] = "123 Main St";
mapping["form1[0].#subform[11].P12_Line9b_To1[0]"] = "Client A";
mapping["form1[0].#subform[11].P12_Line9b_To2[0]"] = "Meeting Room 1";
mapping["form1[0].#subform[11].P12_Line9b_To3[0]"] = "2023-11-25";
mapping["form1[0].#subform[11].P12_Line9b_To4[0]"] = "2023-11-26";
mapping["form1[0].#subform[11].P12_Line10a[0]"] = true;
mapping["form1[0].#subform[11].P12_Line10b[0]"] = false;
mapping["form1[0].#subform[11].P12_Line10b[1]"] = true;
mapping["form1[0].#subform[11].P12_Line10a[1]"] = false;
mapping["form1[0].#subform[11].P12_Line11[0]"] = true;
mapping["form1[0].#subform[11].P12_Line11[1]"] = false;
mapping["form1[0].#subform[11].P12_Line13a[0]"] = true;
mapping["form1[0].#subform[11].P12_Line13b[0]"] = false;
mapping["form1[0].#subform[11].P12_Line13c[0]"] = true;
mapping["form1[0].#subform[11].P12_Line13c[1]"] = false;
mapping["form1[0].#subform[11].P12_Line13b[1]"] = true;
mapping["form1[0].#subform[11].P12_Line13a[1]"] = false;
mapping["form1[0].#subform[11].P12_Line12[0]"] = true;
mapping["form1[0].#subform[11].P12_Line12[1]"] = false;
mapping["form1[0].#subform[11].P12_Line10c[0]"] = false;
mapping["form1[0].#subform[11].P12_Line10c[1]"] = true;
mapping["form1[0].#subform[12].#area[17].Line1_AlienNumber[12]"] = "AB9876543";
mapping["form1[0].#subform[12].P12_Line14a[0]"] = true;
mapping["form1[0].#subform[12].P12_Line14a[1]"] = false;
mapping["form1[0].#subform[12].P12_Line14b[0]"] = false;
mapping["form1[0].#subform[12].P12_Line14b[1]"] = true;
mapping["form1[0].#subform[12].P12_Line16e[0]"] = true;
mapping["form1[0].#subform[12].P12_Line16e[1]"] = false;
mapping["form1[0].#subform[12].P12_Line17a[0]"] = false;
mapping["form1[0].#subform[12].P12_Line17b[0]"] = true;
mapping["form1[0].#subform[12].P12_Line17b[1]"] = false;
mapping["form1[0].#subform[12].P12_Line17a[1]"] = true;
mapping["form1[0].#subform[12].P12_Line17[0]"] = true;
mapping["form1[0].#subform[12].P12_Line17[1]"] = false;
mapping["form1[0].#subform[12].P12_Line18[0]"] = false;
mapping["form1[0].#subform[12].P12_Line18a[0]"] = true;
mapping["form1[0].#subform[12].P12_Line18a[1]"] = false;
mapping["form1[0].#subform[12].P12_Line18b[0]"] = false;
mapping["form1[0].#subform[12].P12_Line18b[1]"] = true;
mapping["form1[0].#subform[12].P12_Line18[1]"] = true;
mapping["form1[0].#subform[13].#area[18].Line1_AlienNumber[13]"] = "AB9876543";
mapping["form1[0].#subform[13].P12_Line22[0]"] = true;
mapping["form1[0].#subform[13].P12_Line22[1]"] = false;
mapping["form1[0].#subform[13].P12_Line23[0]"] = false;
mapping["form1[0].#subform[13].P12_Line23[1]"] = true;
mapping["form1[0].#subform[13].P12_Line24[0]"] = true;
mapping["form1[0].#subform[13].P12_Line24[1]"] = false;
mapping["form1[0].#subform[13].P12_Line25[0]"] = false;
mapping["form1[0].#subform[13].P12_Line25[1]"] = true;
mapping["form1[0].#subform[13].P12_Line27a[0]"] = true;
mapping["form1[0].#subform[13].P12_Line27a[1]"] = false;
mapping["form1[0].#subform[13].P12_Line27b[0]"] = false;
mapping["form1[0].#subform[13].P12_Line27b[1]"] = true;
mapping["form1[0].#subform[13].P12_Line28a[0]"] = true;
mapping["form1[0].#subform[13].P12_Line28a[1]"] = false;
mapping["form1[0].#subform[13].P12_Line28b_Years[0]"] = "12345";
mapping["form1[0].#subform[13].P12_Line28b_Months[0]"] = "67890";
mapping["form1[0].#subform[13].P12_Line28b_Days[0]"] = "54321";
mapping["form1[0].#subform[13].P12_Line29_why1[0]"] = "Business Trip";
mapping["form1[0].#subform[13].P12_Line29_why2[0]"] = "Meeting with Client";
mapping["form1[0].#subform[13].P12_Line29_why3[0]"] = "Team Building";
mapping["form1[0].#subform[13].P12_Line29_why4[0]"] = "Training Session";
mapping["form1[0].#subform[13].P12_Line29_why5[0]"] = "Networking Event";
mapping["form1[0].#subform[13].P12_Line29_Date1[0]"] = "2023-11-27";
mapping["form1[0].#subform[13].P12_Line29_Date2[0]"] = "2023-11-28";
mapping["form1[0].#subform[13].P12_Line29_Date3[0]"] = "2023-11-29";
mapping["form1[0].#subform[13].P12_Line29_Date4[0]"] = "2023-11-30";
mapping["form1[0].#subform[13].P12_Line29_Date5[0]"] = "2023-12-01";
mapping["form1[0].#subform[13].P12_Line29_Where1[0]"] = "New York City";

// ... (continue with other fields)

// ... (continue with other mappings)

  // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[12].P12_Line16e[0]"] = true;
mapping["form1[0].#subform[12].P12_Line16e[1]"] = false;
mapping["form1[0].#subform[12].P12_Line17a[0]"] = true;
mapping["form1[0].#subform[12].P12_Line17b[0]"] = false;
mapping["form1[0].#subform[12].P12_Line17b[1]"] = true;
mapping["form1[0].#subform[12].P12_Line17a[1]"] = false;
mapping["form1[0].#subform[12].P12_Line17[0]"] = true;
mapping["form1[0].#subform[12].P12_Line17[1]"] = false;
mapping["form1[0].#subform[12].P12_Line18[0]"] = true;
mapping["form1[0].#subform[12].P12_Line18a[0]"] = false;
mapping["form1[0].#subform[12].P12_Line18a[1]"] = true;
mapping["form1[0].#subform[12].P12_Line18b[0]"] = false;
mapping["form1[0].#subform[12].P12_Line18b[1]"] = true;
mapping["form1[0].#subform[12].P12_Line18[1]"] = false;
mapping["form1[0].#subform[13].#area[18].Line1_AlienNumber[13]"] = "A12345678";
mapping["form1[0].#subform[13].P12_Line22[0]"] = true;
mapping["form1[0].#subform[13].P12_Line22[1]"] = false;
mapping["form1[0].#subform[13].P12_Line23[0]"] = true;
mapping["form1[0].#subform[13].P12_Line23[1]"] = false;
mapping["form1[0].#subform[13].P12_Line24[0]"] = true;
mapping["form1[0].#subform[13].P12_Line24[1]"] = false;
mapping["form1[0].#subform[13].P12_Line25[0]"] = true;
mapping["form1[0].#subform[13].P12_Line25[1]"] = false;
mapping["form1[0].#subform[13].P12_Line27a[0]"] = true;
mapping["form1[0].#subform[13].P12_Line27a[1]"] = false;
mapping["form1[0].#subform[13].P12_Line27b[0]"] = true;
mapping["form1[0].#subform[13].P12_Line27b[1]"] = false;
mapping["form1[0].#subform[13].P12_Line28a[0]"] = true;
mapping["form1[0].#subform[13].P12_Line28a[1]"] = false;
mapping["form1[0].#subform[13].P12_Line28b_Years[0]"] = "12345";
mapping["form1[0].#subform[13].P12_Line28b_Months[0]"] = "67890";
mapping["form1[0].#subform[13].P12_Line28b_Days[0]"] = "54321";
mapping["form1[0].#subform[13].P12_Line29_why1[0]"] = "Reason1";
mapping["form1[0].#subform[13].P12_Line29_why2[0]"] = "Reason2";
mapping["form1[0].#subform[13].P12_Line29_why3[0]"] = "Reason3";
mapping["form1[0].#subform[13].P12_Line29_why4[0]"] = "Reason4";
mapping["form1[0].#subform[13].P12_Line29_why5[0]"] = "Reason5";
mapping["form1[0].#subform[13].P12_Line29_Date1[0]"] = "2023-01-01";
mapping["form1[0].#subform[13].P12_Line29_Date2[0]"] = "2023-02-02";
mapping["form1[0].#subform[13].P12_Line29_Date3[0]"] = "2023-03-03";
mapping["form1[0].#subform[13].P12_Line29_Date4[0]"] = "2023-04-04";
mapping["form1[0].#subform[13].P12_Line29_Date5[0]"] = "2023-05-05";
mapping["form1[0].#subform[13].P12_Line29_Where1[0]"] = "Location1";
mapping["form1[0].#subform[13].P12_Line29_Where2[0]"] = "Location2";
mapping["form1[0].#subform[13].P12_Line29_Where3[0]"] = "Location3";
mapping["form1[0].#subform[13].P12_Line29_Where4[0]"] = "Location4";
mapping["form1[0].#subform[13].P12_Line29_Where5[0]"] = "Location5";
mapping["form1[0].#subform[13].P12_Line29_Outcome1[0]"] = "Outcome1";
mapping["form1[0].#subform[13].P12_Line29_Outcome2[0]"] = "Outcome2";
mapping["form1[0].#subform[13].P12_Line29_Outcome3[0]"] = "Outcome3";
mapping["form1[0].#subform[13].P12_Line29_Outcome4[0]"] = "Outcome4";
mapping["form1[0].#subform[13].P12_Line29_Outcome5[0]"] = "Outcome5";

// ... (continue with other fields)

// ... (continue with other mappings)
// ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[13].P12_Line26[0]"] = true;
mapping["form1[0].#subform[13].P12_Line26[1]"] = false;
mapping["form1[0].#subform[13].P12_Line19[0]"] = true;
mapping["form1[0].#subform[13].P12_Line19[1]"] = false;
mapping["form1[0].#subform[13].P12_Line20[0]"] = true;
mapping["form1[0].#subform[13].P12_Line20[1]"] = false;
mapping["form1[0].#subform[13].P12_Line21[0]"] = true;
mapping["form1[0].#subform[13].P12_Line21[1]"] = false;
mapping["form1[0].#subform[14].#area[19].Line1_AlienNumber[14]"] = "B98765432";
mapping["form1[0].#subform[14].P12_Line30a[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30a[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30b[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30b[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30c[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30c[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30d[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30d[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30e[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30e[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30f[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30f[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30g[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30g[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30h[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30h[1]"] = false;
mapping["form1[0].#subform[14].P12_Line30i[0]"] = true;
mapping["form1[0].#subform[14].P12_Line30i[1]"] = false;
mapping["form1[0].#subform[14].P12_Line31[0]"] = true;
mapping["form1[0].#subform[14].P12_Line31[1]"] = false;
mapping["form1[0].#subform[14].P12_Line32[0]"] = true;
mapping["form1[0].#subform[14].P12_Line32[1]"] = false;
mapping["form1[0].#subform[14].P12_Line33[0]"] = true;
mapping["form1[0].#subform[14].P12_Line33[1]"] = false;
mapping["form1[0].#subform[14].P12_Line34[0]"] = true;
mapping["form1[0].#subform[14].P12_Line34[1]"] = false;
mapping["form1[0].#subform[14].P12_Line35[0]"] = true;
mapping["form1[0].#subform[14].P12_Line35[1]"] = false;
mapping["form1[0].#subform[14].P12_Line36[0]"] = true;
mapping["form1[0].#subform[14].P12_Line36[1]"] = false;
mapping["form1[0].#subform[14].P12_Line37[0]"] = true;
mapping["form1[0].#subform[14].P12_Line37[1]"] = false;
mapping["form1[0].#subform[14].P12_Line38a[0]"] = true;
mapping["form1[0].#subform[14].P12_Line38a[1]"] = false;
mapping["form1[0].#subform[14].P12_Line38b[0]"] = true;
mapping["form1[0].#subform[14].P12_Line38b[1]"] = false;
mapping["form1[0].#subform[14].P12_Line38c[0]"] = true;
mapping["form1[0].#subform[14].P12_Line38c[1]"] = false;
mapping["form1[0].#subform[14].P12_Line39[0]"] = true;
mapping["form1[0].#subform[14].P12_Line39[1]"] = false;
mapping["form1[0].#subform[14].P12_Line40[0]"] = true;
mapping["form1[0].#subform[14].P12_Line40[1]"] = false;
mapping["form1[0].#subform[14].P12_Line41[0]"] = true;
mapping["form1[0].#subform[14].P12_Line41[1]"] = false;
mapping["form1[0].#subform[14].P12_Line42[0]"] = true;
mapping["form1[0].#subform[14].P12_Line42[1]"] = false;
mapping["form1[0].#subform[14].P12_Line43[0]"] = true;
mapping["form1[0].#subform[14].P12_Line43[1]"] = false;
mapping["form1[0].#subform[15].#area[20].Line1_AlienNumber[15]"] = "123456789";
mapping["form1[0].#subform[16].#area[21].Line1_AlienNumber[16]"] = "123456789";
mapping["form1[0].#subform[16].P14_Line3_Unit[0]"] = true;
mapping["form1[0].#subform[16].P14_Line3_Unit[1]"] = false;
mapping["form1[0].#subform[16].P14_Line3_Number[0]"] = "1234";
mapping["form1[0].#subform[16].P14_Line3_Unit[2]"] = true;
mapping["form1[0].#subform[16].P14_Line3_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[16].P14_Line3_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[16].P14_Line3_ZipLastFour[0]"] = "9876";
mapping["form1[0].#subform[16].P14_Line1_nterpreterGivenName[0]"] = "RandomInterpreterGivenName";
mapping["form1[0].#subform[16].P14_Line1_nterpreterFamilyName[0]"] = "RandomInterpreterFamilyName";

// ... (continue with other fields)

// ... (continue with other mappings)

mapping["form1[0].#subform[17].#area[22].Line1_AlienNumber[17]"] = "123456789";
mapping["form1[0].#subform[17].P14_NameOfLanguage[0]"] = "RandomLanguage";
mapping["form1[0].#subform[17].P14_DateofSignature[0]"] = "01/01/2023";
mapping["form1[0].#subform[17].P15_Line1_PreparerGivenName[0]"] = "RandomPreparerGivenName";
mapping["form1[0].#subform[17].P15_Line1_PreparerFamilyName[0]"] = "RandomPreparerFamilyName";
mapping["form1[0].#subform[17].P15_Line2_NameofBusinessorOrgName[0]"] = "RandomBusinessName";
mapping["form1[0].#subform[17].P15_Line3_City[0]"] = "RandomCity";

    
    return mapping;
  };




  const handleSubmitN400 = async () => {
    const data = mapFormFields();
    console.log(data);
     console.log("uzair")
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "N-400",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };


  return (
    <FormContextN400.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitN400 }}>
      {children}
    </FormContextN400.Provider>
  );
}
