import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextI30';


const TravelInformation130 = () => {

  const navigate=useNavigate("")
    const {  formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
   
       navigate("/Applicantinfo130")
       
    };


    console.log(formData.tripDate)
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>


          <div>
  <label className="flex bg-blue-700 p-4 text-white  rounded">
    Have you ever been issued a Reentry Permit or Refugee Travel Document?
  </label>
  <div className="flex  gap-5 bg-blue-100 p-4 ">
    <label className="flex  text-blue-600 gap-2">
      <input
        type="radio"
        name="reentryPermitOrTravelDocument"
        value="yes"
        checked={formData.reentryPermitOrTravelDocument === 'yes'}
        onChange={handleInputChange}
        className="form-radio text-blue-600"
      />
      Yes
    </label>
    <label className="flex  text-blue-600 gap-2">
      <input
        type="radio"
        name="reentryPermitOrTravelDocument"
        value="no"
        checked={formData.reentryPermitOrTravelDocument === 'no'}
        onChange={handleInputChange}
        className="form-radio text-blue-600"
      />
      No
    </label>
  </div>
</div>
<div>
  <label className="flex bg-blue-600 p-4 text-white  mt-10">
    When was the most recent document issued?
  </label>
  <div className="flex iitems-center bg-blue-100 p-4 rounded gap-4 ">
      <input
      type="date"
      name="mostRecentDocumentDate"
      value={formData.mostRecentDocumentDate}
      onChange={handleInputChange}
      className="w-full md:w-[300px]  text-blue-600 border mr-2 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-2"
    />
   
  </div>
</div>   

      <div>
      <h2 className="flex bg-blue-600 p-4 text-white  mt-10 rounded">Has the travel document expired?</h2>
       <div className='flex bg-blue-100 p-4  gap-10  text-blue-700'>
      <label>
     
        <input
          type="radio"
          name="travelDocumentExpired"
          value="yes"
          checked={formData.travelDocumentExpired === 'yes'}
          onChange={handleInputChange}
        />
           Yes
      </label>
      <label>
       
        <input
          type="radio"
          name="travelDocumentExpired"
          value="no"
          checked={formData.travelDocumentExpired === 'no'}
          onChange={handleInputChange}
        />
         No
      </label>
      <label>
    
        <input
          type="radio"
          name="travelDocumentExpired"
          value="unknown"
          checked={formData.travelDocumentExpired === 'unknown'}
          onChange={handleInputChange}
        />
            Unknown
      </label>
      </div>
    </div>
  





<hr className='flex h-1 bg-blue-700 mt-10'/>

   <h1 className='text-blue-700 text-xl font-semibold mt-10'>Trip Information</h1>


   <div className='mt-10'>
       <div className='flex bg-blue-700 p-4 text-white rounded'>
      <h2 >When do you intend to leave the United States?</h2>
      </div>
      <div className='flex bg-blue-100 p-4'>
      <input
         className='w-full md:w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   md:ml-8'
        type="date"
        name="tripDate"
        value={formData.tripDate}
        onChange={handleInputChange}
      />
      </div>
    </div>
  

    <div>
      <label className='flex bg-blue-700 p-4 text-white rounded'>
        How many days do you expect to be outside the United States?
      </label>
      <div className='flex bg-blue-100 p-4'>
      <input
        className='w-full md:w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   md:ml-8'
        type="number"
        name="expectedDaysOutsideUS"
        value={formData.expectedDaysOutsideUS}
        onChange={handleInputChange}
      />
    </div>
    </div>

    <hr className='h-1 bg-blue-600 mt-10 ' />


    <div>
      <label  className='flex bg-blue-700 p-4 text-white rounded mt-10'>
        Describe the purpose of the trip:
      </label>
      <div className='flex bg-blue-100 p-4'>
      <input
       className='w-full md:w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   md:ml-8'
        type="text"
        name="tripPurpose"
        value={formData.tripPurpose}
        onChange={handleInputChange}
      />
      </div>
      
      <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>
        List the countries you intend to visit:
      </label>
      <div className='flex bg-blue-100 p-4'>
      <input
       className='w-full md-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   md:ml-8'
        type="text"
        name="intendedCountries"
        value={formData.intendedCountries}
        onChange={handleInputChange}
      />
      </div>
    </div>

    <hr className='h-1 bg-blue-600 mt-10'/>

     <h1 className='text-blue-600 text-xl font-semibold mt-10'>Refugee Travel Document Questions</h1>

      <div className='flex gap-4 bg-slate-200 mt-10 rounded  p-4'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Info_30.png" alt="" className='w-[30px] h-[40px]'/>
        <p> <span  className='font-bold'>Advisory for asylees and refugees: </span>AYour asylee or refugee status may be terminated if you return to the country of claimed persecution. Consult with an immigration attorney before requesting a travel document for travel to your country of nationality or, if stateless, country of last habitual residence. Explain more >></p>
      </div>


      <div>
      <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>From which country are you a refugee or asylee?</label>
     <div className='flex p-4 bg-blue-100 '>
      <select
         className='w-full md:w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   md:ml-8'
        name="refugeeOrAsyleeCountry"
        value={formData.refugeeOrAsyleeCountry}
        onChange={handleInputChange}
      >
        <option value="">Select a country</option>
        {code.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
      </div>
    </div>
    <h1 className='text-blue-600 font-semibold text-xl mt-10'>Since you were accorded refugee/asylum status, have you ever:</h1>
    
    
    <div>
      <label  className='flex bg-blue-700 p-4 text-white rounded mt-10'>Do you plan to travel to the country named above?</label>
      <div className='flex bg-blue-100 p-4 gap-4 '>
        <label className='text-blue-700 gap-4'>
       
          <input
            type="radio"
            name="planToTravel"
            value="Yes"
            checked={formData.planToTravel === 'Yes'}
            onChange={handleInputChange}
          />
             Yes
        </label>
        <label className='text-blue-700 gap-4'>
   
          <input
            type="radio"
            name="planToTravel"
            value="No"
            checked={formData.planToTravel === 'No'}
            onChange={handleInputChange}
          />
             No
        </label>
      </div>
    </div>
          { formData.planToTravel === 'Yes'
          &&
          <div className='bg-red-200 p-2'>
           
           <div className='flex gap-4'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
              We found a problem. Based on your answer, you cannot continue. </span>There are significant risks associated with travel to your country of claimed persecution. You may lose your status in the United States. CitizenPath cannot help you with this application, and we recommend that you work with an experienced immigration attorney.</p>
           </div>

           <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt=""  className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
            Help me find an immigration attorney . </span></p>
           </div>
          </div>}
    {formData.planToTravel === 'No'
      &&
      
      <div>
  <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>Returned to the country named above?</label>
  <div className='flex bg-blue-100 p-4 gap-4'>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="returnedToCountry"
        value="Yes"
        checked={formData.returnedToCountry === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="returnedToCountry"
        value="No"
        checked={formData.returnedToCountry === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>
}
   
   {formData.returnedToCountry === 'No'
   &&
    <div>
  <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>Applied for and/or obtained a national passport, passport renewal, or entry permit for that country?</label>
  <div className='flex bg-blue-100 p-4 gap-4'>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="appliedForEntryPermit"
        value="Yes"
        checked={formData.appliedForEntryPermit === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="appliedForEntryPermit"
        value="No"
        checked={formData.appliedForEntryPermit === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>}

{formData.returnedToCountry === 'Yes'
  
  &&
  <>
<div className='bg-orange-200 p-2'>
           
           <div className='flex gap-4'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
             Your answer to this question suggests that you have a history which may require the assistance of a legal professional </span> We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
           </div>

           <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt=""  className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
            Help me find an immigration attorney . </span></p>
           </div>
          </div>
          <div>
  <div className='bg-green-200 rounded items-center p-2 w-full md:w-[700px] flex md:ml-auto'>
  <label className="">
    I acknowledge that I have spoken to an attorney and / or want to continue anyway
  </label>
    <label>
      <input
        type="checkbox"
        name="acknowledgeAttorney"
        checked={formData.acknowledgeAttorney}
        onChange={handleInputChange}
      />
      
    
    </label>
</div>
</div>
</>}



{formData.appliedForEntryPermit === 'Yes'
  
  &&
  <>
<div className='bg-orange-200 p-2'>
           
           <div className='flex gap-4'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
             Your answer to this question suggests that you have a history which may require the assistance of a legal professional </span> We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
           </div>

           <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt=""  className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
            Help me find an immigration attorney . </span></p>
           </div>
          </div>
          <div>
  <div className='bg-green-200 rounded  p-2 w-full  md:w-[700px] flex ml-auto'>
  <label className="">
    I acknowledge that I have spoken to an attorney and / or want to continue anyway
  </label>
    <label>
      <input
        type="checkbox"
        name="acknowledgeAttorney"
        checked={formData.acknowledgeAttorney}
        onChange={handleInputChange}
      />
      
    
    </label>
</div>
</div>
</>}


{formData.appliedForEntryPermit === 'No'
  &&
  <div>
  <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>Applied for and/or received any benefit from such country (for example, health insurance benefits)?</label>
  <div className='flex bg-blue-100 p-4 gap-4'>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="receivedBenefitFromCountry"
        value="Yes"
        checked={formData.receivedBenefitFromCountry === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="receivedBenefitFromCountry"
        value="No"
        checked={formData.receivedBenefitFromCountry === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>}



{formData.receivedBenefitFromCountry === 'No'
  &&
  <div>
  <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>Reacquired the nationality of the country named above?</label>
  <div className='flex bg-blue-100 p-4 gap-4'>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="reacquiredNationality"
        value="Yes"
        checked={formData.reacquiredNationality === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="reacquiredNationality"
        value="No"
        checked={formData.reacquiredNationality === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>}




{formData.reacquiredNationality === 'Yes'
  
  &&
  <>
<div className='bg-orange-200 p-2'>
           
           <div className='flex gap-4'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
             Your answer to this question suggests that you have a history which may require the assistance of a legal professional </span> We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
           </div>

           <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt=""  className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
            Help me find an immigration attorney . </span></p>
           </div>
          </div>
          <div>
  <div className='bg-green-200 rounded  p-2  md:w-[700px] flex md:ml-auto'>
  <label className="">
    I acknowledge that I have spoken to an attorney and / or want to continue anyway
  </label>
    <label>
      <input
        type="checkbox"
        name="acknowledgeAttorney"
        checked={formData.acknowledgeAttorney}
        onChange={handleInputChange}
      />
      
    
    </label>
</div>
</div>
</>}





{formData.reacquiredNationality === 'No'
  &&
  <div>
  <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>Acquired a new nationality?</label>
  <div className='flex bg-blue-100 p-4 gap-4'>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="acquiredNewNationality"
        value="Yes"
        checked={formData.acquiredNewNationality === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="acquiredNewNationality"
        value="No"
        checked={formData.acquiredNewNationality === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>
}


{formData.reacquiredNationality === 'Yes'
  
  &&
  <>
<div className='bg-orange-200 p-2'>
           
           <div className='flex gap-4'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
             Your answer to this question suggests that you have a history which may require the assistance of a legal professional </span> We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
           </div>

           <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt=""  className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
            Help me find an immigration attorney . </span></p>
           </div>
          </div>
          <div>
  <div className='bg-green-200 rounded  p-2  w-full md:w-[700px] flex ml-auto'>
  <label className="">
    I acknowledge that I have spoken to an attorney and / or want to continue anyway
  </label>
    <label>
      <input
        type="checkbox"
        name="acknowledgeAttorney"
        checked={formData.acknowledgeAttorney}
        onChange={handleInputChange}
      />
      
    
    </label>
</div>
</div>
</>}


{formData.acquiredNewNationality === 'No'
  &&
  <div>
  <label className='flex bg-blue-700 p-4 text-white rounded mt-10'>Been granted refugee or asylee status in any other country?</label>
  <div className='flex bg-blue-100 p-4 gap-4'>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="grantedStatusInOtherCountry"
        value="Yes"
        checked={formData.grantedStatusInOtherCountry === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700 gap-4'>
      <input
        type="radio"
        name="grantedStatusInOtherCountry"
        value="No"
        checked={formData.grantedStatusInOtherCountry === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>}


{formData.grantedStatusInOtherCountry === 'Yes'
  
  &&
  <>
<div className='bg-orange-200 p-2'>
           
           <div className='flex gap-4'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
             Your answer to this question suggests that you have a history which may require the assistance of a legal professional </span> We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.</p>
           </div>

           <div className='flex gap-2'>
            <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt=""  className='w-[40px] h-[40px]'/>
            <p>  <span className=' font-bold'>
            Help me find an immigration attorney . </span></p>
           </div>
          </div>
          <div>
  <div className='bg-green-200 rounded  p-2 w-full  md:w-[700px] flex ml-auto'>
  <label className="">
    I acknowledge that I have spoken to an attorney and / or want to continue anyway
  </label>
    <label>
      <input
        type="checkbox"
        name="acknowledgeAttorney"
        checked={formData.acknowledgeAttorney}
        onChange={handleInputChange}
      />
      
    
    </label>
</div>
</div>
</>}





  
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
      </div>
    )
 
}

export default TravelInformation130
