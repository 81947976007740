import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from "../I-1765/Options"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const ApplicantInformationInformation = () => {
  const navigate = useNavigate("")
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  const handleSubmit = (e) => {
    e.preventDefault()

    navigate("/Applicantinfo130")
  }

  console.log(formData.countryOfBirth)

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Address Information
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 1 of 3</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            First, let's determine your eligibility to prepare Form I-864,
            Affidavit of Support
          </span>
        </div>
        <form action=' ' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          <div>
            <div className='mt-10'>
              <label className='bg-blue-600  text-white p-4 flex'>
                Have you ever been employed?
              </label>
              <div className='flex gap-4 bg-blue-100 p-4 text-blue-600'>
                <label>
                  <input
                    type='radio'
                    name='employed'
                    value='Yes'
                    checked={formData.employed === "Yes"}
                    onChange={handleInputChange}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type='radio'
                    name='employed'
                    value='No'
                    checked={formData.employed === "No"}
                    onChange={handleInputChange}
                  />
                  No
                </label>
              </div>
            </div>

            <div className='mt-10'>
              <label className='bg-blue-600  text-white p-4 flex'>
                In the most recent period up until today, which best describes
                your employment status?
              </label>
              <div className='flex gap-2 bg-blue-100 p-4 txt-blue-600 flex-col text-blue-600'>
                <label>
                  <input
                    type='radio'
                    name='employmentStatus'
                    value='Employed'
                    checked={formData.employmentStatus === "Employed"}
                    onChange={handleInputChange}
                  />
                  Employed
                </label>
                <label>
                  <input
                    type='radio'
                    name='employmentStatus'
                    value='Self-Employed'
                    checked={formData.employmentStatus === "Self-Employed"}
                    onChange={handleInputChange}
                  />
                  Self-Employed
                </label>
                <label>
                  <input
                    type='radio'
                    name='employmentStatus'
                    value='Unemployed-Student'
                    checked={formData.employmentStatus === "Unemployed-Student"}
                    onChange={handleInputChange}
                  />
                  Unemployed - Student
                </label>
                <label>
                  <input
                    type='radio'
                    name='employmentStatus'
                    value='Unemployed-Homemaker'
                    checked={
                      formData.employmentStatus === "Unemployed-Homemaker"
                    }
                    onChange={handleInputChange}
                  />
                  Unemployed - Homemaker
                </label>
                <label>
                  <input
                    type='radio'
                    name='employmentStatus'
                    value='Unemployed-Retired'
                    checked={formData.employmentStatus === "Unemployed-Retired"}
                    onChange={handleInputChange}
                  />
                  Unemployed - Retired
                </label>
                <label>
                  <input
                    type='radio'
                    name='employmentStatus'
                    value='Unemployed'
                    checked={formData.employmentStatus === "Unemployed"}
                    onChange={handleInputChange}
                  />
                  Unemployed
                </label>
              </div>
            </div>
          </div>

          <hr className='h-1 bg-blue-600 text-white mt-10' />

          <h1 className='flex text-blue-600 font-semibold text-sl'>
            Current Employment Information
          </h1>

          <p>
            Provide information about your current employer and occupation. If
            you are not working now, select "Unemployed."
          </p>

          <div className='mt-20'>
            <div className='mt-10 flex gap-10 '>
              <label className='text-blue-600 block w-[200px]'>
                In Care of Name (if any)
              </label>
              <input
                type='text'
                name='applicantMailingAddressinCareOfName3'
                value={formData.applicantMailingAddressinCareOfName3}
                onChange={handleInputChange}
                className='w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600'
              />
            </div>

            <div className='mt-10 flex gap-10 '>
              <label className='text-blue-600 block w-[200px]'>
                Street Number and Name
              </label>
              <input
                type='text'
                name='applicantMailingAddressstreetNumberAndName3'
                value={formData.applicantMailingAddressstreetNumberAndName3}
                onChange={handleInputChange}
                className='w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600'
              />
            </div>

            <div className='mt-10 flex gap-10 '>
              <label className='text-blue-600 block w-[200px]'>
                City or Town
              </label>
              <input
                type='text'
                name='applicantMailingAddresscityOrTown3'
                value={formData.applicantMailingAddresscityOrTown3}
                onChange={handleInputChange}
                className='w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600'
              />
            </div>

            <div className='mt-10 flex gap-10'>
              <label className='text-blue-600 block w-[200px]'>
                State (or Territory)
              </label>
              <input
                type='text'
                name='applicantMailingAddressstateOrTerritory3'
                value={formData.applicantMailingAddressstateOrTerritory3}
                onChange={handleInputChange}
                className='w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600'
              />
            </div>

            <div className='mt-10 flex gap-10 '>
              <label className='text-blue-600 block w-[200px]'>ZIP Code</label>
              <input
                type='text'
                name='applicantMailingAddresszipCode3'
                value={formData.applicantMailingAddresszipCode3}
                onChange={handleInputChange}
                className='w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600'
              />
            </div>
          </div>

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default ApplicantInformationInformation
