

import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import FormFotter from '../../components/FormFotter';
import Header from '../../components/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from '../../UseContext/UseContextForI-I29F';

const BenificairyInformationI129F = () => {
    const { state, setFormState } = useFormContext();

    const [countries, setCountries] = useState([]);
    const [countriesNew, setNewCountries] = useState([]);

    // Fetch countries from the API when the component mounts
    useEffect(() => {
      fetch('https://restcountries.com/v3.1/all')
        .then((response) => response.json())
        .then((data) => {
          setCountries(data);
          setNewCountries(data)
        })
        .catch((error) => {
          console.error('Error fetching countries:', error);
        });
    }, []);
  
    const handleCountryChange = (e) => {
      const selectedCountry = e.target.value;
      setFormState('SET_COUNTRY_OF_BIRTH', selectedCountry); // Update your context state
    };

    const handleHasBeneficiaryBeenInUSChange = (e) => {
        const value = e.target.value;
        setFormState('SET_HAS_BENEFICIARY_BEEN_IN_US', value);
    };
    
    const handleIsBeneficiaryCurrentlyInUSChange = (e) => {
        const value = e.target.value;
        setFormState('SET_IS_BENEFICIARY_CURRENTLY_IN_US', value);
    };

    
    

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        setFormState('SET_CITY_OF_BIRTH', selectedCity); // Update your context state
    };

    const handleCountryOfCitizenShipChange = (e) => {
        const selectedCountry = e.target.value;
        setFormState('SET_COUNTRY_OF_CITIZENSHIP', selectedCountry);
    };

    const handleSexChange = (e) => {
        const selectedSex = e.target.value;
        setFormState('SET_SEX', selectedSex);
    };

    const handleUSPhoneNumberChange = (e) => {
        const usTelephoneNumber = e.target.value;
        setFormState('SET_US_TELEPHONE_NUMBER', usTelephoneNumber);
    };

    const handleDateOfBirthChange = (e) => {
        const value = e.target.value;
        setFormState('SET_DATE_OF_BIRTH', value);
    };

    const handleCityOfBirthChange = (e) => {
        const value = e.target.value;
        setFormState('SET_CITY_OF_BIRTH', value);
    };

    const handleForeignPhoneNumberChange = (e) => {
        const value = e.target.value;
        setFormState('SET_FOREIGN_TELEPHONE_NUMBER', value);
    };

    const handleAlienRegistrationNumberChange = (e) => {
        const value = e.target.value;
        setFormState('SET_ALIEN_REGISTRATION_NUMBER', value);
    };
    
    const handleSocialSecurityNumberChange = (e) => {
        const value = e.target.value;
        setFormState('SET_SOCIAL_SECURITY_NUMBER', value);
    };

    const handleRadioChange = (e) => {
        const value = e.target.value === 'Yes';
        setFormState('SET_HAVE_OTHER_EMPLOYERS', value);
    };

    const handleGivenNameChange = (e) => {
        const value = e.target.value;
        setFormState('SET_GIVEN_NAME', value);
    };
    
    const handleMiddleNamesChange = (e) => {
        const value = e.target.value;
        setFormState('SET_MIDDLE_NAMES', value);
    };

    const handleFamilyNameChange = (e) => {
        const value = e.target.value;
        setFormState('SET_FAMILY_NAME', value);
    };
    const handleFianceMarriageStatusChange = (e) => {
        setFormState('SET_FIANCE_MARRIAGE_STATUS', e.target.value);
      };
    
      

  


    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
          <Header />
          <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
    
              <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
              <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
              <div className='h-full'>
                <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
                <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
              </div>
            </div>
    
    
    
    
    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
        <span className='mt-4 font-bold text-xl ml-2 text-black '>
          First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
        </span>
        <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
          <button className='mr-2'>Page 1 of 3</button>
          <button><MdArrowDropDown /></button>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
    </div>
    
            <form action=" " className='mt-2 w-11/12  '>
            <h2 className='text-blue-500  font-bold text-xl font-sans mt-20'>Current Employment</h2>
              <p className='font-thin'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
                  
                  
    

     <div className='flex flex-col gap-3 mt-10'>
    <div className='flex gap-5'>
      <label className='flex  text-blue-600 w-[200px]'>Given Name (First Name) ::</label>
      <input
        type="text"
        value={state.givenName}
        onChange={handleGivenNameChange}
        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      />
     </div>
    
      <div className='flex gap-3'>
      <label className='flex text-blue-600 w-[200px]'>Middle Name(s):  </label>
      <input
        type="text"
        value={state.middleNames}
        onChange={handleMiddleNamesChange}
        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      />
    </div>
  
    <div className='flex gap-3'>
      <label className='flex text-blue-600 w-[200px]'>Family Name (Last Name): </label>
      <input
        type="text"
        value={state.familyName}
        onChange={handleFamilyNameChange}
        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      />
     </div>
    </div>


    <hr className='h-1 bg-blue-600 w-full mt-10'/>


    <h1 className='text-blue-600 font-semibold text-xl mt-10 '>Other Name(s)</h1>
    <p>Provide any other names that your beneficiary has ever used, including maiden names, names from a previous marriage, aliases or nicknames.</p>
      
    <div className='flex  flex-col'>
    <p className='flex bg-blue-600 text-white p-4 rounded mt-10'>Has your beneficiary ever used another name (such as a maiden name)?</p>
       <div className='flex gap-2 text-blue-600 bg-blue-100 p-4'>
      <label className='flex gap-1'>
        <input
          type="radio"
          value="Yes"
          checked={state.haveOtherEmployers === true}
          onChange={handleRadioChange}
        />
         Yes
      </label>
      <label className='flex gap-1'>
        <input
          type="radio"
          value="No"
          checked={state.haveOtherEmployers === false}
          onChange={handleRadioChange}
        />
           No
      </label>
      </div>
      <p className='bg-slate-100 p-2'>Indicate if your beneficiary has ever used a name from a previous marriage, alias, nickname, or used any other name.</p>
    </div>


    <hr  className='h-1 mt-10 bg-blue-600 w-full'/>


    <h1 className='flex text-blue-600 text-xl font-bold mt-10'>Beneficiary's Contact Information</h1>
    <p>Provide a daytime telephone number where your beneficiary can be reached in his/her home country as well as a phone number in the United States. You can use your own number for the U.S. phone number.</p>
   

    <div className='flex gap-5 flex-col mt-10'>
      <div className='flex gap-4'>
        <label className='w-[200px] text-blue-600'>U.S. Telephone Number:</label>
        <input
          type="text"
          value={state.usTelephoneNumber}
          required
          onChange={handleUSPhoneNumberChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
      </div>
      <div className='flex gap-4 text-center'>
        <label  className='w-[200px] text-blue-600'>Foreign Telephone Number:</label>
        <input
          type="text"
          value={state.foreignTelephoneNumber}
          required
          onChange={handleForeignPhoneNumberChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
      </div>
    </div>


<hr  className='h-1 bg-blue-600 w-full mt-10'/>

<h1 className='h1  text-blue-600 mt-10 text-xl font-bold'>Beneficiary's Birth Information</h1>
    
     
<div className='mt-10 flex flex-col gap-10'>
    <div>
      <h2 className='bg-blue-600 text-white  p-4 rounded'>Date of Birth</h2>
      <div className='bg-blue-100  p-4'>
      <input
        type="text"
        value={state.dateOfBirth}
        onChange={handleDateOfBirthChange}
        placeholder="dd/mm/yyyy"
        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      />
      </div>
   </div>
    <div>
      <h2 className='bg-blue-600 text-white  p-4 rounded'>City of Birth</h2>
      <div className='bg-blue-100 p-4'>
      <input
        type="text"
        value={state.cityOfBirth}
        onChange={handleCityChange}
        placeholder="City"
        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      />
      </div>
</div>
    </div>


    <div className='mt-10'>
      <h2 className='bg-blue-600 text-white p-4 rounded-md'>Country of Birth</h2>
      <div className="flex bg-blue-100  p-4">
      <select
        value={state.countryOfBirth}
        onChange={handleCountryChange}
        className='border  w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      >
        <option value="">Select a country</option>
        {countries.map((country) => (
          <option key={country.cca2} value={country.name.common}>
            {country.name.common}
          </option>
        ))}
      </select>
      </div>
    </div>
 

      
<div className='mt-10'>
  <h2 className='bg-blue-600 text-white p-4 rounded-md'>Sex</h2>
  <div className="flex bg-blue-100 text-blue-600 p-4">
    <label className="mr-4">
      <input
        type="radio"
        name="sex"
        value="Male"
        checked={state.sex === 'Male'}
        onChange={handleSexChange}
      /> Male
    </label>
    <label>
      <input
        type="radio"
        name="sex"
        value="Female"
        checked={state.sex === 'Female'}
        onChange={handleSexChange}
      /> Female
    </label>
  </div>
  </div>

  <hr  className='h-1 bg-blue-600 mt-10' />


  <h1 className='flex text-blue-600 text-xl font-semibold'>Beneficiary's Immigration History
</h1>
<p>Select the name of the country where your beneficiary is currently a citizen or national. If stateless, select the name of the country where he or she was last a citizen or national. If your beneficiary is a citizen or national of more than one country, select the name of the country that issued his or her most recent passport.
Country of Citizenship or Nationality</p>

<div className='mt-10'>
      <h2 className='bg-blue-600 text-white p-4 rounded-md'>Country of Birth</h2>
      <div className="flex bg-blue-100  p-4">
      <select
        value={state.countryOfCitizenship}
        onChange={handleCountryOfCitizenShipChange}
        className='border  w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
      >
        <option value="">Select a country</option>
        {countriesNew.map((country) => (
          <option key={country.cca2} value={country.name.common}>
            {country.name.common}
          </option>
        ))}
      </select>
      </div>
    </div>
                  

    <div className='mt-10'>
  <h2 className='bg-blue-600 text-white   p-4 rounded-md'>
    Has your beneficiary EVER been in the United States?
  </h2>
  <div className="flex bg-blue-100 text-blue-600 p-4">
    <label className="mr-4">
      <input
        type="radio"
        name="hasBeneficiaryBeenInUS"
        value="Yes"
        checked={state.hasBeneficiaryBeenInUS === 'Yes'}
        onChange={handleHasBeneficiaryBeenInUSChange}
      /> Yes
    </label>
    <label>
      <input
        type="radio"
        name="hasBeneficiaryBeenInUS"
        value="No"
        checked={state.hasBeneficiaryBeenInUS === 'No'}
        onChange={handleHasBeneficiaryBeenInUSChange}
      /> No
    </label>
  </div>
</div>

<div className='mt-10'>
  <h2 className='bg-blue-600  text-white  p-4 rounded-md'>
    Is your beneficiary currently in the U.S.?
  </h2>
  <div className="flex bg-blue-100 text-blue-600  p-4">
    <label className="mr-4">
      <input
        type="radio"
        name="isBeneficiaryCurrentlyInUS"
        value="Yes"
        checked={state.isBeneficiaryCurrentlyInUS === 'Yes'}
        onChange={handleIsBeneficiaryCurrentlyInUSChange}
      /> Yes
    </label>
    <label>
      <input
        type="radio"
        name="isBeneficiaryCurrentlyInUS"
        value="No"
        checked={state.isBeneficiaryCurrentlyInUS === 'No'}
        onChange={handleIsBeneficiaryCurrentlyInUSChange}
      /> No
    </label>
  </div>
</div>

<hr  className='flex bg-blue-600 mt-10 h-1'/>

<h1 className='text-blue-600 font-semibold text-xl'>Other Information</h1>

<div className='mt-10'>
  <h2 className='bg-blue-600 text-white p-4 rounded-md'>
    Alien Registration Number (A-Number)
  </h2>
  <div className="bg-blue-100 p-4">
    <input
      type="text"
      value={state.alienRegistrationNumber}
      onChange={handleAlienRegistrationNumberChange}
      placeholder="A-Number"
      className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
    />
  </div>
</div>

<div className='mt-10'>
  <h2 className='bg-blue-600 text-white p-4 rounded-md'>
    Social Security Number (if any)
  </h2>
  <div className="bg-blue-100 p-4">
    <input
      type="text"
      value={state.socialSecurityNumber}
      onChange={handleSocialSecurityNumberChange}
      placeholder="Social Security Number"
      className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
    />
  </div>
</div>




     


                    

        
    
                     
    
    
                 
                 
    
    
          <button
            type="button"
            className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
          >
            Add Another Name
          </button>
  
                  
            </form>
            <FormFotter />
          </div>
        </div>
      )
}

export default BenificairyInformationI129F










