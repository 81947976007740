const stateData = [
    { name: 'Alabama', region: 'Southeast' },
    { name: 'Alaska', region: 'West' },
    { name: 'Arizona', region: 'Southwest' },
    { name: 'Arkansas', region: 'South' },
    { name: 'California', region: 'West' },
    { name: 'Colorado', region: 'West' },
    { name: 'Connecticut', region: 'Northeast' },
    { name: 'Delaware', region: 'Northeast' },
    { name: 'Florida', region: 'Southeast' },
    { name: 'Georgia', region: 'Southeast' },
    { name: 'Hawaii', region: 'West' },
    { name: 'Idaho', region: 'West' },
    { name: 'Illinois', region: 'Midwest' },
    { name: 'Indiana', region: 'Midwest' },
    { name: 'Iowa', region: 'Midwest' },
    { name: 'Kansas', region: 'Midwest' },
    { name: 'Kentucky', region: 'Southeast' },
    { name: 'Louisiana', region: 'South' },
    { name: 'Maine', region: 'Northeast' },
    { name: 'Maryland', region: 'Northeast' },
    { name: 'Massachusetts', region: 'Northeast' },
    { name: 'Michigan', region: 'Midwest' },
    { name: 'Minnesota', region: 'Midwest' },
    { name: 'Mississippi', region: 'South' },
    { name: 'Missouri', region: 'Midwest' },
    { name: 'Montana', region: 'West' },
    { name: 'Nebraska', region: 'Midwest' },
    { name: 'Nevada', region: 'West' },
    { name: 'New Hampshire', region: 'Northeast' },
    { name: 'New Jersey', region: 'Northeast' },
    { name: 'New Mexico', region: 'Southwest' },
    { name: 'New York', region: 'Northeast' },
    { name: 'North Carolina', region: 'Southeast' },
    { name: 'North Dakota', region: 'Midwest' },
    { name: 'Ohio', region: 'Midwest' },
    { name: 'Oklahoma', region: 'South' },
    { name: 'Oregon', region: 'West' },
    { name: 'Pennsylvania', region: 'Northeast' },
    { name: 'Rhode Island', region: 'Northeast' },
    { name: 'South Carolina', region: 'Southeast' },
    { name: 'South Dakota', region: 'Midwest' },
    { name: 'Tennessee', region: 'Southeast' },
    { name: 'Texas', region: 'South' },
    { name: 'Utah', region: 'West' },
    { name: 'Vermont', region: 'Northeast' },
    { name: 'Virginia', region: 'Southeast' },
    { name: 'Washington', region: 'West' },
    { name: 'West Virginia', region: 'Southeast' },
    { name: 'Wisconsin', region: 'Midwest' },
    { name: 'Wyoming', region: 'West' },
    { name: 'District of Columbia', region: 'Northeast' },
    { name: 'Puerto Rico', region: 'US Territory' },
    { name: 'Guam', region: 'US Territory' },
    { name: 'American Samoa', region: 'US Territory' },
    { name: 'U.S. Virgin Islands', region: 'US Territory' },
    { name: 'Northern Mariana Islands', region: 'US Territory' },
  ];
  
  export default stateData;
  