import React, { useContext } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { ContextFor1762 } from '../../UseContext/ContextFor1762';
import { useEffect } from 'react';
import crosspic from "../../Assets/crosspic.png"
import {BsArrowRight }from "react-icons/bs"
import objectArray from './Options';
import ports from "../I-90/ports.js"
import { useNavigate } from 'react-router-dom';





const DashedLineWithText = () => {
  return (
    <div className="dashed-line text-center my-4 relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-dashed border-gray-200"></div>
      </div>
      <span className="relative bg-white px-2 text-gray-200 font-bold ">Insert any another name</span>
    </div>
  );
};


const ImmigrantHistory = () => {

  const navigate = useNavigate()
  const { nameSetsCountry,
    handleAddAnotherCountry,
    handleRemoveCountry,
    updateStateCountry,
    countryNames, // Include countryNames in the context
    setGlobalCountryNames,
    handleOptionChange,
    selectedOption,
    travelDocument,
    setTravelDocument,
    expirationDate,
    setExpirationDate,
    countryOfIssuance,
    setCountryOfIssuance,
    handleSubmitI762,
    selectedSecondCountry,
    setSelectedSecondCountry,
    i94Number, setI94Number,
    lastArrivalDate, updateLastArrivalDate ,
    overstayedVisa,
    updateOverstayedVisa,
    selectedState, updateSelectedState ,
    selectedOptionForState,
    handleChangeForState,
    selectedImmigrantStatusOption,
    handleChangeForimmigrationstatus,
    handelChangeRecentImmgigrationStatus,
    recentImmgigrationStatus,
    handelChangeRecentImmgigrationStatusSecond,
    recentImmgigrationStatusSecond



  } = useContext(ContextFor1762);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v2/all');
        const data = await response.json();

        const names = data.map((country) => country.name);
        setGlobalCountryNames(names);

        // Update the nameSetsCountry state

      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);


  const handleTravelDocumentChange = (e) => {
    setTravelDocument(e.target.value);
  };

  const handleExpirationDateChange = (e) => {
    setExpirationDate(e.target.value);
  };
  const handleChangeI94 = (e) => {
    setI94Number(e.target.value);
  };

  const handleDateChange = (event) => {
    updateLastArrivalDate(event.target.value);
  };

  const handleRadioChange = (event) => {
    updateOverstayedVisa(event.target.value);
  };
  const handleStateChange = (event) => {
    updateSelectedState(event.target.value);
  };

  // const handleSelectChange = (event) => {
  //   handleChange(event.target.value);
  // };

  const HandleFormSubmit = (e) => {
    e.preventDefault()
    console.log('nameSetsCountry:', nameSetsCountry);
    // console.log('countryNames:', countryNames);
    console.log('selectedOption:', selectedOption);
    console.log('travelDocument:', travelDocument);
    console.log('expirationDate:', expirationDate);
    console.log('countryOfIssuance:', countryOfIssuance);

    navigate("/ApplicatInfo")

  }

  console.log(selectedImmigrantStatusOption)
  // console.log(selectedOptionForState)

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-row'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's Immigration History
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 1 of 3</button>
                <button><MdArrowDropDown /></button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            Tell us about your immigration history
          </span>
        </div>
        <form action=" " className='mt-8 w-11/12  ' onSubmit={HandleFormSubmit} >


          <h1 className='text-blue-600 text-xl font-semibold '>
            Country of Citizenship or Nationality
          </h1>
          <p className='font-thin'>List all countries where you are currently a citizen or national. If you are a citizen or national of more than one country, click the "Add Another Country" button to add another record. If you are stateless, select the name of the country where you were last a citizen or national.</p>

          <DashedLineWithText />
          <div className='p-10 bg-blue-50 mt-5 rounded'>
            {nameSetsCountry.map((nameSet, index) => (
              <>

                <div key={nameSet.id} className='bg-white  rounded border border-blue-200  mt-10 py-2'>

                  {index > 0 && (
                    <div className='flex w-full justify-between ml-2'>
                      <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
                      <button
                        type="button"
                        onClick={() => handleRemoveCountry(nameSet.id)}
                        className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
                      >
                        Remove
                        <img src={crosspic} alt="" />
                      </button>
                    </div>
                  )}

                  <div className="mb-4 flex mt-6 ml-2">
                    <label htmlFor={`countryName-${nameSet.id}`} className="text-blue-700">
                      Country: <span className="text-red-500">*</span>
                    </label>
                    <select
                      id={`countryName-${nameSet.id}`}
                      name={`countryName-${nameSet.id}`}
                      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                      style={{ width: 'calc(100% - 75%)' }}
                      value={nameSet.state || ''}
                      onChange={(e) => updateStateCountry(nameSet.id, e.target.value)}
                    >
                      {countryNames.map((country) => (
                        <option key={country} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            ))}

            {nameSetsCountry.length === 0 && (
              <div>
                <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                <span className="text-gray-500">Add Other</span>
              </div>
            )}

            <button
              type="button"
              onClick={handleAddAnotherCountry}
              className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
            >
              Add Another Country
            </button>



          </div>

          <hr className='h-1 bg-blue-700 w-full mt-10' />

          <h1 className='text-blue-700 text-xl font-semibold mt-4'>Passport/Travel Document</h1>
          <p className='font-thin'>Enter your passport (or travel document) details below from your most recent entry to the United States.</p>


          <div className="p-4 rounded text-white bg-blue-700 mt-10">
            <span>On your most recent entry to the United States, did you have a passport or travel document? <span className="text-red-500">*</span></span>
          </div>
          <div className="radio-container flex flex-col bg-blue-100 ">
            <label className="flex p-2 text-bg-500 ">
              <input
                type="radio"
                value="Passport"
                checked={selectedOption === 'Passport'}
                onChange={() => handleOptionChange('Passport')}
              
              />
              Passport
            </label>
            <hr className='h-0.5 bg-blue-200' />

            <label className="flex gap-2 p-2 text-bg-500">
              <input
                type="radio"
                value="Travel Document"
                checked={selectedOption === 'Travel Document'}
                onChange={() => handleOptionChange('Travel Document')}
              />
              Travel Document
            </label>
            <hr className='h-0.5 bg-blue-200' />
            <label className="flex gap-2 p-2 text-bg-500">
              <input
                type="radio"
                value="Neither"
                checked={selectedOption === 'Neither'}
                onChange={() => handleOptionChange('Neither')}
              />
              Neither
            </label>
          </div>


          {selectedOption === "Neither" ? null : (
            <div className="mt-6 flex flex-col">
              <div className="mb-4 flex mt-6 ml-2 gap-14">
                <label htmlFor="travelDocumentNumber" className="text-blue-700 ml-4 ">
                  {selectedOption === 'Passport' ? 'Passport Number:' :
                    selectedOption === 'Travel Document' ? 'Travel Document Number:' :
                      ''
                  }
                </label>
                <input
                  type="text"
                  id="travelDocumentNumber"
                  name="travelDocumentNumber"
                  className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
                  value={travelDocument}
                  onChange={handleTravelDocumentChange}
                />
              </div>

              <div className="mb-4 flex mt-6 ml-2 gap-12">
                <label htmlFor="expirationDate" className="text-blue-700">
                  Expiration Date:
                </label>
                <input
                  type="date"
                  id="expirationDate"
                  name="expirationDate"
                  className="pl-2 pr-2 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-14"
                  style={{ width: 'calc(100% - 73%)' }}
                  value={expirationDate}
                  onChange={handleExpirationDateChange}
                />
              </div>

         <div>
      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor="countryOfIssuance" className="text-blue-700">
          Country of Issuance: <span className="text-red-500">*</span>
        </label>
        <select
          id="countryOfIssuance"
          name="countryOfIssuance"
          className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
          value={selectedSecondCountry}
          onChange={(e) => setSelectedSecondCountry(e.target.value)}
        >
          {countryOfIssuance.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
      </div>
    </div>

            </div>
          )}


          <hr  className='h-1 bg-blue-700  w-full mt-10' />

          <div className='mt-5'>
             <h1 className='text-blue-700 text-xl font-semibold'>I-94 Arrival/Departure Record</h1>
             <p className='mt-2 text-gray-500 text-sm'>You most likely have an I-94 Arrival/Departure Record if you entered the U.S. lawfully. The information to answer the next few questions can be found in the I-94 record from your most recent arrival to the United States. If you do not have Form I-94, please refer to your passport.</p>
         
          </div>
          
          <div className='flex flex-col gap-2 mt-10'>
             <p className='text-blue-500'>How to Find an I-94 Record </p>

             <p className='text-blue-500'>How to Read an I-94 Record</p>
             </div>
    <div className='flex flex-col mt-10'>
  <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
    I-94 Number: <span className="text-red-500">*</span>
  </label>
  <div className='w-full bg-blue-100 p-3'>
  <input
    type="text"
    id="i94Number"
    name="i94Number"
    value={i94Number}
    onChange={handleChangeI94}
    className="p-2 rounded border border-gray-300 hover:border-2 px-8 py-2 ml-2 hover:border-indigo-600 focus:border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none"
  />
</div>
<p className='p-2  bg-slate-200'>Enter the I-94 number from your Arrival/Departure Record (I-94). If you do not have an I-94 record, leave this field empty.</p>
</div>


<hr  className='h-1 bg-blue-700 w-full mt-10'/>


<div>
  <h1 className='text-blue-700  font-semibold  text-xl mt-3'>Information About Your Last Arrival</h1>
  <p className='mt-2 text-gray-500 text-sm'>Select the visa category you held when you most recently entered the U.S. and were admitted by a U.S. Customs and Border Protection (CBP) officer. The visa category will be listed on your I-94 record. You may also find it on the visa inside your passport. If you do not remember your visa category, please select "Other," and enter the written word for a broad category such as Visitor or Student.Select your immigration status when you most recently</p>
</div>

        



<div className='flex flex-col mt-10'>
  <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
  Select your immigration status when you most recently entered the United States:
 <span className="text-red-500">*</span>
  </label>

<div className=' bg-blue-100  p-4 '>
      <select size="10" className='w-[1000px] ml-3  border border-slate-300 rounded outline-none' value={selectedImmigrantStatusOption} onChange={(e)=>handleChangeForimmigrationstatus(e.target.value)}>
        {objectArray.map((option, index) => (
          <option key={index} value={option.value} className='p-1 text-gray-500 '>  {option.code} - {option.value}</option>
        ))}
      </select>
    </div>

</div>
<p className='p-2 bg-slate-100'>Enter the I-94 number from your Arrival/Departure Record (I-94). If you do not have an I-94 record, leave this field empty.</p>

{selectedImmigrantStatusOption ==="Other"
   &&
   <div className='mt-10 flex flex-col'>
      <label htmlFor="inputLabel" className="p-4 rounded text-white bg-blue-700">
        Enter your immigration status when you most recently entered the U.S.:
        <span className="text-red-500">*</span>
      </label>
      <div className='p-4 bg-blue-100'>
      <input
      className='w-[300px] py-2 outline-none rounded border border-gray-300 hover:border-2 ml-2 hover:border-indigo-600 focus:border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none'
        type="text"
        id="inputLabel"
        value={recentImmgigrationStatus}
        onChange={(e) => handelChangeRecentImmgigrationStatus(e.target.value)}
      />
      </div>
    </div>}






<div className='flex flex-col mt-10'>
  <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
  Date of Your Last Arrival Into the U.S.
 <span className="text-red-500">*</span>
 
 
  </label>
    
    <div className='bg-blue-100 w-full p-4'>
      <input
        type="date"
        value={lastArrivalDate}
        onChange={handleDateChange}
        className="p-4  mr-4 rounded border border-gray-300 hover:border-2 py-2 ml-2 hover:border-indigo-600 focus:border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none"
        style={{ width: 'calc(100% - 75%)' }}
      />
    </div>
    <p className='p-2 bg-slate-100'>
The date of last arrival is the date you most recently entered the U.S. which may be more recent than your I-94 record.</p>
    </div>




            <div className='flex  mt-10 w-full flex-col'>
            <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
            Have you overstayed your visa?
        <span className="text-red-500">*</span>
          </label>
    <div className='flex p-4 gap-2 bg-blue-100   text-blue-500'>
      <div className='flex gap-2'>
        <input
          type="radio"
          id="yes"
          name="overstayedVisa"
          value="Yes"
          checked={overstayedVisa === 'Yes'}
          onChange={handleRadioChange}
        />
        <label htmlFor="yes">Yes</label>
      </div>
      <div className='flex gap-2  text-blue-500'>
        <input
          type="radio"
          id="no"
          name="overstayedVisa"
          value="No"
          checked={overstayedVisa === 'No'}
          onChange={handleRadioChange}
        
        />
        <label htmlFor="no">No</label>
      </div>
    </div>
    <p className='p-2 bg-slate-200'>Indicate if you have stayed beyond the "Admit Until" date on your I-94.</p>
    </div>
   
   {overstayedVisa === 'Yes'  &&  <div className='flex gap-2 items-center bg-orange-300'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt="" />
      <p> <span className='font-bold'>For Your Information: </span>Immediate relatives (parents, spouse, and unmarried children under 21) of U.S. citizens may file Form I-485 and I-765 after a visa overstay. If you are not an immediate relative, we recommend that you stop here and speak to an immigration attorney.</p>
     </div>}




    <div className='flex  mt-10 w-full flex-col'>
            <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
            Select a State:
                <span className="text-red-500">*</span>
                  </label>
    <div className='flex p-4 bg-blue-100'>

              <select value={selectedState} onChange={handleStateChange} className='px-1 py-2 outline-none rounded border border-gray-300 hover:border-2 ml-2 hover:border-indigo-600 focus:border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none'>
          <option value="">Please select...</option>
          {ports.map((state) => (
            <option key={state.name} value={state.name}>
              {state.name}, {state.region}
            </option>
          ))}
        </select>
        </div>
        <p className='p-2  bg-slate-200'>Select the port of entry where you physically entered the United States. If you did not enter the country through an official port of entry, select “Other” and type the name of the area you entered.</p>
    </div>


    <hr  className='mt-14  h-1 bg-blue-700'/>
 

    <div className='mt-5'>
      <h1 className='text-xl font-semibold  text-blue-500 '>Current Immigration Status or Category</h1>
      <p className='text-sm'>Now, select your <u>current</u> immigration status. For most people, their current immigration status will be the same. However, other people have changed status since <br /> entering the United States.</p>
    </div>

    <div className='flex flex-col mt-10'>
    <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
  Select your immigration status when you most recently entered the United States:
 <span className="text-red-500">*</span>
  </label>
    <div className='bg-blue-100 p-2'>
      <select
        size="10"
        value={selectedOptionForState}
        onChange={(e) => handleChangeForState(e.target.value)}
        className='w-[1000px] ml-3  border border-slate-300 rounded outline-none'
      >
        <option value="">Please select...</option>
        {objectArray.map((option) => (
          <option key={option.value} value={option.value} className='p-1 text-gray-500'>
           {option.code}- {option.value}
          </option>
        ))}
      </select>
    </div>
    </div>
   


   {selectedOptionForState ==="Other"
   &&
   <div className='mt-10 flex  flex-col'>
    <label htmlFor="i94Number" className="p-4 rounded text-white bg-blue-700">
    Enter your immigration status when you most recently entered the U.S.:
          <span className="text-red-500">*</span>
      </label>
      <div className='bg-blue-100 p-4'>
      <input
      className='w-[300px] py-2 outline-none rounded border border-gray-300 hover:border-2 ml-2 hover:border-indigo-600 focus:border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none'
        type="text"
        id="inputLabel"
        value={recentImmgigrationStatusSecond}
        onChange={(e) => handelChangeRecentImmgigrationStatusSecond(e.target.value)}
      />
      </div>
    </div>}























        
            
    <div className='flex  text-white mt-24  items center w-[100px] h-[40px]  ml-auto'>
            <div className='bg-green-600 flex items-center  justify-center  py-2 rounded-xl shadow  '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
            
          
        
          <div className='flex justify-end mt-2'>
            <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>





        </form>
        <FormFotter />
      </div>
    </div>
  );
};

export default ImmigrantHistory;
