




import React from 'react'

import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context134';


const FinancialSupport = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code ,territories ,handleSubmitI134 } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("")
    }

    console.log(formData.selectedTerritory)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            
  
    
     <div>
      {/* Radio Buttons for Form I-134 Preparation */}
      <div className="flex flex-col">
        <label className="bg-blue-700  flex p-4 text-white rounded">Who prepared this Form I-134?</label>
        <div className="bg-blue-100 p-4 rounded ">
           <div>
          <input
            type="radio"
            id="preparer-you"
            name="formPreparer"
            value="I am the beneficiary, and I prepared this form."
            checked={formData.formPreparer === 'I am the beneficiary, and I prepared this form.'}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
            onChange={handleInputChange}
          />
          <label htmlFor="preparer-you" className="ml-2 text-blue-600">I am the beneficiary, and I prepared this form.</label>
        </div>
        

        <div className="">
          <input
            type="radio"
            id="preparer-not-attorney"
            name="formPreparer"
            value="I am not an attorney or accredited representative but have prepared this form on behalf of the beneficiary and with the beneficiary's consent."
            checked={formData.formPreparer === "I am not an attorney or accredited representative but have prepared this form on behalf of the beneficiary and with the beneficiary's consent."}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
            onChange={handleInputChange}
          />
          <label htmlFor="preparer-not-attorney" className="ml-2 text-blue-600">I am not an attorney or accredited representative but have prepared this form on behalf of the beneficiary and with the beneficiary's consent.</label>
        </div>
        <div className="">
          <input
            type="radio"
            id="preparer-attorney"
            name="formPreparer"
            value="I am an attorney or accredited representative of the beneficiary that prepared this form."
            checked={formData.formPreparer === 'I am an attorney or accredited representative of the beneficiary that prepared this form.'}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
            onChange={handleInputChange}
          />
          <label htmlFor="preparer-attorney" className="ml-2 text-blue-600">I am an attorney or accredited representative of the beneficiary that prepared this form.</label>
        </div>
        </div>
      </div>

     

      {/* Radio Buttons for Representation of the Supporter */}
      {formData.formPreparer === 'I am an attorney or accredited representative of the beneficiary that prepared this form.'
        &&
        <div className="flex flex-col mt-10">
        <label className="bg-blue-700 text-white p-4 rounded">Representation of the supporter:</label>
         <div className='bg-blue-100 p-4 rounded'>
        <div className="">
          <input
            type="radio"
            id="representation-extends"
            name="representation"
            value="Extends beyond the preparation of this form."
            checked={formData.representation === 'Extends beyond the preparation of this form.'}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
            onChange={handleInputChange}
          />
          <label htmlFor="representation-extends" className="ml-2 text-blue-600">Extends beyond the preparation of this form.</label>
        </div>
        <div className="">
          <input
            type="radio"
            id="representation-does-not-extend"
            name="representation"
            value="Does not extend beyond the preparation of this form."
            checked={formData.representation === 'Does not extend beyond the preparation of this form.'}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4"
            onChange={handleInputChange}
          />
          <label htmlFor="representation-does-not-extend" className="ml-2 text-blue-600">Does not extend beyond the preparation of this form.</label>
        </div>
        </div>
      </div>}
    </div>




  {  formData.formPreparer === "I am not an attorney or accredited representative but have prepared this form on behalf of the beneficiary and with the beneficiary's consent."
  &&
  <>
  <hr  className='bg-blue-600 h-1 mt-10 '/>

    <div>
   
       <h1 className='text-blue-600 font-semibold text-xl'>Preparer's Information</h1>

      <div className=" flex  mt-10">
        <label htmlFor="preparerGivenName" className="text-blue-700 w-[200px]">
          Preparer's Given Name (First Name):
        </label>
        <input
          type="text"
          id="preparerGivenName"
          name="preparerGivenName"
          value={formData.preparerGivenName}
          onChange={handleInputChange}
          className="w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor="preparerFamilyName" className="text-blue-700 w-[200px]">
          Preparer's Family Name (Last Name):
        </label>
        <input
          type="text"
          id="preparerFamilyName"
          name="preparerFamilyName"
          value={formData.preparerFamilyName}
          onChange={handleInputChange}
          className="w-[300px]  border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor="preparerBusinessName" className="text-blue-700 w-[200px]">
          Preparer's Business or Organization Name:
        </label>
        <input
          type="text"
          id="preparerBusinessName"
          name="preparerBusinessName"
          value={formData.preparerBusinessName}
          onChange={handleInputChange}
          className="w-[300px]  border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

    </div>


   
    </>
}


<div>
     
      <hr  className='bg-blue-600 h-1 mt-10 '/>

      <h1 className='text-blue-600 font-semibold text-xl'>Interpreter</h1>
  
      <div className="flex flex-col mt-10">
        <label htmlFor="interpreterAssistance" className="bg-blue-700 text-white p-4 rounded flex ">
          Did you receive help from an interpreter to prepare this form?
        </label>
        <div className="flex bg-blue-100 p-4 rounded ">
        <div>
          <input
            type="radio"
            id="interpreterYes"
            name="interpreterAssistance"
            value="Yes"
            checked={formData.interpreterAssistance === 'Yes'}
            onChange={handleInputChange}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
          />
          <label htmlFor="interpreterYes" className="ml-2 text-blue-600">Yes</label>
        </div>
        <div className="ml-4">
          <input
            type="radio"
            id="interpreterNo"
            name="interpreterAssistance"
            value="No"
            checked={formData.interpreterAssistance === 'No'}
            onChange={handleInputChange}
            className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
          />
          <label htmlFor="interpreterNo" className="ml-2 text-blue-600">No</label>
        </div>
        </div>
      </div>

      {/* ... (other form fields) */}
    </div>



   { formData.interpreterAssistance === 'Yes'
   
   &&
   <>
   <div>
      {/* ... (other form fields) */}

      {/* Translation Language */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor="translationLanguage" className="text-blue-700 w-[200px]">
          Which language was used to translate into English?
        </label>
        <input
          type="text"
          id="translationLanguage"
          name="translationLanguage"
          value={formData.translationLanguage}
          onChange={handleInputChange}
          className="w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

      {/* ... (other form fields) */}
    </div>
    

    <div>
 
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor="interpretersGivenName" className="text-blue-700 w-[200px]">
          Given Name (First Name) of Interpreter:
        </label>
        <input
          type="text"
          id="interpretersGivenName"
          name="interpretersGivenName"
          value={formData.interpretersGivenName}
          onChange={handleInputChange}
          className="w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

 
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor="interpretersFamilyName" className="text-blue-700 w-[200px]">
          Family Name (Last Name) of Interpreter:
        </label>
        <input
          type="text"
          id="interpretersFamilyName"
          name="interpretersFamilyName"
          value={formData.interpretersFamilyName}
          onChange={handleInputChange}
          className="w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

   
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor="interpretersOrganizationName" className="text-blue-700 w-[200px]">
          Firm or Organization Name (if any) of Interpreter:
        </label>
        <input
          type="text"
          id="interpretersOrganizationName"
          name="interpretersOrganizationName"
          value={formData.interpretersOrganizationName}
          onChange={handleInputChange}
          className="w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2"
        />
      </div>

     
    </div>


    <div>



        <h1 className='text-blue-600 font-semibold text-xl'>Interpreter’s Mailing Address</h1>
      {/* ... (other form fields) */}

      {/* Interpreter's In Care Of Name */}
      <div className="mb-4 flex mt-6 gap-2 items-center">
        <label htmlFor="interpreterInCareOfName" className="w-[200px] text-blue-500">
          In Care of Name (if any)
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          name="interpreterInCareOfName"
          value={formData.interpreterInCareOfName}
          onChange={handleInputChange}
        />
      </div>

      {/* Interpreter's Street Number and Name */}
      <div className="mb-4 flex mt-6 gap-2 items-center">
        <label htmlFor="interpreterStreetNumberAndName" className="w-[200px] text-blue-500">
          Street Number and Name
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          name="interpreterStreetNumberAndName"
          value={formData.interpreterStreetNumberAndName}
          onChange={handleInputChange}
        />
      </div>

      {/* Interpreter's Apartment, Suite, or Floor */}
      <div className="mb-4 flex mt-2 gap-2">
        <label className="w-[200px] text-blue-500">
          Is there an apartment, suite, or floor?
        </label>
        <select
          className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
          name="interpreterApartmentSuiteFloor"
          value={formData.interpreterApartmentSuiteFloor}
          onChange={handleInputChange}
        >
          <option value="No">No</option>
          <option value="Apt">Apt</option>
          <option value="Flr">Flr</option>
          <option value="Ste">Ste</option>
        </select>
      </div>

      {/* Display Enter Number field based on selection */}
      {formData.interpreterApartmentSuiteFloor !== 'No' && (
        <div className="mb-4 flex mt-10 gap-2">
          <label htmlFor="interpreterEnterNumber" className="w-[200px] text-blue-500">
            Enter the number here:
          </label>
          <input
            className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
            type="text"
            name="interpreterEnterNumber"
            value={formData.interpreterEnterNumber}
            onChange={handleInputChange}
          />
        </div>
      )}
          <div className="mb-4 flex mt-10 gap-2">
        <label className="w-[200px] text-blue-500">
          Country
        </label>
        <select
          className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          name="interpreterCountry"
          value={formData.interpreterCountry}
          onChange={handleInputChange}
        >
          <option value="">Select a country</option>
          {code.map((Country, index) => (
            <option key={index} value={Country}>
              {Country}
            </option>
          ))}
        </select>
      </div>


      <div className="mb-4 flex mt-10 gap-2">
        <label className="w-[200px] text-blue-500">
          State (or Territory)
        </label>
        <select
          className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          name="interpreterStateOrTerritory"
          value={formData.interpreterStateOrTerritory}
          onChange={handleInputChange}
        >
          <option value="">Select a state or territory</option>
          {code.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>

     


      {/* ... (other address fields) */}

      {/* Interpreter's ZIP Code */}
      <div className="mb-4 flex mt-10 gap-2 items-center">
        <label htmlFor="interpreterZIPCode" className="w-[200px] text-blue-500">
          ZIP Code
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          name="interpreterZIPCode"
          value={formData.interpreterZIPCode}
          onChange={handleInputChange}
        />
      </div>

      {/* ... (other form fields) */}
    </div>


    <h1 className='text-blue-600 font-semibold text-xl mt-10'>Interpreter's Contact Information</h1>


    <div>
      {/* ... (previous form fields) */}

      {/* Contact Information */}
      <div className="mb-4 flex mt-10 gap-2">
        <label className="w-[200px] text-blue-500">Daytime Phone Number</label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="tel"
          name="daytimePhoneNumber"
          value={formData.daytimePhoneNumber}
          onChange={handleInputChange}
        />
      </div>

      <div className="mb-4 flex mt-10 gap-2">
        <label className="w-[200px] text-blue-500">Mobile Phone Number (if any)</label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="tel"
          name="mobilePhoneNumber"
          value={formData.mobilePhoneNumber}
          onChange={handleInputChange}
        />
      </div>

      <div className="mb-4 flex mt-10 gap-2">
        <label className="w-[200px] text-blue-500">E-mail (if any)</label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="email"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleInputChange}
        />
      </div>

      {/* Interpreter Checkbox */}
      

      {/* ... (other form fields) */}
    </div>

    </>
}
{/* sdssddadsdsa */}

<div className="mb-4 flex mt-10 gap-2">
        <input
          type="checkbox"
          id="interpreterCheckbox"
          name="interpreterCheckbox"
          checked={formData.interpreterCheckbox}
          onChange={handleInputChange}
        />
        <label htmlFor="interpreterCheckbox" className="ml-2 text-blue-600">
          The interpreter named above read to me each and every question and instruction on this form as well as my answer to every question in a language in which I am fluent. I understand each and every question and instruction on this form, as well as my answer to each question.
        </label>
      </div>




<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
               onClick={handleSubmitI134}
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default FinancialSupport