import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const MoralCharacter2 = () => {
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/MoralCharacter3")
  }
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's General Eligibility and Inadmissibility (continued)
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 10 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            You must answer every below question honestly and accurately. If you
            believe a truthful answer could create a problem on the application
            or you are unsure of your answer, we encourage you to speak to an
            immigration attorney before filing this application.
          </span>
        </div>
        <form action='' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          {/* Group 3 */}

          <hr className='h-1 bg-blue-600 mt-10' />

          <h1 className='text-blue-600  text-xl font-semibold'>
            Group 3: Security and Related
          </h1>

          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Do you intend to engage in any activity that violates or evades
              any law relating to espionage (including spying) or sabotage in
              the United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='intentToEngageInMaliciousActivity'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.intentToEngageInMaliciousActivity === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='intentToEngageInMaliciousActivity'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.intentToEngageInMaliciousActivity === "No"}
                />
                No
              </label>
            </div>
          </div>

          {formData.intentToEngageInMaliciousActivity === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Do you intend to engage in any activity in the United States
                  that violates or evades any law prohibiting the export from
                  the United States of goods, technology, or sensitive
                  information?
                </label>
                <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='exportViolationIntent'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={formData.exportViolationIntent === "Yes"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='exportViolationIntent'
                      value='No'
                      onChange={handleInputChange}
                      checked={formData.exportViolationIntent === "No"}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.exportViolationIntent === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Do you intend to engage in any activity whose purpose includes
                  opposing, controlling, or overthrowing the U.S. government by
                  force, violence, or other unlawful means while in the United
                  States?
                </label>
                <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='opposingGovernmentIntent'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={formData.opposingGovernmentIntent === "Yes"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='opposingGovernmentIntent'
                      value='No'
                      onChange={handleInputChange}
                      checked={formData.opposingGovernmentIntent === "No"}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.opposingGovernmentIntent === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Do you intend to engage in any activity that could endanger
                  the welfare, safety, or security of the United States?
                </label>
                <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='endangeringActivity'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={formData.endangeringActivity === "Yes"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='endangeringActivity'
                      value='No'
                      onChange={handleInputChange}
                      checked={formData.endangeringActivity === "No"}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.endangeringActivity === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Do you intend to engage in any other unlawful activity?
                </label>
                <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='unlawfulActivityIntent'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={formData.unlawfulActivityIntent === "Yes"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='unlawfulActivityIntent'
                      value='No'
                      onChange={handleInputChange}
                      checked={formData.unlawfulActivityIntent === "No"}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {formData.unlawfulActivityIntent === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Are you engaged in or, upon your entry into the United States,
                  do you intend to engage in any activity that could have
                  potentially serious adverse foreign policy consequences for
                  the United States?
                </label>
                <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='foreignPolicyAdverseActivity'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={formData.foreignPolicyAdverseActivity === "Yes"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='foreignPolicyAdverseActivity'
                      value='No'
                      onChange={handleInputChange}
                      checked={formData.foreignPolicyAdverseActivity === "No"}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Group 4 */}
          {formData.foreignPolicyAdverseActivity === "No" ? (
            <>
              <hr className='h-1 bg-blue-600 mt-10' />

              <h1 className='text-blue-600  text-xl font-semibold'>
                Group 4: Security and Related
              </h1>

              {formData.foreignPolicyAdverseActivity === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER participated in, or been a member of, a
                      group or organization that did any of the activities
                      described in the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='groupInvolvement'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.groupInvolvement === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='groupInvolvement'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.groupInvolvement === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.groupInvolvement === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER committed, threatened to commit, attempted
                      to commit, conspired to commit, incited, endorsed,
                      advocated, planned, or prepared any of the following:
                      hijacking, sabotage, kidnapping, political assassination,
                      or use of a weapon or explosive to harm another individual
                      or cause substantial damage to property?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='seriousViolentActivity'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.seriousViolentActivity === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='seriousViolentActivity'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.seriousViolentActivity === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.seriousViolentActivity === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER provided money, a thing of value, services
                      or labor, or any other assistance or support for an
                      individual, group, or organization who did any of the
                      activities described in the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='supportedActivity'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.supportedActivity === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='supportedActivity'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.supportedActivity === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.supportedActivity === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER received any type of military, paramilitary,
                      or weapons training?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='weaponsTraining'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.weaponsTraining === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='weaponsTraining'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.weaponsTraining === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.weaponsTraining === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Do you intend to engage in any of the activities listed in
                      any part of the questions in this section?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='intendToEngageInListedActivities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.intendToEngageInListedActivities === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='intendToEngageInListedActivities'
                          value='No'
                          onChange={handleInputChange}
                          checked={
                            formData.intendToEngageInListedActivities === "No"
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {/* Group 5 */}
          {formData.intendToEngageInListedActivities === "No" ? (
            <>
              <hr className='h-1 bg-blue-600 mt-10' />

              <h1 className='text-blue-600  text-xl font-semibold'>
                Group 5: Security and Related
              </h1>
              <h1 className='text-blue-600  text-xl '>
                Are you the spouse or child of an individual who EVER:
              </h1>

              {formData.intendToEngageInListedActivities === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Committed, threatened to commit, attempted to commit,
                      conspired to commit, incited, endorsed, advocated,
                      planned, or prepared any of the following: hijacking,
                      sabotage, kidnapping, political assassination, or use of a
                      weapon or explosive to harm another individual or cause
                      substantial damage to property?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='seriousIllegalActivities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.seriousIllegalActivities === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='seriousIllegalActivities'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.seriousIllegalActivities === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.seriousIllegalActivities === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Participated in, or been a member or a representative of a
                      group or organization that did any of the activities
                      described in the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='groupInvolvementInIllegalActivities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.groupInvolvementInIllegalActivities ===
                            "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='groupInvolvementInIllegalActivities'
                          value='No'
                          onChange={handleInputChange}
                          checked={
                            formData.groupInvolvementInIllegalActivities ===
                            "No"
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.groupInvolvementInIllegalActivities === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Recruited members, or asked for money or things of value,
                      for a group or organization that did any of the activities
                      described in the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='recruitedForIllegalActivities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.recruitedForIllegalActivities === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='recruitedForIllegalActivities'
                          value='No'
                          onChange={handleInputChange}
                          checked={
                            formData.recruitedForIllegalActivities === "No"
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.recruitedForIllegalActivities === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Provided money, a thing of value, services or labor, or
                      any other assistance or support for any of the activities
                      described in the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='supportedIllegalActivities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.supportedIllegalActivities === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='supportedIllegalActivities'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.supportedIllegalActivities === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.supportedIllegalActivities === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Provided money, a thing of value, services or labor, or
                      any other assistance or support to an individual, group,
                      or organization who did any of the activities described in
                      the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='supportedActivities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.supportedActivities === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='supportedActivities'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.supportedActivities === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.supportedActivities === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Received any type of military, paramilitary, or weapons
                      training from a group or organization that did any of the
                      activities described in the highlighted question above?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='militaryTrainingFromIllegalGroup'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.militaryTrainingFromIllegalGroup === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='militaryTrainingFromIllegalGroup'
                          value='No'
                          onChange={handleInputChange}
                          checked={
                            formData.militaryTrainingFromIllegalGroup === "No"
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {/* Group 6 */}
          {formData.militaryTrainingFromIllegalGroup === "No" ? (
            <>
              <hr className='h-1 bg-blue-600 mt-10' />

              <h1 className='text-blue-600  text-xl font-semibold'>
                Group 6: Security and Related
              </h1>

              {formData.militaryTrainingFromIllegalGroup === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you ever assisted or participated in selling
                      providing, or transporting weapons to any person who, to
                      your knowledge, used them against another person?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='weaponAssistance'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.weaponAssistance === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='weaponAssistance'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.weaponAssistance === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.weaponAssistance === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER worked, volunteered, or otherwise served in
                      any prison, jail, prison camp, detention facility, labor
                      camp, or any other situation that involved detaining
                      persons?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='detentionExperience'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.detentionExperience === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='detentionExperience'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.detentionExperience === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.detentionExperience === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER been a member of, assisted, or participated
                      in any group, unit, or organization of any kind in which
                      you or other persons used any type of weapon against any
                      person or threatened to do so?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='weaponUseInGroup'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.weaponUseInGroup === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='weaponUseInGroup'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.weaponUseInGroup === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.weaponUseInGroup === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER served in, been a member of, assisted or
                      participated in any military unit, paramilitary unit,
                      police unit, self-defense unit, vigilante unit, rebel
                      group, guerrilla group, militia, insurgent organization,
                      or any other armed group?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='militaryService'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.militaryService === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='militaryService'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.militaryService === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.militaryService === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER been a member of, or in any way affiliated
                      with, the Communist Party or any other totalitarian party
                      (in the United States or abroad)?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='communistAffiliation'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.communistAffiliation === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='communistAffiliation'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.communistAffiliation === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.communistAffiliation === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      During the period from March 23, 1933 to May 8, 1945, did
                      you ever order, incite, assist, or otherwise participate
                      in the persecution of any person because of race,
                      religion, national origin, or political opinion, in
                      association with either the Nazi government of Germany or
                      any organization or government associated or allied with
                      the Nazi government of Germany?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='naziPersecutionParticipation'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.naziPersecutionParticipation === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='naziPersecutionParticipation'
                          value='No'
                          onChange={handleInputChange}
                          checked={
                            formData.naziPersecutionParticipation === "No"
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          {/* Group 7 */}

          {formData.naziPersecutionParticipation === "No" ? (
            <>
              <hr className='h-1 bg-blue-600 mt-10' />

              <h1 className='text-blue-600  text-xl font-semibold'>
                Group 7: Security and Related
              </h1>
              <span className='mt-2 font-thin'>
                You must answer every below question honestly and accurately. If
                you believe a truthful answer could create a problem on the
                application or you are unsure of your answer, we encourage you
                to speak to an immigration attorney before filing this
                application.
              </span>

              {formData.naziPersecutionParticipation === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Acts involving torture or genocide?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='tortureOrGenocide'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.tortureOrGenocide === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='tortureOrGenocide'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.tortureOrGenocide === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.tortureOrGenocide === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Killing any person?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='killing'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.killing === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='killing'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.killing === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.killing === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Intentionally and severely injuring a person?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='severeInjury'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.severeInjury === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='severeInjury'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.severeInjury === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.severeInjury === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Engaging in any kind of sexual contact or relations with
                      any person who did not consent or was unable to consent,
                      or was being forced or threatened?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='nonConsensualSexualContact'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.nonConsensualSexualContact === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='nonConsensualSexualContact'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.nonConsensualSexualContact === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.nonConsensualSexualContact === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Limiting or denying any person's ability to exercise
                      religious beliefs?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='religiousBeliefLimitation'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.religiousBeliefLimitation === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='religiousBeliefLimitation'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.religiousBeliefLimitation === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.religiousBeliefLimitation === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER recruited, enlisted, conscripted, or used
                      any person under 15 years of age to serve in or help an
                      armed force or group?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='childRecruitment'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={formData.childRecruitment === "Yes"}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='childRecruitment'
                          value='No'
                          onChange={handleInputChange}
                          checked={formData.childRecruitment === "No"}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {formData.childRecruitment === "No" ? (
                <>
                  <div className='mt-10'>
                    <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                      Have you EVER used any person under 15 years of age to
                      take part in hostilities, or to help or provide services
                      to people in combat?
                    </label>
                    <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                      <label>
                        <input
                          type='radio'
                          name='childParticipationInHostilities'
                          value='Yes'
                          onChange={handleInputChange}
                          checked={
                            formData.childParticipationInHostilities === "Yes"
                          }
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type='radio'
                          name='childParticipationInHostilities'
                          value='No'
                          onChange={handleInputChange}
                          checked={
                            formData.childParticipationInHostilities === "No"
                          }
                        />
                        No
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default MoralCharacter2
