import React, { useState } from 'react';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import axiosRequest from '../utils/axiosRequest';

const SignUp = ({ isOpen, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  const Data = {
    "username": firstName + " " + lastName,
    "email": email,
    "password": password
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Assuming the API call is successful
      const response = await axiosRequest.post("/signup", Data);
      console.log(response);
      alert("Form submitted successfully!");

      // Close the modal after a successful form submission
      onClose();
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50 p-auto">
      <div className="bg-white rounded-lg w-[500px]" style={{ height: '550px' }}>
        <h2 className="p-4 flex bg-blue-900 text-white">Create an Account</h2>
        <form onSubmit={handleSubmit} className="p-2">
          <p className='bg-blue-100 flex items-center justify-center p-3 rounded border border-blue-700'>Please create an account to continue</p>
          <div className="flex mb-4 flex-col w-full">
            <div className="">
              <label htmlFor="firstName" className="block text-md text-gray-600 mt-2">
                First Name:
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={handleFirstNameChange}
                className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                required
              />
            </div>
            <div className="">
              <label htmlFor="lastName" className="block text-md text-gray-600 mt-2">
                Last Name:
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={handleLastNameChange}
                className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-md text-gray-600 mt-2">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
              required
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="password" className="block text-md text-gray-600 mt-2">
              Password:
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 flex items-center top-5 right-2 text-gray-500"
              onClick={handleTogglePassword}
            >
              {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
            </button>
          </div>
          <div className="flex items-center">
            <label htmlFor="terms" className="flex items-center font-[100] text-sm">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                checked={termsAccepted}
                onChange={handleTermsChange}
                className="mr-2"
              />
              By checking this box, you confirm that you've read and accepted our Terms of Service and Privacy Policy.
            </label>
          </div>
          <div className="ml-2 mt-2">
            <p className="text-blue-700">
              <span className="cursor-pointer text-blue-500" onClick={onClose}>Already have an account?</span>
            </p>
          </div>
          <div className="flex gap-2">
            <button
              onClick={onClose}
              className="bg-gray-900 text-white px-3 py-2 rounded hover:bg-gray-400 ml-auto"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600"
              disabled={!termsAccepted}
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
