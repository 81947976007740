import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";


const I8645 = () => {
  const navigate= useNavigate()
  const { formData, handleInputChange } = useFormContext();
const maxLength=50;
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };
  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            USCIS Form I-864 - Affidavit of Support Package
          </span>
          <div className="h-full flex justify-end">
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>
        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2 style={{ marginBottom: "-1%" }}>
            Principal Immigrant Information
          </h2>
          <span className="txttop " style={{ color: "#888" }}>
            <p className=" p-4">
              {" "}
              Next, we need to get some details about the principal immigrant.
              The principal immigrant is the intending immigrant who is the
              beneficiary of the immigrant visa petition. For example, if you
              filed Form I-130, this person is listed as the beneficiary.
            </p>
          </span>
        </div>

        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
          <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Principal Immigrant's Full Name</b>
              </h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
                Provide the full legal name. Spell out middle names completely
                and do not use just the initial. If the intending immigrant does
                not have a middle name,
                <br /> you may leave this field blank.
                <u> Explain more >></u>
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="mt-6 w-full">
            <div className="forms">
              <label htmlFor="givenName">
                Given Name (First Name): <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <input
                id="givenName"
                type="text"
                name="Pfirstname"
                maxLength={18}
                value={formData.Pfirstname} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp; <br />
              <label htmlFor="Middle">
                Middle Name(s):<span className="text-red-500">*</span>{" "}
                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </label>
              &nbsp; &nbsp;
              <input
                id="Middle"
                type="text"
                name="PMiddlename"
                maxLength={18}
                value={formData.PMiddlename} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              <br />
              <label htmlFor="family">
                Family Name (Last Name):<span className="text-red-500">*</span>{" "}
              </label>
              &nbsp; &nbsp;
              <input
                id="family"
                type="text"
                name="PFamilyname"
                maxLength={28}
                value={formData.PFamilyname} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
            </div>
            <br />
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />

            <div className="flex flex-col items-right space-y-0 p-4">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16">
                  <b>Principal Immigrant's Mailing Address</b>
                </h3>
              </span>
              <div className="text-left">
                <p className="ptag ml-16">
                  Enter the intending immigrant's mailing address. You may list
                  a valid residence, APO, PO Box, or commercial address. If the
                  intending immigrant's mail will be sent to someone other than
                  him/her, please include an “In Care of Name” as a part of the
                  mailing address.
                </p>
              </div>
            </div>
            <br />
            <div className="flex flex-col">
              <div className="forms">
                <label htmlFor="Caree">
                  In Care of Name  (if any): <span className="text-red-500">*</span>
                </label>
              &nbsp; &nbsp;
                &nbsp;                &nbsp;

                <input
                  id="Caree"
                  type="text"
                  name="Principalcare"
                  value={formData.Principalcare}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                <br /> <br />
                <label htmlFor="streetNamee">
                  Street Number and Name:{" "}
                  <span className="text-red-500">*</span>
                </label>
                &nbsp; &nbsp;
                <input
                  id="streetNamee"
                  type="text"
                  name="Principalstreetnum"
                  maxLength={maxLength}
                  value={formData.Principalstreetnum}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                <br /> <br />
                <label htmlFor="appartment">
                  Is there an apartment, suite <br /> or floor?{" "}
                  <span className="text-red-500">*</span> &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </label>
                <select
                  class="form-control1"
                  aria-required="true"
                  required="required"
                  name="Principalappartmentmail"
                  value={formData.Principalappartmentmail}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="appartment"
                >
                  <option value="no">No</option>
                  <option value="Apt">Apt</option>
                  <option value="Ste">Ste</option>
                  <option value="Flr">Flr</option>
                </select>
                <br />{" "}
                {formData.Principalappartmentmail === "Apt" ||
                formData.Principalappartmentmail === "Ste" ||
                formData.Principalappartmentmail === "Flr" ? (
                  <div className="num-input-wrapper">
                    <br />{" "}
                    <label htmlFor="numb">
                      Enter the number here:
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                    <input
                      id="numb"
                      type="text"
                      name="Principalnumb"
                      maxLength={15}
                      value={formData.Principalnumb}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        width: "10%",
                      }}
                    />
                  </div>
                ) : //-----------------------------------------------------------------------
                null}
                <br />
                <label htmlFor="cityy">
                  City or Town : <span className="text-red-500">* </span>{" "}
                </label>{" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;{" "}
                <input
                  id="cityy"
                  type="text"
                  name="PrincipalCities"
                  maxLength={maxLength}
                  value={formData.PrincipalCities}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                &nbsp; <br /> <br />
                <label htmlFor="countSelects">
                  Country : <span className="text-red-500"> * </span>{" "}
                </label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <select
                  id="countSelects"
                  value={formData.PrincipalCountry}
                  onChange={handleInputChange}
                  class="form-control"
                  name="PrincipalCountry"
                  aria-required="true"
                  required="required"
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                >
                  <option value="US">United States</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Canada">Canada</option>
                </select>
                <br />
                <br />
                {formData.PrincipalCountry === "US" ? (
                  <div>
                    <label htmlFor="stateSelecst">
                      State (or Territory):
                      <span className="text-red-500">*</span>{" "}
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      id="stateSelects"
                      name="PrincipalState"
                      value={formData.PrincipalState}
                      onChange={handleInputChange}
                      class="form-control"
                      aria-required="true"
                      required="required"
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                    >
                      <option value="" selected="selected">
                        Please select...
                      </option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                      <option value="U.S. Armed Forces - America (AA)">
                        U.S. Armed Forces - America (AA)
                      </option>
                      <option value="U.S. Armed Forces - Europe (AE)">
                        U.S. Armed Forces - Europe (AE)
                      </option>
                      <option value="U.S. Armed Forces - Pacific (AP)">
                        U.S. Armed Forces - Pacific (AP)
                      </option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Guam">Guam</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Palau">Palau</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Swains Island">Swains Island</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                    </select>
                    <br /> <br />
                    <label htmlFor="Zips">
                      ZIP Code : <span className="text-red-500">*</span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="Zips"
                      type="text"
                      name="PrincipalZips"
                      maxLength={8}
                      value={formData.PrincipalZips}
                      onChange={handleInputChange}
                      placeholder="####"
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        width: "10%",
                      }}
                    />
                  </div>
                ) : null}
                {formData.PrincipalCountry === "Mexico" ||
                formData.PrincipalCountry === "Canada" ? (
                  <div>
                    <label htmlFor="Province">
                      Province : <span className="text-red-500">* </span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="Province"
                      type="text"
                      name="PrincipalProvince"
                      maxLength={20}
                      value={formData.PrincipalProvince}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    &nbsp; <br /> <br />
                    <label htmlFor="post">
                      Postal Code <span className="text-red-500">* </span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="text"
                      className="p-1"
                      id="post"
                      name="Principalpostal"
                      value={formData.Principalpostal}
                      onChange={handleInputChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Principal Immigrant's Citizenship or Nationality
</b>
              </h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
              Select the name of the country where the intending immigrant is currently a citizen or national. If he/she is stateless, select the name of the country where he/she was last a citizen or national. If the intending immigrant is a citizen or national of more than one country, select the name of the country that issued his/her last passport.


              </p>
            </div>
            </div>
<br />
<div className="mt-2 font-thin ml-2 text ndRendring">
Country of Citizenship
                <span className="text-red-500">*</span>
              </div>
              <div className="form h-14">
                <select
                  id="countryofbirth"
                  name="PrincipalcountryCitizen"
                  className="form-control mt-3 ml-3"
                  aria-required="true"
                  required="required"
                  style={{ width: "30%" }}
                  autoComplete="section-P5_Physical_Address_Domestic address-level1"
                  value={formData.PrincipalcountryCitizen}
                  onChange={handleInputChange}
                >
                  <option value="Aruba">Aruba</option>
                  <option value="Austria">Austria</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Canada">Canada</option>
                  <option value="China">China</option>
                  <option value="England">England</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Greece">Greece</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Honduras">Honduras</option>
                  <option value="India">India</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Italy">Italy</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Korea">Korea</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Russia">Russia</option>
                  <option value="Singapore">Singapore</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Thailand">Thailand</option>
                  <option value="United States" selected="selected">
                    United States
                  </option>
                  <option value="Other">Other</option>
                </select>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Domicile is the place you have a home and intend to remain as
                  a home.
                </div>
              </div>
              <br />
              <br />
              <div className="mt-2 font-thin ml-2 text ndRendring">
                  Date of Birth
                  <span className="text-red-500">*</span>
                </div>
                <div className="form h-14 p-2">
                  <div className="flex flex-col space-y-5 ml-4 mt-0">
                    <div className="flex items-center space-x-10">
                      <input
                        id="dob1"
                        type="date"
                        name="Principaldob"
                        value={formData.Principaldob}
                        onChange={handleInputChange}
                        className="py-1 px-2 rounded"
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>

                <br />
                <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
                <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Other Information

</b>
              </h3>
            </span>
    
            </div>

            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
              Will this person immigrate through consular processing or adjustment of status?
                <span className="text-red-500">*</span>
              </div>
              <div className="form ">
                <br />
                <input
                  type="radio"
                  name="immigratethrough"
                  value="Consular Processing"
                  id="inUSYess"
                  
                  onChange={handleInputChange}
                  checked={formData.immigratethrough === "Consular Processing"}
                />
                <label htmlFor="inUSYess">Consular Processing</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="immigratethrough"
                  value="Adjustment of Status"
                  id="inUSNos"
                  onChange={handleInputChange}
                  checked={formData.immigratethrough === "Adjustment of Status"}
                />
                <label htmlFor="inUSNos">Adjustment of Status</label>
                <div className="mt-2"style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                Indicate which path the intending immigrant will apply for permanent resident status.
 <a href="#">Explain more >></a>

                  </div>
              </div>
              
            </div>
<br />
<br />
<div className=" text-white font-bold p-2 ndRendring text">
Daytime Phone Number
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
              <input
                    id="Daytime"
                    name="PrincipalDaytimephone"
                    type="tel"
                    value={formData.PrincipalDaytimephone}
                    onChange={handleInputChange}
                    pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                    className="p-2 mt-2 mb-2 ml-3 rounded border"
                    placeholder="(123) 456-7890"
                    required="required"
                  />
                    <div className="mt-3"style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                    This USCIS form only provides enough space for a 10-digit phone number. You may hand write a longer number on the form upon completion.

                  </div>
              </div>

              <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Alien Registration Number (A-Number)
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="PAlienNum"
                    value={formData.PAlienNum}
                    onChange={handleInputChange}
                    placeholder=" A-   ######"
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
If the intending immigrant does not have an A-Number, leave this field empty.
                  </div>
                </div>
                <br />
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Social Security Number 
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="PSSNum"
                    value={formData.PSSNum}
                    onChange={handleInputChange}
                    maxLength={9}
                    placeholder="    ######"
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    A Social Security number is required to file Form I-864.{" "}
                    <u>How to find this >></u>
                  </div>
                </div>
                <br />
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  USCIS Online Account Number
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="PAccNum"
                    value={formData.PAccNum}
                    onChange={handleInputChange}
                    placeholder="    #########"
                    required="required"
                    maxLength={12}
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    If you do not have a USCIS online account number or do not
                    know, leave this field empty.<u>How to find this >></u>
                  </div>
                </div>
    {/* -------------------           */}
    <br />
    <br />
            <div className="w-full ml-8">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded mt-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
                onClick={()=>navigate("/I8646")}
              >
                NEXT →
              </button>
            </div>
          </form>
        </div>
        <FormFotter />

      </div>
    </div>
  );
};
export default I8645;
