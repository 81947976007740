


import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/Context485';

const ApplicantImmigrationHistory = () => {
    const navigate=useNavigate("")
    const {  formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();
  
    const handleSubmit = (e) => {
      e.preventDefault();
   
       navigate("/ApplicationAddresHistory")
       
    };
  
  
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
     
          <h1 className=' text-blue-600 font-semibold text-xl'>Citizenship or Nationality</h1>
          <p className='text-sm'>Select the name of the country where you are currently a citizen or national. If stateless, select the name of the country where you were last a citizen or national. If you are a citizen or national of more than one country, select the name of the country that issued your most recent passport.</p>
           


            <div className='flex gap-10 mt-10'>
                <label htmlFor="countryOfCitizenship" className='text-blue-600  font-semibold text-xl'>Country of Citizenship or Nationality:</label>
                <select
                    id="countryOfCitizenship"
                    name="countryOfCitizenship"
                    value={formData.countryOfCitizenship}
                    onChange={handleInputChange}
                    className="w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
                >
                    <option value="" disabled>Select Country</option>
                    {code.map((country) => (
                    <option key={country} value={country}>
                        {country}
                    </option>
                    ))}
                </select>
                </div>


                <hr className='flex h-1 mt-10 bg-blue-600' />

                <h1 className='text-blue-600 font-semibold text-xl mt-10'>I-94 Arrival/Departure Record</h1>
                <p className='text-sm'>You most likely have an I-94 Arrival/Departure Record if you entered the U.S. lawfully. The information to answer the next few questions can be found in the I-94 record from your most recent arrival to the United States.</p>
                
                 
                 <div className='ml-10 mt-10' >
                  <ul className='flex flex-col gap-5 text-blue-600'>
                    <li>How to Find an I-94 Record</li>
                    <li>How to Read an I-94 Record</li>
                  </ul>
                  </div>

<hr className='h-1 bg-blue-600 mt-10' />
     <div className='mt-10'>
      <label className='flex bg-blue-600 text-white p-4 rounded-md'>Did you receive an I-94 record?</label>
      <div className='flex bg-blue-100 p-4  gap-4 text-blue-600'>
        <label>
          <input
            type="radio"
            name="i94RecordReceived"
            value="Yes"
            checked={formData.i94RecordReceived === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="i94RecordReceived"
            value="No"
            checked={formData.i94RecordReceived === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>

    <hr className='h-1 bg-blue-600 mt-10' />

    <h1 className='text-blue-600  text-xl font-semibold'>Most Recent Entry to United States</h1>
    <p>Enter the place and date of your most recent entry to the United States. Place of entry is typically the airport where you arrived and were inspected by Customs and Border Protection. It may also be a port or land border crossing where you entered the United States. Even if you were inspected at a port of entry outside the United States, enter the U.S. location where you actually arrived.
Note: If you have entered the United States more than one time on the same visa, use date and place of the most recent entry.</p>



<div>
      <div className="mt-10">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">City or Town</label>
       <div className="flex bg-blue-100 p-4 gap-4 text-blue-600">
        <input
          type="text"
          name="cityOrTown"
          value={formData.cityOrTown}
          onChange={handleInputChange}
          className="w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg"
        />
        </div>
      </div>

      <div className="mt-4">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">State</label>
         <div  className="flex bg-blue-100 p-4 gap-4 text-blue-600">
        <select
          name="state"
          value={formData.state}
          onChange={handleInputChange}
          className="w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg"
        >
          <option value="">Please select...</option>
          {code.map((state) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </select>
        </div>
      </div>

      
      
      <div className="mt-4">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">Date of Most Recent Entry</label>
       <div    className="flex bg-blue-100 p-4 gap-4 text-blue-600">
        <input
          type="date"
          name="dateOfRecentEntry"
          value={formData.dateOfRecentEntry}
          onChange={handleInputChange}
          className="w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg"
        />
        </div>
      </div>
    </div>



    <div>
      <div className="mt-10">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">
          On your most recent entry, did you use a passport or travel document to enter the United States?
        </label>
        <div className="flex bg-blue-100 p-4 gap-4 text-blue-600">
          <label>
            <input
              type="radio"
              name="entryDocument"
              value="Passport"
              checked={formData.entryDocument === 'Passport'}
              onChange={handleInputChange}
            />
            Passport
          </label>
          <label>
            <input
              type="radio"
              name="entryDocument"
              value="Travel Document"
              checked={formData.entryDocument === 'Travel Document'}
              onChange={handleInputChange}
            />
            Travel Document
          </label>
          <label>
            <input
              type="radio"
              name="entryDocument"
              value="Neither"
              checked={formData.entryDocument === 'Neither'}
              onChange={handleInputChange}
            />
            Neither
          </label>
        </div>
      </div>

      <div className="mt-10">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">Have you EVER overstayed your visit to the United States?</label>
        <div className="flex bg-blue-100 p-4 gap-4 text-blue-600">
          <label>
            <input
              type="radio"
              name="overstayed"
              value="Yes"
              checked={formData.overstayed === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="overstayed"
              value="No"
              checked={formData.overstayed === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>
    </div>
    


    

            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
      </div>
    )
}

export default ApplicantImmigrationHistory