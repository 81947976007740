import React from 'react';
import Header from '../components/Header';
import { MdArrowDropDown } from "react-icons/md";
import { useState } from 'react';
import { BsArrowRight} from "react-icons/bs";
import tikpik from "../Assets/tikpic.png"
import crosspic from "../Assets/crosspic.png"
import FormFotter from '../components/FormFotter';
import  { useEffect} from 'react';
import { useFormContext } from '../UseContext/UseContext';


const PageOneFormThree = () => {

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // const handleSelectChange = (event) => {
  //   setSelectedCountry(event.target.value);
  // };

  const { state, setFormState } = useFormContext(); // Assuming you're using the FormContext

  // ... (existing code)

  const handleGivenNameChange = (event) => {
    setFormState('FormFour', 'givenName', event.target.value);
  };

  const handleMiddleNameChange = (event) => {
    setFormState('FormFour', 'middleName', event.target.value);
  };

  const handleFamilyNameChange = (event) => {
    setFormState('FormFour', 'familyName', event.target.value);
  };

  const handleDobChange = (event) => {
    setFormState('FormFour', 'dob', event.target.value);
  };

  const handleSelectChange = (event) => {
    setSelectedCountry(event.target.value);
    setFormState('FormFour', 'country', event.target.value);
  };

  const handleUsStatusChange = (event) => {
    setFormState('FormFour', 'status', event.target.value);
  };

  const handleANumberChange = (event) => {
    setFormState('FormFour', 'ANumber', event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Add any additional logic you need before submitting
    console.log('Form submitted:', state);
    // Perform your form submission logic here
  }



    
  return (
    

  
      <div className='w-full'>
    <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow '>
    
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <div>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl ml-44'>USCIS Form AR-11 - Alien's Change of Address Card</span>
          </div>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col  border w-full pb-10  bg-slate-100 '>
        <div className='flex justify-between items-center'>
  <span className='mt-4 font-bold text-xl ml-2 text-black'>You're almost done. We just need some information about you.</span>
  <div className='flex item center justify-center  bg-blue-700 mr-2 item-center text-white rounded py-1 px-2'>
   
    <span className="mr-2">Page 1 of 3</span>
    <span><MdArrowDropDown /></span>
   
  </div>
</div>

  <span className='mt-2  font-thin ml-2'  >Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
</div>

<form action=" " className='mt-6 w-5/6  ' onSubmit={handleSubmit}>
<h1 className='text-blue-700 font-bold text-xl'>Full Name</h1>
<p
  className='font-thin text-sm'
  style={{
    color: 'rgba(60, 60, 60, 0.7)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
  }}
>
  Enter your full legal name. Unless you have legally changed your name, your name should be exactly as it appears on your existing immigration documents like a Permanent Resident Card or I-94 Arrival/Departure Record. Spell out names completely. If you do not have a middle name, you may leave this field blank.
</p>




<div className="mb-4 flex mt-6">
<label htmlFor="givenName" className="text-blue-700">
  Given Name (First Name): <span className="text-red-500">*</span>
</label>

        <input
          type="text"
          id="givenName"
          name="givenName"
          value={state.FormFour.givenName}
          onChange={handleGivenNameChange}
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
          required
        />
      </div>

      {/* Middle Name(s) */}
      <div className="mb-4 flex">
        <label htmlFor="middleName" className="text-blue-700">Middle Name(s):
        <span className="text-red-500">*</span>
        
        </label>
        <input
          type="text"
          id="middleName"
          name="middleName"
          value={state.FormFour.middleName}
          onChange={handleMiddleNameChange}
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg  ml-24 '
        />
      </div>

      {/* Family Name (Last Name) */}
      <div className="mb-4 flex">
        <label htmlFor="familyName" className="text-blue-500">Family Name (Last Name):
        <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="familyName"
          name="familyName"
          value={state.FormFour.familyName}
          onChange={handleFamilyNameChange}
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
        
        />
      </div>    


      <div className='p-4  bg-blue-700 rounded mt-14'>
  <span className='text-white'>Date of Birth
  <span className="text-red-500">*</span>
  </span>
</div>
<div className='flex gap-10 bg-blue-100 p-4'>
  <div className='flex gap-2'>
    <input
      type="date"
      id="dob"
      name="dob"
      value={state.FormFour.dob}
      onChange={handleDobChange}
      className='w-[300px] h-10  rounded pl-2'
   
      // value={state.dob} // Replace with the actual state variable containing the date of birtt // onChange={(e) => handleDateOfBirthChange(e.target.value)} // Replace with your state update function
 />
  </div>
  
</div>
<div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
  <span className='text-gray-500'>Enter the date of birth in the month-day-year (mm/dd/yyyy) format.</span>
</div>


<div className='p-4  bg-blue-700 rounded mt-14'>
  <span className='text-white' >Country of Citizenship
  <span className="text-red-500">*</span>
  </span>
  </div>
   <div  className=" bg-blue-100 p-4  rounded">
      <label htmlFor="country-select"   ></label>
      <select
        id="country-select"
        value={state.FormFour.country}
        onChange={handleSelectChange}
        className=' h-10 pl-2'
        
      >
        <option value="">Andorra</option>
        {countries.map((country) => (
          <option key={country.name.common} value={country.name.common}>
            {country.name.common}
          </option>
        ))}
      </select>
     
    </div>
    <div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
  <span className='text-gray-500'>Enter the date of birth in the month-day-year (mm/dd/yyyy) format.</span>
</div>




<div className='p-4   bg-blue-700  rounded mt-14'>
  <span className='text-white' >I am in the United States as a:
  <span className="text-red-500">*</span>
   </span>
  </div>
  <div  className=" bg-blue-100 p-4  rounded">
      <label htmlFor="country-select"   ></label>
      <select
        id="country-select"
         value={state.FormFour.status}
         onChange={handleUsStatusChange}
        className='w-[400px] h-10 pl-2'

      >
  <option value="" disabled  >Student</option>
  <option value="ny">Parmanent Resident (Green Card Holder)</option>
  <option value="ca">Visitor</option>
  <option value="tx">Student</option>
  <option value="ny">Other</option>
  {/* Add other options as needed */}
</select>


</div>
<div className='p-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
  <span className='text-gray-500'>If you have an immigration status other than Permanent Resident, Visitor, or Student, choose "Other" and select your status from the drop down list. If you have an Adjustment of Status (AOS) application (I-485) pending with USCIS, choose "Other" and select "AOS applicant" from the drop down list. Once you file form I-485, your status is "AOS" regardless of whether your nonimmigrant status is valid or expired..</span>
</div>


<div className="p-4 bg-red-500 rounded mt-14">
  <span className="text-white">Alien Registration Number (A-Number)</span>
</div>
<div className="flex items-center  bg-red-100 p-3">
  <span className="p-3 bg-gray-300">A-</span>
  <div  className=" bg-blue-100  rounded">
  <input
  placeholder='ffff'
    type="text"
    id="familyName"
    value={state.FormFour.Avalue}
    onChange={handleANumberChange}
    name="familyName"
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
    required
  />
</div>
</div>
<div className='p-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
  <span className='text-gray-500'>If you do not have an A-Number, leave this field blank.</span>
</div>
<div className='p-2 bg-red-300 rounded flex justify-end' style={{}}>
  <span className='text-red-600 font-bold'>If your A-Number only has 8 numbers then please put a '0' at the front.</span>
</div>

<h5 className='mt-16 flex  justify-center  font-bold text-lg'>You've finished preparing this form. It's time to finalize and generate the documents.</h5>

<div className='flex justify-center mt-20  '>
  <button type="submit" className=' bg-blue-700 text-white  px-36 py-2 ' >
    CREATE MY FILE
  </button>
  </div>












</form>
<FormFotter className />  
      </div>
    </div>
    
  )
}

export default PageOneFormThree
