


import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"



const Eligibility864 = () => {

    const { formData, handleInputChange ,code } = useFormContext();
    const navigate = useNavigate()

     const HandleSubmit =(e)=>{
          e.preventDefault()
          navigate("/Petitioner864")
     }
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
    <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
        </span>
      </div>
      <form action=" " className='mt-14 w-11/12' onSubmit={HandleSubmit}>

     

      <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
        Select your citizenship or residency:
      </label>
      <div className='flex   flex-col gap-2 p-4 bg-blue-100 rounded'>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='citizenshipOrResidency'
            value='U.S. citizen'
            checked={formData.citizenshipOrResidency === 'U.S. citizen'}
            onChange={handleInputChange}
          />
          U.S. citizen
        </label>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='citizenshipOrResidency'
            value='Lawful permanent resident'
            checked={formData.citizenshipOrResidency === 'Lawful permanent resident'}
            onChange={handleInputChange}
          />
          Lawful permanent resident
        </label>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='citizenshipOrResidency'
            value='None of the above'
            checked={formData.citizenshipOrResidency === 'None of the above'}
            onChange={handleInputChange}
          />
          None of the above
        </label>
      </div>
    </div>


    

    {(formData.citizenshipOrResidency === 'U.S. citizen' || formData.citizenshipOrResidency === 'Lawful permanent resident')
        &&
        
        <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
        Select the basis for preparing this affidavit of support:
      </label>
      <div className='flex flex-col bg-blue-100 rounded'>
  <label className='flex gap-2 ml-2 mt-2 mb-2 text-blue-700'>
    <input
      type='radio'
      name='basisForAffidavit'
      value='I am the petitioner. I filed or am filing for the immigration of my relative.'
      checked={formData.basisForAffidavit === 'I am the petitioner. I filed or am filing for the immigration of my relative.'}
      onChange={handleInputChange}
    />
    I am the petitioner. I filed or am filing for the immigration of my relative.
  </label>

  <label className='flex gap-2 ml-2 mt-2 mb-2 text-blue-700'>
    <input
      type='radio'
      name='basisForAffidavit'
      value='I am a joint sponsor.'
      checked={formData.basisForAffidavit === 'I am a joint sponsor.'}
      onChange={handleInputChange}
    />
    I am a joint sponsor.
  </label>

  <label className='flex gap-2 ml-2 mt-2 mb-2 text-blue-700'>
    <input
      type='radio'
      name='basisForAffidavit'
      value='I filed an alien worker petition on behalf of the intending immigrant, who is related to me.'
      checked={formData.basisForAffidavit === 'I filed an alien worker petition on behalf of the intending immigrant, who is related to me.'}
      onChange={handleInputChange}
    />
    I filed an alien worker petition on behalf of the intending immigrant, who is related to me.
  </label>

  <label className='flex gap-2 ml-2 mt-2 mb-2 text-blue-700'>
    <input
      type='radio'
      name='basisForAffidavit'
      value='I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant.'
      checked={formData.basisForAffidavit === 'I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant.'}
      onChange={handleInputChange}
    />
    I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant.
  </label>

  <label className='flex gap-2 ml-2 mt-2 mb-2 text-blue-700'>
    <input
      type='radio'
      name='basisForAffidavit'
      value='I am the substitute sponsor. The original petitioner is deceased.'
      checked={formData.basisForAffidavit === 'I am the substitute sponsor. The original petitioner is deceased.'}
      onChange={handleInputChange}
    />
    I am the substitute sponsor. The original petitioner is deceased.
  </label>
</div>

    </div>}
  
  { formData.basisForAffidavit === 'I am the petitioner. I filed or am filing for the immigration of my relative.'
  
  &&
  <div className='mt-10'>
        <p className='flex bg-blue-700 text-white p-4'>The intending immigrant is the petitioner's:</p>
        <div className='flex flex-col bg-blue-100 p-4 gap-2 '>
        <label>
          <input
            type="radio"
            name="intendingImmigrantRelationship"
            value="Spouse"
            checked={formData.intendingImmigrantRelationship === 'Spouse'}
            onChange={handleInputChange}
          />
          Spouse
        </label>
        <label>
          <input
            type="radio"
            name="intendingImmigrantRelationship"
            value="Child (under age 21)"
            checked={formData.intendingImmigrantRelationship === 'Child (under age 21)'}
            onChange={handleInputChange}
          />
          Child (under age 21)
        </label>
        <label>
          <input
            type="radio"
            name="intendingImmigrantRelationship"
            value="Adult son/daughter"
            checked={formData.intendingImmigrantRelationship === 'Adult son/daughter'}
            onChange={handleInputChange}
          />
          Adult son/daughter
        </label>
        <label>
          <input
            type="radio"
            name="intendingImmigrantRelationship"
            value="Parent (mother/father)"
            checked={formData.intendingImmigrantRelationship === 'Parent (mother/father)'}
            onChange={handleInputChange}
          />
          Parent (mother/father)
        </label>
        <label>
          <input
            type="radio"
            name="intendingImmigrantRelationship"
            value="Sibling (brother/sister)"
            checked={formData.intendingImmigrantRelationship === 'Sibling (brother/sister)'}
            onChange={handleInputChange}
          />
          Sibling (brother/sister)
        </label>
        </div>
      </div>}

   {formData.citizenshipOrResidency === 'None of the above'

   &&
   <>
 <div>
      {/* Are you a U.S. national? */}
      <div className=' mt-10'>
        <p className='flex p-4 text-white bg-blue-700 w-full'>Are you a U.S. national?</p>
        <div className='flex p-4 bg-blue-100 gap-4'>
        <label className='text-blue-600'>
          <input
            type="radio"
            name="isUSNational"
            value="Yes"
            checked={formData.isUSNational === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='text-blue-600'>
          <input
            type="radio"
            name="isUSNational"
            value="No"
            checked={formData.isUSNational === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
      </div>
   
     
    </div>
    </>
    }


    {
        <div className='mt-10'>
      <p className='flex bg-blue-700 p-4 text-white '>How many joint sponsors will there be?</p>
      <div className='flex bg-blue-100 p-4 flex-col gap-2'>
      <label>
        <input
          type="radio"
          name="jointSponsorCount"
          value="I am the only joint sponsor"
          checked={formData.jointSponsorCount === 'I am the only joint sponsor'}
          onChange={handleInputChange}
        />
        I am the only joint sponsor
      </label>
      <label>
        <input
          type="radio"
          name="jointSponsorCount"
          value="I am the first of two joint sponsors"
          checked={formData.jointSponsorCount === 'I am the first of two joint sponsors'}
          onChange={handleInputChange}
        />
        I am the first of two joint sponsors
      </label>
      <label>
        <input
          type="radio"
          name="jointSponsorCount"
          value="I am the second of two joint sponsors"
          checked={formData.jointSponsorCount === 'I am the second of two joint sponsors'}
          onChange={handleInputChange}
        />
        I am the second of two joint sponsors
      </label>
      </div>
    </div>}




    <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
        I am the intending immigrant's:
      </label>
      <div className='flex   flex-col  p-4 bg-blue-100 rounded'>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Spouse'
            checked={formData.relationshipToIntendingImmigrant === 'Spouse'}
            onChange={handleInputChange}
          />
          Spouse
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Mother'
            checked={formData.relationshipToIntendingImmigrant === 'Mother'}
            onChange={handleInputChange}
          />
          Mother
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Father'
            checked={formData.relationshipToIntendingImmigrant === 'Father'}
            onChange={handleInputChange}
          />
          Father
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Mother-in-law'
            checked={formData.relationshipToIntendingImmigrant === 'Mother-in-law'}
            onChange={handleInputChange}
          />
          Mother-in-law
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Father-in-law'
            checked={formData.relationshipToIntendingImmigrant === 'Father-in-law'}
            onChange={handleInputChange}
          />
          Father-in-law
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Brother'
            checked={formData.relationshipToIntendingImmigrant === 'Brother'}
            onChange={handleInputChange}
          />
          Brother
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Sister'
            checked={formData.relationshipToIntendingImmigrant === 'Sister'}
            onChange={handleInputChange}
          />
          Sister
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Brother-in-law'
            checked={formData.relationshipToIntendingImmigrant === 'Brother-in-law'}
            onChange={handleInputChange}
          />
          Brother-in-law
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Sister-in-law'
            checked={formData.relationshipToIntendingImmigrant === 'Sister-in-law'}
            onChange={handleInputChange}
          />
          Sister-in-law
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Son'
            checked={formData.relationshipToIntendingImmigrant === 'Son'}
            onChange={handleInputChange}
          />
          Son
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Daughter'
            checked={formData.relationshipToIntendingImmigrant === 'Daughter'}
            onChange={handleInputChange}
          />
          Daughter
        </label>
        <br />
        <label className='text-blue-700' >
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Son-in-law'
            checked={formData.relationshipToIntendingImmigrant === 'Son-in-law'}
            onChange={handleInputChange}
          />
          Son-in-law
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Daughter-in-law'
            checked={formData.relationshipToIntendingImmigrant === 'Daughter-in-law'}
            onChange={handleInputChange}
          />
          Daughter-in-law
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Grandmother'
            checked={formData.relationshipToIntendingImmigrant === 'Grandmother'}
            onChange={handleInputChange}
          />
          Grandmother
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Grandfather'
            checked={formData.relationshipToIntendingImmigrant === 'Grandfather'}
            onChange={handleInputChange}
          />
          Grandfather
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Granddaughter'
            checked={formData.relationshipToIntendingImmigrant === 'Granddaughter'}
            onChange={handleInputChange}
          />
          Granddaughter
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Grandson'
            checked={formData.relationshipToIntendingImmigrant === 'Grandson'}
            onChange={handleInputChange}
          />
          Grandson
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='relationshipToIntendingImmigrant'
            value='Legal guardian'
            checked={formData.relationshipToIntendingImmigrant === 'Legal guardian'}
            onChange={handleInputChange}
          />
          Legal guardian
        </label>
        <br />
      </div>
    </div>


    <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>Are you at least 18 years old?</label>
      <div className='flex gap-2 bg-blue-100 p-4'>
        <label className='ml-2  text-blue-700 '>
          <input
            type='radio'
            name='isAtLeast18YearsOld'
            value='Yes'
            checked={formData.isAtLeast18YearsOld === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='isAtLeast18YearsOld'
            value='No'
            checked={formData.isAtLeast18YearsOld === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>



    <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>Have you ever served honorably in the U.S. armed forces?</label>
      <div className='flex    gap-2 p-4 bg-blue-100 rounded'>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='servedInUSArmedForces'
            value='Yes'
            checked={formData.servedInUSArmedForces === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='servedInUSArmedForces'
            value='No'
            checked={formData.servedInUSArmedForces === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>

    <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>Do you have a Social Security Number?</label>
      <div className='flex    gap-2 p-4 bg-blue-100 rounded'>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='hasSSN'
            value='Yes'
            checked={formData.hasSSN === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='hasSSN'
            value='No'
            checked={formData.hasSSN === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>


    <div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>Are you currently domiciled in the United States?</label>
      <div className='flex    gap-2 p-4 bg-blue-100 rounded'>
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='isDomiciledInUS'
            value='Yes'
            checked={formData.isDomiciledInUS === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <br />
        <label className='ml-2  text-blue-700'>
          <input
            type='radio'
            name='isDomiciledInUS'
            value='No'
            checked={formData.isDomiciledInUS === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>

















    <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>


     
    </form>
    <FormFotter />

      </div>
      </div>
  
  )
}

export default Eligibility864
