
import React, {useEffect, useState } from 'react';
import Header from '../../components/Header';
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';

import FormFotter from '../../components/FormFotter';




const DottedLineWithText = () => (
    <div className="flex items-center mt-4">
      <hr className="flex-1 border-t-2 border-dashed border-gray-200 mr-4" />
      <span className="text-gray-200 font-bold">Insert Another item</span>
      <hr className="flex-1 border-t-2 border-dashed border-gray-200 ml-4" />
    </div>
  );



const InfoaboutParent = () => {
    const [countries, setCountries] = useState([]);
    const [nameSets, setNameSets] = useState([{ id: 1 }]);

   

    const [unknownBirthDate, setUnknownBirthDate] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('Select Country');
    const [selectedCountrysecond, setSelectedCountrysecond] = useState('Select Country');
    const [selectedCountryParent2, setCountriesParent2] = useState([]);
    const [unknownBirthDateParent2, setUnknownBirthDateParent2] = useState(false);
    const [selectedCountryParenttwo, setSelectedCountryParenttwo] = useState('Select Country');
    const [selectedCountrysecondParenttwo, setSelectedCountrysecondParenttwo] = useState('Select Country')
  
    useEffect(() => {
      const fetchCountries = async () => {
        try {
          const response = await fetch('https://restcountries.com/v3.1/all');
          const data = await response.json();
          const countryNames = data.map(country => country.name.common);
          setCountries(['Select Country', ...countryNames]);
        } catch (error) {
          console.error('Error fetching countries:', error);
        }
      };
  
      fetchCountries();
    }, []);
    const handleAddAnother = () => {
        setNameSets((prevNameSets) => [...prevNameSets, { id: Date.now() }]);
      };
    
      const handleRemove = (id) => {
        setNameSets((prevNameSets) => prevNameSets.filter((nameSet) => nameSet.id !== id));
      };

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
      };
      const handleCountryChangesecond = (event) => {
        setSelectedCountrysecond(event.target.value);
      };
      const handleCountryChangeParent2 = (event) => {
        setSelectedCountryParenttwo(event.target.value);
      };
      const handleCountryChangesecondParenttwo = (event) => {
        setSelectedCountrysecondParenttwo(event.target.value);
      };
 
 
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
 

 

      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>

          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form I-129F - Petition for Alien Fiancé</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
          
        </div>




<div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
  <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
    <span className='mt-4 font-bold text-xl ml-2 text-black '>
    Petitioner's Family

    </span>
    <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
      <button className='mr-2'>Page 1 of 3</button>
      <button><MdArrowDropDown /></button>
    </div>
  </div>
  <span className='mt-2 font-thin ml-2'>Provide details about the petitioner's parents, previous spouses, and children (if applicable).</span>
</div>

        <form action=" " className='mt-8 w-11/12 ' >

<p
  className='font-bold text-xl  text-blue-500'
  
>
Information About Parent 1
</p>

<div className='p-2 ' >
  <span className='text-gray-500'>Enter information about your parent. Your parent may be biological, fully adopted or a step parent. If you do not know the answer to question, "Unknown" is an acceptable answer.</span>
</div>


<p
  className='text-2xl  text-blue-500 mt-4'
  
>
Parent 1 current legal name
</p>
                        <div className="mb-4 flex mt-6">
                <label htmlFor="givenName" className="text-blue-700">
                Given Name (First Name): <span className="text-red-500">*</span>
                </label>

        <input
          type="text"
          id="givenName"
          name="givenName"
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
        
        />
      </div>

      {/* Middle Name(s) */}
      <div className="mb-4 flex gap-2">
        <label htmlFor="middleName" className="text-blue-700">Middle Name(s):
        <span className="text-red-500">*</span>
        
        </label>
        <input
          type="text"
          id="middleName"
          name="middleName"
          className='ml-24 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
        />
      </div>

      {/* Family Name (Last Name) */}
      <div className="mb-4 flex">
        <label htmlFor="familyName" className="text-blue-500">Family Name (Last Name):
        <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="familyName"
          name="familyName"
         
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
        
        />
      </div>    
      <p
  className='text-2xl  text-blue-500 mt-8'
  
>
Parent 1's Birth Information
</p>
 
<div className='flex gap-80'>
  <span className="text-blue-500 font-[500]">Sex</span>
  <div className="flex items-center gap-4">
  <span className="text-red-500">*</span>
    <div className="flex items-center gap-1">
      <input type="radio" id="male" name="sex" value="male" />
      <label htmlFor="male" className="text-blue-500">Male</label>
      
    </div>
    <div className="flex items-center gap-1">
      <input type="radio" id="female" name="sex" value="female" />
      <label htmlFor="female" className="text-blue-500">Female</label>
    </div>
  </div>
</div>


<div>
      {!unknownBirthDate &&
      <>
      <div className='flex gap-36 mt-10 items-center'>
     
      <label htmlFor="dob" className='text-blue-500'>Date of Birth  <span className="text-red-500">*</span></label>
      <input type="date" id="dob" name="dob"  className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'/>
      </div>
      </>}

      <div className="flex items-center gap-2 mt-4">
        <input
          type="checkbox"
          id="unknownBirthDate"
          name="unknownBirthDate"
          checked={unknownBirthDate}
          onChange={() => setUnknownBirthDate(!unknownBirthDate)}
        />
        <label htmlFor="unknownBirthDate" className='text-blue-500'>I don't know about the birth date</label>
      </div>

     
    </div>

<div className='flex  mt-10  items-center'>
  <span className="text-blue-500">Current Birth Country<span className="text-red-500">*</span></span>
  <select

    id="birthCountry"
    name="birthCountry"
    value={selectedCountry}
    onChange={handleCountryChange}
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg  text-sm ml-20'
  >
    {countries.map((country, index) => (
      <option key={index} value={country}>
        {country}
      </option>
    ))}
  </select>
</div>

<p
  className='text-2xl  text-blue-500 mt-8'
  
>
Parent 1's Place of Residence
</p>


<div className='flex gap-20 mt-10'>
      <span className='text-blue-500'>Is the parent deceased? <span className="text-red-500">*</span></span>
      <div className='flex gap-10'>
        <label className='flex gap-2 text-blue-500'>
          <input
            type="radio"
            value="Yes"
           
          />
          Yes
        </label>
        <label className='flex gap-2 text-blue-500'>
          <input
            type="radio"
            value="No"
          />
          No
        </label>
      </div>
    </div>
 
    <div className='flex mt-10 items-center'>
  <span className='text-blue-500'>City or Town <span className="text-red-500">*</span></span>
  <div>
    <input
      type="text"
      id="cityOrTown"
      name="cityOrTown"
      placeholder="Enter city or town..."
      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-32'
    />
  </div>
</div>

<div className='flex gap-16 mt-10  items-center'>
  <span className="text-blue-500">Country<span className="text-red-500">*</span></span>
  <select

    id="birthCountry"
    name="birthCountry"
    value={selectedCountrysecond}
    onChange={handleCountryChangesecond}
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-24 text-sm'
  >
    {countries.map((country, index) => (
      <option key={index} value={country}>
        {country}
      </option>
    ))}
  </select>
</div>

<hr className='bg-blue-700 h-1 w-full mt-20' style={{ width: '100%' }} />








<p className='font-bold text-xl text-blue-500 mt-8'>
  Information About Parent 2
</p>

<div className='p-2'>
  <span className='text-gray-500'>
    Enter information about your second parent. If you do not know the answer to a question, "Unknown" is an acceptable answer.
  </span>
</div>

<p className='text-2xl text-blue-500 mt-4'>
  Parent 2 current legal name
</p>

<div className="mb-4 flex mt-6">
  <label htmlFor="givenNameParent2" className="text-blue-700">
    Given Name (First Name): <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id="givenNameParent2"
    name="givenNameParent2"
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
  />
</div>

{/* Middle Name(s) */}
<div className="mb-4 flex gap-2">
  <label htmlFor="middleNameParent2" className="text-blue-700">
    Middle Name(s): <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id="middleNameParent2"
    name="middleNameParent2"
    className='ml-24 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
  />
</div>

{/* Family Name (Last Name) */}
<div className="mb-4 flex">
  <label htmlFor="familyNameParent2" className="text-blue-500">
    Family Name (Last Name): <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id="familyNameParent2"
    name="familyNameParent2"
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
  />
</div>

<p className='text-2xl text-blue-500 mt-8'>
  Parent 2's Birth Information
</p>

<div className='flex gap-80'>
  <span className="text-blue-500 font-[500]">Sex</span>
  <div className="flex items-center gap-4">
    <span className="text-red-500">*</span>
    <div className="flex items-center gap-1">
      <input type="radio" id="maleParent2" name="sexParent2" value="male" />
      <label htmlFor="maleParent2" className="text-blue-500">Male</label>
    </div>
    <div className="flex items-center gap-1">
      <input type="radio" id="femaleParent2" name="sexParent2" value="female" />
      <label htmlFor="femaleParent2" className="text-blue-500">Female</label>
    </div>
  </div>
</div>

<div>
  {!unknownBirthDateParent2 && (
    <>
      <div className='flex gap-36 mt-10 items-center'>
        <label htmlFor="dobParent2" className='text-blue-500'>
          Date of Birth <span className="text-red-500">*</span>
        </label>
        <input
          type="date"
          id="dobParent2"
          name="dobParent2"
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        />
      </div>
    </>
  )}

  <div className="flex items-center gap-2 mt-4">
    <input
      type="checkbox"
      id="unknownBirthDateParent2"
      name="unknownBirthDateParent2"
      checked={unknownBirthDateParent2}
      onChange={() => setUnknownBirthDateParent2(!unknownBirthDateParent2)}
    />
    <label htmlFor="unknownBirthDateParent2" className='text-blue-500'>
      I don't know about the birth date
    </label>
  </div>
</div>

<div className='flex mt-10 items-center'>
  <span className='text-blue-500'>
    Current Birth Country<span className="text-red-500">*</span>
  </span>
  <select
    id="birthCountryParent2"
    name="birthCountryParent2"
    value={selectedCountryParent2}
    onChange={handleCountryChangeParent2}
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg  text-sm ml-20'
  >
    {countries.map((country, index) => (
      <option key={index} value={country}>
        {country}
      </option>
    ))}
  </select>
</div>

<p className='text-2xl text-blue-500 mt-8'>
  Parent 2's Place of Residence
</p>

<div className='flex gap-20 mt-10'>
  <span className='text-blue-500'>
    Is the parent deceased? <span className="text-red-500">*</span>
  </span>
  <div className='flex gap-10'>
    <label className='flex gap-2 text-blue-500'>
      <input
        type="radio"
        value="YesParent2"
        name="isDeceasedParent2"
      />
      Yes
    </label>
    <label className='flex gap-2 text-blue-500'>
      <input
        type="radio"
        value="NoParent2"
        name="isDeceasedParent2"

      />
      No
    </label>
  </div>
</div>

<div className='flex mt-10 items-center'>
  <span className='text-blue-500'>
    City or Town <span className="text-red-500">*</span>
  </span>
  <div>
    <input
      type="text"
      id="cityOrTownParent2"
      name="cityOrTownParent2"
      placeholder="Enter city or town..."
      className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-32'
    />
  </div>
</div>

<div className='flex gap-16 mt-10 items-center'>
  <span className="text-blue-500">
    Country<span className="text-red-500">*</span>
  </span>
  <select
    id="birthCountryParent2"
    name="birthCountryParent2"
    value={selectedCountryParent2}
    onChange={handleCountryChangeParent2}
    className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-24 text-sm'
  >
    {countries.map((country, index) => (
      <option key={index} value={country}>
        {country}
      </option>
    ))}
  </select>
</div>

<hr className='bg-blue-700 h-1 w-full mt-20' style={{ width: '100%' }} />




<p className='font-bold text-xl text-blue-500 mt-8'>
Petitioner's Marital Information
</p>
<div className= 'gap-10 p-4 mt-14 text-white rounded bg-blue-700' >
  Were you previously married?
  <span className="text-red-500">*</span>
</div>
<div className='flex gap-10 p-4 rounded bg-blue-200'>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="yesMarried"
      name="marriageAnswer"
      value="yes"
      
    />
    <label htmlFor="yesMarried" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="noMarried"
      name="marriageAnswer"
      value="no"
   
    />
    <label htmlFor="noMarried" className='text-blue-500'>No</label>
  </div>
</div>

<p
  className='font-bold text-xl  text-blue-500 mt-10'
  
>
Your Prior Spouse(s)
</p>

<div className='p-2 ' >
  <span className='text-gray-500'>Provide details about all your prior spouses. Enter the dates of divorce, annulment, or death (if marriage ended through the death of a spouse). To add another record, click "Add Another Prior Spouse."</span>
</div>


<div className="">
                {nameSets.map((nameSet, index) => (
                  <>
                  <DottedLineWithText/>
                
                  
                  <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 '>
                    {index > 0 && (
                    
                      <div className='flex w-full justify-between ml-2'>
                    
                        <span className="text-gray-500">{`Add Other ${index}`}</span>
                        <button  type="button"  onClick={() => handleRemove(nameSet.id)} className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4">
                          Remove
                        </button>
                      </div>
                    
                    )}
                    
                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                        Given Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`givenName-${nameSet.id}`}
                        name={`givenName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        // value={nameSet.givenName}
                        // onChange={(e) => handleNameChange(nameSet.id, 'givenName', e.target.value)}
                      />
                    </div>

                    {/* Middle Name(s) */}
                    <div className="mb-4 flex ml-2">
                      <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700">
                        Middle Name(s): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`middleName-${nameSet.id}`}
                        name={`middleName-${nameSet.id}`}
                        // value={nameSet.middleName}
                        // onChange={(e) => handleNameChange(nameSet.id, 'middleName', e.target.value)}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-24'
                      />
                    </div>

                    {/* Family Name (Last Name) */}
                    <div className="mb-4 flex ml-2">
                      <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-500">
                        Family Name (Last Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`familyName-${nameSet.id}`}
                        name={`familyName-${nameSet.id}`}
                        // value={nameSet.familyName}
                        //  onChange={(e) => handleNameChange(nameSet.id, 'familyName', e.target.value)}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
                        
                      />
                    </div>
                    <div className="mb-4 flex ml-2">
                        <label htmlFor={`marriageDate-${nameSet.id}`} className="text-blue-500">
                            Date of Marriage Ended: <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="date"
                            id={`marriageDate-${nameSet.id}`}
                            name={`marriageDate-${nameSet.id}`}
                            // value={nameSet.marriageDate}
                            // onChange={(e) => handleNameChange(nameSet.id, 'marriageDate', e.target.value)}
                            className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-24 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
                                />
                                </div>
                  </div>
                  </>
                ))}


                {nameSets.length === 0 && (
                  <div>
                    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                    <span className="text-gray-500">Add Other</span>
                  </div>
                )}

                <button type="button" onClick={handleAddAnother} className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black">
                  Add Another Name
                </button>
              </div>

<hr className='bg-blue-700 h-1 w-full mt-20' style={{ width: '100%' }} />




<p className='font-bold text-xl text-blue-500 mt-8'>
Children
</p>

<div className='p-2'>
  <span className='text-gray-500'>
  In this section, children include any biological, adopted or step children under the age of 18.
  </span>
</div>


<div className="gap-10 p-4 mt-14 text-white rounded bg-blue-700">
  Do you have any children under 18 years of age?
  <span className="text-red-500">*</span>
</div>

<div className="flex gap-10 p-4 rounded bg-slate-200">
  <div className="flex gap-2">
    <input
      type="radio"
      id="yes3"
      name="ageanswer"
      value="yes"
    //   onChange={() => handleAgeChange('yes')}
    />
    <label htmlFor="yes3" className="text-blue-500">Yes</label>
  </div>
  <div className="flex gap-2">
    <input
      type="radio"
      id="no3"
      name="ageanswer"
      value="no"
    //   onChange={() => handleAgeChange('no')}
    />
    <label htmlFor="no3" className="text-blue-500">No</label>
  </div>
</div>



<div className='mt-20'>
              {nameSets.map((nameSet, index) => (
  <>
    <DottedLineWithText/>
  
    <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 '>
      {index > 0 && (
        <div className='flex w-full justify-between ml-2'>
          <span className="text-gray-500">{`Add Other ${index}`}</span>
          <button type="button" onClick={() => handleRemove(nameSet.id)} className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4">
            Remove
          </button>
        </div>
      )}

      <div className="mb-4 flex mt-6 ml-2">
        <label htmlFor={`age-${nameSet.id}`} className="text-blue-700">
          Age: <span className="text-red-500">*</span>
        </label>
        <input
          type="number"
          id={`age-${nameSet.id}`}
          name={`age-${nameSet.id}`}
          // value={nameSet.age}
          // onChange={(e) => handleNameChange(nameSet.id, 'age', e.target.value)}
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
        />
      </div>
    </div>
  </>
))}

{nameSets.length === 0 && (
  <div>
    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
    <span className="text-gray-500">Add Other</span>
  </div>
)}

<button type="button" onClick={handleAddAnother} className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black">
  Add Another Age
</button>

</div>


        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default InfoaboutParent
