



import React from 'react'

import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context134';

const Eligibility134 = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code ,territories  } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/beneficiaryinformation")
    }

    console.log(formData.selectedTerritory)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            
  
     <div className="mt-10">
  <label htmlFor="filingOnBehalfOf" className="bg-blue-700 text-white flex p-4">
    I am filing this form on behalf of:
  </label>
  <div className="bg-blue-100 p-4 flex flex-col gap-2">
    <div className='flex gap-2'>
    <input
      type="radio"
      id="myselfAsBeneficiary"
      name="filingOnBehalfOf"
      value="Myself as the beneficiary"
      checked={formData.filingOnBehalfOf === "Myself as the beneficiary"}
      onChange={handleInputChange}
    />
    <label htmlFor="myselfAsBeneficiary" className='text-blue-600'>Myself as the beneficiary</label>
    </div>
    <div className='flex gap-2'>
    <input
      type="radio"
      id="anotherIndividualAsBeneficiary"
      name="filingOnBehalfOf"
      value="Another individual who is the beneficiary"
      checked={formData.filingOnBehalfOf === "Another individual who is the beneficiary"}
      onChange={handleInputChange}
    />
    <label htmlFor="anotherIndividualAsBeneficiary" className='text-blue-600'>Another individual who is the beneficiary</label>
    </div>
  </div>
</div> 


<div> 
      {formData.filingOnBehalfOf === "Another individual who is the beneficiary"
         &&
        <>
        <h2 className='flex bg-blue-600 mt-10  text-white p-4 rounded'>Select your citizenship or immigration status:</h2>
      <div className='bg-blue-100 p-4 rounded gap-2 flex flex-col'>
        <div>
        <input
          type="radio"
          id="usCitizen"
          name="citizenshipOrImmigrationStatus"
          value="U.S. citizen"
          checked={formData.citizenshipOrImmigrationStatus === 'U.S. citizen'}
          onChange={handleInputChange}
        />
        <label htmlFor="usCitizen">U.S. citizen</label>
        </div>

        <div>
        <input
          type="radio"
          id="usNational"
          name="citizenshipOrImmigrationStatus"
          value="U.S. national"
          checked={formData.citizenshipOrImmigrationStatus === 'U.S. national'}
          onChange={handleInputChange}
        />
        <label htmlFor="usNational">U.S. national</label>
        </div>

        <div>
        <input
          type="radio"
          id="greenCardHolder"
          name="citizenshipOrImmigrationStatus"
          value="Lawful permanent resident (green card holder)"
          checked={formData.citizenshipOrImmigrationStatus === 'Lawful permanent resident (green card holder)'}
          onChange={handleInputChange}
        />
        <label htmlFor="greenCardHolder">Lawful permanent resident (green card holder)</label>
        </div>

        <div>
        <input
          type="radio"
          id="nonImmigrant"
          name="citizenshipOrImmigrationStatus"
          value="Non-immigrant"
          checked={formData.citizenshipOrImmigrationStatus === 'Non-immigrant'}
          onChange={handleInputChange}
        />
        <label htmlFor="nonImmigrant">Non-immigrant</label>
        </div>

        <div>
        <input
          type="radio"
          id="noneOfTheAbove"
          name="citizenshipOrImmigrationStatus"
          value="None of the above"
          checked={formData.citizenshipOrImmigrationStatus === 'None of the above'}
          onChange={handleInputChange}
        />
        <label htmlFor="noneOfTheAbove">None of the above</label>
        </div>
      </div>
      </>
      }

      <h2 className='flex bg-blue-600 mt-10 text-white rounded p-4'>Were you born in either American Samoa or Swains Island?</h2>
      <div className='flex bg-blue-100  rounded p-4'>
        <input
          type="radio"
          id="bornInAmericanSamoaOrSwainsIslandYes"
          name="bornInAmericanSamoaOrSwainsIsland"
          value="Yes"
          checked={formData.bornInAmericanSamoaOrSwainsIsland === 'Yes'}
          onChange={handleInputChange}
        />
        <label htmlFor="bornInAmericanSamoaOrSwainsIslandYes">Yes</label>

        <input
          type="radio"
          id="bornInAmericanSamoaOrSwainsIslandNo"
          name="bornInAmericanSamoaOrSwainsIsland"
          value="No"
          checked={formData.bornInAmericanSamoaOrSwainsIsland === 'No'}
          onChange={handleInputChange}
        />
        <label htmlFor="bornInAmericanSamoaOrSwainsIslandNo">No</label>
      </div>

     
    {  formData.bornInAmericanSamoaOrSwainsIsland === 'Yes'
    &&
    <>
    <h2 className='bg-blue-600 mt-10 flex text-white rounded p-4'>Select the basis for preparing this Declaration of Financial Support:</h2>
      <div className='bg-blue-100  p-4 rounded flex flex-col'>
        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingB1B2Visitor"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting a B-1 or B-2 visitor"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting a B-1 or B-2 visitor'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingB1B2Visitor">I am supporting a B-1 or B-2 visitor</label>
        </div>
        <div className='flex gap-2'>

        <input
          type="radio"
          id="supportingFianceK1Visa"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting a fiancé (K-1 visa)"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting a fiancé (K-1 visa)'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingFianceK1Visa">I am supporting a fiancé (K-1 visa)</label>
        </div>
        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingChildOfFianceK2Visa"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting the child of a fiancé (K-2 visa)"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting the child of a fiancé (K-2 visa)'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingChildOfFianceK2Visa">I am supporting the child of a fiancé (K-2 visa)</label>
        </div>

        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingStudentVisa"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting a student (F or M visa)"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting a student (F or M visa)'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingStudentVisa">I am supporting a student (F or M visa)</label>
        </div>

        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingHumanitarianParole"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting someone who is applying for humanitarian parole"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting someone who is applying for humanitarian parole'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingHumanitarianParole">I am supporting someone who is applying for humanitarian parole</label>
        </div>

        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingNonImmigrantVisitor"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting another non-immigrant visa visitor"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting another non-immigrant visa visitor'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingNonImmigrantVisitor">I am supporting another non-immigrant visa visitor</label>
        </div>

        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingImmigrantVisaGreenCard"
          name="basisForDeclarationOfFinancialSupport"
          value="I am supporting someone who is applying for an immigrant visa or green card"
          checked={formData.basisForDeclarationOfFinancialSupport === 'I am supporting someone who is applying for an immigrant visa or green card'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingImmigrantVisaGreenCard">I am supporting someone who is applying for an immigrant visa or green card</label>
        </div>

        <div className='flex gap-2'>
        <input
          type="radio"
          id="supportingNoneOfTheAbove"
          name="basisForDeclarationOfFinancialSupport"
          value="None of the above"
          checked={formData.basisForDeclarationOfFinancialSupport === 'None of the above'}
          onChange={handleInputChange}
        />
        <label htmlFor="supportingNoneOfTheAbove">None of the above</label>
        </div>
      </div>
      </>
    }
    </div>
   


{formData.filingOnBehalfOf === "Myself as the beneficiary"
&&
    <>
    <div className="mt-10">
  <label htmlFor="nonImmigrantStatus" className="bg-blue-700 text-white flex p-4">
    What is your non-immigrant status?
  </label>
  <div className="bg-blue-100 p-4 flex flex-col">
    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusB1B2"
      name="nonImmigrantStatus"
      value="B-1 or B-2"
      checked={formData.nonImmigrantStatus === "B-1 or B-2"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusB1B2" className='text-blue-600'>B-1 or B-2</label>
    </div>
    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusE1E2"
      name="nonImmigrantStatus"
      value="E-1 or E-2"
      checked={formData.nonImmigrantStatus === "E-1 or E-2"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusE1E2" className='text-blue-600'>E-1 or E-2</label>
    </div>
    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusH1B"
      name="nonImmigrantStatus"
      value="H-1B"
      checked={formData.nonImmigrantStatus === "H-1B"}
      onChange={handleInputChange}
    />
   
   
    <label htmlFor="nonImmigrantStatusH1B" className='text-blue-600'>H-1B</label>
    </div>
    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusL1"
      name="nonImmigrantStatus"
      value="L-1"
      checked={formData.nonImmigrantStatus === "L-1"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusL1" className='text-blue-600'>L-1</label>
    </div>

    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusTN"
      name="nonImmigrantStatus"
      value="TN"
      checked={formData.nonImmigrantStatus === "TN"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusTN" className='text-blue-600'>TN</label>
    </div>
    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusO1"
      name="nonImmigrantStatus"
      value="O-1"
      checked={formData.nonImmigrantStatus === "O-1"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusO1" className='text-blue-600'>O-1</label>
    </div>

     <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusFVisa"
      name="nonImmigrantStatus"
      value="F Visa"
      checked={formData.nonImmigrantStatus === "F Visa"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusFVisa" className='text-blue-600'>F Visa</label>
    </div>

    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusMVisa"
      name="nonImmigrantStatus"
      value="M Visa"
      checked={formData.nonImmigrantStatus === "M Visa"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusMVisa" className='text-blue-600'>M Visa</label>
    </div>
    <div className='flex gap-2'>
    <input
      type="radio"
      id="nonImmigrantStatusNone"
      name="nonImmigrantStatus"
      value="None of the above"
      checked={formData.nonImmigrantStatus === "None of the above"}
      onChange={handleInputChange}
    />
    <label htmlFor="nonImmigrantStatusNone" className='text-blue-600'>None of the above</label>
    </div>
  </div>
</div>
{formData.nonImmigrantStatus === "None of the above"
&&
  <div className='flex flex-col bg-red-200 p-2 gap-2'>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[40px] h-[40px]' />
        <p> <span className='font-bold'> We found a problem. Based on your answer, you cannot continue. </span> Generally, you must have one of the immigration statuses listed above in order to submit this form to USCIS.</p>
    </div>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[40px] h-[40px]'  />
        <p> Help me find an immigration attorney.</p>
    </div>
  </div>}



<div className="mt-10">
  <label htmlFor="servedInUSArmedForces" className="bg-blue-700 text-white flex p-4">
    Have you ever served honorably in the U.S. armed forces?
  </label>
  <div className="bg-blue-100 p-4 flex flex-col gap-2">
     <div className='flex gap-2'>
    <input
      type="radio"
      id="servedInUSArmedForcesYes"
      name="servedInUSArmedForces"
      value="Yes"
      checked={formData.servedInUSArmedForces === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="servedInUSArmedForcesYes" className='text-blue-600'>Yes</label>
    <input
      type="radio"
      id="servedInUSArmedForcesNo"
      name="servedInUSArmedForces"
      value="No"
      checked={formData.servedInUSArmedForces === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="servedInUSArmedForcesNo" className='text-blue-600'>No</label>
  </div>
  </div>
</div>
</>
}

{formData.servedInUSArmedForces === 'Yes'
    &&
    <div className="mt-10">
        <label htmlFor="activeDuty" className="bg-blue-700 text-white flex p-4">
          Are you active-duty?
        </label>
        <div className="bg-blue-100 p-4 flex  gap-2">
          <div className="flex gap-2">
            <input
              type="radio"
              id="activeDutyYes"
              name="activeDuty"
              value="Yes"
              checked={formData.activeDuty === 'Yes'}
              onChange={handleInputChange}
            />
            <label htmlFor="activeDutyYes" className="text-blue-600">
              Yes
            </label>
          </div>
          <div className="flex gap-2">
            <input
              type="radio"
              id="activeDutyNo"
              name="activeDuty"
              value="No"
              checked={formData.activeDuty === 'No'}
              onChange={handleInputChange}
            />
            <label htmlFor="activeDutyNo" className="text-blue-600">
              Non
            </label>
          </div>
        </div>
      </div>}


      {(formData.activeDuty === 'Yes' || formData.activeDuty === 'No')
        
        &&
        <div className="mt-10">
        <label htmlFor="atLeast18YearsOld" className="bg-blue-700 text-white flex p-4">
          Are you at least 18 years old?
        </label>
        <div className="bg-blue-100 p-4 flex  gap-2">
          <div className="flex gap-2">
            <input
              type="radio"
              id="atLeast18YearsOldYes"
              name="atLeast18YearsOld"
              value="Yes"
              checked={formData.atLeast18YearsOld === 'Yes'}
              onChange={handleInputChange}
            />
            <label htmlFor="atLeast18YearsOldYes" className="text-blue-600">
              Yes
            </label>
          </div>
          <div className="flex gap-2">
            <input
              type="radio"
              id="atLeast18YearsOldNo"
              name="atLeast18YearsOld"
              value="No"
              checked={formData.atLeast18YearsOld === 'No'}
              onChange={handleInputChange}
            />
            <label htmlFor="atLeast18YearsOldNo" className="text-blue-600">
              No
            </label>
          </div>
        </div>
      </div>}

      {formData.atLeast18YearsOld === 'No'
        &&
        <div className='flex bg-red-200 p-2  gap-2 items-center'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]' />
        <p> <span className='font-bold'> We found a problem. Based on your answer, you cannot continue.  </span>Generally you must be at least 18 years of age and reside in the United States to file Form I-134.</p>
      </div>
}


      {formData.atLeast18YearsOld === 'Yes'
        &&
        <div className="mt-10">
        <label htmlFor="liveInUSOrTerritories" className="bg-blue-700 text-white flex p-4">
          Do you live in the United States or its territories?
        </label>
        <div className="bg-blue-100 p-4 flex  gap-2">
          <div className="flex gap-2">
            <input
              type="radio"
              id="liveInUSOrTerritoriesYes"
              name="liveInUSOrTerritories"
              value="Yes"
              checked={formData.liveInUSOrTerritories === 'Yes'}
              onChange={handleInputChange}
            />
            <label htmlFor="liveInUSOrTerritoriesYes" className="text-blue-600">
              Yes
            </label>
          </div>
          <div className="flex gap-2">
            <input
              type="radio"
              id="liveInUSOrTerritoriesNo"
              name="liveInUSOrTerritories"
              value="No"
              checked={formData.liveInUSOrTerritories === 'No'}
              onChange={handleInputChange}
            />
            <label htmlFor="liveInUSOrTerritoriesNo" className="text-blue-600">
              No
            </label>
          </div>
        </div>
      </div>}

        
{formData.liveInUSOrTerritories === 'Yes'
    &&
    <div className='flex flex-col mt-10'>
  <label htmlFor="selectedTerritory" className="bg-blue-600 text-white p-4 rounded  ">
    Select a U.S. Territory:
  </label>
  <div className='flex bg-blue-100 p-4 '>
  <select
  id="selectedTerritory"
  name="selectedTerritory" // Name should match the name you're using in handleInputChange
  value={formData.selectedTerritory}
  onChange={handleInputChange}
  className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
>
    <option value="">Select a territory</option>
    {territories.map((territory, index) => (
      <option key={index} value={territory}>
        {territory}
      </option>
    ))}
  </select>
  </div>
</div>}


      {formData.selectedTerritory
        &&
        <div className="mt-10">
        <label htmlFor="hasSocialSecurityNumber" className="bg-blue-700 text-white flex p-4">
          Do you have a Social Security number?
        </label>
        <div className="bg-blue-100 p-4 flex  gap-2">
          <div className="flex gap-2">
            <input
              type="radio"
              id="hasSocialSecurityNumberYes"
              name="hasSocialSecurityNumber"
              value="Yes"
              checked={formData.hasSocialSecurityNumber === 'Yes'}
              onChange={handleInputChange}
            />
            <label htmlFor="hasSocialSecurityNumberYes" className="text-blue-600">
              Yes
            </label>
          </div>
          <div className="flex gap-2">
            <input
              type="radio"
              id="hasSocialSecurityNumberNo"
              name="hasSocialSecurityNumber"
              value="No"
              checked={formData.hasSocialSecurityNumber === 'No'}
              onChange={handleInputChange}
            />
            <label htmlFor="hasSocialSecurityNumberNo" className="text-blue-600">
              No
            </label>
          </div>
        </div>
      </div>}


      {formData.hasSocialSecurityNumber === 'No'
&&
  <div className='flex flex-col bg-red-200 p-2 gap-2'>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[40px] h-[40px]' />
        <p> <span className='font-bold'> We found a problem. Based on your answer, you cannot continue. </span> Generally, you must have one of the immigration statuses listed above in order to submit this form to USCIS.</p>
    </div>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[40px] h-[40px]'  />
        <p> Help me find an immigration attorney.</p>
    </div>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[40px] h-[40px]'  />
        <p> Help me find an immigration attorney.</p>
    </div>
  </div>}

    { formData.hasSocialSecurityNumber === 'Yes'
    &&
     <div className='mt-10'>
      <label htmlFor="householdSizeSelect" className='bg-blue-600 text-white flex p-4 rounded'>How many people are currently in your household?</label>
      <div className='bg-blue-100 p-4 flex'>
      <select
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6"
        id="householdSizeSelect"
        name="householdSize"
        value={formData.householdSize}
        onChange={handleInputChange}
      >
        {[1, 2, 3, 4, 5, 6,7 ,8].map((number) => (
          <option key={number} value={number}>
            {number}
          </option>
        ))}
      </select>
      </div>
    </div>
}

      {formData.hasSocialSecurityNumber === 'Yes'
        &&
        <div className="mt-10">
        <label htmlFor="incomeOver14580PerYear" className="bg-blue-700 text-white flex p-4">
          Is your individual income over $14,580 per year?
        </label>
        <div className="bg-blue-100 p-4 flex  gap-2">
          <div className="flex gap-2">
            <input
              type="radio"
              id="incomeOver14580PerYearYes"
              name="incomeOver14580PerYear"
              value="Yes"
              checked={formData.incomeOver14580PerYear === 'Yes'}
              onChange={handleInputChange}
            />
            <label htmlFor="incomeOver14580PerYearYes" className="text-blue-600">
              Yes
            </label>
          </div>
          <div className="flex gap-2">
            <input
              type="radio"
              id="incomeOver14580PerYearNo"
              name="incomeOver14580PerYear"
              value="No"
              checked={formData.incomeOver14580PerYear === 'No'}
              onChange={handleInputChange}
            />
            <label htmlFor="incomeOver14580PerYearNo" className="text-blue-600">
              No
            </label>
          </div>
        </div>
      </div>}


      { formData.incomeOver14580PerYear === 'Yes'
      &&
        <div className='bg-green-300 p-4 flex gap-2 mt-20'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className='w-[40px] h-[40px]' />
        <p> <span className='font-bold'>Congratulations, you are likely eligible to prepare Form I-134, Declaration of Financial Support.</span>
Please click "Next" to continue preparing this form.</p>
      </div>}
    







<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default Eligibility134