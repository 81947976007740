import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContextI31A = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContextI31A);
}

// Define the initial state
const initialState = {
    inTheUnitedStates: '',
    permanentResidentStatus: '',
    reasonForApplication: '',
    advanceParoleValid: '',
    inProceedings: '',
    chargedWithCrime: '',
    advanceParoleRevoked: '',
     replacingGreenCard: '',
     reasonoFApplication: '',
     reasonApplication: '',
     acknowledgeAttorney: false,
     nameOfApplicant: '',
     middleNames: '',
     lastName: '',
  nameLegallyChanged: '',
  inCareOfName: '',
  streetNumberAndName: '',
  cityOrTown: '',
  zipCode: '',
  apartment: 'No',
  stateOrTerritory: '',
  number: '',
  mailingAddressSameAsPhysical: '',
  currentPhysicalAddressStreetNumberAndName: '',
  currentPhysicalAddressCityOrTown: '',
  currentPhysicalAddressZipCode: '',
  currentPhysicalAddressApartment: 'No',
  currentPhysicalAddressStateOrTerritory: '',
  currentPhysicalAddressNumber: '',
  InformationDaytimePhoneNumber:"",
  alienRegistrationNumber: '',
  socialSecurityNumber: '',
  uscisOnlineAccountNumber: '',
  dateOfBirth: '',
  countryOfBirth: '',
  gender: '',
  countryOfCitizenship:"",
  departDate: '',
  returnDate: '',
  advanceParoleExpirationDate: '',
  formI131ReceiptNumber:"",
  receivedHelpFromInterpreter:"",
      foreignLanguage:"",
    interpreterGivenName:"",
    interpreterFamilyName:"",
    interpreterBusinessName:"",



  
  };

// Create a provider component to manage the state
export function FormProviderForI31A({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text': // Include input type "text"
        case 'number': // Include input type "number"
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one': // Handle select option
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      };
    });
  };

  const mapFormFields = () => {
    let mapping = {};
    
    mapping["form1[0].#subform[0].attorneyBox[0].attorneyUSCISacct[0].USCISOnlineAcctNumber[0]"] = "RandomUSCIS";
mapping["form1[0].#subform[0].attorneyBox[0].barNo[0].AttorneyStateBarNumber[0]"] = "12345";
mapping["form1[0].#subform[0].attorneyBox[0].g28[0].G28-CheckBox1[0]"] = true;
mapping["form1[0].#subform[0].Pt1_Line1a_FamilyName[0]"] = "RandomLastName";
mapping["form1[0].#subform[0].Pt1_Line1b_GivenName[0]"] = "RandomFirstName";
mapping["form1[0].#subform[0].Pt1_Line1c_MiddleName[0]"] = "RandomMiddleName";
mapping["form1[0].#subform[0].Pt1_Line2_Checkboxes[0]"] = true;
mapping["form1[0].#subform[0].Pt1_Line2_Checkboxes[1]"] = false;
mapping["form1[0].#subform[0].Pt1_Line4_Checkboxes[0]"] = true;
mapping["form1[0].#subform[0].Pt1_Line4_Checkboxes[1]"] = false;
mapping["form1[0].#subform[0].Pt1_Line3h_PostalCode[0]"] = "123456789";
mapping["form1[0].#subform[0].Pt1_Line3i_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[0].Pt1_Line3g_Province[0]"] = "RandomProvince12345678901234567890";
mapping["form1[0].#subform[0].Pt1_Line3d_CityOrTown[0]"] = "RandomCity12345678901234567890";
mapping["form1[0].#subform[0].Pt1_Line3b_StreetNumberName[0]"] = "RandomStreet123456789012345678901234567890";
mapping["form1[0].#subform[0].Pt1_Line3c_Unit[0]"] = true;
mapping["form1[0].#subform[0].Pt1_Line3c_Unit[1]"] = false;
mapping["form1[0].#subform[0].Pt1_Line3c_AptSteFlrNumber[0]"] = "123456";
mapping["form1[0].#subform[0].Pt1_Line3c_Unit[2]"] = true;
mapping["form1[0].#subform[0].Pt1_Line3f_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[0].Pt1_Line3e_State[0]"] = "RandomState";
mapping["form1[0].#subform[0].Pt1_Line3a_InCareOfName[0]"] = "RandomInCareOfName123456789012345678901234567890";
mapping["form1[0].#subform[3].Pt1_Line5a_StreetNumberName[0]"] = "RandomStreetNumberName123456789012345678901234567890";
mapping["form1[0].#subform[3].Pt1_Line5b_Unit[0]"] = true;
mapping["form1[0].#subform[3].Pt1_Line5b_Unit[1]"] = false;
mapping["form1[0].#subform[3].Pt1_Line5b_AptSteFlrNumber[0]"] = "123456";
mapping["form1[0].#subform[3].Pt1_Line5b_Unit[2]"] = true;
mapping["form1[0].#subform[3].Pt1_Line5c_CityOrTown[0]"] = "RandomCity12345678901234567890";
mapping["form1[0].#subform[3].Pt1Line5d_State[0]"] = "RandomState";
mapping["form1[0].#subform[3].Pt1Line5e_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[3].#area[0].Pt1_Line6_AlienNumber[0]"] = "RandomAlienNumber123456789";
mapping["form1[0].#subform[3].Pt1_Line7_USCISOnlineAcctNumber[0]"] = "RandomUSCISNumber";
mapping["form1[0].#subform[3].Pt1_Line8_SSN[0]"] = "123456789";
mapping["form1[0].#subform[3].Pt1_Line9_DateOfBirth[0]"] = "RandomDOB";
mapping["form1[0].#subform[3].Pt1_Line10_Checkboxes[0]"] = true;
mapping["form1[0].#subform[3].Pt1_Line10_Checkboxes[1]"] = false;
mapping["form1[0].#subform[3].Pt1_Line12_CountryOfCitizenship[0]"] = "RandomCitizenshipCountry";
mapping["form1[0].#subform[3].Pt1_Line11_CountryOfBirth[0]"] = "RandomBirthCountry";
mapping["form1[0].#subform[3].P2_Line1_checkbox[0]"] = true;
mapping["form1[0].#subform[3].P2_Line1_checkbox[1]"] = false;
mapping["form1[0].#subform[3].P2_Line1_checkbox[2]"] = true;
mapping["form1[0].#subform[3].P2_Line1_checkbox[3]"] = false;
mapping["form1[0].#subform[3].P2_Line1_checkbox[4]"] = true;
mapping["form1[0].#subform[3].P2_Line1_checkbox[5]"] = false;
mapping["form1[0].#subform[3].P2_Line1_checkbox[6]"] = true;
mapping["form1[0].#subform[3].P2_Line1_checkbox[7]"] = false;
mapping["form1[0].#subform[3].P2_Line1_checkbox[8]"] = true;
mapping["form1[0].#subform[3].P2_Line1i_Other[0]"] = "RandomOther";
mapping["form1[0].#subform[3].P3_Line1_DateDeparture[0]"] = "RandomDepartureDate";
mapping["form1[0].#subform[3].P3_Line2_DateIntendedTravel[0]"] = "RandomIntendedTravelDate";
mapping["form1[0].#subform[3].P3_Line3_ExpirationofPermanentCard[0]"] = "RandomExpirationDate";
mapping["form1[0].#subform[3].P3_Line4_DateReentryPermit[0]"] = "RandomReentryPermitDate";
mapping["form1[0].#subform[3].P3_Line2_DateExpirationI512I512LI766[0]"] = "RandomExpirationI512Date";
mapping["form1[0].#subform[3].P3_Line8_Checkboxes[0]"] = true;
mapping["form1[0].#subform[3].P3_Line8_Checkboxes[1]"] = false;
mapping["form1[0].#subform[3].P3_Line7_Checkboxes[0]"] = true;
mapping["form1[0].#subform[3].P3_Line7_Checkboxes[1]"] = false;
mapping["form1[0].#subform[3].P2_Line6_I131ReceiptNumber[0]"] = "RandomI131ReceiptNumber";
mapping["form1[0].#subform[3].P3_Line9a_Checkboxes[0]"] = true;
mapping["form1[0].#subform[3].P3_Line9a_Checkboxes[1]"] = false;
mapping["form1[0].#subform[3].P3_Line9c_Disposition[0]"] = "RandomDisposition";
mapping["form1[0].#subform[3].P3_Line9b_DateIssued[0]"] = "RandomDateIssued";
mapping["form1[0].#subform[4].P3_Line10_Checkboxes[0]"] = true;
mapping["form1[0].#subform[4].P3_Line10_Checkboxes[1]"] = false;
mapping["form1[0].#subform[4].P3_Line10b_DateRevocation[0]"] = "RandomRevocationDate";
mapping["form1[0].#subform[4].P3_Line10b_ReasonRevocation[0]"] = "RandomRevocationReason";
mapping["form1[0].#subform[4].P4_Line2_Checkbox[0]"] = true;
mapping["form1[0].#subform[4].P4_Line2_Consented[0]"] = "RandomConsented";
mapping["form1[0].#subform[4].P4_Line1b_NameofInterpreter[0]"] = "RandomInterpreterName";
mapping["form1[0].#subform[4].P4_Line1_Checkbox[0]"] = true;
mapping["form1[0].#subform[4].P4_Line1_Checkbox[1]"] = false;
mapping["form1[0].#subform[4].P4_Line5_Email[0]"] = "random@email.com";
mapping["form1[0].#subform[4].P4_Line3_DaytimeTelephoneNumber[0]"] = "1234567890";
mapping["form1[0].#subform[4].P4_Line4_MobileTelephoneNumber[0]"] = "1234567890";
mapping["form1[0].#subform[4].P4_Line6b_DateofSignature[0]"] = "RandomDateOfSignature";
mapping["form1[0].#subform[4].P4_Line6a_SignatureofApplicant[0]"] = "RandomApplicantSignature";
mapping["form1[0].#subform[4].P5_Line1a_InterpreterFamilyName[0]"] = "RandomInterpreterFamilyName";
mapping["form1[0].#subform[4].P5_Line1b_InterpreterGivenName[0]"] = "RandomInterpreterGivenName";
mapping["form1[0].#subform[4].P5_Line2_InterpreterBusiness[0]"] = "RandomInterpreterBusiness";
mapping["form1[0].#subform[5].P5_Line3c_CityOrTown[0]"] = "RandomCity";
mapping["form1[0].#subform[5].P5_Line3a_StreetNumberName[0]"] = "RandomStreetNumberName";
mapping["form1[0].#subform[5].P5_Line3b_Unit[0]"] = true;
mapping["form1[0].#subform[5].P5_Line3b_Unit[1]"] = false;
mapping["form1[0].#subform[5].P5_Line3b_AptSteFlrNumber[0]"] = "Apt123";
mapping["form1[0].#subform[5].P5_Line3b_Unit[2]"] = true;
mapping["form1[0].#subform[5].P5_Line3g_PostalCode[0]"] = "123456789";
mapping["form1[0].#subform[5].P5_Line3e_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[5].P5_Line3d_State[0]"] = "RandomState";
mapping["form1[0].#subform[5].P5_Line3h_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[5].P5_Line3f_Province[0]"] = "RandomProvince";
mapping["form1[0].#subform[5].P5_Line6_EmailAddress[0]"] = "random@email.com";
mapping["form1[0].#subform[5].P5_Line4_InterDayTel[0]"] = "9876543210";
mapping["form1[0].#subform[5].P5_Line5_InterDayTel[0]"] = "9876543210";
mapping["form1[0].#subform[5].P5_Line5b_Fluent[0]"] = "RandomFluent";
mapping["form1[0].#subform[5].P5_Line7a_Signature[0]"] = "S";
mapping["form1[0].#subform[5].P5_Line7b_DateofSignature[0]"] = "RandomDateOfSignature";
mapping["form1[0].#subform[5].P6_Line2_BusinessName[0]"] = "RandomBusinessName";
mapping["form1[0].#subform[5].P6_Line1b_PreparerGivenName[0]"] = "RandomPreparerGivenName";
mapping["form1[0].#subform[5].P6_Line1a_PreparerFamilyName[0]"] = "RandomPreparerFamilyName";
mapping["form1[0].#subform[5].Pt6_Line3c_CityOrTown[0]"] = "RandomCity";
mapping["form1[0].#subform[5].Pt6_Line3a_StreetNumberName[0]"] = "RandomStreetNumberName";
mapping["form1[0].#subform[5].Pt6_Line3b_Unit[0]"] = true;
mapping["form1[0].#subform[5].Pt6_Line3b_Unit[1]"] = false;
mapping["form1[0].#subform[5].Pt6_Line3b_AptSteFlrNumber[0]"] = "Apt456";
mapping["form1[0].#subform[5].Pt6_Line3b_Unit[2]"] = true;
mapping["form1[0].#subform[5].Pt6_Line3g_PostalCode[0]"] = "987654321";
mapping["form1[0].#subform[5].Pt6_Line3e_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[5].Pt6_Line3d_State[0]"] = "RandomState";
mapping["form1[0].#subform[5].Pt6_Line3h_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[5].Pt6_Line3f_Province[0]"] = "RandomProvince";
mapping["form1[0].#subform[5].Pt6_Line4_PrepDayPhone[0]"] = "9876543210";
mapping["form1[0].#subform[5].Pt6_Line5_PrepMobilePhone[0]"] = "9876543210";
mapping["form1[0].#subform[5].Pt6_Line6_PrepEmailAddress[0]"] = "random@email.com";
mapping["form1[0].#subform[6].Pt6_Line7_Checkbox[0]"] = true;
mapping["form1[0].#subform[6].Pt6_Line7_Checkbox[1]"] = false;
mapping["form1[0].#subform[6].Pt6_Line7b_Extend[0]"] = true;
mapping["form1[0].#subform[6].Pt6_Line7b_DoesNotExtend[0]"] = false;
mapping["form1[0].#subform[6].P6_L8a_PrepSignature[0]"] = "S";
mapping["form1[0].#subform[6].P6_L8b_PrepSignature[0]"] = "RandomPrepSignature";
mapping["form1[0].#subform[7].Pt1_Line6_AlienNumber[1]"] = "RandomAlienNumber";
mapping["form1[0].#subform[7].Pt7_Line3a_PageNumber[0]"] = "01";
mapping["form1[0].#subform[7].Pt7_Line3b_PartNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line3c_ItemNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line3d_AdditionalInfo[0]"] = "RandomAdditionalInfo";
mapping["form1[0].#subform[7].Pt7_Line4a_PageNumber[0]"] = "01";
mapping["form1[0].#subform[7].Pt7_Line4b_PartNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line4c_ItemNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line4d_AdditionalInfo[0]"] = "RandomAdditionalInfo";
mapping["form1[0].#subform[7].Pt7_Line5a_PageNumber[0]"] = "01";
mapping["form1[0].#subform[7].Pt7_Line5b_PartNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line5c_ItemNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line7c_ItemNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line7b_PartNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line7a_PageNumber[0]"] = "01";
mapping["form1[0].#subform[7].Pt7_Line6a_PageNumber[0]"] = "01";
mapping["form1[0].#subform[7].Pt7_Line6b_PartNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt7_Line6c_ItemNumber[0]"] = "123456";
mapping["form1[0].#subform[7].Pt1_Line1a_FamilyName[1]"] = "RandomFamilyName";
mapping["form1[0].#subform[7].Pt1_Line1b_GivenName[1]"] = "RandomGivenName";
mapping["form1[0].#subform[7].Pt1_Line1c_MiddleName[1]"] = "RandomMiddleName";
mapping["form1[0].#subform[7].Pt7_Line7d_AdditionalInfo[0]"] = "RandomAdditionalInfo";
mapping["form1[0].#subform[7].Pt7_Line6d_AdditionalInfo[0]"] = "RandomAdditionalInfo";
mapping["form1[0].#subform[7].Pt7_Line5d_AdditionalInfo[0]"] = "RandomAdditionalInfo";




// ... (continue with other mappings)

    
    return mapping;
  };




  const handleSubmitG131A = async () => {
    const data = mapFormFields();
    console.log(data);
     console.log("uzair")
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "I-131A",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };



  return (
    <FormContextI31A.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitG131A }}>
      {children}
    </FormContextI31A.Provider>
  );
}
