import React from 'react'
import safe from "../Assets/safe.png"

const FormFotter = () => {
  return (
    <div className='flex flex-col justify-between items-center mt-14 w-full mx-auto max-w-screen-xl px-6 py-4 bg-slate-100'>
    <div className='flex flex-col sm:flex-row '>
    <div className='mb-4 sm:mx-auto sm:text-center flex justify-center'>
    <img src={safe} alt="" className='max-w-full h-auto mr-14' />
</div>


<div className='mb-4 sm:mx-auto sm:text-center'>
    <p className='font-bold text-center'>
        This entire form and all your answers are secured using 128-bit SSL encryption.
    </p>
    <p className='text-center'>
        We are sensitive to your concerns about how we use the information we collect, for more details see our Privacy Policy.
    </p>
</div>

    </div>
</div>

    
  )
}

export default FormFotter
