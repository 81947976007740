import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import { useFormContext } from '../../UseContext/ContextI30';


const EndPage = () => {
    const {  formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
    };


    console.log(formData.tripDate)
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>

            <div className='mt-2'>
                <h1 className='text-blue-700 font-semibold text-xl'>Urgent Requests for a Travel Document</h1>
                <p className='text-sm'>USCIS may grant urgent travel authorization on a case-by-case status. By answering yes, CitizenPath will provide you with additional instructions for requesting expedited service. This is not a guarantee that USCIS will grant your request.</p>
            </div>

            <div>
      <div className='mt-10'>
        <label className="flex bg-blue-700 p-4 text-white  rounded">Do you have an urgent need to obtain this travel document?</label>
        <div className="flex  bg-blue-100 p-4  gap-4">
          <label className="flex  text-blue-600 gap-2">
            <input
              type="radio"
              name="urgentNeedForTravelDocument"
              value="Yes"
              checked={formData.urgentNeedForTravelDocument === 'Yes'}
              onChange={handleInputChange}
            /> Yes
          </label>
          <label className="flex  text-blue-600 gap-2">
            <input
              type="radio"
              name="urgentNeedForTravelDocument"
              value="No"
              checked={formData.urgentNeedForTravelDocument === 'No'}
              onChange={handleInputChange}
            /> No
          </label>
        </div>
      </div>
    </div>

    <div className='mt-10'>
      <p className="flex bg-blue-700 p-4 text-white  rounded">Who prepared this application?</p>
      <div className="flex  flex-col bg-blue-100 p-4  gap-4">
      <label className='text-blue-700'>
   
        <input
          type="radio"
          name="preparedByApplicant"
          value="I am the applicant, and I prepared this application."
          checked={formData.preparedByApplicant === "I am the applicant, and I prepared this application."}
          onChange={handleInputChange}
        />
        I am the applicant, and I prepared this application.
      </label>
      <label className='text-blue-700'>
        <input
          type="radio"
          name="preparedByApplicant"
          value="I am not an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent."
          checked={formData.preparedByApplicant === "I am not an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent."}
          onChange={handleInputChange}
        />
        I am not an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent.
      </label>
      <label className='text-blue-700'>
        <input
          type="radio"
          name="preparedByApplicant"
          value="I am an attorney or accredited representative of the applicant that prepared this application."
          checked={formData.preparedByApplicant === "I am an attorney or accredited representative of the applicant that prepared this application."}
          onChange={handleInputChange}
        />
        I am an attorney or accredited representative of the applicant that prepared this application.
      </label>
      </div>
    </div>
         
   { formData.preparedByApplicant === "I am an attorney or accredited representative of the applicant that prepared this application"
   &&
   <div className='mt-10'>
      <p className="flex bg-blue-700 p-4 text-white  rounded">Will you also represent the applicant beyond the preparation of the application?</p>
       <div className="flex   bg-blue-100 p-4  gap-4">
      <label className="text-blue-600 gap-2">
        
        <input
          type="radio"
          name="representApplicantBeyondPreparation"
          value="Yes"
          checked={formData.representApplicantBeyondPreparation === "Yes"}
          onChange={handleInputChange}
        />
        Yes
      </label>
      <label className="text-blue-600 gap-2">
        <input
          type="radio"
          name="representApplicantBeyondPreparation"
          value="No"
          checked={formData.representApplicantBeyondPreparation === "No"}
          onChange={handleInputChange}
        />
        No
      </label>
      </div>
    </div>}
         

    

       {  (formData.preparedByApplicant === "I am not an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent." 
            || formData.preparedByApplicant === "I am an attorney or accredited representative of the applicant that prepared this application")
       &&
       <>
       <hr className='h-1  bg-blue-600  mt-10'/>

         <h1 className='text-blue-600 text-xl font-semibold mt-10'>Preparer</h1>


      <div className=''>
    <div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Given Name (First Name):</label>
      <input
        className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
        type="text"
        name="preparerInformationGivenName"
        value={formData.preparerInformationGivenName}
        onChange={handleInputChange}
      /></div>
     
      <div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Family Name (Last Name):</label>
      <input
         className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
        type="text"
        name="preparerInformationFamilyName"
        value={formData.preparerInformationFamilyName}
        onChange={handleInputChange}
      />
      </div>
     
      <div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Business or Organization (if any):</label>
      <input
         className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
        type="text"
        name="preparerInformationBusinessOrOrganization"
        value={formData.preparerInformationBusinessOrOrganization}
        onChange={handleInputChange}
      />
    </div>

    <h1 className='text-blue-600 text-xl font-semibold mt-10'>Preparer's Mailing Address</h1>
    </div>
    
    <div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
  <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Street Number and Name:</label>
  <input
    className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
    type="text"
    name="preparersMailingAddressStreetNumberAndName"
    value={formData.preparersMailingAddressStreetNumberAndName}
    onChange={handleInputChange}
  />
</div>
<div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
  <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">City or Town:</label>
  <input
    className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
    type="text"
    name="preparersMailingAddressCityOrTown"
    value={formData.preparersMailingAddressCityOrTown}
    onChange={handleInputChange}
  />
</div>
<div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
  <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">ZIP Code:</label>
  <input
   className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
    type="text"
    name="preparersMailingAddressZIPCode"
    value={formData.preparersMailingAddressZIPCode}
    onChange={handleInputChange}
  />
</div>

<div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
  <label  className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">State (or Territory) under Preparer's Mailing Address:</label>
 
    <select
       className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
      name="preparersMailingAddressStateOrTerritory"
      value={formData.preparersMailingAddressStateOrTerritory}
      onChange={handleInputChange}
    >
      <option value="">Select a state or territory</option>
      {code.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select>
 
</div>

<div  className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
  <label  className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Country under Preparer's Mailing Address:</label>
  
    <select
       className="w-full md:w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
      name="preparersMailingAddressCountry"
      value={formData.preparersMailingAddressCountry}
      onChange={handleInputChange}
    >
      <option value="">Select a country</option>
      {code.map((country, index) => (
        <option key={index} value={country}>
          {country}
        </option>
      ))}
    </select>
 
</div>            
<div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
        Is there an apartment, suite, or floor?
      </label>
      
        <select
        className="w-[100px] md:w-[100px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
          name="preparersMailingAddressApartmentSuiteFloor"
          value={formData.preparersMailingAddressApartmentSuiteFloor}
          onChange={handleInputChange}
        >
          <option value="No">No</option>
          <option value="Apt">Apt</option>
          <option value="Flr">Flr</option>
          <option value="Ste">Ste</option>
        </select>

    </div>

    {formData.preparersMailingAddressApartmentSuiteFloor !== "No"
        &&
        <div  className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Enter the number here:</label>
      <input
        className="w-[100px] md:w-[100px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded md:ml-6"
        type="text"
        name="enterTheNumberHere"
        value={formData.enterTheNumberHere}
        onChange={handleInputChange}
      />
    </div>}


  <hr  className='h-1 bg-blue-600 mt-10'/>

  <h1 className='text-blue-600  font-semibold text-xl'> Preparer's Contact Information</h1>
 

 <div className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
  <div>
  <label htmlFor="timePhoneNumber" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
    Daytime phone number
  </label>
  <select
    id="timePhoneNumber"
    value={formData.timePhoneNumber}
    onChange={handleInputChange}
    className='w-full md:w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg md:ml-28'
  >
    <option value="">Select a country</option>
    {countryCodes.map((country) => (
      <option key={country.code} value={country.code}>
        {country.code} {country.name}
      </option>
    ))}
  </select>
  </div>
  <input
    value={formData.Mbnumber}
    onChange={handleInputChange}
    type="tel"
    placeholder="123-456-7890"
    className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
  />
</div>

<div   className='flex flex-col  md:flex-row gap-5 md:gap-10 mt-10'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Email (if any):</label>
      <input
        className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
      />
    </div>

    <div className='mt-10 flex flex-col gap-5 md:gap-8'>
      <label className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Extension:</label>
      <input
         className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        type="text"
        name="extension"
        value={formData.extension}
        onChange={handleInputChange}
      />
    </div>
    </>
    }
        
        <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>



          </form>
          <FormFotter/>
        </div>
      </div>
    )
}

export default EndPage
