



import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context751';

const Progress = () => {
    const eyeColors = ['Black', 'Blue', 'Brown', 'Gray', 'Green', 'Hazel', 'Maroon', 'Pink', 'Unknown/Other'];
    const hairColors = ['Bald (no hair)', 'Black', 'Blond', 'Brown', 'Gray', 'Red', 'Sandy', 'White', 'Unknown/Other'];

    const navigate=useNavigate()
    const { formData, handleInputChange ,code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/halfway")
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
          
     <div className="flex flex-col">
      <div className="mb-4 flex  flex-col mt-10">
        <label htmlFor="ethnicity" className="bg-blue-700 flex text-white p-4">
          Ethnicity:
        </label>
        <div className="flex bg-blue-100 p-4 gap-2">
          <label htmlFor="hispanic" className="text-blue-700">
            <input
              type="radio"
              id="hispanic"
              name="ethnicity"
              value="Hispanic"
              checked={formData.ethnicity === 'Hispanic'}
              onChange={handleInputChange}
            />
            Hispanic or Latino
          </label>
          <label htmlFor="notHispanic" className="text-blue-700">
            <input
              type="radio"
              id="notHispanic"
              name="ethnicity"
              value="Not Hispanic"
              checked={formData.ethnicity === 'Not Hispanic'}
              onChange={handleInputChange}
            />
            Not Hispanic or Latino
          </label>
        </div>
      </div>
    
    </div>



    <div className="mb-4 mt-6">
    <div className="mb-4 mt-6">
  <div className="flex flex-col md:flex-col w-full">
    <label className="bg-blue-700 flex text-white p-4 flex-col  md:w-full md:justify-end">Hair Color:</label>
    <div className="flex flex-col md:flex-row bg-blue-100 p-4 gap-3 md:flex-wrap">
      {hairColors.map((color) => (
        <label key={color} className="text-blue-700 mb-2 md:mb-0">
          <input
            type="radio"
            name="hairColor"
            value={color}
            checked={formData.hairColor === color}
            onChange={handleInputChange}
          />
          {color}
        </label>
      ))}
    </div>
  </div>
</div>


  <div className="flex flex-col w-full mt-4">
    <label className="bg-blue-700 text-white p-4 flex-col">Eye Color:</label>
    <div className="flex flex-col md:flex-row bg-blue-100 p-4 gap-3 md:flex-wrap">
      {eyeColors.map((color) => (
        <label key={color} className="text-blue-700 mb-2 md:mb-0">
          <input
            type="radio"
            name="eyeColor"
            value={color}
            checked={formData.eyeColor === color}
            onChange={handleInputChange}
          />
          {color}
        </label>
      ))}
    </div>
  </div>
</div>

<hr  className='h-1 bg-blue-600 mt-10 '  />

<h1 className='font-semibold text-xl text-blue-600 mt-5'>Birth Information</h1>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="countryOfBirth" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Country of Birth:
  </label>
  <select
    id="countryOfBirth"
    name="countryOfBirth"
    className="w-full md:w-[300px] outline-none text-blue-600 border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.countryOfBirth}
    onChange={handleInputChange}
  >
    <option value="">Select a Country</option>
    {code.map((countryName) => (
      <option key={countryName} value={countryName}>
        {countryName}
      </option>
    ))}
  </select>
</div>



<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="dateOfBirth" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Date of Birth:
  </label>
  <input
    type="date"
    id="dateOfBirth"
    name="dateOfBirth"
    className="w-full md:w-[300px] border outline-none text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.dateOfBirth}
    onChange={handleInputChange}
  />
</div>

<hr  className='h-1 bg-blue-600 mt-10 '  />


<h1 className='text-xl fonts-semibold text-blue-600'>Other Information</h1>

<h2 className='text-xl text-blue-600 mt-10'>Nationality</h2>
<p className='text-sm'>Select the name of the country where you are currently a citizen or national. If you are stateless, select the name of the country where you were last a citizen or national. If you are a citizen or national of more than one country, select "Other" from the list and then type the names of the countries.</p>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="countryOfCitizenship" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Country of Citizenship:
  </label>
  <select
    id="countryOfCitizenship"
    name="countryOfCitizenship"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.countryOfCitizenship}
    onChange={handleInputChange}
  >
    <option value="">Select a Country</option>
    {code.map((countryName) => (
      <option key={countryName} value={countryName}>
        {countryName}
      </option>
    ))}
  </select>
</div>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="alienRegistrationNumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Alien Registration Number (A-Number):
  </label>
  <input
    type="number"
    id="alienRegistrationNumber"
    name="alienRegistrationNumber"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.alienRegistrationNumber}
    onChange={handleInputChange}
  />
</div>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="socialSecurityNumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Social Security Number:
  </label>
  <input
    type="number"
    id="socialSecurityNumber"
    name="socialSecurityNumber"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.socialSecurityNumber}
    onChange={handleInputChange}
  />
</div>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="uscisOnlineAccountNumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    USCIS Online Account Number (if any):
  </label>
  <input
    type="number"
    id="uscisOnlineAccountNumber"
    name="uscisOnlineAccountNumber"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.uscisOnlineAccountNumber}
    onChange={handleInputChange}
  />
</div>
<hr  className='h-1 bg-blue-600 mt-10 '  />

<h1 className='text-xl fonts-semibold text-blue-600'>Contact Information</h1>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="daytimePhoneNumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Daytime Phone Number:
  </label>
  <input
    type="number"
    id="daytimePhoneNumber"
    name="daytimePhoneNumber"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.daytimePhoneNumber}
    onChange={handleInputChange}
  />
</div>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="mobilePhoneNumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Mobile Phone Number:
  </label>
  <input
    type="number"
    id="mobilePhoneNumber"
    name="mobilePhoneNumber"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.mobilePhoneNumber}
    onChange={handleInputChange}
  />
</div>

<div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="email" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    E-mail:
  </label>
  <input
    type="email"
    id="email"
    name="email"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.email}
    onChange={handleInputChange}
  />
</div>


<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>``



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default Progress