import React, { useState } from 'react';

const ForgetPassword = ({ onClose }) => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic for handling forgot password, e.g., sending a reset email
    console.log('Forgot Password submitted with:', { email });
    // Close the modal
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50 p-auto">
      <div className="bg-white rounded-lg w-[500px]" style={{ height: '300px' }}>
        <h2 className="p-4 flex bg-blue-900 text-white">Forgot Password</h2>
        <form onSubmit={handleSubmit} className="p-2">
          <div className="mb-4">
            <label htmlFor="email" className="block text-md text-gray-600 mt-2">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
              required
            />
          </div>

          <div className="flex gap-2 mt-4 ">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ml-auto"
            >
              Submit
            </button>
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;
