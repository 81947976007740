import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

const FormContext134 = createContext();

export function useFormContext() {
  return useContext(FormContext134);
}


const initialState = {
  
    filingOnBehalfOf: '', // Initialize with an empty value
    nonImmigrantStatus: '', // Initialize with an empty value
    servedInUSArmedForces: "",
// Have you ever served honorably in the U.S. armed forces?
    activeDuty: '', // Are you active-duty?
    atLeast18YearsOld: '', // Are you at least 18 years old?
    liveInUSOrTerritories: '', // Do you live in the United States or its territories?
    hasSocialSecurityNumber: '', // Do you have a Social Security number?
    incomeOver14580PerYear: '', 
    selectedTerritory:"",
    householdSize:"",

    citizenshipOrImmigrationStatus: '', // Initialize with an empty value
  bornInAmericanSamoaOrSwainsIsland: '', // Initialize with an empty value
  basisForDeclarationOfFinancialSupport: '', 

  givenName: '', // For the first name
  middleNames: '', // For middle name(s)
  familyName: '', 
  inCareOfName: '', // For "In Care of Name (if any)"
  streetNumberAndName: '', // For "Street Number and Name"
  enterNumber: '', // For "Enter the number here"
  cityOrTown: '', // For "City or Town"
  zipCode: '', // For "ZIP Code"
  apartmentSuiteFloor: 'No', 
  country: '', // For selecting the country
  stateOrTerritory: '',
  isPhysicalAddressSameAsMailing: '',


  beniinCareOfName: '', // For "In Care of Name (if any)"
  benistreetNumberAndName: '', // For "Street Number and Name"
  benienterNumber: '', // For "Enter the number here"
  benicityOrTown: '', // For "City or Town"
  benizipCode: '', // For "ZIP Code"
  beniapartmentSuiteFloor: 'No', 
  benicountry: '', // For selecting the country
  benistateOrTerritory: '',
  beniisPhysicalAddressSameAsMailing: '',

  countryOfCitizenship: '', // Initialize with an empty string
  dateFrom: '', // Initialize with an empty string
  dateTo: '',
  departureUponApproval: false,
  aNumber: '',
  maritalStatus: '',
  expectedIncome: '',
  noIncome: false, 
  incomeSource :"",
  willListBeneficiaryAssets: '', 


  formPreparer: '', // Initialize with an empty value
  representation: '',
  preparerGivenName: '', // Initialize with an empty value
  preparerFamilyName: '', // Initialize with an empty value
  preparerBusinessName: '',
  translationLanguage: '',
  interpretersGivenName: '', // Initialize with an empty value
  interpretersFamilyName: '', // Initialize with an empty value
  interpretersOrganizationName: '',
  interpreterInCareOfName: '', // Initialize with an empty value
  interpreterStreetNumberAndName: '', // Initialize with an empty value
  interpreterApartmentSuiteFloor: 'No', // Initialize with a default value
  interpreterEnterNumber: '', // Initialize with an empty value
  interpreterCityOrTown: '', // Initialize with an empty value
  interpreterCountry: '', // Initialize with an empty value
  interpreterStateOrTerritory: '', // Initialize with an empty value
  interpreterZIPCode: '',
  // Interpreter Information
  interpreterDaytimePhoneNumber: '', // Initialize with an empty value
  interpreterMobilePhoneNumber: '', // Initialize with an empty value
  interpreterEmail: '', // Initialize with an empty value
  interpreterReadAndUnderstood: false,
  
  };

// Create a provider component to manage the state
export function FormProviderFor134({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);


  const territories = [
    'Puerto Rico',
    'Guam',
    'U.S. Virgin Islands',
    'American Samoa',
    'Northern Mariana Islands',
    'District of Columbia',
    'U.S. Minor Outlying Islands',
    'Baker Island',
    'Howland Island',
    'Midway Atoll',
    'Johnston Atoll',
    'Wake Island',
    'Palmyra Atoll',
    'Kingman Reef',
  ];



  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate
              ? e.target.valueAsDate.toISOString().slice(0, 10)
              : '',
          };
        case 'text':
        case 'number':
        case 'textarea':
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: checked,
          };
        case 'select-one':
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      }
    });
  };

  
const mapFormFields = () => {
  let mapping = {};

  mapping["form1[0].#subform[2].Pt1Line1A_Checkbox[0]"] = true;
  mapping["form1[0].#subform[0].Pt3Line17[0]"] = true;
  mapping["form1[0].#subform[0].Pt3Line17[1]"] = false;
  mapping["form1[0].#subform[0].Pt1Line1_MiddleName[0]"] = "John";
  mapping["form1[0].#subform[0].Pt1Line1_GivenName[0]"] = "Doe";
  mapping["form1[0].#subform[0].Pt1Line1_FamilyName[0]"] = "Smith";
  mapping["form1[0].#subform[0].P1_Line3_MiddleName2[0]"] = "David";
  mapping["form1[0].#subform[0].P1_Line3_GivenName2[0]"] = "Jane";
  mapping["form1[0].#subform[0].P1_Line3_FamilyName2[0]"] = "Doe";
  mapping["form1[0].#subform[0].P1_Line3_MiddleName1[0]"] = "Alice";
  mapping["form1[0].#subform[0].P1_Line3_GivenName1[0]"] = "Bob";
  mapping["form1[0].#subform[0].P1_Line3_FamilyName1[0]"] = "Johnson";
  mapping["form1[0].#subform[0].#area[0].P2_Line8_DateOfBirth[0]"] = "19900101";
  mapping["form1[0].#subform[0]._Gender[0]"] = true;
  mapping["form1[0].#subform[0]._Gender[1]"] = false;
  mapping["form1[0].#subform[0].Pt1Line5_AlienNumber[0]"] = "123456789";
  mapping["form1[0].#subform[0].P2_Line8_Country[0]"] = "USA";
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[0]"] = true;
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[1]"] = false;
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[2]"] = true;
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[3]"] = false;
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[4]"] = true;
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[5]"] = false;
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatusOther[0]"] = "Married";
  mapping["form1[0].#subform[0].P2_Line12_MaritalStatus[6]"] = true;
  mapping["form1[0].#subform[0].Part2_Item6_Country[0]"] = "Canada";
  mapping["form1[0].#subform[0].Part2_Item6_Province[0]"] = "Ontario";
  mapping["form1[0].#subform[0].Part2_Item6_City[0]"] = "Toronto";
  mapping["form1[0].P2[0].Part2_Item11_InCareOfName[0]"] = "InCare";
  mapping["form1[0].P2[0].Part2_Item11_StreetName[0]"] = "Main St";
  mapping["form1[0].P2[0].Part2_Item11_Unit[0]"] = true;
  mapping["form1[0].P2[0].Part2_Item11_Unit[1]"] = false;
  mapping["form1[0].P2[0].Part2_Item11_Unit[2]"] = true;
  mapping["form1[0].P2[0].Part2_Item11_Number[0]"] = "123";
  mapping["form1[0].P2[0].Part2_Item11_City[0]"] = "New York";
  mapping["form1[0].P2[0].Part2_Item11_State[0]"] = "NY";
  mapping["form1[0].P2[0].Part2_Item11_ZipCode[0]"] = "10001";
  mapping["form1[0].P2[0].Part2_Item11_Province[0]"] = "Ontario";
  mapping["form1[0].P2[0].Part2_Item11_PostalCode[0]"] = "M1A2B3";
  mapping["form1[0].P2[0].Part2_Item11_Country[0]"] = "Canada";
  mapping["form1[0].P2[0].Pt2Line10_Yes[0]"] = true;
  mapping["form1[0].P2[0].Pt2Line10_No[0]"] = false;
  mapping["form1[0].P2[0].Pt1Line3_InCareOfName[0]"] = "InCareName";
  mapping["form1[0].P2[0].P2_Line11_PhysicalStreetName[0]"] = "Main Street";
  mapping["form1[0].P2[0].P2_Line11_Unit[0]"] = true;
  mapping["form1[0].P2[0].P2_Line11_Unit[1]"] = false;
  mapping["form1[0].P2[0].P2_Line11_Unit[2]"] = true;
  mapping["form1[0].P2[0].P2_Line11_Number[0]"] = "456";
  mapping["form1[0].P2[0].Pt2Line11_City[0]"] = "New York";
  mapping["form1[0].P2[0].Pt2Line11_Province[0]"] = "Ontario";
  mapping["form1[0].P2[0].Pt2Line11_State[0]"] = "NY";
  mapping["form1[0].P2[0].Pt2Line11_ZipCode[0]"] = "10002";
  mapping["form1[0].P2[0].Pt2Line11_PostalCode[0]"] = "M1B2C3";
  mapping["form1[0].P2[0].Pt2Line11_Country[0]"] = "USA";
  mapping["form1[0].P2[0].Pt2Line12_DateFiled[0]"] = "20230101";
  mapping["form1[0].P2[0].Pt2Line12_Date[0]"] = true;
  mapping["form1[0].P2[0].Pt2Line12_DateFiled[1]"] = "20230101";
  mapping["form1[0].P2[0].Pt2Line12_NoDate[0]"] = false;
  mapping["form1[0].P3[0].Pt1Line3_InCareOfName[0]"] = "InCareName1";
  mapping["form1[0].P3[0].Pt1Line3_InCareOfName[1]"] = "InCareName2";
  mapping["form1[0].P3[0].Pt1Line3_InCareOfName[2]"] = "InCareName3";
  mapping["form1[0].P3[0].Pt1Line3_InCareOfName[3]"] = "InCareName4";
  mapping["form1[0].P3[0].Pt1Line3_InCareOfName[4]"] = "InCareName5";
  mapping["form1[0].P3[0].P2_Line8_DateOfBirth[0]"] = "19900101";
  mapping["form1[0].P3[0].P2_Line8_DateOfBirth[1]"] = "19900202";
  mapping["form1[0].P3[0].P2_Line8_DateOfBirth[2]"] = "19900303";
  mapping["form1[0].P3[0].Pt2Line13E_FamilyName[0]"] = "FamilyName1";
  mapping["form1[0].P3[0].Pt3Line3Cell3_Amount[0]"] = "123456789";
  mapping["form1[0].P3[0].Pt3Line3Cell3_Amount[1]"] = "987654321";
  mapping["form1[0].P3[0].Pt2Line13D_FamilyName[0]"] = "FamilyName2";
  mapping["form1[0].P3[0].P2_Line8_DateOfBirth[3]"] = "19900404";
  mapping["form1[0].#subform[2].Pt1Line1A_Checkbox[0]"] = true;
mapping["form1[0].P3[0].Pt2Line13C_FamilyName[0]"] = "FamilyName3";
mapping["form1[0].P3[0].Pt3Line3Cell3_Amount[2]"] = "987654321";
mapping["form1[0].P3[0].Pt3Line3Cell3_Amount[3]"] = "123456789";
mapping["form1[0].P3[0].Pt2Line13B_FamilyName[0]"] = "FamilyName4";
mapping["form1[0].P3[0].P2_Line8_DateOfBirth[4]"] = "19900505";
mapping["form1[0].P3[0].Pt2Line13a_FamilyName[0]"] = "FamilyName5";
mapping["form1[0].P3[0].Pt3Line3Cell3_Amount[4]"] = "876543210";
mapping["form1[0].P3[0].Pt1Line12_Age[0]"] = "30";
mapping["form1[0].P3[0].Pt3Line9Cell9_Total[0]"] = "50000";
mapping["form1[0].P3[0].Pt3Line15A_No[0]"] = false;
mapping["form1[0].P3[0].Pt3Line15A_Yes[0]"] = true;
mapping["form1[0].P3[0].Pt3Line15B_Annual[0]"] = "10000";
mapping["form1[0].P4[0].Pt3Line9Cell9_Total[0]"] = "60000";
mapping["form1[0].P4[0].Pt3Line1Cell1_TypeofAssetDropDownList[0]"] = "Real Estate";
mapping["form1[0].P4[0].Pt3Line2Cell2_TypeofAssetDropDownList[0]"] = "Stocks";
mapping["form1[0].P4[0].Pt3Line3Cell3_TypeofAssetDropDownList[0]"] = "Bonds";
mapping["form1[0].P4[0].Pt3Line4Cell4_TypeofAssetDropDownList[0]"] = "Mutual Funds";
mapping["form1[0].P4[0].Pt3Line5Cell5_TypeofAssetDropDownList[0]"] = "Retirement Accounts";
mapping["form1[0].P4[0].Pt3Line6Cell6_TypeofAssetDropDownList[0]"] = "Savings Accounts";
mapping["form1[0].P4[0].Pt3Line7Cell7_TypeofAssetDropDownList[0]"] = "Other";
mapping["form1[0].P4[0].Pt3Line8Cell8_CashValue[0]"] = "120000";
mapping["form1[0].P4[0].Pt3Line1Cell1_Amount[0]"] = "15000";
mapping["form1[0].P4[0].Pt3Line2Cell2_Amount[0]"] = "20000";
mapping["form1[0].P4[0].Pt3Line3Cell3_Amount[0]"] = "25000";
mapping["form1[0].P4[0].Pt3Line4Cell4_Amount[0]"] = "30000";
mapping["form1[0].P4[0].Pt3Line5Cell5_Amount[0]"] = "35000";
mapping["form1[0].P4[0].Pt3Line6Cell6_Amount[0]"] = "40000";
mapping["form1[0].P4[0].Pt3Line7Cell7_Amount[0]"] = "45000";
mapping["form1[0].P4[0].Row1[0].Pt3Line1Cell1_NameOfAssetHolder[0]"] = "AssetHolder1";
mapping["form1[0].P4[0].Row2[0].Pt3Line2Cell2_NameOfAssetHolder[0]"] = "AssetHolder2";
mapping["form1[0].P4[0].Row3[0].Pt3Line3Cell3_NameOfAssetHolder[0]"] = "AssetHolder3";
mapping["form1[0].P4[0].Row4[0].Pt3Line4Cell4_NameOfAssetHolder[0]"] = "AssetHolder4";
mapping["form1[0].P4[0].Row5[0].Pt3Line5Cell5_NameOfAssetHolder[0]"] = "AssetHolder5";
mapping["form1[0].P4[0].Row6[0].Pt3Line6Cell6_NameOfAssetHolder[0]"] = "AssetHolder6";
mapping["form1[0].P4[0].Row7[0].Pt3Line7Cell7_NameOfAssetHolder[0]"] = "AssetHolder7";
mapping["form1[0].P4[0].Pt1Line1_FamilyName[0]"] = "FamilyName6";
mapping["form1[0].P4[0].Pt1Line1_GivenName[0]"] = "GivenName1";
mapping["form1[0].P4[0].Pt1Line1_MiddleName[0]"] = "MiddleName1";
mapping["form1[0].P4[0].P1_Line3_MiddleName2[0]"] = "MiddleName2";
mapping["form1[0].P4[0].P1_Line3_GivenName2[0]"] = "GivenName2";
mapping["form1[0].P4[0].P1_Line3_FamilyName2[0]"] = "FamilyName2";
mapping["form1[0].P4[0].P1_Line3_MiddleName1[0]"] = "MiddleName1";
mapping["form1[0].P4[0].P1_Line3_GivenName1[0]"] = "GivenName1";
mapping["form1[0].P4[0].P1_Line3_FamilyName1[0]"] = "FamilyName1";
mapping["form1[0].P4[0].Pt2Line10_ZipCode[0]"] = "12345";
mapping["form1[0].P4[0].P2_Line10_Number[0]"] = "123456";
mapping["form1[0].P4[0].Pt2Line10_PostalCode[0]"] = "Postal1234";
mapping["form1[0].P4[0].Pt2Line10_Province[0]"] = "Province12345678901234567890";
mapping["form1[0].P5[0].Pt2Line10_ZipCode[0]"] = "12345";
mapping["form1[0].P5[0].P2_Line10_Number[0]"] = "123456";
mapping["form1[0].P5[0].Pt2Line10_PostalCode[0]"] = "Postal1234";
mapping["form1[0].P5[0].Pt2Line10_Province[0]"] = "Province12345678901234567890";
mapping["form1[0].P5[0].P2_Line8_DateOfBirth[0]"] = "19801231";
mapping["form1[0].P5[0].Pt1Line8_AlienNumber[0]"] = "123456789";
mapping["form1[0].P5[0].Pt1Line10_OnlineAccountNumber[0]"] = "Account123456789";
mapping["form1[0].#subform[2].Pt1Line1A_Checkbox[0]"] = true;
mapping["form1[0].P5[0]._I94[0]"] = "12345678901";
mapping["form1[0].P5[0].P3_Line10_Other[0]"] = "Other12345678901234567890123456789012345678901234567890";
mapping["form1[0].P5[0].Part3_Item7_Country[0]"] = "Country1234567890123456789012345678901";
mapping["form1[0].P5[0].Part3_Item7_Province[0]"] = "Province12345678901234567890";
mapping["form1[0].P5[0].Part3_Item7_City[0]"] = "City1234567890123456789012345678901234567890";
mapping["form1[0].P6[0].Pt3Line12a_EmployedType[0]"] = "EmployedType1234567890123456789012345678901";
mapping["form1[0].P6[0].Pt3Line12a_NameOfEmployer[0]"] = "EmployerName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line13_StreetName[0]"] = "StreetName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line13_Number[0]"] = "123456";
mapping["form1[0].P6[0].P3_Line13_City[0]"] = "City1234567890123456789012345678901234567890";
mapping["form1[0].P6[0].P3Line13_ZipCode[0]"] = "12345";
mapping["form1[0].P6[0].P3Line13_Province[0]"] = "Province12345678901234567890";
mapping["form1[0].P6[0].P3Line13_PostalCode[0]"] = "Postal1234";
mapping["form1[0].P6[0].P3Line13_Country[0]"] = "Country1234567890123456789012345678901";
mapping["form1[0].P6[0].Pt1Line3_InCareOfName[0]"] = "InCareOfName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line14a_DateOfBirth[0]"] = "19801231";
mapping["form1[0].P6[0].P3_Line14a_FamilyName[0]"] = "FamilyName1";
mapping["form1[0].P6[0].Pt3Line3Cell3_Amount[0]"] = "12345678";
mapping["form1[0].P6[0].Pt1Line3_InCareOfName[1]"] = "InCareOfName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line14b_DateOfBirth[0]"] = "19801231";
mapping["form1[0].P6[0].P3_Line14b_FamilyName[0]"] = "FamilyName2";
mapping["form1[0].P6[0].Pt3Line3Cell3_Amount[1]"] = "87654321";
mapping["form1[0].P6[0].Pt1Line3_InCareOfName[2]"] = "InCareOfName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line14c_DateOfBirth[0]"] = "19801231";
mapping["form1[0].P6[0].P3_Line14c_FamilyName[0]"] = "FamilyName3";
mapping["form1[0].P6[0].Pt1Line3_InCareOfName[3]"] = "InCareOfName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line14d_DateOfBirth[0]"] = "19801231";
mapping["form1[0].P6[0].P3_Line14d_FamilyName[0]"] = "FamilyName4";
mapping["form1[0].P6[0].Pt3Line3Cell3_Amount[2]"] = "98765432";
mapping["form1[0].P6[0].Pt3Line3Cell3_Amount[3]"] = "23456789";
mapping["form1[0].P6[0].Pt1Line3_InCareOfName[4]"] = "InCareOfName1234567890123456789012345678901";
mapping["form1[0].P6[0].P3_Line14e_DateOfBirth[0]"] = "19801231";
mapping["form1[0].P6[0].P3_Line14e_FamilyName[0]"] = "FamilyName5";
mapping["form1[0].P6[0].Pt3Line3Cell3_Amount[4]"] = "54321678";
mapping["form1[0].P6[0].Pt1Line12_Age[0]"] = "30";
mapping["form1[0].P6[0].Pt3Line9Cell9_Total[0]"] = "123456789";
mapping["form1[0].P7[0].Pt3Line1A_TtlIncome[0]"] = "987654321";
mapping["form1[0].P7[0].Table1[0].Row1[0].Pt3Line1Cell1_NameOfAssetHolder[0]"] = "AssetHolder1";
mapping["form1[0].P7[0].Table1[0].Row1[0].Pt3Line1Cell1_Amount[0]"] = "12345678";
mapping["form1[0].P7[0].Table1[0].Row2[0].Pt3Line2Cell2_NameOfAssetHolder[0]"] = "AssetHolder2";
mapping["form1[0].P7[0].Table1[0].Row2[0].Pt3Line2Cell2_Amount[0]"] = "87654321";
mapping["form1[0].P7[0].Table1[0].Row3[0].Pt3Line3Cell3_NameOfAssetHolder[0]"] = "AssetHolder3";
mapping["form1[0].P7[0].Table1[0].Row3[0].Pt3Line3Cell3_Amount[0]"] = "23456789";
mapping["form1[0].P7[0].Table1[0].Row4[0].Pt3Line4Cell4_NameOfAssetHolder[0]"] = "AssetHolder4";
mapping["form1[0].P7[0].Table1[0].Row4[0].Pt3Line4Cell4_Amount[0]"] = "54321678";
mapping["form1[0].P7[0].Table1[0].Row5[0].Pt3Line5Cell5_NameOfAssetHolder[0]"] = "AssetHolder5";
mapping["form1[0].P7[0].Table1[0].Row5[0].Pt3Line5Cell5_Amount[0]"] = "98765432";
mapping["form1[0].P7[0].Table1[0].Row6[0].Pt3Line6Cell6_NameOfAssetHolder[0]"] = "AssetHolder6";
mapping["form1[0].P7[0].Table1[0].Row6[0].Pt3Line6Cell6_Amount[0]"] = "65432109";
mapping["form1[0].P7[0].Table1[0].Row7[0].Pt3Line7Cell7_NameOfAssetHolder[0]"] = "AssetHolder7";
mapping["form1[0].P7[0].Table1[0].Row7[0].Pt3Line7Cell7_Amount[0]"] = "10987654";
mapping["form1[0].P7[0].Pt3Line8Cell8_CashValue[0]"] = "98765432";
mapping["form1[0].P7[0]._FamilyName[0]"] = "FamilyName6";
mapping["form1[0].P7[0]._GivenName[0]"] = "GivenName6";
mapping["form1[0].P7[0]._MiddleName[0]"] = "MiddleName6";
mapping["form1[0].P7[0]._AlienNumber21[0]"] = "AlienNumber123";
mapping["form1[0].P7[0].DateOfBirth[0]"] = "19901231";
mapping["form1[0].P7[0]._FamilyName[1]"] = "FamilyName7";
mapping["form1[0].#subform[2].Pt1Line1A_Checkbox[0]"] = true;
mapping["form1[0].P7[0]._GivenName[1]"] = "GivenName7";
mapping["form1[0].P7[0]._MiddleName[1]"] = "MiddleName7";
mapping["form1[0].P7[0]._AlienNumber22[0]"] = "AlienNumber456";
mapping["form1[0].P7[0].DateOfBirth[1]"] = "19891231";
mapping["form1[0].P8[0].P8_Line1B_Language[0]"] = "Language123";
mapping["form1[0].P8[0].Pt4Line1b_language[0]"] = "Language789";
mapping["form1[0].P8[0].Pt4Line2_RepresentativeName[0]"] = "RepresentativeName123";
mapping["form1[0].P8[0].Part4_Line5_EmailAddress[0]"] = "email@example.com";
mapping["form1[0].P8[0].Part4_Line4_SafePhoneNumber3[0]"] = "123456789012345";
mapping["form1[0].P8[0].Part4_Line3_DaytimePhoneNumber3[0]"] = "987654321098765";
mapping["form1[0].P9[0].P8_Line6_Sign[0]"] = "Signature9";
mapping["form1[0].P9[0].P8_Line6_Date[0]"] = "20231123";
mapping["form1[0].P9[0].Pt4Line1b_language[0]"] = "Language789";
mapping["form1[0].P9[0].Part4_Line5_EmailAddress[0]"] = "email@example.com";
mapping["form1[0].P9[0].Part4_Line4_SafePhoneNumber3[0]"] = "123456789012345";
mapping["form1[0].P9[0].Part4_Line3_DaytimePhoneNumber3[0]"] = "987654321098765";
mapping["form1[0].P10[0].P8_Line6_Sign[0]"] = "Signature10";
mapping["form1[0].P10[0].P8_Line6_Date[0]"] = "20231123";
mapping["form1[0].P10[0].Pt6Line1_InterpreterGivenName[0]"] = "InterpreterGivenName";
mapping["form1[0].P10[0].Pt6Line1_InterpreterFamilyName[0]"] = "InterpreterFamilyName";
mapping["form1[0].P10[0].Pt6Line2_BusinessOrOrgName[0]"] = "OrgName123";
mapping["form1[0].P11[0].Pt6Line3_StreetNumberName[0]"] = "StreetName123";
mapping["form1[0].P11[0].Pt6Line3_AptSteFlrNumber[0]"] = "Apt123";
mapping["form1[0].P11[0].Pt6Line3_CityOrTown[0]"] = "City123";
mapping["form1[0].P11[0].Pt6Line3_State[0]"] = "StateXYZ";
mapping["form1[0].P11[0].Pt6Line3_ZipCode[0]"] = "12345";
mapping["form1[0].P11[0].Pt6Line3_PostalCode[0]"] = "K1A2B3C4D";
mapping["form1[0].P11[0].Pt6Line3_Province[0]"] = "Province123";
mapping["form1[0].P11[0].Pt6Line3_Country[0]"] = "Country123";
mapping["form1[0].P11[0].P3_Line4_DaytimeTelePhoneNumber[0]"] = "123456789012345";
mapping["form1[0].P11[0].P3_Line5_MobileTelePhoneNumber[0]"] = "987654321098765";
mapping["form1[0].P11[0].P3_Line6_Email[0]"] = "email@example.com";
mapping["form1[0].P11[0].P4_Line6_Language[0]"] = "Language1234";
mapping["form1[0].P11[0].P12_SignatureApplicant[0]"] = "ApplicantSignature";
mapping["form1[0].P11[0].P13_DateofSignature[0]"] = "20231123";
mapping["form1[0].P11[0].P4_Line1_InterpreterGivenName[0]"] = "InterpreterGivenName";
mapping["form1[0].P11[0].P4_Line1_InterpreterFamilyName[0]"] = "InterpreterFamilyName";
mapping["form1[0].P11[0].P4_Line2_NameofBusinessorOrgName[0]"] = "OrgName1234";
mapping["form1[0].P12[0].Pt7Line3_StreetNumberName[0]"] = "StreetName1234";
mapping["form1[0].P12[0].Pt7Line3_Unit[0]"] = "Unit1";
mapping["form1[0].P12[0].Pt7Line3_Unit[1]"] = "Unit2";
mapping["form1[0].P12[0].Pt7Line3_Unit[2]"] = "Unit3";
mapping["form1[0].P12[0].Pt7Line3_AptSteFlrNumber[0]"] = "Apt123";
mapping["form1[0].P12[0].Pt7Line3_CityOrTown[0]"] = "City1234";
mapping["form1[0].P12[0].Pt7Line3_State[0]"] = "StateXYZ";
mapping["form1[0].P12[0].Pt7Line3_ZipCode[0]"] = "56789";
mapping["form1[0].P12[0].Pt7Line3_PostalCode[0]"] = "M5H2N2P3Q";
mapping["form1[0].P12[0].Pt7Line3_Province[0]"] = "Province5678";
mapping["form1[0].P12[0].Pt7Line3_Country[0]"] = "Country5678";
mapping["form1[0].P12[0].Pt7Line4_DaytimeTelephoneNum[0]"] = "987654321012345";
mapping["form1[0].P12[0].Pt7Line3_MobileTelephoneNum[0]"] = "123456789098765";
mapping["form1[0].P12[0].Pt7Line6_Email[0]"] = "email@example.org";
mapping["form1[0].#subform[2].Pt1Line1A_Checkbox[0]"] = true;
mapping["form1[0].P12[0].Pt7Line7A_Checkbox[0]"] = true;
mapping["form1[0].P12[0].Pt7Line7B_Checkbox[0]"] = true;
mapping["form1[0].P12[0].Pt7Line7B_extends[0]"] = true;
mapping["form1[0].P12[0].Pt7Line7B_notextends[0]"] = true;
mapping["form1[0].P12[0].Pt7Line8_SignatureofPreparer[0]"] = "PreparerSignature";
mapping["form1[0].P12[0].Pt7Line8_DateofSignature[0]"] = "20231123";
mapping["form1[0].P13[0].Pt9Line3d_AdditionalInfo[0]"] = "AdditionalInfo1";
mapping["form1[0].P13[0].Pt9Line3a_PageNumber[0]"] = "12";
mapping["form1[0].P13[0].Pt9Line3b_PartNumber[0]"] = "Part123";
mapping["form1[0].P13[0].Pt9Line3c_ItemNumber[0]"] = "Item123";
mapping["form1[0].P13[0].Line3_ANumber[0].Pt1Line5_AlienNumber[0]"] = "AlienNumber789";
mapping["form1[0].P13[0].Pt9Line4a_PageNumber[0]"] = "34";
mapping["form1[0].P13[0].Pt9Line4b_PartNumber[0]"] = "Part456";
mapping["form1[0].P13[0].Pt9Line4c_ItemNumber[0]"] = "Item456";
mapping["form1[0].P13[0].Pt1Line1_GivenName[0]"] = "GivenName123";
mapping["form1[0].P13[0].Pt1Line1_FamilyName[0]"] = "FamilyName123";
mapping["form1[0].P13[0].Pt1Line1_MiddleName[0]"] = "MiddleName123";
mapping["form1[0].P13[0].Pt9Line4a_PageNumber[1]"] = "56";
mapping["form1[0].P13[0].Pt9Line4b_PartNumber[1]"] = "Part789";
mapping["form1[0].P13[0].Pt9Line4c_ItemNumber[1]"] = "Item789";
mapping["form1[0].P13[0].Pt9Line4a_PageNumber[2]"] = "78";
mapping["form1[0].P13[0].Pt9Line4b_PartNumber[2]"] = "Part012";
mapping["form1[0].P13[0].Pt9Line4c_ItemNumber[2]"] = "Item012";
mapping["form1[0].P13[0].Pt9Line3d_AdditionalInfo[1]"] = "AdditionalInfo2";
mapping["form1[0].P13[0].Pt9Line3d_AdditionalInfo[2]"] = "AdditionalInfo3";
mapping["form1[0].P13[0].Pt9Line3d_AdditionalInfo[3]"] = "AdditionalInfo4";
 
  return mapping;
};




const handleSubmitI134= async () => {
  const data = mapFormFields();
  console.log(data);
   console.log("uzair")
  // Now, you can call your API with this data 
  try {
    const response = await axiosRequest.post("/fillForm", {
      "formName": "I-134",
      "isCompleted": true,
      "userData": data
    });
    console.log(response);
    alert("Form submitted successfully!");
  }
  catch (error) {
    console.log(error);
    alert(error.message);
  }
};

  

  return (
    <FormContext134.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange, territories ,handleSubmitI134  }}>
      {children}
    </FormContext134.Provider>
  );
}
