




import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context751';
import  crosspic from "../../Assets/crosspic.png"

const Halfway = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code } = useFormContext();

    const relationshipInfo = formData.gainedThrough === 'parentSpouse' ? 'Parent Information' : 'Spouse Information';

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/child")
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            
      


<div className="mb-4 flex flex-col mt-6">
  <label className="bg-blue-700  flex f mr-4 text-white p-4 rounded">
    The U.S. citizen or legal permanent resident through whom I gained conditional residence is:
  </label>
  <div className="flex bg-blue-100 p-4 rounded">

    <input
      type="radio"
      id="gainedThroughSpouse"
      name="gainedThrough"
      value="spouse"
      checked={formData.gainedThrough === 'spouse'}
      onChange={handleInputChange}
    />
        <label htmlFor="gainedThroughSpouse" className="text-blue-700 mr-2">
      My spouse or former spouse
    </label>

  <div className="flex items-center ml-4">
   
    <input
      type="radio"
      id="gainedThroughParentSpouse"
      name="gainedThrough"
      value="parentSpouse"
      checked={formData.gainedThrough === 'parentSpouse'}
      onChange={handleInputChange}
    />
     <label htmlFor="gainedThroughParentSpouse" className="text-blue-700 mr-2">
      My parent's spouse or former spouse
    </label>
  </div>
  </div>
</div>

<div className="mt-10">
  <label className="bg-blue-700  text-white p-4 flex">
    Is this person currently serving with or employed by the U.S. government AND serving outside the United States?
  </label>
  <div className="flex flex-col bg-blue-100 p-4  rounded">
  <div>
    
    <input
      type="radio"
      id="isEmployedOutsideUSNo"
      name="isEmployedOutsideUS"
      value="No"
      checked={formData.isEmployedOutsideUS === 'No'}
      onChange={handleInputChange}
    />
    <label htmlFor="isEmployedOutsideUSNo" className="text-blue-700 mr-2">
      No
    </label>
  </div>
  <div className="flex items-center ">
    
    <input
      type="radio"
      id="isEmployedOutsideUSYesActiveMilitary"
      name="isEmployedOutsideUS"
      value="Yes_ActiveMilitary"
      checked={formData.isEmployedOutsideUS === 'Yes_ActiveMilitary'}
      onChange={handleInputChange}
    />
    <label htmlFor="isEmployedOutsideUSYesActiveMilitary" className="text-blue-700 mr-2">
      Yes - Active Military
    </label>
  </div>
  <div className="flex items-center ">
 
    <input
      type="radio"
      id="isEmployedOutsideUSYesGovernmentOrders"
      name="isEmployedOutsideUS"
      value="Yes_GovernmentOrders"
      checked={formData.isEmployedOutsideUS === 'Yes_GovernmentOrders'}
      onChange={handleInputChange}
    />
     <label htmlFor="isEmployedOutsideUSYesGovernmentOrders" className="text-blue-700 mr-2">
      Yes - Government Orders
    </label>
  </div>
 

    </div>
</div>



{formData.isEmployedOutsideUS === 'Yes_GovernmentOrders'
    &&
    <div className="mt-10">
  <label className="bg-blue-700 mr-4 text-white p-4 flex">Has this person ever served honorably in the U.S. armed forces?</label>
  <div className='flex  bg-blue-100 p-4 rounded'>
  <div className="">
    <label htmlFor="servedHonorablyNo" className="text-blue-700 mr-2">
      No
    </label>
    <input
      type="radio"
      id="servedHonorablyNo"
      name="servedHonorably"
      value="No"
      checked={formData.servedHonorably === 'No'}
      onChange={handleInputChange}
    />
  </div>
  <div className="flex items-center ml-4">
    <label htmlFor="servedHonorablyYes" className="text-blue-700 mr-2">
      Yes
    </label>
    <input
      type="radio"
      id="servedHonorablyYes"
      name="servedHonorably"
      value="Yes"
      checked={formData.servedHonorably === 'Yes'}
      onChange={handleInputChange}
    />
  </div>
  </div>
</div>
}

<hr className='bg-blue-600 mt-10  h-1'/>

  <div className='mt-10'>
    <h1 className='text-blue-600 font-semibold text-xl '>{relationshipInfo}</h1>
    <p className='font-sm'>Enter information about the petitioning spouse through whom you gained your conditional residence. Spell out middle names completely and do not use just the initial. If the spouse doesn't have a middle name, you may leave this field blank.</p>
  </div>



<div>
      {/* Spouse Given Name */}
      <div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="spouseGivenName" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Given Name (First Name):
  </label>
  <input
    type="text"
    id="spouseGivenName"
    name="spouseGivenName"
    className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.spouseGivenName}
    onChange={handleInputChange}
  />
</div>

      {/* Spouse Middle Name(s) */}
      <div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="spouseMiddleName" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Middle Name(s):
  </label>
  <input
    type="text"
    id="spouseMiddleName"
    name="spouseMiddleName"
    className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.spouseMiddleName}
    onChange={handleInputChange}
  />
</div>

      {/* Spouse Family Name (Last Name) */}
      <div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="spouseFamilyName" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Family Name (Last Name):
  </label>
  <input
    type="text"
    id="spouseFamilyName"
    name="spouseFamilyName"
    className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.spouseFamilyName}
    onChange={handleInputChange}
  />
</div>
      {/* Spouse Date of Birth */}
      <div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center ">
  <label htmlFor="spouseDateOfBirth" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Date of Birth:
  </label>
  <input
    type="date"
    id="spouseDateOfBirth"
    name="spouseDateOfBirth"
    className="w-full md:w-[300px] border text-blue-600 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.spouseDateOfBirth}
    onChange={handleInputChange}
  />
</div>

      {/* Spouse Alien Registration Number (A-Number) */}
      <div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="spouseANumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Alien Registration Number (A-Number):
  </label>
  <input
    type="number"
    id="spouseANumber"
    name="spouseANumber"
    className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.spouseANumber}
    onChange={handleInputChange}
  />
</div>

      {/* Spouse Social Security Number */}
      <div className="mb-4 flex flex-col items-center mt-6 md:flex-row md:items-center">
  <label htmlFor="spouseANumber" className="text-blue-700 w-full md:w-[200px] mb-2 md:mb-0 text-center">
    Alien Registration Number (A-Number):
  </label>
  <input
    type="number"
    id="spouseANumber"
    name="spouseANumber"
    className="w-full md:w-[300px] border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:ml-2"
    value={formData.spouseANumber}
    onChange={handleInputChange}
  />
</div>
    </div>



<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default Halfway