import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"
const MoralCharacter3 = () => {
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/ApplicantsParticaption")
  }
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's General Eligibility and Inadmissibility (continued)
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 11 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            You must answer every below question honestly and accurately. If you
            believe a truthful answer could create a problem on the application
            or you are unsure of your answer, we encourage you to speak to an
            immigration attorney before filing this application.
          </span>
        </div>
        <form action='' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          {/* Group 8 */}

          <hr className='h-1 bg-blue-600 mt-10' />

          <h1 className='text-blue-600  text-xl font-semibold'>
            Group 8: Public Charge
          </h1>
          <span className='mt-2 font-thin'>
            Based on your application type, we've determined that you are likely
            subject to the public charge ground of inadmissibility under INA
            section 212(a)(4). The vast majority of applicants are subject to
            this rule.{" "}
            <b>Very few applicants are exempt from this requirement.</b>
          </span>

          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Are you subject to the public charge ground of inadmissibility?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='publicChargeInadmissibility'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.publicChargeInadmissibility === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='publicChargeInadmissibility'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.publicChargeInadmissibility === "No"}
                />
                No
              </label>
            </div>
          </div>

          {/* Group 9 */}
          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Group 9: Illegal Entries and Other Immigration Violations
          </h1>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER failed or refused to attend or to remain in
              attendance at any removal proceedings filed against you on or
              after April 1, 1997?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='removalProceedingsAttendance'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.removalProceedingsAttendance === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='removalProceedingsAttendance'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.removalProceedingsAttendance === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER submitted fraudulent or counterfeit documentation to
              any U.S. Government official to obtain or attempt to obtain any
              immigration benefit, including a visa or entry into the United
              States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='fraudulentDocumentation'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.fraudulentDocumentation === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='fraudulentDocumentation'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.fraudulentDocumentation === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER lied about, concealed, or misrepresented any
              information on an application or petition to obtain a visa, other
              documentation required for entry into the United States, admission
              to the United States, or any other kind of immigration benefit?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='misrepresentationOnApplication'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.misrepresentationOnApplication === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='misrepresentationOnApplication'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.misrepresentationOnApplication === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER falsely claimed to be a U.S. citizen (in writing or
              any other way)?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='falseClaimToUSCitizenship'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.falseClaimToUSCitizenship === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='falseClaimToUSCitizenship'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.falseClaimToUSCitizenship === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been a stowaway on a vessel or aircraft arriving in
              the United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='stowaway'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.stowaway === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='stowaway'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.stowaway === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER knowingly encouraged, induced, assisted, abetted, or
              aided any foreign national to enter or try to enter the United
              States illegally (alien smuggling)?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='alienSmuggling'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.alienSmuggling === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='alienSmuggling'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.alienSmuggling === "No"}
                />
                No
              </label>
            </div>
          </div>

          {formData.alienSmuggling === "No" ? (
            <>
              <div className='mt-10'>
                <label className='flex bg-blue-600 text-white p-4 rounded-md'>
                  Are you under a final order of civil penalty for violating INA
                  section 274C for use of fraudulent documents?
                </label>
                <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
                  <label>
                    <input
                      type='radio'
                      name='usedFraudulentDocs'
                      value='Yes'
                      onChange={handleInputChange}
                      checked={formData.usedFraudulentDocs === "Yes"}
                    />
                    Yes
                  </label>
                  <label>
                    <input
                      type='radio'
                      name='usedFraudulentDocs'
                      value='No'
                      onChange={handleInputChange}
                      checked={formData.usedFraudulentDocs === "No"}
                    />
                    No
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* Group 10 */}
          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Group 10: Removal, Unlawful Presence, or Illegal Reentry After
            Previous Immigration Violations
          </h1>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been excluded, deported, or removed from the United
              States or have you ever departed the United States on your own
              after having been ordered excluded, deported, or removed from the
              United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='excludedDeportedRemoved'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.excludedDeportedRemoved === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='excludedDeportedRemoved'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.excludedDeportedRemoved === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER entered the United States without being inspected
              and admitted or paroled?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='enteredWithoutInspection'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.enteredWithoutInspection === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='enteredWithoutInspection'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.enteredWithoutInspection === "No"}
                />
                No
              </label>
            </div>
          </div>

          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Since April 1, 1997, have you been unlawfully present in the United
            States:
          </h1>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              For more than 180 days but less than a year, and then departed the
              United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='stayedBetween180And365Days'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.stayedBetween180And365Days === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='stayedBetween180And365Days'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.stayedBetween180And365Days === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              For one year or more and then departed the United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='stayedOneYearOrMore'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.stayedOneYearOrMore === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='stayedOneYearOrMore'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.stayedOneYearOrMore === "No"}
                />
                No
              </label>
            </div>
          </div>

          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Since April 1, 1997, have you EVER reentered or attempted to reenter
            the United States without being inspected and admitted or paroled
            after:
          </h1>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Having been unlawfully present in the United States for more than
              one year in the aggregate?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='unlawfullyPresentMoreThanOneYear'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.unlawfullyPresentMoreThanOneYear === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='unlawfullyPresentMoreThanOneYear'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.unlawfullyPresentMoreThanOneYear === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Having been deported, excluded, or removed from the United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='deportedExcludedRemoved'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.deportedExcludedRemoved === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='deportedExcludedRemoved'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.deportedExcludedRemoved === "No"}
                />
                No
              </label>
            </div>
          </div>

          {/* Group 11 */}
          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Group 11: Miscellaneous Conduct
          </h1>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Do you plan to practice polygamy in the United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='practicePolygamy'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.practicePolygamy === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='practicePolygamy'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.practicePolygamy === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Are you accompanying another foreign national who requires your
              protection or guardianship but who is inadmissible after being
              certified by a medical officer as being helpless from sickness,
              physical or mental disability, or infancy, as described in INA
              section 232(c)?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='accompanyingInadmissible'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.accompanyingInadmissible === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='accompanyingInadmissible'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.accompanyingInadmissible === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER assisted in detaining, retaining, or withholding
              custody of a U.S. citizen child outside the United States from a
              U.S. citizen who has been granted custody of the child?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='assistedInDetainingChild'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.assistedInDetainingChild === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='assistedInDetainingChild'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.assistedInDetainingChild === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER voted in violation of any Federal, state, or local
              constitutional provision, statute, ordinance, or regulation in the
              United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='votedInViolation'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.votedInViolation === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='votedInViolation'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.votedInViolation === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER renounced U.S. citizenship to avoid being taxed by
              the United States?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='renouncedUSCitizenshipForTaxAvoidance'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={
                    formData.renouncedUSCitizenshipForTaxAvoidance === "Yes"
                  }
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='renouncedUSCitizenshipForTaxAvoidance'
                  value='No'
                  onChange={handleInputChange}
                  checked={
                    formData.renouncedUSCitizenshipForTaxAvoidance === "No"
                  }
                />
                No
              </label>
            </div>
          </div>

          {/* Have you ever */}
          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='text-blue-600  text-xl font-semibold'>
            Have you Ever
          </h1>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Applied for exemption or discharge from training or service in the
              U.S. armed forces or in the U.S. National Security Training Corps
              on the ground that you are a foreign national?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='appliedForExemptionFromService'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.appliedForExemptionFromService === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='appliedForExemptionFromService'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.appliedForExemptionFromService === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Been relieved or discharged from such training or service on the
              ground that you are a foreign national?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='relievedOrDischargedFromService'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.relievedOrDischargedFromService === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='relievedOrDischargedFromService'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.relievedOrDischargedFromService === "No"}
                />
                No
              </label>
            </div>
          </div>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Been convicted of desertion from the U.S. armed forces?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='convictedOfDesertion'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.convictedOfDesertion === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='convictedOfDesertion'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.convictedOfDesertion === "No"}
                />
                No
              </label>
            </div>
          </div>

          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER left or remained outside the United States to avoid
              or evade training or service in the U.S. armed forces in time of
              war or a period declared by the President to be a national
              emergency?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='leftOrRemainedOutsideUs'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.leftOrRemainedOutsideUs === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='leftOrRemainedOutsideUs'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.leftOrRemainedOutsideUs === "No"}
                />
                No
              </label>
            </div>
          </div>

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default MoralCharacter3
