

import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';

const PrincipleImmigrantInfo = () => {
    const { formData, handleInputChange ,code } = useFormContext();

     console.log(formData.principalImmigrantGivenName)
     console.log(formData.principalImmigrantapartment)
      
     const navigate =useNavigate()
      

    const HandleSubmit =(e)=>{
         e.preventDefault()
         navigate("/otherMember")
    }
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

     <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
       <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
         <span className='mt-4 font-bold text-xl ml-2 text-black'>
         Address Information
         </span>
         <div className='flex justify-between items-center gap-4'>
           <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
             <button className='mr-2'>Page 1 of 3</button>
             <button><MdArrowDropDown /></button>
           </div>
           <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
             <AiOutlineShoppingCart />
           </div>
         </div>
       </div>
       <span className='mt-2 font-thin ml-2'>
       First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
       </span>
     </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
       
       <div className='flex flex-col'>
         <h1 className='text-blue-600 font-semibold text-xl'>Principal Immigrant's Full Name</h1>
        <p className='text-sm text-gray-900'>Provide the full legal name. Spell out middle names completely and do not use just the initial. If the intending immigrant does not have a middle name, you may leave this field blank. Explain more </p>
       </div>
     <div className='flex flex-col md:flex-row mt-10 gap-2'>
     <label htmlFor="principalImmigrantGivenName" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
  Given Name (First Name):
</label>
      <input
        className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
        type="text"
        id="principalImmigrantGivenName"
        name="principalImmigrantGivenName"
        value={formData.principalImmigrantGivenName}
        onChange={handleInputChange}
      />
    </div>

    <div className='flex flex-col md:flex-row mt-10 gap-2'>
      <label htmlFor="principalImmigrantMiddleName" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Middle Name(s):</label>
      <input
        className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
        type="text"
        id="principalImmigrantMiddleName"
        name="principalImmigrantMiddleName"
        value={formData.principalImmigrantMiddleName}
        onChange={handleInputChange}
      />
    </div>


    <div className='flex flex-col md:flex-row mt-10 gap-2'>
      <label htmlFor="principalImmigrantFamilyName" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">Family Name (Last Name):</label>
      <input
        className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
        type="text"
        id="principalImmigrantFamilyName"
        name="principalImmigrantFamilyName"
        value={formData.principalImmigrantFamilyName}
        onChange={handleInputChange}
      />
    </div>

    <hr className='h-1 mt-10 bg-blue-600 w-full'/>

    <div  className='flex flex-col md:flex-row mt-10 gap-2'>
      <label htmlFor="principalImmigrantInCareOfName" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">In Care of Name (if any):</label>
      <input
       className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
        type="text"
        id="principalImmigrantInCareOfName"
        name="principalImmigrantInCareOfName"
        value={formData.principalImmigrantInCareOfName}
        onChange={handleInputChange}
      />

      </div>

      <div className='flex flex-col md:flex-row mt-10 gap-2'>
  <label htmlFor="principalImmigrantStreet" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
    Street Number and Name:
  </label>
  <input
     className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
    type="text"
    id="principalImmigrantStreet"
    name="principalImmigrantStreet"
    value={formData.principalImmigrantStreet}
    onChange={handleInputChange}
  />
</div>
         
         
         
<div className='flex flex-col md:flex-row mt-10 gap-2'>
  <label htmlFor="principalImmigrantCity" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
    City or Town:
  </label>
  <input
    className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
    type="text"
    id="principalImmigrantCity"
    name="principalImmigrantCity"
    value={formData.principalImmigrantCity}
    onChange={handleInputChange}
  />
</div>

{/* Country */}
<div className='flex flex-col md:flex-row mt-10 gap-2'>
  <label htmlFor="country" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
    Country:
  </label>
  <select
    className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
    id="country"
    name="country"
    value={formData.principalImmigrantCountry}
    onChange={handleInputChange}
  >
    <option value="">Select a Country</option>
    {code.map((countryName) => (
      <option key={countryName} value={countryName}>
        {countryName}
      </option>
    ))}
  </select>
</div>

{/* Apartment, Suite, or Floor */}
<div className='flex flex-col md:flex-row mt-10 gap-2'>
  <label htmlFor="apartment" className="text-blue-500">
    Is there an apartment, suite, or floor?
  </label>
  <select
    className="w-full md:w-[200px] border outline-none text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-6"
    id="apartment"
    name="apartment"
    value={formData.principalImmigrantapartment}
    onChange={handleInputChange}
  >
    <option value="No">No</option>
    <option value="Apt">Apt</option>
    <option value="Ste">Ste</option>
    <option value="Flr">Flr</option>
  </select>
</div>

{/* Enter the number if there is an apartment */}
{formData.principalImmigrantapartment !== "No" && (
  <div className='flex flex-col md:flex-row mt-10 gap-2'>
    <label htmlFor="phoneNumber" className="w-full md:w-[200px] text-center md:text-left items-center text-blue-500">
      Enter the number here:
    </label>
    <input
      className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
      type="number"  // Use type="number" for numeric input
      id="phoneNumber"
      name="phoneNumber"
      value={formData.phoneNumber}
      onChange={handleInputChange}
    />
  </div>
)}
    
    
    
    


<hr className='bg-blue-700 mt-10 h-1 full'/>

<div>
 <h1 className='text-blue-500 font-semibold text-sl'>Principal Immigrant's Citizenship or Nationality</h1>
 <p>Select the name of the country where the intending immigrant is currently a citizen or national. If he/she is stateless, select the name of the country where he/she was last a citizen or national. If the intending immigrant is a citizen or national of more than one country, select the name of the country that issued his/her last passport.</p>

</div>
    

      <div  className='flex flex-col mt-10'>
      <label htmlFor="country"  className=" bg-blue-500  flex p-4 text-white ">Country of CitizenShip</label>
      <div className='flex bg-blue-100 p-4'>
      <select
        className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
        id="country"
        name="country"
        value={formData.countryOfCitizenship}
        onChange={handleInputChange}
      >
        <option value="">Select a Country</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
      </div>
      </div>


      <div className='flex flex-col mt-10'>
      <label htmlFor="dateOfBirth" className=" bg-blue-500  flex p-4 text-white ">
        Date of Birth:
      </label>
      <div className='flex bg-blue-100 p-4'>
      <input
         className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-2 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-2"
        type="date"
        id="dateOfBirth"
        name="dateOfBirth"
        value={formData.dateOfBirth}
        onChange={handleInputChange}
       
      />
      </div>
    </div>

    <hr className='h-1 bg-blue-600  mt-10' />

    <div>
        <h1 className='text-blue-700 font-semibold text-xl'>Other Information</h1>
    </div>
  

    <div className='flex flex-col mt-10'>
      <p className=" bg-blue-500  flex p-4 text-white ">Will this person immigrate through consular processing or adjustment of status?</p>
      
      <div className='flex flex-col md:flex-row bg-blue-100 p-4 text-blue-700 gap-5 md:gap-10'>
      <label >
        <input
          type="radio"
          name="immigrateMethod"
          value="Consular Processing"
          checked={formData.immigrateMethod === 'Consular Processing'}
          onChange={handleInputChange}
        />
        Consular Processing
      </label>
      <label>
        <input
          type="radio"
          name="immigrateMethod"
          value="Adjustment of Status"
          checked={formData.immigrateMethod === 'Adjustment of Status'}
          onChange={handleInputChange}
        />
        Adjustment of Status
      </label>
      </div>
    </div>
    <p className='bg-slate-200 p-2'>Indicate which path the intending immigrant will apply for permanent resident status. Explain more</p>
 

    
    <div className='flex flex-col mt-10'>
      <label htmlFor="countrySelect" className=" bg-blue-500  flex p-4 text-white " >Day time phone number</label>
      <div  className='flex bg-blue-100 p-4 text-blue-700'>
      <select
        id="countrySelect"
        value={formData.otherInformationdaytimePhoneNumber}
        onChange={handleInputChange}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={formData.PHnumber}
        onChange={handleInputChange}
        type="tel"
        placeholder="123-456-7890" 
        className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
      </div>
    </div>
    <p className='bg-slate-200 p-2'>Select the name of the country where you were born (even if it no longer exists).</p>
     
    
    <div>
      <div className='flex flex-col mt-10'>
        <label htmlFor="otherInformationAlienRegistrationNumber" className=" bg-blue-500  flex p-4 text-white ">Alien Registration Number (A-Number)</label>
         <div className='flex bg-blue-100 p-4 text-blue-700'>
        <input
          className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
          type="text"
          id="otherInformationAlienRegistrationNumber"
          name="otherInformationAlienRegistrationNumber"
          value={formData.otherInformationAlienRegistrationNumber}
          onChange={handleInputChange}
        />
        </div>
      </div>
      <p className='bg-slate-200 p-2'>If the intending immigrant does not have an A-Number, leave this field empty.</p>

      <div className='flex flex-col mt-10'>
        <label htmlFor="otherInformationSocialSecurityNumber" className=" bg-blue-500  flex p-4 text-white ">Social Security Number</label>
        <div className='flex bg-blue-100 p-4 text-blue-700'>
        <input
          className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
          type="text"
          id="otherInformationSocialSecurityNumber"
          name="otherInformationSocialSecurityNumber"
          value={formData.otherInformationSocialSecurityNumber}
          onChange={handleInputChange}
        />
        </div>
      </div>
      <p className='bg-slate-200 p-2'>If the intending immigrant does not have a Social Security Number, leave this field empty..</p>

      <div className='flex flex-col mt-10'>
        <label htmlFor="otherInformationUscisOnlineAccountNumber" className=" bg-blue-500  flex p-4 text-white ">USCIS Online Account Number</label>
        <div className='flex bg-blue-100 p-4 text-blue-700'>
        <input
          className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
          type="text"
          id="otherInformationUscisOnlineAccountNumber"
          name="otherInformationUscisOnlineAccountNumber"
          value={formData.otherInformationUscisOnlineAccountNumber}
          onChange={handleInputChange}
        />
        </div>
      </div>
      <p className='bg-slate-200 p-2'>If the intending immigrant does not have a USCIS online account number or does not know, leave this field empty.</p>
    </div>


    <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>


    
   </form>
   <FormFotter />

     </div>
     </div>
 
 )
}

export default PrincipleImmigrantInfo
