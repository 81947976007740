


import React from 'react'
import { useFormContext } from '../../UseContext/ContextN565';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';


 const LastPage = () => {
    const { formData, handleInputChange ,code ,handleSubmitN565 } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
    }

  
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            

     <div className='mt-4'>
  <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
    Who prepared this application?
  </label>
  <div className='bg-blue-100 p-4 rounded flex flex-col gap-2'>
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='applicationPreparer'
        value='I am the applicant, and I prepared this application'
        checked={formData.applicationPreparer === 'I am the applicant, and I prepared this application'}
        onChange={handleInputChange}
      />
      I am the applicant, and I prepared this application
    </label>
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='applicationPreparer'
        value="I am NOT an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent."
        checked={formData.applicationPreparer === "I am NOT an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent."}
        onChange={handleInputChange}
      />
      I am NOT an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent.
    </label>
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='applicationPreparer'
        value='I am an attorney or accredited representative of the applicant that prepared this application.'
        checked={formData.applicationPreparer === 'I am an attorney or accredited representative of the applicant that prepared this application.'}
        onChange={handleInputChange}
      />
      I am an attorney or accredited representative of the applicant that prepared this application.
    </label>
  </div>
</div>

<div className='mt-10'>
  <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
    My representation of the applicant in this case:
  </label>
  <div className='flex flex-col gap-2 p-4 bg-blue-100 rounded'>
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='representationExtends'
        value='Extends beyond the preparation of this application'
        checked={formData.representationExtends === 'Extends beyond the preparation of this application'}
        onChange={handleInputChange}
      />
      Extends beyond the preparation of this application
    </label>
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='representationExtends'
        value='Does not extend beyond the preparation of this application'
        checked={formData.representationExtends === 'Does not extend beyond the preparation of this application'}
        onChange={handleInputChange}
      />
      Does not extend beyond the preparation of this application
    </label>
  </div>
</div>
     

       

<div>
  <div className='flex mt-10 gap-2'>
    <label htmlFor="preparersGivenName" className="w-[200px] text-blue-500">
      Preparer’s Given Name (First Name)
    </label>
    <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="preparersGivenName"
      name="preparersGivenName"
      value={formData.preparersGivenName}
      onChange={handleInputChange}
    />
  </div>
  <div className='flex mt-10 gap-2'>
    <label htmlFor="preparersFamilyName" className="w-[200px] text-blue-500">
      Preparer’s Family Name (Last Name)
    </label>
    <input
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="preparersFamilyName"
      name="preparersFamilyName"
      value={formData.preparersFamilyName}
      onChange={handleInputChange}
    />
  </div>
  <div className='flex mt-10 gap-2'>
    <label htmlFor="preparersFirmOrOrganizationName" className="w-[200px] text-blue-500">
      Preparer’s Firm or Organization Name
    </label>
    <input
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="preparersFirmOrOrganizationName"
      name="preparersFirmOrOrganizationName"
      value={formData.preparersFirmOrOrganizationName}
      onChange={handleInputChange}
    />
  </div>
  <div className='flex mt-10 gap-2'>
    <label htmlFor="stateBarNumber" className="w-[200px] text-blue-500">State Bar Number</label>
    <input
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="stateBarNumber"
      name="stateBarNumber"
      value={formData.stateBarNumber}
      onChange={handleInputChange}
    />
  </div>
  <div className='flex mt-10 gap-2'>
    <label htmlFor="uscisOnlineAccountNumber" className="w-[200px] text-blue-500">USCIS Online Account Number</label>
    <input
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="uscisOnlineAccountNumber"
      name="uscisOnlineAccountNumber"
      value={formData.uscisOnlineAccountNumber}
      onChange={handleInputChange}
    />
  </div>
</div>

<h1 className='font-semibold text-xl text-blue-700 mt-10'>Preparer’s Address
</h1>
   
<div>
  <div className='flex mt-10 gap-2'>
    <label htmlFor="preparersStreetNumberAndName" className="w-[200px] text-blue-500">
      Street Number and Name
    </label>
    <input
      className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="preparersStreetNumberAndName"
      name="preparersStreetNumberAndName"
      value={formData.preparersStreetNumberAndName}
      onChange={handleInputChange} 
    />
  </div>

  
  <div className='flex mt-10 gap-2'>
    <label htmlFor="preparersCityOrTown" className="w-[200px] text-blue-500">City or Town</label>
    <input
      className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="preparersCityOrTown"
      name="preparersCityOrTown"
      value={formData.preparersCityOrTown}
      onChange={handleInputChange}
    />
  </div>
           
  <div className='flex mt-10 gap-2'>
  <label htmlFor="apartmentSuiteFloor" className="w-[200px] text-blue-500">Is there an apartment, suite, or floor?</label>
  <select
    className="w-[80px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    id="apartmentSuiteFloor"
    name="apartmentSuiteFloor"
    value={formData.apartmentSuiteFloor}
    onChange={handleInputChange}
  >
    <option value="No">No</option>
    <option value="Ste">Ste</option>
    <option value="Flr">Flr</option>
    <option value="Apt">Apt</option>
  </select>
</div>

{formData.apartmentSuiteFloor !== 'No'
&&    
<div className='flex mt-10 gap-2'>
  <label htmlFor="enterNumber" className="w-[200px] text-blue-500">Enter the number here:</label>
  <input
    className="w-[80px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    id="enterNumber"
    name="enterNumber"
    value={formData.enterNumber}
    onChange={handleInputChange}
  />
</div>}


<div className='flex mt-10 gap-2'>
  <label htmlFor="stateOrTerritory" className="w-[200px] text-blue-500">State (or Territory)</label>
  <select
     className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    id="stateOrTerritory"
    name="stateOrTerritory"
    value={formData.preparersstateOrTerritory}
    onChange={handleInputChange}
  >
    <option value=''>Select a state or territory</option>
    {code.map((state, index) => (
      <option key={index} value={state}>
        {state}
      </option>
    ))}
  </select>
</div>

<div className='flex mt-10 gap-2'>
  <label htmlFor="country" className="w-[200px] text-blue-500">Country</label>
  <select
   className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    id="country"
    name="country"
    value={formData.preparerscountry}
    onChange={handleInputChange}
  >
    <option value=''>Select a country</option>
    {code.map((country, index) => (
      <option key={index} value={country}>
        {country}
      </option>
    ))}
  </select>
</div>
  















  <div className='flex mt-10 gap-2'>
    <label htmlFor="preparersZIPCode"  className="w-[200px] text-blue-500">Preparer’s ZIP Code</label>
    <input
       className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
      type="text"
      id="preparersZIPCode"
      name="preparersZIPCode"
      value={formData.preparersZIPCode}
      onChange={handleInputChange}
    />
  </div>
</div>
     
  

<div  className='flex mt-10 gap-2'>
  <label htmlFor="preparerDaytimePhoneNumber" className="w-[200px] text-blue-500">Preparer's Daytime Phone Number</label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="preparerDaytimePhoneNumber"
    name="preparerDaytimePhoneNumber"
    value={formData.preparerDaytimePhoneNumber}
    onChange={handleInputChange}
  />
</div>

<h1 className='text-blue-600 font-semibold text-sl'>Preparer's Contact Information</h1>
<div className='flex mt-10 gap-2'>
  <label htmlFor="preparerMobilePhoneNumber" className="w-[200px] text-blue-500">Preparer's Mobile Phone Number (if any)</label>
  <input
      className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="preparerMobilePhoneNumber"
    name="preparerMobilePhoneNumber"
    value={formData.preparerMobilePhoneNumber}
    onChange={handleInputChange}
  />
</div>
<div className='flex mt-10 gap-2'>
  <label htmlFor="preparerEmailAddress" className="w-[200px] text-blue-500">Preparer’s E-mail Address (if any)</label>
  <input
      className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="preparerEmailAddress"
    name="preparerEmailAddress"
    value={formData.preparerEmailAddress}
    onChange={handleInputChange}
  />
</div>

<hr  className='bg-blue-600 h-1 mt-10'/>

<div  className='flex mt-10  flex-col'>
  <label  className="bg-blue-600 text-white flex p-4 ">Did you receive help from an interpreter to translate this application?</label>
  <div className='flex bg-blue-100 p-4  gap-4 '>
    <label className='text-blue-700'>
      <input
        type="radio"
        name="receivedHelpFromInterpreter"
        value="Yes"
        checked={formData.receivedHelpFromInterpreter === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label className='text-blue-700'>
      <input
        type="radio"
        name="receivedHelpFromInterpreter"
        value="No"
        checked={formData.receivedHelpFromInterpreter === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
</div>

<div>
  <label className='text-blue-600 flex gap-2'>
    <input
      type="checkbox"
      name="understandEnglish"
      checked={formData.understandEnglish}
      onChange={handleInputChange}
    />
    I can read and understand English, and have read and understand every question and instruction on this application, as well as my answer to every question.
  </label>
</div>




  {/* Interpreter's Given Name (First Name) */}
  <div className="flex mt-10 gap-2">
        <label htmlFor="interpretersGivenName" className="w-[200px] text-blue-500">
          Interpreter's Given Name (First Name)
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          id="interpretersGivenName"
          name="interpretersGivenName"
          value={formData.interpretersGivenName}
          onChange={handleInputChange}
        />
      </div>

      {/* Interpreter's Family Name (Last Name) */}
      <div className="flex mt-10 gap-2">
        <label htmlFor="interpretersFamilyName" className="w-[200px] text-blue-500">
          Interpreter's Family Name (Last Name)
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          id="interpretersFamilyName"
          name="interpretersFamilyName"
          value={formData.interpretersFamilyName}
          onChange={handleInputChange}
        />
      </div>

      <div className="flex mt-10 gap-2">
        <label htmlFor="interpretersFirmOrOrganizationName" className="w-[200px] text-blue-500">
          Interpreter's Firm or Organization Name
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          id="interpretersFirmOrOrganizationName"
          name="interpretersFirmOrOrganizationName"
          value={formData.interpretersFirmOrOrganizationName}
          onChange={handleInputChange}
        />
      </div>

      {/* Interpreter's State Bar Number */}
      <div className="flex mt-10 gap-2">
        <label htmlFor="interpretersStateBarNumber" className="w-[200px] text-blue-500">
          Interpreter's State Bar Number
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          id="interpretersStateBarNumber"
          name="interpretersStateBarNumber"
          value={formData.interpretersStateBarNumber}
          onChange={handleInputChange}
        />
      </div>

      {/* Interpreter's USCIS Online Account Number */}
      <div className="flex mt-10 gap-2">
        <label htmlFor="interpretersUSCISOnlineAccountNumber" className="w-[200px] text-blue-500">
          Interpreter's USCIS Online Account Number
        </label>
        <input
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          type="text"
          id="interpretersUSCISOnlineAccountNumber"
          name="interpretersUSCISOnlineAccountNumber"
          value={formData.interpretersUSCISOnlineAccountNumber}
          onChange={handleInputChange}
        />
      </div>


 <h1 className='text-blue-600 font-semibold text-xl mt-10'>Interpreter’s Address</h1>

      <div className="flex mt-10 gap-2">
  <label htmlFor="interpretersStreetNumberAndName" className="w-[200px] text-blue-500">
    Interpreter's Street Number and Name
  </label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="interpretersStreetNumberAndName"
    name="interpretersStreetNumberAndName"
    value={formData.interpretersStreetNumberAndName}
    onChange={handleInputChange}
  />
</div>

{/* Interpreter's City or Town */}
<div className="flex mt-10 gap-2">
  <label htmlFor="interpretersCityOrTown" className="w-[200px] text-blue-500">
    Interpreter's City or Town
  </label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="interpretersCityOrTown"
    name="interpretersCityOrTown"
    value={formData.interpretersCityOrTown}
    onChange={handleInputChange}
  />
</div>

{/* Interpreter's Apartment/Suite/Floor */}
<div className="flex mt-10 gap-2">
  <label htmlFor="interpretersApartmentSuiteFloor" className="w-[200px] text-blue-500">
    Interpreter's Apartment/Suite/Floor
  </label>
  <select
    className="w-[80px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    id="interpretersApartmentSuiteFloor"
    name="interpretersApartmentSuiteFloor"
    value={formData.interpretersApartmentSuiteFloor}
    onChange={handleInputChange}
  >
    <option value="No">No</option>
    <option value="Ste">Ste</option>
    <option value="Flr">Flr</option>
    <option value="Apt">Apt</option>
  </select>
</div>

{/* Interpreter's Enter the number here */}
{formData.interpretersApartmentSuiteFloor !== 'No' && (
  <div className="flex mt-10 gap-2">
    <label htmlFor="interpretersEnterNumber" className="w-[200px] text-blue-500">
      Interpreter's Enter the number here
    </label>
    <input
      className="w-[80px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
      type="text"
      id="interpretersEnterNumber"
      name="interpretersEnterNumber"
      value={formData.interpretersEnterNumber}
      onChange={handleInputChange}
    />
  </div>
)}

{/* Interpreter's ZIP Code */}
<div className="flex mt-10 gap-2">
  <label htmlFor="interpretersZIPCode" className="w-[200px] text-blue-500">
    Interpreter's ZIP Code
  </label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="interpretersZIPCode"
    name="interpretersZIPCode"
    value={formData.interpretersZIPCode}
    onChange={handleInputChange}
  />
</div>


<h1 className='font-semibold text-xl text-blue-600 mt-10'>Interpreter's Contact Information
</h1>

  {/* Interpreter's Daytime Phone Number */}
<div className="flex mt-10 gap-2">
  <label htmlFor="interpretersDaytimePhoneNumber" className="w-[200px] text-blue-500">
    Interpreter's Daytime Phone Number
  </label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="interpretersDaytimePhoneNumber"
    name="interpretersDaytimePhoneNumber"
    value={formData.interpretersDaytimePhoneNumber}
    onChange={handleInputChange}
  />
</div>

{/* Interpreter's Mobile Phone Number (if any) */}
<div className="flex mt-10 gap-2">
  <label htmlFor="interpretersMobilePhoneNumber" className="w-[200px] text-blue-500">
    Interpreter's Mobile Phone Number (if any)
  </label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="interpretersMobilePhoneNumber"
    name="interpretersMobilePhoneNumber"
    value={formData.interpretersMobilePhoneNumber}
    onChange={handleInputChange}
  />
</div>

{/* Interpreter's Email Address */}
<div className="flex mt-10 gap-2">
  <label htmlFor="interpretersEmailAddress" className="w-[200px] text-blue-500">
    Interpreter's Email Address
  </label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
    type="text"
    id="interpretersEmailAddress"
    name="interpretersEmailAddress"
    value={formData.interpretersEmailAddress}
    onChange={handleInputChange}
  />
</div>

<div className="mt-10">
  <label htmlFor="acknowledgeStatement"  className='text-blue-600 flex gap-2'>
  <div className=''>
    <input
      type="checkbox"
      id="acknowledgeStatement"
      name="acknowledgeStatement"
      checked={formData.acknowledgeStatement}
      onChange={handleInputChange}
    />
    I acknowledge that each and every question and instruction on this form, as well as my answer to each question, has been read to me by the person named above in a language in which I am fluent. I have provided true and correct responses in the language indicated above.
  </div>
  </label>
</div>



  





<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
               onClick={handleSubmitN565}
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
 }
 
 export default LastPage