
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import FormFotter from '../../components/FormFotter';
import Header from '../../components/Header';
import { useState } from 'react';
import { useEffect } from 'react';


const DottedLineWithText = () => (
    <div className="flex items-center mt-4">
      <hr className="flex-1 border-t-2 border-dashed border-gray-200 mr-4" />
      <span className="text-gray-200 font-bold">Insert Another item</span>
      <hr className="flex-1 border-t-2 border-dashed border-gray-200 ml-4" />
    </div>
  );



const PetitionerMailingAddress = () => {
    const [locationData, setLocationData] = useState([]);
    const [nameSets, setNameSets] = useState([{ id: 1 }]);
    const [countries, setCountries] = useState([]);
 

    const handleAddAnother = () => {
        setNameSets((prevNameSets) => [...prevNameSets, { id: Date.now() }]);
      };
    
      const handleRemove = (id) => {
        setNameSets((prevNameSets) => prevNameSets.filter((nameSet) => nameSet.id !== id));
      };

    useEffect(() => {
        // Replace this URL with your actual API endpoint
        fetch('https://restcountries.com/v3.1/all')
          .then((response) => response.json())
          .then((data) => {
            // Map the 'name' property of each country to 'label' in locationData
            const mappedData = data.map((country) => ({
              label: country.name.common,
              value: country.name.common,
              id: country.cca2, // Assign a unique identifier if needed
            }));
            setLocationData(mappedData);
            
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []);



      useEffect(() => {
        // Replace this URL with your actual API endpoint
        fetch('https://restcountries.com/v3.1/all')
          .then((response) => response.json())
          .then((data) => {
            setCountries(data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      }, []); // Empty dependency array to run the effect once on component mount
    
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
 

 

      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>

          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>




<div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
  <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
    <span className='mt-4 font-bold text-xl ml-2 text-black '>
      First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
    </span>
    <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
      <button className='mr-2'>Page 1 of 3</button>
      <button><MdArrowDropDown /></button>
    </div>
  </div>
  <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
</div>

        <form action=" " className='mt-2 w-11/12  '>
        <h2 className='text-blue-500  font-bold text-xl font-sans mt-20'>Mailing Address for the Subject of Record</h2>
              
              <p className='font-thin'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
              
              
             
              
              
              <div className='flex flex-col gap-6 mt-10'>

              <div className='flex gap-10 item-center '>
  <label htmlFor="inCareOfForm" className='mt-2 text-blue-500'>
    In Care Of
    <span className="text-red-500 ml-1">*</span>
  </label>
  <input
    type="text"
    id="inCareOfForm"
    name="inCareOf"
    className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-28'
  />
</div>
                  <div className='flex gap-6  item-center  ' >
              <label htmlFor="streetform2" className='text-blue-500  mt-2 p-2'>Street Number and Name  <span className="text-red-500 ml-1">*</span></label>
              <input 
              
               type="text" id="streetform2" name="street" className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16  py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg '/>
               
              </div>


              <div className='flex gap-10  item-center '>
                <label htmlFor="cityform2" className='mt-2 text-blue-500 '>Is there an apartment,<br/> suite or floor?
                <span className="text-red-500 ml-1">*</span>
                </label>
                <select
              
                id="cityform2"
                name="city"
                className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-6 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-14'
              >
                <option value="no">No</option>
                <option value="Apt">Apt</option>
                <option value="Sle">Sle</option>
                <option value="Flr">Flr</option>
              </select>

              </div>

              {/* Conditionally render the input field based on the selected option */}

                      <div className='flex gap-10 item-center'>
                        <label htmlFor="numberform2" className='mt-2 text-blue-500'>Enter a number here</label>
                        <input
                          type="number"
                          id="numberform2"
                          name="state"
                         
                          className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-14'
                        />
                      </div>
                  
              <div className='flex gap-10 item-center'>
              <label htmlFor="stateform2" className='mt-2 text-blue-500 '>City or Town
              <span className="text-red-500 ml-1">*</span>
              </label>
              <input type="text" id="stateform2" name="state"   
            
              className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-24'/>
                                </div>

                                <div className='flex gap-12 item-center'>
                    <label htmlFor="provinceForm" className='mt-2 text-blue-500'>
                        Province
                        <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                        type="text"
                        id="provinceForm"
                        name="province"
                        className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-28'
                    />
                    </div>

              <div className='flex gap-10 item-center'>
              <label  htmlFor="zipform2" className='mt-2 text-blue-500'>PostalCode
              <span className="text-red-500 ml-1">*</span>
              </label>
              <input type="text" id="zipform2" name="zip" 
             
              className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-40'/>
              </div>

              <div className='flex gap-10 items-center'>
                <label htmlFor="stateTownfrom2" className='mt-2 text-blue-500'>Country
                <span className="text-red-500 ml-1">*</span>
                </label>
                
                <select id="stateTownform2" name="stateTown"
                 
                className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
                <option value="" disabled>Please Select</option>
                      {locationData.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      ))}

                </select>
              </div>



              </div>


                 


             
             


              <div className="p-4 bg-blue-500 rounded mt-20 " >
                          <span className='text-white'>Is your mailing address (listed above) the same as your current physical address?
                          <span className="text-red-500">*</span></span>
                        </div>
                        <div className='flex gap-10  p-5 bg-blue-50'>
                          <div className='flex gap-2'>
                            <input type="radio" id="yes" name="answer" value="yes"  />
                            <label htmlFor="yes">Yes</label>
                          </div>
                          <div className='flex gap-2'>
                            <input type="radio" id="no" name="answer" value="no" />
                            <label htmlFor="no">No</label>
                          </div>
                        </div>
                        <div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                <span className='text-gray-500'>Your physical address is the residence where you live.</span>
              </div>

              <hr className='bg-blue-700 h-1 w-full mt-20'/>                

                                    <div className="p-4 bg-blue-500 rounded mt-20">
                        <label htmlFor="startDate" className="text-white">
                            Date you began living at the address above:
                            <span className="text-red-500">*</span>
                        </label>
                        </div>
                        <div className="flex gap-10 p-5 bg-blue-50">
                        <input type="date" id="startDate" name="startDate" className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg" />
                        </div>
              <hr className='bg-blue-700 h-1 w-full mt-20'/>

              <h2 className='text-blue-500  font-bold text-xl font-sans mt-2'>Countries of Residence</h2>
              <p className='font-[100]'>
  Provide all countries and U.S. states you have resided since your 18th birthday.
  You may list them in any order. Temporary visits do not count as a residence.
  Only list locations where you considered it the place where you lived.
</p>

<div className="mt-20">
      {nameSets.map((nameSet, index) => (
        <>
         <DottedLineWithText/>
          <div key={nameSet.id} className=' bg-slate-50 rounded border border-blue-200'>
            {index > 0 && (
              <div className='flex w-full justify-between ml-2'>
                <span className="text-gray-500">{`Add Other ${index}`}</span>
                <button
                  type="button"
                  onClick={() => handleRemove(nameSet.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4"
                >
                  Remove
                </button>
              </div>
            )}

            {/* Select Option for Countries */}
            <div className="mb-4 flex mt-6 ml-2 items-center">
              <label htmlFor={`country-${nameSet.id}`} className="text-blue-700">
                Country: <span className="text-red-500">*</span>
              </label>
              <select
                id={`country-${nameSet.id}`}
                name={`country-${nameSet.id}`}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
              >
                {/* Map through the fetched countries */}
                {countries.map((country) => (
                  <option key={country.cca3} value={country.name.common}>
                    {country.name.common}
                  </option>
                ))}
              </select>
            </div>

            {/* Additional Input Fields */}
            {/* ... (Given Name, Middle Name, Last Name, Marriage Date, etc.) */}
          </div>
        </>
      ))}

      {nameSets.length === 0 && (
        <div>
          <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
          <span className="text-gray-500">Add Other</span>
        </div>
      )}

      <button
        type="button"
        onClick={handleAddAnother}
        className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
      >
        Add Another Name
      </button>
    </div>
              
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default PetitionerMailingAddress
