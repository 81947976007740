

import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/Context131';


const ApplicatInfromation131 = () => {
    const { formData, handleInputChange ,code} = useFormContext();
    const navigate=useNavigate("/endpage")

    const options = ['No', 'Apt', 'Ste', 'Flr'];

    const handleSubmit = (e) => {
      e.preventDefault();

      navigate("/endpage131")
    };
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>

            <div>
              <h1 className='text-blue-600  font-semibold text-xl'>Applicant's Full Name</h1>
              <p className='text-sm'>Provide your current legal name. Spell out middle names completely. If you do not have a middle name, you may leave this field empty.</p>

            </div>
<div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">Given Name (First Name)</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="firstName"
    value={formData.firstName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-18 mt-10'>
  <label className="w-[200px]  text-blue-500">Middle Name</label>
  <input
 className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-14"
    type="text"
    name="middleName"
    value={formData.middleName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'> 
  <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="lastName"
    value={formData.lastName}
    onChange={handleInputChange}
  />
</div>

<hr className='bg-blue-700 h-1 mt-10' />

<div className='mt-10'>
    <h1 className='text-blue-700 font-semibold text-xl'>U.S. Mailing Address</h1>
    <p className='text-sm'>You may list a valid residence, APO, C/O, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">In Care of Name</label>
  <input
     className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="inCareOfName"
    value={formData.inCareOfName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">Street Number and Name</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="streetName"
    value={formData.streetName}
    onChange={handleInputChange}
  />
</div>
  


<div className='flex gap-10 mt-10'>
  <label htmlFor="selectedOption" className="w-[200px]  text-blue-500">Select an Option:</label>
  <select
      className="w-[100px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    id="selectedOption" // Change this to match the name attribute
    name="selectedOption" // Add the name attribute
    value={formData.selectedOption}
    onChange={handleInputChange}
  >
    {options.map((option) => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
</div>



{formData.selectedOption !== "No"
      &&
    <div className='flex gap-10 mt-10'>
      <label htmlFor="enteredNumber"  className="w-[200px]  text-blue-500">Enter the number here:</label>
      <input
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        type="number"
        id="enteredNumber"
        name="enteredNumber"
        value={formData.enteredNumber}
        onChange={handleInputChange}
      />
    </div>}

  

<div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">City or Town</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="city"
    value={formData.city}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">ZIP Code (Number)</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="number"
    name="zipCode"
    value={formData.zipCode}
    onChange={handleInputChange}
  />
</div>

<hr className='bg-blue-600 h-1 mt-10' />
 
    <h1 className='text-blue-700 text-xl font-semibold'>other information</h1>

    <div>
      <div className='flex gap-36 mt-10'>
        <label className='text-blue-600'>Sex:</label>
        <div className='flex gap-5 text-blue-700'>
        <label>
          <input
            type="radio"
            name="sex"
            value="Male"
            checked={formData.sex === 'Male'}
            onChange={handleInputChange}
          />
          Male
        </label>
        <label>
          <input
            type="radio"
            name="sex"
            value="Female"
            checked={formData.sex === 'Female'}
            onChange={handleInputChange}
          />
          Female
        </label>
        </div>
      </div>
      <div className='flex gap-10 mt-10'> 
        <label className="w-[200px]  text-blue-500">Alien Registration Number:</label>
        <input
         className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
          type="text"
          name="alienRegistrationNumber"
          value={formData.alienRegistrationNumber}
          onChange={handleInputChange}
        />
      </div>
      <div className='flex gap-10 mt-10'>
        <label className="w-[200px]  text-blue-500">Social Security Number (if applicable):</label>
        <input
         className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
          type="text"
          name="socialSecurityNumber"
          value={formData.socialSecurityNumber}
          onChange={handleInputChange}
        />
      </div>
    </div>

    <div className='flex gap-10 mt-10'>
      <label htmlFor="CountryofCitizenship" className="w-[200px]  text-blue-500">
        Where is your country of domicile?
      </label>
    
      <select
        className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        id="CountryofCitizenship"
        name="CountryofCitizenship"
        value={formData.CountryofCitizenship}
        onChange={handleInputChange}
      >
        <option value="">Select your country of domicile</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
      
    </div>

    <div className='flex gap-10 mt-10'>
      <label htmlFor="CountryofBirth" className="w-[200px]  text-blue-500">
        Where is your country of domicile?
      </label>
    
      <select
          className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        id="CountryofBirth"
        name="CountryofBirth"
        value={formData.CountryofBirth}
        onChange={handleInputChange}
      >
        <option value="">Select your country of domicile</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
    
    </div>

<hr className='bg-blue-600 h-1 mt-10' />

<div className='mt-10'>
    <h1 className='text-xl text-blue-700 font-semibold'>Applicant Contact Information</h1>
    <p className='text-sm'>This contact information is so that USCIS can reach you. Your mobile phone number and/or email address will be used so that CitizenPath can create a complimentary Form G-1145 for you. By submitting G-1145 with Form I-131, USCIS will text and/or email when they have accepted the application for processing.</p>
</div>


<div className='flex mt-10'>
      <label htmlFor="countrySelect" className="w-[200px]  text-blue-500">Day time phone number</label>
      
      <select
        id="countrySelect"
        value={formData.otherInformationday}
        onChange={handleInputChange}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={formData.Pnumber}
        onChange={handleInputChange}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
    
    </div>


    <div className='flex  mt-10'>
      <label htmlFor="countrySelect" className="w-[200px]  text-blue-500">Day time phone number</label>
     
      <select
        id="countrySelect"
        value={formData.otherInformationdaytimePhoneNumber}
        onChange={handleInputChange}
        className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      >
        <option value="">Select a country</option>
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
         {country.code}  {country.name}

          </option>
        ))}
      </select>
      <input
        value={formData.PHnumber}
        onChange={handleInputChange}
        type="tel"
        placeholder="123-456-7890" 
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
      />
   
    </div>


    <div className='flex  mt-10'>
     
        <label className="w-[200px]  text-blue-500">Email Address (if any):</label>
        
        <input
           className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
          type="text"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleInputChange}
        />
  
    </div>




       
  
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
      </div>
    )
}

export default ApplicatInfromation131