
import React, { useEffect, useState } from 'react';
import { FaShoppingCart, FaSignOutAlt } from 'react-icons/fa';
import axiosRequest from '../../utils/axiosRequest';

import { toast } from 'react-toastify';
const Profiler = () => {
    const [formList, setFormList] = useState([]);
    const UserData = JSON.parse(localStorage.getItem('response'));
    console.log(UserData);
    if (!UserData) {
      window.location.href = 'http://localhost:3000/login'; // change it Uzair to login
    }
  
  
    const fetchFormList = async () => {
      try {
        const response = await axiosRequest.get(`getAllUserForms?userId=${UserData.userData._id}`);
        console.log(response);
  
        setFormList(response.data);
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    };
  
  
  console.log(UserData.userData.username)
  
  
     
  
    useEffect(() => {
  
  
      fetchFormList();
    }, []);
  
    const handleDownloadForm = async (form) => {
      console.log(form);
      try {
        await axiosRequest.get(`/downloadCompletedForm?formId=${form?._id}&formName=${form?.formName}`);
        // console.log(response);
        toast.success('Form downloaded successfully', { autoClose: 3000 });
      }
      catch (error) {
        console.log(error);
        toast.error('Error downloading form', { autoClose: 3000 });
      }
    }
  
  
    return (
      <div className="w-full">
        <div className="flex justify-between items-center mt-3 w-full mx-auto max-w-screen-xl">
          <div>
            <img src='https://forms.citizenpath.com/assets/img/Icon_Form_30.png' alt="" className="w-full sm:w-1/2 lg:w-full" />
          </div>
          <div className="flex flex-col items-start gap-1 text-blue-500 sm:mr-4">
            <h1 className="work">Workspace</h1>
            <h2 className="name">
              <b>Name:</b> {formList.length > 0 ? UserData.userData.username : 'N/A'}
            </h2>
            <h3 className="email">
              <b>Email:</b> {formList.length > 0 ? UserData.userData.email : 'N/A'}
            </h3>
          </div>
        </div>
  
        <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
          <div className="flex justify-between w-full items-center bg-blue-500 h-12" style={{ backgroundColor: '#0072bc' }}>
            <div className="h-full flex justify-end">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <ul className="Menu">
                <li className="myForm">My Forms</li>
                <li className="Account">Account</li>
                <li className="Support">Support</li>
              </ul>
            </div>
  
            <div className="h-full flex justify-end mt-7 pd-2 iconsprofile text-white">
              <FaShoppingCart className="iconsprofile1" /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <FaSignOutAlt className="iconsprofile2" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
  
          <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl">
            <br />
            <div className="showforms">
              {formList?.map((form, index) => (
                <div key={index} className="flex-container">
                  <div className="flex-2/5 p-4 colflex">
                    <div className="label">
                      <strong>Form</strong>
                    </div>
                    <div className="icon-and-message">
                      <span className="icon">
                        <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" className=" w-[32px] h-[32px]" />
                      </span>
                      <p className="message ml-2">
                        <span className="font-bold text-blue-700 text-lg fontn">{form.formName}</span>
                        <br />
                        <span className="text-blue-400 font-lighter">{form.email}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex-1/7 colflex">
                    <div className="label2">
                      <strong>Progress</strong>
                    </div>
                    <p
  
                    >Downloaded</p>
                  </div>
                  <div className="flex-3/7 column buttonss">
                    <button className="bg-blue-600 hover:bg-blue-800 text-white py-2 px-4 w-40 mr-8"
                      onClick={() => { handleDownloadForm(form) }}
                    >Download</button>
                    <br />
                    <br />
                    <button className="bg-white text-blue-600 border border-blue-500 hover:bg-blue-600 hover:text-white hover:border-blue-600 py-2 px-4 w-40 mr-8">
                      Edit
                    </button>
                  </div>
                </div>
              ))}
              <br />
              <br />
              <br />
              <button className="btn-primary">Download</button>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Profiler