




import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';

const FederalIncomeTax = () => {
    const { formData, handleInputChange ,code } = useFormContext();
    const navigate = useNavigate()

     const HandleSubmit =(e)=>{
          e.preventDefault()
          navigate("")
     }

     
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
    <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
        </span>
      </div>
      <form action=" " className='mt-14 w-11/12' onSubmit={HandleSubmit}>
      
      <h1 className='flex text-blue-600 '>
      Sponsor's Employment and Income
      </h1>
      <p>
      Enter your current, individual annual income that you are using to meet the requirements of this affidavit and indicate the total in the space provided. If you receive income from an employer and self-employment, you may list both. Please list the employer first. What counts as income
      </p>


      <div className='mt-10'>
      <p className='bg-blue-600 text-white p-4 rounded-md'>
        Have you filed a Federal income tax return for each of the three most recent tax years?
      </p>
      <div className='flex p-4 bg-blue-100 gap-2 rounded-md text-blue-600'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="filedIncomeTaxReturn"
            value="Yes"
            checked={formData.filedIncomeTaxReturn === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="filedIncomeTaxReturn"
            value="No"
            checked={formData.filedIncomeTaxReturn === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>


    <div className='mt-10'>
      <p className='bg-blue-600 text-white p-4 rounded-md'>
        Were you required to file a Federal income tax return for each of the three most recent tax years?
      </p>
      <div className='flex p-4 bg-blue-100 gap-2 rounded-md text-blue-600'>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="requiredToFileIncomeTaxReturn"
            value="Yes"
            checked={formData.requiredToFileIncomeTaxReturn === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="requiredToFileIncomeTaxReturn"
            value="No"
            checked={formData.requiredToFileIncomeTaxReturn === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        <label className='flex gap-2'>
          <input
            type="radio"
            name="requiredToFileIncomeTaxReturn"
            value="I don't know"
            checked={formData.requiredToFileIncomeTaxReturn === "I don't know"}
            onChange={handleInputChange}
          />
          I don't know
        </label>
      </div>
    </div>



    <div>
      {/* Most Recent Tax Year */}
      <div>
      <div className='mt-4'>
        <p className='bg-blue-600 text-white p-4 rounded-md'>Most Recent Tax Year</p>
        <div className='flex bg-blue-100 p-4'>
          <label className='flex gap-2'>
            Select the year:
            <select
              name="mostRecentTaxYear.year"
              value={formData.mostRecentTaxryear}
              onChange={handleInputChange}
            >
              <option value="">Select a Year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              {/* Add more options as needed */}
            </select>
          </label>
         
        </div>
      </div>
    </div>


    <label className='bg-blue-600 flex text-white p-4 rounded-md'>
            Income:
            </label>
            <div className='flex bg-blue-100 p-4'>
            <input
              type="text"
              name="mostRecentTaxYear.income"
              value={formData.mostRecentTaxryear}
              onChange={handleInputChange}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
            />
          </div>

      {/* 2nd Most Recent Tax Year */}
      <div className='mt-4'>
        <p className='bg-blue-600 flex mt-10 text-white p-4 rounded-md'>2nd Most Recent Tax Year</p>
        <div className='flex bg-blue-100 p-4'>
            <select
              name="secondMostRecentTaxYear.year"
              value={formData.secondmostRecentTaxryear}
              onChange={handleInputChange}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
            >
              <option value="">Select a Year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              {/* Add more options as needed */}
            </select>
         
         
        </div>
      </div>


      <label className='bg-blue-600 flex mt-10 text-white p-4 rounded-md'>
            Income:
        </label>
          <div className='flex bg-blue-100 p-4'>
            <input
              type="text"
              name="secondMostRecentTaxYear.income"
              value={formData.secondmostRecentTaxryear}
              onChange={handleInputChange}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
            />
        </div>
      {/* 3rd Most Recent Tax Year */}
      <div className='mt-4'>
        <p className='bg-blue-600 flex mt-10 text-white p-4 rounded-md'>third Most Recent Tax Year</p>
        <div className='flex bg-blue-100 p-4'>
            <select
              name="secondMostRecentTaxYear.year"
              value={formData.thirdmostRecentTaxryear}
              onChange={handleInputChange}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
            >
              <option value="">Select a Year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              {/* Add more options as needed */}
            </select>
         
         
        </div>
      </div>
    </div>

          <label className='bg-blue-600 flex mt-10 text-white p-4 rounded-md'>
            Income:
            </label>
            <div className='flex bg-blue-100 p-4'>
            <input
              type="text"
              name="thirdMostRecentTaxYear.income"
              value={formData.thirdmostRecentTaxryear}
              onChange={handleInputChange}
              className="w-full md:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded md:ml-6"
            />
            </div>
          

          <hr className='h-1 bg-blue-600 mt-10'/>

          <h1 className='flex text-blue-600 mt-10'>Evidence of Income Tax Return</h1>
          <p>When submitting this affidavit, you MUST attach a copy or transcript of your Federal income tax return for the most recent tax year only. If you believe additional returns may help you to establish your ability to maintain sufficient income, you may submit transcripts or photocopies of your Federal income tax returns for the three most recent years.</p>
















    <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>


     
    </form>
    <FormFotter />

      </div>
      </div>
  
  )
}

export default FederalIncomeTax