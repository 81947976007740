import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FormProvider } from "./UseContext/UseContext";
import { FormProviderFor861D } from './UseContext/ContextFor821D';
import { FormProviderForI30 } from './UseContext/ContextI30';
import { FormProviderFor864 } from './UseContext/Context864.js';
import { FormProviderForI31 } from './UseContext/Context131';
import { FormProviderForG639 } from './UseContext/ContextG639';
import { FormProviderForI31A } from './UseContext/Context131A';
import { BrowserRouter } from 'react-router-dom';
import { FormProviderFor864BForm } from './Pages/I-864/FormContext';
import { FormProviderForN565 } from './UseContext/ContextN565';
import { FormProviderFor134 } from './UseContext/Context134.js';
import { FormProviderFor90Form } from './Pages/I-90/Formcontext';
import { FormProviderFor751 } from './UseContext/Context751.js';
import { FormProviderForN400 } from './UseContext/ContextN400.js';
import  {FormProviderI129F }  from "./UseContext/UseContextForI-I29F.js"
import { FormProviderFor485 } from './UseContext/Context485.js';
import { PageThreeDataProvider } from './UseContext/useContextPageThree.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <PageThreeDataProvider>
    <FormProviderFor485>
      <FormProviderI129F>
         <FormProviderForN400>
         <FormProviderFor751>
           <FormProviderFor90Form>
             <FormProviderFor134>
              <FormProviderForN565>
                <FormProviderFor864BForm>
                <FormProviderForI31A>
                    <FormProviderForG639>
                        <FormProviderForI31>
                            <FormProviderForI30>
                                <FormProviderFor864>
                                    <FormProviderFor861D>
                                        <FormProvider>
                                            <App />
                                        </FormProvider>
                                    </FormProviderFor861D>
                                </FormProviderFor864>
                            </FormProviderForI30>
                        </FormProviderForI31>
                    </FormProviderForG639>
                </FormProviderForI31A>
                </FormProviderFor864BForm>
                </FormProviderForN565>
                </FormProviderFor134>
                </FormProviderFor90Form>
                </FormProviderFor751>
                </FormProviderForN400>
                </FormProviderI129F>
                </FormProviderFor485>
                </PageThreeDataProvider>
</React.StrictMode>

);
reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// import React from 'react';
// import ReactDOM from 'react-dom';
// import { FormProvider } from "./UseContext/UseContext";
// import Form3 from './Pages/I-90/I-90.jsx';
// // import Form3 from './Pages/form2/I-902nd.jsx'; 

// // Render only the 'Form3' component in the 'root' element
// ReactDOM.render(
//   <React.StrictMode>
//     {/* <FormProvider> */}
//       <Form3/>
//     {/* </FormProvider> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );
