




import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import FormFotter from '../../components/FormFotter';
import Header from '../../components/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from '../../UseContext/UseContextForI-I29F';

const BenificiaryFamilyI129F = () => {
    const { state, setFormState } = useFormContext();

    
    const [countries, setCountries] = useState([]);
    const [countriesNew, setNewCountries] = useState([]);
    const [countriesNew2, setNew2Countries] = useState([]);

  
    // Fetch countries from the API when the component mounts
    useEffect(() => {
      fetch('https://restcountries.com/v3.1/all')
        .then((response) => response.json())
        .then((data) => {
          setCountries(data);
          setNewCountries(data)
          setNew2Countries(data)
          
        })
        .catch((error) => {
          console.error('Error fetching countries:', error);
        });
    }, []);
    const handleParent1GivenNameChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT1_GIVEN_NAME', value);
      };
      
      const handleParent1MiddleNamesChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT1_MIDDLE_NAMES', value);
      };
      
      const handleParent1FamilyNameChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT1_FAMILY_NAME', value);
      };

      const handleParent1SexChange = (e) => {
        const selectedSex = e.target.value;
        setFormState('SET_PARENT1_SEX', selectedSex);
      };
      const handleParent1DateOfBirthChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT1_DATE_OF_BIRTH', value);
      };
      
      const handleParent1DoNotKnowDateOfBirthChange = (e) => {
        const value = e.target.checked;
        setFormState('SET_PARENT1_DO_NOT_KNOW_DATE_OF_BIRTH', value);
      };

      const handleParent2PlaceOfResidenceChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT2_PLACE_OF_RESIDENCE', value);
      };
    
      const handleIsParent2DeceasedChange = (e) => {
        const value = e.target.value;
        setFormState('SET_IS_PARENT2_DECEASED', value);
      };
    
      const handleParent2CityOrTownChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT2_CITY_OR_TOWN', value);
      };
    
      const handleParent2CountryChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT2_COUNTRY', value);
      };
      const handleFiancesMarriageStatusChange = (e) => {
        setFormState('SET_FIANCE_MARRIAGE_STATUS', e.target.value);
      };
    
      // Function to handle changes in beneficiary's children radio buttons
      const handleBeneficiaryHasChildrenChange = (e) => {
        setFormState('SET_BENEFICIARY_HAS_CHILDREN', e.target.value);
      };
      
      const handleParent1CountryOfBirthChange = (e) => {
        const value = e.target.value;
        setFormState('SET_PARENT1_COUNTRY_OF_BIRTH', value);
      };
    

    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
          <Header />
          <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
    
              <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
              <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
              <div className='h-full'>
                <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
                <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
              </div>
            </div>
    
    
    
    
    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
        <span className='mt-4 font-bold text-xl ml-2 text-black '>
          First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
        </span>
        <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
          <button className='mr-2'>Page 1 of 3</button>
          <button><MdArrowDropDown /></button>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
    </div>
    
            <form action=" " className='mt-2 w-11/12  '>
            <h2 className='text-blue-500  font-bold text-xl font-sans mt-20'>Current Employment</h2>
              <p className='font-thin'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
                  
                <div className='mt-10 flex gap-3 '>
                    <h2 className='text-blue-600 p-4 rounded-md w-[200px]'>
                        Given Name (First Name)
                    </h2>
                    <div className="p-4">
                        <input
                        type="text"
                        value={state.parent1GivenName}
                        onChange={handleParent1GivenNameChange}
                        placeholder="Given Name"
                        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
                        />
                    </div>
                    </div>

                    <div className='mt-2 flex gap-3 '>
                    <h2 className=' text-blue-600 p-4 rounded-md w-[200px]' >
                        Middle Name (s)
                    </h2>
                    <div className="text-blue-600 p-4">
                        <input
                        type="text"
                        value={state.parent1MiddleNames}
                        onChange={handleParent1MiddleNamesChange}
                        placeholder="Middle Names"
                        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
                        />
                    </div>
                    </div>

                    <div className='mt-2 flex gap-3'>
                    <h2 className='text-blue-600 p-4 rounded-md w-[200px]'>
                        Family Name (Last Name)
                    </h2>
                    <div className=" p-4">
                        <input
                        type="text"
                        value={state.parent1FamilyName}
                        onChange={handleParent1FamilyNameChange}
                        placeholder="Family Name"
                        className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
                        />
                    </div>
                    </div>



<h2 className=' text-blue-600 p-4 rounded-md  text-xl font-semibold'>
    Parent 1's Birth Information - Sex
  </h2>

 <div className='mt-10 flex gap-10 w-full '>
  <h1 className="text-blue-600 ">sex</h1>
  <div className="flex m-auto  align-center">
    <label className="mr-4">
      <input
        type="radio"
        name="parent1Sex"
        value="Male"
        checked={state.parent1BirthInfo.sex === 'Male'}
        onChange={handleParent1SexChange}
      /> Male
    </label>
    <label>
      <input
        type="radio"
        name="parent1Sex"
        value="Female"
        checked={state.parent1BirthInfo.sex === 'Female'}
        onChange={handleParent1SexChange}
      /> Female
    </label>
  </div>
</div>   
   

<div className='mt-10 flex '>
  <h2 className='text-blue-600 w-[300px]'>
    Parent 1's Date of Birth
  </h2>
  <div className="w-[300px]">
    <input
      type="text"
      value={state.parent1DateOfBirth}
      onChange={handleParent1DateOfBirthChange}
      placeholder="dd/mm/yyyy"
      className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '
    />
  </div>
</div>

<div className='mt-10'>
  <label className='text-blue-600 gap-4'>
  
    <input
      type="checkbox"
      checked={state.parent1DoNotKnowDateOfBirth}
      onChange={handleParent1DoNotKnowDateOfBirthChange}
      className='ml-2'
    />
      I do not know Parent 1's date of birth
  </label>
</div>

<div className='mt-10 flex '>
  <h2 className='text-blue-600 w-[300px]'>
   Country of Birth
  </h2>
  <div className="">
    <select
      value={state.parent1CountryOfBirth}
      onChange={handleParent1CountryOfBirthChange}
      className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
    >
      <option value="">Select a country</option>
      {countriesNew.map((country) => (
        <option key={country.cca2} value={country.name.common}>
          {country.name.common}
        </option>
      ))}
    </select>
  </div>
</div>



<div className="">
      <h2 className='text-blue-600 text-xl font-semibold'>Parent 2's Information</h2>
      <div className='flex'>
        <label className='text-blue-600 w-[300px] '>Place of Residence:</label>
        <input
          type="text"
          value={state.parent2PlaceOfResidence}
          onChange={handleParent2PlaceOfResidenceChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        />
      </div>
      <div className='flex  mt-10'>
        <label className='text-blue-600 w-[300px]'>Is this parent deceased?</label>
        <div className='flex ml-32 gap-5'>
          <label className='text-blue-600'>
            <input
              type="radio"
              name="isParent2Deceased"
              value="Yes"
              checked={state.isParent2Deceased === 'Yes'}
              onChange={handleIsParent2DeceasedChange}
            />
            Yes
          </label>
          <label className='text-blue-600'>
            <input
              type="radio"
              name="isParent2Deceased"
              value="No"
              checked={state.isParent2Deceased === 'No'}
              onChange={handleIsParent2DeceasedChange}
            />
            No
          </label>
        </div>
      </div>
      <div className='flex mt-10'>
        <label className='text-blue-600 w-[300px]'>City or Town:</label>
        <input
          type="text"
          value={state.parent2CityOrTown}
          onChange={handleParent2CityOrTownChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        />
      </div>
      <div className='flex mt-10'>
        <label className='text-blue-600 w-[300px]'>Country:</label>
        <select
          value={state.parent2Country}
          onChange={handleParent2CountryChange}
          className='border w-[300px] border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        >
          <option value="">Select a country</option>
          {countries.map((country) => (
            <option key={country.cca2} value={country.name.common}>
              {country.name.common}
            </option>
          ))}
        </select>
      </div>
    </div>
  

    <hr  className='h-1 bg-blue-600 mt-10' />
   <h1 className='flex text-blue-600 text-xl font-semibold mt-10'>Beneficiary's Marital Information</h1>
    

    
    <div className='mt-10 '>
      <h2 className='flex bg-blue-600  ronded-md text-white  p-4'>What is your fiancé's marriage status?</h2>
     <div className='bg-blue-100 p-4 rounded-md '>
      <div>
        <label className='text-blue-600'>
          <input
            type="radio"
            value="Single and never married"
            checked={state.fianceMarriageStatus === 'Single and never married'}
            onChange={handleFiancesMarriageStatusChange}
          />
          Single and never married
        </label>
      </div>
      <div>
        <label className='text-blue-600'>
          <input
            type="radio"
            value="Married"
            checked={state.fianceMarriageStatus === 'Married'}
            onChange={handleFiancesMarriageStatusChange}
          />
          Married
        </label>
      </div>
      <div>
        <label className='text-blue-600'>
          <input
            type="radio"
            value="Divorced"
            checked={state.fianceMarriageStatus === 'Divorced'}
            onChange={handleFiancesMarriageStatusChange}
          />
          Divorced
        </label>
      </div>
      <div>
        <label className='text-blue-600'>
          <input
            type="radio"
            value="Widowed"
            checked={state.fianceMarriageStatus === 'Widowed'}
            onChange={handleFiancesMarriageStatusChange}
          />
          Widowed
        </label>
        </div>
      </div>
      <hr  className='h-1 bg-blue-600 w-full  mt-10' />

      <h1 className='flex text-blue-600 text-xl font-semibold mt-10'>Beneficiary's Children</h1>

      <p className='font-sm text-slate-500'>A child is any biological, adopted, or step child who is unmarried and under 21 years of age. Your beneficiary's children may also apply for a K nonimmigrant visa through this same petition. You do not need to file a separate petition to classify the child as a K nonimmigrant. Include each child below even if they will not apply with the parent. Explain more</p>

      <h2 className='flex  text-white bg-blue-600 mt-10 p-4'>Does your beneficiary have any children?</h2>
      <div className='flex bg-blue-100 p-4 gap-4'>
  <div>
    <label>
      <input
        type="radio"
        value="Yes"
        checked={state.beneficiaryHasChildren === 'Yes'}
        onChange={handleBeneficiaryHasChildrenChange}
      />
      Yes
    </label>
  </div>
  <div>
    <label>
      <input
        type="radio"
        value="No"
        checked={state.beneficiaryHasChildren === 'No'}
        onChange={handleBeneficiaryHasChildrenChange}
      />
      No
    </label>
  </div>
</div>
    </div>

    
                     
    
    
                 
                 
    
    
          <button
            type="button"
            className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
          >
            Add Another Name
          </button>
  
                  
            </form>
            <FormFotter />
          </div>
        </div>
      )
}

export default BenificiaryFamilyI129F