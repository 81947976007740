import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';


const PublicDafetyInfo = () => {
    const navigate=useNavigate()

    const { formData, handleInputChange } = useFormContext();

    const handleSubmit =(e)=>{
        e.preventDefault()
        navigate("/ApplicantProfile")
    }

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
        <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
        <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
        <div className='h-full'>
          <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
          <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
        </div>
      </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        Provide your current mailing address and contact information.
        </span>
      </div>
      <form action=" " className='mt-14 w-11/12' onSubmit={handleSubmit}>
      <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Have you EVER been arrested for, charged with, or convicted of a felony or misdemeanor in the United States?
        </label>
        <div  className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="arrestedUS"
            value="Yes"
            checked={formData.arrestedUS === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="arrestedUS"
            value="No"
            checked={formData.arrestedUS === 'No'}
            onChange={handleInputChange}
          /> No
                    </div>

           { formData.arrestedUS === 'Yes' &&
           <>
           <div className='flex flex-col gap-2 bg-orange-200 p-2'>
                <div className='flex  gap-2'>
                    <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[40px] h-[40px]'/>
                    <p> <span className='font-bold'>  Your answer to this question suggests that you have a history which may require the assistance of a legal professional.</span>   We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney before continuing.</p>
                </div>
                <div className='flex gap-2'>
                    <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[40px] h-[40px]' />
                    <p>Your answer to this question suggests that you have a history which may require the assistance of a legal professional. We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney before continuing.</p>
                </div>
            </div>
            <div className='flex bg-green-200 p-4  gap-2  rounded-l-md ml-auto w-[650px]'>
            I acknowledge that I have spoken to an attorney and / or want to continue anyway
            <input
                type="checkbox"
                name="attorneyAcknowledgement"
                checked={formData.attorneyAcknowledgement}
                onChange={handleInputChange}
            /> 
            </div>
           

            {formData.attorneyAcknowledgement
            &&
            <div className='flex gap-2 bg-slate-200 mt-20 p-4'>
                 <img src="https://forms.citizenpath.com/assets/img/Icon_Info_30.png" alt="" className='w-[40px] h-[40px]'/>
                 <p>   <span className='font-bold'>
                For Your Information: </span> When filing your application, you must submit a statement on a separate sheet of paper that provides a full explanation and describes the circumstances of your answer. We encourage you to obtain the assistance of an immigration attorney when creating this statement.</p>
            </div>}
            </>
            }
                  
          
    

      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Have you EVER been arrested for, charged with, or convicted of a crime in any country other than the United States?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="arrestedOtherCountry"
            value="Yes"
            checked={formData.arrestedOtherCountry === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="arrestedOtherCountry"
            value="No"
            checked={formData.arrestedOtherCountry === 'No'}
            onChange={handleInputChange}
          /> No
        </div>
        {formData.arrestedOtherCountry === 'Yes'
            &&
            <>
            <div className='flex flex-col gap-2 bg-orange-200 p-2'>
                <div className='flex  gap-2'>
                    <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[40px] h-[40px]'/>
                    <p> <span className='font-bold'>  Your answer to this question suggests that you have a history which may require the assistance of a legal professional.</span>   We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney before continuing.</p>
                </div>
                <div className='flex gap-2'>
                    <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[40px] h-[40px]' />
                    <p>Your answer to this question suggests that you have a history which may require the assistance of a legal professional. We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney before continuing.</p>
                </div>
            </div>
            <div className='flex bg-green-200 p-4  gap-2  rounded-l-md ml-auto w-[650px]'>
            I acknowledge that I have spoken to an attorney and / or want to continue anyway
            <input
                type="checkbox"
                name="attorneyAcknowledgement"
                checked={formData.attorneyAcknowledgement}
                onChange={handleInputChange}
            /> 
            </div>
           

          { formData.attorneyAcknowledgement
          &&
          
          <div className='flex gap-2 bg-slate-200 mt-20 p-4'>
                 <img src="https://forms.citizenpath.com/assets/img/Icon_Info_30.png" alt="" className='w-[40px] h-[40px]'/>
                 <p>   <span className='font-bold'>
                For Your Information: </span> When filing your application, you must submit a statement on a separate sheet of paper that provides a full explanation and describes the circumstances of your answer. We encourage you to obtain the assistance of an immigration attorney when creating this statement.</p>
            </div>}
            </>
            }

   

      <label   className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Have you EVER engaged in or do you continue to engage in or plan to engage in terrorist activities?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="engagedInTerroristActivities"
            value="Yes"
            checked={formData.engagedInTerroristActivities === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="engagedInTerroristActivities"
            value="No"
            checked={formData.engagedInTerroristActivities === 'No'}
            onChange={handleInputChange}
          /> No
        </div>


      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Are you NOW or have you EVER been a member of a gang?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="memberOfAGang"
            value="Yes"
            checked={formData.memberOfAGang === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="memberOfAGang"
            value="No"
            checked={formData.memberOfAGang === 'No'}
            onChange={handleInputChange}
          /> No
        </div>


      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Acts involving torture, genocide, or human trafficking?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="actsInvolvingTortureGenocide"
            value="Yes"
            checked={formData.actsInvolvingTortureGenocide === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="actsInvolvingTortureGenocide"
            value="No"
            checked={formData.actsInvolvingTortureGenocide === 'No'}
            onChange={handleInputChange}
          /> No
        </div>


      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10' >
        Killing any person?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="killedAnyPerson"
            value="Yes"
            checked={formData.killedAnyPerson === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="killedAnyPerson"
            value="No"
            checked={formData.killedAnyPerson === 'No'}
            onChange={handleInputChange}
          /> No
        </div>
   

      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Severely injuring any person?
        </label>
        <div  className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="severelyInjuredAnyPerson"
            value="Yes"
            checked={formData.severelyInjuredAnyPerson === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="severelyInjuredAnyPerson"
            value="No"
            checked={formData.severelyInjuredAnyPerson === 'No'}
            onChange={handleInputChange}
          /> No
        </div>
   

      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Any kind of sexual contact or relations with any person who was being forced or threatened?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="sexualContactWithForcedOrThreatenedPerson"
            value="Yes"
            checked={formData.sexualContactWithForcedOrThreatenedPerson === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="sexualContactWithForcedOrThreatenedPerson"
            value="No"
            checked={formData.sexualContactWithForcedOrThreatenedPerson === 'No'}
            onChange={handleInputChange}
          /> No
        </div>


      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Have you EVER recruited, enlisted, conscripted, or used any person to serve in or help an armed force or group while such person was under age 15?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="usedUnderAge15InHostilities"
            value="Yes"
            checked={formData.usedUnderAge15InHostilities === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="usedUnderAge15InHostilities"
            value="No"
            checked={formData.usedUnderAge15InHostilities === 'No'}
            onChange={handleInputChange}
          /> No
        </div>
   
      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Have you EVER used any person under age 15 to take part in hostilities, or to help or provide services to people in combat?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="usedUnderAge15InHostilities"
            value="Yes"
            checked={formData.usedUnderAge15InHostilities === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="usedUnderAge15InHostilities"
            value="No"
            checked={formData.usedUnderAge15InHostilities === 'No'}
            onChange={handleInputChange}
          /> No
        </div>


      <label  className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
        Are you NOW or have you EVER been in removal proceedings, or do you have a removal order issued in any context (for example, at the border or within the United States by an immigration agent)?
        </label>
        <div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
          <input
            type="radio"
            name="removalProceedings"
            value="Yes"
            checked={formData.removalProceedings === 'Yes'}
            onChange={handleInputChange}
          /> Yes
          <input
            type="radio"
            name="removalProceedings"
            value="No"
            checked={formData.removalProceedings === 'No'}
            onChange={handleInputChange}
          /> No
        </div>



     <label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  What is your current status or outcome of your removal proceedings?
</label>
<div className='flex bg-blue-100 w-full rounded p-4 flex-col text-blue-600'>

    <div>
  <input
    type="radio"
    name="removalProceedingsStatus"
    value="Currently in proceedings (active)"
    checked={formData.removalProceedingsStatus === 'Currently in proceedings (active)'}
    onChange={handleInputChange}
  /> Currently in proceedings (active)
</div>
<div>
  <input
    type="radio"
    name="removalProceedingsStatus"
    value="Currently in proceedings (administratively closed)"
    checked={formData.removalProceedingsStatus === 'Currently in proceedings (administratively closed)'}
    onChange={handleInputChange}
  /> Currently in proceedings (administratively closed)
</div>

<div>
  <input
    type="radio"
    name="removalProceedingsStatus"
    value="Terminated"
    checked={formData.removalProceedingsStatus === 'Terminated'}
    onChange={handleInputChange}
  /> Terminated
</div>
<div>
  <input
    type="radio"
    name="removalProceedingsStatus"
    value="Subject to final order"
    checked={formData.removalProceedingsStatus === 'Subject to final order'}
    onChange={handleInputChange}
  /> Subject to final order (radio button)
</div>

<div>
  <input
    type="radio"
    name="removalProceedingsStatus"
    value="Other"
    checked={formData.removalProceedingsStatus === 'Other'}
    onChange={handleInputChange}
  /> Other
</div>


</div>
<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Most recent date of proceedings:
</label>
<div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
  <input
   className='w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
    type="date"
    name="mostRecentDateOfProceedings"
    value={formData.mostRecentDateOfProceedings}
    onChange={handleInputChange}
  />
</div>

<label className='flex bg-blue-600 w-full rounded p-4 text-white mt-10'>
  Location of proceedings:
</label>
<div className='flex bg-blue-100 p-4 w-full gap-2 text-blue-500'>
  <input
  className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
    type="text"
    name="locationOfProceedings"
    value={formData.locationOfProceedings}
    onChange={handleInputChange}
  />
</div>




<div className='flex  text-white mt-32 w-[100px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>


     
    </form>
    <FormFotter />

      </div>
      </div>
  )
}

export default PublicDafetyInfo
