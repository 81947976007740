import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from "../I-1765/Options"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const ApplicantInformation485 = () => {
  const navigate = useNavigate("/Applicantinfo130")
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()

  const handleSubmit = (e) => {
    e.preventDefault()

    navigate("/ApplicationImmigrationHistory")
  }

  console.log(formData.countryOfBirth)

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Address Information
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 1 of 3</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            First, let's determine your eligibility to prepare Form I-864,
            Affidavit of Support
          </span>
        </div>
        <form action=' ' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-5'>
            <div>
              <label className='w-[300px]  text-blue-600'>
                Name of Applicant:
              </label>
              <input
                type='text'
                name='nameOfApplicant'
                value={formData.nameOfApplicant}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10'
              />
            </div>
            <div>
              <label className='w-[300px]  text-blue-600'>
                Middle Name(s):
              </label>
              <input
                type='text'
                name='middleNames'
                value={formData.middleNames}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10'
              />
            </div>

            <div>
              <label className='w-[300px]  text-blue-600'>
                Family Name (Last Name):
              </label>
              <input
                type='text'
                name='familyName'
                value={formData.familyName}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10'
              />
            </div>
          </div>

          <hr className='flex w-full h-1 bg-blue-600' />

          <h1 className='text-blue-600 '>Other Name(s)</h1>
          <p>
            Provide any other names that you have ever used, including maiden
            names, names from a previous marriage, aliases or nicknames. Explain
            more
          </p>

          <div className='mt-10'>
            <label className='flex items-center text-white p-4 bg-blue-600 gap-2'>
              Have you ever used another name since birth (such as a maiden
              name)?
            </label>
            <div className='flex  gap-4 bg-blue-100 p-4'>
              <label className='flex items-center text-blue-600 gap-1'>
                <input
                  type='radio'
                  name='haveUsedAnotherName'
                  value='Yes'
                  checked={formData.haveUsedAnotherName === "Yes"}
                  onChange={handleInputChange}
                  className='form-radio text-blue-600'
                />{" "}
                Yes
              </label>
              <label className='flex items-center text-blue-600 gap-1'>
                <input
                  type='radio'
                  name='haveUsedAnotherName'
                  value='No'
                  checked={formData.haveUsedAnotherName === "No"}
                  onChange={handleInputChange}
                  className='form-radio text-blue-600'
                />{" "}
                No
              </label>
            </div>
          </div>

          <hr className='h-1 bg-blue-600 mt-10' />
          <h1 className='flex text-blue-600 mt-10'>
            Applicant's Contact Information
          </h1>
          <p className='font-semibold text-sm'>
            This contact information is so that USCIS can reach you. Your mobile
            phone number and/or email address will also be used so that
            CitizenPath can create a complimentary Form G-1145 for you. By
            filing G-1145 with your adjustment of status application, USCIS will
            text and/or email when they have accepted the application for
            processing.
          </p>

          <div className='flex flex-col gap-5 mt-10'>
            <div>
              <label
                htmlFor='daytimePhoneNumber'
                className='w-[300px]  text-blue-600'
              >
                Daytime Phone Number
              </label>
              <input
                type='text'
                id='daytimePhoneNumber'
                name='daytimePhoneNumber'
                value={formData.daytimePhoneNumber}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10'
              />
            </div>

            <div>
              <label
                htmlFor='mobilePhoneNumber'
                className='w-[300px]  text-blue-600'
              >
                Mobile Phone Number
              </label>
              <input
                type='text'
                id='mobilePhoneNumber'
                name='mobilePhoneNumber'
                value={formData.mobilePhoneNumber}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10'
              />
            </div>
            <div>
              <label
                htmlFor='email'
                className='border border-black text-blue-600'
              >
                E-mail
              </label>
              <input
                type='email'
                id='email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-40'
              />
            </div>
          </div>

          <hr className='flex h-1 bg-blue-600 mt-10' />

          <h1 className='flex gap-10 text-blue-600'>Birth Information</h1>

          <div className='mt-10'>
            <label
              htmlFor='dateOfBirth'
              className='flex text-white bg-blue-600 p-4 rounded-md'
            >
              Date of Birth
            </label>
            <div className='flex bg-blue-100 p-4'>
              <input
                type='date'
                id='dateOfBirth'
                name='dateOfBirth'
                value={formData.dateOfBirth}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
              />
            </div>
          </div>

          <div className='mt-10'>
            <label
              htmlFor='cityOfBirth'
              className='flex text-white bg-blue-600 p-4 rounded-md'
            >
              City of Birth
            </label>
            <div className='flex bg-blue-100 p-4'>
              <input
                type='text'
                id='cityOfBirth'
                name='cityOfBirth'
                value={formData.cityOfBirth}
                onChange={handleInputChange}
                className='w-[400px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
              />
            </div>
          </div>

          <div className='mt-10'>
            <label className='flex text-white bg-blue-600 p-4 rounded-md'>
              Have you ever used a different date of birth in connection with
              another name or alias?
            </label>
            <div className='flex bg-blue-100 p-4  text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='hasUsedDifferentDOB'
                  value='Yes'
                  checked={formData.hasUsedDifferentDOB === "Yes"}
                  onChange={handleInputChange}
                />{" "}
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='hasUsedDifferentDOB'
                  value='No'
                  checked={formData.hasUsedDifferentDOB === "No"}
                  onChange={handleInputChange}
                />{" "}
                No
              </label>
            </div>
          </div>

          <div className='flex flex-col mt-10'>
            <label
              htmlFor='countryOfBirth'
              className='flex text-white bg-blue-600 p-4 rounded-md'
            >
              Country of Birth
            </label>

            <div className='flex bg-blue-100 p-4'>
              <select
                id='countryOfBirth'
                name='countryOfBirth'
                value={formData.countryOfBirth}
                onChange={handleInputChange}
                className='w-[400px] text-black border outline-none border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
              >
                {code.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <hr className='flex h-1 bg-blue-600 mt-10' />

          <label className='flex bg-blue-600 mt-10 p-4 rounded-md text-white'>
            Ethnicity:
          </label>
          <div className='flex p-4 bg-blue-100  gap-5'>
            <div className='text-blue-600'>
              <input
                type='radio'
                id='hispanicOrLatino'
                name='ethnicity'
                value='HispanicOrLatino'
                checked={formData.ethnicity === "HispanicOrLatino"}
                onChange={handleInputChange}
              />
              <label htmlFor='hispanicOrLatino'>Hispanic or Latino</label>
            </div>
            <div className='text-blue-600'>
              <input
                type='radio'
                id='notHispanicOrLatino'
                name='ethnicity'
                value='NotHispanicOrLatino'
                checked={formData.ethnicity === "NotHispanicOrLatino"}
                onChange={handleInputChange}
              />

              <label htmlFor='notHispanicOrLatino'>
                Not Hispanic or Latino
              </label>
            </div>
          </div>

          <div className='mt-10'>
            <h1 className='flex bg-blue-600 text-white p-4 rounded-md'>
              Race (select all applicable boxes)
            </h1>
            <div className='flex bg-blue-100 rounded-md p-4  gap-3 text-blue-600'>
              <label>
                <input
                  type='checkbox'
                  name='raceWhite'
                  checked={formData.raceWhite}
                  onChange={handleInputChange}
                />
                White
              </label>

              <label>
                <input
                  type='checkbox'
                  name='raceBlack'
                  checked={formData.raceBlack}
                  onChange={handleInputChange}
                />
                Black or African American
              </label>

              <label>
                <input
                  type='checkbox'
                  name='raceNative'
                  checked={formData.raceNative}
                  onChange={handleInputChange}
                />
                American Indian or Alaska Native
              </label>

              <label>
                <input
                  type='checkbox'
                  name='racePacific'
                  checked={formData.racePacific}
                  onChange={handleInputChange}
                />
                Native Hawaiian or Other Pacific Islander
              </label>

              <label>
                <input
                  type='checkbox'
                  name='raceAsian'
                  checked={formData.raceAsian}
                  onChange={handleInputChange}
                />
                Asian
              </label>
            </div>
          </div>

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default ApplicantInformation485
