import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import Header from './Header';
import FormFotter from './FormFotter';



const SecureCheckout = () => {
  
    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-14 w-11/12  '>
            <div className=''>
            <p class="text-2xl font-semibold text-center text-black">
                    You've finished preparing this form. It's time to purchase the service and <br/>download your documents. You may review your answers now or edit your<br/> documents after purchase.
                </p>

         
               </div>
                
            <div className="flex item-center justify-center gap-20 mt-10">

                <button className='w-[200px] h-[40px] border-2  border-blue-600 text-blue-600 bg-white font-semibold' >Review</button>
                <button className='w-[200px] h-[40px]  bg-blue-700 text-white'>Purchase Option</button>
                </div>
          </form>
          <FormFotter />
        </div>
      </div>
    );
}

export default SecureCheckout