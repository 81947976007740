import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextI30';


const EligibilityI30 = () => {

  const navigate =useNavigate()
    const { formData, handleInputChange } = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
       navigate("/travel130")
    };
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
            <div>
              <h2 className='text-blue-700 text-xl font-semibold'>Immigration Application</h2>
  
              <fieldset className='mt-10'>
                <legend  className='bg-blue-500 text-white flex w-full p-4 rounded'>Where will you file this application?</legend>
                <div className='flex   flex-col gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="filingLocation"
                    value="inside"
                    checked={formData.filingLocation === 'inside'}
                    onChange={handleInputChange}
                    
                  />
                  From inside the United States
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="filingLocation"
                    value="outside"
                    checked={formData.filingLocation === 'outside'}
                    onChange={handleInputChange}
                  
                  />
                    From outside the United States
                </label>
                </div>
              </fieldset>

              {formData.filingLocation === 'outside'
                &&
                <div className='flex flex-col gap-4 bg-red-200 '>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p>Sorry. We do not offer a way to prepare a humanitarian parole application at this time. Requesting humanitarian parole can be very unique to the beneficiary's situation and the specific conditions that require parole. We recommend using an immigration attorney who is experienced with this type of case.</p>
                  </div>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p className='font-bold text-green-600'>Help me find an immigration attorney</p>
                  </div>
                  
                </div>}
  
             {formData.filingLocation === 'inside' &&
              <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Are you CURRENTLY in exclusion, deportation, removal, or rescission proceedings?</legend>
                <div className='flex   gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="proceedingsStatus"
                    value="yes"
                    checked={formData.proceedingsStatus === 'yes'}
                    onChange={handleInputChange}
                  />
                  Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="proceedingsStatus"
                    value="no"
                    checked={formData.proceedingsStatus === 'no'}
                    onChange={handleInputChange}
                  />
                  No
                </label>
                </div>
              </fieldset>}

              {formData.proceedingsStatus === 'yes'
              &&
                <div className='flex flex-col gap-4 bg-red-200 '>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p>Sorry. We do not offer a way to prepare a humanitarian parole application at this time. Requesting humanitarian parole can be very unique to the beneficiary's situation and the specific conditions that require parole. We recommend using an immigration attorney who is experienced with this type of case.</p>
                  </div>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p className='font-bold text-green-600'>Help me find an immigration attorney</p>
                  </div>
                  
                </div>}
  
            {formData.proceedingsStatus === 'no'
             &&
            <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Have you EVER been under immigration proceedings or ordered removed from the United States?</legend>
                 <div className='flex    gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
               
                  <input
                    type="radio"
                    name="immigrationHistory"
                    value="yes"
                    checked={formData.immigrationHistory === 'yes'}
                    onChange={handleInputChange}
                  />
                     Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                
                  <input
                    type="radio"
                    name="immigrationHistory"
                    value="no"
                    checked={formData.immigrationHistory === 'no'}
                    onChange={handleInputChange}
                />
                  No
                 </label>
                 </div>
              </fieldset>}


             {formData.immigrationHistory === 'yes'
             &&
             <>
             <div className='flex flex-col gap-4 bg-red-200 '>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p>Sorry. We do not offer a way to prepare a humanitarian parole application at this time. Requesting humanitarian parole can be very unique to the beneficiary's situation and the specific conditions that require parole. We recommend using an immigration attorney who is experienced with this type of case.</p>
                  </div>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p className='font-bold text-green-600'>Help me find an immigration attorney</p>
                  </div>
                  
                </div>
                     <div>
      
       <div className='w-full md:w-[600px] md:ml-auto bg-green-300 gap-2 rounded'>
      <label>
        I acknowledge that I have spoken to an attorney and want to continue anyway
      </label>
      <input
        type="checkbox"
        name="spokenToAttorney"
        checked={formData.spokenToAttorney}
        onChange={handleInputChange}
      />
      </div>
    </div>
                </>
                
                }



  
              {formData.immigrationHistory === 'no'
                
                 &&
                <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Have you EVER been charged with any misdemeanor or felony crime in the United States?</legend>
                  <div className='flex    gap-2 p-4 bg-blue-100 rounded'>
                 <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="criminalCharges"
                    value="yes"
                    checked={formData.criminalCharges === 'yes'}
                    onChange={handleInputChange}
                  />
                  Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
          
                  <input
                    type="radio"
                    name="criminalCharges"
                    value="no"
                    checked={formData.criminalCharges === 'no'}
                    onChange={handleInputChange}
                />
                        No
                 </label>
                 </div>
              </fieldset>}


              {formData.criminalCharges === 'yes'
                &&
                
                <>
             <div className='flex flex-col gap-4 bg-red-200 '>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p>Sorry. We do not offer a way to prepare a humanitarian parole application at this time. Requesting humanitarian parole can be very unique to the beneficiary's situation and the specific conditions that require parole. We recommend using an immigration attorney who is experienced with this type of case.</p>
                  </div>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p className='font-bold text-green-600'>Help me find an immigration attorney</p>
                  </div>
                  
                </div>
                     <div>
      
       <div className='w-full md:w-[600px] md:ml-auto bg-green-300 gap-4 rounded'>
      <label>
        I acknowledge that I have spoken to an attorney and want to continue anyway
      </label>
      <input
        type="checkbox"
        name="spokenToAttorney"
        checked={formData.spokenToAttorney}
        onChange={handleInputChange}
      />
      </div>
    </div>
                </>
}

  
              {formData.criminalCharges === 'no'
                &&
                <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Which travel document are you requesting?</legend>
               
               <div className='flex   flex-col gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="travelDocument"
                    value="advanceParole"
                    checked={formData.travelDocument === 'advanceParole'}
                    onChange={handleInputChange}
                  />
                   Advance Parole Document
                </label  >
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="travelDocument"
                    value="reentryPermit"
                    checked={formData.travelDocument === 'reentryPermit'}
                    onChange={handleInputChange}
                  />
                   Reentry Permit
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="travelDocument"
                    value="refugeeTravelDocument"
                    checked={formData.travelDocument === 'refugeeTravelDocument'}
                    onChange={handleInputChange}
                  />
                   Refugee Travel Document
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="travelDocument"
                    value="humanitarianParole"
                    checked={formData.travelDocument === 'humanitarianParole'}
                    onChange={handleInputChange}
                  />
                  Humanitarian Parole
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
               
                  <input
                    type="radio"
                    name="travelDocument"
                    value="dontKnow"
                    checked={formData.travelDocument === 'dontKnow'}
                    onChange={handleInputChange}
                  />
                     I don't know. Help me.
                </label>
                </div>
              </fieldset>}


              <div>

                {formData.travelDocument === 'humanitarianParole'
                  &&
                  <div className='flex flex-col gap-4 bg-red-200 '>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p>Sorry. We do not offer a way to prepare a humanitarian parole application at this time. Requesting humanitarian parole can be very unique to the beneficiary's situation and the specific conditions that require parole. We recommend using an immigration attorney who is experienced with this type of case.</p>
                  </div>
                  <div className='flex gap-4 p-2'>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className="w-[30px] h-[30px]" />
                  <p className='font-bold text-green-600'>Help me find an immigration attorney</p>
                  </div>
                  
                </div>}
     
       {formData.travelDocument === 'advanceParole'
       
       &&
       <div>
          <label className='bg-blue-500 mt-10 text-white flex w-full p-4 rounded'>Select the basis of your application for an Advance Parole Document:</label>
         
           <div className='flex bg-blue-100 p-4  flex-col'>
          <label className='ml-2  text-blue-700 gap-2'>
           
            <input
             
              type="radio"
              name="basis"
              value="adjustStatus"
              checked={formData.basis === 'adjustStatus'}
              onChange={handleInputChange}
            />
             I am in the process of filing or have a pending application to adjust status (Form I-485)
          </label>
          <label className='ml-2  text-blue-700 gap-2'>
          
            <input
              type="radio"
              name="basis"
              value="uNonimmigrantStatus"
              checked={formData.basis === 'uNonimmigrantStatus'}
              onChange={handleInputChange}
            />
              I have been granted U nonimmigrant status
          </label>
          <label className='ml-2  text-blue-700 gap-2'>
           
            <input
              type="radio"
              name="basis"
              value="temporaryProtectedStatus"
              checked={formData.basis === 'temporaryProtectedStatus'}
              onChange={handleInputChange}
            />
             I have applied for or have been granted Temporary Protected Status
          </label>
          <label   className='ml-2  text-blue-700 gap-2' >
 
            <input
              type="radio"
              name="basis"
              value="lifeFamilyUnityProgram"
              checked={formData.basis === 'lifeFamilyUnityProgram'}
              onChange={handleInputChange}
            />
            I am an active beneficiary of the LIFE Family Unity Program in valid status
          </label>
          <label className='ml-2  text-blue-700 gap-2'>
           
            <input
              type="radio"
              name="basis"
              value="otherImmigrationStatus"
              checked={formData.basis === 'otherImmigrationStatus'}
              onChange={handleInputChange}
            />
           I have an immigration status other than those listed above
          </label>
          </div>
        </div>}
       
       
       {formData.travelDocument === 'reentryPermit'
        &&
        
        <div>


          <label className='bg-blue-500 text-white flex w-full p-4 rounded mt-10' >Select your current status:</label>
           <div className='flex flex-col bg-blue-100 p-4'>
          <label  className='ml-2  text-blue-700 gap-2'>
           
            <input
              type="radio"
              name="currentStatus"
              value="permanentResident"
              checked={formData.currentStatus === 'permanentResident'}
              onChange={handleInputChange}
            />
             Lawful permanent resident (10-year green card)
          </label>
          <label  className='ml-2  text-blue-700 gap-2'>
         
            <input
              type="radio"
              name="currentStatus"
              value="conditionalResident"
              checked={formData.currentStatus === 'conditionalResident'}
              onChange={handleInputChange}
            />
               Conditional permanent resident (2-year green card)
          </label>
          </div>
        </div>}
        
        
        {formData.travelDocument === 'refugeeTravelDocument'
           &&
          <div>
          <label className='bg-blue-500 text-white flex w-full p-4 rounded mt-10'>What is your current immigration status?</label>
           <div className='bg-blue-100 p-4 flex  flex-col '>
          <label className='ml-2  text-blue-700 gap-2'>
        
            <input
              type="radio"
              name="immigrationStatus"
              value="asylumStatus"
              checked={formData.immigrationStatus === 'asylumStatus'}
              onChange={handleInputChange}
            />
                I was granted asylum status
          </label>
          <label className='ml-2  text-blue-700 gap-2'>
          
            <input
              type="radio"
              name="immigrationStatus"
              value="refugeeStatus"
              checked={formData.immigrationStatus === 'refugeeStatus'}
              onChange={handleInputChange}
            />
              I was granted refugee status
          </label>
          <label className='ml-2  text-blue-700 gap-2'>
       
            <input
              type="radio"
              name="immigrationStatus"
              value="permanentResidence"
              checked={formData.immigrationStatus === 'permanentResidence'}
              onChange={handleInputChange}
            />
                 I was granted permanent residence (green card)
          </label>
          <label className='ml-2  text-blue-700 gap-2'>
           
            <input
              type="radio"
              name="immigrationStatus"
              value="pendingAsylum"
              checked={formData.immigrationStatus === 'pendingAsylum'}
              onChange={handleInputChange}
            />
             I have a pending application for asylum
          </label>
          </div>
        </div>}
      </div>


     {formData.immigrationStatus === 'asylumStatus'
     &&
     <div>
     
      <label className='bg-blue-500 text-white flex w-full p-4 rounded mt-10'>
        Are you in the process of filing or do you currently have a pending application to adjust status (Form I-485)?
      </label>
      <div className='bg-blue-100 p-4 flex   '>
        <label className='ml-2  text-blue-700 gap-2'>

          <input
            type="radio"
            name="isAdjustingStatus"
            value="yes"
            checked={formData.isAdjustingStatus === 'yes'}
            onChange={handleInputChange}
          />
                    Yes
        </label>
        <label className='ml-2  text-blue-700 gap-2'>
     
          <input
            type="radio"
            name="isAdjustingStatus"
            value="no"
            checked={formData.isAdjustingStatus === 'no'}
            onChange={handleInputChange}
          />
               No
        </label>
      </div>
    </div>}

              {(formData.isAdjustingStatus === 'no' || formData.isAdjustingStatus === 'yes' || formData.immigrationStatus === 'refugeeStatus' )
              &&
              <div className='flex gap-4 bg-green-300  mt-20 p-2 rounded'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className='w-[30px] h-[40px]'/>
                <p> <span className='font-bold'>   Congratulations, you're likely eligible to apply for a Refugee Travel Document with Form I-131.</span>
                    Please click on "Next Page" below to continue your application</p>


              </div>}
  
              {formData.travelDocument === 'dontKnow'
                
                  &&
                <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Are you a permanent resident or conditional permanent resident?</legend>
                 <div className='flex gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="permanentResident"
                    value="yes"
                    checked={formData.permanentResident === 'yes'}
                    onChange={handleInputChange}
                  />
                  Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="permanentResident"
                    value="no"
                    checked={formData.permanentResident === 'no'}
                    onChange={handleInputChange}
                />
                 No
                 </label>
                 </div>
              </fieldset>}
  
             {formData.permanentResident === 'no'
             &&
             <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Do you currently have refugee status or asylum status?</legend>
                 <div className='flex   flex-col gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="refugeeAsylumStatus"
                    value="grantedRefugee"
                    checked={formData.refugeeAsylumStatus === 'grantedRefugee'}
                    onChange={handleInputChange}
                  />
                   Yes, I was granted refugee status.
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="refugeeAsylumStatus"
                    value="grantedAsylum"
                    checked={formData.refugeeAsylumStatus === 'grantedAsylum'}
                    onChange={handleInputChange}
                  />
                  Yes, I was granted asylum status.
                </label>

                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="refugeeAsylumStatus"
                    value="no"
                    checked={formData.refugeeAsylumStatus === 'no'}
                    onChange={handleInputChange}
                />
                 No
                 </label>
                 </div>
              </fieldset>}

     { formData.immigrationStatus === 'permanentResidence'
     &&
     
     <div>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
        Did you obtain your current permanent resident status as a direct result of refugee or asylum status?
      </label>
      <div className='flex    gap-8 p-4 bg-blue-100 rounded'>
        <label className='ml-2  text-blue-700 gap-2'>
        
          <input
            type="radio"
            name="obtainedPRFromRefugeeOrAsylum"
            value="yes"
            checked={formData.obtainedPRFromRefugeeOrAsylum === 'yes'}
            onChange={handleInputChange}
          />
            Yes
        </label>
        <label className='ml-2  text-blue-700 gap-2'>
         
          <input
            type="radio"
            name="obtainedPRFromRefugeeOrAsylum"
            value="no"
            checked={formData.obtainedPRFromRefugeeOrAsylum === 'no'}
            onChange={handleInputChange}
          />
           No
        </label>
      </div>
    </div>}

   { formData.immigrationStatus === 'refugeeStatus'
   &&
    <div className='flex gap-4 bg-red-200 p-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[30px] h-[40px]'/>
      <p>  <span className='font-bold'>We found a problem. Based on your answer, you cannot continue.</span> You are not eligible to apply for a Refugee Travel Document, but you may be eligible for a Reentry Permit.</p>
    </div>}

   {formData.obtainedPRFromRefugeeOrAsylum === 'no'
   &&
   <div className='flex gap-4 bg-red-200 p-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[30px] h-[40px]'/>
      <p>  <span className='font-bold'>We found a problem. Based on your answer, you cannot continue.</span> You are not eligible to apply for a Refugee Travel Document, but you may be eligible for a Reentry Permit.</p>
    </div>}


    {formData.immigrationStatus === 'pendingAsylum'
   &&
   <div className='flex gap-4 bg-red-200 p-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[30px] h-[40px]'/>
      <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue. You are not eligible to apply for a Refugee Travel Document. </span> In some cases, asylum applicants may be eligible for an advance parole document. However, CitizenPath recommends that you work with an attorney to evaluate your specific situation and determine the best strategy for travel.</p>
    </div>}

   {formData.obtainedPRFromRefugeeOrAsylum === 'yes'
   &&
    <div className='flex gap-4 bg-green-300  mt-20 p-2 rounded'>
                <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className='w-[30px] h-[40px]'/>
                <p> <span className='font-bold'>   Congratulations, you're likely eligible to apply for a Refugee Travel Document with Form I-131.</span>
                    Please click on "Next Page" below to continue your application</p>


              </div>}
  
             {(formData.refugeeAsylumStatus === 'grantedAsylum' || formData.refugeeAsylumStatus === 'no')
             &&
             
             <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Have you been granted U non-immigrant status (U visa)?</legend>
                 <div className='flex gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                  
                  <input
                    type="radio"
                    name="uVisaStatus"
                    value="yes"
                    checked={formData.uVisaStatus === 'yes'}
                    onChange={handleInputChange}
                  />
                  Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="uVisaStatus"
                    value="no"
                    checked={formData.uVisaStatus === 'no'}
                    onChange={handleInputChange}
                />
                 No
                 </label>
                 </div>
              </fieldset>}
  
              {formData.uVisaStatus === 'no'
                
                &&
                <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Do you have an approved or pending Form I-821, or will you be filing Form I-821 along with this Application for Travel Document?</legend>
                <div className='flex gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
               
                  <input
                    type="radio"
                    name="i821Status"
                    value="yes"
                    checked={formData.i821Status === 'yes'}
                    onChange={handleInputChange}
                  />
                     Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
                
                  <input
                    type="radio"
                    name="i821Status"
                    value="no"
                    checked={formData.i821Status === 'no'}
                    onChange={handleInputChange}
                />
                  No
                 </label>
                 </div>
              </fieldset>}
  
              {formData.i821Status === 'no'
                &&
                <fieldset className='mt-10'>
                <legend className='bg-blue-500 text-white flex w-full p-4 rounded'>Are you currently an active beneficiary of the LIFE Act Family Unity Program in valid status?</legend>
                 <div  className='flex gap-2 p-4 bg-blue-100 rounded'>
                <label className='ml-2  text-blue-700 gap-2'>
                 
                  <input
                    type="radio"
                    name="lifeActStatus"
                    value="yes"
                    checked={formData.lifeActStatus === 'yes'}
                    onChange={handleInputChange}
                  />
                   Yes
                </label>
                <label className='ml-2  text-blue-700 gap-2'>
            
                  <input
                    type="radio"
                    name="lifeActStatus"
                    value="no"
                    checked={formData.lifeActStatus === 'no'}
                    onChange={handleInputChange}
                />
                      No
                 </label>
                 </div>
              </fieldset>}
            </div>
  
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
      </div>
    )
 
}

export default EligibilityI30
