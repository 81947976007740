import React, { useState } from 'react';
import {AiOutlineShoppingCart} from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // imp
import { BsArrowRight} from "react-icons/bs";
import {BiArrowBack, BiArrowToLeft} from 'react-icons/bi'
import {ACTIONS, useFormContext } from '../../UseContext/useContextPageThree';






const DottedLineWithText = () => (
    <div className="flex items-center mt-4">
      <hr className="flex-1 border-t-2 border-dashed border-gray-200 mr-4" />
      <span className="text-gray-200 font-bold">Insert Another item</span>
      <hr className="flex-1 border-t-2 border-dashed border-gray-200 ml-4" />
    </div>
  );



const FormOne = () => {

  
  const { state, dispatch } = useFormContext();

      

  const handleRadioChange = (type) => {
    dispatch({
      type: 'SET_CHECKBOX',
      payload: { formType: 'FormOne', type, value: !state.FormOne[type] },
    });
  };

  const handleRecordsRequestChange = (e) => {
    dispatch({
      type: 'SET_RECORDS_REQUEST',
      payload: e.target.value,
    });
  };

  const handlePurposeRequestChange = (e) => {
    dispatch({
      type: 'SET_PURPOSE_REQUEST',
      payload: e.target.value,
    });
  };
  const handlePersonRequestChange = (value) => {
    dispatch({
      type: 'SET_PERSON_REQUEST',
      payload: value,
    });
  };

  
  const handleChildRequestChange = (value) => {
    dispatch({
      type: 'SET_CHILD_REQUEST',
      payload: value,
    });
  };

  const handleDeceasedRequestChange = (value) => {
    dispatch({
      type: 'SET_DECEASED_REQUEST',
      payload: value,
    });
  };

  const handlePowerRequestChange = (value) => {
    dispatch({
      type: 'SET_POWER_REQUEST',
      payload: value,
    });
  };

  const handleUsedDifferentNameChange = (value) => {
    dispatch({
      type: ACTIONS.SET_Subject_record,
      payload: value,
    });
  };

  const handleAddAnotherChange = (event) => {
    event.preventDefault();
    dispatch({ type: ACTIONS.ADD_NAME_SET });
    // Additional logic
  }
  
 
  const handleRemoveChange = (id) => {
    dispatch({ type: ACTIONS.REMOVE_NAME_SET, payload: id });
  };

  const handleNameChange = (id, field, value) => {
    dispatch({ type: ACTIONS.UPDATE_NAME_FIELD, payload: { id, field, value } });
  };

  const handleChange = (type, value) => {
    switch (type) {
      case 'street':
        dispatch({ type: ACTIONS.SET_STREET, payload: value });
        break;

      case 'aptSuiteFloor':
        dispatch({ type: ACTIONS.SET_APT_SUITE_FLOOR, payload: value });
        break;

      case 'number':
        dispatch({ type: ACTIONS.SET_NUMBER, payload: value });
        break;

      case 'cityTown':
        dispatch({ type: ACTIONS.SET_CITY_TOWN, payload: value });
        break;

      case 'zip':
        dispatch({ type: ACTIONS.SET_ZIP, payload: value });
        break;

      case 'stateTerritory':
        dispatch({ type: ACTIONS.SET_STATE_TERRITORY, payload: value });
        break;
        case 'stateTown':
        dispatch({ type: ACTIONS.SET_STATE_TOWN, payload: value });
        break;
      // ... other cases
    

      // ... other cases
    }
  };

  const handleChangePhoneNumber = (type, value) => {
    switch (type) {
      // ... existing cases
      case 'dayTimePhoneNumber':
        dispatch({ type: ACTIONS.SET_DAY_TIME_PHONE_NUMBER, payload: value });
        break;
      case 'MobilePhoneNumber':
        dispatch({ type: ACTIONS.SET_DAY_TIME_MOBILE_NUMBER, payload: value });
        break;
      // ... other cases
    }
  };
  const handleChangeEmail = (type, value) => {
    switch (type) {
      // ... existing cases
      case 'email':
        dispatch({ type: ACTIONS.SET_EMAIL, payload: value });
        break;
      case 'expeditedCondition':
        dispatch({ type: ACTIONS.TOGGLE_EXPEDITED_CONDITION, payload: value });
        break;
      // ... other cases
    }
  };
  const handleAorGVisaChange = (value) => {
    dispatch({ type: ACTIONS.SET_A_OR_G_VISA, payload: value === 'yes' });
  };
 
 
    const [locationData, setLocationData] = useState([]);
    const [value, setValue] = useState();
    const [valuetwo, setValuetwo] = useState();


    const [nameSets, setNameSets] = useState([{ id: 1 }]);

    const handleAddAnother = () => {
      setNameSets((prevNameSets) => [...prevNameSets, { id: Date.now() }]);
    };
  
    const handleRemove = (id) => {
      setNameSets((prevNameSets) => prevNameSets.filter((nameSet) => nameSet.id !== id));
    };


    useEffect(() => {
      // Replace this URL with your actual API endpoint
      fetch('https://restcountries.com/v3.1/all')
        .then((response) => response.json())
        .then((data) => {
          // Map the 'name' property of each country to 'label' in locationData
          const mappedData = data.map((country) => ({
            label: country.name.common,
            value: country.name.common,
            id: country.cca2, // Assign a unique identifier if needed
          }));
          setLocationData(mappedData);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }, []);
    
    const handleFormSubmit = (e) => {
      e.preventDefault();
      console.log('Handling form submission...');
      console.log('FormOne state:', state.FormOne);
      console.log('recordsRequest state:', state.recordsRequest);
      console.log('purposeRequest state:', state.purposeRequest);
      console.log('Person Request:', state.personRequest);
      console.log('Child Request:', state.childRequest); // Add this line for childRequest
      console.log('Deceased Request:', state.deceasedRequest); // Add this line for deceasedRequest
      console.log('Power Request:', state.powerOfAttorneyRequest); //
      console.log('Given Name:', state.FormOne.givenName); // Log givenName
      console.log('Middle Name:', state.FormOne.middleName); // Log middleName
      console.log('Family Name:', state.FormOne.familyName); // Log familyName
      console.log('Subject Record:', state.SET_Subject_record); 
      console.log('Subject Record:', state.subjectRecord);
      if (state.nameSets) {
        console.log('Name Sets:', state.nameSets);
      }

      console.log('Street:', state.street);
      console.log('Apt/Suite/Floor:', state.aptSuiteFloor);
      console.log('Number:', state.number);
      console.log('City/Town:', state.cityTown);
      console.log('Zip:', state.zip);
      console.log('State/Territory:', state.stateTerritory);
      console.log('Day Time Phone Number:', state.dayTimePhoneNumber);
      console.log('Mobile Phone Number:', state.MobilePhoneNumber);
      console.log('Email:', state.email);
      
      console.log('Expedited Conditions:');
      console.log('  Circumstances:', state.expeditedConditions.circumstances);
      console.log('  Urgency:', state.expeditedConditions.urgency);
      console.log('  Another Circumstances:', state.expeditedConditions.anotherCircumstances);
      console.log('  Another Urgency:', state.expeditedConditions.anotherUrgency);
    
      console.log('A or G Visa Holder:', state.isAorGVisaHolder);
      // Additional logic if needed
   
      // Reset the form if needed
      // dispatch({ type: 'RESET_FORM' });
   };
  
    



  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
 

 

    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
    <div className='flex justify-between w-full items-center bg-blue-800 h-12 rounded-t-lg'>

        <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
        <span className='font-bold text-white bg-blue-800 text-xl'>Freedom of Information Act Request</span>
        <div className='h-full'>
          <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
          <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
        </div>
      </div>





<div className='flex flex-col  w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col border border-black '>
<div className='flex flex-col-reverse justify-between items-center lg:flex-row md:'>
  <span className='mt-4 font-bold text-xl ml-2 text-black'>
  Form G-639, Freedom of Information / Privacy Act Request
  </span>
  <div className='flex gap-3'>
  <div className='flex justify-center items-center bg-blue-700 text-white rounded py-1 px-2 lg:mt-3 sm:mt-4 border border-black h-full'>
    <button className='mr-2'>Page 1 of 3</button>
    <MdArrowDropDown />
  </div>
  <div className='bg-green-600 rounded px-2 py-2 mt-3 mr-1 flex justify-center items-center h-full'>
    <AiOutlineShoppingCart className='text-white' />
  </div>
</div>

</div>

  
<span className='mt-2 font-thin ml-2'>Some of the information in this request is not required by USCIS. However, you should prepare this form as completely and accurately as possible to improvethe ability <br/>of USCIS to process your request.</span>
</div> 
<h1 className='text-blue-800 font-bold  mt-2 text-xl w-11/12   '>Description of Request</h1>
<p className='text-slate-500   mt-1  w-11/12 '>Select the type of record that you are requesting. If you would like to type your own request, select "Other" and describe the records that you are seeking in the space provided. <span className='text-blue-500'>Explain more </span> </p>



<form action=" " className='mt-14 w-11/12' onSubmit={handleFormSubmit}>

                      
                    
                  <div className='p-4 bg-blue-900 rounded ' >
                          <span className='text-white'>I am requesting:
                          <span className="text-red-500">*</span></span>
                        </div>
              <div className='flex gap-2 flex-col p-5 bg-blue-50'>
                <div className='flex gap-2'>
                  <input type="radio" name="visaType" id="alienNumber" 
                    checked={state.FormOne.alienNumber}
                    onChange={() => handleRadioChange('alienNumber')}
                  />
                  <label htmlFor="alienNumber">My alien registration number </label>
                </div>
                <hr className='bg-blue-200 h-0.5' />

                <div className='flex gap-2'>
                  <input type="radio" name="visaType" id="alienFile"
                   checked={state.FormOne.alienFile}
                   onChange={() => handleRadioChange('alienFile')}
                  
                  />
                  <label htmlFor="alienFile">Complete alien file (immigration file)</label>
                </div>
                <hr className='bg-blue-200 h-0.5' />
                <div className='flex gap-2'>
                  <input type="radio" name="visaType" id="i130Approval"
                   checked={state.FormOne.i130Approval}
                   onChange={() => handleRadioChange('i130Approval')}
                  
                  />
                  <label htmlFor="i130Approval">Any and all I-130 approval notices that name me as the beneficiary </label>
                </div>
                <hr className='bg-blue-200 h-0.5' />

                <div className='flex gap-2'>
                  <input type="radio" name="visaType" id="arrivalDepartureRecords"
                     checked={state.FormOne.arrivalDepartureRecords}
                     onChange={() => handleRadioChange('arrivalDepartureRecords')}
                    
                  />
                  <label htmlFor="arrivalDepartureRecords">Any and all arrival/departure records for the last five years</label>
                </div>
                <hr className='bg-blue-200 h-0.5' />

                <div className='flex gap-2 '>
                  <input type="radio" name="visaType" id="other"
                  checked={state.FormOne.other}
                  onChange={() => handleRadioChange('other')}
                  
                  />
                  <label htmlFor="other">Other</label>
                </div>
              </div>


              {/* condional rendering on the basis of other */}

              <div className="p-4 bg-blue-900 rounded mt-14">
                <span className="text-white">Describe the exact records that you are requesting:  </span>
              </div>
              <div className="flex items-center w-full bg-blue-50  p-3">
                <div  className="rounded w-full">
                <textarea
                id="familyName"
                name="familyName"
                className="w-full py-3 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                value={state.recordsRequest}
                onChange={handleRecordsRequestChange}
              ></textarea>

              </div>
              </div>
              <div className='p-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                <span className='text-gray-500'>Enter your specific request. How to do this </span>
              </div>


              <div className="p-4 bg-blue-900 rounded mt-14">
                <span className="text-white">What is the purpose of your request? </span>
              </div>
              <div className="flex items-center w-full bg-blue-50  p-3">
                <div  className="rounded w-full">
                <textarea
                id="familyName"
                name="familyName"
                className="w-full py-3 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                value={state.purposeRequest}
                onChange={handlePurposeRequestChange}
              ></textarea>

                          </div>
                          </div>
                          <div className='p-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                          <span className='text-gray-500'>You are not required to state the purpose of your request. However, providing this information may assist USCIS in locating the records needed to respond to your request.</span>
                          </div>


                      <div className='p-4 bg-blue-900 rounded  mt-10 ' >
                          <span className='text-white'>Are you requesting information about yourself or another person?
                          <span className="text-red-500">*</span></span>
                        </div>
                        <div className='flex gap-2 flex-col p-5 bg-blue-50'>
                        <div className='flex gap-2'>
                <input
                  type="radio"
                  name={`visaType`}
                  id={`alienNumber_myself`}
                  value="myself"
                  checked={state.personRequest === 'myself'}
                  onChange={() => handlePersonRequestChange('myself')} // Unique value for the "Myself" option
                />
                <label htmlFor={`alienNumber_myself`}>Myself</label>
              </div>
              <hr className='bg-blue-200 h-0.5' />

              <div className='flex gap-2'>
                <input
                  type="radio"
                  name={`visaType`}
                  id={`alienFile_other`}
                  value="myself"
                  checked={state.personRequest === 'other'}
                  onChange={() => handlePersonRequestChange('other')} // Unique value for the "Other" option
                />
                <label htmlFor={`alienFile_other`}>Other</label>
              </div>

              </div>






              <div>

                <hr className='bg-blue-700 h-1 w-full mt-20'/>

                <h1 className='text-blue-700 font-bold text-xl mt-8'>Subject of Record</h1>
                <p
                  className='font-thin text-sm'
                  style={{
                    color: 'rgba(60, 60, 60, 0.7)',
                    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                  }}
                >
                  <span className=''>The Subject of Record is the person whose records you're requesting. For example, if you are requesting a copy of your father's I-485 application, your father is the Subject of Record. Provide the following details to the best of your ability.</span>
                </p>
                
                <div className="p-4 bg-blue-700 rounded " >
                  <span className='text-white'>Are you requesting information on behalf of your child or a minor you have guardianship over?
                  <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-10  p-5 bg-blue-50'>
                  <div className='flex gap-2'>
                    <input type="radio" id="childYes" name="childAnswer" value="yes"
                     checked={state.childRequest === 'yes'}
                     onChange={() => handleChildRequestChange('yes')}
                    
                    />
                    <label htmlFor="childYes">Yes</label>
                  </div>
                  <div className='flex gap-2'>
                    <input type="radio" id="childNo" name="childAnswer" value="no" 
                         checked={state.childRequest === 'no'}
                         onChange={() => handleChildRequestChange('no')}
                    />
                    <label htmlFor="childNo">No</label>
                  </div>
                </div>
                <div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                  <span className='text-gray-500'>Indicate if you are requesting the records of a minor child under the age of 18 years old.</span>
                </div>

                <div className="p-4 bg-blue-700 rounded mt-10" >
                  <span className='text-white'>Are you requesting information on behalf of someone who is deceased?
                  <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-10  p-5 bg-blue-50'>
                <div className='flex gap-2'>
                            <input
                              type="radio"
                              id="deceasedYes"
                              name="deceasedAnswer"
                              value="yes"
                              checked={state.deceasedRequest === 'yes'}
                              onChange={() => handleDeceasedRequestChange('yes')}
                            />
                            <label htmlFor="deceasedYes">Yes</label>
                          </div>
                          <div className='flex gap-2'>
                            <input
                              type="radio"
                              id="deceasedNo"
                              name="deceasedAnswer"
                              value="no"
                              checked={state.deceasedRequest === 'no'}
                              onChange={() => handleDeceasedRequestChange('no')}
                            />
                            <label htmlFor="deceasedNo">No</label>
                          </div>
                </div>
                <div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                  <span className='text-gray-500'>Indicate if you are requesting the records of a person that has died.</span>
                </div>

                <div className="p-4 bg-blue-700 rounded mt-10 " >
                  <span className='text-white'>Are you requesting information on behalf of someone for whom you have power of attorney?
                  <span className="text-red-500">*</span></span>
                </div>
                <div className='flex gap-10  p-5 bg-blue-50'>
                <div className='flex gap-2'>
                    <input
                      type="radio"
                      id="powerYes"
                      name="powerAnswer"
                      value="yes"
                      checked={state.powerOfAttorneyRequest === 'yes'}
                      onChange={() => handlePowerRequestChange('yes')}
                    />
                    <label htmlFor="powerYes">Yes</label>
                  </div>
                  <div className='flex gap-2'>
                    <input
                      type="radio"
                      id="powerNo"
                      name="powerAnswer"
                      value="no"
                      checked={state.powerOfAttorneyRequest === 'no'}
                      onChange={() => handlePowerRequestChange('no')}
                    />
                    <label htmlFor="powerNo">No</label>
                  </div>

                </div>
                <div className='p-4 flex gap-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                  <img src="https://forms.citizenpath.com/assets/img/Icon_Attachment_30.png" alt="" className='rounded-lg' />
                  <span className='text-gray-500'>
                    <span className='font-bold'> For Your Information:</span> You will need to provide written authorization from the subject permitting disclosure of the records to you. We will provide a reminder in your filing instructions to obtain the subject's signature on this FOIA request.  <span className='font-bold'> You may continue >></span>
                  </span>
                </div>

              </div>



              {/* here is the hr */}
              <hr className='bg-blue-700 h-1 w-full mt-20'/>


              <h1 className='text-blue-700 font-bold text-xl mt-8'>Subject of Record</h1>
              <p
                className='font-thin text-sm'
                style={{
                  color: 'rgba(60, 60, 60, 0.7)',
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                }}
              >
              <span className='font-bold'>Because you are requesting access to your own records, <u></u>you are the Subject of Record.</span> Provide the following details to the best of your ability.
              </p>




              <div className="mb-4 flex mt-6">
              <label htmlFor="givenName" className="text-blue-700">
                Given Name (First Name): <span className="text-red-500">*</span>
              </label>

                      <input
                        type="text"
                        id="givenName"
                        name="givenName"
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
                        value={state.FormOne.givenName} // Set the value
                        onChange={(e) => dispatch({ type: ACTIONS.SET_GIVEN_NAME, payload: e.target.value })}
                      />
                    </div>

                    {/* Middle Name(s) */}
                    <div className="mb-4 flex">
                      <label htmlFor="middleName" className="text-blue-700"
                 
                      
                      >Middle Name(s):
                      <span className="text-red-500">*</span>
                      
                      </label>
                      <input
                        type="text"
                        id="middleName"
                        name="middleName"
                        value={state.FormOne.middleName} // Set the value
                        onChange={(e) => dispatch({ type: ACTIONS.SET_MIDDLE_NAME, payload: e.target.value })}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg  ml-24 '
                      />
                    </div>

                    {/* Family Name (Last Name) */}
                    <div className="mb-4 flex">
                      <label htmlFor="familyName" className="text-blue-500">Family Name (Last Name):
                      <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="familyName"
                        name="familyName"
                        value={state.FormOne.familyName} // Set the value
                        onChange={(e) => dispatch({ type: ACTIONS.SET_FAMILY_NAME, payload: e.target.value })}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
                      
                      />
                    </div>   


                  
                    
                    
                    <div className='flex w-full items-start'>
                <span className='text-blue-500 flex-2'>Has the Subject of Record ever used<br/> a different name <br/>since birth?
                  <span className="text-red-500">*</span>
                </span>

                <div className='flex ml-80 mt-8'>
                              <div className='flex gap-2'>
                                <input
                                  type="radio"
                                  name="SubjectRecordGroup"
                                  id="SubjectRecordYes"
                                  onChange={() => handleUsedDifferentNameChange('Yes')}
                                  checked={state.subjectRecord === 'Yes'}
                                />
                                <label htmlFor="SubjectRecordYes">Yes</label>
                              </div>

                              <div className='flex gap-2'>
                                <input
                                  type="radio"
                                  name="SubjectRecordGroup"
                                  id="SubjectRecordNo"
                                  onChange={() => handleUsedDifferentNameChange('No')}
                                  checked={state.subjectRecord === 'No'}
                                />
                                <label htmlFor="SubjectRecordNo">No</label>
                              </div>
                             </div>
              </div>
                  

              <div className="">
                {nameSets.map((nameSet, index) => (
                  <>
                  <DottedLineWithText/>
                
                  
                  <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 '>
                    {index > 0 && (
                    
                      <div className='flex w-full justify-between ml-2'>
                    
                        <span className="text-gray-500">{`Add Other ${index}`}</span>
                        <button  type="button"  onClick={() => handleRemove(nameSet.id)} className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4">
                          Remove
                        </button>
                      </div>
                    
                    )}
                    
                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                        Given Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`givenName-${nameSet.id}`}
                        name={`givenName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        value={nameSet.givenName}
                        onChange={(e) => handleNameChange(nameSet.id, 'givenName', e.target.value)}
                      />
                    </div>

                    {/* Middle Name(s) */}
                    <div className="mb-4 flex ml-2">
                      <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700">
                        Middle Name(s): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`middleName-${nameSet.id}`}
                        name={`middleName-${nameSet.id}`}
                        value={nameSet.middleName}
                        onChange={(e) => handleNameChange(nameSet.id, 'middleName', e.target.value)}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-24'
                      />
                    </div>

                    {/* Family Name (Last Name) */}
                    <div className="mb-4 flex ml-2">
                      <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-500">
                        Family Name (Last Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`familyName-${nameSet.id}`}
                        name={`familyName-${nameSet.id}`}
                        value={nameSet.familyName}
                         onChange={(e) => handleNameChange(nameSet.id, 'familyName', e.target.value)}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
                        
                      />
                    </div>
                  </div>
                  </>
                ))}


                {state.nameSets.length === 0 && (
                  <div>
                    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                    <span className="text-gray-500">Add Other</span>
                  </div>
                )}

                <button type="button" onClick={handleAddAnother} className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black">
                  Add Another Name
                </button>
              </div>

              <h2 className='text-blue-500  font-bold text-xl font-sans mt-20'>Mailing Address for the Subject of Record</h2>
              <p className='font-thin'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
              <div className='flex flex-col gap-6'>
                  <div className='flex gap-10  item-center  ' >
              <label htmlFor="streetform2" className='text-blue-500  mt-2 p-2'>Street Number and Name  <span className="text-red-500 ml-1">*</span></label>
              <input 
              value={state.street}
              onChange={(e) => handleChange('street', e.target.value)}
               type="text" id="streetform2" name="street" className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16  py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg '/>
               
              </div>


              <div className='flex gap-10  item-center '>
                <label htmlFor="cityform2" className='mt-2 text-blue-500 '>Is there an apartment,<br/> suite or floor?
                <span className="text-red-500 ml-1">*</span>
                </label>
                <select
                value={state.aptSuiteFloor}
                onChange={(e) => handleChange('aptSuiteFloor', e.target.value)}
                id="cityform2"
                name="city"
                className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-6 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-14'
              >
                <option value="no">No</option>
                <option value="Apt">Apt</option>
                <option value="Sle">Sle</option>
                <option value="Flr">Flr</option>
              </select>

              </div>

              {/* Conditionally render the input field based on the selected option */}

                      <div className='flex gap-10 item-center'>
                        <label htmlFor="numberform2" className='mt-2 text-blue-500'>Enter a number here</label>
                        <input
                          type="number"
                          id="numberform2"
                          name="state"
                          value={state.number}
                onChange={(e) => handleChange('number', e.target.value)}
                          className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-14'
                        />
                      </div>
                  
              <div className='flex gap-10 item-center'>
              <label htmlFor="stateform2" className='mt-2 text-blue-500 '>City or Town
              <span className="text-red-500 ml-1">*</span>
              </label>
              <input type="text" id="stateform2" name="state"   
              value={state.cityTown}
              onChange={(e) => handleChange('cityTown', e.target.value)}
              className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-24'/>
              </div>

              <div className='flex gap-10 item-center'>
              <label  htmlFor="zipform2" className='mt-2 text-blue-500'>Zip
              <span className="text-red-500 ml-1">*</span>
              </label>
              <input type="text" id="zipform2" name="zip" 
              value={state.zip}
              onChange={(e) => handleChange('zip', e.target.value)}
              className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-40'/>
              </div>

              <div className='flex gap-10 items-center'>
                <label htmlFor="stateTownfrom2" className='mt-2 text-blue-500'>State and territory
                <span className="text-red-500 ml-1">*</span>
                </label>
                
                <select id="stateTownform2" name="stateTown"
                  value={state.stateTerritory}
                  onChange={(e) => handleChange('stateTerritory', e.target.value)}
                className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
                <option value="" disabled>Please Select</option>
                      {locationData.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.label}
                        </option>
                      ))}

                </select>
              </div>



              </div>


                  <h1 className='text-blue-500 text-xl mt-10'>Contact Information for the Subject of Record</h1>
                  <div className='flex gap-10 items-center'>
                    <label className='mt-2 text-blue-500'>Day Time Phone number</label>
                    <PhoneInput
                    value={state.dayTimePhoneNumber}
                    onChange={(value) => handleChangePhoneNumber('dayTimePhoneNumber', value)}
                      

                      className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
                      </PhoneInput>
                  
                  </div>


                  <div className='flex gap-10 items-center'>
                    <label className='mt-2 text-blue-500'>Mobile Phone Number</label>
                    <PhoneInput
                      value={state.MobilePhoneNumber}
                      onChange={(value) => handleChangePhoneNumber('MobilePhoneNumber', value)}
                      className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
                      </PhoneInput>
                  
                  </div>


                  <div className='flex gap-10 items-center mt-10'>
                <label htmlFor="emailInput" className='mt-2 text-blue-500'>
                  Email
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="email"
                  id="emailInput"
                  name="email"
                  placeholder='@user@gmailcom'
                  value={state.email}
                onChange={(e) => handleChangeEmail('email', e.target.value)}
                  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-40'
                />
              </div>

              <hr className='bg-blue-700 h-1 w-full mt-20'/>
              <h1 className='text-blue-700 text-xl mt-10 font-bold' >Expenditure Request</h1>
              <p className="font-sans text-base font-normal leading-6  text-slate-500">In certain circumstances you may be able to receive expedited processing. Please indicate if any of the following conditions apply to your situation.</p>


              <div className="p-4 bg-blue-500 rounded " >
                          <span className='text-white'>Are you currently in the United States with an A visa or G visa?
                          <span className="text-red-500">*</span></span>
                        </div>
                        <div className='flex gap-10  p-5 bg-blue-50'>
                          <div className='flex gap-2'>
                            <input type="radio" id="yes" name="answer" value="yes"  onChange={() => handleAorGVisaChange('yes')}/>
                            <label htmlFor="yes">Yes</label>
                          </div>
                          <div className='flex gap-2'>
                            <input type="radio" id="no" name="answer" value="no" onChange={() => handleAorGVisaChange('no')} />
                            <label htmlFor="no">No</label>
                          </div>
                        </div>
                        <div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                <span className='text-gray-500'>Cases that involve individuals who are to appear before an immigration judge can receive expeditedy processing. You will need to include proof with your request.</span>
              </div>

                
              <div className="p-4 bg-blue-500 rounded  mt-10" >
                          <span className='text-white'>Indicate if any of these circumstances apply to your request (select all that apply):
                        </span>
                        </div>
                        <div className='flex gap-2 p-5 bg-blue-50 flex-col'>
                <div className='flex gap-2 items-center justify-evenly'>
                  <input type="checkbox" id="circumstances" name="circumstances" value="yes"
                   checked={state.expeditedConditions.circumstances}
                   onChange={() => handleChangeEmail('expeditedCondition', 'circumstances')}
                  />
                  <label htmlFor="circumstances" className='text-blue-500 flex-grow'>
                    Circumstances in which the lack of expedited treatment could reasonably be expected to pose an imminent threat to the life or physical safety of the individual.
                  </label>
                </div>
                <div className='flex gap-2 items-center '>
                  <input type="checkbox" id="urgency" name="urgency" value="no"
                   checked={state.expeditedConditions.urgency}
                   onChange={() => handleChangeEmail('expeditedCondition', 'urgency')}
                  />
                  <label htmlFor="urgency" className='text-blue-500 flex-grow'>
                    An urgency to inform the public about an actual or alleged Federal government activity, if made by a person primarily engaged in disseminating information.
                  </label>
                </div>
                <div className='flex gap-2 items-center'>
                  <input type="checkbox" id="anotherCircumstances" name="anotherCircumstances" value="yes"
                  checked={state.expeditedConditions.anotherCircumstances}
                  onChange={() => handleChangeEmail('expeditedCondition', 'anotherCircumstances')}
                  />
                  <label htmlFor="anotherCircumstances" className='text-blue-500 flex-grow'>
                  The loss of substantial due process rights.
                  </label>
                </div>
                <div className='flex gap-2 items-center'>
                  <input type="checkbox" id="anotherUrgency" name="anotherUrgency" value="no"
                  checked={state.expeditedConditions.anotherUrgency}
                  onChange={() => handleChange('expeditedCondition', 'anotherUrgency')}
                  />
                  <label htmlFor="anotherUrgency" className='text-blue-500 flex-grow'>
                  A matter of widespread and exceptional media interest in which there exists possible questions about the government's integrity which affects public confidence.
                  </label>
                </div>
              </div>
              <div className='p-4 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
                <span className='text-gray-500'>If none of these circumstances apply to your situation, please continue without checking any boxes.
              </span>
              </div>
              <div className='flex justify-between text-white mt-32 bg-white w-full'>
              <div className='bg-slate-300 flex items-center px-6  rounded-xl shadow '>
                            <button
                              type="submit"
                              className='text-white px-1 py-2 rounded'
                              // disabled={!formValid}
                            >
                              Previous
                            </button>
                            <BiArrowBack/>
                          </div>

              <div className='bg-green-600 flex items-center px-6  rounded-xl shadow '>
                            <button
                              type="submit"
                              className='text-white px-1 py-2 rounded'
                             
                            >
                              Next
                            </button>
                            <BsArrowRight />
                          </div>
              </div>
                        
       
      </form>
      <FormFotter />
    </div>
  </div>
  )
}

export default FormOne
