


import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context751';


const Eligibility751 = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/informationabout")
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            
       <h1 className='text-xl text-blue-600 font-semibold '>Basis of Application</h1>


       <div className='mt-10'>
      <h2 className='flex bg-blue-600 p-4 text-white rounded'>
      My conditional residence is based on:
      </h2>
      <div className='flex  flex-col gap-2 p-4 bg-blue-100'>
        <label htmlFor="marriage" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="conditionalResidenceType"
            id="marriage"
            value="marriage"
            checked={formData.conditionalResidenceType === 'marriage'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
          />
          <span>Marriage to a U.S. citizen or permanent resident</span>
        </label>
        <label htmlFor="parentsMarriage" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="conditionalResidenceType"
            id="parentsMarriage"
            value="parentsMarriage"
            checked={formData.conditionalResidenceType === 'parentsMarriage'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
          />
          <span>Parent's marriage to a U.S. citizen or permanent resident</span>
        </label>
        <label htmlFor="financialInvestment" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="conditionalResidenceType"
            id="financialInvestment"
            value="financialInvestment"
            checked={formData.conditionalResidenceType === 'financialInvestment'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
          />
          <span>Financial investment in a U.S. business</span>
        </label>
      </div>

      </div>



      {formData.conditionalResidenceType === 'marriage'
        
        &&
        
        <div className='mt-10'>
      <h2 className='flex bg-blue-600 p-4 text-white rounded'>
        How will you be filing?
      </h2>
      <div className='flex gap-5 flex-col p-4 bg-blue-100'>
        <label htmlFor="jointly" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="filingOption"
            id="jointly"
            value="jointly"
            checked={formData.filingOption === 'jointly'}
            onChange={handleInputChange}
            className='form-radio text-blue-500'
          />
          <span>Filing Jointly with my spouse</span>
        </label>

        <label htmlFor="individuallyWithWaiver" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="filingOption"
            id="individuallyWithWaiver"
            value="individuallyWithWaiver"
            checked={formData.filingOption === 'individuallyWithWaiver'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
          />
          <span>Filing Individually with a request for joint filing requirement waiver</span>
        </label>
      </div>
      <p className='bg-slate-200 p-2'>If you are still married, file Form I-751 jointly with your spouse through whom you obtained your conditional status. Explain more</p>
      </div>

      }



{formData.conditionalResidenceType === 'parentsMarriage'
    
    &&

    <div className='mt-10'>
      <h2 className='flex bg-blue-600 p-4 text-white rounded'>
        How will you be filing?
      </h2>
      <div className='flex gap-5 flex-col p-4 bg-blue-100'>
        <label htmlFor="jointlyWithParentsSpouse" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="filingOption"
            id="jointlyWithParentsSpouse"
            value="jointlyWithParentsSpouse"
            checked={formData.filingOption === 'jointlyWithParentsSpouse'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
          />
          <span>
            I am filing jointly with my parent's spouse because I am unable to be included in a joint petition filed by my parent and my parent's spouse.
          </span>
        </label>

        <label htmlFor="individuallyWithWaiver" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="filingOption"
            id="individuallyWithWaiver"
            value="individuallyWithWaiver"
            checked={formData.filingOption === 'individuallyWithWaiver'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
          />
          <span>Filing individually with a request for the joint filing requirement to be waived.</span>
        </label>
      </div>

      </div>
}


{formData.conditionalResidenceType === 'financialInvestment'
&&
    <div className='flex bg-red-300 gap-2 p-2'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[40px] h-[40px]' />
    <p>If you became a conditional resident based on a financial investment in a U.S. business, file Form I-829 Petition by Entrepreneur to Remove Conditions.</p>
    
</div>
  }





{formData.filingOption === 'jointlyWithParentsSpouse'
    
    &&
    <div className='flex  mt-10 gap-20  items-center'>
    <div className='flex flex-col flex-2 w-[700px]'>
      <label htmlFor="greenCardExpirationDate" className='flex bg-blue-600 p-4 text-white rounded'>
        When does your green card expire?
      </label>
      <div className='p-4 bg-blue-100 flex'>
          <input
            className="w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
            type="date"
            id="greenCardExpirationDate"
            name="greenCardExpirationDate"
            value={formData.greenCardExpirationDate}
            onChange={handleInputChange}
            required='true'
          />
      </div>
      </div>
      <div className='flex-1  ml-32'>
        <img src="https://citizenpath.com/cpwp/wp-content/uploads/2023/07/PRC-2023-Fade-Expiration-300px.jpg" alt="" />
      </div>
    </div>
    
    }


    <div className='flex flex-col bg-orange-200 p-2 gap-4 mt-10'>
        
        <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" className='w-[40px] h-[30px]'/>
        <p>
        Your card is expired. By failing to file I-751 before the expiration date, you are removable from the United States. Before you continue, CitizenPath recommends that you get a consultation from an experienced immigration attorney that can assist you with this matter.
        </p>
        </div>

        <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png"/>
        <p className='text-blue-600'>
        Help me find an immigration attorney

        </p>
        </div>
    </div>
    <div className='flex flex-col w-full md:w-[700px] ml-auto rounded-full'>
  <div className='p-4 bg-blue-100'>
    <label htmlFor="acknowledgeAttorney" className='text-blue-700 flex items-center gap-1 text-sm md:text-base'>
      I acknowledge that I have spoken to an attorney and/or want to continue anyway
      <input
        type="checkbox"
        id="acknowledgeAttorney"
        name="acknowledgeAttorney"
        checked={formData.acknowledgeAttorney}
        onChange={handleInputChange}
        className='form-checkbox  text-blue-500 ml-2'
      />
    </label>
  </div>
</div>


      

    <div className='flex flex-col mt-10'>
      <label htmlFor="livingOverseas" className='flex bg-blue-600 p-4 text-white rounded'>
        Are you (the conditional resident) living overseas due to military service or government orders?
      </label>
      <div className='flex flex-col md:flex-row gap-5 md:gap-10 p-4 bg-blue-100'>
        <label htmlFor="activeMilitary" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="livingOverseas"
            id="activeMilitary"
            value="Active Military"
            checked={formData.livingOverseas === 'Active Military'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
            required
          />
          <span>Yes (Active Military)</span>
        </label>

        <label htmlFor="governmentOrders" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="livingOverseas"
            id="governmentOrders"
            value="Government Orders"
            checked={formData.livingOverseas === 'Government Orders'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
            required
          />
          <span>Yes (Government Orders)</span>
        </label>

        <label htmlFor="notLivingOverseas" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="livingOverseas"
            id="notLivingOverseas"
            value="No"
            checked={formData.livingOverseas === 'No'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
            required
          />
          <span>No</span>
        </label>
       
      </div>
      <p className='bg-slate-200 p-2'>
        If you reside overseas because of military service or government orders, indicate Yes.
        </p>

      <label htmlFor="removalProceedings" className='flex bg-blue-600 p-4 text-white rounded mt-10'>
        Are you in removal, deportation, or rescission proceedings?
      </label>
      <div className='flex gap-10 p-4 bg-blue-100'>
        <label htmlFor="yesRemovalProceedings" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="removalProceedings"
            id="yesRemovalProceedings"
            value="Yes"
            checked={formData.removalProceedings === 'Yes'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
            required
          />
          <span>Yes</span>
        </label>

        <label htmlFor="noRemovalProceedings" className='text-blue-700 flex items-center gap-1'>
          <input
            type="radio"
            name="removalProceedings"
            id="noRemovalProceedings"
            value="No"
            checked={formData.removalProceedings === 'No'}
            onChange={handleInputChange}
            className='form-radio  text-blue-500'
            required
          />
          <span>No</span>
        </label>
      </div>
      <p className='bg-slate-200 p-2'>
      Removal, deportation, and rescission proceedings are similar terms to describe a situation that you must appear before an immigration judge and your immigration status is being challenged. Explain more 
        </p>

    </div>

{formData.removalProceedings === 'Yes'
&&
<>
    <div className='flex flex-col bg-orange-200 p-2 gap-4 mt-10'>
        
        <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" className='w-[40px] h-[30px]'/>
        <p>
       <span className='font-bold'>
        Your answer to this question suggests that you have a history which may require the assistance of a legal professional. </span>We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.
        </p>
        </div>

        <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png"/>
        <p className='text-blue-600'>
        Help me find an immigration attorney

        </p>
        </div>
    </div>


    
        
    <div className='flex flex-col w-full md:w-[700px] ml-auto rounded-full'>
  <div className='p-4 bg-blue-100'>
    <label htmlFor="acknowledgeAttorney2" className='text-blue-700 flex items-center gap-1 text-sm md:text-base'>
      I acknowledge that I have spoken to an attorney and/or want to continue anyway
      <input
        type="checkbox"
        id="acknowledgeAttorney2"
        name="acknowledgeAttorney2"
        checked={formData.acknowledgeAttorney2}
        onChange={handleInputChange}
        className='form-checkbox  text-blue-500 ml-2'
      />
    </label>
  </div>
</div>
    </>
    }
    

    <div className='flex flex-col'>
      {(formData.acknowledgeAttorney2 || formData.removalProceedings === 'No')
        
        &&
        
        <div className=' mt-10'>
        <label className='bg-blue-700 flex items-center gap-1 p-4 rounded text-white'>
          Was a fee paid to anyone other than an attorney in connection with this petition?
          </label>
          <div className='flex p-4 gap-4 bg-blue-100'>
            <label htmlFor="feePaidYes" className='text-blue-700 flex items-center gap-1'>
              <input
                type="radio"
                id="feePaidYes"
                name="feePaid"
                value="Yes"
                checked={formData.feePaid === 'Yes'}
                onChange={handleInputChange}
                className='form-radio  text-blue-500'
              />
              Yes
            </label>
            <label htmlFor="feePaidNo" className='text-blue-700 flex items-center gap-1'>
              <input
                type="radio"
                id="feePaidNo"
                name="feePaid"
                value="No"
                checked={formData.feePaid === 'No'}
                onChange={handleInputChange}
                className='form-radio  text-blue-500'
              />
              No
            </label>
          </div>
       
      </div>}

     {formData.feePaid === 'No'
     &&
     <div className='mt-10'>
        <label className='bg-blue-700 flex items-center gap-1 text-white p-4 rounded'>
          Have you ever been arrested, detained, charged, indicted, fined, or imprisoned for breaking or violating any law or ordinance (excluding traffic regulations), or committed any crime which you were not arrested in the United States or abroad?
          </label>
          <div className='flex gap-4 bg-blue-100 p-4'>
            <label htmlFor="arrestsOrCrimesYes" className='text-blue-700 flex items-center gap-1'>
              <input
                type="radio"
                id="arrestsOrCrimesYes"
                name="arrestsOrCrimes"
                value="Yes"
                checked={formData.arrestsOrCrimes === 'Yes'}
                onChange={handleInputChange}
                className='form-radio  text-blue-500'
              />
              Yes
            </label>
            <label htmlFor="arrestsOrCrimesNo" className='text-blue-700 flex items-center gap-1'>
              <input
                type="radio"
                id="arrestsOrCrimesNo"
                name="arrestsOrCrimes"
                value="No"
                checked={formData.arrestsOrCrimes === 'No'}
                onChange={handleInputChange}
                className='form-radio  text-blue-500'
              />
              No
            </label>
          </div>
   
      </div>}

    
     
      </div>

{formData.arrestsOrCrimes === 'Yes'
 &&
     <>
      <div className='flex flex-col bg-orange-200 p-2 gap-4 mt-10'>
        
        <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" className='w-[40px] h-[30px]'/>
        <p>
       <span className='font-bold'>
        Your answer to this question suggests that you have a history which may require the assistance of a legal professional. </span>We want the best result for you, and therefore CitizenPath suggests that you consult with an experienced immigration attorney that can provide you with additional guidance and representation.
        </p>
        </div>

        <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png"/>
        <p className='text-blue-600'>
        Help me find an immigration attorney

        </p>
        </div>
    </div>


       
    <div className='flex flex-col w-full md:w-[700px] ml-auto rounded-full'>
  <div className='p-4 bg-blue-100'>
    <label htmlFor="acknowledgeAttorney3" className='text-blue-700 flex items-center gap-1 text-sm md:text-base'>
      I acknowledge that I have spoken to an attorney and/or want to continue anyway
      <input
        type="checkbox"
        id="acknowledgeAttorney3"
        name="acknowledgeAttorney3"
        checked={formData.acknowledgeAttorney3}
        onChange={handleInputChange}
        className='form-checkbox  text-blue-500 ml-2'
      />
    </label>
  </div>
</div>
    </>
}

{(formData.acknowledgeAttorney3 || formData.arrestsOrCrimes === 'No')
    &&
    <div className='p-2 bg-green-300 flex gap-2 rounded mt-10'>
   <img
  src='https://forms.citizenpath.com/assets/img/Icon_Right_30.png'
  style={{ maxHeight: '50px', maxWidth: '50px', width: 'auto', height: 'auto' }}
/>
    <p>
      <span className='font-bold'>
        Congratulations, you're likely eligible to file Form I-751 Petition to Remove Conditions on Residence
      </span>
      Please click on "Next Page" below to continue your application.
    </p>
  </div>
}






<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default Eligibility751