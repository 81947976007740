





import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';



import { useFormContext } from '../../UseContext/Context485';


const ApplicationAddresHistory = () => {
    const navigate=useNavigate("")
    const {  formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();
  
    const handleSubmit = (e) => {
      e.preventDefault();
   
       navigate("/ApplicantInformationHistory485")
       
    };
  
  
  

    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
     
          
          
<div>
  <div className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">In Care of Name (if any)</label>
    <input
      type="text"
      name="applicantMailingAddressinCareOfName"
      value={formData.applicantMailingAddressinCareOfName}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div  className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">Street Number and Name</label>
    <input
      type="text"
      name="applicantMailingAddressstreetNumberAndName"
      value={formData.applicantMailingAddressstreetNumberAndName}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">City or Town</label>
    <input
      type="text"
      name="applicantMailingAddresscityOrTown"
      value={formData.applicantMailingAddresscityOrTown}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div  className="mt-10 flex gap-10">
    <label className="text-blue-600 block w-[200px]">State (or Territory)</label>
    <input
      type="text"
      name="applicantMailingAddressstateOrTerritory"
      value={formData.applicantMailingAddressstateOrTerritory}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div  className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">ZIP Code</label>
    <input
      type="text"
      name="applicantMailingAddresszipCode"
      value={formData.applicantMailingAddresszipCode}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>
</div>



<div className="mt-10">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">
          Is the above address also your current physical address?
        </label>
        <div className="flex bg-blue-100 p-4 gap-4 text-blue-600">
          <label>
            <input
              type="radio"
              name="sameAsMailingAddress"
              value="Yes"
              checked={formData.currentPhysicalAddresssameAsMailingAddress === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="sameAsMailingAddress"
              value="No"
              checked={formData.currentPhysicalAddresssameAsMailingAddress === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>

      <div className="mt-4">
        <label className="flex bg-blue-600 text-white p-4 rounded-md">Date you began living at the address above:</label>
        <div className="flex bg-blue-100 p-4 gap-4 text-blue-600">
        <input
          type="date"
          name="dateBeganLiving"
          value={formData.currentPhysicalAddressdateBeganLiving}
          onChange={handleInputChange}
          className="mt-1 p-2 border rounded-md"
        />
    </div>
      </div>


<hr  className='h-1 bg-blue-600 mt-10'/>

<div className='mt-10'>
  <div className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">In Care of Name (if any)</label>
    <input
      type="text"
      name="applicantMailingAddressinCareOfName2"
      value={formData.applicantMailingAddressinCareOfName2}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div  className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">Street Number and Name</label>
    <input
      type="text"
      name="applicantMailingAddressstreetNumberAndName2"
      value={formData.applicantMailingAddressstreetNumberAndName2}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">City or Town</label>
    <input
      type="text"
      name="applicantMailingAddresscityOrTown2"
      value={formData.applicantMailingAddresscityOrTown2}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div  className="mt-10 flex gap-10">
    <label className="text-blue-600 block w-[200px]">State (or Territory)</label>
    <input
      type="text"
      name="applicantMailingAddressstateOrTerritory2"
      value={formData.applicantMailingAddressstateOrTerritory2}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>

  <div  className="mt-10 flex gap-10 ">
    <label className="text-blue-600 block w-[200px]">ZIP Code</label>
    <input
      type="text"
      name="applicantMailingAddresszipCode2"
      value={formData.applicantMailingAddresszipCode2}
      onChange={handleInputChange}
      className="w-[400px] px-4 py-2 border rounded border-gray-300 focus:outline-none focus:border-indigo-600"
    />
  </div>
</div>

















            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
      </div>
    )
}

export default ApplicationAddresHistory