

import axios from "axios";
const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTFlMjI2ZWJkODM0MTNhMTlkMjZkZjkiLCJlbWFpbCI6ImVtYWlsQGdtYWlsLmNvbSIsInVzZXJuYW1lIjoiQWJkdWwgTWFqaWQiLCJjcmVhdGVkQXQiOiIyMDIzLTEwLTA1VDAyOjQxOjUwLjY2M1oiLCJ1cGRhdGVkQXQiOiIyMDIzLTEwLTA1VDAyOjQxOjUwLjY2M1oiLCJpYXQiOjE2OTc2NDkzNTksImV4cCI6MTcwMDI0MTM1OX0.LD6-zoSkKicObr2onh3buLUUqIrBpin4V0KY2RAY_2o'
//const baseUrl = "http://localhost:8800/api";

const baseUrl = "https://citizenfiles.com/backend/api";
const axiosRequest = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    // include auth token with requests made by the frontend
    headers: {
        Authorization: token,
    },
});


export default axiosRequest;
