export const Options = [
  { title: 'Adjustment of Status' },
  { code: '(c)(9)', label: 'Adjustment of Status Applicant' },
  { code: '(c)(16)', label: 'Registry Applicant Based on Continuous Residence Since January 1, 1972' },
  { code: '', label: 'NIW Physician - Renewal EAD for National Interest Waiver Physicians' },
  { title: 'Deferred Action Categories' },
  { code: '(c)(14)', label: 'Deferred Action (not including DACA)' },
  { code: '(c)(33)', label: 'Consideration of Deferred Action for Childhood Arrivals (DACA)' },
  { title: 'Asylee/Refugee Categories (including spouses and children)' },
  { code: '(a)(3)', label: 'Refugee' },
  { code: '(a)(4)', label: 'Paroled as a Refugee' },
  { code: '(a)(5)', label: 'Asylee (Granted Asylum)' },
  { code: '(a)(10)', label: 'Granted Withholding of Deportation or Removal' },
  { code: '(c)(8)', label: 'Asylum Applicant (with a pending asylum application)Who Filed for Asylum on or After January 4, 1995' },
  { title: 'Nationality Categories' },
  { code: '(a)(8)', label: 'Citizen of Micronesia, the Marshall Islands, or Palau' },
  { code: '(a)(11)', label: 'Deferred Enforced Departure (DED)' },
  { code: '(a)(12)', label: 'Temporary Protected Status (TPS) under 8CFR 244' },
  { code: '(c)(19)', label: 'Temporary Protected Status (TPS) under 8CFR 244.5' },
  { code: '(c)(10)', label: 'NACARA Section 203 Applicants Who Are Eligible to Apply for NACARA Relief With USCIS' },
  { code: '(c)(2)', label: 'Dependent of TECRO E-1 Nonimmigrant' },
  { title: 'Foreign Students' },
  { code: '(c)(3)(A)', label: 'F-1 Student Seeking Pre-Completion Optional Practical Training' },
  { code: '(c)(3)(B)', label: 'F-1 Student Seeking Post-Completion Optional Practical Training' },
  { code: '(c)(3)(C)', label: 'F-1 Student Seeking 24-month Extension for STEM Students' },
  { code: '(c)(3)(ii)', label: 'F-1 Student Offered Off-Campus Employment by Qualifying Organization' },
  { code: '(c)(3)(iii)', label: 'F-1 Student Seeking Off-Campus Employment Due to Economic Hardship' },
  { code: '(c)(5)', label: 'J-2 Spouse or Minor Child of an Exchange Visitor' },
  { code: '(c)(6)', label: 'M-1 Student Seeking Post-Completion OPT After Completing Studies' },
  { title: 'Eligible Dependents of Diplomatic Missions, International Organizations, or NATO' },
  { code: '(c)(1)', label: 'Dependent of A-1 or A-2 Foreign Government Officials' },
  { code: '(c)(4)', label: 'Dependent of G-1, G-3 or G-4 Nonimmigrant' },
  { code: '(c)(7)', label: 'Dependent of NATO-1 Through NATO-6' },
  { title: 'Employment-Based Nonimmigrant Categories' },
  { code: '(c)(17)(i)', label: 'B-1 Nonimmigrant Who Is the Personal or Domestic Servant of a Nonimmigrant Employer' },
  { code: '(c)(17)(ii)', label: 'B-1 Nonimmigrant Domestic Servant of a U.S. Citizen' },
  { code: '(c)(17)(iii)', label: 'B-1 Nonimmigrant Employed by a Foreign Airline' },
  { code: '(a)(17)', label: 'Spouse of an E-1 Treaty Trader, E-2 Treaty Investor, or E-3 Special Occupation' },
  { code: '(a)(18)', label: 'Spouse of an L-1 Intracompany Transferee' },
  { code: '(c)(12)', label: 'Spouse of an E-2 CNMI Investor' },
  { code: '(c)(26)', label: 'Spouse of an H-1B Nonimmigrant (H-4 Dependent)' },
  { code: '(c)(35)', label: 'Principal Beneficiary of an Approved Employment Petition Facing Compelling Circumstances' },
  { code: '(c)(36)', label: 'Spouse or Unmarried Child of (c)(35) Principal Beneficiary' },
  { title: 'Family-Based Nonimmigrant Categories' },
  { code: '(a)(6)', label: 'K-1 Nonimmigrant Fiancé(e) of U.S. Citizen or K-2 Dependent' },
  { code: '(a)(9)', label: 'K-3 Nonimmigrant Spouse of U.S. Citizen or K-4 Dependent' },
  { code: '(a)(13)', label: 'Family Unity Program' },
  { code: '(a)(14)', label: 'LIFE Family Unity' },
  { code: '(a)(15)', label: 'V-1, V-2 or V-3 Nonimmigrant' },
  { title: 'Other Categories' },
  { code: '(a)(2)', label: 'Legalization Temporary Resident Pursuant to INA Sections 245A or 210' },
  { code: '(a)(7)', label: 'N-8 or N-9 Nonimmigrant' },
  { code: '(c)(10)', label: 'Applicant for Cancellation of Removal' },
  { code: '(c)(20)', label: 'Applicant for Legalization Pursuant to INA Section 210' },
  { code: '(c)(22)', label: 'Applicant for Legalization Pursuant to INA 245A' },
  { code: '(c)(11)', label: 'Parole' },
  { code: '(c)(18)', label: 'Final Order of Deportation or Removal, including Deferral of Removal under CAT' },
  { code: '(c)(24)', label: 'LIFE Legalization Applicant' },
  { code: '(a)(16)', label: 'T-1 Nonimmigrant' },
  { code: '(c)(25)', label: 'T-2, T-3, T-4, T-5 or T-6 Nonimmigrant' },
  { code: '(a)(19)', label: 'U-1 Nonimmigrant' },
  { code: '(a)(20)', label: 'U-2, U-3, U-4 or U-5' },
  { code: '(c)(31)', label: 'VAWA Self-Petitioners' },
  { code: '(c)(37)', label: 'Applicant for Commonwealth of the Northern Mariana Islands (CNMI) Long-Term Resident Status' },
];



const immigrationStatusOptions = `
Please select...
Adjustment of Status Applicant
No Status
Deferred Action
Refugee/Asylee
A1 - Ambassador, Public Minister, Career Diplomat or Consular Officer, or Immediate Family
A2 - Other Foreign Government Official or Employee, or Immediate Family
A3 - Attendant, Servant, or Personal Employee of A1 or A2, or Immediate Family
B1 - Temporary Visitor for Business
B1/B2 - Temporary Visitor for Business & Pleasure
B2 - Temporary Visitor for Pleasure
C1/D - Combined Transit and Crewmember Visa
C2 - Alien in Transit to United Nations Headquarters
C3 - Foreign Government Official, Immediate Family, Attendant or Personal Employee in Transit
D - Crewmember (Sea or Air)
E1 - Treaty Trader, Spouse or Child
E2 - Treaty Investor, Spouse or Child
E3 - Australian Treaty Alien to Perform Services in a Specialty Occupation
E3D - Spouse or Child of E3
E3R - Returning E3
F1 - Student in an academic or language training program
F2 - Spouse or Child of F1
F3 - Canadian or Mexican national commuter student
G1 - Principal Resident Representative of Recognized Foreign Government
G2 - Other Representative of Recognized Foreign Member Government or Immediate Family
G3 - Representative of Nonrecognized or Nonmember Foreign Government
G4 - International Organization Officer or Employee, or Immediate Family
G5 - Attendant, Servant, or Personal Employee of G1 through G4, or Immediate Family
H1B - Alien in a Specialty Occupation (Profession)
H1B1 - Chilean or Singaporean National to Work in a Specialty Occupation
H1C - Nurse in health professional shortage area
H2A - Temporary Worker Performing Agricultural Services Unavailable in the United States
H2B - Temporary Worker Performing Other Services Unavailable in the United States
H3 - Trainee
H4 - Spouse or Child of Alien Classified H1B/B1/C, H2A/B/R, or H-3
I - Representative of Foreign Information Media, Spouse and Child
J1 - Exchange Visitor
J2 - Spouse or Child of J1
K1 - Fiance(e) of United States Citizen
K2 - Child of Fiance(e) of U.S. Citizen
K3 - Spouse of U.S. citizen awaiting availability of immigrant visa
K4 - Child of K3
L1 - Intracompany Transferee (Executive, Managerial, and Specialized Knowledge)
L2 - Spouse or Child of Intracompany Transferee
M1 - Vocational Student or Other Nonacademic Student
M2 - Spouse or Child of M1
M3 - Canadian or Mexican national commuter student
N8 - Parent of an Alien Classified SK3 or SN3
N9 - Child of N8 or of SK1, SK2, SK4, SN1, SN2, or SN4
O1 - Alien with Extraordinary Ability in Sciences, Arts, Education, Business or Athletics
O2 - Alien Accompanying and Assisting in the Artistic or Athletic Performance by O1
O3 - Spouse or Child of O1 or O2
P1 - Internationally Recognized Athlete or Entertainer
P2 - Artist or Entertainer in a Reciprocal Exchange Program
P3 - Artist or Entertainer in a Culturally Unique Program
P4 - Spouse or Child of P1, P2, or P3
Q1 - Participant in an International Cultural Exchange Program
Q2 - Irish Peace Program Participant
Q3 - Spouse or Child of Q2
R1 - Alien in a Religious Occupation
R2 - Spouse or Child of R1
S5 - Certain Aliens Supplying Critical Information Relating to a Criminal Organization
S6 - Certain Aliens Supplying Critical Information Relating to Terrorism
S7 - Qualified Family Member of S5 or S6
T1 - Victim of a severe form of trafficking in persons
T2 - Spouse of T1
T3 - Child of T1
T4 - Parent of a T1 under 21 years of age
T5 - Unmarried Sibling under age 18 of T1 under 21 years of age
T6 - Adult or Minor Child of a Derivative Beneficiary of a T1
TD - Spouse or Child of a NAFTA Professional
TN - NAFTA Professional
U1 - Victim of criminal activity
U2 - Spouse of U1
U3 - Child of U1
U4 - Parent of U1 under 21 years of age
U5 - Unmarried Sibling under age 18 of U1 under 21 years of age
V1 - Spouse of a Lawful Permanent Resident Alien Awaiting Availability of Immigrant Visa
V2 - Child of a Lawful Permanent Resident Alien Awaiting Availability Of Immigrant Visa
V3 - Child of a V1 or V2
WB - Visa Waiver Program (VWP) Business
WT - Visa Waiver Program (VWP) Tourism
Other
`;

const optionsArray = immigrationStatusOptions.split('\n').map(option => option.trim());

const objectArray = optionsArray.map((option, index) => {
  const [code, value] = option.split(' - ');

  return { code: code || `Option ${index}`, value: value || option };
});

export default objectArray;


export const countryCodes = [
  { code: '+1', name: 'United States' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+49', name: 'Germany' },
  { code: '+33', name: 'France' },
  { code: '+81', name: 'Japan' },
  { code: '+86', name: 'China' },
  { code: '+91', name: 'India' },
  { code: '+52', name: 'Mexico' },
  { code: '+39', name: 'Italy' },
  { code: '+34', name: 'Spain' },
  { code: '+7', name: 'Russia' },
  { code: '+61', name: 'Australia' },
  { code: '+82', name: 'South Korea' },
  { code: '+971', name: 'United Arab Emirates' },
  { code: '+46', name: 'Sweden' },
  { code: '+31', name: 'Netherlands' },
  { code: '+30', name: 'Greece' },
  { code: '+64', name: 'New Zealand' },
  { code: '+52', name: 'Mexico' },
  { code: '+55', name: 'Brazil' },
  { code: '+41', name: 'Switzerland' },
  { code: '+90', name: 'Turkey' },
  { code: '+86', name: 'China' },
  { code: '+82', name: 'South Korea' },
  // Add more country codes and names as needed
];







