import React from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextG639';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png";
import { countryCodes } from '../I-1765/Options';

const FreedomOfinformation = () => {
  const navigate = useNavigate();
  const { formData, handleInputChange, code } = useFormContext();

  console.log(formData.requestType);

  const HandleSubmit = (e) => {
    e.preventDefault();

    navigate("/subjectinformation");
  };
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
     <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
       <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
       <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
       <div className='h-full'>
         <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
         <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
       </div>
     </div>

     <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
       <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
         <span className='mt-4 font-bold text-xl ml-2 text-black'>
         Address Information
         </span>
         <div className='flex justify-between items-center gap-4'>
           <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
             <button className='mr-2'>Page 1 of 3</button>
             <button><MdArrowDropDown /></button>
           </div>
           <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
             <AiOutlineShoppingCart />
           </div>
         </div>
       </div>
       <span className='mt-2 font-thin ml-2'>
       First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
       </span>
     </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>


        <div className="">
          <h1 className="text-blue-600 text-xl font-semibold">Description of Request</h1>
          <p className="text-sm">Select the type of record that you are requesting. If you would like to type your own request, select "Other" and describe the records that you are seeking in the space <br/>
           provided. Explain more </p>

        </div>
    

        <div className='mt-4'>
  <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
    I am requesting:
  </label>
  <div className='flex flex-col p-4 bg-blue-100 rounded'>
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='requestType'
        value='My alien registration number'
        checked={formData.requestType === 'My alien registration number'}
        onChange={handleInputChange}
      />
      My alien registration number
    </label>
    <br />
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='requestType'
        value='Complete alien file (immigration file)'
        checked={formData.requestType === 'Complete alien file (immigration file)'}
        onChange={handleInputChange}
      />
      Complete alien file (immigration file)
    </label>
    <br />
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='requestType'
        value='Any and all I-130 approval notices that name me as the beneficiary'
        checked={formData.requestType === 'Any and all I-130 approval notices that name me as the beneficiary'}
        onChange={handleInputChange}
      />
      Any and all I-130 approval notices that name me as the beneficiary
    </label>
    <br />
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='requestType'
        value='Any and all arrival/departure records for the last five years'
        checked={formData.requestType === 'Any and all arrival/departure records for the last five years'}
        onChange={handleInputChange}
      />
      Any and all arrival/departure records for the last five years
    </label>
    <br />
    <label className='ml-2 text-blue-700'>
      <input
        type='radio'
        name='requestType'
        value='Other'
        checked={formData.requestType === 'Other'}
        onChange={handleInputChange}
      />
      Other
    </label>
  </div>
</div>

   
<div className="mt-10">
      
      <label label className='bg-blue-500 text-white flex w-full p-4 rounded'>Describe the exact records that you are requesting:</label>
      <div className="flex p-4 bg-blue-100 rounded">
      <textarea
        className="w-full border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded py-4"
        name="recordsRequest"
        value={formData.recordsRequest}
        onChange={handleInputChange}
      />
      </div>
    </div>
<p className="bg-slate-200 p-2">

    
Enter your specific request. How to do this
</p>
    
   
<div className="mt-10">
      
      <label label className='bg-blue-500 text-white flex w-full p-4 rounded'>What is the purpose of your request?</label>
      <div className="flex p-4 bg-blue-100 rounded">
      <textarea
        className="w-full border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none   rounded py-4"
        name="recordsRequest"
        value={formData.purposeofrequest}
        onChange={handleInputChange}
      />
      </div>
    </div>
<p className="bg-slate-200 p-2">
You are not required to state the purpose of your request. However, providing this information may assist USCIS in locating the records needed to respond to your request.
</p>


<div className='mt-4'>
      <label className='bg-blue-500 text-white flex w-full p-4 rounded'>
        Are you requesting information about yourself or another person?
      </label>
      <div className='flex flex-col p-4 bg-blue-100 rounded'>
        <label className='ml-2 text-blue-700'>
          <input
            type='radio'
            name='requestingInformation'
            value='Myself'
            checked={formData.requestingInformation === 'Myself'}
            onChange={handleInputChange}
          />
          Myself
        </label>
        <br />
        <label className='ml-2 text-blue-700'>
          <input
            type='radio'
            name='requestingInformation'
            value='Another person'
            checked={formData.requestingInformation === 'Another person'}
            onChange={handleInputChange}
          />
          Another person
        </label>
      </div>
    </div>


    <hr className="h-1 bg-blue-600 mt-10"/>


    <div className="mt-10">
   <h1 className="text-blue-600 text-xl font-semibold">Subject of Record</h1>
    <p className="text-sm">Because you are requesting access to your own records, you are the Subject of Record. Provide the following details to the best of your ability.</p>
    </div>


    <div className="mt-10">
        <h1 className="font-semibold text-blue-600 text-xl">
        Full Name of the Subject of Record
        </h1>
        <p>
        Enter the full legal name. Spell out middle names completely and do not use just the initial. If there is no middle name, you may leave this field blank.
        </p>
    </div>


    <div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">Given Name (First Name)</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="firstName"
    value={formData.firstName}
    onChange={handleInputChange}
  />
</div>

    <div className='flex gap-10 mt-10'>
  <label className="w-[200px]  text-blue-500">Middle Name</label>
  <input
 className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="middleName"
    value={formData.middleName}
    onChange={handleInputChange}
  />
</div>

<div className='flex gap-10 mt-10'> 
  <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
  <input
    className="w-[300px]  border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
    type="text"
    name="lastName"
    value={formData.lastName}
    onChange={handleInputChange}
  />
</div>


<div className='mt-10 flex gap-10'>

      <label className="text-blue-700">
        Has the Subject of Record ever <br/> used a different name <br/> since birth?
      </label>
  
      <div className="flex gap-5">
        <label className='ml-2 text-blue-700'>
          <input
            type='radio'
            name='usedDifferentName'
            value='Yes'
            checked={formData.usedDifferentName === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <br />
        <label className='ml-2 text-blue-700'>
          <input
            type='radio'
            name='usedDifferentName'
            value='No'
            checked={formData.usedDifferentName === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>


    <div className="mt-10">
        <h1 className="text-blue-600 text-xl font-semibold">
        Mailing Address for the Subject of Record
        </h1>
        <p className="text-sm">
        You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.
        </p>
    </div>

    <div className='flex mt-10 gap-2'>
      <label htmlFor=" inCareName"  className="w-[200px]  text-blue-500"> In Care Name (if Any)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="text"
        id=" inCareName"
        name=" inCareName"
        value={formData.streetNumberAndName}
        onChange={handleInputChange}
      />
    </div>
    

    <div className='flex mt-10 gap-2'>
      <label htmlFor="streetNumberAndName"  className="w-[200px]  text-blue-500">Street Number and Name:</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="text"
        id="streetNumberAndName"
        name="streetNumberAndName"
        value={formData.streetNumberAndName}
        onChange={handleInputChange}
      />
    </div>
    

    <div className='mt-10'>
      <label htmlFor="apartment" className="w-[150px]  text-blue-500">Is there an apartment, suite,  or floor?</label>
      <select
         className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-2 rounded ml-6"
        id="apartment"
        name="apartment"
        value={formData.apartment}
        onChange={handleInputChange}
      >
        <option value="">Select an option</option>
        <option value="No">No</option>
        <option value="Ste">Ste</option>
        <option value="Flr">Flr</option>
        <option value="Apt">Apt</option>
      </select>
    </div>

   { formData.apartment !== "No"
   &&
   <div className='flex mt-10 gap-2'>
      <label htmlFor="number"  className="w-[200px]  text-blue-500">Enter a number here</label>
      <input
        className="w-[50px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="text"
        id="number"
        name="number"
        value={formData.number}
        onChange={handleInputChange}
      />
    </div>
}
    <div className='flex mt-10 gap-2'>
      <label htmlFor="cityOrTown"  className="w-[200px]  text-blue-500">City or Town:</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="text"
        id="cityOrTown"
        name="cityOrTown"
        value={formData.cityOrTown}
        onChange={handleInputChange}
      />
    </div>


    <div className='flex mt-10 gap-2'>
      <label htmlFor="zipCode"  className="w-[200px]  text-blue-500">ZIP Code:</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="text"
        id="zipCode"
        name="zipCode"
        value={formData.zipCode}
        onChange={handleInputChange}
      />
    </div>


    <div  className='flex mt-10 gap-2'>
      <label htmlFor="country"  className="w-[200px]  text-blue-500">Country:</label>
      <select
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        id="country"
        name="country"
        value={formData.country}
        onChange={handleInputChange}
      >
        <option value="">Select a Country</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
      </div>

      <div className='flex mt-10 gap-2'>

      <label htmlFor="state"  className="w-[200px]  text-blue-500">State (or Territory):</label>
      <select
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        id="state"
        name="state"
        value={formData.state}
        onChange={handleInputChange}
      >
         <option value="">Select a Country</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      
      </select>
    </div>


  <hr  className="h-1 w-full bg-blue-600 mt-10"   />
    



  <div className='mt-10'>
  <label htmlFor="TimePhoneNumber" className='w-[250px] text-blue-700'>
    Daytime phone number
  </label>
  <select
    id="TimePhoneNumber"
    name="TimePhoneNumber"
    value={formData.TimePhoneNumber}
    onChange={handleInputChange}
    className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-28'
  >
    <option value="">Select a country</option>
    {countryCodes.map((country) => (
      <option key={country.code} value={country.code}>
        {country.code} {country.name}
      </option>
    ))}
  </select>
  <input
  value={formData.Mbnumber}
  name="Mbnumber"
  onChange={handleInputChange}
  type="tel"
  placeholder="123-456-7890"
  className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
/>
</div>


<div className='mt-10'>
  <label htmlFor="MobileNumber" className='w-[250px] text-blue-700'>
    Daytime phone number
  </label>
  <select
    id="MobileNumber"
    name="MobileNumber"
    value={formData.MobileNumber}
    onChange={handleInputChange}
    className='w-[100px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-28'
  >
    <option value="">Select a country</option>
    {countryCodes.map((country) => (
      <option key={country.code} value={country.code}>
        {country.code} {country.name}
      </option>
    ))}
  </select>
  <input
   
    value={formData.Mnumber}
    onChange={handleInputChange}
    type="tel"
    placeholder="123-456-7890"
    className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
  />
</div>

<div  className='mt-10 flex gap-8'>
      <label className='w-[250px] text-blue-700'>Email (if any):</label>
      <input
        className='border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
        type="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
      />
    </div>


<hr   className="bg-blue-600 mt-10 h-1"/>


<div>
 <h1 className='text-blue-600  font-semibold text-xl'>
 Expedited Requests
 </h1>
 <p className='text-sm'>
 In certain circumstances you may be able to receive expedited processing. Please indicate if any of the following conditions apply to your situation.
 </p>

</div>


<div className='mt-10'>
      <label className='bg-blue-600 p-4 text-white rounded mt-10 flex'>Do you have an urgent need for these records because you are to appear before an immigration judge?</label>
    <div className='flex bg-blue-100  p-4 gap-8'>
      <label className='text-blue-600 '>
    <input
        type="radio"
        name="urgentNeed" // Make sure 'name' matches the state variable name
        value="Yes"
        checked={formData.urgentNeed === 'Yes'}
        onChange={handleInputChange}
    />
    Yes
</label>
<label className='text-blue-600 '>
    <input
        type="radio"
        name="urgentNeed" // Make sure 'name' matches the state variable name
        value="No"
        checked={formData.urgentNeed === 'No'}
        onChange={handleInputChange}
    />
    No
</label>
</div>
    </div>
   <p className='bg-slate-200 p-2'>Cases that involve individuals who are to appear before an immigration judge can receive expeditedy processing. You will need to include proof with your request.
Indicate if any </p>


<div className='mt-10'>
<label className='bg-blue-600 p-4 text-white rounded flex'>Do you have an urgent need for these records because you are to appear before an immigration judge?</label>
<div className='flex flex-col p-4 gap-2 bg-blue-100'>

<div>
<label className='text-blue-600 '>
  <input
    type="checkbox"
    name="circumstancesImminentThreat"
    checked={formData.circumstancesImminentThreat}
    onChange={handleInputChange}
  />
  Circumstances in which the lack of expedited treatment could reasonably be expected to pose an imminent threat to the life or physical safety of the individual.
</label>
</div>
<div>
<label className='text-blue-600 '>
  <input
    type="checkbox"
    name="urgencyToInformPublic"
    checked={formData.urgencyToInformPublic}
    onChange={handleInputChange}
  />
  An urgency to inform the public about an actual or alleged Federal government activity, if made by a person primarily engaged in disseminating information.
</label>
</div>
<div>
<label className='text-blue-600 '>
  <input
    type="checkbox"
    name="lossOfDueProcessRights"
    checked={formData.lossOfDueProcessRights}
    onChange={handleInputChange}
  />
  The loss of substantial due process rights.
</label>
</div>

<div>
<label className='text-blue-600 '>
  <input
    type="checkbox"
    name="widespreadMediaInterest"
    checked={formData.widespreadMediaInterest}
    onChange={handleInputChange}
  />
  A matter of widespread and exceptional media interest in which there exists possible questions about the government's integrity which affects public confidence.
</label>
</div>
</div>
</div>



<div className='flex  text-white mt-32   w-[100px] h-[50px] ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>


    
   </form>
   <FormFotter />

     </div>
     </div>
 
 )
}

export default FreedomOfinformation