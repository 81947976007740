
import { createContext, useContext, useReducer } from 'react';

const initialState = {
  isCitizenValid: false,
  isBeneficiaryValid: false,
  isAgeValid: false,
  hasServedValid: false,
  isRelatedValid: false,
  isFiledAnswerValid: false,
  isFileI130AnswerValid: false,
  isRestrainingOrderAnswerValid: false,
  isDomesticViolenceAnswerValid: false,
  isHomicideAnswerValid: false,
  isRestrainingOrderValid: false,
  isDomesticViolenceValid: false,
  isHomicideValid: false,
  isLawViolationValid: false,
  isSubstanceAbuseValid: false,
  isImmigrationProceedingsValid: false,
  isMarriageEvadingValid: false,
  isCrimeChargedValid: false,
  employmentStatus: '',
  fromDate: '',
  haveOtherEmployers: null,
  givenName: '',
  middleNames: '',
  familyName: '',
  usTelephoneNumber: '',
  foreignTelephoneNumber: '',
  dateOfBirth: '',
  cityOfBirth: '',
  countryOfBirth:'',
  sex: '', 
  countryOfCitizenship: '',
  hasBeneficiaryBeenInUS: '',
  isBeneficiaryCurrentlyInUS: '',
  alienRegistrationNumber: '',
  socialSecurityNumber: '',
  parent1GivenName: '',
  parent1MiddleNames: '',
  parent1FamilyName: '',
  sex: '',
  parent1BirthInfo: {
    sex: '',
  },
  parent1DateOfBirth: '',
  parent1DoNotKnowDateOfBirth: false,
  parent1CountryOfBirth: '',
  fiancesMarriageStatus: '', // Initialize with an empty string
  beneficiaryHasChildren: '', 
  beneficiaryMailingAddress: {
    inCareOfName: '',
    streetNumberAndName: '',
    cityOrTown: '',
    province: '',
    postalCode: '',
  },
  dateBeganLivingAtAddress: '',
  
  // Add other initial states as needed
};

const formReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_IS_CITIZEN_VALID':
      return { ...state, isCitizenValid: payload };
    case 'SET_IS_BENEFICIARY_VALID':
      return { ...state, isBeneficiaryValid: payload };
    case 'SET_IS_AGE_VALID':
      return { ...state, isAgeValid: payload };
    case 'SET_HAS_SERVED_VALID':
      return { ...state, hasServedValid: payload };
    case 'SET_IS_RELATED_VALID':
      return { ...state, isRelatedValid: payload };
    case 'SET_IS_FILED_ANSWER_VALID':
      return { ...state, isFiledAnswerValid: payload };
    case 'SET_IS_FILE_I130_ANSWER_VALID':
      return { ...state, isFileI130AnswerValid: payload };
    case 'SET_IS_RESTRAINING_ORDER_ANSWER_VALID':
      return { ...state, isRestrainingOrderAnswerValid: payload };
    case 'SET_IS_DOMESTIC_VIOLENCE_ANSWER_VALID':
      return { ...state, isDomesticViolenceAnswerValid: payload };
    case 'SET_IS_HOMICIDE_ANSWER_VALID':
      return { ...state, isHomicideAnswerValid: payload };
    case 'SET_IS_RESTRAINING_ORDER_VALID':
      return { ...state, isRestrainingOrderValid: payload };
    case 'SET_IS_DOMESTIC_VIOLENCE_VALID':
      return { ...state, isDomesticViolenceValid: payload };
    case 'SET_IS_HOMICIDE_VALID':
      return { ...state, isHomicideValid: payload };
    case 'SET_IS_LAW_VIOLATION_VALID':
      return { ...state, isLawViolationValid: payload };
    case 'SET_IS_SUBSTANCE_ABUSE_VALID':
      return { ...state, isSubstanceAbuseValid: payload };
    case 'SET_IS_IMMIGRATION_PROCEEDINGS_VALID':
      return { ...state, isImmigrationProceedingsValid: payload };
    case 'SET_IS_MARRIAGE_EVADING_VALID':
      return { ...state, isMarriageEvadingValid: payload };
    case 'SET_IS_CRIME_CHARGED_VALID':
      return { ...state, isCrimeChargedValid: payload };
    case 'SET_EMPLOYMENT_STATUS':
      return { ...state, employmentStatus: payload };
    case 'SET_FROM_DATE':
      return { ...state, fromDate: payload };
    case 'SET_HAVE_OTHER_EMPLOYERS':
      return { ...state, haveOtherEmployers: payload };
      case 'SET_DATE_OF_BIRTH':
        return { ...state, dateOfBirth: action.payload };
      case 'SET_CITY_OF_BIRTH':
        return { ...state, cityOfBirth: action.payload };
        case 'SET_COUNTRY_OF_BIRTH':
          return { ...state, cityOfBirth: payload };
          case 'SET_SEX':
            return { ...state, sex: payload };
            case 'SET_COUNTRY_OF_CITIZENSHIP':
              return { ...state, countryOfCitizenship: payload };
              case 'SET_HAS_BENEFICIARY_BEEN_IN_US':
                return { ...state, hasBeneficiaryBeenInUS: payload };
              
              case 'SET_IS_BENEFICIARY_CURRENTLY_IN_US':
                return { ...state, isBeneficiaryCurrentlyInUS: payload };
              case 'SET_PARENT1_GIVEN_NAME':
                return { ...state, parent1GivenName: payload };

              case 'SET_PARENT1_MIDDLE_NAMES':
                return { ...state, parent1MiddleNames: payload };

              case 'SET_PARENT1_FAMILY_NAME':
                return { ...state, parent1FamilyName: payload };
                case 'SET_PARENT1_SEX':
                  return {
                    ...state,
                    parent1BirthInfo: {
                      ...state.parent1BirthInfo,
                      sex: payload,
                    },
                  };
                  case 'SET_PARENT1_DATE_OF_BIRTH':
                    return { ...state, parent1DateOfBirth: payload };
                  case 'SET_PARENT1_DO_NOT_KNOW_DATE_OF_BIRTH':
                    return { ...state, parent1DoNotKnowDateOfBirth: payload };
                  case 'SET_PARENT1_COUNTRY_OF_BIRTH':
                    return { ...state, parent1CountryOfBirth: payload };
                    case 'SET_FIANCE_MARRIAGE_STATUS':
                      return { ...state, fianceMarriageStatus: action.payload };
                
                    case 'SET_BENEFICIARY_HAS_CHILDREN':
                      return { ...state, beneficiaryHasChildren: action.payload };

                      case 'SET_BENEFICIARY_MAILING_ADDRESS':
                            return {
                              ...state,
                              beneficiaryMailingAddress: {
                                ...state.beneficiaryMailingAddress,
                                [action.field]: action.payload,
                              },
                            };
                            case 'SET_IS_MAILING_SAME_AS_PHYSICAL':
                              return { ...state, isMailingSameAsPhysical: action.payload };
                            case 'SET_DATE_BEGAN_LIVING':
                              return { ...state, dateBeganLivingAtAddress: action.payload };
                             
                            
              
          
    // Add other cases as needed
    default:
      return state;
  }
};

const FormContext = createContext();

const FormProviderI129F = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const setFormState = (type, value) => dispatch({ type, payload: value });

  const contextValue = {
    state,
    setFormState,
  };

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

export { FormProviderI129F, useFormContext };