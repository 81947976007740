

import React from 'react';
import Header from '../components/Header';
import { MdArrowDropDown } from "react-icons/md";
import { useState } from 'react';
import { BsArrowRight} from "react-icons/bs";
import tikpik from "../Assets/tikpic.png"
import crosspic from "../Assets/crosspic.png"
import FormFotter from '../components/FormFotter';
import {BiArrowBack, BiArrowToLeft} from 'react-icons/bi'
import { useFormContext } from '../UseContext/UseContext';
import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';


// Repeat these for other fields as well

// Event handler for updating street and number




const PageOneFormTwo = () => {

  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    // Replace this URL with your actual API endpoint
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        // Map the 'name' property of each country to 'label' in locationData
        const mappedData = data.map((country) => ({
          label: country.name.common,
          value: country.name.common,
          id: country.cca2, // Assign a unique identifier if needed
        }));
        setLocationData(mappedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  // const handleSelectChange = (event) => {
  //   setSelectedCountry(event.target.value);
  // };

  const navigate=useNavigate();

  const { state, setFormState } = useFormContext();

  const handlestreetandnumber = (value) => {
    setFormState('FormTwo', 'streetandnumber', value);
  };



 

  const handleStateOrTownChange= (value) => {
    setFormState('FormTwo', 'StateOrTown', value);
  };


  const handlezipChange = (value) => {
    setFormState('FormTwo', 'zip', value);
  };
  const handlecityortownChange = (value) => {
    setFormState('FormTwo', 'cityortown', value);
  };
  const handleapartmentNumberChange = (value) => {
    setFormState('FormTwo', 'apartmentNumber', value);
  };

  const handleapartmentNumbertwoChangetwo = (value) => {
    setFormState('FormTwo', 'apartmentNumbertwo', value);
  };
  const handlesteetandtwoChangetwo = (value) => {
    setFormState('FormTwo', 'streetandnumbertwo', value);
  };

  const handlezipChangetwo = (value) => {
    setFormState('FormTwo', 'ziptwo', value);;
  };

  const handleStateOrTownChangetwo= (value) => {
    setFormState('FormTwo', 'StateOrTowntwo', value);
  };
  const handlecityortownChangetwo = (value) => {
    setFormState('FormTwo', 'cityortowntwo', value);
  };

  const handleIsCheckedChange = (value) => {
    // Update the state with the selected value ('yes' or 'no')
    setFormState('FormTwo', 'isChecked', value);
  };

  const handleapartmentNumberChangethree = (value) => {
    setFormState('FormThree', 'apartmentNumberthree', value);
  };
  const handleStateOrTownChangethree = (value) => {
    setFormState('FormThree', 'StateOrTownthree', value);
  };
 
  const handlecityortownChangethree = (value) => {
    setFormState('FormThree', 'cityortownthree', value);
  };

  const handlezipChangethree = (value) => {
    setFormState('FormThree', 'zipthree', value);
  };

  const handlestreetandnumberthree = (value) => {
    setFormState('FormThree', 'threestreetandnumber', value); // Remove the space before 'FormThree'
  };
  

  // const handleapartmentNumberChangethree = (value) => {
  //   setFormState('FormThree', 'streetandnumber', value);
  // };
  // const handlezipChangethree = (value) => {
  //   setFormState('FormThree', 'streetandnumber', value);
  // };
  // const handlecityortownChangethree = (value) => {
  //   setFormState('FormThree', 'streetandnumber', value);
  // };
  const handleNumberChange = (value) => {
    // Convert the value to a number or keep it as an empty string
    const numericValue = value === '' ? '' : Number(value);
  
    setFormState('FormTwo', 'number', numericValue);
  };

  const handleNumbertwoChange = (value) => {
    // Convert the value to a number or keep it as an empty string
    const numericValue = value === '' ? '' : Number(value);
  
    setFormState('FormTwo', 'numbertwo', numericValue);
  };

  const handleNumberThreeChange = (value) => {
    // Convert the value to a number or keep it as an empty string
    const numericValue = value === '' ? '' : Number(value);
  
    setFormState('FormThree', 'numberthree', numericValue);
  };
  



      const formRef = useRef(null);

    
      
  

      const logFormData = () => {
        console.log('Form Data:', state);
      };
    
      const handleFormSubmit = (e) => {
        if (e) {
          e.preventDefault();
        }
    
        logFormData(); // Log the form data before submission
      
        navigate("/FormThree")
      };
    
    const [selectedOption, setSelectedOption] = useState('no');
   


    //
    //   };
  return (
    <div>
    <Header />

      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow  relative'>
    
                <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>

          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
          </div>

        <div className='flex flex-col border w-full pb-4 bg-gray-200 bg-opacity-70  '>
        <div className='flex justify-between items-center  '>
  <span className='mt-4 font-bold text-xl ml-2 text-black '>Let's get started with information about your addresses</span>
  <div className='flex item center justify-center  bg-blue-700 mr-2 item-center text-white rounded py-1 px-2'>
   
    <span className="mr-2">Page 2 of 3</span>
    <span><MdArrowDropDown /></span>
   
  </div>
</div>

  <span className='mt-2  font-thin ml-2'  >Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change</span>
</div>

<form action=" " className='mt-4 w-5/6  mr-8'  ref={formRef}  onSubmit={handleFormSubmit}>

<h2 className='text-blue-500  font-bold text-xl font-sans'>Previous Physical Address</h2>
<h3 className='font-thin'>Enter your <u>old</u> home address. This should be the location that you physically resided in the United States. Do not list a PO Box.</h3>
<div className='flex flex-col gap-6'>
  <div className='flex gap-10  item-center  ' >
<label htmlFor="street" className='text-blue-500  mt-2 p-2' >Street Number and Name<span className="text-red-500 ml-1">*</span></label>
<input value={state.FormTwo.streetandnumber}  onChange={(e)=>handlestreetandnumber(e.target.value)} type="text" id="street" name="street"  
 className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16  rounded  transition-all duration-300 ease-in-out  hover:shadow-lg '/>
</div>

<div className='flex gap-10  item-center '>
  <label htmlFor="city" className='mt-2 text-blue-500 ' >Is there an apartment,<br/> suite or floor?<span className="text-red-500 ml-1">*</span></label>
  <select
  value={state.FormTwo.apartmentNumber}
onChange={(e)=>handleapartmentNumberChange(e.target.value)}
  id="city"
  name="city"
  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-6 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-12'
>
  <option value="no">No</option>
  <option value="Apt">Apt</option>
  <option value="Sle">Sle</option>
  <option value="Flr">Flr</option>
</select>

</div>

{/* Conditionally render the input field based on the selected option */}
{state.FormTwo.apartmentNumber !== 'no' && (
        <div className='flex gap-10 item-center'>
          <label htmlFor="state" className='mt-2 text-blue-500' >Enter a number here</label>
          <input
          value={state.FormTwo.number} 
          onChange={(e)=>  handleNumberChange(e.target.value)}
            type="number"
            id="state"
            name="state"
            
            className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-12'
          />
        </div>
      )}
<div className='flex gap-10 item-center'>
<label htmlFor="state" className='mt-2 text-blue-500 '>City or Town<span className="text-red-500 ml-1">*</span></label>
<input type="text" id="state" name="state" value={state.FormTwo.cityortown} onChange={(e)=>handlecityortownChange(e.target.value)} className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-24'/>
</div>

<div className='flex gap-10 item-center'>
<label  htmlFor="zip" className='mt-2 text-blue-500'>Zip<span className="text-red-500 ml-1">*</span></label>
<input type="text" id="zip" name="zip"  value={state.FormTwo.zip} onChange={(e)=>handlezipChange(e.target.value)}  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-40'/>
</div>

<div className='flex gap-10 items-center'>
      <label htmlFor="stateTown" className='mt-2 text-blue-500'>State and Town</label>
  
      <select
        id="stateTown"
        name="stateTown"
        value={state.FormTwo.StateOrTown}
        onChange={(e) => handleStateOrTownChange(e.target.value)}
        className=' text-black border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-20'
      >
        <option value="" disabled>Please Select</option>
        {locationData.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>



</div>


<hr className='bg-blue-700 h-1 w-full mt-20'/>

{/* // Form Two starts here  */}
<h2 className='text-blue-500  font-bold text-xl font-sans'>Previous Physical Address</h2>
<h3 className='font-thin'>Enter your   <u>new</u> home address. This should be the location that you physically resided in the United States. Do not list a PO Box.</h3>
<div className='flex flex-col gap-6'>
    <div className='flex gap-10  item-center  ' >
<label htmlFor="streetform2" className='text-blue-500  mt-2 p-2'>Street Number and Name  <span className="text-red-500 ml-1">*</span></label>
<input type="text" id="streetform2" name="street"  value={state.FormTwo.streetandnumbertwo}  onChange={(e)=>handlesteetandtwoChangetwo(e.target.value)}  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16  py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg '/>
</div>

<div className='flex gap-10  item-center '>
  <label htmlFor="cityform2" className='mt-2 text-blue-500 '>Is there an apartment,<br/> suite or floor?
  <span className="text-red-500 ml-1">*</span>
  </label>
  <select
value={state.FormTwo.apartmentNumbertwo}
onChange={(e)=> handleapartmentNumbertwoChangetwo(e.target.value)}
  id="cityform2"
  name="city"
  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-6 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-14'
>
  <option value="no">No</option>
  <option value="Apt">Apt</option>
  <option value="Sle">Sle</option>
  <option value="Flr">Flr</option>
</select>

</div>

{/* Conditionally render the input field based on the selected option */}
{state.FormTwo.apartmentNumbertwo !== 'no' && (
        <div className='flex gap-10 item-center'>
          <label htmlFor="numberform2" className='mt-2 text-blue-500'>Enter a number here</label>
          <input
            type="number"
            value={state.FormTwo.numbertwo}
            onChange={(e)=>handleNumbertwoChange(e.target.value)}
            id="numberform2"
            name="state"
           
            className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-14'
          />
        </div>
      )}
<div className='flex gap-10 item-center'>
<label htmlFor="stateform2" className='mt-2 text-blue-500 '>City or Town
<span className="text-red-500 ml-1">*</span>
</label>
<input type="text" id="stateform2" name="state"   value={state.FormTwo.cityortowntwo} onChange={(e)=>handlecityortownChangetwo(e.target.value)}   className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-24'/>
</div>

<div className='flex gap-10 item-center'>
<label  htmlFor="zipform2" className='mt-2 text-blue-500'>Zip
<span className="text-red-500 ml-1">*</span>
</label>
<input type="text" id="zipform2" name="zip" value={state.FormTwo.ziptwo} onChange={(e)=>handlezipChangetwo(e.target.value)} className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-40'/>
</div>

<div className='flex gap-10 items-center'>
  <label htmlFor="stateTownfrom2" className='mt-2 text-blue-500'>State and territory
  <span className="text-red-500 ml-1">*</span>
  </label>
  
  <select id="stateTownform2" name="stateTown"  value={state.FormTwo.StateOrTowntwo} onChange={(e)=>handleStateOrTownChangetwo(e.target.value)} className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
  <option value="" disabled>Please Select</option>
        {locationData.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}

  </select>
</div>



</div>
<div className='p-4  bg-blue-500 rounded  mt-16'>
  <span className='text-white'>Are you currently in the United States with an A visa or G visa?<span className="text-red-500 ml-1">*</span></span>
</div>
<div className='flex gap-10 bg-blue-100 p-5'>
  <div className='flex gap-2'>
    <input type="radio" id="yes" name="answer" value='yes' checked={state.FormTwo.isChecked === 'yes'}
    onChange={(e) => handleIsCheckedChange(e.target.value)} />
    <label htmlFor="yes">Yes</label>
  </div>

  <div className='flex gap-2'>
    <input type="radio" id="no" name="answer" value='no' checked={state.FormTwo.isChecked === 'no'}
    onChange={(e) => handleIsCheckedChange(e.target.value)} />
    <label htmlFor="no">No</label>
  </div>
</div>
{state.FormTwo.isChecked === 'no'? (

<>
{/* form three start from here */}
<hr className='bg-blue-700 h-1 w-full mt-20' style={{ width: '100%' }} />

<div  className='mt-4 w-5/6  mr-8'>

<h2 className='text-blue-500  font-bold text-xl font-sans'>Mailing Address</h2>
<h3 className='font-thin'>Enter the address where you prefer to receive your mail.</h3>
<div className='flex flex-col gap-6'>
 <div className='flex gap-10  item-center  mt-2 ' >
<label htmlFor="streetform3" className='text-blue-500  mt-2 p-2'>Street Number and Name
<span className="text-red-500 ml-1">*</span>
</label>
<input type="text" id="streetform3" name="street" value={state.FormThree.threestreetandnumber} onChange={(e)=>handlestreetandnumberthree(e.target.value)} className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16  rounded  transition-all duration-300 ease-in-out  hover:shadow-lg '/>
</div>

<div className='flex gap-10  item-center '>
  <label htmlFor="numberform3" className='mt-2 text-blue-500 '>Is there an apartment,<br/> suite or floor?
  <span className="text-red-500 ml-1">*</span>
  </label>
  <select
      value={state.FormThree.apartmentNumberthree}
      onChange={ (e)=>handleapartmentNumberChangethree(e.target.value)}
        id="numberform3"
        name="city"
        className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-6 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-12'
>
  <option value="no">No</option>
  <option value="Apt">Apt</option>
  <option value="Sle">Sle</option>
  <option value="Flr">Flr</option>
</select>

</div>

{/* Conditionally render the input field based on the selected option */}
{state.FormThree.apartmentNumberthree !== 'no' && (
        <div className='flex gap-10 item-center'>
          <label htmlFor="stateform3" className='mt-2 text-blue-500'>Enter a number here
          <span className="text-red-500 ml-1">*</span>
          </label>
          <input
            value={state.FormThree.numberthree}
            onChange={(e)=>handleNumberThreeChange(e.target.value)}
            type="number"
            id="stateform3"
            name="state"
            className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
          />
        </div>
      )}
<div className='flex gap-10 item-center'>
<label htmlFor="cityform3" className='mt-2 text-blue-500 '>City or Town
<span className="text-red-500 ml-1">*</span>
</label>
<input type="text" id="cityform3" name="state" value={state.FormThree.cityortownthree}  onChange={(e)=>handlecityortownChangethree(e.target.value)}  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-24'/>
</div>

<div className='flex gap-10 item-center'>
<label  htmlFor="zipform3" className='mt-2 text-blue-500'>Zip
<span className="text-red-500 ml-1">*</span>
</label>
<input type="text" id="zipform3" name="zip" value={state.FormThree.zipthree} onChange={(e)=>handlezipChangethree(e.target.value)} className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  px-16 py-3 rounded  transition-all duration-300 ease-in-out  hover:shadow-lg ml-40'/>
</div>

<div className='flex gap-10 items-center'>
  <label htmlFor="stateTownform3" className='mt-2 text-blue-500'>State and Town<span className="text-red-500 ml-1">*</span></label>
  
  <select id="stateTownform3" name="stateTown" value={state.FormThree.StateOrTownthree} onChange={(e)=>handleStateOrTownChangethree(e.target.value)} className='border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-20'>
  <option value="" disabled>Please Select</option>
        {locationData.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}

  </select>
</div>



</div>
           
</div>


</>
)  : ""}




<div className='flex justify-between text-white mt-32 bg-white w-[100px] ml-auto'>


<div className='bg-green-600 flex items-center px-6  rounded-xl shadow '>
              <button
                type="submit"
                className='text-white px-1 py-2 rounded'
                // disabled={!formValid}
              >
                Next
              </button>
              <BsArrowRight />
            </div>
</div>
    
</form>

<FormFotter />  



      </div>
    </div>
  )
}

export default PageOneFormTwo
