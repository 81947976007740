import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";

const I8643 = () => {
  const navigate=useNavigate()
  const { formData, handleInputChange } = useFormContext();
  const maxLength = 50;
  if (!formData.name) formData.name = "";
  if (!formData.Middle) formData.Middle = "";
  if (!formData.Family) formData.Family = "";
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };

  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            USCIS Form I-864 - Affidavit of Support Package
          </span>
          <div className="h-full flex justify-end">
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>

        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2 style={{ marginBottom: "-1%" }}>
            Let's get started with some basic information about you -- the
            sponsor.
          </h2>
        </div>

        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
          <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Sponsor's Full Name</b>
              </h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
                Provide your full legal name. Spell out middle names completely
                and do not use just the initial. If you do not have a middle
                name, you may leave this field blank.
              </p>
            </div>
          </div>

          <form onSubmit={handleSubmit} className="mt-6 w-full">
            <div className="forms">
              <label htmlFor="givenName">
                Given Name (First Name): <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <input
                id="givenName"
                type="text"
                name="name"
                maxLength={maxLength}
                value={formData.name} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp;{" "}
              {formData.name.length < maxLength &&
                ` ${maxLength - formData.name.length} characters remaining`}
              <br />
              <label htmlFor="Middle">
                Middle Name(s):<span className="text-red-500">*</span>{" "}
                &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </label>
              &nbsp; &nbsp;
              <input
                id="Middle"
                type="text"
                name="Middle"
                maxLength={maxLength}
                value={formData.Middle} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp;{" "}
              {formData.Middle.length < maxLength &&
                ` ${maxLength - formData.Middle.length} characters remaining`}
              <br />
              <label htmlFor="family">
                Family Name (Last Name):<span className="text-red-500">*</span>{" "}
              </label>
              &nbsp; &nbsp;
              <input
                id="family"
                type="text"
                name="Family"
                maxLength={maxLength}
                value={formData.Family} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />
              &nbsp;{" "}
              {formData.Family.length < maxLength &&
                ` ${maxLength - formData.Family.length} characters remaining`}
            </div>
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <br />
            <div className="flex flex-col items-right space-y-0 p-4">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16">
                  <b>Sponsor's Mailing Address</b>
                </h3>
              </span>
              <div className="text-left">
                <p className="ptag ml-16">
                  Enter your home address or a place that you can safely receive
                  mail. You may list a valid residence, APO, PO Box, or
                  commercial address. If your mail will be sent to someone other
                  than yourself, please include an “In Care of Name” as a part
                  of your mailing address. If your mailing address is a U.S.
                  territory and it contains an urbanization name, list the
                  urbanization name in the "In Care of Name" box.
                </p>
              </div>
            </div>
            <br />
            <div className="flex flex-col">
              <div className="forms">
                <label htmlFor="Caree">
                  In Care of Name: <span className="text-red-500">*</span>
                </label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;
                <input
                  id="Caree"
                  type="text"
                  name="care"
                  value={formData.care}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                <br /> <br />
                <label htmlFor="streetNamee">
                  Street Number and Name:{" "}
                  <span className="text-red-500">*</span>
                </label>
                &nbsp; &nbsp;
                <input
                  id="streetNamee"
                  type="text"
                  name="streetnum"
                  maxLength={maxLength}
                  value={formData.streetnum}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                <br /> <br />
                <label htmlFor="appartment">
                  Is there an apartment, suite <br /> or floor?{" "}
                  <span className="text-red-500">*</span> &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </label>
                <select
                  class="form-control1"
                  aria-required="true"
                  required="required"
                  name="appartmentmail"
                  value={formData.appartmentmail}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="appartment"
                >
                  <option value="no">No</option>
                  <option value="Apt">Apt</option>
                  <option value="Ste">Ste</option>
                  <option value="Flr">Flr</option>
                </select>
                <br />{" "}
                {formData.appartmentmail === "Apt" ||
                formData.appartmentmail === "Ste" ||
                formData.appartmentmail === "Flr" ? (
                  <div className="num-input-wrapper">
                    <br />{" "}
                    <label htmlFor="numb">
                      Enter the number here:
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                    <input
                      id="numb"
                      type="text"
                      name="numb"
                      maxLength={15}
                      value={formData.numb}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        width: "10%",
                      }}
                    />
                  </div>
                ) : //-----------------------------------------------------------------------
                null}
                <br />
                <label htmlFor="cityy">
                  City or Town : <span className="text-red-500">* </span>{" "}
                </label>{" "}
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;{" "}
                <input
                  id="cityy"
                  type="text"
                  name="Cities"
                  maxLength={maxLength}
                  value={formData.Cites}
                  onChange={handleInputChange}
                  style={{
                    padding: "5px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    borderRadius: "5px",
                  }}
                />
                &nbsp; <br /> <br />
                <label htmlFor="countSelects">
                  Country : <span className="text-red-500"> * </span>{" "}
                </label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <select
                  id="countSelects"
                  value={formData.Country}
                  onChange={handleInputChange}
                  class="form-control"
                  name="Country"
                  aria-required="true"
                  required="required"
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                >
                  <option value="US">United States</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Canada">Canada</option>
                </select>
                <br />
                <br />
                {formData.Country === "US" ? (
                  <div>
                    <label htmlFor="stateSelecst">
                      State (or Territory):
                      <span className="text-red-500">*</span>{" "}
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      id="stateSelects"
                      name="State"
                      value={formData.State}
                      onChange={handleInputChange}
                      class="form-control"
                      aria-required="true"
                      required="required"
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                    >
                      <option value="" selected="selected">
                        Please select...
                      </option>
                      <option value="Alabama">Alabama</option>
                      <option value="Alaska">Alaska</option>
                      <option value="Arizona">Arizona</option>
                      <option value="Arkansas">Arkansas</option>
                      <option value="California">California</option>
                      <option value="Colorado">Colorado</option>
                      <option value="Connecticut">Connecticut</option>
                      <option value="Delaware">Delaware</option>
                      <option value="District of Columbia">
                        District of Columbia
                      </option>
                      <option value="Florida">Florida</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Idaho">Idaho</option>
                      <option value="Illinois">Illinois</option>
                      <option value="Indiana">Indiana</option>
                      <option value="Iowa">Iowa</option>
                      <option value="Kansas">Kansas</option>
                      <option value="Kentucky">Kentucky</option>
                      <option value="Louisiana">Louisiana</option>
                      <option value="Maine">Maine</option>
                      <option value="Maryland">Maryland</option>
                      <option value="Massachusetts">Massachusetts</option>
                      <option value="Michigan">Michigan</option>
                      <option value="Minnesota">Minnesota</option>
                      <option value="Mississippi">Mississippi</option>
                      <option value="Missouri">Missouri</option>
                      <option value="Montana">Montana</option>
                      <option value="Nebraska">Nebraska</option>
                      <option value="Nevada">Nevada</option>
                      <option value="New Hampshire">New Hampshire</option>
                      <option value="New Jersey">New Jersey</option>
                      <option value="New Mexico">New Mexico</option>
                      <option value="New York">New York</option>
                      <option value="North Carolina">North Carolina</option>
                      <option value="North Dakota">North Dakota</option>
                      <option value="Ohio">Ohio</option>
                      <option value="Oklahoma">Oklahoma</option>
                      <option value="Oregon">Oregon</option>
                      <option value="Pennsylvania">Pennsylvania</option>
                      <option value="Rhode Island">Rhode Island</option>
                      <option value="South Carolina">South Carolina</option>
                      <option value="South Dakota">South Dakota</option>
                      <option value="Tennessee">Tennessee</option>
                      <option value="Texas">Texas</option>
                      <option value="Utah">Utah</option>
                      <option value="Vermont">Vermont</option>
                      <option value="Virginia">Virginia</option>
                      <option value="Washington">Washington</option>
                      <option value="West Virginia">West Virginia</option>
                      <option value="Wisconsin">Wisconsin</option>
                      <option value="Wyoming">Wyoming</option>
                      <option value="U.S. Armed Forces - America (AA)">
                        U.S. Armed Forces - America (AA)
                      </option>
                      <option value="U.S. Armed Forces - Europe (AE)">
                        U.S. Armed Forces - Europe (AE)
                      </option>
                      <option value="U.S. Armed Forces - Pacific (AP)">
                        U.S. Armed Forces - Pacific (AP)
                      </option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Guam">Guam</option>
                      <option value="Northern Mariana Islands">
                        Northern Mariana Islands
                      </option>
                      <option value="Palau">Palau</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Swains Island">Swains Island</option>
                      <option value="Virgin Islands">Virgin Islands</option>
                    </select>
                    <br /> <br />
                    <label htmlFor="Zips">
                      ZIP Code : <span className="text-red-500">*</span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="Zips"
                      type="text"
                      name="Zips"
                      maxLength={8}
                      value={formData.Zips}
                      onChange={handleInputChange}
                      placeholder="####"
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                        width: "10%",
                      }}
                    />
                  </div>
                ) : null}
                {formData.Country === "Mexico" ||
                formData.Country === "Canada" ? (
                  <div>
                    <label htmlFor="Province">
                      Province : <span className="text-red-500">* </span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="Province"
                      type="text"
                      name="Province"
                      maxLength={20}
                      value={formData.Province}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    &nbsp; <br /> <br />
                    <label htmlFor="post">
                      Postal Code <span className="text-red-500">* </span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="text"
                      className="p-1"
                      id="post"
                      name="postal"
                      value={formData.postal}
                      onChange={handleInputChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
                Is your mailing address different than your physical address?
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="maillingAddress"
                  value="Yes"
                  id="inUSYess"
                  onChange={handleInputChange}
                  checked={formData.maillingAddress === "Yes"}
                />
                <label htmlFor="inUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="maillingAddress"
                  value="No"
                  id="inUSNos"
                  onChange={handleInputChange}
                  checked={formData.maillingAddress === "No"}
                />
                <label htmlFor="inUSNos">No</label>
              </div>
            </div>
            <br />
            {formData.maillingAddress === "No" ? (
              <div className="flex flex-col">
                <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />

                <div className="flex flex-col items-right space-y-0 p-4">
                  <span className="flex items-right space-x-10">
                    <h3 className="heading33 ml-16">
                      <b>Sponsor's Physical Address</b>
                    </h3>
                  </span>
                  <div className="text-left">
                    <p className="ptag ml-16">
                      The physical address is the location where you live.
                      Please provide a complete address. Every U.S. address
                      includes a street address with a number and street name.
                      You may or may not have an apartment number. All addresses
                      should include the city, state and ZIP code.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="forms">
                    <label htmlFor="streetNameep">
                      Street Number and Name:{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    &nbsp; &nbsp;
                    <input
                      id="streetNameep"
                      type="text"
                      name="Phystreetnum"
                      maxLength={maxLength}
                      value={formData.Phystreetnum}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    <br /> <br />
                    <label htmlFor="appartment1">
                      Is there an apartment, suite <br /> or floor?{" "}
                      <span className="text-red-500">*</span> &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </label>
                    <select
                      class="form-control1"
                      aria-required="true"
                      required="required"
                      name="Phyappartmentmail"
                      value={formData.Phyappartmentmail}
                      onChange={handleInputChange}
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                      id="appartment1"
                    >
                      <option value="no">No</option>
                      <option value="Apt">Apt</option>
                      <option value="Ste">Ste</option>
                      <option value="Flr">Flr</option>
                    </select>
                    <br />{" "}
                    {formData.Phyappartmentmail === "Apt" ||
                    formData.Phyappartmentmail === "Ste" ||
                    formData.Phyappartmentmail === "Flr" ? (
                      <div className="num-input-wrapper">
                        <br />{" "}
                        <label htmlFor="numba">
                          Enter the number here:
                          <span className="text-red-500">*</span>
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                        <input
                          id="numba"
                          type="text"
                          name="Phynumb"
                          maxLength={15}
                          value={formData.Phynumb}
                          onChange={handleInputChange}
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                            width: "10%",
                          }}
                        />
                      </div>
                    ) : //-----------------------------------------------------------------------
                    null}
                    <br />
                    <label htmlFor="cityy1">
                      City or Town : <span className="text-red-500">* </span>{" "}
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      id="cityy1"
                      type="text"
                      name="PhyCities"
                      maxLength={maxLength}
                      value={formData.PhyCites}
                      onChange={handleInputChange}
                      style={{
                        padding: "5px",
                        marginTop: "5px",
                        marginBottom: "5px",
                        borderRadius: "5px",
                      }}
                    />
                    &nbsp; <br /> <br />
                    <label htmlFor="countSelectss">
                      Country : <span className="text-red-500"> * </span>{" "}
                    </label>{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      id="countSelectss"
                      value={formData.PhyCountry}
                      onChange={handleInputChange}
                      class="form-control"
                      name="PhyCountry"
                      aria-required="true"
                      required="required"
                      autocomplete="section-P5_Physical_Address_Domestic address-level1"
                    >
                      <option value="US">United States</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Canada">Canada</option>
                    </select>
                    <br />
                    <br />
                    {formData.PhyCountry === "US" ? (
                      <div>
                        <label htmlFor="stateSelecsts">
                          State (or Territory):
                          <span className="text-red-500">*</span>{" "}
                        </label>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <select
                          id="stateSelecsts"
                          name="PhyState"
                          value={formData.PhyState}
                          onChange={handleInputChange}
                          class="form-control"
                          aria-required="true"
                          required="required"
                          autocomplete="section-P5_Physical_Address_Domestic address-level1"
                        >
                          <option value="" selected="selected">
                            Please select...
                          </option>
                          <option value="Alabama">Alabama</option>
                          <option value="Alaska">Alaska</option>
                          <option value="Arizona">Arizona</option>
                          <option value="Arkansas">Arkansas</option>
                          <option value="California">California</option>
                          <option value="Colorado">Colorado</option>
                          <option value="Connecticut">Connecticut</option>
                          <option value="Delaware">Delaware</option>
                          <option value="District of Columbia">
                            District of Columbia
                          </option>
                          <option value="Florida">Florida</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Idaho">Idaho</option>
                          <option value="Illinois">Illinois</option>
                          <option value="Indiana">Indiana</option>
                          <option value="Iowa">Iowa</option>
                          <option value="Kansas">Kansas</option>
                          <option value="Kentucky">Kentucky</option>
                          <option value="Louisiana">Louisiana</option>
                          <option value="Maine">Maine</option>
                          <option value="Maryland">Maryland</option>
                          <option value="Massachusetts">Massachusetts</option>
                          <option value="Michigan">Michigan</option>
                          <option value="Minnesota">Minnesota</option>
                          <option value="Mississippi">Mississippi</option>
                          <option value="Missouri">Missouri</option>
                          <option value="Montana">Montana</option>
                          <option value="Nebraska">Nebraska</option>
                          <option value="Nevada">Nevada</option>
                          <option value="New Hampshire">New Hampshire</option>
                          <option value="New Jersey">New Jersey</option>
                          <option value="New Mexico">New Mexico</option>
                          <option value="New York">New York</option>
                          <option value="North Carolina">North Carolina</option>
                          <option value="North Dakota">North Dakota</option>
                          <option value="Ohio">Ohio</option>
                          <option value="Oklahoma">Oklahoma</option>
                          <option value="Oregon">Oregon</option>
                          <option value="Pennsylvania">Pennsylvania</option>
                          <option value="Rhode Island">Rhode Island</option>
                          <option value="South Carolina">South Carolina</option>
                          <option value="South Dakota">South Dakota</option>
                          <option value="Tennessee">Tennessee</option>
                          <option value="Texas">Texas</option>
                          <option value="Utah">Utah</option>
                          <option value="Vermont">Vermont</option>
                          <option value="Virginia">Virginia</option>
                          <option value="Washington">Washington</option>
                          <option value="West Virginia">West Virginia</option>
                          <option value="Wisconsin">Wisconsin</option>
                          <option value="Wyoming">Wyoming</option>
                          <option value="U.S. Armed Forces - America (AA)">
                            U.S. Armed Forces - America (AA)
                          </option>
                          <option value="U.S. Armed Forces - Europe (AE)">
                            U.S. Armed Forces - Europe (AE)
                          </option>
                          <option value="U.S. Armed Forces - Pacific (AP)">
                            U.S. Armed Forces - Pacific (AP)
                          </option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Guam">Guam</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Palau">Palau</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Swains Island">Swains Island</option>
                          <option value="Virgin Islands">Virgin Islands</option>
                        </select>
                        <br /> <br />
                        <label htmlFor="Zipss">
                          ZIP Code : <span className="text-red-500">*</span>{" "}
                        </label>{" "}
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                        <input
                          id="Zipss"
                          type="text"
                          name="PhyZips"
                          maxLength={8}
                          value={formData.PhyZips}
                          onChange={handleInputChange}
                          placeholder="####"
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                            width: "10%",
                          }}
                        />
                      </div>
                    ) : null}
                    {formData.PhyCountry === "Mexico" ||
                    formData.PhyCountry === "Canada" ? (
                      <div>
                        <label htmlFor="Provinces">
                          Province : <span className="text-red-500">* </span>{" "}
                        </label>{" "}
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                        <input
                          id="Provinces"
                          type="text"
                          name="PhyProvince"
                          maxLength={20}
                          value={formData.PhyProvince}
                          onChange={handleInputChange}
                          style={{
                            padding: "5px",
                            marginTop: "5px",
                            marginBottom: "5px",
                            borderRadius: "5px",
                          }}
                        />
                        &nbsp; <br /> <br />
                        <label htmlFor="posts">
                          Postal Code <span className="text-red-500">* </span>
                        </label>{" "}
                        &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <input
                          type="text"
                          className="p-1"
                          id="posts"
                          name="Phypostal"
                          value={formData.Phypostal}
                          onChange={handleInputChange}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : null}
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <div className="flex flex-col">
              <div className="flex flex-col items-right space-y-0 p-4">
                <span className="flex items-right space-x-10">
                  <h3 className="heading33 ml-16">
                    <b>Country of Domicile</b>
                  </h3>
                </span>
                <div className="text-left">
                  <p className="ptag ml-16">
                    A sponsor who files Form I-864, Affidavit of Support, must
                    be domiciled in the United States or in the process of
                    reestablishing U.S. domicile.{" "}
                    <u style={{ color: "blue" }}>Explain more {">>"}</u>
                  </p>
                </div>
              </div>
              <div className="mt-2 font-thin ml-2 text ndRendring">
                Select your citizenship or residency:
                <span className="text-red-500">*</span>
              </div>
              <div className="form h-14">
                <select
                  id="countryofbirth"
                  name="countryCitizen"
                  className="form-control mt-3 ml-3"
                  aria-required="true"
                  required="required"
                  style={{ width: "30%" }}
                  autoComplete="section-P5_Physical_Address_Domestic address-level1"
                  value={formData.countryCitizen}
                  onChange={handleInputChange}
                >
                  <option value="Aruba">Aruba</option>
                  <option value="Austria">Austria</option>
                  <option value="Bahamas">Bahamas</option>
                  <option value="Bermuda">Bermuda</option>
                  <option value="Canada">Canada</option>
                  <option value="China">China</option>
                  <option value="England">England</option>
                  <option value="Germany">Germany</option>
                  <option value="Ghana">Ghana</option>
                  <option value="Greece">Greece</option>
                  <option value="Haiti">Haiti</option>
                  <option value="Honduras">Honduras</option>
                  <option value="India">India</option>
                  <option value="Ireland">Ireland</option>
                  <option value="Italy">Italy</option>
                  <option value="Jordan">Jordan</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Korea">Korea</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Pakistan">Pakistan</option>
                  <option value="Philippines">Philippines</option>
                  <option value="Russia">Russia</option>
                  <option value="Singapore">Singapore</option>
                  <option value="South Africa">South Africa</option>
                  <option value="Thailand">Thailand</option>
                  <option value="United States" selected="selected">
                    United States
                  </option>
                  <option value="Other">Other</option>
                </select>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Domicile is the place you have a home and intend to remain as
                  a home.
                </div>
              </div>
            </div>
            {formData.countryCitizen === "United States" ? (
              <div>
                <br />
                <br />

                <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
                <div className="flex flex-col items-right space-y-0 p-4">
                  <span className="flex items-right space-x-10">
                    <h3 className="heading33 ml-16">
                      <b>Sponsor's Contact Information</b>
                    </h3>
                  </span>
                </div>
                <div className="forms">
                  <label htmlFor="Daytime">
                    Daytime Phone Number
                    <span className="text-red-500">*</span>
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{" "}
                  <input
                    id="Daytime"
                    name="Daytimephone"
                    type="tel"
                    value={formData.Daytimephone}
                    onChange={handleInputChange}
                    pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                    className="p-2 mt-2 mb-2 rounded border"
                    placeholder="(123) 456-7890"
                    required="required"
                  />
                  <br />
                  <label htmlFor="mobphone">
                    Mobile Phone Number <i>(if any)</i>
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;
                  <input
                    id="mobphone"
                    name="mobilephone"
                    type="tel"
                    value={formData.mobilephone}
                    onChange={handleInputChange}
                    pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                    className="p-2 mt-2 mb-2 rounded border"
                    placeholder="(123) 456-7890"
                  />
                  <br />
                  <label htmlFor="mobphone">
                    E-mail<i> (if any)</i>
                  </label>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                  &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
                  <input
                    id="emails"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="p-2 mt-2 mb-2 rounded border "
                    placeholder="user@example.com"
                    style={{ width: "29%" }}
                  />
                </div>

                <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
                <br />
                <div className="flex flex-col items-right space-y-0 p-4">
                  <span className="flex items-right space-x-10">
                    <h3 className="heading33 ml-16">
                      <b>Sponsor's Birth Information</b>
                    </h3>
                  </span>
                </div>
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Date of Birth
                  <span className="text-red-500">*</span>
                </div>
                <div className="form h-14 p-2">
                  <div className="flex flex-col space-y-5 ml-4 mt-0">
                    <div className="flex items-center space-x-10">
                      <input
                        id="dob1"
                        type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleInputChange}
                        className="py-1 px-2 rounded"
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Country of Birth
                  <span className="text-red-500">*</span>
                </div>
                <div className="form h-14">
                  <select
                    id="countryofbirths"
                    name="countryofBirth"
                    className="form-control mt-3 ml-3"
                    aria-required="true"
                    required="required"
                    style={{ width: "30%" }}
                    autoComplete="section-P5_Physical_Address_Domestic address-level1"
                    value={formData.countryofBirth}
                    onChange={handleInputChange}
                  >
                    <option value="" selected="selected">
                      Please select...
                    </option>
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Aland Islands">Aland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua and Barbuda">
                      Antigua and Barbuda
                    </option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">
                      Bosnia and Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burma (Myanmar)">Burma (Myanmar)</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">
                      Cocos (Keeling) Islands
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, Democratic Republic of the">
                      Congo, Democratic Republic of the
                    </option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curacao">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Islas Malvinas)">
                      Falkland Islands (Islas Malvinas)
                    </option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-Bissau">Guinea-Bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Holy See">Holy See</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, North">Korea, North</option>
                    <option value="Korea, South">Korea, South</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">
                      Micronesia, Federated States of
                    </option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">
                      Netherlands Antilles
                    </option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">
                      Northern Mariana Islands
                    </option>
                    <option value="Norway">Norway</option>
                    <option value="Occupied Palestinian Territories">
                      Occupied Palestinian Territories
                    </option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="People's Republic of Korea">
                      People's Republic of Korea
                    </option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn Islands">Pitcairn Islands</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Korea">Republic of Korea</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">
                      Saint Kitts and Nevis
                    </option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">
                      Saint Pierre and Miquelon
                    </option>
                    <option value="Saint Vincent and the Grenadines">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">
                      Sao Tome and Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Maarten">Sint Maarten</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Sudan">South Sudan</option>
                    <option value="Soviet Union">Soviet Union</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard">Svalbard</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-Leste">Timor-Leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">
                      Trinidad and Tobago
                    </option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">
                      Turks and Caicos Islands
                    </option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">
                      United Arab Emirates
                    </option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vatican City">Vatican City</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands, British">
                      Virgin Islands, British
                    </option>
                    <option value="Virgin Islands, U.S.">
                      Virgin Islands, U.S.
                    </option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                    <option value="Other">Other</option>
                  </select>
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    Select the name of the country where you were born (even if
                    it no longer exists).
                  </div>
                </div>
                <br />
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  State or Province:
                  <span className="text-red-500">*</span>
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="stOrprov"
                    value={formData.stOrprov}
                    onChange={handleInputChange}
                    maxLength={25}
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    Enter the state or province where you were born.
                  </div>
                </div>
                <br />
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Town, City or Village of Birth:
                  <span className="text-red-500">*</span>
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="townBirth"
                    value={formData.townBirth}
                    onChange={handleInputChange}
                    maxLength={38}
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    Enter the town, city or village where you were born.
                  </div>
                </div>
                <br />
                <br />

                <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />

                <div className="flex flex-col items-right space-y-0 p-4">
                  <span className="flex items-right space-x-10">
                    <h3 className="heading33 ml-16">
                      <b>Sponsor's Birth Information</b>
                    </h3>
                  </span>
                </div>
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Alien Registration Number (A-Number)
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="AlienNum"
                    value={formData.AlienNum}
                    onChange={handleInputChange}
                    placeholder=" A-   ######"
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    If you do not have an A-Number, leave this field empty.{" "}
                    <u>How to find this >></u>
                  </div>
                </div>
                <br />
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  Social Security Number <span className="text-red-500">*</span>
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="SSNum"
                    value={formData.SSNum}
                    onChange={handleInputChange}
                    maxLength={9}
                    placeholder="    ######"
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    A Social Security number is required to file Form I-864.{" "}
                    <u>How to find this >></u>
                  </div>
                </div>
                <br />
                <br />
                <div className="mt-2 font-thin ml-2 text ndRendring">
                  USCIS Online Account Number
                </div>
                <div className="form h-14">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-3 ml-3"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    required
                    name="AccNum"
                    value={formData.AccNum}
                    onChange={handleInputChange}
                    placeholder="    #########"
                    required="required"
                    maxLength={12}
                  />
                  <div
                    style={{ backgroundColor: "#E7E7E7", color: "#888" }}
                    className="mt-2"
                  >
                    If you do not have a USCIS online account number or do not
                    know, leave this field empty.<u>How to find this >></u>
                  </div>
                </div>
              </div>
            ) : null}
            {formData.countryCitizen != "United States" ? (
              <div className="bg-red-200 error">
                <div className="icon-and-message p-1">
                  <span className="icon">
                    <img src={warn} alt="" width="42" height="32" />
                  </span>
                  <p className="message ml-2">
                    <span className="font-bold">
                      We found a problem. Based on your answer, you cannot
                      continue.{" "}
                    </span>
                    In order to file Form I-864, Affidavit of Support, you must
                    be domiciled in the United States or provide proof that you
                    are reestablishing domicile in the U.S.{" "}
                    <u>Explain more >></u>
                  </p>
                </div>
                <br />
                <div className="icon-and-message">
                  <span className="icon">
                    <img src={Searchicon} alt="" width="32" height="32" />
                  </span>
                  <p className="message ml-2">
                    <u>Help me find an immigration attorney</u>
                  </p>
                </div>
              </div>
            ) : null}
            {/* //------------------- */}
            <br />
            <br /> <br />
            <div className="w-full ml-8">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded mt-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20"
                onClick={()=>navigate("/I8642")}
              >
                NEXT →
              </button>
            </div>
          </form>
        </div>
        <FormFotter />
      </div>
    </div>
  );
};

export default I8643;
