import React, { useState } from 'react';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import SignUp from './SignUp';
import ForgetPassword from './ForgetPassword';
import axiosRequest from '../utils/axiosRequest';
import { toast } from 'react-toastify';

const Login = ({ isOpen, onClose }) => {




  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [currentForm, setCurrentForm] = useState('login');


  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSwitchToSignUp = () => {
    setCurrentForm('signup');
  };

  const handleSwitchToForgotPassword = () => {
    setCurrentForm('forgotPassword');
  };

 
    // Add your login logi
    
  //   const handleSubmit = async (e) => {

  //     e.preventDefault()

  //     const FormData = {
  //       email,
  //       password
  //     };
    
      
  //      try {
     
  //       const response = await axiosRequest.post('/login', FormData);
      
  //       console.log('Login response:', response.data);
  //       console.log('Login submitted with:', { email, password });
    
     
  //       onClose();
  //     } catch (error) {
  //       console.error('Login error:', error);
      
  //     }
  
   
    
  //   onClose();


  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axiosRequest.post("/login", {
        "email": email,
        "password": password
      });
  
      // Assuming your response data is an object that you want to store in localStorage
      // You can convert it to a string using JSON.stringify and store it
      localStorage.setItem("response", JSON.stringify(response.data));
  
      console.log(response);
      toast.success('Login successful', { autoClose: 3000 });
    } catch (error) {
      console.log(error);
      toast.error('Invalid credentials', { autoClose: 3000 });
    }
    onClose();
  };

 

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50 p-auto">
      <div className="bg-white rounded-lg w-[500px]" style={{ height: '400px' }}>
        <h2 className="p-4 flex bg-blue-900 text-white">Account Login</h2>

        {currentForm === 'signup' && (
          <SignUp isOpen={isOpen} onClose={() => setCurrentForm('login')} />
        )}

        {currentForm === 'forgotPassword' && (
          <ForgetPassword onClose={() => setCurrentForm('login')} />
        )}

        {currentForm === 'login' && (
          <form onSubmit= { handleSubmit} className="p-2">
            <div className="mb-4">
              <label htmlFor="email" className="block text-md text-gray-600 mt-2">
                Email:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                required
              />
            </div>

            <div className="relative mb-4">
              <label htmlFor="password" className="block text-md text-gray-600 mt-2">
                Password:
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                className="border border-slate-400 w-full hover:border-2 bg-white hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-1 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 flex items-center top-5 right-2 text-gray-500"
                onClick={handleTogglePassword}
              >
                {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
              </button>
            </div>

            <div className="ml-2">
              <p className="text-blue-700">
                <span
                  className="cursor-pointer text-blue-500"
                  onClick={handleSwitchToSignUp}
                >
                  Create an account
                </span>
              </p>
              <p className="cursor-pointer text-blue-500" onClick={handleSwitchToForgotPassword}>
                Forgot Password
              </p>
            </div>

            <div className="flex gap-2 mt-20 ">
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ml-auto"
              >
                Login
              </button>
              <button
                onClick={onClose}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
              >
                Close
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
