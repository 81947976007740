// FormContext.js
import { createContext, useContext, useReducer, useState } from 'react';

// Define initial state
const initialState = {
  FormOne: {
    Ischecked: false,
    IsnoChecked: false,
    Istwochecked: false,
    IsnotwoChecked: false,
  },
  FormTwo: {
    checkedOption: 'no',
    streetandnumber: '',
    StateOrTown: 'no',
    apartmentNumber: 'no',
    number:"",
    zip: '',
    cityortown: '',
    checkedOptiontwo: 'no',
    streetandnumbertwo: '',
    StateOrTowntwo: 'no',
    apartmentNumbertwo: 'no',
    numbertwo:"",
    ziptwo: '',
    cityortowntwo: '',
    isChecked: null,
  },
  FormThree: {
    checkedOptionthree: 'no',
    threestreetandnumber: '',
    StateOrTownthree: 'no',
    apartmentNumberthree: 'no',
    numberthree:"",
    zipthree: '',
    cityortownthree: '',
  },
   FormFour : {
    givenName: '',
    middleName: '',
    familyName: '',
    dob: '', // Date of Birth
    country: '', // Country of Citizenship
    status: '', // I am in the United States as a
    ANumber: '', // Alien Registration Number (A-Number)
  }
  
};

// Create context
const FormContext = createContext();

// Define the form reducer
const formReducer = (state, action) => {
  const { formType, type, payload } = action;
  return {
    ...state,
    [formType]: {
      ...state[formType],
      [type]: payload,
    },
  };
};

// Create FormProvider component
const FormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialState);

  const setFormState = (formType, type, value) => dispatch({ formType, type, payload: value });

  const contextValue = {
    state,
    setFormState,
  };

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
};

// Create a hook to use the context
const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

export { FormProvider, useFormContext };
