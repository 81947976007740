




import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/Context131A';

const TravelInformation131A = () => {
    const navigate =useNavigate()
    const {  formData, handleInputChange, code} = useFormContext();

    const handleSubmit = (e) => {
      e.preventDefault();
       navigate("/finaldetail131A")
    };
  
    return (
      <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
                Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
              First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
            </span>
          </div>
          <form action=" " className='mt-8 w-11/12' onSubmit={handleSubmit}>
               

               <div>
              <h2 className='text-blue-700 text-xl font-semibold'>Current Travel Details</h2>
              <p className='text-sm'>Provide your full legal name. Your legal name is most likely the name on your passport unless it has been changed by a legal action such as a marriage or court order. Do not provide a nickname. Spell out middle names completely and do not use just the initial. If you do not have a middle name, you may leave this field blank.</p>
              </div>
               

              <div>
      <div className='mt-10 '>
        <label htmlFor="departDate" className="flex bg-blue-600 text-white p-4 rounded">When did you depart the United States?</label>
     <div className='flex bg-blue-100 p-4 rounded'>
        <input
          type="date"
          id="departDate"
          name="departDate"
          value={formData.departDate}
          onChange={handleInputChange}
          className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded ml-6"
        />
      </div>
      </div>

      <div className='mt-10'>
        <label htmlFor="returnDate" className="flex bg-blue-600 p-4 rounded text-white">When do you intend to travel back to the United States?</label>
         <div className='flex p-4 bg-blue-100 rounded'>
        <input
          type="date"
          id="returnDate"
          name="returnDate"
          value={formData.returnDate}
          onChange={handleInputChange}
          className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded ml-6"
        />
        </div>
      </div>
    <hr  className='h-1 bg-blue-600 mt-10'/>
      <h1 className='text-blue-600 font-semibold text-xl'>Current Travel Document</h1>

      <div className='mt-20'>
        <label htmlFor="advanceParoleExpirationDate" className="flex bg-blue-600 text-white p-4">What is the expiration date on your Advance Parole?</label>
       <div className='flex  bg-blue-100 p-4 rounded'>
        <input
          type="date"
          id="advanceParoleExpirationDate"
          name="advanceParoleExpirationDate"
          value={formData.advanceParoleExpirationDate}
          onChange={handleInputChange}
          className="w-[300px] border border-gray-300 hover:border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded ml-6"
        />
        </div>
      </div>
    </div>
     <p className='bg-slate-200 p-2'>Find the expiration date on the front of your Advance Parole document or EAD (if issued a card).</p>
                 
     <div className='mt-10 gap-2'>
  <label htmlFor="formI131ReceiptNumber" className="flex bg-blue-600 text-white p-4">
    Form I-131 Receipt Number:
  </label>
  <div className='flex bg-blue-100 p-4 rounded'>
    <input
      type="text"
      id="formI131ReceiptNumber"
      name="formI131ReceiptNumber"
      value={formData.formI131ReceiptNumber}
      onChange={handleInputChange}
      className="w-[300px] border border-gray-300 hover:border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded ml-6"
    />
  </div>
</div>
<p className='bg-slate-200 p-2'>Provide the receipt number from the I-131 (Application for Travel Document) associated with the lost, stolen or damaged Advance Parole.</p>
  
            <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
              <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
                <button
                  type="submit"
                  className='text-white rounded'
                >
                  Next
                </button>
                <BsArrowRight />
              </div>
            </div>
            <div className='flex justify-end mt-2'>
              <span>or <span className='text-blue-500'>Save Your progress?</span></span>
            </div>
          </form>
          <FormFotter/>
        </div>
        
      </div>
    )
}

export default TravelInformation131A