import React from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { FormProvider } from "./Formcontext.js"; // Import your context
import { useState } from "react";
import { useEffect } from "react";

import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import stateData from "./ports";
import form from "../../Assets/icon.png";
import "./style.css";
import { useNavigate } from "react-router-dom";

const I903r = () => {
  const navigate=useNavigate()
  const [formData, setFormData] = useState({
    dob: "",
    city: "",
    cityfocused: false,
    countryOfBirth: "",
    countries: [],
    mothername: "",
    mothernamefocused: false,
    fathername: "",
    fathernamefocused: false,
    sex: "",
    Ethnicity: "",
    raceWhite: false,
    raceAsian: false,
    raceBlack: false,
    raceNative: false,
    racePacificIslander: false,
    P3_9_ft: "",
    P3_9_in: "",
    Weight: "",
    EyeColor: "",
    HairColor: "",
  });

  const [errors, setErrors] = useState({
    dob: "",
    city: "",
    countryOfBirth: "",
    mothername: "",
    fathername: "",
    sex: "",
    Ethnicity: "",
    Race: "",
    Height: "",
    Weight: "",
    EyeColor: "",
    HairColor: "",
  });
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked, // Update the checkbox state
      });
    } else {
      setFormData({
        ...formData,
        [name]: value, // Update other input fields
      });
    }
  };

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        // assuming data is an array of country objects
        setFormData((prevState) => ({
          ...prevState,
          countries: data.map((country) => country.name.common), // assuming 'name.common' is the country name field you want to use
        }));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = {
      dob: "",
      city: "",
    };

    // Check if there are no errors before submitting
    if (Object.values(newErrors).every((error) => error === "")) {
      console.log("Form Data:", formData);
    }
  };

  return (
    <div className="w-full">
      <Header />
      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            Application to Replace Permanent Resident Card
          </span>
          <div className="h-full flex justify-end">
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>
        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2 style={{ marginBottom: "-1%" }}>You're almost halfway there.</h2>
          <span className="txttop" style={{ color: "#888", marginLeft: "1%" }}>
            We have a few more questions about you.
          </span>
        </div>
        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
          <div className="flex justify-between items-center">
            <span className="mt-4 font-bold text-xl ml-2  section">
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Birth Information
            </span>
          </div>
          <form onSubmit={handleSubmit} className="mt-6 w-full ">
            <div className="forms">
              <div className="flex flex-col space-y-5 ml-4 mt-0">
                <div className="flex items-center space-x-10">
                  <label htmlFor="dob1" className="mr-14">
                    Date of Birth: <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="dob1"
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    className="py-1 px-2 rounded"
                  />
                </div>
              </div>
              <br />
              <span className="flex items-center space-x-10">
                <h3 className="heading33">Place of Birth</h3>
              </span>

              <div className="flex items-center space-x-10">
                <p className="ptag" style={{}}>
                  Enter your city, town or village of birth. Select the country.
                </p>
              </div>
              {/* ------------- */}
              <br />

              <div className="flex flex-col space-y-5 ml-0 mt-0">
                <div className="flex items-center space-x-10">
                  &nbsp; &nbsp;{" "}
                  <label htmlFor="city" className="mr-3">
                    City, Town or Village
                    <span className="text-red-500">*</span>
                  </label>
                  <input
                    id="city"
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="py-1 px-2 rounded "
                    style={{ width: "30%" }}
                    maxLength={38}
                    onFocus={() =>
                      setFormData({ ...formData, cityfocused: true })
                    }
                    onBlur={() =>
                      setFormData({ ...formData, cityfocused: false })
                    }
                  />
                  &nbsp;
                  {formData.cityfocused &&
                    ` ${38 - formData.city.length} characters remaining`}
                  {errors.city && (
                    <div className="text-red-500 text-center ">
                      City is Required
                    </div>
                  )}
                </div>
                <div className="flex items-center space-x-14">
                  &nbsp; &nbsp;{" "}
                  <label htmlFor="country" className="mr-6">
                    Country of Birth
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="countryOfBirth"
                    className="py-1 px-2 rounded"
                    id="country"
                    value={formData.countryOfBirth}
                    onChange={handleInputChange}
                  >
                    <option value="">Please Select ..</option>
                    {formData.countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  {formData.countryOfBirth === "" ? (
                    <div className="text-red-500 text-center "></div>
                  ) : null}
                  {errors.city && (
                    <div className="text-red-500 text-center ">
                      Please Select City
                    </div>
                  )}
                </div>

                <br />
                <div className="flex flex-col items-right space-y-0">
                  <span className="flex items-right space-x-10">
                    <h3 className="heading33">Parents' Names</h3>
                  </span>
                  <div className="text-left">
                    <p className="ptag">
                      Enter your mother and father's <u>first name only</u>.
                      Applicants who do not know may enter "Unknown."
                    </p>
                  </div>
                </div>
                <br />

                <div className="flex flex-col space-y-5 ml-0 mt-0">
                  <div className="flex items-center space-x-10">
                    &nbsp; &nbsp;{" "}
                    <label htmlFor="mothername" className="mr-3">
                      Mother's Given Name (first name)
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      id="mothername"
                      type="text"
                      name="mothername"
                      value={formData.mothername}
                      onChange={handleInputChange}
                      className="py-1 px-2 rounded"
                      style={{ width: "30%" }}
                      maxLength={18}
                      onFocus={() =>
                        setFormData({ ...formData, mothernamefocused: true })
                      }
                      onBlur={() =>
                        setFormData({ ...formData, mothernamefocused: false })
                      }
                    />
                    &nbsp;
                    {formData.mothernamefocused &&
                      ` ${
                        18 - formData.mothername.length
                      } characters remaining`}
                  </div>
                  <div className="flex items-center space-x-10">
                    &nbsp; &nbsp;{" "}
                    <label htmlFor="fathername" className="mr-3">
                      Father's Given Name (first name)
                      <span className="text-red-500">*</span>&nbsp;&nbsp;
                    </label>
                    <input
                      id="fathername"
                      type="text"
                      name="fathername"
                      value={formData.fathername}
                      onChange={handleInputChange}
                      className="py-1 px-2 rounded"
                      style={{ width: "30%" }}
                      maxLength={18}
                      onFocus={() =>
                        setFormData({ ...formData, fathernamefocused: true })
                      }
                      onBlur={() =>
                        setFormData({ ...formData, fathernamefocused: false })
                      }
                    />
                    &nbsp;
                    {formData.fathernamefocused &&
                      ` ${
                        18 - formData.fathername.length
                      } characters remaining`}
                  </div>
                </div>
              </div>
            </div>

            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <div className="flex justify-between items-center">
              <span className="mt-4 font-bold text-xl ml-2 section">
                &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;Biographic Information
              </span>
            </div>
            <div className="additional-div" style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Sex
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="sex"
                  value="male"
                  id="male"
                  checked={formData.sex === "male"}
                  onChange={handleInputChange}
                />
                <label htmlFor="male">Male</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="sex"
                  value="female"
                  id="Female"
                  checked={formData.sex === "female"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Female">Female</label>
              </div>
            </div>

            <div className="additional-div " style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Ethnicity
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="Ethnicity"
                  value="Hispanic or Latino"
                  id="Hispanic"
                  checked={formData.Ethnicity === "Hispanic or Latino"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Hispanic">Hispanic or Latino</label> &nbsp;
                &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="Ethnicity"
                  value="Not Hispanic or Latino"
                  id="NotHispanic"
                  checked={formData.Ethnicity === "Not Hispanic or Latino"}
                  onChange={handleInputChange}
                />
                <label htmlFor="NotHispanic">Not Hispanic or Latino</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  If you are Hispanic or Latino you are still required to select
                  a race below. Explain more {">>"}
                </div>
              </div>
            </div>
            <div className="additional-div" style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Race <i>(select all applicable boxes)</i>
                <span className="text-red-500">*</span>
              </div>
              <div className="form p-3">
                <label>
                  <input
                    type="checkbox"
                    name="raceWhite"
                    checked={formData.raceWhite}
                    onChange={handleInputChange}
                  />
                  White
                </label>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="checkbox"
                    name="raceAsian"
                    checked={formData.raceAsian}
                    onChange={handleInputChange}
                  />
                  Asian
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="checkbox"
                    name="raceBlack"
                    checked={formData.raceBlack}
                    onChange={handleInputChange}
                  />
                  Black or African American
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type="checkbox"
                    name="raceNative"
                    checked={formData.raceNative}
                    onChange={handleInputChange}
                  />
                  American Indian or Alaska Native
                </label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <br />{" "}
                <label>
                  <input
                    type="checkbox"
                    name="racePacificIslander"
                    checked={formData.racePacificIslander}
                    onChange={handleInputChange}
                  />
                  Native Hawaiian or Other Pacific Islander
                </label>
              </div>
              <div
                style={{
                  backgroundColor: "#E7E7E7",
                  color: "#888",
                  width: "90%",
                  marginLeft: "6%",
                }}
              >
                Select the option that best describes the natural color of your
                eyes.
              </div>
            </div>
            <div className="additional-div" style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Height
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <div className="input-wrapper p-2">
                  <button type="button" className="btn btn-link float-right">
                    Help me convert my metric height.
                  </button>
                  <div className="field-container required field-size-xs inline-block mr-5">
                    <div className="input-group">
                      <select
                        id="P3_9_ft"
                        name="P3_9_ft"
                        className="form-control py-2 px-3 text-right w-40 h-25"
                        aria-required="true"
                        required="required"
                        value={formData.P3_9_ft}
                        onChange={handleInputChange}
                      >
                        <option value="">...</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </select>
                      <div className="input-group-append">
                        <label
                          className="input-group-text cursor-pointer p-2"
                          htmlFor="P3_9_ft"
                          id="P3_9_ft-suffix"
                          style={{ color: "black" }}
                        >
                          feet
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="field-container required field-size-xls inline-block">
                    <div className="input-group">
                      <select
                        id="P3_9_in"
                        name="P3_9_in"
                        className="form-control py-2 px-3 text-right w-40 h-25"
                        aria-required="true"
                        required="required"
                        value={formData.P3_9_in}
                        onChange={handleInputChange}
                      >
                        <option value="">..</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                      </select>
                      <div className="input-group-append">
                        <label
                          className="input-group-text cursor-pointer p-2 "
                          htmlFor="P3_9_in"
                          id="P3_9_in-suffix"
                          style={{ color: "black" }}
                        >
                          inches
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="additional-div" style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Weight
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <div className="field-container required inline-block">
                  <div className="input-group p-2">
                    <input
                      type="number"
                      id="weight1"
                      name="Weight"
                      className="form-control py-2 px-3 text-right w-20 h-25"
                      aria-required="true"
                      required="required"
                      value={formData.Weight}
                      onChange={handleInputChange}
                      min="30"
                      placeholder="30"
                    />
                    <div className="input-group-append">
                      <label
                        className="input-group-text cursor-pointer p-2"
                        htmlFor="weight"
                        id="weight1"
                        style={{ color: "black" }}
                      >
                        lbs
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link float-right"
                      style={{ marginLeft: "30%", textDecoration: "none" }}
                    >
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    </button>
                  </div>
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{
                      marginLeft: "58%",
                      position: "absolute",
                      marginTop: "-3%",
                    }}
                  >
                    Help me convert my metric height.
                  </button>
                </div>
              </div>
            </div>

            <div className="additional-div" style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Eye Color
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="EyeColor"
                  value="Black"
                  id="Black"
                  checked={formData.EyeColor === "Black"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Black">Black</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Blue"
                  id="Blue"
                  checked={formData.EyeColor === "Blue"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Blue">Blue</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Brown"
                  id="Brown"
                  checked={formData.EyeColor === "Brown"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Brown">Brown</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Gray"
                  id="Gray"
                  checked={formData.EyeColor === "Gray"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Gray">Gray</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Green"
                  id="Green"
                  checked={formData.EyeColor === "Green"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Green">Green</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Hazel"
                  id="Hazel"
                  checked={formData.EyeColor === "Hazel"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Hazel">Hazel</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Maroon"
                  id="Maroon"
                  checked={formData.EyeColor === "Maroon"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Maroon">Maroon</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Pink"
                  id="Pink"
                  checked={formData.EyeColor === "Pink"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Pink">Pink</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="EyeColor"
                  value="Unknown/Other"
                  id="UnknownOther"
                  checked={formData.EyeColor === "Unknown/Other"}
                  onChange={handleInputChange}
                />
                <label htmlFor="UnknownOther">Unknown/Other</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  Select the option that best describes the natural color of
                  your eyes.
                </div>
              </div>
            </div>
            <div className="additional-div" style={{ marginLeft: "-1%" }}>
              <div className="text-white font-bold p-2 ndRendring text">
                Hair Color
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="HairColor"
                  value="Bald (no hair)"
                  id="Bald"
                  checked={formData.HairColor === "Bald (no hair)"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Bald">Bald (no hair)</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Black"
                  id="Black"
                  checked={formData.HairColor === "Black"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Black">Black</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Blond"
                  id="Blond"
                  checked={formData.HairColor === "Blond"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Blond">Blond</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Brown"
                  id="Brown"
                  checked={formData.HairColor === "Brown"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Brown">Brown</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Gray"
                  id="Gray"
                  checked={formData.HairColor === "Gray"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Gray">Gray</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Red"
                  id="Red"
                  checked={formData.HairColor === "Red"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Red">Red</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Sandy"
                  id="Sandy"
                  checked={formData.HairColor === "Sandy"}
                  onChange={handleInputChange}
                />
                <label htmlFor="Sandy">Sandy</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="White"
                  id="White"
                  checked={formData.HairColor === "White"}
                  onChange={handleInputChange}
                />
                <label htmlFor="White">White</label>
                &nbsp; &nbsp;
                <input
                  type="radio"
                  name="HairColor"
                  value="Unknown/Other"
                  id="UnknownOther"
                  checked={formData.HairColor === "Unknown/Other"}
                  onChange={handleInputChange}
                />
                <label htmlFor="UnknownOther">Unknown/Other</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                  <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                    Select the option that best describes the natural color of
                    your eyes.
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="w-full">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded m-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-4"
                onClick={()=>navigate("/I9014")}
              >
                NEXT →
              </button>
            </div>
          </form>
        </div>
        <FormFotter />
      </div>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
        integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
        crossorigin="anonymous"
      />
    </div>
  );
};

export default I903r;
