import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContext864 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContext864);
}

// Define the initial state
const initialState = {
    citizenshipOrResidency: '', // Select your citizenship or residency
    basisForAffidavit: '', // Select the basis for preparing this affidavit of support
    relationshipToIntendingImmigrant: '', // Select the intending immigrant's relationship
    isAtLeast18: '', // Are you at least 18 years old?
    servedInUSArmedForces: '', // Have you ever served honorably in the U.S. armed forces?
    hasSSN: '', // Do you have a Social Security Number?
    domiciledInUS: "",
    isAtLeast18YearsOld: '', // Yes or No
    servedInUSArmedForces: '', // Yes or No
    hasSSN: '', // Yes or No
    isDomiciledInUS: '', 
    isUSNational: '', // Yes or No
    intendingImmigrantRelationship: '',
    jointSponsorCount: '', 
    petitionerCitizenship: '', // Initial state for petitionerCitizenship
  intendingImmigrantRelationship: '',
  intendingImmigrantRelationshipform2: '',
  streetNumberAndName: '', // Global state for Street Number and Name
  cityOrTown: '', // Global state for City or Town
  zipCode: "",
  country:"" ,
  state :"" ,
  apartment: '',
  countryOfDomicile: '',
  DayTimePhoneNumber:"",
  MobilePhoneNumber:"",
  Pnumber:"",
  Mnumber:"",
  email:"",
  dateOfBirth:"",
  countryofbirth:"",
  stateOrProvince: '',
  birthPlace: '',
  alienRegistrationNumber: '',
  socialSecurityNumber: '',
  uscisOnlineAccountNumber: '',
  principalImmigrantGivenName: '',
  principalImmigrantMiddleName: '',
  principalImmigrantFamilyName: '',
  principalImmigrantInCareOfName: '',
  principalImmigrantStreet: '',
  principalImmigrantCity: '',
  principalImmigrantCountry:"",
  Number: '',
  principalImmigrantapartment:"No",
  countryOfCitizenship:"",
  dateOfBirth:"",
  immigrateMethod: '',
  otherInformationdaytimePhoneNumber: '',
  PHnumber:"",
  otherInformationAlienRegistrationNumber: '', // Updated variable name
  otherInformationSocialSecurityNumber: '', // Updated variable name
  otherInformationUscisOnlineAccountNumber: '',
  sponsoringAdditionalFamilyMembers: 'No',
  immigratingAtTheSameTime: 'No',
  numberOfAdditionalFamilyMembers: 'None',
  employmentStatus: '',
  employerName: '', // For "Name of Employer" input
  occupation: '', // For "Occupation" input
  hasSecondJob: '',
  secondJobStatus: '', // For radio buttons
  employerName2: '', // For "Name of Employer 2" input
  totalIncome: '', // For "My total current individual income is" input
  nonTaxableIncome: '',
  dependentChildrenCountOptions: [],
  additionaldependents :[],
  sponsoredOtherPersons: '',
  householdMembersIncomeCombination: '',
  usingJointSponsor: '',
  filedIncomeTaxReturn: '', // Have you filed a Federal income tax return for each of the three most recent tax years? (Yes/No)
  requiredToFileIncomeTaxReturn: '', 

    mostRecentTaxryear: '', // Select the year (2023, 2024, etc.)
    mostRecentTaxYearincome: '', // Income ($)
  

  // 2nd Most Recent Tax Year

    secondMostRecentTaxryear: '', // Select the year (2023, 2024, etc.)
    secondMostRecentTaxYearincome: '', // Income ($)
  

  // 3rd Most Recent Tax Year
 
    thirdMostRecentTaxyear: '', // Select the year (2023, 2024, etc.)
    thirdMostRecentTaxincome: '', // Income ($)
  

  
  };

// Create a provider component to manage the state
export function FormProviderFor864({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchDependentChildrenOptions = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        // Extract the options you need from the API data
        const dependentChildrenOptions = data.map((country, index) => ({
          value: index + 1, // You can adjust the value as needed
          label: `${index + 1}`, // Label for the select option
        }));

        // Set the options in the form data state
        setFormData((prevData) => ({
          ...prevData,
          dependentChildrenCountOptions: dependentChildrenOptions,
        }));
      } catch (error) {
        console.error('Error fetching dependent children options:', error);
      }
    };

    fetchDependentChildrenOptions();
  }, []);

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text':
        case 'number':
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        case 'select-one':
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      }
    });
  };
  const mapFormFields = () => {
    let mapping = {};

    // Map the values for the provided fields in Form1 and Form2 to the given PDF fields
    mapping["form1[0].#subform[0].P1_Line1c_Checkbox[0]"] = true;
    mapping["form1[0].#subform[0].P1_Line1d_Checkbox[0]"] = false;
    mapping["form1[0].#subform[0].P1_Line1e_Checkbox[0]"] = true;
    mapping["form1[0].#subform[0].P1_Line1f_Checkbox[0]"] = false;
    mapping["form1[0].#subform[0].P1_Line1e1_Checkbox[0]"] = true;
    mapping["form1[0].#subform[0].P1_Line1e1_Checkbox[1]"] = false;
    mapping["form1[0].#subform[0].P1_Line1b_Checkbox[0]"] = true;
    mapping["form1[0].#subform[0].P1_Line1a_Checkbox[0]"] = false;
    mapping["form1[0].#subform[0].P1_Line1_Name[0]"] = "John Doe";
    mapping["form1[0].#subform[0].P1_Line1b_Relationship[0]"] = "Friend";
    mapping["form1[0].#subform[0].P1_Line1c_InterestIn[0]"] = "Traveling";
    mapping["form1[0].#subform[0].P1_Line1c_Relationship[0]"] = "Colleague";
    mapping["form1[0].#subform[0].P1_Line1f_Relationship[0]"] = "Business Partner";
    mapping["form1[0].#subform[0].P2_Line2_CityOrTown[0]"] = "Cityville";
    mapping["form1[0].#subform[0].P2_Line2_StreetNumberName[0]"] = "123 Main St";
    mapping["form1[0].#subform[0].P2_Line2_AptSteFlrNumber[0]"] = "Apt 45";
    mapping["form1[0].#subform[0].P2_Line2_PostalCode[0]"] = "12345-6789";
    mapping["form1[0].#subform[0].P2_Line2_ZipCode[0]"] = "54321";
    mapping["form1[0].#subform[0].P2_Line2_State[0]"] = "California";
    mapping["form1[0].#subform[0].P2_Line2_Country[0]"] = "USA";
    mapping["form1[0].#subform[0].P2_Line2_Province[0]"] = "CA";
    mapping["form1[0].#subform[0].P2_Line2_Unit[0]"] = true;
    mapping["form1[0].#subform[0].P2_Line2_Unit[1]"] = false;
    mapping["form1[0].#subform[0].P2_Line2_Unit[2]"] = true;
    mapping["form1[0].#subform[0].P2_Line2_InCareOf[0]"] = "Jane Doe";
    mapping["form1[0].#subform[0].AttorneyStateBarNumber[0]"] = "ABC123456";
    mapping["form1[0].#subform[0].USCISOnlineAcctNumber[0]"] = "USCIS789012";
    mapping["form1[0].#subform[0].G28-CheckBox1[0]"] = true;
    mapping["form1[0].#subform[0].P2_Line1b_GivenName[0]"] = "Alice";
    mapping["form1[0].#subform[0].P2_Line1c_MiddleName[0]"] = "Marie";
    mapping["form1[0].#subform[0].P2_Line1a_FamilyName[0]"] = "Smith";
    mapping["form1[0].#subform[0].#area[1].P2_Line5_AlienNumber[0]"] = "A98765432";
    mapping["form1[0].#subform[0].#area[2].Pt2Line6_USCISOnlineAcctNumber[0]"] = "USCIS654321";
    mapping["form1[0].#subform[0].P2_Line4_DateOfBirth[0]"] = "1980-05-15";
    mapping["form1[0].#subform[0].P2_Line3_CountryOfCitenship[0]"] = "USA";
    mapping["form1[0].#subform[0].P2_Line7_DaytimeTelephoneNumber[0]"] = "1234567890";
    mapping["form1[0].#subform[0].PDF417BarCode1[0]"] = "PDF417123456";
    mapping["form1[0].#subform[1].P3_Line2_SponsoringFamily[0]"] = false;
    mapping["form1[0].#subform[1].P3_Line1_Checkbox[0]"] = true;
    mapping["form1[0].#subform[1].P3_Line1_Checkbox[1]"] = false;
    mapping["form1[0].#subform[1].P3_Line3a_FamilyName[0]"] = "Johnson";
    mapping["form1[0].#subform[1].P3_Line3b_GivenName[0]"] = "Emma";
    mapping["form1[0].#subform[1].P3_Line3c_MiddleName[0]"] = "Grace";
    mapping["form1[0].#subform[1].P3_Line4_Relationship[0]"] = "Spouse";
    mapping["form1[0].#subform[1].P3_Line_DateOfBirth[0]"] = "1990-08-22";
    mapping["form1[0].#subform[1].#area[4].P3_Line7_AcctIdentifier[0]"] = "ACCT789012";
    mapping["form1[0].#subform[1].P3_Line8c_MiddleName[0]"] = "Grace";
    mapping["form1[0].#subform[1].P3_Line8b_GivenName[0]"] = "Michael";
    mapping["form1[0].#subform[1].P3_Line8a_FamilyName[0]"] = "Taylor";
    mapping["form1[0].#subform[1].P3_Line9_Relationship[0]"] = "Friend";
    mapping["form1[0].#subform[1].P3_Line10_DateOfBirth[0]"] = "1985-12-10"
    mapping["form1[0].#subform[2].P4_Line1_Checkbox[0]"] = true;
      mapping["form1[0].#subform[2].P4_Line1_Checkbox[1]"] = false;
      mapping["form1[0].#subform[2].P4_Line2_FamilyName[0]"] = "Brown";
      mapping["form1[0].#subform[2].P4_Line3_GivenName[0]"] = "David";
      mapping["form1[0].#subform[2].P4_Line4_MiddleName[0]"] = "Lee";
      mapping["form1[0].#subform[2].P4_Line5_Relationship[0]"] = "Brother";
      mapping["form1[0].#subform[2].P4_Line6_DateOfBirth[0]"] = "1982-07-20";
      mapping["form1[0].#subform[2].P4_Line7_AlienNumber[0]"] = "A76543210";
      mapping["form1[0].#subform[3].Pt4_Line2_SponsoringFamily[0]"] = false;
      mapping["form1[0].#subform[3].Pt4_Line1_Checkbox[0]"] = true;
      mapping["form1[0].#subform[3].Pt4_Line1_Checkbox[1]"] = false;
      mapping["form1[0].#subform[3].Pt4_Line3a_FamilyName[0]"] = "Miller";
      mapping["form1[0].#subform[3].Pt4_Line3b_GivenName[0]"] = "Jennifer";
      mapping["form1[0].#subform[3].Pt4_Line3c_MiddleName[0]"] = "Anne";
      mapping["form1[0].#subform[3].Pt4_Line4_Relationship[0]"] = "Sister";
      mapping["form1[0].#subform[3].Pt4_Line5_DateOfBirth[0]"] = "1990-03-15";
      mapping["form1[0].#subform[3].Pt4_Line6_AlienNumber[0]"] = "A87654321";
      mapping["form1[0].#subform[3].#area[4].Pt4_Line7_AcctIdentifier[0]"] = "ACCT654321";

      mapping["form1[0].#subform[1].#area[5].P3_Line11_AlienNumber[0]"] = "A87654321";
      mapping["form1[0].#subform[1].#area[6].P3_Line12_AcctIdentifier[0]"] = "ACCT876543";
      mapping["form1[0].#subform[1].P3_Line13a_FamilyName[0]"] = "Miller";
      mapping["form1[0].#subform[1].P3_Line13b_GivenName[0]"] = "Sophia";
      mapping["form1[0].#subform[1].P3_Line13c_MiddleName[0]"] = "Rose";
      mapping["form1[0].#subform[1].P3_Line14_Relationship[0]"] = "Colleague";
      mapping["form1[0].#subform[1].P3_Line15_DateOfBirth[0]"] = "1992-07-25";
      mapping["form1[0].#subform[1].#area[8].P3_Line17_AcctIdentifier[0]"] = "ACCT987654";
      mapping["form1[0].#subform[1].P3_Line18c_MiddleName[0]"] = "James";
      mapping["form1[0].#subform[1].P3_Line18b_GivenName[0]"] = "Olivia";
      mapping["form1[0].#subform[1].P3_Line18a_FamilyName[0]"] = "Anderson";
      mapping["form1[0].#subform[1].P3_Line19_Relationship[0]"] = "Neighbor";
      mapping["form1[0].#subform[1].#area[9].P3_Line21_AlienNumber[0]"] = "A76543210";
      mapping["form1[0].#subform[1].P3_Line20_DateOfBirth[0]"] = "1988-04-18";
      mapping["form1[0].#subform[1].#area[10].P3_Line22_AcctIdentifier[0]"] = "ACCT765432";
      mapping["form1[0].#subform[1].P3_Line23b_GivenName[0]"] = "Ethan";
      mapping["form1[0].#subform[1].P3_Line23a_FamilyName[0]"] = "Johnson";
      mapping["form1[0].#subform[1].P3_Line23c_MiddleName[0]"] = "Alexander";
      mapping["form1[0].#subform[1].P3_Line24_Relationship[0]"] = "Cousin";
      mapping["form1[0].#subform[1].#area[11].P3_Line26_AlienNumber[0]"] = "A65432109";
      mapping["form1[0].#subform[1].P3_Line25_DateOfBirth[0]"] = "1995-09-30";
      mapping["form1[0].#subform[1].P2_Line5_AlienNumber[1]"] = "A98765432";
      mapping["form1[0].#subform[1].P2_Line5_AlienNumber[2]"] = "A87654321";
      mapping["form1[0].#subform[1].#area[12].P3_Line27_AcctIdentifier[0]"] = "ACCT87654321";
      mapping["form1[0].#subform[1].P3_Line2_SponsoringFamily[1]"] = true;
      mapping["form1[0].#subform[1].PDF417BarCode1[1]"] = "PDF123456";
      mapping["form1[0].#subform[2].P4_Line1c_MiddleName[0]"] = "Emma";
      mapping["form1[0].#subform[2].P4_Line1b_GivenName[0]"] = "Daniel";
      mapping["form1[0].#subform[2].P4_Line1a_FamilyName[0]"] = "Johnson";
      mapping["form1[0].#subform[2].P4_Line2d_CityOrTown[0]"] = "Springfield";
      mapping["form1[0].#subform[2].P4_Line2b_StreetNumberName[0]"] = "123 Main St";
      mapping["form1[0].#subform[2].P4_Line2c_AptSteFlrNumber[0]"] = "Apt 456";
      mapping["form1[0].#subform[2].P4_Line2h_PostalCode[0]"] = "K8N5W6";
      mapping["form1[0].#subform[2].P4_Line2f_ZipCode[0]"] = "90210";
      mapping["form1[0].#subform[2].P4_Line2e_State[0]"] = "CA";
      mapping["form1[0].#subform[2].P4_Line2i_Country[0]"] = "USA";
      mapping["form1[0].#subform[2].P4_Line2g_Province[0]"] = "Ontario";
      mapping["form1[0].#subform[2].P4_Line2c_Unit[0]"] = true;
      mapping["form1[0].#subform[2].P4_Line2c_Unit[1]"] = false;
      mapping["form1[0].#subform[2].P4_Line2c_Unit[2]"] = true;
      mapping["form1[0].#subform[2].P4_Line2a_InCareOf[0]"] = "John Smith";
      mapping["form1[0].#subform[2].P4_Line3_Checkbox[0]"] = true;
      mapping["form1[0].#subform[2].P4_Line3_Checkbox[1]"] = false;
      mapping["form1[0].#subform[2].P4_Line4c_CityOrTown[0]"] = "Riverside";
      mapping["form1[0].#subform[2].P4_Line4a_StreetNumberName[0]"] = "789 Oak St";
      mapping["form1[0].#subform[2].P4_Line4b_AptSteFlrNumber[0]"] = "Suite 101";
      mapping["form1[0].#subform[2].P4_Line4g_PostalCode[0]"] = "L5V2B4";
      mapping["form1[0].#subform[2].P4_Line4e_ZipCode[0]"] = "12345";
      mapping["form1[0].#subform[2].P4_Line4d_State[0]"] = "California";
      mapping["form1[0].#subform[2].P4_Line4h_Country[0]"] = "USA";
      mapping["form1[0].#subform[2].P4_Line4f_Province[0]"] = "Ontario";
      mapping["form1[0].#subform[2].P4_Line4b_Unit[0]"] = true;
      mapping["form1[0].#subform[2].P4_Line4b_Unit[1]"] = false;
      mapping["form1[0].#subform[2].P4_Line4b_Unit[2]"] = true;
      mapping["form1[0].#subform[2].P4_Line6_DateOfBirth[0]"] = "1980-05-15";
      mapping["form1[0].#subform[2].P4_Line5_CountryOfDomicile[0]"] = "Canada";
      mapping["form1[0].#subform[2].P4_Line7_CityofBirth[0]"] = "New York";
      mapping["form1[0].#subform[2].P4_Line8_StateorProvinceofBirth[0]"] = "New York";
      mapping["form1[0].#subform[2].P4_Line9_CountryOfBirth[0]"] = "USA";
      mapping["form1[0].#subform[2].P4_Line11b_Checkbox[0]"] = true;
      mapping["form1[0].#subform[2].P4_Line11a_Checkbox[0]"] = false;
      mapping["form1[0].#subform[2].P4_Line11c_Checkbox[0]"] = true;
      mapping["form1[0].#subform[2].Line11c_AlienNumberGroup[0].P4_Line12_AlienNumber[0]"] = "A12345678";
      mapping["form1[0].#subform[2].#area[14].P4_Line13_AcctIdentifier[0]"] = "ACCT87654321";
      mapping["form1[0].#subform[2].P4_Line14_Checkboxes[0]"] = true;
      mapping["form1[0].#subform[2].P4_Line14_Checkboxes[1]"] = false;
      mapping["form1[0].#subform[2].P4_Line10_SocialSecurityNumber[0]"] = "123456789";
      mapping["form1[0].#subform[2].P3_Line28_TotalNumberofImmigrants[0]"] = "123";
      mapping["form1[0].#subform[2].PDF417BarCode1[2]"] = "PDF789012";
      mapping["form1[0].#subform[3].P5_Line1_Number[0]"] = "001";
      mapping["form1[0].#subform[3].P5_Line2_Yourself[0]"] = "John Doe";
      mapping["form1[0].#subform[3].P5_Line3_Married[0]"] = "Jane Doe";
      mapping["form1[0].#subform[3].P5_Line4_DependentChildren[0]"] = "2";
      mapping["form1[0].#subform[3].P5_Line5_OtherDependents[0]"] = "3";
      mapping["form1[0].#subform[3].P5_Line6_Sponsors[0]"] = "John Corporation";
      mapping["form1[0].#subform[3].P5_Line7_SameResidence[0]"] = "Yes";
      mapping["form1[0].#subform[3].Override[0]"] = "125";
      mapping["form1[0].#subform[3].P6_Line1a1_NameofEmployer[0]"] = "ABC Company Inc.";
      mapping["form1[0].#subform[3].P6_Line1a_NameofEmployer[0]"] = "XYZ Corporation";
      mapping["form1[0].#subform[3].P6_Line1a2_NameofEmployer[0]"] = "DEF Enterprises";
      mapping["form1[0].#subform[3].P6_Line4a_SelfEmployedAs[0]"] = "Consultant";
      mapping["form1[0].#subform[3].P6_Line3_Name[0]"] = "Jane Smith";
      mapping["form1[0].#subform[3].P6_Line2_TotalIncome[0]"] = "75000";
      mapping["form1[0].#subform[3].P6_Line6a_DateofUnemployment[0]"] = "2022-08-20";
      mapping["form1[0].#subform[3].P6_Line4_Relationship[0]"] = "Spouse";
      mapping["form1[0].#subform[3].P6_Line5_CurrentIncome[0]"] = "45000";
      mapping["form1[0].#subform[3].P6_Line9_Name[0]"] = "David Johnson";
      mapping["form1[0].#subform[3].P6_Line8_CurrentIncome[0]"] = "60000";
      mapping["form1[0].#subform[3].P6_Line6_Name[0]"] = "Mary Davis";
      mapping["form1[0].#subform[3].P6_Line7_Relationship[0]"] = "Child";
      mapping["form1[0].#subform[3].P6_Line10_Relationship[0]"] = "Parent";
      mapping["form1[0].#subform[3].P6_Line11_CurrentIncome[0]"] = "30000";
      mapping["form1[0].#subform[3].P6_Line12_Name[0]"] = "Michael Wilson";
      mapping["form1[0].#subform[3].P6_Line13_Relationship[0]"] = "Sibling";
      mapping["form1[0].#subform[3].P6_Line14_DateofBirth[0]"] = "1982-03-08";
mapping["form1[0].#subform[3].P6_Line15_CountryofCitizenship[0]"] = "USA";
mapping["form1[0].#subform[3].P6_Line16_DateofFiling[0]"] = "2023-11-23";
mapping["form1[0].#subform[3].P6_Line17_FilingLocation[0]"] = "Chicago";
mapping["form1[0].#subform[3].P6_Line18a_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line18b_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line18c_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line19_EmploymentStartDate[0]"] = "2022-05-10";
mapping["form1[0].#subform[3].P6_Line20_EmploymentEndDate[0]"] = "2023-10-15";
mapping["form1[0].#subform[3].P6_Line21a_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line21b_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line21c_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line22_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line23_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line24_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line25_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line26_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line27_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line28_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line29_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line30_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line31_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line32_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line33_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line34_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line35_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line36_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line37_Checkbox[0]"] = true;
mapping["form1[0].#subform[3].P6_Line38_Checkbox[0]"] = false;
mapping["form1[0].#subform[3].P6_Line39_Checkbox[0]"] = true;

   // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[9].P8_Line3_DaytimeTelephoneNumber[0]"] = "1234567890";
mapping["form1[0].#subform[9].P8_Line4_MobileTelephoneNumber[0]"] = "9876543210";
mapping["form1[0].#subform[9].P7Line7_EmailAddress[0]"] = "john.doe@example.com";
mapping["form1[0].#subform[9].P8_Line1_Checkbox[1]"] = true;
mapping["form1[0].#subform[9].PDF417BarCode1[6]"] = "PDF234567";

mapping["form1[0].#subform[20].P9_Line3c_CityOrTown[0]"] = "Cityville";
mapping["form1[0].#subform[20].P9_Line3a_StreetNumberName[0]"] = "123 Main St";
mapping["form1[0].#subform[20].P9_Line3b_Unit[0]"] = true;
mapping["form1[0].#subform[20].P9_Line3b_Unit[1]"] = false;
mapping["form1[0].#subform[20].P9_Line3b_Unit[2]"] = true;
mapping["form1[0].#subform[20].P9_Line3b_AptSteFlrNumber[0]"] = "Apt 456";
mapping["form1[0].#subform[20].P9_Line3f_Province[0]"] = "Provinceville";
mapping["form1[0].#subform[20].P9_Line3e_ZipCode[0]"] = "54321";
mapping["form1[0].#subform[20].P9_Line3d_State[0]"] = "CA";
mapping["form1[0].#subform[20].P9_Line3h_Country[0]"] = "USA";
mapping["form1[0].#subform[20].P9_Line3g_PostalCode[0]"] = "P987654321";
mapping["form1[0].#subform[20].P9_Line5_InterpretersEmailAddress[0]"] = "interpreter@example.com";
mapping["form1[0].#subform[20].P9_Line4_InterpretersDaytimePhoneNumber[0]"] = "1112223333";
mapping["form1[0].#subform[20].P9_Line4_InterpretersDaytimePhoneNumber[1]"] = "4445556666";
mapping["form1[0].#subform[20].P7Line9b_DateofSignature[0]"] = "2023-02-15";
mapping["form1[0].#subform[20].P8_Line9a_ApplicantSignature[0]"] = "Applicant Signature";
mapping["form1[0].#subform[20].P9_Language[0]"] = "English";
mapping["form1[0].#subform[20].P9_Line1b_InterpretersGivenName[0]"] = "Interpreter Given Name";
mapping["form1[0].#subform[20].P9_Line1a_InterpretersFamilyName[0]"] = "Interpreter Family Name";
mapping["form1[0].#subform[20].P9_Line6b_DateofSignature[0]"] = "2023-02-16";
mapping["form1[0].#subform[20].P9_Line6a_InterpretersSignature[0]"] = "I";
mapping["form1[0].#subform[20].P8Line2_InterpretersBusinessName[0]"] = "Interpreter Business";
mapping["form1[0].#subform[20].PDF417BarCode1[7]"] = "PDF876543";

mapping["form1[0].#subform[25].P10_Line2_PreparersBusinessName[0]"] = "Preparer Business";
mapping["form1[0].#subform[25].P10_Line1a_PreparersFamilyName[0]"] = "Preparer Family";
mapping["form1[0].#subform[25].P10_Line1b_PreparersGivenName[0]"] = "Preparer Given";
mapping["form1[0].#subform[25].P10_Line6_PreparersEmailAddress[0]"] = "preparer@example.com";
mapping["form1[0].#subform[25].P10_Line4_PreparersDaytimePhoneNumber[0]"] = "7778889999";
mapping["form1[0].#subform[25].P10_Line5_PreparersFaxNumber[0]"] = "8889990000";
mapping["form1[0].#subform[25].P10_Line3c_CityOrTown[0]"] = "Townsville";
mapping["form1[0].#subform[25].P10_Line3a_StreetNumberName[0]"] = "456 Oak St";
mapping["form1[0].#subform[25].P10_Line3b_Unit[0]"] = true;
mapping["form1[0].#subform[25].P10_Line3b_Unit[1]"] = false;
mapping["form1[0].#subform[25].P10_Line3b_AptSteFlrNumber[0]"] = "Apt 789";
mapping["form1[0].#subform[25].P10_Line3b_Unit[2]"] = true;
mapping["form1[0].#subform[25].P10_Line3e_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[25].P10_Line3d_State[0]"] = "NY";
mapping["form1[0].#subform[25].P10_Line3g_PostalCode[0]"] = "P543210987";
mapping["form1[0].#subform[25].P10_Line3h_Country[0]"] = "USA";
mapping["form1[0].#subform[25].P10_Line3f_Province[0]"] = "California";
mapping["form1[0].#subform[25].P10_Line7_checkbox[0]"] = true;
mapping["form1[0].#subform[25].P10_Line7_checkbox[1]"] = false;
mapping["form1[0].#subform[25].P10_Line7_Extend[0]"] = true;
mapping["form1[0].#subform[25].P10_Line7_Extend[1]"] = false;
mapping["form1[0].#subform[25].P10_Line8a_PreparersSignature[0]"] = "P";
mapping["form1[0].#subform[25].P10_Line8b_DateofSignature[0]"] = "2023-02-20";
mapping["form1[0].#subform[25].PDF417BarCode1[8]"] = "PDF123456";

mapping["form1[0].#subform[26].P11_Line3d_AdditionalInfo[0]"] = "Additional Info";
mapping["form1[0].#subform[26].P11_Line5a_PageNumber[0]"] = "02";
mapping["form1[0].#subform[26].P11_Line5b_PartNumber[0]"] = "P78901";
mapping["form1[0].#subform[26].P11_Line5c_ItemNumber[0]"] = "I23456";
mapping["form1[0].#subform[26].P11_Line5d_AdditionalInfo[0]"] = "Additional Info";
mapping["form1[0].#subform[26].P11_Line3a_PageNumber[0]"] = "03";

// ... (continue with other mappings)

mapping["form1[0].#subform[26].P11_Line3b_PartNumber[0]"] = "P12345";
mapping["form1[0].#subform[26].P11_Line3c_ItemNumber[0]"] = "I67890";
mapping["form1[0].#subform[26].P4_Line1a_FamilyName[1]"] = "RandomFamilyName";
mapping["form1[0].#subform[26].P4_Line1b_GivenName[1]"] = "RandomGivenName";
mapping["form1[0].#subform[26].P4_Line1c_MiddleName[1]"] = "RandomMiddleName";
mapping["form1[0].#subform[26].#area[19].P4_Line12_AlienNumber[1]"] = "A12345678";
mapping["form1[0].#subform[26].P11_Line4d_AdditionalInfo[0]"] = "Additional Info";
mapping["form1[0].#subform[26].P11_Line4a_PageNumber[0]"] = "04";
mapping["form1[0].#subform[26].P11_Line4b_PartNumber[0]"] = "P54321";
mapping["form1[0].#subform[26].P11_Line4c_ItemNumber[0]"] = "I09876";
mapping["form1[0].#subform[26].P11_Line5a_PageNumber[1]"] = "05";
mapping["form1[0].#subform[26].P11_Line5b_PartNumber[1]"] = "P98765";
mapping["form1[0].#subform[26].P11_Line5c_ItemNumber[1]"] = "I43210";
mapping["form1[0].#subform[26].P11_Line5d_AdditionalInfo[1]"] = "Additional Info";
mapping["form1[0].#subform[26].P11_Line5a_PageNumber[2]"] = "06";
mapping["form1[0].#subform[26].P11_Line5b_PartNumber[2]"] = "P23456";
mapping["form1[0].#subform[26].P11_Line5c_ItemNumber[2]"] = "I78901";
mapping["form1[0].#subform[26].P11_Line5d_AdditionalInfo[2]"] = "Additional Info";
mapping["form1[0].#subform[26].PDF417BarCode1[9]"] = "PDF654321";
    
    return mapping;
  };




  const handleSubmitI864 = async () => {
    const data = mapFormFields();
    console.log(data);
     console.log("uzair")
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "G-639",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

    


  return (
    <FormContext864.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitI864}}>
      {children}
    </FormContext864.Provider>
  );
}
