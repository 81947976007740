import React, { useState } from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";
const I8648 = () => {
    const navigate=useNavigate()


  const { formData, handleInputChange } = useFormContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
  };

return(
    <div className="w-full">
    <Header />
    <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
    <div
        className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
        style={{ backgroundColor: "#0072bc" }}
      >
        <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
          verified and security
        </span>
        <span className="font-bold text-white text-xl formheaderfont">
          USCIS Form I-864 - Affidavit of Support Package
        </span>
        <div className="h-full flex justify-end">
          {" "}
          {/* Added 'flex' and 'justify-end' */}
          <button
            className="text-white px-2 bg-blue-900 h-full border border-black"
            style={{ textAlign: "left" }}
          >
            Save
          </button>
          <button className="text-white px-2 bg-blue-900 h-full">Help</button>
        </div>
      </div>
   
      <div
        className="progress-description pt-3 pt-lg-0"
        id="progress-description"
      >
        <h2 style={{ marginBottom: "-1%" }}>
          {" "}
          Federal Income Tax Return Information
        </h2>
<p>&nbsp;&nbsp;&nbsp;You are required to enter your total income (as reported to the IRS) for the three most recent tax years.
</p>
<br />
      </div>
      <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
 
      <form onSubmit={handleSubmit}  className=" w-full ">
      <div className="additional-div">
              <div className="bg-blue-500 text-white font-bold p-2 ndRendring text">
              Have you filed a Federal income tax return for each of the three most recent tax years?
                <span className="text-red-500">*</span>
              </div>
              <div className="form p-2">
                <input
                 type="radio"
                 name="filedtax"
                 value="Yes" // Set the value to "Yes"
                 id="filedtaxinUSYes"
                 checked={formData.filedtax === "Yes"} // Check if interview is "Yes"
                 onChange={handleInputChange}
                />
                <label htmlFor="filedtaxinUSYes">Yes</label> &nbsp; &nbsp;
                <input
                 type="radio"
                 name="filedtax"
                 value="No" // Set the value to "Yes"
                 id="filedtaxnn"
                 checked={formData.filedtax === "No"} // Check if interview is "Yes"
                 onChange={handleInputChange}
                />
                <label htmlFor="filedtaxnn">No</label>
                {/* <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                This application must be filed while in the United States.
                </div> */}
                
<br />


              </div>              </div>


 
{formData.filedtax==="No"?(
     <div className="additional-div">
     <div className="bg-blue-500 text-white font-bold p-2 ndRendring text">
     Were you required to file a Federal income tax return for each of the three most recent tax years?
       <span className="text-red-500">*</span>
     </div>
     <div className="form p-2">
       <input
        type="radio"
        name="filefederaltax"
        value="Yes" // Set the value to "Yes"
        id="filedtaxinUSYess"
        checked={formData.filefederaltax === "Yes"} // Check if interview is "Yes"
        onChange={handleInputChange}
       />
       <label htmlFor="filedtaxinUSYess">Yes</label> &nbsp; &nbsp;
       <input
        type="radio"
        name="filefederaltax"
        value="No" // Set the value to "Yes"
        id="filedtaxnnv"
        checked={formData.filefederaltax === "No"} // Check if interview is "Yes"
        onChange={handleInputChange}
       />
       <label htmlFor="filedtaxnnv">No</label>
       <input
        type="radio"
        name="filefederaltax"
        value="idk" // Set the value to "Yes"
        id="filedtaxncnv"
        checked={formData.filefederaltax === "idk"} // Check if interview is "Yes"
        onChange={handleInputChange}
       />
       <label htmlFor="filedtaxncnv">I don't know</label>
       
<br />


     </div>              </div>
):null}
              {formData.filefederaltax==="Yes" ||formData.filefederaltax==="idk" ?(
 <div className="bg-red-200 error">
              <div className="icon-and-message">
                <span className="icon">
                  <img src={crosspic} alt="" width="72" height="52" />
                </span>
                <p className="message ml-2">
                  <span className="font-bold">
                  We found a problem. Based on your answer, you cannot continue.
                  </span>
                  Regardless of whether one or three years of federal income tax returns are submitted, a sponsor must have actually filed returns for all three most recent years if legally required to do so. If a sponsor had a legal duty to file a tax return and did not do so, it's highly likely that the sponsor's support will be determined to be "insufficient."
                </p>
              </div>
              <div className="icon-and-message">
                <span className="icon">
                  <img src={Searchicon} alt="" width="32" height="32" />
                </span>
                <p className="message ml-2">
                  
                  <u>Help me find an immigration attorney.</u>
                </p>
              </div>
            </div> 
):null}
<br />
{formData.filedtax==="Yes"|| formData.filefederaltax==="No"?(<div>
<hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
<div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Federal Income Tax Returns
</b>
              </h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
              Enter your total income (adjusted gross income on Internal Revenue Service (IRS) Form 1040EZ or 1040) as reported on your Federal income tax returns  <br /> for the most recent three years.
                <u> Explain more >></u>
              </p>
            </div>
          </div>


          <h3 class="heading33 ml-12">Most Recent Tax Year
  </h3>
         
  <div className="forms">
              <label htmlFor="givenName">
              Select the year: <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <select
                  class="form-control1"
                  aria-required="true"
                  required
                  name="year"
                  value={formData.year}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="childern"
                  className="form-control mt-3 ml-3"
                  style={{ width: "30%" }}

                >
                  <option value="Please Select">Please Select</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  
                </select>
             
              <br />
              <label htmlFor="Middle">
              Income:<span className="text-red-500">*</span>{" "}
              &nbsp; &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp;     &nbsp; &nbsp;{"$"}
              </label>
          
              <input
                id="Middle"
                type="text"
                name="taxincome"
                value={formData.taxincome} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />

</div>
<br />
<h3 class="heading33 ml-12">2nd Most Recent Tax Year
  </h3>
         
  <div className="forms">
              <label htmlFor="givenNamec">
              Select the year: <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <select
                  class="form-control1"
                  aria-required="true"
                  required
                  name="year2"
                  value={formData.year2}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="givenNamec"
                  className="form-control mt-3 ml-3"
                  style={{ width: "30%" }}

                >
                  <option value="Please Select">Please Select</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>

                  
                </select>
             
              <br />
              <label htmlFor="33">
              Income:<span className="text-red-500">*</span>{" "}
              &nbsp; &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp;     &nbsp; &nbsp;{"$"}
              </label>
          
              <input
                id="33"
                type="text"
                name="taxincome2"
                value={formData.taxincome2} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />

</div>

<h3 class="heading33 ml-12">3rd Most Recent Tax Year
  </h3>
         
  <div className="forms">
              <label htmlFor="childerna">
              Select the year: <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp;
              <select
                  class="form-control1"
                  aria-required="true"
                  required
                  name="year3"
                  value={formData.year3}
                  onChange={handleInputChange}
                  autocomplete="section-P5_Physical_Address_Domestic address-level1"
                  id="childerna"
                  className="form-control mt-3 ml-3"
                  style={{ width: "30%" }}

                >
                  <option value="Please Select">Please Select</option>
                  <option value="2019">2019</option>
                  <option value="2020">2020</option>

                  
                </select>
             
              <br />
              <label htmlFor="Middlea">
              Income:<span className="text-red-500">*</span>{" "}
              &nbsp; &nbsp;   &nbsp; &nbsp; &nbsp; &nbsp;     &nbsp; &nbsp;{"$"}
              </label>
          
              <input
                id="Middlea"
                type="text"
                name="taxincome3"
                value={formData.taxincome3} // Replace with your formData field
                onChange={handleInputChange} // Add the appropriate field name
                style={{
                  padding: "5px",
                  marginTop: "5px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
              />

</div>
<div
              style={{
                backgroundColor: "#E7E7E7",
                color: "#888",
                width: "89%",
                marginLeft: "6%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                padding: "1%",
                borderRadius: "5px",
                marginTop: "2%",
              }}
            >
              <img
                src={warn}
                alt="Warning"
                style={{
                  width: "34px",
                  height: "34px",
                  marginRight: "1%",
                }}
              />
              <div>
                <p>
                 <b>Be Advised:</b> 
                 You may proceed without using the most recent tax year, but this will likely result in a Request for Evidence and delay the beneficiary's immigration case. USCIS wants information from the three most recent tax years. If you have filed an extension, it is generally best to complete the tax return first and then submit Form I-864. You may proceed without using the most recent tax year. However, USCIS will likely come back to you with a request for the most recent tax return before making a decision on the beneficiary's immigration case.                  
                </p>
              </div>
            </div>
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16">
                <b>Federal Income Tax Returns
</b>
              </h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
              When submitting this affidavit, you <b>MUST</b> attach a copy or transcript of your Federal income tax return for the most recent tax year only. If you believe additional returns may help you to establish your ability to maintain sufficient income, you may submit transcripts or photocopies of your Federal income tax returns for the three most recent years.               
              </p>
            </div>
          </div>

          <div className="additional-div">
              <div className="bg-blue-500 text-white font-bold p-2 ndRendring text">
              Will you be attaching photocopies or transcripts of Federal income tax returns for your second and third most recent years?
                <span className="text-red-500">*</span>
              </div>
              <div className="form ">
                <input
                 type="radio"
                 name="photocopies"
                 value="Yes" // Set the value to "Yes"
                 id="photocopiesSYes"
                 checked={formData.photocopies  === "Yes"} // Check if interview is "Yes"
                 onChange={handleInputChange}
                />
                <label htmlFor="photocopiesSYes">Yes</label> &nbsp; &nbsp;
                <input
                 type="radio"
                 name="photocopies"
                 value="Yes" // Set the value to "Yes"
                 id="photocopiesn"
                 checked={formData.photocopies  === "Yes"} // Check if interview is "Yes"
                 onChange={handleInputChange}
                />
                <label htmlFor="photocopiesn">No</label>
                <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                Generally, there is no reason to provide tax returns for your second and third most recent years. Provide them only if they improve your case. <br />
                <u>Explain more >></u>
                </div>
                



              </div>     <br />         </div>
</div>

            ):null}  








            <br />
          


<br />
<br />

            <div className="w-full ml-8">
            <button
              type="button"
              className="float-left previous text-white px-4 py-2 rounded mt-4"
              style={{ background: "rgb(187, 187, 187)" }}
            >
              ← PREV
            </button>
            <br />
            <button
              type="submit"
              className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
              onClick={()=>navigate("/I8649")}
            >
              NEXT →
            </button>
          </div>
      </form >

      </div>
      <FormFotter />

    </div>
 
              </div>
);}
export default I8648;