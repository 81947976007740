import React from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useState } from "react";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";

const I8642= () => {
  const navigate=useNavigate()
    const {formData ,handleInputChange} =useFormContext()
const handleSubmit=(e)=>{
e.preventDefault()
// console.log(formData)
}
    return(
        <div className="w-full">
      <Header />
      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
      <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            USCIS Form I-864 - Affidavit of Support Package
          </span>
          <div className="h-full flex justify-end">
            {" "}
            {/* Added 'flex' and 'justify-end' */}
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>
     
        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2 style={{ marginBottom: "-1%" }}>
            {" "}
            Before we begin, we need to know the type of qualifying relationship that the petitioner has with the principal immigrant.
          </h2>

        </div>
        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
        <div className="flex flex-col items-right space-y-0 p-4">
            <span className="flex items-right space-x-10">
              <h3 className="heading33 ml-16"><b>Petitioner</b></h3>
            </span>
            <div className="text-left">
              <p className="ptag ml-16">
              The petitioner is the person that filed the immigrant petition. Forms I-130, I-140, and I-129F are examples of immigrant petitions.


              </p>
            </div>
          </div> 
        <form onSubmit={handleSubmit}  className="mt-6 w-full ">
        <div className="mt-2 font-thin ml-2 text ndRendring">
        Select your citizenship or residency:
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="residency2"
                  value="US"
                  id="lostRadio"
                  onChange={handleInputChange}
                  checked={formData.residency2 === "US"}
                />
                <label htmlFor="lostRadio">
                U.S. citizen                </label>
                <hr />
                <input
                  type="radio"
                  name="residency2"
                  value="U.S. national"
                  id="notReceivedRadio"
                  onChange={handleInputChange}
                  checked={formData.residency2 === "U.S. national"}
                />
                <label htmlFor="notReceivedRadio">
                U.S. national

                </label>
                <hr />
                <input
                  type="radio"
                  name="residency2"
                  value="Lawful permanent resident"
                  id="mutilatedRadio"
                  onChange={handleInputChange}
                  checked={formData.residency2 === "Lawful permanent resident"}
                />
                <label htmlFor="mutilatedRadio">
                Lawful permanent resident
                </label>
              
              </div>
              <br />
              {formData.residency2==="US" ?(
                <div>
  <div className="mt-2 font-thin ml-2 text ndRendring">
    The intending immigrant is the petitioner's:
    <span className="text-red-500">*</span>
  </div>
  <div className="form">
    <input
      type="radio"
      name="relationship2"
      value="Spouse"
      id="spouseRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Spouse"}
    />
    <label htmlFor="spouseRadio">
      Spouse
    </label>
    <hr />
    <input
      type="radio"
      name="relationship2"
      value="Child (under age 21)"
      id="childRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Child (under age 21)"}
    />
    <label htmlFor="childRadio">
      Child (under age 21)
    </label>
    <hr />
    <input
      type="radio"
      name="relationship2"
      value="Adult son/daughter"
      id="adultSonDaughterRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Adult son/daughter"}
    />
    <label htmlFor="adultSonDaughterRadio">
      Adult son/daughter
    </label>
    <hr />
    <input
      type="radio"
      name="relationship2"
      value="Parent (mother/father)"
      id="parentRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Parent (mother/father)"}
    />
    <label htmlFor="parentRadio">
      Parent (mother/father)
    </label>
    <hr />
    <input
      type="radio"
      name="relationship2"
      value="Sibling (brother/sister)"
      id="siblingRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Sibling (brother/sister)"}
    />
    <label htmlFor="siblingRadio">
      Sibling (brother/sister)
    </label>
  </div>
</div>


):null}

{formData.residency2 === "U.S. national" || formData.residency2 === "Lawful permanent resident"?(

<div><div className="mt-2 font-thin ml-2 text ndRendring">
The intending immigrant is the petitioner's:
<span className="text-red-500">*</span>
</div>
<div className="form">

<input
      type="radio"
      name="relationship2"
      value="Spouse"
      id="spouseRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Spouse"}
    />
    <label htmlFor="spouseRadio">
      Spouse
    </label>
    <hr />
    <input
      type="radio"
      name="relationship2"
      value="Child (under age 21)"
      id="childRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Child (under age 21)"}
    />
    <label htmlFor="childRadio">
      Child (under age 21)
    </label>
    <hr />
    <input
      type="radio"
      name="relationship2"
      value="Adult son/daughter"
      id="adultSonDaughterRadio"
      onChange={handleInputChange}
      checked={formData.relationship2 === "Adult son/daughter"}
    />
    <label htmlFor="adultSonDaughterRadio">
      Adult son/daughter
    </label>
    </div>

</div>

):null}

<br />
<br />

              <div className="w-full ml-8">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded mt-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
                onClick={()=>navigate("/I8645")}
              >
                NEXT →
              </button>
            </div>
        </form >

        </div>
        <FormFotter />

      </div>
   
                </div>

        );

};
export default I8642;
