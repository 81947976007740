import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContextN565 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContextN565);
}

// Define the initial state
const initialState = {
  isUSCitizen: '', // 'Yes' or 'No'
  lostCitizenship: '', // 'Yes' or 'No'
  servedInArmedForces: '', // 'Yes' or 'No'
  residingOutsideUS: '', // 'Yes' or 'No'
  documentType: '', // Type of document (e.g., 'Certificate of Naturalization')
  previouslyIssued: '',
  sinceLostRenouncedCitizenship: '',
  howBecameUSCitizen: '',
  applicationBasis: '',
  haveOriginalCertificate: '',
  specificChanges: '',
  givenName: '', // For the first name
middleNames: '', // For middle name(s)
familyName: '', // For the last name
usedOtherNamesSinceBirth: '',
maritalStatusChanged: '',
maritalStatus: '',

  inCareOfName: '', // For "In Care of Name (if any)"
  streetNumberAndName: '', // For "Street Number and Name"
  enterNumber: '', // For "Enter the number here"
  cityOrTown: '', // For "City or Town"
  zipCode: '', // For "ZIP Code"
  apartmentSuiteFloor: 'No', 
  country: '', // For selecting the country
  stateOrTerritory: '',
  daytimePhoneNumber: '', // For daytime phone number
  mobilePhoneNumber: '', // For mobile phone number
  email: '',


  wasisseuedgivenName: '', // For the first name
  wasisseuedmiddleNames: '', // For middle name(s)
  wasisseuedfamilyName: '',
  certificateNumber: '', // For Certificate Number
  placeOfIssuance: '',

  dateOfIssuance: '', // For Date of Issuance
    dateOfBirthOnCertificate: '',
    previousCountryOfCitizenship: '',
    doNotKnowDateOfIssuance: false,
    aNumber: '', // For Alien Registration Number (A-Number)
    doNotKnowANumber: false, 
    certificateCircumstances:"",
    evidenceOfNameChange: '',
    dateOfChangeOnDocument: '', 

    NewgivenName: '', // For the first name
    NewdmiddleNames: '', // For middle name(s)
    NewfamilyName: '',
    applicationPreparer: '',
    representationExtends: '',
   

    preparersGivenName: '', // For Preparer’s Given Name (First Name)
    preparersFamilyName: '', // For Preparer’s Family Name (Last Name)
    preparersFirmOrOrganizationName: '', // For Preparer’s Firm or Organization Name
    stateBarNumber: '', // For State Bar Number
    uscisOnlineAccountNumber: '',


    preparersStreetNumberAndName: '', // For Preparer’s Street Number and Name
preparersCityOrTown: '', // For Preparer’s City or Town
preparersZIPCode: '',
apartmentSuiteFloor: 'No',
enterNumber: '',
preparersstateOrTerritory: '',
preparerscountry: '',



preparerDaytimePhoneNumber: '',
preparerMobilePhoneNumber: '',
preparerEmailAddress: '',



receivedHelpFromInterpreter: '',
understandEnglish: false,
interpretersGivenName: '', // Interpreter's Given Name (First Name)
  interpretersFamilyName: '', // Interpreter's Family Name (Last Name)
  interpretersFirmOrOrganizationName: '', // Interpreter's Firm or Organization Name
  interpretersStateBarNumber: '', // Interpreter's State Bar Number
  interpretersUSCISOnlineAccountNumber: '', // Interpreter's USCIS Online Account Number
  interpretersStreetNumberAndName: '', // Interpreter's Street Number and Name
  interpretersCityOrTown: '', // Interpreter's City or Town
  interpretersApartmentSuiteFloor: 'No', // Interpreter's Apartment/Suite/Floor
  interpretersEnterNumber: '', // Interpreter's Enter the number here
  interpretersZIPCode: '', // Interpreter's ZIP Code
  interpretersDaytimePhoneNumber: '', // Interpreter's Daytime Phone Number
  interpretersMobilePhoneNumber: '', // Interpreter's Mobile Phone Number (if any)
  interpretersEmailAddress: '', 
    
  
  };

// Create a provider component to manage the state
export function FormProviderForN565({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);





  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  // Function to handle removing a name set
  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames); // Store the country names in state
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  

  // Handle change for all form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate
              ? e.target.valueAsDate.toISOString().slice(0, 10)
              : '',
          };
        case 'text':
        case 'number':
        case 'textarea': // Include textarea input type
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: checked,
          };
        case 'select-one':
          return {
            ...prevData,
            [name]: value,
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      }
    });
  };
  
  const mapFormFields = () => {
    let mapping = {};
   // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[0].AttorneyStatebarnumber[0]"] = "RandomStateBar123";
mapping["form1[0].#subform[0].#area[0].USCISOnlineActNumber[0]"] = "RandomUSCIS123";
mapping["form1[0].#subform[0].P1Line1_MiddleName[0]"] = "RandomMiddleName123";
mapping["form1[0].#subform[0].P1Line1_GivenName[0]"] = "RandomGivenName123";
mapping["form1[0].#subform[0].P1Line1_FamilyName[0]"] = "RandomFamilyName123";
mapping["form1[0].#subform[0].Line3_CountryOfCitizenship[0]"] = "RandomCountry123";
mapping["form1[0].#subform[0].ANum[0]"] = "ANum123456";
mapping["form1[0].#subform[0].P1Line4_CertificateNumber[0]"] = "Certificate123";
mapping["form1[0].#subform[0].P1Line6_USCISOfficeorNameofCourt[0]"] = "USCISOffice123";
mapping["form1[0].#subform[0].P1Line6_DateOfDeclaration[0]"] = "DeclarationDate123";
mapping["form1[0].#subform[0].Line1_MiddleName[0]"] = "RandomMiddleName123";
mapping["form1[0].#subform[0].Line1_GivenName[0]"] = "RandomGivenName123";
mapping["form1[0].#subform[0].P2_Line1_FamilyName[0]"] = "RandomFamilyName123";
mapping["form1[0].#subform[0].Pt1Line2_DateofBirthonCert[0]"] = "BirthDate123";
mapping["form1[0].#subform[0].PDF417BarCode1[0]"] = "PDF417Barcode123";
mapping["form1[0].#subform[1].P2Line3_InCareofName[0]"] = "InCareofName1";
mapping["form1[0].#subform[1].P2Line3_StreetNumberName[0]"] = "StreetNumberName";
mapping["form1[0].#subform[1].P2Line3_Unit[0]"] = false;
mapping["form1[0].#subform[1].P2Line3_Unit[1]"] = true;
mapping["form1[0].#subform[1].P2Line3_Unit[2]"] = false;
mapping["form1[0].#subform[1].P2Line3_AptSteFlrNumber[0]"] = "Apt123";
mapping["form1[0].#subform[1].Pt2Line3_CityTown[0]"] = "CityTown12345678901234567890";
mapping["form1[0].#subform[1].P2Line3_State[0]"] = "RandomState123";
mapping["form1[0].#subform[1].P2Line3_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[1].P2Line3_Province[0]"] = "RandomProvince";
mapping["form1[0].#subform[1].P2Line3_PostalCode[0]"] = "Postal3";
mapping["form1[0].#subform[1].P2Line3_Country[0]"] = "RandomCountry123";
mapping["form1[0].#subform[1].Part2_Item5[0]"] = true;
mapping["form1[0].#subform[1].Part2_Item5[1]"] = false;
mapping["form1[0].#subform[1].Part2_Item5[2]"] = true;
mapping["form1[0].#subform[1].Part2_Item5[3]"] = false;
mapping["form1[0].#subform[1].Part2_Item4[0]"] = false;
mapping["form1[0].#subform[1].Part2_Item4[1]"] = true;
mapping["form1[0].#subform[1].Part2_Item5[4]"] = false;
mapping["form1[0].#subform[1].Part2_Item6[0]"] = true;
mapping["form1[0].#subform[1].Part2_Item6[1]"] = true;
mapping["form1[0].#subform[1].P2Line2_MiddleName[0]"] = "RandomMiddleName2";
mapping["form1[0].#subform[1].P2Line2_FamilyName2[0]"] = "RandomFamilyName2";
mapping["form1[0].#subform[1].P2Line2_GivenName2[0]"] = "RandomGivenName2";
mapping["form1[0].#subform[1].P2Line2_MiddleName2[0]"] = "RandomMiddleName2";
mapping["form1[0].#subform[1].P2Line2_GivenName[0]"] = "RandomGivenName2";
mapping["form1[0].#subform[1].P2Line2_FamilyName[0]"] = "RandomFamilyName2";
mapping["form1[0].#subform[1].Part3_Item1[0]"] = false;
mapping["form1[0].#subform[1].Part3_Item1[1]"] = true;
mapping["form1[0].#subform[1].Part3_Item1[2]"] = false;
mapping["form1[0].#subform[1].Part3_Item1[3]"] = true;
mapping["form1[0].#subform[1].Part3_Item1[4]"] = false;
mapping["form1[0].#subform[1].Pt3CheckBox2a[0]"] = true;
mapping["form1[0].#subform[1].Pt3Line2b_Explanation[0]"] = "RandomExplanation123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890";
mapping["form1[0].#subform[1].PDF417BarCode1[1]"] = "RandomBarcode123";
mapping["form1[0].#subform[2].Pt3CheckBox3[0]"] = false;
mapping["form1[0].#subform[2].Pt3CheckBox4[0]"] = true;
mapping["form1[0].#subform[2].Pt3CheckBox6[0]"] = false;
mapping["form1[0].#subform[2].Pt3CheckBox8a[0]"] = true;
mapping["form1[0].#subform[2].Pt3CheckBox7[0]"] = false;
mapping["form1[0].#subform[2].Pt3Line8b_Explanation[0]"] = "RandomExplanation";
mapping["form1[0].#subform[2].Pt3CheckBox5[0]"] = true;
mapping["form1[0].#subform[2].Pt4_AdditionalInfo[0]"] = "RandomAdditionalInfo";
mapping["form1[0].#subform[2].Pt4_Item1_DOB[0]"] = false;
mapping["form1[0].#subform[2].Pt4_Item1_Name[0]"] = true;
mapping["form1[0].#subform[2].Pt4_Item1_Other[0]"] = false;
mapping["form1[0].#subform[2].Pt4_Item1_Gender[0]"] = true;
mapping["form1[0].#subform[2].PDF417BarCode1[2]"] = "RandomBarcode456";
mapping["form1[0].#subform[3].Pt5LineB_DateChanged[0]"] = "RandomDate";
mapping["form1[0].#subform[3].Pt5CheckBoxB[0]"] = true;
mapping["form1[0].#subform[3].Pt5CheckBoxA[0]"] = false;
mapping["form1[0].#subform[3].Pt5LineA_DateChanged[0]"] = "RandomDate";
mapping["form1[0].#subform[3].P5Line2_MiddleName[0]"] = "RandomMiddleName3";
mapping["form1[0].#subform[3].P5Line2_GivenName[0]"] = "RandomGivenName3";
mapping["form1[0].#subform[3].P5Line2_FamilyName[0]"] = "RandomFamilyName3";
mapping["form1[0].#subform[3].Pt6Line1A_DateChanged[0]"] = "RandomDate";

// ... (continue with other fields)

// ... (continue with other mappings)

    // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[3].Pt6CheckBox1B[0]"] = false;
mapping["form1[0].#subform[3].Pt6Line1B_DateChanged[0]"] = "RandomDate";
mapping["form1[0].#subform[3].Pt6CheckBox1A[0]"] = true;
mapping["form1[0].#subform[3].Line6_DateOfBirth[0]"] = "RandomDateOfBirth";
mapping["form1[0].#subform[3].Pt7CheckBoxC[0]"] = true;
mapping["form1[0].#subform[3].Pt7CheckBoxB[0]"] = false;
mapping["form1[0].#subform[3].Pt7CheckBoxA[0]"] = true;
mapping["form1[0].#subform[3].P7Line2_male[0]"] = false;
mapping["form1[0].#subform[3].P7Line2_female[0]"] = true;
mapping["form1[0].#subform[3].PDF417BarCode1[3]"] = "RandomBarcode789";
mapping["form1[0].#subform[4].Pt8Line1_NameOfForeignCountry[0]"] = "RandomForeignCountry";
mapping["form1[0].#subform[4].Pt8Line2_MiddleName[0]"] = "RandomMiddleName4";
mapping["form1[0].#subform[4].Pt8Line2_GivenName[0]"] = "RandomGivenName4";
mapping["form1[0].#subform[4].Pt8Line2_FamilyName[0]"] = "RandomFamilyName4";
mapping["form1[0].#subform[4].Pt8_Line2_GovAgencyName[0]"] = "RandomGovAgency";
mapping["form1[0].#subform[4].Pt8_Line2_OfficialTitle[0]"] = "RandomOfficialTitle";
mapping["form1[0].#subform[4].Pt8Line3_CityTown[0]"] = "RandomCity";
mapping["form1[0].#subform[4].Pt8Line3a_StreetNumberName[0]"] = "RandomStreet";
mapping["form1[0].#subform[4].Pt8Line3_Unit[0]"] = true;
mapping["form1[0].#subform[4].Pt8Line3_Unit[1]"] = false;
mapping["form1[0].#subform[4].Pt8Line3_Unit[2]"] = true;
mapping["form1[0].#subform[4].Pt8Line3_AptSteFlrNumber[0]"] = "Apt123";
mapping["form1[0].#subform[4].Pt8Line3_State[0]"] = "RandomState";
mapping["form1[0].#subform[4].Pt8Line3_ZipCode[0]"] = "12345";
mapping["form1[0].#subform[4].Pt8Line3_Province[0]"] = "RandomProvince";
mapping["form1[0].#subform[4].Pt8Line3_PostalCode[0]"] = "Postal123";
mapping["form1[0].#subform[4].Pt8Line3_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[4].Pt8_Line4_DateofSignature[0]"] = "RandomDate";
mapping["form1[0].#subform[4].Pt8_Line4_Signature[0]"] = "S";
mapping["form1[0].#subform[4].Pt9Line1a_Checkbox[0]"] = true;
mapping["form1[0].#subform[4].PDF417BarCode1[4]"] = "RandomBarcode012";
mapping["form1[0].#subform[5].Pt9Line1b_Language[0]"] = "RandomLanguage";
mapping["form1[0].#subform[5].Pt9Line1b_Checkbox[0]"] = false;
mapping["form1[0].#subform[5].Pt9Line2_Checkbox[0]"] = true;
mapping["form1[0].#subform[5].Pt9Line2_PreparerName[0]"] = "RandomPreparerName";
mapping["form1[0].#subform[5].Pt9Line5_Email[0]"] = "random@email.com";
mapping["form1[0].#subform[5].Pt9Line3_DaytimeTelephoneNumber3[0]"] = "1234567890";
mapping["form1[0].#subform[5].Pt9Line4_MobileTelephoneNumber3[0]"] = "9876543210";
mapping["form1[0].#subform[5].Pt9Line6_SignatureofApplicant[0]"] = "RandomApplicantSignature";
mapping["form1[0].#subform[5].Pt9Line6_DateofSignature[0]"] = "RandomSignatureDate";
mapping["form1[0].#subform[5].Pt10Line1_InterpreterFamilyName[0]"] = "RandomInterpreterFamilyName";
mapping["form1[0].#subform[5].Pt10Line1_InterpreterGivenName[0]"] = "RandomInterpreterGivenName";
mapping["form1[0].#subform[5].Pt10Line2_NameofBusinessorOrgName[0]"] = "RandomBusinessOrgName";
mapping["form1[0].#subform[5].PDF417BarCode1[5]"] = "RandomBarcode345";
mapping["form1[0].#subform[6].Pt10Line3_StreetNumberName[0]"] = "RandomStreetNumberName";
mapping["form1[0].#subform[6].Pt10Line3_Unit[0]"] = true;
mapping["form1[0].#subform[6].Pt10Line3_Unit[1]"] = false;
mapping["form1[0].#subform[6].Pt10Line3_Unit[2]"] = true;
mapping["form1[0].#subform[6].Pt10Line3_AptSteFlrNumber[0]"] = "Apt456";
mapping["form1[0].#subform[6].Pt10Line3_CityTown[0]"] = "RandomCityTown";
mapping["form1[0].#subform[6].Pt10Line3_State[0]"] = "RandomState";
mapping["form1[0].#subform[6].Pt10Line3_ZipCode[0]"] = "67890";
mapping["form1[0].#subform[6].Pt10Line3_Province[0]"] = "RandomProvince";
mapping["form1[0].#subform[6].Pt10Line3_PostalCode[0]"] = "Postal678";
mapping["form1[0].#subform[6].Pt10Line3_Country[0]"] = "RandomCountry";
mapping["form1[0].#subform[6].Pt10Line6_Email[0]"] = "random2@email.com";
mapping["form1[0].#subform[6].Pt10Line4_DaytimeTelephoneNumber[0]"] = "2345678901";
mapping["form1[0].#subform[6].Pt10Line4_MobileTelephoneNumber[0]"] = "8765432109";
mapping["form1[0].#subform[6].Pt10_Iamfluent[0]"] = "RandomFluency";
mapping["form1[0].#subform[6].Pt10Line7_DateofSignature[0]"] = "RandomSignatureDate2";
mapping["form1[0].#subform[6].Pt10Line7_Signature[0]"] = "T";
mapping["form1[0].#subform[6].Pt11Line1_PreparerFamilyName[0]"] = "RandomPreparerFamilyName";
mapping["form1[0].#subform[6].Pt11Line1_PreparerGivenName[0]"] = "RandomPreparerGivenName";
mapping["form1[0].#subform[6].Pt11Line2_BusinessName[0]"] = "RandomBusinessName";
mapping["form1[0].#subform[6].PDF417BarCode1[6]"] = "RandomBarcode678";

// ... (continue with other fields)

// ... (continue with other mappings)

   // ... (previous mappings)

// Continuing with additional mappings
mapping["form1[0].#subform[7].Pt11Line6_EmailAddress[0]"] = "random3@email.com";
mapping["form1[0].#subform[7].Pt11Line5_MobileTelephoneNumber[0]"] = "3456789012";
mapping["form1[0].#subform[7].Pt11Line4_DaytimeTelephoneNumber[0]"] = "9012345678";
mapping["form1[0].#subform[7].Pt11Line3_StreetNumberName[0]"] = "RandomStreetNumber456";
mapping["form1[0].#subform[7].Pt11Line3_Unit[0]"] = true;
mapping["form1[0].#subform[7].Pt11Line3_Unit[1]"] = false;
mapping["form1[0].#subform[7].Pt11Line3_Unit[2]"] = true;
mapping["form1[0].#subform[7].Pt11Line3_AptSteFlrNumber[0]"] = "Apt789";
mapping["form1[0].#subform[7].Pt11Line3_CityTown[0]"] = "RandomCity789";
mapping["form1[0].#subform[7].Pt11Line3_State[0]"] = "RandomState789";
mapping["form1[0].#subform[7].Pt11Line3_ZipCode[0]"] = "56789";
mapping["form1[0].#subform[7].Pt11Line3_Province[0]"] = "RandomProvince789";
mapping["form1[0].#subform[7].Pt11Line3_PostalCode[0]"] = "Postal789";
mapping["form1[0].#subform[7].Pt11Line3_Country[0]"] = "RandomCountry789";
mapping["form1[0].#subform[7].Pt11Line7_Checkbox[0]"] = true;
mapping["form1[0].#subform[7].Pt11Line7_Checkbox[1]"] = false;
mapping["form1[0].#subform[7].Pt11Line7b1_Checkbox[0]"] = true;
mapping["form1[0].#subform[7].Pt11Line7b1_Checkbox[1]"] = false;
mapping["form1[0].#subform[7].Pt11Line8_SignatureofPreparer[0]"] = "S";
mapping["form1[0].#subform[7].Pt11Line8_DateOfSignature[0]"] = "RandomSignatureDate3";
mapping["form1[0].#subform[7].PDF417BarCode1[7]"] = "RandomBarcode789";
mapping["form1[0].#subform[8].P12_Line3a_PageNumber[0]"] = "34";
mapping["form1[0].#subform[8].P12_Line3b_PartNumber[0]"] = "Part678";
mapping["form1[0].#subform[8].P12_Line3c_ItemNumber[0]"] = "Item678";
mapping["form1[0].#subform[8].P12_Line3d_AdditionalInfo[0]"] = "AdditionalInfo678";
mapping["form1[0].#subform[8].P1Line1_MiddleName[1]"] = "RandomMiddleName678";
mapping["form1[0].#subform[8].P1Line1_GivenName[1]"] = "RandomGivenName678";
mapping["form1[0].#subform[8].P1Line1_FamilyName[1]"] = "RandomFamilyName678";
mapping["form1[0].#subform[8].P12_Line4d_AdditionalInfo[0]"] = "AdditionalInfo678";
mapping["form1[0].#subform[8].P12_Line5d_AdditionalInfo[0]"] = "AdditionalInfo678";
mapping["form1[0].#subform[8].P12_Line6d_AdditionalInfo[0]"] = "AdditionalInfo789";
mapping["form1[0].#subform[8].P12_Line4a_PageNumber[0]"] = "23";
mapping["form1[0].#subform[8].P12_Line4b_PartNumber[0]"] = "Part789";
mapping["form1[0].#subform[8].P12_Line4c_ItemNumber[0]"] = "Item789";
mapping["form1[0].#subform[8].P12_Line5a_PageNumber[0]"] = "45";
mapping["form1[0].#subform[8].P12_Line5b_PartNumber[0]"] = "Part567";
mapping["form1[0].#subform[8].P12_Line5c_ItemNumber[0]"] = "Item567";
mapping["form1[0].#subform[8].P12_Line6a_PageNumber[0]"] = "67";
mapping["form1[0].#subform[8].P12_Line6b_PartNumber[0]"] = "Part345";
mapping["form1[0].#subform[8].P12_Line6c_ItemNumber[0]"] = "Item345";
mapping["form1[0].#subform[8].ANum[1]"] = "RandomANum";
mapping["form1[0].#subform[8].PDF417BarCode1[8]"] = "RandomBarcode890";

// ... (continue with other fields)

// ... (continue with other mappings)

   
    
    return mapping;
  };




  const handleSubmitN565 = async () => {
    const data = mapFormFields();
    console.log(data);
     console.log("uzair")
    // Now, you can call your API with this data 
    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "N-565",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };






  return (
    <FormContextN565.Provider value={{ formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange ,handleSubmitN565 }}>
      {children}
    </FormContextN565.Provider>
  );
}
