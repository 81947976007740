import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"
import { countryCodes } from '../I-1765/Options';


const BasicInformation = () => {
    const { formData, handleInputChange ,code } = useFormContext();
    const navigate =useNavigate()

    const HandleSubmit =(e)=>{
         e.preventDefault()
         navigate("/ImmigrantInfo")
    }
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>

     <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
       <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
         <span className='mt-4 font-bold text-xl ml-2 text-black'>
         Address Information
         </span>
         <div className='flex justify-between items-center gap-4'>
           <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
             <button className='mr-2'>Page 1 of 3</button>
             <button><MdArrowDropDown /></button>
           </div>
           <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
             <AiOutlineShoppingCart />
           </div>
         </div>
       </div>
       <span className='mt-2 font-thin ml-2'>
       First, let's determine your eligibility to prepare Form I-864, Affidavit of Support
       </span>
     </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

    

   <div className='flex flex-col'>
       <h1 className='flex font-semibold text-xl text-blue-700'>Sponsor's Full Name</h1>
       <p className='text-sm'>Provide your full legal name. Spell out middle names completely and do not use just the initial. If you do not have a middle name, you may leave this field blank.</p>
   </div>



   <div className='flex flex-col mt-10 sm:flex-row sm:gap-2 items-center'>
  <label htmlFor="spouseGivenName" className="w-full sm:w-[200px] mb-2 sm:mb-0 text-blue-700 text-center">
    Given Name (First Name):
  </label>
  <input
    className="w-full sm:w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 sm:ml-2"
    type="text"
    id="spouseGivenName"
    name="spouseGivenName"
    value={formData.spouseGivenName}
    onChange={handleInputChange}
  />
</div>


<div className='flex flex-col mt-10 sm:flex-row sm:gap-2'>
  <label htmlFor="middleName" className="w-full sm:w-[200px] text-blue-500 mb-2 sm:mb-0 text-center sm:text-left">Middle Name(s):</label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 sm:px-16 py-2 rounded"
    type="text"
    id="middleName"
    name="middleName"
    value={formData.middleName}
    onChange={handleInputChange}
  />
</div>

<div className='flex flex-col mt-10 sm:flex-row sm:gap-2'>
  <label htmlFor="familyName" className="w-full sm:w-[200px] text-blue-500 mb-2 sm:mb-0 text-center sm:text-left">Family Name (Last Name):</label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 sm:px-16 py-2 rounded"
    type="text"
    id="familyName"
    name="familyName"
    value={formData.familyName}
    onChange={handleInputChange}
  />
</div>


<hr  className='h-1 bg-blue-700 w-full mt-10'/>



  <div className='mt-10'>
    <h1 className='flex text-xl  text-blue-700 font-semibold'>Sponsor's Mailing Address</h1>
    <p>Enter your home address or a place that you can safely receive mail. You may list a valid residence, APO, PO Box, or commercial address. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
  </div>


    
  <div className='flex flex-col mt-10 sm:flex-row sm:gap-2'>
  <label htmlFor="streetNumberAndName" className="w-full sm:w-[200px] text-blue-500 mb-2 sm:mb-0 text-center sm:text-left">Street Number and Name:</label>
  <input
    className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 sm:px-16 py-2 rounded"
    type="text"
    id="streetNumberAndName"
    name="streetNumberAndName"
    value={formData.streetNumberAndName}
    onChange={handleInputChange}
  />
</div>

<div className='mt-10 flex items-center'>
  <label htmlFor="apartment" className="w-[150px] text-blue-500">Is there an apartment, suite, or floor?</label>
  <select
    className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded ml-6"
    id="apartment"
    name="apartment"
    value={formData.apartment}
    onChange={handleInputChange}
  >
    <option value="">Select an option</option>
    <option value="No">No</option>
    <option value="Ste">Ste</option>
    <option value="Flr">Flr</option>
    <option value="Apt">Apt</option>
  </select>
</div>

<div className='flex flex-col mt-10 sm:flex-row sm:gap-2'>
  <label htmlFor="zipCode" className="w-full sm:w-[200px] text-blue-500 mb-2 sm:mb-0 text-center sm:text-left">ZIP Code:</label>
  <input
    className="w-full sm:w-[200px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded"
    type="text"
    id="zipCode"
    name="zipCode"
    value={formData.zipCode}
    onChange={handleInputChange}
  />
</div>

<div className='flex flex-col mt-10 sm:flex-row sm:gap-2'>
  <label htmlFor="country" className="w-full sm:w-[200px] text-blue-500 mb-2 sm:mb-0 text-center sm:text-left">Country:</label>
  <select
    className="w-full sm:w-[200px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded"
    id="country"
    name="country"
    value={formData.country}
    onChange={handleInputChange}
  >
    <option value="">Select a Country</option>
    {code.map((countryName) => (
      <option key={countryName} value={countryName}>
        {countryName}
      </option>
    ))}
  </select>
</div>

<div className='flex flex-col mt-10 sm:flex-row sm:gap-2'>
  <label htmlFor="state" className="w-full sm:w-[200px] text-blue-500 mb-2 sm:mb-0 text-center sm:text-left">State (or Territory):</label>
  <select
    className="w-full sm:w-[200px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded"
    id="state"
    name="state"
    value={formData.state}
    onChange={handleInputChange}
  >
    <option value="">Select a State (or Territory)</option>
    {code.map((stateName) => (
      <option key={stateName} value={stateName}>
        {stateName}
      </option>
    ))}
  </select>
</div>

    <hr className='h-1 bg-blue-600 w-full mt-10'/>

    <div className='mt-10'>
        <h1 className='text-blue-600 font-semibold '>Country of Domicile</h1>
        <p>A sponsor who files Form I-864, Affidavit of Support, must be domiciled in the United States or in the process of reestablishing U.S. domicile. Explain more </p>
    </div>

 


     <div className='bg-slate-200 flex gap-4 p-2 mt-10'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt=""  className='w-[40px] h-[60px]'/>
        <p>For Your Information: If you are not currently living in the United States, you may meet the domicile requirement if you plan to reestablish your domicile in the United States. Therefore if you intend in good faith to reestablish your domicile in the U.S. no later than the date of the intending immigrant's admission or adjustment of status, you make select United States.</p>
     </div>

     



    <hr className='h-1 bg-blue-600 w-full mt-10'/>


    <div className="mt-10 flex flex-col">
      <label htmlFor="countryOfDomicile" className="bg-blue-500 flex  text-white p-4 rounded">
        Where is your country of domicile?
      </label>
      <div className='flex p-4 bg-blue-100'>
      <select
        className="border outline-none text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        id="countryOfDomicile"
        name="countryOfDomicile"
        value={formData.countryOfDomicile}
        onChange={handleInputChange}
      >
        <option value="">Select your country of domicile</option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
      </div>
    </div>



    <div className='mt-10 flex flex-col md:flex-row  md:gap-10  md:justify-start'>
  <label htmlFor="countrySelect" className='w-full sm:w-[250px] text-blue-700 mb-2 sm:mb-0 text-center sm:text-left'>Daytime Phone Number</label>
  <div className='flex flex-col md:flex-row'>
  <div className=''>
    <select
      id="countrySelect"
      value={formData.DayTimePhoneNumber}
      onChange={handleInputChange}
      className='w-full h-10 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg sm:mr-2'
    >
      <option value="">Select a Country</option>
      {countryCodes.map((country) => (
        <option key={country.code} value={country.code}>
          {country.code} {country.name}
        </option>
      ))}
    </select>
  </div>
  <input
    value={formData.Mnumber}
    onChange={handleInputChange}
    type="tel"
    placeholder="123-456-7890"
    className='w-full md:w-[300px] border px-7 border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
  />
</div>
</div>

<div className='mt-10 flex flex-col md:flex-row '>
  <label htmlFor="countrySelect" className='w-full sm:w-[250px] text-blue-700 mb-2 sm:mb-0 text-center sm:text-left'>Mobile Phone Number</label>
 <div className='flex flex-col md:flex-row'>
  <div className=''>
    <select
      id="countrySelect"
      value={formData.MobilePhoneNumber}
      onChange={handleInputChange}
      className='w-full h-10 border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg sm:mr-2'
    >
      <option value="">Select a Country</option>
      {countryCodes.map((country) => (
        <option key={country.code} value={country.code}>
          {country.code} {country.name}
        </option>
      ))}
    </select>
  </div>
  <input
    value={formData.Pnumber}
    onChange={handleInputChange}
    type="tel"
    placeholder="123-456-7890"
    className='w-full border  border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg'
  />

</div>
</div>

<div className="mt-10 flex flex-col items-center sm:flex-row sm:gap-2">
  <label htmlFor="email" className='w-full sm:w-[250px] text-blue-700 mb-2 sm:mb-0 text-center sm:text-left'>
    E-mail
  </label>
  <input
    type="email"
    id="email"
    name="email"
    className="w-full sm:w-[350px] border outline-none text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-0 sm:ml-10"
    value={formData.email}
    onChange={handleInputChange}
  />
</div>


    <hr className='h-1 bg-blue-600 w-full mt-10'/>

      <div className='mt-10'>
        <p className='text-blue-600 text-xl font-semibold'>Other Information About Sponsor</p>
      </div>
   
    <div className=''>
        <h1 className='text-blue-600 text-xl font-semibold flex '>Sponsor's Birth Information</h1>
    </div>
      <div className="mt-10 flex flex-col ">
      <label htmlFor="dateOfBirth" className=" p-4 bg-blue-600 text-white  rounded">
        Date of Birth
      </label>
      <div className='flex p-4 bg-blue-100'>
      <input
        type="date"
        id="dateOfBirth"
        name="dateOfBirth"
        className="w-full md:w-[300px] border px-2 outline-none text-slate-700 border-gray-300 hover:border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-10"
        value={formData.dateOfBirth}
        onChange={handleInputChange}
      />
      </div>
    </div>



    
    <div className="mt-10 flex flex-col">
      <label htmlFor="countryOfDomicile" className="bg-blue-500 flex  text-white p-4 rounded">
      Country of Birth
      </label>
      <div className='flex p-4 bg-blue-100'>
      <select
        className="w-full md:w-[300px] border outline-none text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg  md:ml-10"
        id="countryOfDomicile"
        name="countryOfDomicile"
        value={formData.countryofbirth}
        onChange={handleInputChange}
      >
        <option value=""></option>
        {code.map((countryName) => (
          <option key={countryName} value={countryName}>
            {countryName}
          </option>
        ))}
      </select>
      </div>
    </div>
    <p className='bg-slate-200 p-2'>Select the name of the country where you were born (even if it no longer exists).</p>

    <div className="mt-10 flex flex-col   w-full">
  <label htmlFor="birthPlace" className="bg-blue-500 text-white w-full  md:w-full p-4 flex rounded mb-2 sm:mb-0 sm:w-[200px] text-center sm:text-left">
    Town, City, or Village of Birth:
  </label>
  <div className='bg-blue-100 p-4 rounded  '>
    <input
      type="text"
      id="birthPlace"
      name="birthPlace"
      className="w-full md:w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-0 sm:ml-10"
      value={formData.birthPlace}
      onChange={handleInputChange}
    />
  </div>
</div>
    <p className='bg-slate-200 p-2'>Enter the town, city or village where you were born.</p>

    <div className="mt-10">
      <label htmlFor="stateOrProvince"  className="bg-blue-500 text-white w-full p-4 flex rounded ">
        State or Province:
      </label>
      <div className='bg-blue-100 p-4 rounded'>
      <input
        type="text"
        id="stateOrProvince"
        name="stateOrProvince"
        className="w-full md:w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-10"
        value={formData.stateOrProvince}
        onChange={handleInputChange}
      />
      </div>
    </div>
    <p className='bg-slate-200 p-2'>Enter the state or province where you were born.</p>


   <hr className='h-1 bg-blue-600 w-full mt-10'/>
    <div className="mt-10">
      <label htmlFor="uscisOnlineAccountNumber"  className="bg-blue-500 text-white w-full p-4 flex rounded ">
        USCIS Online Account Number:
      </label>
       <div className='bg-blue-100 p-4 rounded'>
      <input
        type="text"
        id="uscisOnlineAccountNumber"
        name="uscisOnlineAccountNumber"
        className="w-full md:w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-10"
        value={formData.uscisOnlineAccountNumber}
        onChange={handleInputChange}
      />
      </div>
    </div>
    <p className='bg-slate-200 p-2'>If you do not have an A-Number, leave this field empty. How to find this</p>


    <div className="mt-10">
      <label htmlFor="socialSecurityNumber"  className="bg-blue-500 text-white w-full p-4 flex rounded ">
        Social Security Number:
      </label>
      <div className='bg-blue-100 p-4 rounded'>
      <input
        type="number"
        id="socialSecurityNumber"
        name="socialSecurityNumber"
        className=" w-full md:w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-10"
        value={formData.socialSecurityNumber}
        onChange={handleInputChange}
      />
      </div>
    </div>
    <p className='bg-slate-200 p-2'>A Social Security number is required to file Form I-864. How to find this >></p>

    <div className="mt-10">
      <label htmlFor="alienRegistrationNumber"  className="bg-blue-500 text-white w-full p-4 flex rounded ">
        Alien Registration Number (A-Number):
      </label>
      <div className='bg-blue-100 p-4 rounded'>
      <input
        type="number"
        id="alienRegistrationNumber"
        name="alienRegistrationNumber"
        className="w-full md:w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg md:ml-10"
        value={formData.alienRegistrationNumber}
        onChange={handleInputChange}
      />
      </div>
    </div>
    <p>If you do not have an A-Number, leave this field empty. How to find this</p>

 






    <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>



    
   </form>
   <FormFotter />

     </div>
     </div>
 
 )
}

export default BasicInformation
