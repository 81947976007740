


import React from 'react'
import { useFormContext } from '../../UseContext/ContextN565';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';

const Eligibility565 = () => {
    
   const navigate=useNavigate()
    const { formData, handleInputChange ,code } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/applicationinfon565")
    }

    console.log(formData.applicationBasis)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>
            
   <div className='flex flex-col'>
  <label className='flex bg-blue-600 p-4 text-white rounded'>
    Are you currently a U.S. citizen?
  </label>
  <div className='flex gap-10 p-4 bg-blue-100'>
    <label htmlFor="isUSCitizenYes" className='text-blue-700 flex gap-1'>
      <input
        type="radio"
        name="isUSCitizen"
        id="isUSCitizenYes"
        value="Yes"
        checked={formData.isUSCitizen === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label htmlFor="isUSCitizenNo" className='text-blue-700 flex gap-1'>
      <input
        type="radio"
        name="isUSCitizen"
        id="isUSCitizenNo"
        value="No"
        checked={formData.isUSCitizen === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>

 {formData.isUSCitizen === 'No'
 &&
 <div className='flex bg-red-200 p-2 flex-col gap-2'>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
        <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue. </span>You must be a current U.S. citizen to file Form N-565. If you would like to apply for U.S. citizenship through naturalization, please use N-400, Application for Naturalization.</p>
    </div>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Form_30.png" alt="" className='w-[30px] h-[30px]'/>
        <p className='font-bold'>Form N-400, Application for Naturalization</p>
    </div>


  </div>}

  {formData.isUSCitizen === 'Yes'
    &&
    <>
    <label className='flex bg-blue-600 p-4 text-white rounded mt-10'>
        Since becoming a U.S. citizen, have you lost or renounced your U.S. citizenship in any manner?
      </label>
      <div className='flex gap-10 p-4 bg-blue-100'>
        <label htmlFor='sinceLostRenouncedCitizenshipYes' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='sinceLostRenouncedCitizenship'
            id='sinceLostRenouncedCitizenshipYes'
            value='Yes'
            checked={formData.sinceLostRenouncedCitizenship === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label htmlFor='sinceLostRenouncedCitizenshipNo' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='sinceLostRenouncedCitizenship'
            id='sinceLostRenouncedCitizenshipNo'
            value='No'
            checked={formData.sinceLostRenouncedCitizenship === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
      <p  className='flex bg-slate-200 p-2'>Indicate if you have ever given up your U.S. citizenship.</p>
      </>
      }

        
  {formData.sinceLostRenouncedCitizenship === 'No'
  &&
    <>
    <label className='flex bg-blue-600 p-4 text-white rounded mt-10'>
    Have you ever served honorably in the U.S. armed forces?
  </label>
  <div className='flex gap-10 p-4 bg-blue-100'>
    <label htmlFor="servedInArmedForcesYes" className='text-blue-700 flex gap-1'>
      <input
        type="radio"
        name="servedInArmedForces"
        id="servedInArmedForcesYes"
        value="Yes"
        checked={formData.servedInArmedForces === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label htmlFor="servedInArmedForcesNo" className='text-blue-700 flex gap-1'>
      <input
        type="radio"
        name="servedInArmedForces"
        id="servedInArmedForcesNo"
        value="No"
        checked={formData.servedInArmedForces === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
  <p className='bg-slate-200 p-2'>
Select "Yes" only if you served honorably in the U.S. Army, Marines, Navy, Air Force, Space Force or Coast Guard. Explain more </p>
  </>
  }

{formData.servedInArmedForces === 'Yes' || formData.servedInArmedForces === 'No'
&&
    <>
    <label className='flex bg-blue-600 p-4 text-white rounded mt-10'>
        Are you currently residing outside the United States?
      </label>
      <div className='flex gap-10 p-4 bg-blue-100'>
        <label htmlFor='residingOutsideUSYes' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='residingOutsideUS'
            id='residingOutsideUSYes'
            value='Yes'
            checked={formData.residingOutsideUS === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label htmlFor='residingOutsideUSNo' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='residingOutsideUS'
            id='residingOutsideUSNo'
            value='No'
            checked={formData.residingOutsideUS === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
      </>
      }

{formData.sinceLostRenouncedCitizenship === 'Yes'
    &&
    <div className='flex bg-red-200 p-2 flex-col gap-2'>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
        <p> <span className='font-bold'>Although you may be eligible to file Form N-565, your situation is complex. </span>Therefore, we recommend that you consult with an experienced immigration attorney instead of using our service.</p>
    </div>
    <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
        <p className='font-bold text-green-500'>Help me find an immigration attorney</p>
    </div>


  </div>
  
  }
  
 {formData.sinceLostRenouncedCitizenship === 'No'
 &&
 <>
 <label className='flex bg-blue-600 p-4 text-white rounded mt-10'>
    Have you lost or renounced your U.S. citizenship in any manner?
  </label>
  <div className='flex gap-10 p-4 bg-blue-100'>
    <label htmlFor="lostCitizenshipYes" className='text-blue-700 flex gap-1'>
      <input
        type="radio"
        name="lostCitizenship"
        id="lostCitizenshipYes"
        value="Yes"
        checked={formData.lostCitizenship === 'Yes'}
        onChange={handleInputChange}
      />
      Yes
    </label>
    <label htmlFor="lostCitizenshipNo" className='text-blue-700 flex gap-1'>
      <input
        type="radio"
        name="lostCitizenship"
        id="lostCitizenshipNo"
        value="No"
        checked={formData.lostCitizenship === 'No'}
        onChange={handleInputChange}
      />
      No
    </label>
  </div>
  </>
}


{formData.residingOutsideUS === 'Yes'
        &&
      <div className='bg-slate-100 p-2 flex gap-2 mt-5'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Info_60.png" alt=""  className='w-[30px] h-[30px]'/>
        <p className=''>For Your Information: You may file Form N-565 from abroad and use a foreign address. If you use a domestic U.S. mailing address, please be advised that USCIS generally will request that domestic filers of Form N-565 attend a biometric appointment. You may use a U.S. mailing address, but you will likely be scheduled for a mandatory biometric appointment inside the United States.</p>
      </div>}

     { formData.residingOutsideUS === 'No' || formData.residingOutsideUS === 'Yes'
     &&
     <>
     <label className='flex bg-blue-600 p-4 text-white rounded mt-10'>
        Which type of document are you replacing?
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col'>
        <label htmlFor='documentTypeNaturalization' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='documentType'
            id='documentTypeNaturalization'
            value='Certificate of Naturalization'
            checked={formData.documentType === 'Certificate of Naturalization'}
            onChange={handleInputChange}
          />
          Certificate of Naturalization
        </label>
        <label htmlFor='documentTypeCitizenship' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='documentType'
            id='documentTypeCitizenship'
            value='Certificate of Citizenship'
            checked={formData.documentType === 'Certificate of Citizenship'}
            onChange={handleInputChange}
          />
          Certificate of Citizenship
        </label>
        <label htmlFor='documentTypeOther' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='documentType'
            id='documentTypeOther'
            value='Other type of certificate or declaration'
            checked={formData.documentType === 'Other type of certificate or declaration'}
            onChange={handleInputChange}
          />
          Other type of certificate or declaration
        </label>
      </div>
      </>
}
   
{  (formData.documentType === 'Certificate of Naturalization' || formData.documentType === 'Certificate of Citizenship')
    &&
    <div className='flex flex-col'>
     <label className='flex bg-blue-600 p-4 text-white rounded mt-10'>
       Were you previously issued this document?
     </label>
     <div className='flex gap-10 p-4 bg-blue-100'>
       <label htmlFor='previouslyIssuedYes' className='text-blue-700 flex gap-1'>
         <input
           type='radio'
           name='previouslyIssued'
           id='previouslyIssuedYes'
           value='Yes'
           checked={formData.previouslyIssued === 'Yes'}
           onChange={handleInputChange}
         />
         Yes
       </label>
       <label htmlFor='previouslyIssuedNo' className='text-blue-700 flex gap-1'>
         <input
           type='radio'
           name='previouslyIssued'
           id='previouslyIssuedNo'
           value='No'
           checked={formData.previouslyIssued === 'No'}
           onChange={handleInputChange}
         />
         No
       </label>
     </div>
   </div>}

  {formData.documentType === 'Other type of certificate or declaration'
  &&
  <div className='flex bg-red-200 p-2 flex-col gap-2'>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p> <span className='font-bold'>Although you may be eligible to file Form N-565, your situation is complex. </span>Therefore, we recommend that you consult with an experienced immigration attorney instead of using our service.</p>
  </div>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p className='font-bold text-green-500'>Help me find an immigration attorney</p>
  </div>


</div>
      
      
      }
      

      {formData.previouslyIssued === 'Yes'
        &&
        <div className='flex flex-col mt-10'>
      <label className='flex bg-blue-600 p-4 text-white rounded'>
        How did you become a U.S. citizen?
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col'>
        <label htmlFor='naturalization' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='howBecameUSCitizen'
            id='naturalization'
            value='Naturalization'
            checked={formData.howBecameUSCitizen === 'Naturalization'}
            onChange={handleInputChange}
          />
          Through my naturalization
        </label>
        <label htmlFor='parents' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='howBecameUSCitizen'
            id='parents'
            value='Parents'
            checked={formData.howBecameUSCitizen === 'Parents'}
            onChange={handleInputChange}
          />
          Through my parents
        </label>
        <label htmlFor='birthInUS' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='howBecameUSCitizen'
            id='birthInUS'
            value='Birth'
            checked={formData.howBecameUSCitizen === 'Birth'}
            onChange={handleInputChange}
          />
          Through birth in the United States
        </label>
      </div>
      <p className='bg-slate-200 p-2'>Please confirm that you were issued the certificate at some point in the past.</p>
    </div>
    
}

{formData.previouslyIssued === 'No'
    &&
    <div className='flex bg-red-200 p-2 flex-col gap-2'>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue. </span>ou may only file Form N-565 to replace a document that you were previously issued. If you believe that you acquired citizenship through parents, you may need to obtain an initial Certificate of Citizenship.</p>
  </div>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p className='font-bold text-blue-500'>Form N-600, Application for Certificate of Citizenship</p>
  </div>


</div>}

{formData.howBecameUSCitizen === 'Naturalization'
    &&
    
    <div className='flex flex-col mt-10'>
      <label className='flex bg-blue-600 p-4 text-white rounded'>
        What is the basis for this application?
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col'>
        <label htmlFor='lostCertificate' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='applicationBasis'
            id='lostCertificate'
            value='Lost'
            checked={formData.applicationBasis === 'Lost'}
            onChange={handleInputChange}
          />
          My certificate was lost, stolen, or destroyed.
        </label>
        <label htmlFor='mutilatedCertificate' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='applicationBasis'
            id='mutilatedCertificate'
            value='Mutilated'
            checked={formData.applicationBasis === 'Mutilated'}
            onChange={handleInputChange}
          />
          My certificate is mutilated.
        </label>
        <label htmlFor='typographicalError' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='applicationBasis'
            id='typographicalError'
            value='TypographicalError'
            checked={formData.applicationBasis === 'TypographicalError'}
            onChange={handleInputChange}
          />
          My certificate is incorrect due to a typographical/clerical error.
        </label>
        <label htmlFor='biographicUpdate' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='applicationBasis'
            id='biographicUpdate'
            value='BiographicUpdate'
            checked={formData.applicationBasis === 'BiographicUpdate'}
            onChange={handleInputChange}
          />
          My biographic information needs to be updated.
        </label>
      </div>
    </div>
}



{formData.howBecameUSCitizen === 'Parents'
    &&
    <div className='flex bg-red-200 p-2 flex-col gap-2'>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue </span>You selected a path to citizenship that doesn't match your requested document type. Let's make sure you understand the correct type of document to request before continuing. Select the "explain more" link in the previous question, or you can learn more about each document type through the links below:</p>
  </div>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p className='font-bold text-blue-500'>Certificate of Citizenship</p>
  </div>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p className='font-bold text-blue-500'>Certificate of Naturalization</p>
  </div>



</div>
}

{formData.howBecameUSCitizen === 'Parents'
    &&
    <div className='flex bg-red-200 p-2 flex-col gap-2'>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue</span>Persons born within the United States (including the territories of Puerto Rico, Guam, the U.S. Virgin Islands, and the Northern Mariana Islands) are automatically granted U.S. citizenship. You would use your birth certificate as evidence of U.S. citizenship. Natural-born U.S. citizens are not eligible for a Certificate of Citizenship or Certificate of Naturalization</p>
  </div>




</div>
}

           
{formData.applicationBasis === 'BiographicUpdate'
&&
    <>
    <label className='flex bg-blue-600 p-4 text-white rounded'>
        What are the specific changes you would like to make?
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col'>
        <label htmlFor='nameChanged' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='specificChanges'
            id='nameChanged'
            value='NameChanged'
            checked={formData.specificChanges === 'NameChanged'}
            onChange={handleInputChange}
          />
          My name was legally changed.
        </label>
        <label htmlFor='DOBChanged' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='specificChanges'
            id='DOBChanged'
            value='DOBChanged'
            checked={formData.specificChanges === 'DOBChanged'}
            onChange={handleInputChange}
          />
          My date of birth has legally changed.
        </label>
        <label htmlFor='genderChanged' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='specificChanges'
            id='genderChanged'
            value='GenderChanged'
            checked={formData.specificChanges === 'GenderChanged'}
            onChange={handleInputChange}
          />
          My gender has legally changed.
        </label>
      </div>
      </>
      }
{(formData.applicationBasis === 'TypographicalError'  || formData.applicationBasis === 'BiographicUpdate')
&&
    <>
    <div className='bg-slate-200 p-2 mt-5 flex gap-2'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Warning_30.png" alt="" className='w-[30px] h-[30px]'/>
    <p> <span className='font-bold'> Be advised: </span>Use this reason only if the mistake on your certificate was due to a typographical/clerical error made by USCIS or DHS. If the incorrect certificate was due to your own mistake, go back and select "My biographic information needs to be updated."</p>
</div>


<label className='flex bg-blue-600 p-4 text-white rounded mt-5'>
        Do you have your original certificate?
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col '>
        <label htmlFor='haveOriginalCertificateYes' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='haveOriginalCertificate'
            id='haveOriginalCertificateYes'
            value='Yes'
            checked={formData.haveOriginalCertificate === 'Yes'}
            onChange={handleInputChange}
          />
          Yes, I have my certificate.
        </label>
        <label htmlFor='haveOriginalCertificateNo' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='haveOriginalCertificate'
            id='haveOriginalCertificateNo'
            value='No'
            checked={formData.haveOriginalCertificate === 'No'}
            onChange={handleInputChange}
          />
          No, it was lost, stolen, or destroyed.
        </label>
      </div>
      </>
      }

    
    
     {   (formData.haveOriginalCertificate === 'Yes'  || formData.haveOriginalCertificate === 'No' || formData.applicationBasis === 'Lost' || formData.applicationBasis === 'Mutilated' || formData.specificChanges === 'NameChanged' ||  formData.specificChanges === 'GenderChanged')
        &&
        <div className='bg-green-300 p-2  flex gap-2 rounded mt-20'>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt="" className='w-[30px] h-[30px]'/>
    <p> <span className='font-bold'> Congratulations, you are likely eligible to file Form N-565, Application for Replacement Naturalization/Citizenship Document. </span>Use this reason only if the mistake on your certificate was due to a typographical/clerical error made by USCIS or DHS. If the incorrect certificate was due to your own mistake, go back and select "My biographic information needs to be updated."</p>
</div>}

{formData.specificChanges === 'DOBChanged'
&&
<div className='flex bg-red-200 p-2 flex-col gap-2'>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p> <span className='font-bold'>We found a problem. Based on your answer, you cannot continue </span>You selected a path to citizenship that doesn't match your requested document type. Let's make sure you understand the correct type of document to request before continuing. Select the "explain more" link in the previous question, or you can learn more about each document type through the links below:</p>
  </div>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p className='font-bold text-blue-500'>Certificate of Citizenship</p>
  </div>
  <div className='flex gap-2'>
      <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" className='w-[30px] h-[30px]'/>
      <p className='font-bold text-blue-500'>Certificate of Naturalization</p>
  </div>



</div>}

   

          







  
  
  

</div>
     



  





<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default Eligibility565