import React from "react"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { MdArrowDropDown } from "react-icons/md"
import { BsArrowRight } from "react-icons/bs"
import Header from "../../components/Header"
import { useNavigate } from "react-router-dom"
import FormFotter from "../../components/FormFotter"
import { useFormContext } from "../../UseContext/Context485"

const ApplicantsParticipation = () => {
  const {
    formData,
    handleInputChange,
    code,
    nameSets,
    handleAddAnother,
    handleRemove,
    handleChange,
  } = useFormContext()
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    navigate("/FinalDetails")
  }


  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>
            verified and security
          </span>
          <span className='font-bold text-white bg-blue-600 text-xl'>
            Application For Employment Authorization
          </span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>
              Save
            </button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant's Participation in Organizations
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 12 of 14</button>
                <button>
                  <MdArrowDropDown />
                </button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin mb-2 ml-2'>
            List your present and past membership in or affiliation with all
            organizations in the United States or any other country. Include any
            military service. Examples include religious organizations,
            professional associations, political groups and social clubs.
          </span>
          <span className='mt-2 font-thin ml-2'>
            Note: USCIS may evaluate your affiliations with certain
            organizations that may be illegal or against the general principles
            of American democracy. Use your best judgement to determine if your
            involvement with a group or organization conflicts with these
            principles. If you are unsure, talk to an attorney.
          </span>
        </div>
        <form action='' className='mt-8 w-11/12' onSubmit={handleSubmit}>
          <div className='mt-10'>
            <label className='flex bg-blue-600 text-white p-4 rounded-md'>
              Have you EVER been a member of, involved in, or in any way
              associated with any organization, association, fund, foundation,
              party, club, society, or similar group in the United States or in
              any other location in the world including any military service?
            </label>
            <div className='flex bg-blue-100 p-4 gap-4 text-blue-600'>
              <label>
                <input
                  type='radio'
                  name='invlolvedmentIntheOrganization'
                  value='Yes'
                  onChange={handleInputChange}
                  checked={formData.invlolvedmentIntheOrganization === "Yes"}
                />
                Yes
              </label>
              <label>
                <input
                  type='radio'
                  name='invlolvedmentIntheOrganization'
                  value='No'
                  onChange={handleInputChange}
                  checked={formData.invlolvedmentIntheOrganization === "No"}
                />
                No
              </label>
            </div>
          </div>

          {formData.invlolvedmentIntheOrganization === "Yes" ? (
            <>
              <div className='mt-4 grid gap-4 bg-gray-100 p-4'>
                <h1 className='flex text-blue-600 font-semibold text-xl'>
                  Organization
                </h1>

                <div className='flex items-center ml-4'>
                  <label className='w-[300px] text-blue-500'>
                    Name of Organization
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='text'
                      name='organizationName'
                      value={formData.organizationName}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    Nature of Group
                  </label>
                  <span className='flex text-blue-600'>
                    <select
                      id='groupName'
                      name='groupName'
                      value={formData.groupName}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    >
                      <option value=''>Please select...</option>
                      <option>Religious</option>
                      <option>Professional Association</option>
                      <option>Political Group</option>
                      <option>Social Club</option>
                      <option>Military Service</option>
                      <option>Other</option>
                    </select>
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='w-[300px] text-blue-500'>Country</label>
                  <span className='flex text-blue-600'>
                    <select
                      id='orgCountry'
                      name='orgCountry'
                      value={formData.orgCountry}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    >
                      <option value=''>Please select...</option>
                      {code.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    State or Province
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='text'
                      name='orgStateOrProvince'
                      value={formData.orgStateOrProvince}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>
                    City or Town
                  </label>
                  <span className='flex text-blue-600'>
                    <input
                      type='text'
                      name='orgCityOrTown'
                      value={formData.orgCityOrTown}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>

                <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>Date From</label>
                  <span className='flex text-blue-600'>
                    <input
                      type='date'
                      name='orgDateFrom'
                      value={formData.orgDateFrom}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>

                {formData.presentMember ? <></>:<>
                
                        <div className='flex items-center ml-4'>
                  <label className='text-blue-500 w-[300px]'>Date To</label>
                  <span className='flex text-blue-600'>
                    <input
                      type='date'
                      name='orgDateTo'
                      value={formData.orgDateTo}
                      onChange={handleInputChange}
                      className='w-[300px] border outline-none text-slate-700 border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg'
                    />
                  </span>
                </div>
                </>}
                
                <div className='ml-4'>
                  <input
                    name='presentMember'
                    type='checkbox'
                    value={formData.presentMember ?? ""}
                    onChange={handleInputChange}
                  />
                  <label htmlFor='presentMember' className='text-blue-500 ml-4'>
                  I am presently a member of this organization
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className='flex text-white mt-32 w-[80px] h-[30px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow'>
              <button type='submit' className='text-white rounded'>
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>
              or <span className='text-blue-500'>Save Your progress?</span>
            </span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  )
}

export default ApplicantsParticipation
