
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import { useNavigate } from 'react-router-dom';
import crosspic from "../../../src/Assets/crosspic.png"


const TravelInformation = () => {
    const { formData, handleInputChange, code ,nameSets ,handleAddAnother ,handleRemove ,handleChange} = useFormContext();

    const  handleSubmit =(e)=>{
        e.preventDefault()
    }
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
        <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
        <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
        <div className='h-full'>
          <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
          <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
        </div>
      </div>

      <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
        <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
          <span className='mt-4 font-bold text-xl ml-2 text-black'>
          Address Information
          </span>
          <div className='flex justify-between items-center gap-4'>
            <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
            <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
              <AiOutlineShoppingCart />
            </div>
          </div>
        </div>
        <span className='mt-2 font-thin ml-2'>
        Provide your current mailing address and contact information.
        </span>
      </div>
      <form action=" " className='mt-14 w-11/12' onSubmit={handleSubmit}>

      <div>
      <label className="flex bg-blue-600 w-full rounded p-4 text-white mt-10">
        Have you left the United States for any period since your last Form I-821D that was approved?
      </label>
      <div className="flex bg-blue-100 p-4 w-full gap-2">
        <input
          type="radio"
          id="leftUSYes"
          name="leftUSPeriod"
          value="Yes"
          checked={formData.leftUSPeriodSinceLastI821D === 'Yes'}
          onChange={handleInputChange}
        />
        <label for="leftUSYes">Yes</label>

        <input
          type="radio"
          id="leftUSNo"
          name="leftUSPeriod"
          value="No"
          checked={formData.leftUSPeriodSinceLastI821D === 'No'}
          onChange={handleInputChange}
        />
        <label for="leftUSNo">No</label>
      </div>
    </div>



    <div className="mt-10">
    {nameSets.map((nameSet, index) => (
  <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 mt-10'>
    {index > 0 && (
      <div className='flex w-full justify-between ml-2'>
        <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
        <button
          type="button"
          onClick={() => handleRemove(nameSet.id)}
          className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
        >
          Remove
          <img src={crosspic} alt="" />
        </button>
      </div>
    )}

    <div className="mb-4 flex mt-6 ml-2">
      <label htmlFor={`departureDate-${nameSet.id}`} className="text-blue-700">
        Departure Date: <span className="text-red-500">*</span>
      </label>
      <input
        type="date" // Change input type to date
        id={`departureDate-${nameSet.id}`}
        name={`departureDate-${nameSet.id}`}
        className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        value={nameSet.departureDate || ''}
        onChange={(e) => handleChange(e, nameSet.id, 'departureDate')}
      />
    </div>

    <div className="mb-4 flex mt-6 ml-2">
      <label htmlFor={`returnDate-${nameSet.id}`} className="text-blue-700">
        Return Date: <span className="text-red-500">*</span>
      </label>
      <input
        type="date" // Change input type to date
        id={`returnDate-${nameSet.id}`}
        name={`returnDate-${nameSet.id}`}
        className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        value={nameSet.returnDate || ''}
        onChange={(e) => handleChange(e, nameSet.id, 'returnDate')}
      />
    </div>

    <div className="mb-4 flex mt-6 ml-2">
      <label htmlFor={`reason-${nameSet.id}`} className="text-blue-700">
        Reason for: <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        id={`reason-${nameSet.id}`}
        name={`reason-${nameSet.id}`}
        className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        value={nameSet.reason || ''}
        onChange={(e) => handleChange(e, nameSet.id, 'reason')}
      />
    </div>

                    {/* ... Other input fields ... */}

                  </div>
                ))}

                {nameSets.length === 0 && (
                  <div>
                    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                    <span className="text-gray-500">Add Other</span>
                  </div>
                )}

                <button
                  type="button"
                  onClick={handleAddAnother}
                  className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
                >
                  Add Another Name
                </button>
              </div>



    <div className="mb-4 flex mt-6 ml-2 flex-col">
  <label className="text-white p-4 bg-blue-700">
    Have you left the United States without advance parole on or after August 15, 2012?
  </label>
  <div className='flex gap-2 p-4 bg-blue-100'>
  <input
    type="radio"
    name="leftWithoutAdvanceParole"
    value="Yes"
    checked={formData.leftWithoutAdvanceParole === "Yes"}
    onChange={handleChange}
  />
  <label>Yes</label>
  <input
    type="radio"
    name="leftWithoutAdvanceParole"
    value="No"
    checked={formData.leftWithoutAdvanceParole === "No"}
    onChange={handleAddAnother}
  />
  <label>No</label>
</div>
</div>



<div className="mb-4 flex mt-6 ml-2 flex-col">
  <label className="bg-blue-700 text-white  p-4 ">
    Have you ever had a passport?
  </label>
  <div className='bg-blue-100 p-4 gap-2 flex  '>
  <div className="radio-button-container">
    <input
      type="radio"
      name="hadPassport"
      value="Yes"
      checked={formData.hadPassport === "Yes"}
      onChange={handleChange}
    />
    <label className="radio-button-label">Yes</label>
  </div>
  <div className="radio-button-container">
    <input
      type="radio"
      name="hadPassport"
      value="No"
      checked={formData.hadPassport === "No"}
      onChange={handleChange}
    />
    <label className="radio-button-label">No</label>
  </div>
</div>
</div>

<hr  className='h-1 bg-blue-700 mt-10 ' />


<div className='flex flex-col gap-2 mt-10'>
    <h1 className='text-blue-700 text-xl font-semibold'>Passport Details</h1>
    <p>Enter your passport details. The country that issued your passport will be printed on the front cover and/or near the top of the page with your photo. See How To Read Your Passport for answers and examples.</p>
</div>



<div className='mt-10'>
  <label htmlFor="countryIssued" className='text-blue-500'>
    Country That Issued Your Last Passport
  </label>
  <select
  className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
  name="selectedCountry"
  value={formData.selectedCountry || ''}
  onChange={handleInputChange}
>
  <option value="">Select a Country</option>
  {code.map((countryName) => (
    <option key={countryName} value={countryName}>
      {countryName}
    </option>
  ))}
</select>
</div>
 

<div className='flex gap-20 mt-10'>
<label htmlFor="passportNumber" className="text-blue-500">
  Passport Number
</label>
<input
  type="number"
  id="passportNumber"
  name="passportNumber"
  className="w-[400px] border outline-none text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-28"
  value={formData.passportNumber}
  onChange={handleInputChange}
/>
</div>

<div className='flex gap-10 mt-10'>

<label htmlFor="passportExpirationDate" className="text-blue-500">
  Passport Expiration Date
</label>
<input
  type="date"
  id="passportExpirationDate"
  name="passportExpirationDate"
  className="w-[400px] border outline-none text-slate-700 border-gray-300 hover:border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-20"
  value={formData.passportExpirationDate}
  onChange={handleInputChange}
/>
</div>


<hr  className='h-1 bg-blue-700 w-full mt-10'/>

<label className='text-white bg-blue-700 p-4 flex  mt-10'>Have you ever had a Border Crossing Card?</label>
<div className='flex items-center  p-4 bg-blue-100'>
  <label className='mr-4'>
    <input
      type='radio'
      name='borderCrossingCard'
      value='Yes'
      checked={formData.borderCrossingCard === 'Yes'}
      onChange={handleInputChange}
    />
    Yes
  </label>
  <label>
    <input
      type='radio'
      name='borderCrossingCard'
      value='No'
      checked={formData.borderCrossingCard === 'No'}
      onChange={handleInputChange}
    />
    No
  </label>
</div>

<div className='mt-4'>
  <label htmlFor='borderCrossingCardNumber' className='text-blue-500'>
    Border Crossing Card Number
  </label>
  <input
    type='text'
    id='borderCrossingCardNumber'
    name='borderCrossingCardNumber'
    value={formData.borderCrossingCardNumber}
    onChange={handleInputChange}
    className="w-[400px] border outline-none text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
  />
</div>























<div className='flex  text-white mt-32  ml-auto w-[100px]'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
            <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>


     
    </form>
    <FormFotter />

      </div>
      </div>
  
  )
}

export default TravelInformation
