// Eligibility.js

import React, { useState } from 'react';
import Header from '../../components/Header';
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import FormFotter from '../../components/FormFotter';
import { useFormContext  } from '../../UseContext/UseContextForI-I29F';
import { useNavigate } from 'react-router-dom';

const Eligibility = () => {

    const navigate =useNavigate()
    const {state, setFormState } = useFormContext();
    const {
        isCitizenValid,
        isBeneficiaryValid,
        isAgeValid,
        hasServedValid,
        isRelatedValid,
        isFiledAnswerValid,
        isFileI130AnswerValid,
        isRestrainingOrderAnswerValid,
        isDomesticViolenceAnswerValid,
        isHomicideAnswerValid,
        isRestrainingOrderValid,
        isDomesticViolenceValid,
        isHomicideValid,
        isLawViolationValid,
        isSubstanceAbuseValid,
        isImmigrationProceedingsValid,
        isMarriageEvadingValid,
        isCrimeChargedValid,
        // Add other states as needed
      } = state;

      const handleCitizenChange = (value) => {
        // Do any additional logic if needed
        const isValid = value === 'yes' || value === 'no';
        
        // Update the global state when the radio button changes
        setFormState('SET_IS_CITIZEN_VALID', isValid);
        setFormState('SET_IS_BENEFICIARY_VALID', value === 'no');
      };

      const handleBeneficiaryChange = (value) => {
        // Do any additional logic if needed
        const isValid = value === 'yes' || value === 'no';
      
        // Update the global state when the radio button changes
        setFormState('SET_IS_BENEFICIARY_VALID', isValid);
          // If "Y
      };
      const handleAgeChange = (value) => {
        // Replace placeholder logic with your actual age validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_AGE_VALID', isValid);
      };
    
      const handleServedChange = (value) => {
        // Replace placeholder logic with your actual served validation logic
        const isValid = value === 'yes';
        setFormState('SET_HAS_SERVED_VALID', isValid);
      };
    
      const handleRelatedChange = (value) => {
        // Replace placeholder logic with your actual related validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_RELATED_VALID', isValid);
      };
    
      const handleFiledAnswerChange = (value) => {
        // Replace placeholder logic with your actual filed answer validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_FILED_ANSWER_VALID', isValid);
      };
    
      const handleFileI130AnswerChange = (value) => {
        // Replace placeholder logic with your actual File I-130 answer validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_FILE_I130_ANSWER_VALID', isValid);
      };

      const handleRestrainingOrderAnswerChange = (value) => {
        // Replace placeholder logic with your actual restraining order answer validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_RESTRAINING_ORDER_ANSWER_VALID', isValid);
      };
    
      const handleDomesticViolenceAnswerChange = (value) => {
        // Replace placeholder logic with your actual domestic violence answer validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_DOMESTIC_VIOLENCE_ANSWER_VALID', isValid);
      };
    
      const handleHomicideAnswerChange = (value) => {
        // Replace placeholder logic with your actual homicide answer validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_HOMICIDE_ANSWER_VALID', isValid);
      };
    
      const handleRestrainingOrderChange = (value) => {
        // Replace placeholder logic with your actual restraining order validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_RESTRAINING_ORDER_VALID', isValid);
      };
    
      const handleDomesticViolenceChange = (value) => {
        // Replace placeholder logic with your actual domestic violence validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_DOMESTIC_VIOLENCE_VALID', isValid);
      };
    
      const handleHomicideChange = (value) => {
        // Replace placeholder logic with your actual homicide validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_HOMICIDE_VALID', isValid);
      };
    
      const handleLawViolationChange = (value) => {
        // Replace placeholder logic with your actual law violation validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_LAW_VIOLATION_VALID', isValid);
      };
    
      const handleSubstanceAbuseChange = (value) => {
        // Replace placeholder logic with your actual substance abuse validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_SUBSTANCE_ABUSE_VALID', isValid);
      };
    
      const handleImmigrationProceedingsChange = (value) => {
        // Replace placeholder logic with your actual immigration proceedings validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_IMMIGRATION_PROCEEDINGS_VALID', isValid);
      };
    
      const handleMarriageEvadingChange = (value) => {
        // Replace placeholder logic with your actual marriage evading validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_MARRIAGE_EVADING_VALID', isValid);
      };
    
      const handleCrimeChargedChange = (value) => {
        // Replace placeholder logic with your actual crime charged validation logic
        const isValid = value === 'yes' || value === 'no';
        setFormState('SET_IS_CRIME_CHARGED_VALID', isValid);
      };
    
    
    const isFormValid = isCitizenValid && isBeneficiaryValid;

    const handleSubmit = (e) => {
        e.preventDefault();
       

        navigate("/Pinfo")
        // Log the current state
        console.log('Current state:', state);
      
        // Perform other submit logic
        // ...
      };


    
   
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto' >
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:'>
            <span className='mt-4 font-bold text-xl ml-2 text-black '>
              First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
            </span>
            <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
              <button className='mr-2'>Page 1 of 3</button>
              <button><MdArrowDropDown /></button>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
        </div>

        <form action=" " className='mt-14 w-11/12' onSubmit={handleSubmit}>
        <div className={`gap-10 p-4 text-white rounded bg-${isCitizenValid ? 'blue' : 'red'}-700`}>
  Are you a U.S. citizen?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isCitizenValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="yes1"
      name="citizen"
      value="yes"
      onChange={() => handleCitizenChange('yes')}
    />
    <label htmlFor="yes1" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="no1"
      name="citizen"
      value="no"
      onChange={() => handleCitizenChange('no')}
    />
    <label htmlFor="no1" className='text-blue-500'>No</label>
  </div>
</div>

{isCitizenValid && (
  <p>Some additional information for U.S. citizens.</p>
)}

{!isCitizenValid && (
  <>
    <div className={`gap-10 p-4 mt-14 text-white rounded bg-${isBeneficiaryValid ? 'blue' : 'red'}-700`}>
      Did you meet your beneficiary through the services of an International Marriage Broker?
      <span className="text-red-500">*</span>
    </div>
    <div className={`flex gap-10 p-4 rounded bg-${isBeneficiaryValid ? 'slate' : 'red'}-200`}>
      <div className='flex gap-2 '>
        <input
          type="radio"
          id="yes2"
          name="beneficiary"
          value="yes"
          onChange={() => handleBeneficiaryChange('yes')}
        />
        <label htmlFor="yes2" className='text-blue-500'>Yes</label>
      </div>
      <div className='flex gap-2'>
        <input
          type="radio"
          id="no2"
          name="beneficiary"
          value="no"
          onChange={() => handleBeneficiaryChange('no')}
        />
        <label htmlFor="no2" className='text-blue-500'>No</label>
      </div>
    </div>
  </>
)}



<div className={` gap-10 p-4 mt-14 text-white rounded bg-${isAgeValid ? 'blue' : 'red'}-700`}>
          Are you at least 18 years of age?
            <span className="text-red-500">*</span>
          </div>
          <div className={`flex gap-10 p-4 rounded bg-${isAgeValid ? 'slate' : 'red'}-200`}>
            <div className='flex gap-2 '>
              <input
                type="radio"
                id="yes3"
                name="ageanswer"
                value="yes"
                onChange={() =>handleAgeChange('yes')}
              />
              <label htmlFor="yes3" className='text-blue-500'>Yes</label>
            </div>
            <div className='flex gap-2'>
              <input
                type="radio"
                id="no3"
                name="ageanswer"
                value="no"
                onChange={() => handleAgeChange('no')}
              />
              <label htmlFor="no3" className='text-blue-500'>No</label>
            </div>
          </div>


          <div className={` gap-10 p-4 mt-14 text-white rounded bg-${hasServedValid ? 'blue' : 'red'}-700`}>
          Have you ever served honorably in the U.S. armed forces?
            <span className="text-red-500">*</span>
          </div>
          <div className={`flex gap-10 p-4 rounded bg-${hasServedValid ? 'slate' : 'red'}-200`}>
            <div className='flex gap-2 '>
              <input
                type="radio"
                id="yes4"
                name="servedanswer"
                value="yes"
                onChange={() => handleServedChange('yes')}
              />
              <label htmlFor="yes4" className='text-blue-500'>Yes</label>
            </div>
            <div className='flex gap-2'>
              <input
                type="radio"
                id="no4"
                name="servedanswer"
                value="no"
                onChange={() => handleServedChange('no')}
              />
              <label htmlFor="no4" className='text-blue-500'>No</label>
            </div>
          </div>

          


          <div className={` gap-10 p-4 mt-14 text-white rounded bg-${isRelatedValid ? 'blue' : 'red'}-700`}>
          How is your beneficiary related to you
            <span className="text-red-500">*</span>
          </div>
          <div className={`flex gap-10 p-4 rounded bg-${isRelatedValid ? 'slate' : 'red'}-200`}>
            <div className='flex gap-2 '>
              <input
                type="radio"
                id="yes5"
                name="relatedanswer"
                value="yes"
                onChange={() => handleRelatedChange('yes')}
              />
              <label htmlFor="yes5" className='text-blue-500'>Fiance`</label>
            </div>
            <div className='flex gap-2'>
              <input
                type="radio"
                id="no5"
                name="relatedanswer"
                value="no"
                onChange={() => handleRelatedChange('no')}
              />
              <label htmlFor="no4" className='text-blue-500'>Spouse</label>
            </div>
          </div>




          <div className={` gap-10 p-4 mt-14 text-white rounded bg-${isFiledAnswerValid ? 'blue' : 'red'}-700`}>
          Have you EVER filed Form I-129F for any other beneficiary?
            <span className="text-red-500">*</span>
          </div>
          <div className={`flex gap-10 p-4 rounded bg-${isFiledAnswerValid ? 'slate' : 'red'}-200`}>
            <div className='flex gap-2 '>
              <input
                type="radio"
                id="yes6"
                name="filedanswer"
                value="yes"
                onChange={() => handleFiledAnswerChange('yes')}
              />
              <label htmlFor="yes6" className='text-blue-500'>Yes</label>
            </div>
            <div className='flex gap-2'>
              <input
                type="radio"
                id="no6"
                name="filedanswer"
                value="no"
                onChange={() =>handleFiledAnswerChange ('no')}
              />
              <label htmlFor="no6" className='text-blue-500'>No</label>
            </div>
          </div>


          <div className={`gap-10 p-4 mt-14 text-white rounded bg-${isFileI130AnswerValid ? 'blue' : 'red'}-700`}>
  Will you file Form I-130 concurrently with this petition?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isFileI130AnswerValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="fileI130Yes"
      name="fileI130Answer"
      value="yes"
      onChange={() =>handleFileI130AnswerChange('yes')}
    />
    <label htmlFor="fileI130Yes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="fileI130No"
      name="fileI130Answer"
      value="no"
      onChange={() => handleFileI130AnswerChange('no')}
    />
    <label htmlFor="fileI130No" className='text-blue-500'>No</label>
  </div>
</div>

          

<div className={` gap-10 p-4 mt-14 text-white rounded bg-${isRestrainingOrderAnswerValid ? 'blue' : 'red'}-700`}>
  Been subject to a temporary or permanent protection or restraining order (either civil or criminal)?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isRestrainingOrderAnswerValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="restrainingOrderYes"
      name="restrainingOrderAnswer"
      value="yes"
      onChange={() =>  handleRestrainingOrderAnswerChange('yes')}
    />
    <label htmlFor="restrainingOrderYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="restrainingOrderNo"
      name="restrainingOrderAnswer"
      value="no"
      onChange={() =>  handleRestrainingOrderAnswerChange('no')}
    />
    <label htmlFor="restrainingOrderNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={` gap-10 p-4 mt-14 text-white rounded bg-${isDomesticViolenceAnswerValid ? 'blue' : 'red'}-700`}>
  Been arrested or convicted of domestic violence, sexual assault, child abuse, child neglect, dating violence, elder abuse, stalking, or an attempt to commit any of these crimes?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isDomesticViolenceAnswerValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="domesticViolenceYes"
      name="domesticViolenceAnswer"
      value="yes"
      onChange={() =>handleDomesticViolenceAnswerChange('yes')}
    />
    <label htmlFor="domesticViolenceYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="domesticViolenceNo"
      name="domesticViolenceAnswer"
      value="no"
      onChange={() => handleDomesticViolenceAnswerChange('no')}
    />
    <label htmlFor="domesticViolenceNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={` gap-10 p-4 mt-14 text-white rounded bg-${isHomicideAnswerValid ? 'blue' : 'red'}-700`}>
  Been arrested or convicted of homicide, murder, manslaughter, rape, abusive sexual contact, sexual exploitation, incest, torture, trafficking, peonage, holding hostage, involuntary servitude, slave trade, kidnapping, abduction, unlawful criminal restraint, false imprisonment, or an attempt to commit any of these crimes?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isHomicideAnswerValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="homicideYes"
      name="homicideAnswer"
      value="yes"
      onChange={() =>  handleHomicideAnswerChange('yes')}
    />
    <label htmlFor="homicideYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="homicideNo"
      name="homicideAnswer"
      value="no"
      onChange={() => handleHomicideAnswerChange('no')}
    />
    <label htmlFor="homicideNo" className='text-blue-500'>No</label>
  </div>
</div>


          


<div className={` gap-10 p-4 mt-14 text-white rounded bg-${isRestrainingOrderValid ? 'blue' : 'red'}-700`}>
  Been subject to a temporary or permanent protection or restraining order (either civil or criminal)?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isRestrainingOrderValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="restrainingOrderYes"
      name="restrainingOrderAnswer"
      value="yes"
      onChange={() => handleRestrainingOrderChange('yes')}
    />
    <label htmlFor="restrainingOrderYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="restrainingOrderNo"
      name="restrainingOrderAnswer"
      value="no"
      onChange={() => handleRestrainingOrderChange('no')}
    />
    <label htmlFor="restrainingOrderNo" className='text-blue-500'>No</label>
  </div>
</div>


          


<div className={`gap-10 p-4 mt-14 text-white rounded bg-${isDomesticViolenceValid ? 'blue' : 'red'}-700`}>
  Been arrested or convicted of domestic violence, sexual assault, child abuse, child neglect, dating violence, elder abuse, stalking, or an attempt to commit any of these crimes?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isDomesticViolenceValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="domesticViolenceYes"
      name="domesticViolenceAnswer"
      value="yes"
      onChange={() =>handleDomesticViolenceChange('yes')}
    />
    <label htmlFor="domesticViolenceYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="domesticViolenceNo"
      name="domesticViolenceAnswer"
      value="no"
      onChange={() => handleDomesticViolenceChange('no')}
    />
    <label htmlFor="domesticViolenceNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={`gap-10 p-4 mt-14 text-white rounded bg-${isHomicideValid ? 'blue' : 'red'}-700`}>
  Been arrested or convicted of homicide, murder, manslaughter, rape, abusive sexual contact, sexual exploitation, incest, torture, trafficking, peonage, holding hostage, involuntary servitude, slave trade, kidnapping, abduction, unlawful criminal restraint, false imprisonment, or an attempt to commit any of these crimes?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isHomicideValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="homicideYes"
      name="homicideAnswer"
      value="yes"
      onChange={() => handleHomicideChange('yes')}
    />
    <label htmlFor="homicideYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="homicideNo"
      name="homicideAnswer"
      value="no"
      onChange={() => handleHomicideChange('no')}
    />
    <label htmlFor="homicideNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={`gap-10 p-4 mt-14 text-white rounded bg-${isLawViolationValid ? 'blue' : 'red'}-700`}>
  Been arrested, cited, charged, indicted, convicted, fined, or imprisoned for breaking or violating any law or ordinance in any country?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isLawViolationValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="lawViolationYes"
      name="lawViolationAnswer"
      value="yes"
      onChange={() => handleLawViolationChange('yes')}
    />
    <label htmlFor="lawViolationYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="lawViolationNo"
      name="lawViolationAnswer"
      value="no"
      onChange={() =>handleLawViolationChange('no')}
    />
    <label htmlFor="lawViolationNo" className='text-blue-500'>No</label>
  </div>
</div>


          



         <div className={`gap-10 p-4 mt-14 text-white rounded bg-${isSubstanceAbuseValid ? 'blue' : 'red'}-700`}>
  Do you have three or more arrests or convictions (not from a single act) for crimes related to a controlled substance or alcohol?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isSubstanceAbuseValid? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="substanceAbuseYes"
      name="substanceAbuseAnswer"
      value="yes"
      onChange={() =>  handleSubstanceAbuseChange ('yes')}
    />
    <label htmlFor="substanceAbuseYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="substanceAbuseNo"
      name="substanceAbuseAnswer"
      value="no"
      onChange={() =>  handleSubstanceAbuseChange ('no')}
    />
    <label htmlFor="substanceAbuseNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={`gap-10 p-4 text-white  mt-14 rounded bg-${isImmigrationProceedingsValid  ? 'blue' : 'red'}-700`}>
  Has your beneficiary EVER been under immigration proceedings?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isImmigrationProceedingsValid  ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="immigrationProceedingsYes"
      name="immigrationProceedingsAnswer"
      value="yes"
      onChange={() => handleImmigrationProceedingsChange('yes')}
    />
    <label htmlFor="immigrationProceedingsYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="immigrationProceedingsNo"
      name="immigrationProceedingsAnswer"
      value="no"
      onChange={() =>handleImmigrationProceedingsChange('no')}
    />
    <label htmlFor="immigrationProceedingsNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={`gap-10 p-4 text-white mt-14 rounded bg-${isMarriageEvadingValid ? 'blue' : 'red'}-700`}>
  Has your beneficiary EVER attempted or conspired to enter into a marriage for the purpose of evading U.S. immigration laws?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isMarriageEvadingValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="marriageEvadingYes"
      name="marriageEvadingAnswer"
      value="yes"
      onChange={() => handleMarriageEvadingChange('yes')}
    />
    <label htmlFor="marriageEvadingYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="marriageEvadingNo"
      name="marriageEvadingAnswer"
      value="no"
      onChange={() => handleMarriageEvadingChange('no')}
    />
    <label htmlFor="marriageEvadingNo" className='text-blue-500'>No</label>
  </div>
</div>

<div className={`gap-10 p-4 text-white  mt-14 rounded bg-${isCrimeChargedValid ? 'blue' : 'red'}-700`}>
  Has your beneficiary EVER been charged with any misdemeanor or felony crime in the United States?
  <span className="text-red-500">*</span>
</div>
<div className={`flex gap-10 p-4 rounded bg-${isCrimeChargedValid ? 'slate' : 'red'}-200`}>
  <div className='flex gap-2 '>
    <input
      type="radio"
      id="crimeChargedYes"
      name="crimeChargedAnswer"
      value="yes"
      onChange={() =>handleCrimeChargedChange('yes')}
    />
    <label htmlFor="crimeChargedYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input
      type="radio"
      id="crimeChargedNo"
      name="crimeChargedAnswer"
      value="no"
      onChange={() =>handleCrimeChargedChange('no')}
    />
    <label htmlFor="crimeChargedNo" className='text-blue-500'>No</label>
  </div>
</div> 

          

<div className='flex justify-end text-white mt-32'>
          <div
            className={`${
              isFormValid ? 'bg-blue-700' : 'bg-red-500 cursor-not-allowed'
            } flex items-center px-5 py-2 rounded-xl shadow`}
          >
            <button
              type="submit"
              className='text-white px-1 py-1 rounded '
             
            >
              Next
            </button>
            {/* ... other components ... */}
          </div>
        </div>
          
        </form>

        <FormFotter />
      </div>
    </div>
  );
};

export default Eligibility;
