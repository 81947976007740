
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import FormFotter from '../../components/FormFotter';
import Header from '../../components/Header';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from '../../UseContext/UseContextForI-I29F';


const PetionerEmploymentHistory = () => {
    const { state, setFormState } = useFormContext();

    const handleFromDateChange = (e) => {
        const fromDate = e.target.value;
        setFormState('SET_FROM_DATE', fromDate);
      };

      const handleEmploymentHistoryChange = (e) => {
        const value = e.target.value === 'true'; // Convert the value to a boolean
        setFormState('SET_HAVE_OTHER_EMPLOYERS', value);
      };
    

    const handleRadioChange = (e) => {
      setFormState('SET_EMPLOYMENT_STATUS', e.target.value);
    };
    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
          <Header />
          <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
    
              <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
              <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
              <div className='h-full'>
                <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
                <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
              </div>
            </div>
    
    
    
    
    <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
      <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
        <span className='mt-4 font-bold text-xl ml-2 text-black '>
          First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
        </span>
        <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
          <button className='mr-2'>Page 1 of 3</button>
          <button><MdArrowDropDown /></button>
        </div>
      </div>
      <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
    </div>
    
            <form action=" " className='mt-2 w-11/12  '>
            <h2 className='text-blue-500  font-bold text-xl font-sans mt-20'>Current Employment</h2>
                  
                  <p className='font-thin'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
                  
                  
                 
    <div className=''>
      <p className='flex bg-blue-600 text-white p-4 rounded-md'>In the most recent period up until today, which best describes your employment?</p>
     <div className='flex bg-blue-100 p-2 rounded-md flex-col  gap-2'>
      <label className='text-blue-600 flex gap-1'>
        <input
          type="radio"
          name="employmentStatus"
          value="Employed"
          checked={state.employmentStatus === 'Employed'}
          onChange={handleRadioChange}
        />
        Employed
      </label>
      
      <label className='text-blue-600 flex gap-1'>
        <input
          type="radio"
          name="employmentStatus"
          value="Self-Employed"
          checked={state.employmentStatus === 'Self-Employed'}
          onChange={handleRadioChange}
        />
        Self-Employed
      </label>
     
      <label className='text-blue-600 flex gap-1'>
        <input
          type="radio"
          name="employmentStatus"
          value="Unemployed - Student"
          checked={state.employmentStatus === 'Unemployed - Student'}
          onChange={handleRadioChange}
        />
        Unemployed - Student
      </label>

      <label className='text-blue-600 flex gap-1'>
        <input
          type="radio"
          name="employmentStatus"
          value="Unemployed - Homemaker"
          checked={state.employmentStatus === 'Unemployed - Homemaker'}
          onChange={handleRadioChange}
        />
        Unemployed - Homemaker
      </label>
     
      <label className='text-blue-600 flex gap-1'>
        <input
          type="radio"
          name="employmentStatus"
          value="Unemployed - Retired"
          checked={state.employmentStatus === 'Unemployed - Retired'}
          onChange={handleRadioChange}
        />
        Unemployed - Retired
      </label>
    
      <label className='text-blue-600 flex gap-1'>
        <input
          type="radio"
          name="employmentStatus"
          value="Unemployed"
          checked={state.employmentStatus === 'Unemployed'}
          onChange={handleRadioChange}
        />
        Unemployed
      </label>
      </div>
    </div>




      

    <div className='flex flex-col mt-10'>
      <label htmlFor="fromDate" className='flex p-4 rounded-md bg-blue-600  text-white w-full'>From Date:</label>
      <div className='w-full bg-blue-100 p-4'>
      <input
        type="date"
        id="fromDate"
        name="fromDate"
        value={state.fromDate}
        onChange={handleFromDateChange}
        placeholder="dd/mm/yyyy"
        className='border  w-[300px] flex  border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg  text-sm '
      />
      </div>
    </div>




    <div className='flex  flex-col mt-10'>
      <label className='flex bg-blue-600  text-white p-4 rounded'>Have you had any other employers in the past five years?</label>
     <div className='flex p-4 rounded-md bg-blue-100 gap-4 text-blue-600'>
      <div className='flex gap-1'>
        <input
          type="radio"
          id="yes"
          name="haveOtherEmployers"
          value={true}
          checked={state.haveOtherEmployers === true}
          onChange={handleEmploymentHistoryChange}
        />
        <label htmlFor="yes">Yes</label>
      </div>
      <div className='flex gap-1'>
        <input
          type="radio"
          id="no"
          name="haveOtherEmployers"
          value={false}
          checked={state.haveOtherEmployers === false}
          onChange={handleEmploymentHistoryChange}
        />
        <label htmlFor="no">No</label>
      </div>
      </div>
    </div>



                  
        
    
                     
    
    
                 
                 
    
    
          <button
            type="button"
            className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
          >
            Add Another Name
          </button>
  
                  
            </form>
            <FormFotter />
          </div>
        </div>
      )
}

export default PetionerEmploymentHistory