import React from 'react'
import Header from '../../components/Header'
import FormFotter from '../../components/FormFotter'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
// import tikpik from '../Assets/tikpic.png';
// import crosspic from '../Assets/crosspic.png';
import { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; //
import { useEffect } from 'react';



const Pinfo = () => {

    
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
 

 

    <Header />
    <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
    <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>

        <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
        <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
        <div className='h-full'>
          <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
          <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
        </div>
      </div>




<div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
<div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
  <span className='mt-4 font-bold text-xl ml-2 text-black '>
    First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
  </span>
  <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
    <button className='mr-2'>Page 1 of 3</button>
    <button><MdArrowDropDown /></button>
  </div>
</div>
<span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
</div>

      <form action=" " className='mt-14 w-11/12  ' >

      <h1 className='text-blue-700 font-bold text-xl'>Full Name</h1>
<p
  className='font-thin text-sm'
  style={{
    color: 'rgba(60, 60, 60, 0.7)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
  }}
>
  Enter your full legal name. Unless you have legally changed your name, your name should be exactly as it appears on your existing immigration documents like a Permanent Resident Card or I-94 Arrival/Departure Record. Spell out names completely. If you do not have a middle name, you may leave this field blank.
</p>




<div className="mb-4 flex mt-6">
<label htmlFor="givenName" className="text-blue-700">
  Given Name (First Name): <span className="text-red-500">*</span>
</label>

        <input
          type="text"
          id="givenName"
          name="givenName"
         
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'
          required
        />
      </div>

      {/* Middle Name(s) */}
      <div className="mb-4 flex">
        <label htmlFor="middleName" className="text-blue-700">Middle Name(s):
        <span className="text-red-500">*</span>
        
        </label>
        <input
          type="text"
          id="middleName"
          name="middleName"
         
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg  ml-24 '
        />
      </div>

      {/* Family Name (Last Name) */}
      <div className="mb-4 flex">
        <label htmlFor="familyName" className="text-blue-500">Family Name (Last Name):
        <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id="familyName"
          name="familyName"
       
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'
          required
        />
      </div>    
      <hr className='bg-blue-700 h-1 w-full mt-20'/>
      <p
  className='font-thin text-sm mt-10'
  style={{
    color: 'rgba(60, 60, 60, 0.7)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
  }}
>
  Enter your full legal name. Unless you have legally changed your name, your name should be exactly as it appears on your existing immigration documents like a Permanent Resident Card or I-94 Arrival/Departure Record. Spell out names completely. If you do not have a middle name, you may leave this field blank.
</p>

      <div className='p-4 rounded text-white bg-blue-700 mt-10' >
                 Have you ever used another name (such as a maiden name)?
                  <span className="text-red-500">*</span>
                </div>
                <div className='flex gap-10 bg-slate-200 p-5'>
                  <div className='flex gap-2'>
                    <input type="radio" id="yes" name="answer" value="yes" />
                    <label htmlFor="yes" className='text-blue-500'>Yes</label>
                  </div>
                  <div className='flex gap-2'>
                    <input type="radio" id="no" name="answer" value="no"  />
                    <label htmlFor="no" className='text-blue-500'>No</label>
                  </div>
                </div>
                <div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>


        <div className='p-4 rounded text-white bg-blue-700 mt-10' >
        Ethnicity
                  <span className="text-red-500">*</span>
    </div>
     <div className='flex gap-10 bg-slate-200 p-5'>
      <div className='flex gap-2'>
        <input
          type="radio"
          id="hispanic"
          name="ethnicity"
          value="hispanic"
       
        />
        <label htmlFor="hispanic" className='text-blue-500'>Hispanic or Latino</label>
      </div>
      

      <div className='flex gap-2'>
        <input
          type="radio"
          id="notHispanic"
          name="ethnicity"
          value="notHispanic"
         
        />

        <label htmlFor="notHispanic" className='text-blue-500'>Not Hispanic or Latino</label>
      </div>
     
    </div>
    <div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>

    <div className='p-4 rounded text-white bg-blue-700 mt-10' >
    Races (select all applicable boxes)
                  <span className="text-red-500">*</span>
    </div>

    <div className='flex flex-col gap-2 bg-slate-200 p-4' >
<div className='flex gap-10'>
  <label className='flex text-blue-500 gap-2'>
    <input
      type="checkbox"
      value="White"
    />
    White
  </label>
  <label className='flex text-blue-500 gap-2'>
    <input
      type="checkbox"
      value="Asian"
    />
    Asian
  </label>
  <label className='flex text-blue-500 gap-2'>
    <input
      type="checkbox"
      value="Black or African American"
    />
    Black or African American
  </label>
  <label className='flex text-blue-500 gap-2'>
    <input
      type="checkbox"
      value="American Indian or Alaska Native"
    />
    American Indian or Alaska Native
  </label>
  </div>
  <div>
  <label className='flex text-blue-500 gap-2'>
    <input
      type="checkbox"
      value="Native Hawaiian or Other Pacific Islander"
    />
    Native Hawaiian or Other Pacific Islander
  </label>
  </div>
</div>
<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
</div>



<div className='p-4 rounded text-white bg-blue-700 mt-10' >
Height
                  <span className="text-red-500">*</span>
    </div>




<div className='flex gap-20  bg-blue-100 p-2'>
<div className='border border-black mt-4 ml-4'>


<select
  className="p-2  border-r border-black"
  id="numberSelect"
 
>
<option value="...">...</option>
  {/* Add options for numbers 0 to 11 */}
  {[...Array(12).keys()].map((number) => (
    <option key={number} value={number}>
      {number}
    </option>
  ))}
</select>
<span className='bg-slate-200'>inches</span>
</div>






<div className='border border-black mt-4  ml-4'>


<select
  className="p-2  border-r border-black"
  id="numberSelect"
 
>
<option value="...">...</option>
  {/* Add options for numbers 0 to 11 */}
  {[...Array(12).keys()].map((number) => (
    <option key={number} value={number}>
      {number}
    </option>
  ))}
</select>
<span className='bg-slate-200'>inches</span>




</div>
</div>

<div className='p-4 rounded text-white bg-blue-700 mt-10'>
  Weight
  <span className="text-red-500">*</span>
</div>

<div className='w-full bg-blue-200 flex p-4 rounded'>
  <label className='flex gap-2 text-blue-500 border border-slate-200'>
    <input type="number" name="weight" placeholder="Enter weight" className='w-[60px] p-2 border border-slate-200 '/>
  </label>
  <span className='w-[40px] p-2 bg-slate-200 flex justify-center border border-slate-200'>Ilb</span>
</div>




<div className='p-4 rounded text-white bg-blue-700 mt-10' >
Eye Color
                  <span className="text-red-500">*</span>
    </div>

<div className='bg-slate-200 flex gap-10 p-4'>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Black" />
    Black
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Blue" />
    Blue
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Brown" />
    Brown
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Gray" />
    Gray
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Green" />
    Green
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Hazel" />
    Hazel
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Maroon" />
    Maroon
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Pink" />
    Pink
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="eyeColor" value="Unknown/Other" />
    Unknown/Other
  </label>
</div>
<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>


<div className='p-4 rounded text-white bg-blue-700 mt-10' >
 Hair color
                  <span className="text-red-500">*</span>
    </div>


    <div className='bg-slate-200 flex gap-10 p-4'>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Bald" />
    Bald (no hair)
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Black" />
    Black
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Blond" />
    Blond
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Brown" />
    Brown
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Gray" />
    Gray
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Red" />
    Red
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Sandy" />
    Sandy
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="White" />
    White
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="hairColor" value="Unknown/Other" />
    Unknown/Other
  </label>
</div>
<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>

<hr className='bg-blue-700 h-1 w-full mt-20'/>

<p
  className='font-bold text-xl mt-10 text-blue-500'
  
>
  Birth info
</p>


<div className='p-4 rounded text-white bg-blue-700 mt-10' >
 Date of birth
                  <span className="text-red-500">*</span>
    </div>
   < div className='flex gap-10 bg-blue-100 p-4'>
  <div className='flex gap-2'>
    <input
      type="date"
      id="dob"
      name="dob"
      className='w-[300px] h-10  rounded pl-2'
   
      // value={state.dob} // Replace with the actual state variable containing the date of birtt // onChange={(e) => handleDateOfBirthChange(e.target.value)} // Replace with your state update function
 />
  </div>
  
</div>


<div className='p-4 rounded text-white bg-blue-700 mt-10' >
 city of birth
                  <span className="text-red-500">*</span>
    </div>
   < div className='flex gap-10 bg-blue-100 p-4'>
  <div className='flex gap-2'>
    <input
      type="text"
      id="dob"
      name="dob"
      className='w-[300px] h-10  rounded pl-2'
   
      // value={state.dob} // Replace with the actual state variable containing the date of birtt // onChange={(e) => handleDateOfBirthChange(e.target.value)} // Replace with your state update function
 />
  </div>
  
</div>



<div className='p-4 rounded text-white bg-blue-700 mt-10' >
 Province or state
                 
    </div>
   < div className='flex gap-10 bg-blue-100 p-4'>
  <div className='flex gap-2'>
    <input
      type="text"
      id="dob"
      name="dob"
      className='w-[300px] h-10  rounded pl-2'
   
      // value={state.dob} // Replace with the actual state variable containing the date of birtt // onChange={(e) => handleDateOfBirthChange(e.target.value)} // Replace with your state update function
 />
  </div>

  
</div>



<div className='p-4 rounded text-white bg-blue-700 mt-10' >
 Select country
                  <span className="text-red-500">*</span>
</div>

<div  className=" bg-blue-100 p-4  rounded">
      <label htmlFor="country-select"   ></label>
      <select
        id="country-select"
       
        className=' h-10 pl-2'
        
      >
        <option value="">Andorra</option>
        {countries.map((country) => (
          <option key={country.name.common} value={country.name.common}>
            {country.name.common}
          </option>
        ))}
      </select>
     
    </div>

    <div className='p-4 rounded text-white bg-blue-700 mt-10'>
  sex
  <span className="text-red-500">*</span>
</div>

<div className='bg-slate-200 flex gap-10 p-4'>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="gender" value="Male" />
    Male
  </label>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="gender" value="Female" />
    Female
  </label>
</div>
<hr className='bg-blue-700 h-1 w-full mt-20'/>

<p
  className='font-bold text-xl mt-10 text-blue-500'
  
>
Petitioner's Citizenship Information
</p>

<div className='p-4 rounded text-white bg-blue-700 mt-10'>
  Citizenship
  <span className="text-red-500">*</span>
</div>

<div className='bg-slate-200 flex flex-col gap-2 p-4'>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="citizenship" value="BirthInUS" />
    Birth in the U.S.
  </label>
  <hr className='bg-blue-200 h-0.5 w-full'/>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="citizenship" value="Naturalization" />
    Naturalization
  </label>
  <hr className='bg-blue-200 h-0.5 w-full'/>
  <label className='flex gap-2 text-blue-500'>
    <input type="radio" name="citizenship" value="Parents" />
    Parents
  </label>
</div>
<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>


<div className='p-4 rounded text-white bg-blue-700 mt-10'>
  Do you have a Certificate of Naturalization?
  <span className="text-red-500">*</span>
</div>

<div className='flex gap-10 bg-slate-200 p-5'>
  <div className='flex gap-2'>
    <input type="radio" id="natYes" name="naturalization" value="yes" />
    <label htmlFor="natYes" className='text-blue-500'>Yes</label>
  </div>
  <div className='flex gap-2'>
    <input type="radio" id="natNo" name="naturalization" value="no" />
    <label htmlFor="natNo" className='text-blue-500'>No</label>
  </div>
</div>
<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
</div>

<p
  className='font-bold text-xl mt-10 text-blue-500'
  
>
Certificate of Naturalization
</p>

<p
  className='font-thin text-sm'
  style={{
    color: 'rgba(60, 60, 60, 0.7)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
  }}
>
Provide details from your Certificate of Citizenship: How to find this >>
</p>

<div className="p-4 bg-blue-500 rounded mt-14">
  <span className="text-white">Certificate Number</span>
</div>

<div className="flex items-center  bg-blue-100 p-3">
  <div  className=" bg-blue-100  rounded">
    <input
      placeholder='Enter Certificate Number'
      type="text"
      id="certificateNumber"
      name="certificateNumber"
      className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
      required
    />
  </div>
</div>

<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>
<div className="p-4 bg-blue-500 rounded mt-14">
  <span className="text-white">Place of Issuance</span>
</div>

<div className="flex items-center  bg-blue-200 p-3">
  <div className="bg-blue-100 rounded">
    <input
      placeholder='Enter Insurance Place'
      type="text"
      id="insurancePlace"
      name="insurancePlace"
      className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
      required
    />
  </div>
</div>
<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>

<div className='p-4 rounded text-white bg-blue-500 mt-10' >
Date of Issuance:
                  <span className="text-blue-500">*</span>
    </div>
   < div className='flex gap-5 bg-blue-100 p-4  flex-col'>
  <div className='flex gap-2'>
    <input
      type="date"
      id="dob"
      name="dob"
      className='w-[300px] h-10  rounded pl-2'
   
      // value={state.dob} // Replace with the actual state variable containing the date of birtt // onChange={(e) => handleDateOfBirthChange(e.target.value)} // Replace with your state update function
 />
  </div>
  <div className='flex gap-2 ' >
    <input type="checkbox" />
    <span className='text-blue-500'>I do not know the date of issuance</span>
  </div>
  
</div>



<hr className='bg-blue-700 h-1 w-full mt-20'/>


<p
  className='font-bold text-xl mt-10 text-blue-500'
  
>
Petitioner's Contact Information
</p>
<p
  className='font-thin text-sm'
  style={{
    color: 'rgba(60, 60, 60, 0.7)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
  }}
>
This contact information is so that USCIS can reach you. Your mobile phone number and/or email address will also be used so that CitizenPath can create a complimentary Form G-1145 for you. By filing G-1145 with your petition, USCIS will text and/or email you when they have accepted the petition for processing.
</p>



<div className='flex gap-10 items-center'>
                    <label className='mt-2 text-blue-500'>Day Time Phone number</label>
                    <PhoneInput
                 
                      

                      className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
                      </PhoneInput>
                  
                  </div>


                  <div className='flex gap-10 items-center'>
                    <label className='mt-2 text-blue-500'>Mobile Phone Number</label>
                    <PhoneInput
                   
                      className=' ml-12 border  border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg '>
                      </PhoneInput>
                  
                  </div>


                  <div className='flex gap-10 items-center mt-10'>
                <label htmlFor="emailInput" className='mt-2 text-blue-500'>
                  Email
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="email"
                  id="emailInput"
                  name="email"
                  placeholder='@user@gmailcom'
                
                  className='border border-gray-200 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-40'
                />
              </div>


              <hr className='bg-blue-700 h-1 w-full mt-20'/>


<p
  className='font-bold text-xl mt-10 text-blue-500'
  
>
other info
</p>

<div className='p-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
  <span className='text-gray-500'>If you have an immigration status other than Permanent Resident, Visitor, or Student, choose "Other" and select your status from the drop down list. If you have an Adjustment of Status (AOS) application (I-485) pending with USCIS, choose "Other" and select "AOS applicant" from the drop down list. Once you file form I-485, your status is "AOS" regardless of whether your nonimmigrant status is valid or expired..</span>
</div>


<div className="p-4 bg-blue-500 rounded mt-14">
  <span className="text-white">Alien Registration Number (A-Number)</span>
</div>
<div className="flex items-center  bg-red-100 p-3">
  <span className="p-3 bg-gray-300">A-</span>
  <div  className=" bg-blue-100  rounded">
  <input
  placeholder='ffff'
    type="text"
    id="familyName"
   
    name="familyName"
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
    required
  />
</div>
</div>

<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>


<div className='p-2 bg-gray-300 rounded' style={{ backgroundColor: '#E7E7E7' }}>
  <span className='text-gray-500'>If you have an immigration status other than Permanent Resident, Visitor, or Student, choose "Other" and select your status from the drop down list. If you have an Adjustment of Status (AOS) application (I-485) pending with USCIS, choose "Other" and select "AOS applicant" from the drop down list. Once you file form I-485, your status is "AOS" regardless of whether your nonimmigrant status is valid or expired..</span>
</div>


<div className="p-4 bg-blue-500 rounded mt-14">
  <span className="text-white">Alien Registration Number (A-Number)</span>
</div>
<div className="flex items-center  bg-blue-100 p-3">

  <div  className=" bg-blue-100  rounded">
  <input
  placeholder='ffff'
    type="text"
    id="familyName"
   
    name="familyName"
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
    required
  />
</div>
</div>

<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>



<div className="p-4 bg-blue-500 rounded mt-14">
  <span className="text-white">Alien Registration Number (A-Number)</span>
</div>
<div className="flex items-center  bg-blue-100 p-3">
 
  <div  className=" bg-blue-100  rounded">
  <input
  placeholder='ffff'
    type="text"
    id="familyName"
   
    name="familyName"
    className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-3 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
    required
  />
</div>
</div>

<div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>




            
        <div className='flex justify-end text-white mt-32' >
          <div className='bg-green-600 flex items-center px-5 py-2 rounded-xl shadow'>
            <button
              type="submit"
              className='text-white px-1 py-1 rounded'
              
            >
              Next
            </button>
            <BsArrowRight />
          </div>
        </div>
        <div className='flex justify-end mt-2'>
        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
        </div>
      </form>
      <FormFotter />
    </div>
  </div>
  )
}

export default Pinfo
