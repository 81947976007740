import React from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useState } from "react";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import form from "../../Assets/icon.png";
import "../I-90/style.css";
import { useFormContext } from "./FormContext";
import { useNavigate } from "react-router-dom";



const I864 = () => {
  const navigate=useNavigate()
  const {formData ,handleInputChange} =useFormContext()
  const handleSubmit=(e)=>{
    e.preventDefault()
    console.log("this is my form data", JSON.stringify(formData,null,2));
  }



  return (
    <div className="w-full">
      <Header />

      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            USCIS Form I-864 - Affidavit of Support Package
          </span>
          <div className="h-full flex justify-end">
            {" "}
            {/* Added 'flex' and 'justify-end' */}
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>

        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2 style={{ marginBottom: "-1%" }}>
            {" "}
            First, let's determine your eligibility to prepare Form I-864,
            Affidavit of Support
          </h2>
          <span className="txttop " style={{ color: "#888" }}>
            <p className=" p-4">
              {" "}
              A sponsor prepares this form for an intending immigrant. In most
              cases, the sponsor must be the same person who filed the visa
              petition for the intending immigrant. The intending immigrant is
              the person that wants to get a green card and live in the United
              States. After the sponsor prepares Form I-864, the intending
              immigrant must file it. <a href="#">Explain more {">>"}</a>  
            </p>
          </span>
        </div>
        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
        <form  onSubmit={handleSubmit}  className="mt-6 w-full ">
        <div className="mt-2 font-thin ml-2 text ndRendring">
        Select your citizenship or residency:
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="residency"
                  value="US"
                  id="lostRadio"
                  onChange={handleInputChange}
                  checked={formData.residency === "US"}
                />
                <label htmlFor="lostRadio">
                U.S. citizen                </label>
                <hr />
                <input
                  type="radio"
                  name="residency"
                  value="Lawful"
                  id="notReceivedRadio"
                  onChange={handleInputChange}
                  checked={formData.residency === "Lawful"}
                />
                <label htmlFor="notReceivedRadio">
                Lawful permanent resident

                </label>
                <hr />
                <input
                  type="radio"
                  name="residency"
                  value="none"
                  id="mutilatedRadio"
                  onChange={handleInputChange}
                  checked={formData.residency === "none"}
                />
                <label htmlFor="mutilatedRadio">
                None of the above

                </label>
              
              </div>
 <br />
 {formData.residency==="none"?(
  <div>
    <div className="additional-div">
<div className="text-white font-bold p-2 ndRendring text">
Are you a U.S. national?
  <span className="text-red-500">*</span>
</div>
<div className="form p-1">
  <input
    type="radio"
    name="uscitizen"
    value="Yes"
    required="required"

    id="servedYesss"
    checked={formData.uscitizen === "Yes"}
    onChange={handleInputChange}
  />
  <label htmlFor="servedYesss">Yes</label> &nbsp;&nbsp;
  <input
    type="radio"
    name="uscitizen"
    value="No"
    id="servedNo11"
    required="required"
    checked={formData.uscitizen === "No"}
    onChange={handleInputChange}
  />
  <label htmlFor="servedNo11">No</label>
  <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
  Generally, U.S. nationals are only born in American Samoa or Swains Island.


                  </div>
</div>
</div>
  </div>
):null}
{formData.uscitizen==="No"?(
    <div className="bg-red-200 error">
    <div className="icon-and-message">
      <span className="icon">
        <img src={crosspic} alt="" width="72" height="32" />
      </span>
      <p className="message ml-2">
        <span className="font-bold">
        We found a problem. Based on your answer, you cannot continue.           </span>
        You must be a U.S. citizen, U.S. national, or lawful permanent resident of the U.S. to file Form I-864, Affidavit of Support.      </p>
    </div>
  </div>  
 ):null}
 <br />
{formData.residency==="US" || formData.residency === "Lawful" || formData.uscitizen === "Yes"?(
    <div>
  <div className="mt-2 font-thin ml-2 text ndRendring">
    Select the basis for preparing this affidavit of support:
    <span className="text-red-500">*</span>
  </div>
  <div className="form">
    <input
      type="radio"
      name="support"
      value="I am the petitioner. I filed or am filing for the immigration of my relative."
      id="lostRadio1"
      onChange={handleInputChange}
      checked={formData.support === "I am the petitioner. I filed or am filing for the immigration of my relative."}
    />
    <label htmlFor="lostRadio1">
      I am the petitioner. I filed or am filing for the immigration of my relative.
    </label>
    <hr />
    <input
      type="radio"
      name="support"
      required="required"

      value="I am a joint sponsor."
      id="notReceivedRadio2"
      onChange={handleInputChange}
      checked={formData.support === "I am a joint sponsor."}
    />
    <label htmlFor="notReceivedRadio2">
      I am a joint sponsor.
    </label>
    <hr />
    <input
      type="radio"
      name="support"
      value="I filed an alien worker petition on behalf of the intending immigrant, who is related to me."
      id="mutilatedRadio12"
      onChange={handleInputChange}
      checked={formData.support === "I filed an alien worker petition on behalf of the intending immigrant, who is related to me."}
    />
    <label htmlFor="mutilatedRadio12">
      I filed an alien worker petition on behalf of the intending immigrant, who is related to me.
    </label>
    <hr />
    <input
      type="radio"
      name="support"
      value="I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant."
      id="mutilatedRadio23"
      onChange={handleInputChange}
      checked={formData.support === "I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant."}
    />
    <label htmlFor="mutilatedRadio23">
      I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant.
    </label>
    <hr />
    <input
      type="radio"
      name="support"
      value="I am the substitute sponsor. The original petitioner is deceased."
      id="mutilatedRadio33"
      onChange={handleInputChange}
      checked={formData.support === "I am the substitute sponsor. The original petitioner is deceased."}
    />
    <label htmlFor="mutilatedRadio33">
      I am the substitute sponsor. The original petitioner is deceased.
    </label>
    <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
    Most people will prepare Form I-864 as the "petitioner." <a href="#">Explain more >></a>

                  </div>
  </div>
  
</div>


):null}


<br />
{formData.support === "I am a joint sponsor."?(
   <div>
   <div className="mt-2 font-thin ml-2 text ndRendring">
   How many joint sponsors will there be? 
     <span className="text-red-500">*</span>
   </div>
   <div className="form">
     <input
       type="radio"
       name="jointsponsors"
       value="I am the only joint sponsor."
       id="lostRadio14"
       onChange={handleInputChange}
       checked={formData.jointsponsors === "I am the only joint sponsor."}
     />
     <label htmlFor="lostRadio14">
     I am the only joint sponsor.     </label>
     <hr />
     <input
       type="radio"
       name="jointsponsors"
       required="required"
 
       value="I am the first of two joint sponsors."
       id="notReceivedRadio211"
       onChange={handleInputChange}
       checked={formData.jointsponsors === "I am the first of two joint sponsors."}
     />
     <label htmlFor="notReceivedRadio211">
     I am the first of two joint sponsors.
     </label>
     <hr />
     <input
       type="radio"
       name="jointsponsors"
       value="I am the second of two joint sponsors."
       id="mutilatedRadio12111"
       onChange={handleInputChange}
       checked={formData.jointsponsors === "I am the second of two joint sponsors."}
     />
     <label htmlFor="mutilatedRadio12111">
     I am the second of two joint sponsors.     </label>
    
     
   </div>
   
 </div>
):null}
{formData.jointsponsors==="I am the only joint sponsor."?(
  <div className="additional-div">
  <div className="text-white font-bold p-2 ndRendring text">
  Are you at least 18 years old?

    <span className="text-red-500">*</span>
  </div>
  <div className="form p-1">
    <input
      type="radio"
      name="age18"
      value="Yes" // Set the value to "Yes"
      id="nn"
      checked={formData.age18 === "Yes"} // Check if interview is "Yes"
      onChange={handleInputChange}
    />
    <label htmlFor="nn">Yes</label> &nbsp;&nbsp;
    <input
      type="radio"
      name="age18"
      value="No" // Set the value to "No"
      id="nnq"
      checked={formData.age18 === "No"} // Check if interview is "No"
      onChange={handleInputChange}
    />
    <label htmlFor="nnq">No</label>

  </div>
</div>
):null

}
{formData.jointsponsors==="I am the first of two joint sponsors." ||formData.jointsponsors==="I am the second of two joint sponsors."?(
  <div className="bg-red-200 error">
<div className="icon-and-message">
  <span className="icon">
    <img src={crosspic} alt="" width="52" height="32" />
  </span>
  <p className="message ml-2">
    <span className="font-bold">
    We found a problem. Based on your answer, you cannot continue         </span>
    You must have a Social Security Number to prepare Form I-864, Affidavit of Support. You can apply for an SSN through the Social Security Administration.
  </p>
</div>
<div className="icon-and-message">
  <span className="icon">
    <img src={Searchicon} alt="" width="32" height="32" />
  </span>
 <a href="#" rel="noopener noreferrer">Apply for a Social Security Number and Card</a>
</div>
</div>

):null}
<br />
{formData.support==="I am the petitioner. I filed or am filing for the immigration of my relative."|| formData.support==="I filed an alien worker petition on behalf of the intending immigrant, who is related to me."|| formData.support==="I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant." ?(
    
    <div>
      {formData.support==="I have an ownership interest of at least 5 percent in a business which filed an alien worker petition on behalf of the intending immigrant."?(
    <div><div className="mt-2 font-thin ml-2 text ndRendring">
Enter the name of the business entity:
      <span className="text-red-500">*</span>
    </div>
    <div className="form">
              <div className="flex flex-col space-y-5 ml-1 mt-0">
                <div className="flex items-center space-x-10 mt-3">
                  &nbsp;{" "}
                  <input
                    id="ss"
                    type="text"
                    name="bussniesname"
                    value={formData.bussniesname}
                    onChange={handleInputChange}
                    className="py-1 px-2 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    required
                    
                  />
  
                </div>
         
              </div>
              <br />
            </div></div>  
    
      ):null}
<br /><div>
  <div className="mt-2 font-thin ml-2 text ndRendring">
    The intending immigrant is the petitioner's:
    <span className="text-red-500">*</span>
  </div>
  <div className="form">
    <input
      type="radio"
      name="relationship"
      value="Spouse"
      id="spouseRadio"
      onChange={handleInputChange}
      checked={formData.relationship === "Spouse"}
    />
    <label htmlFor="spouseRadio">
      Spouse
    </label>
    <hr />
    <input
      type="radio"
      name="relationship"
      value="Child (under age 21)"
      id="childRadio"
      onChange={handleInputChange}
      checked={formData.relationship === "Child (under age 21)"}
    />
    <label htmlFor="childRadio">
      Child (under age 21)
    </label>
    <hr />
    <input
      type="radio"
      name="relationship"
      value="Adult son/daughter"
      id="adultSonDaughterRadio"
      onChange={handleInputChange}
      checked={formData.relationship === "Adult son/daughter"}
    />
    <label htmlFor="adultSonDaughterRadio">
      Adult son/daughter
    </label>
    <hr />
    <input
      type="radio"
      name="relationship"
      value="Parent (mother/father)"
      id="parentRadio"
      onChange={handleInputChange}
      checked={formData.relationship === "Parent (mother/father)"}
    />
    <label htmlFor="parentRadio">
      Parent (mother/father)
    </label>
    <hr />
    <input
      type="radio"
      name="relationship"
      value="Sibling (brother/sister)"
      id="siblingRadio"
      onChange={handleInputChange}
      checked={formData.relationship === "Sibling (brother/sister)"}
    />
    <label htmlFor="siblingRadio">
      Sibling (brother/sister)
    </label>
  </div>
</div>
{formData.relationship==="Sibling (brother/sister)" && formData.residency==="Lawful"||formData.relationship==="Parent (mother/father)" && formData.residency==="Lawful" ?(
        <div className="bg-red-200 error">
        <div className="icon-and-message">
          <span className="icon">
            <img src={crosspic} alt="" width="72" height="32" />
          </span>
          <p className="message ml-2">
            <span className="font-bold">
            We found a problem. Based on your answer, you cannot continue.           </span>
            Only a U.S. citizen may petition a parent, sibling or married son or daughter. Lawful permanent residents and U.S. nationals are unable to file a petition this type of relationship.           </p>
        </div>
      </div>
):null}
{formData.relationship ==="Child (under age 21)" && formData.support==="I am the petitioner. I filed or am filing for the immigration of my relative." && formData.residency==="US"?(
    <div className="additional-div">
  <div className="text-white font-bold p-2 ndRendring text">
  Is your child also filing Form I-485 after entering the United States on a K-2 visa?
    <span className="text-red-500">*</span>
  </div>
  <div className="form p-1">
    <input
      type="radio"
      name="Child"
      value="Yes"
      id="spouseFilingYesa"
      checked={formData.Child === "Yes"}
      onChange={handleInputChange}
    />
    <label htmlFor="spouseFilingYesa">Yes</label> &nbsp;&nbsp;
    <hr />
    <input
      type="radio"
      name="Child"
      value="No"
      id="spouseFilingNoa"
      checked={formData.Child === "No"}
      onChange={handleInputChange}
    />
    <label htmlFor="spouseFilingNoa">No</label>
    <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>    Indicate if your child was admitted on a K-2 visa (child of a K-1).


    </div>
   
  </div>
  {formData.Child==="Yes"?(
           <div className="bg-red-200 error">
           <div className="icon-and-message">
             <span className="icon">
               <img src={crosspic} alt="" width="72" height="32" />
             </span>
             <p className="message ml-2">
               <span className="font-bold">
               We found a problem. Based on your answer, you cannot continue.           </span>
               You do not need a separate Form I-864 for a child who is adjusting status through a K-2 entry. Provided that the K-1 parent married within 90 days and is adjusting status, you may use a photocopy of the Form I-864 prepared on behalf of the K-1 parent. The K-2 child must be listed as a family member on the K-1 parent's I-864 affidavit.             </p>
           </div>
         </div>
    ):null}
</div>


):null}
{formData.relationship ==="Spouse" && formData.support==="I am the petitioner. I filed or am filing for the immigration of my relative." && formData.residency==="US"?(
    <div className="additional-div">
  <div className="text-white font-bold p-2 ndRendring text">
    Is your spouse also filing Form I-485 after entering the United States on a K-1 visa?
    <span className="text-red-500">*</span>
  </div>
  <div className="form p-1">
    <input
      type="radio"
      name="spouseFilingI485"
      value="Yes"
      id="spouseFilingYes"
      checked={formData.spouseFilingI485 === "Yes"}
      onChange={handleInputChange}
    />
    <label htmlFor="spouseFilingYes">Yes</label> &nbsp;&nbsp;
    <input
      type="radio"
      name="spouseFilingI485"
      value="No"
      id="spouseFilingNo"
      checked={formData.spouseFilingI485 === "No"}
      onChange={handleInputChange}
    />
    <label htmlFor="spouseFilingNo">No</label>
    <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>    Indicate if your spouse was admitted on a K-1 fiancé visa.

    </div>
  </div>
</div>


):null}
<div className="additional-div">
                <div className="text-white font-bold p-2 ndRendring text">
                Are you at least 18 years old?

                  <span className="text-red-500">*</span>
                </div>
                <div className="form p-1">
                  <input
                    type="radio"
                    name="age18"
                    value="Yes" // Set the value to "Yes"
                    id="nn"
                    checked={formData.age18 === "Yes"} // Check if interview is "Yes"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="nn">Yes</label> &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="age18"
                    value="No" // Set the value to "No"
                    id="nnq"
                    checked={formData.age18 === "No"} // Check if interview is "No"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="nnq">No</label>
          
                </div>
              </div>

    </div>
    ):null}
{formData.support==="I am the substitute sponsor. The original petitioner is deceased."?(
 <div> <div className="text-white font-bold p-2 ndRendring text">
I am the intending immigrant's:
  <span className="text-red-500">*</span>
</div>
<div className="form">
  <label id="" htmlFor="Spouse">
    <input
      type="radio"
      data-type="radio"
      id="Spouse"
      name="P2_I864_P1_1f"
      value="Spouse"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Spouse"}
    />
    Spouse
  </label>
  <hr />
  <label id="Mother_SS-L" htmlFor="Mother_SS">
    <input
      type="radio"
      data-type="radio"
      id="Mother_SS"
      name="P2_I864_P1_1f"
      value="Mother"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Mother"}
    />
    Mother
  </label>  <hr />

  <label id="Father_SS-L" htmlFor="Father_SS">
    <input
      type="radio"
      data-type="radio"
      id="Father_SS"
      name="P2_I864_P1_1f"
      value="Father"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Father"}
    />
    Father
  </label>  <hr />

  <label id="MIL_SS-L" htmlFor="MIL_SS">
    <input
      type="radio"
      data-type="radio"
      id="MIL_SS"
      name="P2_I864_P1_1f"
      value="Mother-in-law"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Mother-in-law"}
    />
    Mother-in-law
  </label>  <hr />

  <label id="FIL_SS-L" htmlFor="FIL_SS">
    <input
      type="radio"
      data-type="radio"
      id="FIL_SS"
      name="P2_I864_P1_1f"
      value="Father-in-law"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Father-in-law"}
    />
    Father-in-law
  </label>  <hr />

  <label id="Brother_SS-L" htmlFor="Brother_SS">
    <input
      type="radio"
      data-type="radio"
      id="Brother_SS"
      name="P2_I864_P1_1f"
      value="Brother"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Brother"}
    />
    Brother
  </label>  <hr />

  <label id="Sister_SS-L" htmlFor="Sister_SS">
    <input
      type="radio"
      data-type="radio"
      id="Sister_SS"
      name="P2_I864_P1_1f"
      value="Sister"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Sister"}
    />
    Sister
  </label>  <hr />

  <label id="BIL_SS-L" htmlFor="BIL_SS">
    <input
      type="radio"
      data-type="radio"
      id="BIL_SS"
      name="P2_I864_P1_1f"
      value="Brother-in-law"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Brother-in-law"}
    />
    Brother-in-law
  </label>  <hr />

  <label id="SIL_SS-L" htmlFor="SIL_SS">
    <input
      type="radio"
      data-type="radio"
      id="SIL_SS"
      name="P2_I864_P1_1f"
      value="Sister-in-law"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Sister-in-law"}
    />
    Sister-in-law
  </label>  <hr />

  <label id="Son_SS-L" htmlFor="Son_SS">
    <input
      type="radio"
      data-type="radio"
      id="Son_SS"
      name="P2_I864_P1_1f"
      value="Son"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Son"}
    />
    Son
  </label>  <hr />

  <label id="Daughter_SS-L" htmlFor="Daughter_SS">
    <input
      type="radio"
      data-type="radio"
      id="Daughter_SS"
      name="P2_I864_P1_1f"
      value="Daughter"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Daughter"}
    />
    Daughter
  </label>  <hr />

  <label id="Son_In_Law_SS-L" htmlFor="Son_In_Law_SS">
    <input
      type="radio"
      data-type="radio"
      id="Son_In_Law_SS"
      name="P2_I864_P1_1f"
      value="Son-in-law"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Son-in-law"}
    />
    Son-in-law
  </label>  <hr />

  <label id="DIL_SS-L" htmlFor="DIL_SS">
    <input
      type="radio"
      data-type="radio"
      id="DIL_SS"
      name="P2_I864_P1_1f"
      value="Daughter-in-law"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Daughter-in-law"}
    />
    Daughter-in-law
  </label>  <hr />

  <label id="Grandmother_SS-L" htmlFor="Grandmother_SS">
    <input
      type="radio"
      data-type="radio"
      id="Grandmother_SS"
      name="P2_I864_P1_1f"
      value="Grandmother"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Grandmother"}
    />
    Grandmother
  </label>  <hr />

  <label id="Grandfather_SS-L" htmlFor="Grandfather_SS">
    <input
      type="radio"
      data-type="radio"
      id="Grandfather_SS"
      name="P2_I864_P1_1f"
      value="Grandfather"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Grandfather"}
    />
    Grandfather
  </label>  <hr />

  <label id="Granddaughter_SS-L" htmlFor="Granddaughter_SS">
    <input
      type="radio"
      data-type="radio"
      id="Granddaughter_SS"
      name="P2_I864_P1_1f"
      value="Granddaughter"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Granddaughter"}
    />
    Granddaughter
  </label>  <hr />

  <label id="Grandson_SS-L" htmlFor="Grandson_SS">
    <input
      type="radio"
      data-type="radio"
      id="Grandson_SS"
      name="P2_I864_P1_1f"
      value="Grandson"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Grandson"}
    />
    Grandson
  </label>  <hr />

  <label id="Legal_guardian_SS-L" htmlFor="Legal_guardian_SS">
    <input
      type="radio"
      data-type="radio"
      id="Legal_guardian_SS"
      name="P2_I864_P1_1f"
      value="Legal guardian"
      aria-required="true"
      required="required"
      onChange={handleInputChange}
      checked={formData.relationship === "Legal guardian"}
    />
    Legal guardian
  </label>
</div>
<div className="additional-div">
                <div className="text-white font-bold p-2 ndRendring text">
                Are you at least 18 years old?

                  <span className="text-red-500">*</span>
                </div>
                <div className="form p-1">
                  <input
                    type="radio"
                    name="age18"
                    value="Yes" // Set the value to "Yes"
                    id="nn"
                    checked={formData.age18 === "Yes"} // Check if interview is "Yes"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="nn">Yes</label> &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="age18"
                    value="No" // Set the value to "No"
                    id="nnq"
                    checked={formData.age18 === "No"} // Check if interview is "No"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="nnq">No</label>
          
                </div>
              </div>
</div>
):null}
{formData.age18==="Yes"?(

<div className="additional-div">
<div className="text-white font-bold p-2 ndRendring text">
  Have you ever served honorably in the U.S. armed forces?
  <span className="text-red-500">*</span>
</div>
<div className="form p-1">
  <input
    type="radio"
    name="servedInUSArmedForces"
    value="Yes"
    required="required"

    id="servedYes"
    checked={formData.servedInUSArmedForces === "Yes"}
    onChange={handleInputChange}
  />
  <label htmlFor="servedYes">Yes</label> &nbsp;&nbsp;
  <input
    type="radio"
    name="servedInUSArmedForces"
    value="No"
    id="servedNo"
    required="required"
    checked={formData.servedInUSArmedForces === "No"}
    onChange={handleInputChange}
  />
  <label htmlFor="servedNo">No</label>
  <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
  Indicate if you have ever served honorably in the U.S. Army, Marines, Navy, Air Force, Space Force or Coast Guard.
<a href="#">Explain more {">>"}</a>

                  </div>
</div>
</div>

):null}

{formData.servedInUSArmedForces==="Yes" ?(
  <div> 
     <div className="additional-div">
    <div className="text-white font-bold p-2 ndRendring text">
      Are you currently on active duty in the U.S. Armed Forces or U.S. Coast Guard?
      <span className="text-red-500">*</span>
    </div>
    <div className="form p-1">
      <input
        type="radio"
        name="activeDutyUSArmedForces"
        value="Yes"
        id="activeDutyYes"
        checked={formData.activeDutyUSArmedForces === "Yes"}
        onChange={handleInputChange}
      />
      <label htmlFor="activeDutyYes">Yes</label> &nbsp;&nbsp;
      <input
        type="radio"
        name="activeDutyUSArmedForces"
        value="No"
        id="activeDutyNo"
        checked={formData.activeDutyUSArmedForces === "No"}
        onChange={handleInputChange}
      />
      <label htmlFor="activeDutyNo">No</label>
      <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
      Indicate if you are on active duty in the U.S. Army, Marines, Navy, Air Force, Space Force or Coast Guard. &nbsp; 
<a href="#">Explain more {">>"}</a>

                  </div>
    </div>
  </div>
  
  <div className="additional-div">
  <div className="text-white font-bold p-2 ndRendring text">
    Do you have a Social Security Number?
    <span className="text-red-500">*</span>
  </div>
  <div className="form p-1">
    <input
      type="radio"
      name="hasSocialSecurityNumber"
      value="Yes"
      id="ssnYes"
      checked={formData.hasSocialSecurityNumber === "Yes"}
      onChange={handleInputChange}
    />
    <label htmlFor="ssnYes">Yes</label> &nbsp;&nbsp;
    <input
      type="radio"
      name="hasSocialSecurityNumber"
      value="No"
      id="ssnNo"
      checked={formData.hasSocialSecurityNumber === "No"}
      onChange={handleInputChange}
    />
    <label htmlFor="ssnNo">No</label>

    <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
    Domicile is the place you have a home and intend to remain as a home. &nbsp; 
<a href="#">Explain more {">>"}</a>

                  </div>
  </div>
</div>


  </div>
  
):null
}
{formData.servedInUSArmedForces==="No"?(
     <div>
     <div className="additional-div">
     <div className="text-white font-bold p-2 ndRendring text">
       Do you have a Social Security Number?
       <span className="text-red-500">*</span>
     </div>
     <div className="form p-1">
       <input
         type="radio"
         name="hasSocialSecurityNumber"
         value="Yes"
         id="ssnYes"
         checked={formData.hasSocialSecurityNumber === "Yes"}
         onChange={handleInputChange}
       />
       <label htmlFor="ssnYes">Yes</label> &nbsp;&nbsp;
       <input
         type="radio"
         name="hasSocialSecurityNumber"
         value="No"
         id="ssnNo"
         checked={formData.hasSocialSecurityNumber === "No"}
         onChange={handleInputChange}
       />
       <label htmlFor="ssnNo">No</label>
       <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
       A Social Security Number is required to prepare and file this form
   <a href="#">Explain more {">>"}</a>
   
                     </div>
     </div>
   </div>
   
  
   </div>
):null
}


{formData.hasSocialSecurityNumber==="Yes" ?(
    <div className="additional-div">
    <div className="text-white font-bold p-2 ndRendring text">
      Are you currently domiciled in the United States?
      <span className="text-red-500">*</span>
    </div>
    <div className="form p-1">
      <input
        type="radio"
        name="isDomiciledInUS"
        value="Yes"
        id="domiciledYes"
        checked={formData.isDomiciledInUS === "Yes"}
        onChange={handleInputChange}
      />
      <label htmlFor="domiciledYes">Yes</label> &nbsp;&nbsp;
      <input
        type="radio"
        name="isDomiciledInUS"
        value="No"
        id="domiciledNo"
        checked={formData.isDomiciledInUS === "No"}
        onChange={handleInputChange}
      />
      <label htmlFor="domiciledNo">No</label>
      <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
    Domicile is the place you have a home and intend to remain as a home. &nbsp; 
<a href="#">Explain more {">>"}</a>

                  </div>
    </div>
  </div>
  
):null}
{formData.hasSocialSecurityNumber === "No"?(
     <div className="bg-red-200 error">
     <div className="icon-and-message">
       <span className="icon">
         <img src={crosspic} alt="" width="52" height="32" />
       </span>
       <p className="message ml-2">
         <span className="font-bold">
         We found a problem. Based on your answer, you cannot continue         </span>
         You must have a Social Security Number to prepare Form I-864, Affidavit of Support. You can apply for an SSN through the Social Security Administration.
       </p>
     </div>
     <div className="icon-and-message">
       <span className="icon">
         <img src={Searchicon} alt="" width="32" height="32" />
       </span>
      <a href="#" rel="noopener noreferrer">Apply for a Social Security Number and Card</a>
     </div>
   </div>
):null}
{formData.age18==="No"?(
      <div className="bg-red-200 error">
      <div className="icon-and-message">
        <span className="icon">
          <img src={crosspic} alt="" width="72" height="32" />
        </span>
        <p className="message ml-2">
          <span className="font-bold">
          We found a problem. Based on your answer, you cannot continue.           </span>
          Generally you must be at least 18 years of age to file Form I-864. Additionally, you must be at least 21 years of age to petition certain types of relatives.
        </p>
      </div>
    </div>
):null}

{formData.isDomiciledInUS=="No"?(
    <div className="additional-div">
  <div className="text-white font-bold p-2 ndRendring text">
    Do you intend to reestablish domicile in the United States?
    <span className="text-red-500">*</span>
  </div>
  <div className="form p-1">
    <input
      type="radio"
      name="intendToReestablishDomicile"
      value="Yes"
      id="reestablishYes"
      checked={formData.intendToReestablishDomicile === "Yes"}
      onChange={handleInputChange}
    />
    <label htmlFor="reestablishYes">Yes</label> &nbsp;&nbsp;
    <input
      type="radio"
      name="intendToReestablishDomicile"
      value="No"
      id="reestablishNo"
      checked={formData.intendToReestablishDomicile === "No"}
      onChange={handleInputChange}
    />
    <label htmlFor="reestablishNo">No</label>
  </div>
</div>

):null}
{formData.intendToReestablishDomicile ==="No"?(
 <div className="bg-red-200 error">
 <div className="icon-and-message">
   <span className="icon">
     <img src={crosspic} alt="" width="32" height="32" />
   </span>
   <p className="message ml-2">
     <span className="font-bold">
       Sorry. Based on your answers, you cannot continue.
     </span>
     This application is used by permanent residents to apply for a
     replacement or renewal of their existing Permanent Resident
     Cards.
   </p>
 </div>
 <div className="icon-and-message">
   <span className="icon">
     <img src={Searchicon} alt="" width="32" height="32" />
   </span>
   <p className="message ml-2">
     <span className="font-bold">
       Sorry. Based on your answers, you cannot continue.
     </span>
     Learn more about family-based immigration or try our
     Immigration Form Finder.
   </p>
 </div>
</div>

): null  }
{formData.isDomiciledInUS=="Yes" || formData.intendToReestablishDomicile==="Yes"?(
<div
style={{
  backgroundColor: "#92D3A8",
  padding: "10px",
  margin: "10px",
  color: 'rgba(60, 60, 60, 0.7)',
  textAlign: "center",
  borderRadius: "9px",
  width: "90%",
  marginLeft: "6%",
}}
>
<div style={{ display: "flex", alignItems: "center" }}>
  <span className="icon">
    <img src={sucess} alt="" width="32" height="32" />
  </span>
  <div style={{ marginLeft: "10px", textAlign: "left" }}>
    <p style={{ color: "#006400", fontWeight: "bold",                color: 'rgba(60, 60, 60, 0.7)',
}}>
Congratulations, you are likely eligible to file Form I-864 Affidavit of Support.
    </p>
    <p>
      Please click on "Next Page" below to continue your
      application.
    </p>
  </div>
</div>

</div>
):null}
          <br />
          <div className="w-full ml-8">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded mt-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
                onClick={()=>navigate("/I8643")}
              >
                NEXT →
              </button>
            </div>
          </form>

        </div>

        <FormFotter />
      </div>
    </div>
  );
};
export default I864;
