







import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context751';
import  crosspic from "../../Assets/crosspic.png"


const ChildrenInformation = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code,nameSets ,handleAddAnother ,handleRemove ,handleChange } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/preparer751")
    }

    console.log(formData.filingOption)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
   <div className='flex  flex-col md:flex-row justify-between   w-full items-center bg-blue-500 md:h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full  text-green-300 font-semibold'>verified and security</span>
            <span className='font-bold text-white bg-blue-600  text-sm md:text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

        <h1 className='text-blue-600  font-semibold text-sm'>Children</h1>
        <p className='font-sm'>List all of your children in this section, even if they are from a previous marriage. If your dependent child acquired conditional residence on the same day as you or within 90 days thereafter, you may include him/her on this same petition. Any children that acquired conditional residence outside of the 90-day window must file a separate Form I-751 to have conditions on their status removed.

Do you have any children (under age 21)?</p>


    
<div className="mb-4 flex flex-col mt-6">
        <label htmlFor="haveChildren" className="bg-blue-700  text-white fles rounded p-4">
          Do you have any children (under age 21)?
        </label>
        <div className="flex bg-blue-100 p-4 rounded">
          <label htmlFor="haveChildrenYes" className="text-blue-700">
            <input
              type="radio"
              id="haveChildrenYes"
              name="haveChildren"
              value="Yes"
              checked={formData.haveChildren === 'Yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label htmlFor="haveChildrenNo" className="text-blue-700 ml-4">
            <input
              type="radio"
              id="haveChildrenNo"
              name="haveChildren"
              value="No"
              checked={formData.haveChildren === 'No'}
              onChange={handleInputChange}
            />
            No
          </label>
        </div>
      </div>


{formData.haveChildren === 'Yes'
    &&
    <>

      <div className="mt-10">
  {nameSets.map((nameSet, index) => (
    <div key={nameSet.id} className="bg-slate-50 rounded border border-blue-200 mt-10">
      {index > 0 && (
        <div className="flex w-full justify-between ml-2">
          <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="flex items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
          >
            Remove
            <img src={crosspic} alt="" />
          </button>
        </div>
      )}

<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left">
    Given Name (First Name): <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`givenName-${nameSet.id}`}
    name={`givenName-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.givenName || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'givenName')}
  />
</div>

{/* Responsive styling for middleName input field */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left">
    Middle Name (First Name): <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`middleName-${nameSet.id}`}
    name={`middleName-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.middleName || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'middleName')}
  />
</div>

     {/* Add Family Name input field */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left">
    Family Name (Last Name): <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`familyName-${nameSet.id}`}
    name={`familyName-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.familyName || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'familyName')}
  />
</div>

{/* Responsive styling for Date of Birth input field */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`dateOfBirth-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left">
    Date of Birth: <span className="text-red-500">*</span>
  </label>
  <input
    type="date"
    id={`dateOfBirth-${nameSet.id}`}
    name={`dateOfBirth-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.dateOfBirth || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'dateOfBirth')}
  />
</div>

{/* Responsive styling for Alien Registration Number input field */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2">
  <label htmlFor={`aNumber-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left">
    Alien Registration Number (A-Number): <span className="text-red-500">*</span>
  </label>
  <input
    type="number"
    id={`aNumber-${nameSet.id}`}
    name={`aNumber-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.aNumber || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'aNumber')}
  />
</div>


      {/* Add "Is this child applying with you?" radio buttons */}

{/* Add "Is this child living with you?" radio buttons */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2 items-center">
  <label htmlFor={`livingWithYou-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left mb-2 md:mb-0">
    Is this child living with you? <span className="text-red-500">*</span>
  </label>
  <div className="flex items-center justify-center md:justify-start">
    <label htmlFor={`livingWithYouYes-${nameSet.id}`} className="text-blue-700">
      <input
        type="radio"
        id={`livingWithYouYes-${nameSet.id}`}
        name={`livingWithYou-${nameSet.id}`}
        value="Yes"
        checked={nameSet.livingWithYou === 'Yes'}
        onChange={(e) => handleChange(e, nameSet.id, 'livingWithYou')}
      />
      Yes
    </label>
    <label htmlFor={`livingWithYouNo-${nameSet.id}`} className="text-blue-700 ml-4">
      <input
        type="radio"
        id={`livingWithYouNo-${nameSet.id}`}
        name={`livingWithYou-${nameSet.id}`}
        value="No"
        checked={nameSet.livingWithYou === 'No'}
        onChange={(e) => handleChange(e, nameSet.id, 'livingWithYou')}
      />
      No
    </label>
  </div>
</div>

   {  nameSet.livingWithYou === 'No'
   &&
   <>
   <div className="mb-4 flex flex-col md:flex-row mt-10 ml-2 items-center">
  <label htmlFor={`streetNumberName-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left mb-2 md:mb-0">
    Street Number and Name: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`streetNumberName-${nameSet.id}`}
    name={`streetNumberName-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0 ml-2"
    value={nameSet.streetNumberName || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'streetNumberName')}
  />
</div>

{/* Is there an apartment, suite, or floor? dropdown */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2 items-center">
  <label htmlFor={`aptSuiteFloor-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left mb-2 md:mb-0">
    Is there an apartment, suite, or floor?: <span className="text-red-500">*</span>
  </label>
  <select
    id={`aptSuiteFloor-${nameSet.id}`}
    name={`aptSuiteFloor-${nameSet.id}`}
    className="border border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0 ml-2"
    value={nameSet.aptSuiteFloor || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'aptSuiteFloor')}
  >
    <option value="">Select Option</option>
    <option value="No">No</option>
    <option value="Apt">Apt</option>
    <option value="Suite">Suite</option>
    <option value="Floor">Floor</option>
  </select>
</div>

{/* City or Town input field */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2 items-center">
  <label htmlFor={`cityOrTown-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left mb-2 md:mb-0">
    City or Town: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`cityOrTown-${nameSet.id}`}
    name={`cityOrTown-${nameSet.id}`}
    className="border w-full outline-none border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.cityOrTown || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'cityOrTown')}
  />
</div>

{/* State (or Territory) dropdown */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2 items-center">
  <label htmlFor={`stateOrTerritory-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left mb-2 md:mb-0">
    State (or Territory): <span className="text-red-500">*</span>
  </label>
  <select
    id={`stateOrTerritory-${nameSet.id}`}
    name={`stateOrTerritory-${nameSet.id}`}
    className="border w-full outline-none border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0"
    value={nameSet.stateOrTerritory || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'stateOrTerritory')}
  >
    <option value="">Select a Country</option>
    {code.map((countryName) => (
      <option key={countryName} value={countryName}>
        {countryName}
      </option>
    ))}
  </select>
</div>

{/* ZIP Code input field */}
<div className="mb-4 flex flex-col md:flex-row mt-6 ml-2 items-center">
  <label htmlFor={`zipCode-${nameSet.id}`} className="text-blue-700 w-full md:w-[180px] text-center md:text-left mb-2 md:mb-0">
    ZIP Code: <span className="text-red-500">*</span>
  </label>
  <input
    type="text"
    id={`zipCode-${nameSet.id}`}
    name={`zipCode-${nameSet.id}`}
    className="border w-full outline-none border-gray-300 hover-border-2 hover-border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-4 md:px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg mt-2 md:mt-0 ml-2"
    value={nameSet.zipCode || ''}
    onChange={(e) => handleChange(e, nameSet.id, 'zipCode')}
  />
</div>
      </>}
      {/* ... Other input fields ... */}
    </div>
  ))}

  {nameSets.length === 0 && (
    <div>
      <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
      <span className="text-gray-500">Add Other</span>
    </div>
  )}

  <button
    type="button"
    onClick={handleAddAnother}
    className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
  >
    Add Another Name
  </button>
</div>;

</>
}



<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
}

export default ChildrenInformation