


import React from 'react';
import Header from '../../components/Header';
import { MdArrowDropDown } from "react-icons/md";
import { useState } from 'react';
import { BsArrowRight} from "react-icons/bs";
// import tikpik from "../Assets/tikpic.png"
// import crosspic from "../Assets/crosspic.png"
import FormFotter from '../../components/FormFotter';
import  { useEffect} from 'react';
import { useFormContext } from '../../UseContext/UseContext';
import '../I-90/style.css';

const Form2 = () => {

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [email, setEmail] = useState('');
  const [emailEmpty, setEmailEmpty] = useState(false);
  useEffect(() => {
    fetch('https://restcountries.com/v3.1/all')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // const handleSelectChange = (event) => {
  //   setSelectedCountry(event.target.value);
  // };
  const [formData, setFormData] = useState({
    givenName: '',
    middleName: '',
    familyName: '',
    email: '',
    mobilePhone: '',
    agreeToTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    givenName: false,
    middleName: false,
    familyName: false,
    email: false,
    mobilePhone: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value === '',
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const isFormValid = Object.values(formErrors).every((error) => !error);
  
    if (isFormValid) {
      console.log('Form Data:', formData);
  
    } else {
      console.error('Form submission error: Some fields are empty.');
    }
  };
  


    
  return (
    

  
    <div className='w-full'>
  <Header />
  <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
  <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg '>

<span className='ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security'>verified and security</span>
<span className='font-bold text-white text-xl formheaderfont'>USCIS Form AR-11 - Alien's Change of Address Card</span>
<div className='h-full'>
  <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
  <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
</div>
</div>

    <div className='flex flex-col border w-full pb-10 bg-slate-100'>

      <div className='flex justify-between items-center'>
        <span className='mt-4 font-bold text-xl ml-2 text-black'>Request a Text / Email Notification</span>
      </div>

      <span className='mt-2 font-thin ml-2'>
        Complete the information below to prepare a free Form G-1145. Place G-1145 with your primary application or petition. USCIS will provide one e-mail and/or text confirmation (with receipt number) after they accept your application or petition.
      </span>
    </div>

    <form onSubmit={handleSubmit} className='mt-6 w-full md:w-5/6'>
        <p>Which application / petition are you filing?</p>
        <div className='div1'>
          <div className="mb-4 flex ">
          <select
  id="tfa_8594"
  name="tfa_8594"
  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded-l shadow leading-tight focus:outline-none focus:shadow-outline with-arrow "
  aria-required="false"
  value={formData.selectedApplication}
  onChange={handleInputChange}
>
                       <option value="I-129F, Petition for Alien Fiance(e)">I-129F, Petition for Alien Fiance(e)</option><option value="I-130, Petition for Alien Relative">I-130, Petition for Alien Relative</option><option value="I-131, Application for Travel Document">I-131, Application for Travel Document</option><option value="I-485, Application to Register Permanent Residence or Adjust Status">I-485, Application to Register Permanent Residence or Adjust Status</option><option value="I-765, Application for Employment Authorization">I-765, Application for Employment Authorization</option><option value="I-817, Application for Family Unity Benefits">I-817, Application for Family Unity Benefits</option><option value="I-821, Application for Temporary Protected Status">I-821, Application for Temporary Protected Status</option><option value="I-821D, Consideration of Deferred Action for Childhood Arrivals (DACA)">I-821D, Consideration of Deferred Action for Childhood Arrivals (DACA)</option><option value="I-824, Application for Action on an Approved Application or Petition">I-824, Application for Action on an Approved Application or Petition</option><option value="I-90, Application to Replace Permanent Resident Card">I-90, Application to Replace Permanent Resident Card</option><option value="N-400, Application for Naturalization">N-400, Application for Naturalization</option><option value="N-565, Application for Replacement Naturalization/Citizenship Document">N-565, Application for Replacement Naturalization/Citizenship Document</option><option value="N-600, Application for Certificate of Citizenship">N-600, Application for Certificate of Citizenship</option><option value="Other">Other</option>          
                         </select>
          </div>
          <div className="custom-div"></div>
        </div>

        <p id="name-HH">
          Enter your name <u>exactly as you did on the application or petition</u> for which you would like to receive e-notification.
        </p>
<br />
        <div className={`mb-4 flex ${formErrors.givenName ? 'border-red-500' : ''}`}>
          <label htmlFor="givenName" className="text-blue-700">Given Name (First Name): <span className="text-red-500">*</span></label>
          <input
            type="text"
            id="givenName"
            name="givenName"
            value={formData.givenName}
            onChange={handleInputChange}
            className={`border ${
              formErrors.givenName
                ? 'border-red-500 bg-red-100'
                : 'border-gray-300 hover:border-2 hover:border-indigo-600'
            } px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg focus:outline-none focus:bg-white focus:border-gray-500 ml-2 md:ml-10`}
          />
        </div>

        {/* Middle Name(s) */}
        <div className={`mb-4 flex ${formErrors.middleName ? 'border-red-500' : ''}`}>
          <label htmlFor="middleName" className="text-blue-700">Middle Name(s): <span className="text-red-500">*</span></label>
         &nbsp;&nbsp;<input
            type="text"
            id="middleName"
            name="middleName"
            value={formData.middleName}
            onChange={handleInputChange}
            className={`border ${
              formErrors.middleName
                ? 'border-red-500 bg-red-100'
                : 'border-gray-300 hover:border-2 hover:border-indigo-600'
            } px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg focus:outline-none focus:bg-white focus:border-gray-500 ml-2 md:ml-24`}
          />
        </div>

        {/* Family Name (Last Name) */}
        <div className={`mb-4 flex ${formErrors.familyName ? 'border-red-500' : ''}`}>
          <label htmlFor="familyName" className="text-blue-500">Family Name (Last Name): <span className="text-red-500">*</span></label>
          &nbsp; <input
            type="text"
            id="familyName"
            name="familyName"
            value={formData.familyName}
            onChange={handleInputChange}
            className={`border ${
              formErrors.familyName
                ? 'border-red-500 bg-red-100'
                : 'border-gray-300 hover:border-2 hover:border-indigo-600'
            } px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg focus:outline-none focus:bg-white focus:border-gray-500 ml-2 md:ml-8`}
          />
        </div>

      <div className='details'>
        <h3 id="contact_info-L">Contact Information</h3>
        <p id="contact_info-HH">To complete this form, we only need a few pieces of information.</p>

   
        <div className={`mb-4 flex ${formErrors.email ? 'border-red-500' : ''}`}>
          <label htmlFor="email" className="text-blue-500">E-mail Address <span className="text-red-500">*</span></label>
       &nbsp; &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className={`border ${
              formErrors.email
                ? 'border-red-500 bg-red-100'
                : 'border-gray-300 hover:border-2 hover:border-indigo-600'
            } px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg focus:outline-none focus:bg-white focus:border-gray-500 ml-2 md:ml-8`}
          />
        </div>

        {/* Mobile Phone Number */}
        <div className={`mb-4 flex ${formErrors.mobilePhone ? 'border-red-500' : ''}`}>
          <label htmlFor="mobilePhone" className="text-blue-500">Mobile Phone Number<span className="text-red-500">*</span></label>
          &nbsp; &nbsp;&nbsp;&nbsp;    &nbsp;   <input
            type="tel"
            id="mobilePhone"
            name="mobilePhone"
            value={formData.mobilePhone}
            onChange={handleInputChange}
            className={`border ${
              formErrors.mobilePhone
                ? 'border-red-500 bg-red-100'
                : 'border-gray-300 hover:border-2 hover:border-indigo-600'
            } px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg focus:outline-none focus:bg-white focus:border-gray-500 ml-2 md:ml-8`}
          />
        </div>
      </div>

      <div className='details'>
        <label htmlFor="agreeToTerms" id="agreeToTermsLabel">
          <input
            type="checkbox"
            data-type="checkbox"
            id="agreeToTerms"
            name="tfa_8592"
            value="I agree to the Terms and Conditions."
            aria-required="true"
          />
          <a target="_blank" href="https://citizenpath.com/terms-use/"> I agree to the Terms and Conditions</a> <span className="text-red-500">*</span>
        </label>
      </div>

      <h5 className="text-dark py-5">You've finished preparing this form. It's time to finalize and generate the documents.</h5>

      <div className='flex justify-center mt-20'>
        <button type="submit" className='bg-blue-700 text-white px-24 py-2 md:px-36'>
          CREATE MY FILE
        </button>
      </div>
    </form>
    <FormFotter className />
  </div>
</div>

  
    
  )
}

export default Form2
