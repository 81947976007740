import React, { createContext, useContext, useState, useEffect } from 'react';
import axiosRequest from '../utils/axiosRequest';

// Create a context for the form state
const FormContext485 = createContext();

// Create a custom hook to access the form state and update functions
export function useFormContext() {
  return useContext(FormContext485);
}

// Define the initial state
const initialState = {
  nameOfApplicant: '',
  middleNames: '',
  familyName: '',
  hasUsedAnotherName: '',
  daytimePhoneNumber: '',
  mobilePhoneNumber: '',
  email: '',
  dateOfBirth: '',
  cityOfBirth: '',
  hasUsedDifferentDOB: '',
  ethnicity: '',
  raceWhite: '',
  raceBlack: '',
  raceNative: '',
  racePacific: '',
  raceAsian: '',
  eyeColor: '',
  hairColor: '', 
  countryOfCitizenship: '', // Add ethnicity field
  i94RecordReceived: '',
  cityOrTown: '',
  state: '',
  dateOfRecentEntry: '',
  entryDocument: '', // Passport, Travel Document, Neither
  overstayed: '',
  applicantMailingAddressinCareOfName: '',
  applicantMailingAddressstreetNumberAndName: '',
  applicantMailingAddresscityOrTown: '',
  applicantMailingAddressstateOrTerritory: '',
  applicantMailingAddresszipCode: '',
  stateOrTerritoryOptions:"",
  currentPhysicalAddresssameAsMailingAddress:"",
  currentPhysicalAddressdateBeganLiving:"",
  applicantMailingAddressinCareOfName2: '',
  applicantMailingAddressstreetNumberAndName2: '',
  applicantMailingAddresscityOrTown2: '',
  applicantMailingAddressstateOrTerritory2: '',
  applicantMailingAddresszipCode2: '',
  applicantMailingAddressinCareOfName3: '',
  applicantMailingAddressstreetNumberAndName3: '',
  applicantMailingAddresscityOrTown3: '',
  applicantMailingAddressstateOrTerritory3: '',
  applicantMailingAddresszipCode3: '',
  employed: '',
  employmentStatus: '',
  countryOfBirth3:"",
  countryOfBirth2:"",

  //family info
  howManyTimesHaveYouBeenMarried: 0,
  dateOfMarriage: '',
  countryOfCitizenship: '',
  stateOrProvince: '',
  city: '',
  currentSpouseName: '',
  currentSpouseMiddleName: '',
  currentSpouseLastName: '',
  currentSpouseDOB: '',
  currentSpouseCity: '',
  currentSpouseStateOrProvince: '',
  currentSpouseAlienRegestrationNumber: '',
  currentSpouseIsUsArmedForcesMember: '',
  currentSpouseStateOrProvince2: '',
  childrenCount: 0,
  childFirstName: '',
  childLastName: '',
  childMiddleName: '',
  childDOB: '',
  childRelationship: '',
  childAlienNumber: '',
  childCountryOfBirth: '',
  childUnmarriedAndUnder21: '',
  childAppylingWithYou: '',
  dontKnowMotherDOB: '',
  motherDOB: '',
  cityOrTownOfMother: '',
  countryOfMother: '',
  cityOrTownOfFather: '',
  countryOfFather: '',


  motherGivenName: '',
  motherMiddleName: '',
  motherFamilyName:'',
  parentsDifferentBirthName: '',
  motherGender: '',
  motherDOB: '',
  dontKnowMotherDOB: '',
  motherCityOrTown: '',
  motherCountryOfBirth: '',
  isMotherDeceased: '',
  fatherGivenFirstName: '',
  fatherMiddleName: '',
  fatherLastName: '',
  fatherDifferentBirthName: '',
  fatherGender: '',
  fatherDOB: '',
  dontKnowFatherDOB: '',
  fatherCityOrTown: '',
  fatherCountryOfBirth: '',
  isFatherDeceased: '',
  fatherCountry: '',
  fatherFirstNameAtBirth: '',
  fatherMiddleNameAtBirth: '',
  fatherLastNameAtBirth:'',

  //moral character
  deniedAddmission: '',
  denidedVisa: '',
  workedInUsWithoutAuth: '',
  violatedTermsAndCond: '',
  deportationProcessing: '',
  issuedFinalOrder: '',
  priorIssuedFinalOrder: '',
  lawfulPermanentResident: '',
  voluntaryDeparture: '',
  appliedForRelief: '',
  nonImmigrantExchangeVisitor: '',
  arrested: '',
  cimmittedCrime: '',
  pledGuilty: '',
  punished: '',
  defendantOrAccused: '',
  controlledSubstanceViolation: '',
  multipleConvictions: '',
  controlledSubstanceTrafficking: '',
  aidedTrafficking: '',
  familyMemberTraffickingBenefit: '',
  prostitutionEngagement: '',
  prostitutionProcurement: '',
  prostitutionProceedsReceived: '',
  illegalActivitiesIntent: '',
  religiousFreedomViolations: '',
  traffickingOfPersons: '',
  involuntaryServitudeTrafficking: '',
  traffickingCollusion: '',
  familyTraffickingBenefit: '',
  moneyLaundering: '',

  //moral2
  intentToEngageInMaliciousActivity:'',
  exportViolationIntent:'',
  opposingGovernmentIntent: '',
  unlawfulActivityIntent: '',
  endangeringActivity: '',
  foreignPolicyAdverseActivity: '',
  seriousViolentActivity: '',
  groupInvolvement: '',
  supportedActivity: '',
  weaponsTraining: '',
  intendToEngageInListedActivities: '',
  seriousIllegalActivities: '',
  groupInvolvementInIllegalActivities: '',
  recruitedForIllegalActivities: '',
  supportedIllegalActivities: '',
  supportedActivities: '',
  militaryTrainingFromIllegalGroup: '',
  weaponAssistance: '',
  detentionExperience: '',
  weaponUseInGroup: '',
  militaryService: '',
  communistAffiliation: '',
  naziPersecutionParticipation: '',
  tortureOrGenocide:'',
  killing: '',
  severeInjury: '',
  nonConsensualSexualContact: '',
  religiousBeliefLimitation: '',
  childRecruitment: '',
  childParticipationInHostilities:'',
  publicChargeInadmissibility:'',
  removalProceedingsAttendance:'',
  fraudulentDocumentation:'',
  usedFraudulentDocs: '',
  misrepresentationOnApplication:'',
  falseClaimToUSCitizenship:'',
  stowaway:'',
  alienSmuggling:'',
  excludedDeportedRemoved:'',
  enteredWithoutInspection:'',
  stayedBetween180And365Days:'',
  stayedOneYearOrMore:'',
  unlawfullyPresentMoreThanOneYear:'',
  deportedExcludedRemoved:'',
  practicePolygamy:'',
  accompanyingInadmissible:'',
  assistedInDetainingChild:'',
  votedInViolation:'',
  renouncedUSCitizenshipForTaxAvoidance:'',
  appliedForExemptionFromService: '',
  relievedOrDischargedFromService: '',
  convictedOfDesertion: '',
  leftOrRemainedOutsideUs: '',
  invlolvedmentIntheOrganization: '',
  applicantsAge: '',
  accomodationBecauseOfDisabilities: '',
  recieveAnyHelpFormInterpreter: '',

  //applicants participation
  orgCountry: '',
  groupName:'',
  organizationName: '',
  orgDateFrom: '',
  orgDateTo:'',
  orgCityOrTown:'',
  orgStateOrProvince:'',
  presentMember:'',
  applicantSameAsAtleastOneParent:'',
  isDeaf: '',
  isBlind: '',
  iamtheApplicant: '',
  whoPreparedThisApplication:'',
  preparerFirstName:'',
  preparerMiddleName:'',
  preparerLastName:'',
  preparerAddress:'',
  apartment:'',
  preparerCityOrTown:'',
  preparerCountry:'',
  preparerStateOrTerritory:'',
  preparerZipCode: '',
  preparerEmail: '',
  preparerDaytimePhoneNo:'',
  preparerPhoneNo:'',
  apartment: '',
  clientRepresentation: ''

};

// Create a provider component to manage the state
export function FormProviderFor485({ children }) {
  const [formData, setFormData] = useState(initialState);
  const [code, setcode] = useState([]);
  const [nameSets, setNameSets] = useState([{ id: 1 }]);

  const handleAddAnother = () => {
    const newId = nameSets.length + 1;
    setNameSets([...nameSets, { id: newId }]);
  };

  const handleRemove = (id) => {
    const updatedNameSets = nameSets.filter((nameSet) => nameSet.id !== id);
    setNameSets(updatedNameSets);
  };

  const handleChange = (e, id, field) => {
    const updatedNameSets = [...nameSets];
    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {
      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };

      setNameSets(updatedNameSets);
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const countryNames = data.map((country) => country.name.common);
        setcode(countryNames);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchDependentChildrenOptions = async () => {
      try {
        const response = await fetch('https://restcountries.com/v3.1/all');
        const data = await response.json();

        const dependentChildrenOptions = data.map((country, index) => ({
          value: index + 1,
          label: `${index + 1}`,
        }));

        setFormData((prevData) => ({
          ...prevData,
          dependentChildrenCountOptions: dependentChildrenOptions,
        }));
      } catch (error) {
        console.error('Error fetching dependent children options:', error);
      }
    };

    fetchDependentChildrenOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    setFormData((prevData) => {
      switch (type) {
        case 'radio':
          return {
            ...prevData,
            [name]: value,
          };
        case 'date':
          return {
            ...prevData,
            [name]: e.target.valueAsDate ? e.target.valueAsDate.toISOString().slice(0, 10) : '',
          };
        case 'text':
        case 'number':
          return {
            ...prevData,
            [name]: value,
          };
        case 'select-one':
          return {
            ...prevData,
            [name]: value,
          };
        case 'checkbox':
          return {
            ...prevData,
            [name]: {
              ...prevData[name],
              [value]: checked,
            },
          };
        default:
          return {
            ...prevData,
            [name]: value,
          };
      }
    });
  };
  

  const mapFormFields = () => {
    let mapping = {};

    return mapping;
  };

  const handleSubmit485 = async () => {
    const data = mapFormFields();
    console.log(data);

    try {
      const response = await axiosRequest.post("/fillForm", {
        "formName": "G-639",
        "isCompleted": true,
        "userData": data
      });
      console.log(response);
      alert("Form submitted successfully!");
    }
    catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  return (
    <FormContext485.Provider value={{ formData, handleInputChange, code, nameSets, handleAddAnother, handleRemove, handleChange, handleSubmit485 }}>
      {children}
    </FormContext485.Provider>
  );
}