


import React from 'react'
import { useFormContext } from '../../UseContext/ContextN565';
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';



  const ApplicationInfoN565 = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange , code ,nameSets ,handleAddAnother ,handleRemove ,handleChange } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()

         
     navigate("/originalcertificate")
    }
  


    console.log(formData.applicationBasis)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>

         <div className='flex flex-col gap-4'>
            <h1 className='font-semibold text-xl text-blue-600 '>Applicant's Current Legal Name</h1>
            <p className='text-sm'>Your current legal name is the name that you use now. For many people, it's the name on your birth certificate unless it has been changed by a legal action such as a marriage or court order. Do not provide a nickname. Spell out middle names completely and do not use just the initial. If you do not have a middle name, you may leave this field empty. Explain more</p>
         </div>
            
              
    <div className='flex flex-col'>
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Given Name (First Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='givenName'
        value={formData.givenName}
        onChange={handleInputChange}
      />
      </div>
     
      <div className='flex mt-10 gap-2'>
      <label  className="w-[200px]  text-blue-500">Middle Name(s)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='middleNames'
        value={formData.middleNames}
        onChange={handleInputChange}
      />
      </div>

       <div className='flex mt-10 gap-2'>
      <label className="w-[200px]  text-blue-500">Family Name (Last Name)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='familyName'
        value={formData.familyName}
        onChange={handleInputChange}
      />
      </div>
    </div>
       

    <div className='flex flex-col mt-10'>
      <label className='flex bg-blue-600 p-4 text-white rounded'>
        Have you used any other names since birth?
      </label>
      <div className='flex gap-10 p-4 bg-blue-100'>
        <label htmlFor='usedOtherNamesYes' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='usedOtherNamesSinceBirth'
            id='usedOtherNamesYes'
            value='Yes'
            checked={formData.usedOtherNamesSinceBirth === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label htmlFor='usedOtherNamesNo' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='usedOtherNamesSinceBirth'
            id='usedOtherNamesNo'
            value='No'
            checked={formData.usedOtherNamesSinceBirth === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
      <p className='bg-slate-200 p-4'>
           Indicate if you have ever used another name such as nicknames, aliases or a maiden name. Explain more </p>
    </div>

           
{formData.usedOtherNamesSinceBirth === 'Yes'
    &&
    
    <div className="mt-10">
                {nameSets.map((nameSet, index) => (
                  <>
                  
                
                  
                  <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 '>
                    {index > 0 && (
                    
                      <div className='flex w-full justify-between ml-2'>
                    
                        <span className="text-gray-500">{`Add Other ${index}`}</span>
                        <button  type="button"  onClick={() => handleRemove(nameSet.id)} className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4">
                          Remove
                        </button>
                      </div>
                    
                    )}
                    
                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                        Given Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`givenName-${nameSet.id}`}
                        name={`givenName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        // value={nameSet.givenName}
                        // onChange={(e) => handleNameChange(nameSet.id, 'givenName', e.target.value)}
                      />
                    </div>

                    {/* Middle Name(s) */}
                    <div className="mb-4 flex ml-2">
                      <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700">
                        Middle Name(s): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`middleName-${nameSet.id}`}
                        name={`middleName-${nameSet.id}`}
                        // value={nameSet.middleName}
                        // onChange={(e) => handleNameChange(nameSet.id, 'middleName', e.target.value)}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-24'
                      />
                    </div>

                    {/* Family Name (Last Name) */}
                    <div className="mb-4 flex ml-2">
                      <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-500">
                        Family Name (Last Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`familyName-${nameSet.id}`}
                        name={`familyName-${nameSet.id}`}
                        // value={nameSet.familyName}
                        //  onChange={(e) => handleNameChange(nameSet.id, 'familyName', e.target.value)}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
                        
                      />
                    </div>
                  </div>
                  </>
                ))}


                {nameSets.length === 0 && (
                  <div>
                    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                    <span className="text-gray-500">Add Other</span>
                  </div>
                )}

                <button type="button" onClick={handleAddAnother} className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black">
                  Add Another Name
                </button>
              </div>
  }





  <hr  className='bg-blue-700 h-1 mt-10'/>
  <h1 className='text-blue-600 text-xl font-semibold mt-5'>Miritual status</h1>


  <div className='flex flex-col mt-10'>
      <label className='flex bg-blue-600 p-4 text-white rounded'>
        Marital Status
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col'>
        <label htmlFor='single' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatus'
            id='single'
            value='Single'
            checked={formData.maritalStatus === 'Single'}
            onChange={handleInputChange}
          />
          Single and never married
        </label>
        <label htmlFor='married' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatus'
            id='married'
            value='Married'
            checked={formData.maritalStatus === 'Married'}
            onChange={handleInputChange}
          />
          Married
        </label>
        <label htmlFor='divorced' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatus'
            id='divorced'
            value='Divorced'
            checked={formData.maritalStatus === 'Divorced'}
            onChange={handleInputChange}
          />
          Divorced
        </label>
        <label htmlFor='widowed' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatus'
            id='widowed'
            value='Widowed'
            checked={formData.maritalStatus === 'Widowed'}
            onChange={handleInputChange}
          />
          Widowed
        </label>
        <label htmlFor='annulled' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatus'
            id='annulled'
            value='Annulled'
            checked={formData.maritalStatus === 'Annulled'}
            onChange={handleInputChange}
          />
          Marriage Annulled
        </label>
      </div>
      <p className='bg-slate-200 p-2'>Select the marital status you have on the date you are filing this application.</p>
    </div>






















 {formData.maritalStatus !== 'Single' 
 &&
 <div className='flex flex-col mt-10'>
      <label className='flex bg-blue-600 p-4 text-white rounded'>
        Did your marital status change from when your most recent citizenship document was issued?
      </label>
      <div className='flex gap-2 p-4 bg-blue-100 flex-col'>
        <label htmlFor='maritalStatusChangedYes' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatusChanged'
            id='maritalStatusChangedYes'
            value='Yes'
            checked={formData.maritalStatusChanged === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label htmlFor='maritalStatusChangedNo' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatusChanged'
            id='maritalStatusChangedNo'
            value='No'
            checked={formData.maritalStatusChanged === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
        <label htmlFor='maritalStatusChangedUnknown' className='text-blue-700 flex gap-1'>
          <input
            type='radio'
            name='maritalStatusChanged'
            id='maritalStatusChangedUnknown'
            value='Unknown'
            checked={formData.maritalStatusChanged === 'Unknown'}
            onChange={handleInputChange}
          />
          Unknown
        </label>
      </div>
      <p className='bg-slate-200  p-2'>If you have a copy of your previously issued citizenship document, check the marital status on the front.</p>
    </div>
}

<hr  className='bg-blue-600 h-1 mt-10'/>


   <div className='mt-10'>
    <h1  className='text-blue-600  font-semibold text-xl'>Applicant's Mailing Address</h1>
    <p className='text-sm'>You may list a valid residence, APO, or commercial address. This may be a Post Office address (P.O. Box) if that is how you receive your mail. If your mail will be sent to someone other than yourself, please include an “In Care of Name” as a part of your mailing address. If your mailing address is a U.S. territory and it contains an urbanization name, list the urbanization name in the "In Care of Name" box.</p>
   </div>


   <div className='flex mt-10 gap-2 flex-col'>
    <div className='flex mt-10 gap-2'>
      <label className="w-[200px] text-blue-500">In Care of Name (if any)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='applicantMailingAddress.inCareOfName'
        value={formData.inCareOfName}
        onChange={handleInputChange}
      />
      </div>

      <div className='flex mt-10 gap-2'>

      <label className="w-[200px] text-blue-500">Street Number and Name</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='applicantMailingAddress.streetNumberAndName'
        value={formData.streetNumberAndName}
        onChange={handleInputChange}
      />
</div>

<div className='flex mt-2 gap-2'>
      <label className='w-[200px] text-blue-500'>
        Is there an apartment, suite, or floor?
      </label>
      <select
        className='w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-2 py-2 rounded ml-6'
        name='apartmentSuiteFloor'
        value={formData.apartmentSuiteFloor}
        onChange={handleInputChange}
      >
        <option value='No'>No</option>
        <option value='Apt'>Apt</option>
        <option value='Flr'>Flr</option>
        <option value='Ste'>Ste</option>
      </select>
    </div>

{formData.apartmentSuiteFloor !== 'No'
    &&
    <div className='flex mt-10 gap-2'>
      <label className="w-[200px] text-blue-500">Enter the number here:</label>
      <input
        className="w-[70px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='applicantMailingAddress.enterNumber'
        value={formData.enterNumber}
        onChange={handleInputChange}
      />
      </div>}

      <div className='flex mt-10 gap-2'>

      <label className="w-[200px] text-blue-500">City or Town</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='applicantMailingAddress.cityOrTown'
        value={formData.cityOrTown}
        onChange={handleInputChange}
      />
      </div>

      
      <div className='flex mt-10 gap-2'>
    
        <label className="w-[200px] text-blue-500">
          Country
        </label>
        <select
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          value={formData.country}
          onChange={handleInputChange}
        >
          <option value=''>Select a country</option>
          {code.map((Country, index) => (
            <option key={index} value={Country}>
              {Country}
            </option>
          ))}
        </select>
      </div>
      <div className='flex mt-10 gap-2'>
        <label  className="w-[200px] text-blue-500">
          State (or Territory)
        </label>
        <select
          className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
          onChange={handleInputChange}
        >
          <option value=''>Select a state or territory</option>
          {code.map((state, index) => (
            <option key={index} value={state}>
              {state}
            </option>
          ))}
        </select>
      </div>
   
      <div className='flex mt-10 gap-2'>

      <label className="w-[200px] text-blue-500">ZIP Code</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type='text'
        name='applicantMailingAddress.zipCode'
        value={formData.zipCode}
        onChange={handleInputChange}
      />
      </div>
    </div>
   
    <div className='flex mt-10 gap-2 flex-col  '>
    <div>
      <label htmlFor="daytimePhoneNumber" className="w-[200px] text-blue-500">Daytime Phone Number</label>
      <input
         className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="number"
        id="daytimePhoneNumber"
        name="daytimePhoneNumber"
        value={formData.daytimePhoneNumber}
        onChange={handleInputChange}
      />
     </div>
      <div className='flex mt-10 gap-2'>
      <label htmlFor="mobilePhoneNumber" className="w-[200px] text-blue-500">Mobile Phone Number (if any)</label>
      <input
        className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="number"
        id="mobilePhoneNumber"
        name="mobilePhoneNumber"
        value={formData.mobilePhoneNumber}
        onChange={handleInputChange}
      />
      </div>
  

     <div className='flex mt-10 gap-2'>
      <label htmlFor="email" className="w-[200px] text-blue-500">E-mail</label>
      <input
         className="border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded ml-6"
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
      />
      </div>
    </div>

   

<div className='flex  text-white mt-32  w-[72px]  ml-auto'>
           <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
             <button
               type="submit"
               className='text-white   rounded'
            
             >
               Next
             </button>
             <BsArrowRight />
           </div>
         </div>
         <div className='flex justify-end mt-2'>
           <span>or <span className='text-blue-500'>Save Your progress?</span></span>
         </div>
  



    
   </form>
   <FormFotter />

</div>
</div>

  )
  }
  
  export default ApplicationInfoN565