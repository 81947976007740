import React from "react";
import Header from "../../components/Header";
import FormFotter from "../../components/FormFotter";
import Fotter from "../../components/Footer";
import { useState } from "react";
import { useEffect } from "react";
import PhoneInput from "react-phone-number-input/input";
import "react-phone-number-input/style.css"; // Import the library's CSS
import crosspic from "../../Assets/crosspic.png";
import Searchicon from "../../Assets/IconSearch.png";
import sucess from "../../Assets/tikpic.png";
import warn from "../../Assets/warning.png";
import help from "../../Assets/help.png";
import card from "../../Assets/cat.jpg";
import date from "../../Assets/datecard.jpg";
import { useFormContext } from "./Formcontext";

import stateData from "./ports";
import form from "../../Assets/icon.png";
import "./style.css";
import { useNavigate } from "react-router-dom";
const I905 = () => {
   const navigate=useNavigate()

  const {formData ,handleInputChange} =useFormContext();
      console.log(formData);

  const handleSubmit=(e)=>{
    e.preventDefault();
  }
  return (
    <div className="w-full">
      <Header />

      <div className="flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow">
        <div
          className="flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg"
          style={{ backgroundColor: "#0072bc" }}
        >
          <span className="ml-2 bg-white rounded-full px-2 text-green-500 font-thick form-security">
            verified and security
          </span>
          <span className="font-bold text-white text-xl formheaderfont">
            Application to Replace Permanent Resident Card
          </span>
          <div className="h-full flex justify-end">
            {" "}
            {/* Added 'flex' and 'justify-end' */}
            <button
              className="text-white px-2 bg-blue-900 h-full border border-black"
              style={{ textAlign: "left" }}
            >
              Save
            </button>
            <button className="text-white px-2 bg-blue-900 h-full">Help</button>
          </div>
        </div>
        <div
          className="progress-description pt-3 pt-lg-0"
          id="progress-description"
        >
          <h2>We just need some additional information about the preparer</h2>
        </div>
        <div className="flex flex-col w-full pb-10 bg-slate-100 bgc mx-4">
          <div className="flex justify-between items-center">
            <span className="mt-4 font-bold text-xl ml-2 text-black section">
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
              &nbsp;Applicant Contact Information
            </span>
          </div>
          <p id="P4_Applicant_Name-HH">
            Enter contact information so that USCIS can reach you. Your mobile
            phone number and/or email address will be used so that CitizenPath
            can create a complimentary Form G-1145 for you. By submitting G-1145
            with Form I-90, USCIS will text and/or email when they have accepted
            the application for processing.
          </p>
          <form onSubmit={handleSubmit} className="mt-6 w-full ">
            <div className="forms">
              <label htmlFor="Daytime">
                Daytime Phone Number
                <span className="text-red-500">*</span>
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;{" "}
              <input
                id="Daytime"
                name="Daytimephone"
                type="tel"
                value={formData.Daytimephone}
                onChange={handleInputChange}
                pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                className="p-2 mt-2 mb-2 rounded border"
                placeholder="(123) 456-7890"
                required="required"
              />
              <br />
              <label htmlFor="mobphone">
                Mobile Phone Number <i>(if any)</i>
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;
              <input
                id="mobphone"
                name="mobilephone"
                type="tel"
                value={formData.mobilephone}
                onChange={handleInputChange}
                pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                className="p-2 mt-2 mb-2 rounded border"
                placeholder="(123) 456-7890"
              />
              <br />
              <label htmlFor="mobphone">
                E-mail<i> (if any)</i>
              </label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;
              <input
                id="emails"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                className="p-2 mt-2 mb-2 rounded border "
                placeholder="user@example.com"
                style={{ width: "29%" }}
              />
            </div>
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <br />
            <div className="flex flex-col items-right space-y-0">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16 font-bold text-xl ">
                  {" "}
                  <b>Becoming a Permanent Resident</b>
                </h3>
              </span>
            </div>
            <br />
            <div className="text-white font-bold p-2 ndRendring text">
              Social Security Number :
            </div>
            <div className="form">
              <div className="flex flex-col space-y-5 ml-1 mt-0">
                <div className="flex items-center space-x-10 mt-3">
                  &nbsp;{" "}
                  <input
                    id="ss"
                    type="text"
                    name="ssnum"
                    value={formData.ssnum}
                    onChange={handleInputChange}
                    className="py-1 px-2 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    required
                    placeholder="#########"
                    maxLength={9}
                   
                  />
                  &nbsp;
                
                </div>
                <div
                  style={{
                    backgroundColor: "#E7E7E7",
                    color: "#888",
                    padding: "0.5%",
                  }}
                >
                  If you do not have a Social Security Number, leave the field
                  empty. Using a false SSN may result in your application being
                  denied. <u>How to find this >></u>
                </div>
              </div>
            </div>
            <br />
            <div className="text-white font-bold p-2 ndRendring text">
              USCIS Online Account Number <i>(if any)</i>{" "}
            </div>
            <div className="form">
              <div className="flex flex-col space-y-5 ml-1 mt-0">
                <div className="flex items-center space-x-10 mt-3">
                  &nbsp;{" "}
                  <input
                    id="acc"
                    type="text"
                    name="accnum"
                    value={formData.accnum}
                    onChange={handleInputChange}
                    className="py-1 px-2 rounded border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    required
                    placeholder="#########"
                    maxLength={12}
                   
                  />
                  &nbsp;
                 
                </div>
                <div
                  style={{
                    backgroundColor: "#E7E7E7",
                    color: "#888",
                    padding: "0.5%",
                  }}
                >
                  The USCIS Online Account Number is not the same as an
                  A-Number. If you do not have a USCIS Online Account Number or
                  do not know, you may leave this blank.{" "}
                  <u>How to find this >></u>
                </div>
              </div>
            </div>
            <br />
            <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
            <br />
            <div className="flex flex-col items-right space-y-0">
              <span className="flex items-right space-x-10">
                <h3 className="heading33 ml-16 font-bold text-xl ">
                  {" "}
                  <b>Interpreter</b>
                </h3>
              </span>
            </div>
            <div className="additional-div">
              <div className=" text-white font-bold p-2 ndRendring text">
                Did you receive help from an interpreter to prepare this
                application?
                <span className="text-red-500">*</span>
              </div>
              <div className="form p-3">
                <input
                  type="radio"
                  name="help"
                  value="yes"
                  id="inUSYess"
                  checked={formData.help === "yes"}
                  onChange={handleInputChange}
                />
                <label htmlFor="inUSYess">Yes</label> &nbsp; &nbsp; &nbsp;
                <input
                  type="radio"
                  name="help"
                  value="no"
                  id="inUSNos"
                  checked={formData.help === "no"}
                  onChange={handleInputChange}
                />
                <label htmlFor="inUSNos">No</label>
              </div>
              <div className="forms flex items-center">
                <label>
                  <input
                    type="checkbox"
                    name="ischecked"
                    required="required"
                    checked={formData.ischecked}
                    onChange={handleInputChange}
                  />
                  &nbsp; An interpreter read every question and instruction on
                  this application to me, and I answered every question in a
                  language in which I am fluent. I understood every question and
                  instruction on this application as translated to me by my
                  interpreter.
                  <span className="text-red-500">*</span>
                </label>
              </div>
              {!formData.ischecked && (
                <span className="text-red-500 " style={{ marginLeft: "30%" }}>
                  This field is required.
                </span>
              )}
              {formData.help === "yes" ? (
                <div className="flex flex-col">
                  <div className="flex justify-between items-center mb-0">
                    <span className="text-xl ml-0 text-black section2">
                      <h3 className="heading3">Interpreter's Information</h3>
                    </span>
                  </div>
                  <div className="forms">
                    <label htmlFor="Language">
                      Which language was used to translate into English?
                      <span className="text-red-500">*</span>
                    </label>
                    &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="language"
                      placeholder="Enter language"
                      value={formData.language}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="forms">
                    <label htmlFor="FirstName">
                      Interpreter's First Name (Given Name) :
                      <span className="text-red-500">*</span>
                    </label>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="FirstName"
                      placeholder="Enter first name"
                      value={formData.FirstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="forms">
                    <label htmlFor="LastName">
                      Interpreter's Last Name (Family Name)
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="LastName"
                      placeholder="Enter last name"
                      value={formData.LastName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="forms">
                    <label htmlFor="BusinessName">
                      Interpreter's Business or Organization Name
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="BusinessName"
                      placeholder="Enter business or organization name"
                      value={formData.BusinessName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-0">
                      <span className="text-xl ml-0 text-black section2">
                        <h3 className="heading3">Mailing Address</h3>
                      </span>
                    </div>
                    <p id="P4_Applicant_Name-HH1">
                      You may list a valid residence, APO, C/O, or commercial
                      address. This may be a Post Office address (P.O. Box) if
                      that is how you receive your mail. If your mail will be
                      sent to someone other than yourself, please include an “In
                      Care of Name” as a part of your mailing address. If your
                      mailing address is a U.S. territory and it contains an
                      urbanization name, list the urbanization name in the "In
                      Care of Name" box.
                    </p>

                    <div className="forms">
                      <label htmlFor="streetNamee">
                        Street Number and Name:{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp;
                      <input
                        id="streetNamee"
                        type="text"
                        name="streetnum"
                        maxLength={25}
                        value={formData.streetnum}
                        onChange={handleInputChange}
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                        
                      />
                      &nbsp; &nbsp;
                     
                      <br /> <br />
                      <label htmlFor="appartment">
                        Is there an apartment, suite or floor?
                        <span className="text-red-500">*</span> &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </label>
                      <select
                        id="appartment"
                        value={formData.appartmentmail}
                        onChange={handleInputChange}
                        name="appartmentmail"
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <option value="no">No</option>
                        <option value="Apt">Apt</option>
                        <option value="Ste">Ste</option>
                        <option value="Flr">Flr</option>
                      </select>
                      <br />
                      {formData.appartmentmail !== "no" ? (
                        <div className="num-input-wrapper">
                          <br />
                          <label htmlFor="numb">
                            Enter the number here:{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                          <input
                            id="numb"
                            type="text"
                            maxLength={5}
                            value={formData.numb}
                            onChange={handleInputChange}
                            name="numb"
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                              width: "10%",
                            }}
                           
                          />
                         
                        </div>
                      ) : null}
                      <br />
                      <label htmlFor="cityy">
                        City or Town: <span className="text-red-500">*</span>
                      </label>
                      &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        id="cityy"
                        type="text"
                        name="Cites"
                        maxLength={20}
                        value={formData.Cites}
                        onChange={handleInputChange}
                      
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      &nbsp;
                     
                      <br /> <br />
                      <label htmlFor="countSelects">
                        Country: <span className="text-red-500"> *</span>
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <select
                        name="Country"
                        id="countSelects"
                        value={formData.Country}
                        onChange={handleInputChange}
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <option value="US">United States</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Canada">Canada</option>
                      </select>
                      <br />
                      <br />
                      {formData.Country === "US" ? (
                        <div>
                          <label htmlFor="state">
                            State <span className="text-red-500"> *</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select
                            id="cityy"
                            type="text"
                            name="State"
                            value={formData.State}
                            onChange={handleInputChange}
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            <option value="PS">Please Select ...</option>

                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                          </select>
                          &nbsp;
                          <br /> <br />
                        </div>
                      ) : null}
                      {formData.Country === "Mexico" ||
                      formData.Country === "Canada" ? (
                        <div>
                          <label htmlFor="Province">
                            {" "}
                            Province <span className="text-red-500"> *</span>
                          </label>{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="text"
                            id="Province"
                            type="text"
                            name="province"
                            value={formData.province}
                            onChange={handleInputChange}
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      ) : null}
                      <label htmlFor="postcode">
                        Postal Code: <span className="text-red-500">*</span>
                      </label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;
                      <input
                        id="postcode"
                        type="text"
                        name="postcode"
                        maxLength={9}
                        value={formData.postcode}
                        onChange={handleInputChange}
                       
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      &nbsp;
                     
                    </div>
                  </div>{" "}
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-0">
                      <span className="text-xl ml-0 text-black section2">
                        <h3 className="heading3">Interpreter's Information</h3>

                        <div className="forms">
                          <label htmlFor="Daytime2">
                            Daytime Phone Number :
                            <span className="text-red-500">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                          &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp;
                          <input
                            id="Daytime2"
                            name="InterpreterNumber"
                            type="tel"
                            value={formData.InterpreterNumber}
                            onChange={handleInputChange}
                            pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                            className="p-2 mt-2 mb-2 rounded border"
                            placeholder="(123) 456-7890"
                            required="required"
                          />
                          <br />
                          <label htmlFor="mobphone1">
                            Mobile Phone Number <i>(if any):</i>
                          </label>
                          &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                          &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          <input
                            id="mobphone1"
                            name="InterpreterNumber2"
                            type="tel"
                            value={formData.InterpreterNumber2}
                            onChange={handleInputChange}
                            pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                            className="p-2 mt-2 mb-2 rounded border"
                            placeholder="(123) 456-7890"
                          />
                          <br />
                          <label htmlFor="emailss">
                            E-mail<i> (if any)</i>
                          </label>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                          &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                          &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          <input
                            id="emailss"
                            name="Interpreteremail"
                            type="email"
                            value={formData.Interpreteremail}
                            onChange={handleInputChange}
                            className="p-2 mt-2 mb-2 rounded border "
                            placeholder="user@example.com"
                            style={{ width: "29%" }}
                          />
                        </div>
                      </span>
                    </div>
                    {/* ---- */}
                  </div>
                </div>
              ) : null}
              <br />
              <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
              <br />
              <div className="flex flex-col items-right space-y-0">
                <span className="flex items-right space-x-10">
                  <h3 className="heading33 ml-16 font-bold text-xl ">
                    {" "}
                    <b>Becoming a Permanent Resident</b>
                  </h3>
                </span>
              </div>
              <br />
              <div className="mt-2 font-thin ml-2 text ndRendring">
                Who prepared this application?
                <span className="text-red-500">*</span>
              </div>
              <div className="form">
                <input
                  type="radio"
                  name="Whoprepared"
                  value="Iam"
                  id="lostRadio"
                  onChange={handleInputChange}
                  checked={formData.Whoprepared === "Iam"}
                />
                <label htmlFor="lostRadio">
                  I am the applicant, and I prepared this application.
                </label>
                <hr />
                <input
                  type="radio"
                  name="Whoprepared"
                  value="iprepare"
                  id="notReceivedRadio"
                  onChange={handleInputChange}
                  checked={formData.Whoprepared === "iprepare"}
                />
                <label htmlFor="notReceivedRadio">
                  I am not an attorney or accredited representative but have
                  prepared this application on behalf of the applicant and with
                  the applicant's consent.
                </label>
                <hr />
                <input
                  type="radio"
                  name="Whoprepared"
                  value="im_not"
                  id="mutilatedRadio"
                  onChange={handleInputChange}
                  checked={formData.Whoprepared === "im_not"}
                />
                <label htmlFor="mutilatedRadio">
                  I am an attorney or accredited representative of the applicant
                  that prepared this application.
                </label>
                <hr />

                <hr />
              </div>
              <br />
              {formData.Whoprepared === "im_not" ? (
                <div className="div">
                  <div className="mt-2 font-thin ml-2 text ndRendring">
                    My representation of the applicant in this case:
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="form">
                    <input
                      type="radio"
                      name="representation"
                      value="Extends"
                      id="lostRadioo"
                      onChange={handleInputChange}
                      checked={formData.representation === "Extends"}
                    />
                    <label htmlFor="lostRadioo">
                      Extends beyond the preparation of this application.
                    </label>
                    <hr />
                    <input
                      type="radio"
                      name="representation"
                      value="notExtends"
                      id="notReceivedRadioo"
                      onChange={handleInputChange}
                      checked={formData.representation === "notExtends"}
                    />
                    <label htmlFor="notReceivedRadioo">
                      Does not extend beyond the preparation of this
                      application.
                    </label>
                    <hr />
                  </div>
                </div>
              ) : null}
              {formData.Whoprepared === "iprepare" ||
              formData.representation === "Extends" ||
              formData.representation === "notExtends" ? (
                <div className="flex flex-col">
                  <div className="flex justify-between items-center mb-0">
                    <span className="text-xl ml-0 text-black section2">
                      <h3 className="heading3">Preparer's Information</h3>
                    </span>
                  </div>
                  <div className="forms">
                    <label htmlFor="PrepFirstName">
                      Preparer's First Name (Given Name) :
                      <span className="text-red-500">*</span>
                    </label>
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      id="p"
                      type="text"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="PrepFirstName"
                      placeholder="Enter first name"
                      value={formData.PrepFirstName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="forms">
                    <label htmlFor="pLastName">
                      Preparer's Last Name (Family Name)
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <input
                      type="text"
                      id="pLastName"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="PrepLastName"
                      placeholder="Enter last name"
                      value={formData.PrepLastName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="forms">
                    <label htmlFor="PrepBusinessName">
                      Preparer's Business or Organization Name
                      <span className="text-red-500">*</span>
                    </label>{" "}
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp;
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                      required
                      name="PrepBusinessName"
                      placeholder="Enter business or organization name"
                      value={formData.PrepBusinessName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <br />
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-0">
                      <span className="text-xl ml-0 text-black section2">
                        <h3 className="heading3">Preparer's Mailing Address</h3>
                      </span>
                    </div>
                    <div className="forms">
                      <label htmlFor="pstreetNamee">
                        Street Number and Name:{" "}
                        <span className="text-red-500">*</span>
                      </label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                      &nbsp;&nbsp; &nbsp;
                      <input
                        id="pstreetNamee"
                        type="text"
                        name="Prepstreetnum"
                        maxLength={25}
                        value={formData.Prepstreetnum}
                        onChange={handleInputChange}
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      
                      />
                      &nbsp; &nbsp;
                      
                      <br /> <br />
                      <label htmlFor="appartment">
                        Is there an apartment, suite or floor?
                        <span className="text-red-500">*</span> &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      </label>
                      <select
                        id="appartment"
                        value={formData.Prepapartment}
                        onChange={handleInputChange}
                        name="Prepapartment"
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <option value="no">No</option>
                        <option value="Apt">Apt</option>
                        <option value="Ste">Ste</option>
                        <option value="Flr">Flr</option>
                      </select>
                      <br />
                      {formData.Prepapartment !== "no" ? (
                        <div className="num-input-wrapper">
                          <br />
                          <label htmlFor="numb1">
                            Enter the number here:{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                          <input
                            id="numb1"
                            type="text"
                            maxLength={5}
                            value={formData.PrepNum}
                            onChange={handleInputChange}
                            name="PrepNum"
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                              width: "10%",
                            }}
                           
                          />
                          
                        </div>
                      ) : null}
                      <br />
                      <label htmlFor="cityy1">
                        City or Town: <span className="text-red-500">*</span>
                      </label>
                      &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input
                        id="cityy1"
                        type="text"
                        name="PrepCites"
                        maxLength={20}
                        value={formData.PrepCites}
                        onChange={handleInputChange}
                      
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      />
                      &nbsp;
                     
                      <br /> <br />
                      <label htmlFor="countSelectss">
                        Country: <span className="text-red-500"> *</span>
                      </label>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <select
                        name="PrepCountry"
                        id="countSelectss"
                        value={formData.PrepCountry}
                        onChange={handleInputChange}
                        style={{
                          padding: "5px",
                          marginTop: "5px",
                          marginBottom: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        <option value="US">United States</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Canada">Canada</option>
                      </select>
                      <br />
                      <br />
                      {formData.PrepCountry === "US" ? (
                        <div>
                          <label htmlFor="states">
                            State <span className="text-red-500"> *</span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select
                            id="states"
                            type="text"
                            name="PrepState"
                            value={formData.PrepState}
                            onChange={handleInputChange}
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            <option value="PS">Please Select ...</option>

                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                          </select>
                          &nbsp;
                          <br /> <br />
                          <label htmlFor="Zip">
                            ZIP Code : <span className="text-red-500">* </span>{" "}
                          </label>{" "}
                          &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          <input
                            id="Zip"
                            type="text"
                            name="PrepZip"
                            maxLength={5}
                            value={formData.PrepZip}
                            onChange={handleInputChange}
                            
                            placeholder="####"
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                              width: "10%",
                            }}
                          />
                          &nbsp;{" "}
                         
                        </div>
                      ) : null}
                      {formData.PrepCountry === "Mexico" ||
                      formData.PrepCountry === "Canada" ? (
                        <div>
                          <label htmlFor="Provinces">
                            {" "}
                            Province <span className="text-red-500">*</span>
                          </label>{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="text"
                            id="Provinces"
                            type="text"
                            name="Prepprovince"
                            value={formData.Prepprovince}
                            onChange={handleInputChange}
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                          />
                          <br /> <br />
                          <label htmlFor="postcodess">
                            Postal Code: <span className="text-red-500">*</span>
                          </label>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp;
                          <input
                            id="postcodess"
                            type="text"
                            name="Preppostcode"
                            maxLength={9}
                            value={formData.Preppostcode}
                            onChange={handleInputChange}
                            
                            style={{
                              padding: "5px",
                              marginTop: "5px",
                              marginBottom: "5px",
                              borderRadius: "5px",
                            }}
                          />
                          &nbsp;
                         
                        </div>
                      ) : null}
                    </div>
                  </div>{" "}
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center mb-0">
                      <span className="text-xl ml-0 text-black section2">
                        <h3 className="heading3">
                          Preparer's Contact Information
                        </h3>
                      </span>
                    </div>

                    <div className="forms">
                      <label htmlFor="Daytime22">
                        Daytime Phone Number :
                        <span className="text-red-500">*</span>
                      </label>
                      &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;
                      <input
                        id="Daytime22"
                        name="PrepNumb1"
                        type="tel"
                        value={formData.PrepNumb1}
                        onChange={handleInputChange}
                        pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                        className="p-2 mt-2 mb-2 rounded border"
                        placeholder="(123) 456-7890"
                        required="required"
                      />
                      <br />
                      <label htmlFor="mobphone11">
                        Mobile Phone Number <i>(if any):</i>
                      </label>
                      &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                      &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <input
                        id="mobphone11"
                        name="PrepNumb2"
                        type="tel"
                        value={formData.PrepNumb2}
                        onChange={handleInputChange}
                        pattern="^(?:\+?1[-. ]?)?\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$"
                        className="p-2 mt-2 mb-2 rounded border"
                        placeholder="(123) 456-7890"
                      />
                      <br />
                      <label htmlFor="emailsss">
                        E-mail<i> (if any)</i>
                      </label>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
                      &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                      &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp; &nbsp;
                      <input
                        id="emailsss"
                        name="prepemail"
                        type="email"
                        value={formData.prepemail}
                        onChange={handleInputChange}
                        className="p-2 mt-2 mb-2 rounded border "
                        placeholder="user@example.com"
                        style={{ width: "29%" }}
                      />
                    </div>

                    {/* ---- */}
                  </div>
                </div>
              ) : null}
              <br />
              <hr style={{ backgroundColor: "#0072bc", height: "3px" }} />
              <br />
              <div className="flex flex-col items-right space-y-0">
                <span className="flex items-right space-x-10">
                  <h3 className="heading33 ml-16">
                    <b>
                      Accommodations for Individuals With Disabilities and
                      Impairments
                    </b>
                  </h3>
                </span>
                <div className="text-left">
                  <p className="ptag ml-16">
                    If you require more space than available here, please
                    provide the information on a separate sheet of paper and
                    attach it to your application. Write your A-Number at the
                    top of each sheet.
                  </p>
                </div>
              </div>
              <div className="additional-div">
                <div className="text-white font-bold p-2 ndRendring text">
                  Are you requesting an accommodation because of a disability
                  and/or impairment?
                  <span className="text-red-500">*</span>
                </div>
                <div className="form">
                  <input
                    type="radio"
                    name="requesting"
                    value="Yes" // Set the value to "Yes"
                    id="nn"
                    checked={formData.requesting === "Yes"} // Check if interview is "Yes"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="nn">Yes</label> &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="requesting"
                    value="No" // Set the value to "No"
                    id="nnq"
                    checked={formData.requesting === "No"} // Check if interview is "No"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="nnq">No</label>
                  <div style={{ backgroundColor: "#E7E7E7", color: "#888" }}>
                    USCIS is committed to providing reasonable accommodations
                    for qualified individuals with disabilities and impairments.
                    Explain more>>
                  </div>
                </div>
              </div>
              <br />
              <br />
              {formData.requesting === "Yes" ? (
                <div className="flex flex-col items-right space-y-0">
                  <span className="flex items-right space-x-10">
                    <h3 className="heading33 ml-16">
                      Please check any applicable boxes and provide an
                      explanation:
                    </h3>
                  </span>
                  <div className="text-left">
                    <p className="ptag ml-16">
                      If you require more space than available here, please
                      provide the information on a separate sheet of paper and
                      attach it to your application. Write your A-Number at the
                      top of each sheet.
                    </p>
                  </div>

                  <br />
                  <div className="forms flex items-center">
                    <label>
                      <input
                        id="df"
                        type="checkbox"
                        name="deaf"
                        checked={formData.deaf}
                        onChange={handleInputChange}
                      />
                      <b
                        color="black"
                        className="font-black-100"
                        style={{ color: "black" }}
                        htmlFor="df"
                      >
                        &nbsp; I am deaf or hard of hearing and request the
                        following accommodation:
                      </b>
                    </label>
                  </div>
                  {formData.deaf && (
                    <div>
                      <div className="mt-2 font-thin ml-2 text ndRendring">
                      If requesting a sign-language interpreter, indicate for which language (Example: American Sign Language)
                        <span className="text-red-500">*</span>
                      </div>
                        <div className="form p-2">
                        &nbsp;   &nbsp;  &nbsp;  &nbsp; <textarea
                            id="APP_Deaf_Desc"
                            name="deafreason"
                            class="form-control"
                            placeholder=""
                            maxlength="120"
                            aria-required="true"
                            required="required"
                            data-gramm="false"
                            wt-ignore-input="true"
                            onChange={handleInputChange}
                            value={formData.deafreason}

                            data-quillbot-element="MrV3DUViIF7ekwJOkk5xd"
                            style={{width:"90%",height:"50px"}}
                          ></textarea>
                        </div>
                    </div>
                  )}
                  <br />                  <br />
                  <div className="forms flex items-center">
                    <label>
                      <input
                        id="df"
                        type="checkbox"
                        name="blind"
                        checked={formData.blind}
                        onChange={handleInputChange}
                      />
                      <b
                        color="black"
                        className="font-black-100"
                        style={{ color: "black" }}
                        htmlFor="df"
                      >
                        &nbsp; I am blind or have low vision and request the
                        following accommodation:
                      </b>
                    </label>
                  </div>
                  {formData.blind && (
                    <div>
                     
                        <div className="form p-2">
                        &nbsp;   &nbsp;  &nbsp;  &nbsp; <textarea
                            id="APP_Deaf_Desc"
                            name="blindreason"
                            class="form-control"
                            placeholder=""
                            maxlength="120"
                            aria-required="true"
                            required="required"
                            data-gramm="false"
                            wt-ignore-input="true"
                            onChange={handleInputChange}
                            value={formData.blindreason}


                            data-quillbot-element="MrV3DUViIF7ekwJOkk5xd"
                            style={{width:"90%",height:"50px"}}
                          ></textarea>
                        </div>
                    </div>
                  )}
                  <br />                  <br />

                  <div className="forms flex items-center">
                    <label>
                      <input
                        id="df"
                        type="checkbox"
                        name="disability"
                        checked={formData.disability}
                        onChange={handleInputChange}
                      />
                      <b
                        color="black"
                        className="font-black-100"
                        style={{ color: "black" }}
                        htmlFor="df"
                      >
                        &nbsp; I have another type of disability and / or
                        impairment:
                      </b>
                    </label>
                  </div>
                  {formData.disability && (
                    <div>
                      <div className="mt-2 font-thin ml-2 text ndRendring">
                      Describe the nature of the disability and/or impairment and accommodation you are requesting
                        <span className="text-red-500">*</span>
                      </div>
                        <div className="form p-2">
                        &nbsp;   &nbsp;  &nbsp;  &nbsp; <textarea
                            id="APP_Deaf_Desc"
                            name="disabilityreason"
                            class="form-control"
                            placeholder=""
                            maxlength="120"
                            aria-required="true"
                            required="required"
                            data-gramm="false"
                            onChange={handleInputChange}
                            value={formData.disabilityreason}

                            wt-ignore-input="true"
                            data-quillbot-element="MrV3DUViIF7ekwJOkk5xd"
                            style={{width:"90%",height:"50px"}}
                          ></textarea>
                        </div>
                    </div>
                  )}
                </div>
              ) : null}{" "}
            </div>
            {/* ----------------- */}
            &nbsp;
            <div className="w-full ml-8">
              <button
                type="button"
                className="float-left previous text-white px-4 py-2 rounded mt-4"
                style={{ background: "rgb(187, 187, 187)" }}
              >
                ← PREV
              </button>
              <br />
              <button
                type="submit"
                className="float-right next bg-green-500 text-white px-4 py-2 rounded mr-20  "
                onClick={()=>navigate()}
              >
                NEXT →
              </button>
            </div>
          </form>
        </div>
        <FormFotter />
      </div>
    </div>
  );
};
export default I905;
