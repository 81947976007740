
import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { ContextFor1762 } from '../../UseContext/ContextFor1762';
import { useContext } from 'react';
import crosspic from "../../Assets/crosspic.png"
import { Options } from "./Options.js"
import { BsArrowRight } from 'react-icons/bs';
import { BiCrosshair, BiNoEntry } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';




const DashedLineWithText = () => {
  return (
    <div className="dashed-line text-center my-4 relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-dashed border-gray-200"></div>
      </div>
      <span className="relative bg-white px-2 text-gray-200 font-bold ">Insert any another name</span>
    </div>
  );
};





const ApplicantInformation = () => {


  const navigate = useNavigate();

  const { givenName, updateGivenName,
    middleName, updateMiddleName,
    familyName, updateFamilyName,
    haveUsedOtherNames, updateHaveUsedOtherNames,
    nameSets, handleAddAnother, handleRemove,
    setNameSets, cityOrTown,
    updateCityOrTown,
    provinceOrState,
    updateProvinceOrState,
    dateOfBirth,
    updateDateOfBirth,
    countries,
    selectedCountry,
    updateSelectedCountry,
    sex, updateSex,
    maritalStatus,
    updateMaritalStatus,
    uscisOnlineAccountNumber,
    updateUscisOnlineAccountNumber,
    aNumber, updateANumber,
    ssnIssued,
    updateSsnIssued,
    ssn,
    updateSsn,
    unknownSsn,
    updateUnknownSsn,
    replaceSsnCard,
    updateReplaceSsnCard,
    authorizeDisclosureToSSA,
    updateAuthorizeDisclosureToSSA,
    fathersGivenName,
    updateFathersGivenName,
    fathersFamilyName,
    updateFathersFamilyName,
    mothersGivenName,
    updateMothersGivenName,
    mothersFamilyName,
    updateMothersFamilyName,
    
  } = useContext(ContextFor1762);






  const handleGivenNameChange = (e) => {
    updateGivenName(e.target.value);
  };

  const handleMiddleNameChange = (e) => {
    updateMiddleName(e.target.value);
  };

  const handleFamilyNameChange = (e) => {
    updateFamilyName(e.target.value);
  };


  const handleHaveUsedOtherNamesChange = (value) => {
    updateHaveUsedOtherNames(value);
  };
  const handleChange = (e, id, field) => {


    const updatedNameSets = [...nameSets];


    const index = updatedNameSets.findIndex((nameSet) => nameSet.id === id);

    if (index !== -1) {

      updatedNameSets[index] = {
        ...updatedNameSets[index],
        [field]: e.target.value,
      };


      setNameSets(updatedNameSets);
    }
  };

  const handleCountryChange = (e) => {
    const selectedValue = e.target.value;

    updateSelectedCountry(selectedValue);
  };

  const handleSexChange = (e) => {
    updateSex(e.target.value);
  };
  const handleMaritalStatusChange = (e) => {
    updateMaritalStatus(e.target.value);
  };

  const handlAnumbereChange = (e) => {

    updateANumber(e.target.value);
  };

  const handleSsnIssuedChange = (e) => {
    updateSsnIssued(e.target.value);
  };

  const handleSsnChange = (e) => {
    updateSsn(e.target.value);
  };

  const handlFormSubmit = (e) => {
    e.preventDefault();
    console.log('givenName:', givenName);
    console.log('middleName:', middleName);
    console.log('familyName:', familyName);
    console.log('haveUsedOtherNames:', haveUsedOtherNames);
    console.log('nameSets:', nameSets);
    console.log('cityOrTown:', cityOrTown);
    console.log('provinceOrState:', provinceOrState);
    console.log('dateOfBirth:', dateOfBirth);
    //  console.log('countries:', countries);
    console.log('selectedCountry:', selectedCountry);
    console.log('sex:', sex);
    console.log('maritalStatus:', maritalStatus);
    console.log('uscisOnlineAccountNumber:', uscisOnlineAccountNumber);
    console.log('aNumber:', aNumber);
    console.log('ssnIssued:', ssnIssued);
    console.log('ssn:', ssn);
    console.log('unknownSsn:', unknownSsn);
    console.log('replaceSsnCard:', replaceSsnCard);
    console.log('authorizeDisclosureToSSA:', authorizeDisclosureToSSA);
    console.log('fathersGivenName:', fathersGivenName);
    console.log('fathersFamilyName:', fathersFamilyName);
    console.log('mothersGivenName:', mothersGivenName);
    console.log('mothersFamilyName:', mothersFamilyName);
    // ... log other values
    navigate("/ImmigranteHistory")
  }


  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
        <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>NowApplication For Employment Authorization</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>

        <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
          <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
            <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Applicant Information
            </span>
            <div className='flex justify-between items-center gap-4'>
              <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                <button className='mr-2'>Page 1 of 3</button>
                <button><MdArrowDropDown /></button>
              </div>
              <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                <AiOutlineShoppingCart />
              </div>
            </div>
          </div>
          <span className='mt-2 font-thin ml-2'>
            Let's get started with some information about you, the applicant.
          </span>
        </div>


        <form action=" " className='mt-4 w-11/12   ' onSubmit={handlFormSubmit}>

          <div>
            <h1 className='text-blue-700 font-bold text-xl'>Applicant's Full Name</h1>
            <p
              className='font-thin text-sm'
              style={{
                color: 'rgba(60, 60, 60, 0.7)',
                fontFamily:
                  '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              }}
            >
              Provide your full legal name. Spell out middle names completely. If you do not have a middle name, you may leave this field blank. <span className='text-blue-700'>Explain more >></span>
            </p>

            <div className="mb-4 flex mt-6">
              <label htmlFor="givenName" className="text-blue-700">
                Given Name (First Name): <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="givenName"
                name="givenName"
                value={givenName}
                onChange={handleGivenNameChange}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-10'

              />
            </div>

            {/* Middle Name(s) */}
            <div className="mb-4 flex gap-2">
              <label htmlFor="middleName" className="text-blue-700">
                Middle Name(s): <span className="text-red-500">*</span>
              </label>
              <input

                type="text"
                id="middleName"
                name="middleName"
                value={middleName}
                onChange={handleMiddleNameChange}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg  ml-24 '
              />
            </div>

            {/* Family Name (Last Name) */}
            <div className="mb-4 flex gap-1">
              <label htmlFor="familyName" className="text-blue-500">
                Family Name (Last Name): <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="familyName"
                name="familyName"
                value={familyName}
                onChange={handleFamilyNameChange}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg   ml-8'

              />
            </div>
          </div>
          <hr className="h-1 bg-blue-500  mt-20" />

          <div>
            {/* Your existing code */}
            <div className='p-4 rounded text-white bg-blue-700'>
              Have you used any other names since birth?
              <span className="text-red-500">*</span>
            </div>
            <div className='flex gap-10  bg-blue-100 p-5'>
              <div className='flex gap-2'>
                <input
                  type="radio"
                  id="haveUsedOtherNamesYes"
                  name="haveUsedOtherNames"
                  value="yes"
                  checked={haveUsedOtherNames === 'yes'}
                  onChange={() => handleHaveUsedOtherNamesChange('yes')}
                />
                <label htmlFor="haveUsedOtherNamesYes">Yes</label>
              </div>
              <div className='flex gap-2'>
                <input
                  type="radio"
                  id="haveUsedOtherNamesNo"
                  name="haveUsedOtherNames"
                  value="no"
                  checked={haveUsedOtherNames === 'no'}
                  onChange={() => handleHaveUsedOtherNamesChange('no')}
                />
                <label htmlFor="haveUsedOtherNamesNo">No</label>
              </div>
            </div>
          </div>
          <p className='bg-slate-200 '> <span className='ml-2'>Indicate if you've ever been known by another name, alias or nick name. </span></p>





          {haveUsedOtherNames === 'yes' &&


            <><DashedLineWithText />
              <div className="mt-10">
                {nameSets.map((nameSet, index) => (
                  <div key={nameSet.id} className='bg-slate-50 rounded border border-blue-200 mt-10'>
                    {index > 0 && (
                      <div className='flex w-full justify-between ml-2'>
                        <span className="text-gray-500">{`Add Other ${index + 1}`}</span>
                        <button
                          type="button"
                          onClick={() => handleRemove(nameSet.id)}
                          className=" flex  items-center bg-slate-200 px-4 py-2 rounded mt-2 mr-4 text-red-600 font-semibold"
                        >
                          Remove
                          <img src={crosspic} alt="" />
                        </button>
                      </div>
                    )}

                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`givenName-${nameSet.id}`} className="text-blue-700">
                        Given Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`givenName-${nameSet.id}`}
                        name={`givenName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        value={nameSet.givenName || ''}
                        onChange={(e) => handleChange(e, nameSet.id, 'givenName')}
                      />
                    </div>

                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`middleName-${nameSet.id}`} className="text-blue-700">
                        Middle Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`middleName-${nameSet.id}`}
                        name={`middleName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                        value={nameSet.middleName || ''}
                        onChange={(e) => handleChange(e, nameSet.id, 'middleName')}
                      />
                    </div>

                    <div className="mb-4 flex mt-6 ml-2">
                      <label htmlFor={`familyName-${nameSet.id}`} className="text-blue-700">
                        Family  Name (First Name): <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id={`familyName-${nameSet.id}`}
                        name={`familyName-${nameSet.id}`}
                        className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-11'
                        value={nameSet.familyName || ''}
                        onChange={(e) => handleChange(e, nameSet.id, 'familyName')}
                      />
                    </div>

                    {/* ... Other input fields ... */}

                  </div>
                ))}

                {nameSets.length === 0 && (
                  <div>
                    <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
                    <span className="text-gray-500">Add Other</span>
                  </div>
                )}

                <button
                  type="button"
                  onClick={handleAddAnother}
                  className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
                >
                  Add Another Name
                </button>
              </div>
            </>


          }

          <hr className="h-1 bg-blue-500  mt-10" />

          <div>
            <div className="mb-4 flex ml-2 gap-10">
              <label htmlFor="cityOrTown" className="text-blue-700">
                City or Town:
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="cityOrTown"
                name="cityOrTown"
                value={cityOrTown}
                onChange={(e) => updateCityOrTown(e.target.value)}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-20'
              />
            </div>

            <div className="mb-4 flex ml-2 gap-10">
              <label htmlFor="provinceOrState" className="text-blue-700">
                Province or State:
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="provinceOrState"
                name="provinceOrState"
                value={provinceOrState}
                onChange={(e) => updateProvinceOrState(e.target.value)}
                className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-12'
              />
            </div>

            <div className="mb-4 flex ml-2 gap-10">
              <label htmlFor="dateOfBirth" className="text-blue-700">
                Date of Birth:
                <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                value={dateOfBirth}
                onChange={(e) => updateDateOfBirth(e.target.value)}
                className='border border-gray-300 hover:border-2  focus:border-2  hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none pl-2 pr-2 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-20 '
                style={{ width: 'calc(100% - 73%)' }}
              />
            </div>
          </div>
          <div className='flex  gap-20'>
            <label htmlFor="country" className='text-blue-700'>Country:<span className="text-red-500">*</span></label>
            <div>
              <label htmlFor="country" className='text-blue-700'>Country:<span className="text-red-500">*</span></label>
              <select
                id="country"
                name="country"
                value={selectedCountry}
                onChange={handleCountryChange}
                className='border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-20 pr-4'
                style={{ width: 'calc(100% - 73%)' }}  // Adjust the width here
              >
                <option value="" disabled>Select a country</option>
                {countries.map((country, index) => (
                  <option key={index} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <hr className='h-1 bg-blue-700 w-full mt-10' />

          <h1 className=' text-blue-500 font-semibold mt-10 text-2xl'>Other Information</h1>

          <div className="mb-4 flex  flex-col ml-2  mt-10">
            <div className="p-4 rounded text-white bg-blue-700">
              Sex
              <span className="text-red-500">*</span>
            </div>

            <div className="flex items-center gap-4 bg-blue-100 p-4">
              <label className="flex gap-2 text-blue-500">
                <input
                  type="radio"
                  id="male"
                  name="sex"
                  value="Male"
                  checked={sex === 'Male'}
                  onChange={handleSexChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Male
              </label>
              <label className="flex gap-2 text-blue-500">
                <input
                  type="radio"
                  id="female"
                  name="sex"
                  value="Female"
                  checked={sex === 'Female'}
                  onChange={handleSexChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Female
              </label>
            </div>
          </div>


          <div className="mb-4 flex flex-col ml-2 mt-10">
            <div className="p-4 rounded text-white bg-blue-700">
              Marital Status
              <span className="text-red-500">*</span>
            </div>
            <div className="flex flex-col  gap-4 bg-blue-100 p-4">
              <label className="flex gap-2 text-blue-500">
                <input
                  type="radio"
                  id="single"
                  name="maritalStatus"
                  value="Single"
                  checked={maritalStatus === 'Single'}
                  onChange={handleMaritalStatusChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Single
              </label>
              <hr className="h-0.5 bg-blue-200  " />
              <label className="flex gap-2 text-blue-500">
                <input
                  type="radio"
                  id="married"
                  name="maritalStatus"
                  value="Married"
                  checked={maritalStatus === 'Married'}
                  onChange={handleMaritalStatusChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Married
              </label>
              <hr className="h-0.5 bg-blue-200 " />
              <label className="flex gap-2 text-blue-500">
                <input
                  type="radio"
                  id="divorced"
                  name="maritalStatus"
                  value="Divorced"
                  checked={maritalStatus === 'Divorced'}
                  onChange={handleMaritalStatusChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Divorced
              </label>
              <hr className="h-0.5 bg-blue-200 " />
              <label className="flex gap-2 text-blue-500">
                <input
                  type="radio"
                  id="widowed"
                  name="maritalStatus"
                  value="Widowed"
                  checked={maritalStatus === 'Widowed'}
                  onChange={handleMaritalStatusChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Widowed
              </label>
            </div>
            <p className='bg-slate-200 p-2'>Select the marital status you have on the date you are filing this application. If you are currently unmarried, but had a prior marriage that was annulled or otherwise legally terminated, select "Divorced."</p>

          </div>

          <div className="mb-4 flex ml-2 flex-col mt-10">

            <div className="p-4 rounded text-white bg-blue-700">
              USCIS Online Account Number
              <span className="text-red-500">*</span>
            </div>
            <div className='bg-blue-100 p-3 rounded '>
              <input
                type="number"
                placeholder='#########'
                id="uscisOnlineAccountNumber"
                name="uscisOnlineAccountNumber"
                value={uscisOnlineAccountNumber}
                onChange={(e) => updateUscisOnlineAccountNumber(e.target.value)}
                className='border pl-8 border-gray-300 hover:border-2 focus:border-2 py-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none  rounded transition-all duration-300 ease-in-out hover:shadow-lg '
                style={{ width: 'calc(100% - 73%)' }}

              />
            </div>
            <p className='bg-slate-300 p-2'>If you have a USCIS Online Account Number, enter it here. If you don't have one or you're not sure, leave the field empty. Explain more >>I</p>
          </div>


          <div className="mb-4 flex flex-col mt-10">
            <div className="p-4 rounded text-white bg-blue-700">
              Alien Registration Number (A-Number)

              <span className="text-red-500">*</span>

            </div>
            <div className='flex  items-center p-3 bg-blue-100'>
              <span className='bg-slate-300 p-2 px-3 h-full rounded'>A-</span>
              <input
                type="text"
                placeholder='#########'
                id="aNumber"
                name="aNumber"
                value={aNumber}
                onChange={handlAnumbereChange}
                className='border pl-8 border-gray-300 hover:border-2 focus:border-2 py-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none rounded transition-all duration-300 ease-in-out hover:shadow-lg '
                style={{ width: 'calc(100% - 76%)' }}
              />
            </div>
            <p className='bg-slate-300 p-2'>If you have an A-Number, enter it here. If you don't have an A-Number, you may leave this field empty. How to find this >></p>

          </div>

          <hr className='h-1 bg-blue-700 w-full mt-10' />

          <h1 className='text-blue-700 font-semibold mt-10 text-xl'>Social Security Number</h1>


          <div className="mb-4 flex flex-col ml-2 mt-10">
            <div className="p-4 rounded text-white bg-blue-700">
              Has the Social Security Administration (SSA) ever officially issued a Social Security card to you?
              <span className="text-red-500">*</span>
            </div>

            <div className="flex items-center gap-4 bg-blue-100 p-4">
              <label className="flex items-center gap-2 text-blue-500">
                <input
                  type="radio"
                  id="ssnYes"
                  name="ssnIssued"
                  value="Yes"
                  checked={ssnIssued === 'Yes'}
                  onChange={handleSsnIssuedChange}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                Yes
              </label>
              <label className="flex items-center gap-2 text-blue-500">
                <input
                  type="radio"
                  id="ssnNo"
                  name="ssnIssued"
                  value="No"
                  checked={ssnIssued === 'No'}
                  onChange={handleSsnIssuedChange}
                  className="border border-gray-300 focus:border-2  focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                No
              </label>
            </div>
          </div>

          {ssnIssued === 'Yes' &&

            <>

              {!unknownSsn && <div className="mb-4 flex flex-col ml-2 mt-10">
                <div className="p-4 rounded text-white bg-blue-700">
                  Social Security Number
                  <span className="text-red-500">*</span>
                </div>

                <div className="flex items-center bg-blue-100 p-4">
                  <input
                    type="text"
                    placeholder="#########"
                    id="ssn"
                    name="ssn"
                    value={ssn}
                    onChange={handleSsnChange}
                    className="border pl-8 border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                    style={{ width: 'calc(100% - 75%)' }}
                  />
                </div>
                <p className='p-2 bg-slate-200 '>Enter the 9-digit Social Security number. If you lost the card or otherwise do not know your SSN, you may select "I don't know my Social Security number" below. How to find this >></p>
              </div>
              }





              <div className='flex gap-2 ml-1'>
                <input
                  type="checkbox"
                  id="unknownSsn"
                  name="unknownSsn"
                  checked={unknownSsn}
                  onChange={updateUnknownSsn}
                  className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                />
                I don't know my Social Security number <span className="text-red-500">*</span>
              </div>


              <div className="mb-4 flex flex-col ml-2 mt-10">
                <div className="p-4 rounded text-white bg-blue-700">
                  Do you want the SSA to issue you a replacement Social Security card?
                  <span className="text-red-500">*</span>
                </div>

                <div className="flex items-center gap-4 bg-blue-100 p-4">
                  <label className="flex gap-2 text-blue-500">
                    <input
                      type="radio"
                      id="replaceSsnCardYes"
                      name="replaceSsnCard"
                      value="Yes"
                      checked={replaceSsnCard === 'Yes'}
                      onChange={() => updateReplaceSsnCard('Yes')}
                      className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                    />
                    Yes
                  </label>
                  <label className="flex gap-2 text-blue-500">
                    <input
                      type="radio"
                      id="replaceSsnCardNo"
                      name="replaceSsnCard"
                      value="No"
                      checked={replaceSsnCard === 'No'}
                      onChange={() => updateReplaceSsnCard('No')}
                      className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                    />
                    No
                  </label>
                </div>
                <p className='bg-slate-200 p-2'>If your request for employment authorization is approved, the SSA can issue a replacement Social Security card.</p>
              </div>
            </>
          }



          {(replaceSsnCard === 'Yes') &&
            <>
              <div className="mb-4 flex flex-col ml-2 mt-10">
                <div className="p-4 rounded text-white bg-blue-700">
                  Do you authorize the disclosure of information from this application to the SSA as required for the purpose of assigning you an SSN (if necessary) and issuing you a Social Security card?
                  <span className="text-red-500">*</span>
                </div>

                <div className="flex items-center gap-4 bg-blue-100 p-4">
                  <label className="flex gap-2 text-blue-500">
                    <input
                      type="radio"
                      id="authorizeDisclosureToSSA-Yes"
                      name="authorizeDisclosureToSSA"
                      value="Yes"
                      checked={authorizeDisclosureToSSA === 'Yes'}
                      onChange={() => updateAuthorizeDisclosureToSSA('Yes')}
                      className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                    />
                    Yes
                  </label>
                  <label className="flex gap-2 text-blue-500">
                    <input
                      type="radio"
                      id="authorizeDisclosureToSSA-No"
                      name="authorizeDisclosureToSSA"
                      value="No"
                      checked={authorizeDisclosureToSSA === 'No'}
                      onChange={() => updateAuthorizeDisclosureToSSA('No')}
                      className="border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none p-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg"
                    />
                    No
                  </label>
                </div>
                <p className='bg-slate-200 p-2 rounded'>If you do not consent to the disclosure of your information to SSA, you must go back and answer "No" to the question above
                </p>
              </div>
            </>
          }


          {authorizeDisclosureToSSA === 'Yes' &&
            <div>
              <h1 className='text-blue-700 font-semibold text-xl mt-10'>Father's Birth Name</h1>
              <p className='font-thin mt-2'>Enter your mother's <u>birth</u> name. Applicants who do not know may enter "Unknown" for both names.</p>
              <div className="mb-4 flex ml-2 mt-10">
                <label htmlFor="fathersGivenName" className="text-blue-700">
                  Given Name (First Name):
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="fathersGivenName"
                  name="fathersGivenName"
                  value={fathersGivenName}
                  onChange={(e) => updateFathersGivenName(e.target.value)}
                  className='border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                />
              </div>

              <div className="mb-4 flex ml-2">
                <label htmlFor="fathersFamilyName" className="text-blue-500">
                  Family Name (Last Name):
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="fathersFamilyName"
                  name="fathersFamilyName"
                  value={fathersFamilyName}
                  onChange={(e) => updateFathersFamilyName(e.target.value)}
                  className='border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
                />
              </div>

              <h1 className='text-blue-700 font-semibold text-xl mt-10'>Mother's Birth Name</h1>
              <p className='font-thin mt-2'>Enter your mother's  <u>birth</u> name. Applicants who do not know may enter "Unknown" for both names.</p>
              <div className="mb-4 flex ml-2 mt-10">
                <label htmlFor="mothersGivenName" className="text-blue-700">
                  Given Name (First Name):
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="mothersGivenName"
                  name="mothersGivenName"
                  value={mothersGivenName}
                  onChange={(e) => updateMothersGivenName(e.target.value)}
                  className='border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-10'
                />
              </div>

              <div className="mb-4 flex ml-2">
                <label htmlFor="mothersFamilyName" className="text-blue-500">
                  Family Name (Last Name):
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="mothersFamilyName"
                  name="mothersFamilyName"
                  value={mothersFamilyName}
                  onChange={(e) => updateMothersFamilyName(e.target.value)}
                  className='border border-gray-300 hover:border-2 focus:border-2 hover:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-8'
                />
              </div>
            </div>

          }

          {authorizeDisclosureToSSA === 'No' &&

            <div className='flex gap-2 bg-red-300 p-2'>
              <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" />
              <p>If you do not consent to the disclosure of your information to SSA, you cannot request a Social Security card with this form</p>
            </div>
          }


<div className='flex  text-white mt-24  items center w-[100px] h-[40px]  ml-auto'>
            <div className='bg-green-600 flex items-center  justify-center  py-2 rounded-xl shadow  '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>


    
    </form>



        <FormFotter />
      </div>
    </div>


  )
}

export default ApplicantInformation