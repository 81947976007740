import React, { useState } from "react";
import pic1 from "../Assets/pic1.PNG";
import { BsFillPersonFill } from "react-icons/bs";
import Login from "../Pages/Login";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log('Close button clicked');
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-between items-center mt-8 w-full mx-auto max-w-screen-xl">
      <div>
        <img src={pic1} alt="" className="w-full sm:w-1/2 lg:w-full" />
      </div>
      <div
        className="flex items-center gap-2 text-blue-500 sm:mr-4 cursor-pointer"
        onClick={openModal}
      >
        <BsFillPersonFill className="width-20" />
        <span>Login</span>
      </div>
      {isModalOpen && <Login isOpen={isModalOpen} onClose={closeModal} />}
    </div>
  );
};

export default Header;
