

import React from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useFormContext } from '../../UseContext/ContextFor821D';
import  objectArray  from "../I-1765/Options"
import { useNavigate } from 'react-router-dom';



const ImmigrationHtry821D = () => {

     const navigate =useNavigate()

     const handleSubmit =()=>{
        navigate('/TravelInfo')
     }
    const { formData, handleInputChange ,code } = useFormContext();
    return (
        <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
        <Header />
        <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
    
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              Address Information
              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                  <AiOutlineShoppingCart />
                </div>
              </div>
            </div>
            <span className='mt-2 font-thin ml-2'>
            Provide your current mailing address and contact information.
            </span>
          </div>
          <form action=" " className='mt-14 w-11/12' onSubmit={handleSubmit}>
        


<label className="flex bg-blue-600 w-full rounded p-4 text-white mt-10">
  Country of Citizenship/Nationality
</label>


<div className='flex p-4 bg-blue-100'>
<select
  name="selectedCountry"
  value={formData.selectedCountry}
  onChange={handleInputChange}
  className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
>
  <option value="">Select a Country</option>
  {code.map((countryName) => (
    <option key={countryName} value={countryName}>
      {countryName}
    </option>
  ))}
</select>
</div>
<p className='bg-slate-200  p-2'>Select the name of the country where you are currently a citizen or national. If you are stateless, select the name of the country where you were last a citizen or national. If you are a citizen or national of more than one country, select the name of the foreign country that issued your last passport.</p>


<div>
      <label className="flex bg-blue-600 w-full rounded p-4 text-white mt-10">
        Country of Residence
      </label>
      <div className="bg-blue-100 p-4 w-full">
        <select
          name="selectedCountryOfResidence"
          value={formData.selectedCountryOfResidence}
          onChange={handleInputChange}
          className="border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none px-16 py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        >
          <option value="">Select your Country of Residence</option>
          {code.map((countryName) => (
            <option key={countryName} value={countryName}>
              {countryName}
            </option>
          ))}
        </select>
      </div>
      <p className='bg-slate-200 p-2'>For DACA eligibility, you must be residing in the United States.</p>
    </div>


    <div >
      <label className="flex bg-blue-600 w-full rounded p-4 text-white mt-10">
        Initial Entry into the United States (on or approximately):
        </label>
        <div className='bg-blue-100 p-4'>
        <input
         className="w-[350px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
          type="date"
          name="initialEntryDate"
          value={formData.initialEntryDate}
          onChange={handleInputChange}
        />

        </div>
        <p className='bg-slate-200 p-2'>If you made more than one trip to the U.S., list the first date you came to the U.S. Remember, you must have entered the country and made it your home before turning 16 years of age; AND you must have intially entered the U.S. and made it your home by June 15, 2007</p>
  
    </div>
    
    <div>
    <label className="flex bg-blue-600 w-full rounded p-4 text-white mt-10">
    Status on June 15, 2012:
        </label>
     
        <div className='bg-blue-100 p-4'>
        <select
        className="w-[350px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
          name="statusOnJune152012"
          value={formData.statusOnJune152012}
          onChange={handleInputChange}
        >
          <option value="No Status">No Status</option>
          <option value="Status Expired">Status Expired</option>
          <option value="Payroll Expired">Payroll Expired</option>
        </select>
        </div>
        <p className='bg-slate-200 p-2'>Indicate how you qualify for DACA based on your status on 6/15/2012. Explain More </p>
    
    </div>

    <hr  className='h-1 bg-blue-600 w-full'/>

    <div className='flex flex-col gap-1'>
        <h1 className="text-blue-700 font-semibold text-xl">Arrival/Departure Record</h1>
        <p>If you entered the U.S. lawfully, you will probably have an I-94 record. However, if you entered without inspection, you probably do not have an I-94 record. How to Find Your I-94 Arrival/Departure Record</p>
    </div>



    <div className='flex flex-col mt-10'>
      <label className='bg-blue-700 text-white p-4 rounded'>
        Were you ever issued an Arrival/Departure Record (I-94, I-94W, or I-95)?
      </label>

      <div className=' flex gap-2 bg-blue-100 rounded p-4'>
        <label>
          <input
            type="radio"
            name="issuedI94Record"
            value="Yes"
            checked={formData.issuedI94Record === 'Yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>

        <label>
          <input
           
            type="radio"
            name="issuedI94Record"
            value="No"
            checked={formData.issuedI94Record === 'No'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>
    <p className='bg-slate-200 p-2'>If you do not have an Arrival/Departure Record to your knowledge, answer "No."</p>
  

      <hr  className='bg-blue-600 h-1 w-full mt-10'/>


      <div className='mt-10'>
        <h1 className='text-blue-700 text-xl font-semibold'>
        Most Recent Entry into the United States
        </h1>
        <p>If you traveled outside the United States with Advance Parole, this would be considered your most recent entry. If you did not travel with Advance Parole, your answers will most likely be the same as your initial application. Therefore, make certain the answers you provide in this section are consistent with your previously approved DACA application. Check your previously filed Form I-765.</p>
      </div>


      <div className='flex flex-col mt-10'>
      <label className='bg-blue-700 text-white p-4 rounded'>When did you last enter the United States?</label>
      <div className='bg-blue-100 p-4'>
      <input
        className="w-[350px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        type="date"
        name="lastEntryDate"
        value={formData.lastEntryDate}
        onChange={handleInputChange}
      />
      </div>
        </div>
    <p className='bg-slate-200 p-2'>The date of last entry is the date you last arrived in the U.S. If you do not have an I-94, you may find the date in a passport. If you entered without inspection, enter the most accurate date possible (based on the information you have).</p>

    <div className='flex flex-col mt-10' >
      <label className='bg-blue-700 text-white p-4 rounded'>Where did you last enter the United States?</label>
      <div className='bg-blue-100 p-4'>
      <input
        className="w-[350px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        type="text"
        name="lastEntryLocation"
        value={formData.lastEntryLocation}
        onChange={handleInputChange}
      />
       </div>
        </div>
        <p className='bg-slate-200 p-2'>Enter the name of the city and state that you last entered the U.S. with your parents. If you do not know, "Unknown" is an acceptable answer.</p>
  
    <div className='flex flex-col mt-10' >
      <label className='bg-blue-700 text-white p-4 rounded'>
        What was your immigration status when you last entered the United States?
      </label>
    <div className='bg-blue-100 p-4 w-full'>
      <select
      size="10"
      className="w-[400px] border outline-none  text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        name="lastEntryImmigrationStatus"
        value={formData.lastEntryImmigrationStatus}
        onChange={handleInputChange}
      >
        <option value="" className=''>Please select...</option>
        {objectArray.map((option) => (
          <option key={option.code} value={option.value}>
          {option.code}  - {option.value}
          </option>
        ))}
      </select>
      </div>
    </div>
    <p className='bg-slate-200 p-2'>Select the visa category you held when you last entered the U.S. The visa category will be listed on your visa which is located inside your passport. If you do not remember your visa category, please select "Other," and enter the written word for a broad category such as Visitor or Student. If you did not have an immigration status when you last entered, please select "No Status." (Many DACA applicants will use "No Status.")</p>

    {formData.lastEntryImmigrationStatus === "Other" &&
      
      <div className="flex flex-col mt-10">
      <label className="bg-blue-700 text-white p-4 rounded">Indicate your immigration status when you last entered the United States:</label>
      
       <div className='bg-blue-100 p-4'>
      <input
         className="w-[400px] border outline-none  text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        type="text"
        name="lastEntryImmigrationStatus" // Use the appropriate name
        value={formData.lastEntryImmigrationStatusforother}
        onChange={handleInputChange}
      />
      </div>
    </div>

   
    }

<div className="flex flex-col mt-10">
      <label className="bg-blue-700 text-white p-4 rounded">Alien Registration Number (A-Number):</label>
      <div className='bg-blue-100 p-4'>
      <input
         className="w-[400px] border outline-none  text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg ml-10"
        type="number"
        value={formData.alienRegistrationNumber}
        onChange={handleInputChange}
      />
    </div>
    </div>

    <div className="flex flex-col mt-10">
      <label className="bg-blue-700 text-white p-4 rounded">USCIS Online Account Number (if any):</label>
      <div  className='bg-blue-100 p-4 flex items-center '>
     <span className='bg-slate-200  flex items-center justify-center text-xl w-[40px] h-[40px] '>A-</span>
      <input
        className="w-[300px] border outline-none  text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg"
        type="text"
        value={formData.uscisOnlineAccountNumber}
        onChange={handleInputChange}
      />
    </div>
    </div>
    <p className='bg-slate-200'>If you have an A-Number, enter it here. When entering an 8-digit A-Number, please enter a 0 first followed by your 8 digit number. If you do not have an A-Number, leave this field empty. How to find this </p>
           

    <div className="flex flex-col mt-10">
      <label  className="bg-blue-700 text-white p-4 rounded">USCIS Online Account Number (if any):</label>
    <div className='bg-blue-100  p-4'>
      <input
        
        className="w-[300px] border outline-none  text-slate-700 border-gray-300 hover:border-2 hover:border-indigo-600 focus-border-2 focus-border-indigo-600 focus-border-3 focus-shadow-lg focus-outline-none  py-2 rounded transition-all duration-300 ease-in-out hover-shadow-lg"
        type="text"
        value={formData.uscisOnlineAccountNumber}
        onChange={handleInputChange}
      />
    </div>
    </div>
    <div className='flex  text-white mt-32 w-[100px] ml-auto'>
            <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
              <button
                type="submit"
                className='text-white   rounded'
             
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
    
         
        </form>
        <FormFotter />
    
          </div>
          </div>
      )
}

export default ImmigrationHtry821D
