



import React from 'react'

import { AiOutlineShoppingCart } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import Header from '../../components/Header';
import FormFotter from '../../components/FormFotter';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from '../../UseContext/Context134';
  
  const PrepareAndInterpreter = () => {
    const navigate=useNavigate()
    const { formData, handleInputChange ,code ,territories , nameSets ,handleAddAnother ,handleRemove ,handleChange, } = useFormContext();

    const HandleSubmit =(e)=>{
         e.preventDefault()
          navigate("/financialsupport")
    }

    console.log(formData.selectedTerritory)
 return (
   <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
   <Header />
   <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl shadow'>
          <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>
            <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
            <span className='font-bold text-white bg-blue-600 text-xl'>Application For Employment Authorization</span>
            <div className='h-full'>
              <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
              <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
            </div>
          </div>
  
          <div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
            <div className='flex flex-col-reverse justify-between items-center lg:flex-row md:flex-col'>
              <span className='mt-4 font-bold text-xl ml-2 text-black'>
              First, let’s determine if you are eligible to file Form N-565.

              </span>
              <div className='flex justify-between items-center gap-4'>
                <div className='flex bg-blue-700 text-white rounded py-1 px-2'>
                  <button className='mr-2'>Page 1 of 3</button>
                  <button><MdArrowDropDown /></button>
                </div>
                { ( formData.documentType === 'Certificate of Citizenship' || formData.documentType === 'Other type of certificate or declaration') ? null : (
                    <div className='bg-green-600 px-2 py-2 rounded-md text-white'>
                    <AiOutlineShoppingCart />
                    </div>
                )}
              </div>
            </div>
            <span className='mt-2 font-thin ml-2 w-5/6'  >
            On this page, CitizenPath will ask you several questions to help make sure that you meet the requirements to the N-565 application. Generally, U.S. citizens use Form N-565 (Application for Replacement Naturalization/Citizenship Document) to replace a Certificate of Naturalization or Certificate of Citizenship.
            </span>
          </div>
     <form action=" " className='mt-8 w-11/12' onSubmit={HandleSubmit}>


    <div className=''>
        <h1 className='text-blue-600 font-semibold text-xl'>Beneficiary's Income & Dependents</h1>
        <p className='text-sm'>Create a record for each of the beneficiary's dependents. Dependents generally include the beneficiary's spouse, children, and any other individuals the beneficiary financially supports. Do not list the supporter or any persons in the supporter's household.</p>
    </div>

 { !formData.noIncome
 &&
 <div className="mt-6 flex gap-10">
  <label htmlFor="expectedIncome" className="text-blue-700 " >
    What is the beneficiary's <br /> expected income for  <br />the current year?
  </label>
 <div>
  <input
    type="number"
    id="expectedIncome"
    name="expectedIncome"
    value={formData.expectedIncome}
    onChange={handleInputChange}
    className=" w-[300px] border border-gray-300 hover:border-indigo-600 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded mt-2"
  />
  </div>
</div>}

<div className="mt-4">
  <input
    type="checkbox"
    id="noIncome"
    name="noIncome"
    checked={formData.noIncome}
    onChange={handleInputChange}
  />
  <label htmlFor="noIncome" className="ml-2 text-blue-700">
    This person has no income
  </label>
</div>  


<div className="flex gap-10 mt-10">
  <label className="text-blue-700 mr-2">Does the beneficiary have <br />any dependents?</label>
  <div className="flex items-center">
    <input
      type="radio"
      id="dependentsYes"
      name="hasDependents"
      value="Yes"
      checked={formData.hasDependents === 'Yes'}
      onChange={handleInputChange}
    />
    <label htmlFor="dependentsYes" className="ml-2 text-blue-600">Yes</label>

    <input
      type="radio"
      id="dependentsNo"
      name="hasDependents"
      value="No"
      checked={formData.hasDependents === 'No'}
      onChange={handleInputChange}
      className="ml-4"
    />
    <label htmlFor="dependentsNo" className="ml-2 text-blue-600">No</label>
  </div>
</div>

{formData.hasDependents === 'Yes'
    &&
    <>
    <div className="mt-20">

  {nameSets.map((nameSet, index) => (
    <div key={nameSet.id} className=' bg-slate-50 rounded border border-blue-200'>
      {index > 0 && (
        <div className='flex w-full justify-between ml-2'>
          <span className="text-gray-500">{`Add Other ${index}`}</span>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4"
          >
            Remove
          </button>
        </div>
      )}

      {/* Radio Buttons for Relationship */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label className="text-blue-700">Relationship to the beneficiary: </label>
        <div className="ml-4">
          <input
            type="radio"
            id={`relationship-spouse-${nameSet.id}`}
            name={`relationship-${nameSet.id}`}
            value="Spouse"
            checked={formData[`relationship-${nameSet.id}`] === 'Spouse'}
            className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4'
            onChange={(e) => handleInputChange(e, nameSet.id, `relationship-${nameSet.id}`)}
          />
          <label htmlFor={`relationship-spouse-${nameSet.id}`} className="ml-2 text-blue-600">Spouse</label>
        </div>
        <div className="ml-4">
          <input
            type="radio"
            id={`relationship-child-${nameSet.id}`}
            name={`relationship-${nameSet.id}`}
            value="Child"
            checked={formData[`relationship-${nameSet.id}`] === 'Child'}
            className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4'
            onChange={(e) => handleInputChange(e, nameSet.id, `relationship-${nameSet.id}`)}
          />
          <label htmlFor={`relationship-child-${nameSet.id}`} className="ml-2 text-blue-600">Child</label>
        </div>
        <div className="ml-4">
          <input
            type="radio"
            id={`relationship-dependent-${nameSet.id}`}
            name={`relationship-${nameSet.id}`}
            value="Other dependent"
            checked={formData[`relationship-${nameSet.id}`] === 'Other dependent'}
            className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-4'
            onChange={(e) => handleInputChange(e, nameSet.id, `relationship-${nameSet.id}`)}
          />
          <label htmlFor={`relationship-dependent-${nameSet.id}`} className="ml-2 text-blue-600">Other dependent</label>
        </div>
      </div>

      {/* Input Field for Full Name */}
      <div className="mb-4 flex mt-6 ml-2 items-center ">
        <label htmlFor={`fullName-${nameSet.id}`} className="text-blue-700 w-[200px]">
          Person's full name: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id={`fullName-${nameSet.id}`}
          name={`fullName-${nameSet.id}`}
          className='w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2'
        />
      </div>

      {/* Input Field for Date of Birth */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor={`dob-${nameSet.id}`} className="text-blue-700 w-[200px]">
          Date of Birth: <span className="text-red-500">*</span>
        </label>
        <input
          type="date"
          id={`dob-${nameSet.id}`}
          name={`dob-${nameSet.id}`}
          className='w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2'
        />
      </div>

      {/* Input Field for Income Contribution */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor={`incomeContribution-${nameSet.id}`} className="text-blue-700 w-[200px]"   style={{ display: formData[`noIncome-${nameSet.id}`] ? 'none' : 'block' }}>
          Income contribution to the household:
        </label>
        <input
          type="text"
          id={`incomeContribution-${nameSet.id}`}
          style={{ display: formData[`noIncome-${nameSet.id}`] ? 'none' : 'block' }}
          name={`incomeContribution-${nameSet.id}`}
          className='w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2'
        />
      </div>

      {/* Checkbox for No Income */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <input
          type="checkbox"
          id={`noIncome-${nameSet.id}`}
          name={`noIncome-${nameSet.id}`}
          checked={formData[`noIncome-${nameSet.id}`]}
          onChange={(e) => handleInputChange(e, nameSet.id, `noIncome-${nameSet.id}`)}
        />
        <label htmlFor={`noIncome-${nameSet.id}`} className="ml-2 text-blue-600">
          This person has no income
        </label>
      </div>

      {/* Additional Input Fields */}
      {/* ... (rest of the code remains the same) */}
    </div>
  ))}

  {nameSets.length === 0 && (
    <div>
      <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
      <span className="text-gray-500">Add Other</span>
    </div>
  )}

  <button
    type="button"
    onClick={handleAddAnother}
    className="bg-green-700 text-white px-4 py-2 rounded mt-2 flex ml-auto border border-black"
  >
    Add Another Name
  </button>
</div>

<div className='mt-10 rounded'>

<div className='bg-blue-600 text-white p-4 flex rounded '>
    <h1>Income Analysis</h1>
</div>
<div className='bg-[#f2f2f2] p-6 flex items-center justify-center'>
    <h1 className='text-slate-500 text-2xl' style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>Based on the information you've provided, your household size is  <span className='text-3xl bg-blue-600 p-2 text-white rounded-full font-[700] '>2</span> persons.</h1>
</div>
<div className='bg-[#e6e6e6] p-6 flex items-center justify-center'>
    <h1 className='text-slate-500 text-2xl' style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>For a household this size the minimum income requirement is  <span className='text-3xl p-2 text-blue-600  font-[800] '>$19,720 </span> </h1>
</div>
<div className='bg-[#d9d9d9] p-6 flex items-center justify-center'>
    <h1 className='text-slate-500 text-2xl' style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>Based on the information you've provided, your household income is   <span className='text-3xl p-2 text-green-600  font-[800] '>$22,323,123.</span> </h1>
</div>



</div>
</>




}


{formData.hasDependents === 'No'

  &&
  <div className='mt-10 rounded'>

    <div className='bg-blue-600 text-white p-4 flex rounded '>
        <h1>Income Analysis</h1>
    </div>
    <div className='bg-[#f2f2f2] p-6 flex items-center justify-center'>
        <h1 className='text-slate-500 text-2xl' style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>Based on the information you've provided, your household size is  <span className='text-3xl bg-blue-600 p-2 text-white rounded-full font-[700] '>1</span> persons.</h1>
    </div>
    <div className='bg-[#e6e6e6] p-6 flex items-center justify-center'>
        <h1 className='text-slate-500 text-2xl' style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>For a household this size the minimum income requirement is  <span className='text-3xl p-2 text-blue-600  font-[800] '> $14,580 .</span> </h1>
    </div>
    <div className='bg-[#d9d9d9] p-6 flex items-center justify-center'>
        <h1 className='text-slate-500 text-2xl' style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"' }}>Based on the information you've provided, your household income is   <span className='text-3xl p-2 text-red-600  font-[800] '>$0.</span> </h1>
    </div>



</div>}




<div className=' bg-red-300 flex gap-2  p-2 rounded flex-col'>

     <div className='flex gap-2 '>
    <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt=""  className='w-[30px] h-[30px]'/>
      <div className=''>
    <p>We found a problem. Based on your income, you may not continue. Your income does not meet the minimum income requirement to support your household size. Based on your income alone, it is likely that USCIS will not consider this declaration adequate. This could lead to the denial of your requested visa. (For a complete listing of minimum income requirements by household size, visit the HHS Poverty Guidelines.) You may have a couple of options:</p>
    <p className='mt-10'>1. Ask a friend or family member in the United States to support you. This is the most straight forward path to facilitate the beneficiary's entry. This person will prepare Form I-134 instead of you.
       <br />
       2. If you have significant assets (including bank accounts, personal property or real estate), an attorney can evaluate your specific situation and help you qualify by using the assets in place of income.</p>
       </div>
       </div>
      <div className='flex gap-2 mt-10'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" />
        <p className='text-blue-500'>Help me find an immigration attorney</p>
      </div>
</div>

<div className='bg-green-300 p-2 rounded flex gap-2 mt-10'>
  <img src="https://forms.citizenpath.com/assets/img/Icon_Right_30.png" alt=""  className='w-[30px] h-[30px]'/>
  <p> <span className='font-bold'>Good News! Your household income exceeds the poverty guidelines. This is a positive factor and is sufficient to file Form I-134 on your own behalf. For a complete listing of minimum income requirements by household size, visit the HHS Poverty Guidelines. </span></p>
</div>

<hr  className='bg-blue-600 h-1 mt-10'/>

<div className='mt-10'>
      <label className='flex bg-blue-600 text-white rounded p-4'>Does any portion of the income listed above come from an illegal activity or source?</label>
    <div className='bg-blue-100 p-4 rounded  gap-4 flex'>
      <div>
      <input
        type="radio"
        id="incomeSourceYes"
        name="incomeSource"
        value="Yes"
        checked={formData.incomeSource === 'Yes'}
        onChange={handleInputChange}
      />
      <label htmlFor="incomeSourceYes" className='text-blue-600'>Yes</label>
      </div>
      <div>
      <input
        type="radio"
        id="incomeSourceNo"
        name="incomeSource"
        value="No"
        checked={formData.incomeSource === 'No'}
        onChange={handleInputChange}
      />
      <label htmlFor="incomeSourceNo" className='text-blue-600'>No</label>
      </div>
      </div>
      <p className='bg-slate-200 p-2'>Indicate if the beneficiary or any member of the beneficiary's household earns income from illegal gambling, drug sales, or any other illegal activity.</p>
</div>


{formData.incomeSource === 'Yes'
  &&
  <div className='flex flex-col bg-red-200 p-2 gap-4'>
      <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Wrong_30.png" alt="" className='w-[30px] h-[30px]'/>
        <p>
       <span className='font-bold'> We found a problem. Based on your answer, you cannot continue.</span> Although you may be eligible to prepare this form, our service does not conform to your requirements at this time. Please feel free to contact our customer support team for more information or find seek an immigration for assistance.
        </p>
      </div>
      <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" />
        <p className='text-blue-600'>Contact Citizenship support team</p>
      </div>
      <div className='flex gap-2'>
        <img src="https://forms.citizenpath.com/assets/img/Icon_Search_30.png" alt="" />
        <p className='text-blue-600'>Help me find an immigration attorney.</p>
      </div>
    </div>}



<hr  className='bg-blue-600 mt-10 h-1' />

<div>
  <h1 className='text-blue-600 font-semibold text-xl'>Beneficiary's Household Assets</h1>
  <p className='font-sm'>You may list your assets or another household member's assets. Listing assets can help strengthen the declaration. If the beneficiary does not have much income, listing assets with value can help prove the beneficiary has adequate resources to pay expenses during a temporary stay. Assets may include cash accounts, stocks and bonds, personal property, real estate and even life insurance. What You Should Know About Assets
</p>
</div>


<div className='mt-10'>
      {/* Your existing form components */}
      
      {/* New field for listing beneficiary assets */}
      <label className='bg-blue-600 text-white rounded p-4 flex'>Will you be listing any beneficiary assets?</label>
       <div className='bg-blue-100 p-4 rounded  flex gap-5'>
      <div className=''>
        <input
          type="radio"
          id="yes"
          name="willListBeneficiaryAssets"
          value="Yes"
          checked={formData.willListBeneficiaryAssets === 'Yes'}
          onChange={handleInputChange}
        />
        <label htmlFor="yes" className='text-blue-600'>Yes</label>
      </div>
      <div>
        <input
          type="radio"
          id="no"
          name="willListBeneficiaryAssets"
          value="No"
          checked={formData.willListBeneficiaryAssets === 'No'}
          onChange={handleInputChange}
        />
        <label htmlFor="no" className='text-blue-600'>No</label>
      </div>
      </div>
      <p className='bg-slate-200 p-2'>Indicate if you want to list any assets to show the beneficiary has fianancial resources to pay expenses during a tempoary stay in the U.S. Assets can include financial accounts, personal property, life insurance and real estate. Listing assets is not required.</p>
    </div>

    


    {formData.willListBeneficiaryAssets === 'Yes'
      &&
      
      <div className="mt-20">
  {nameSets.map((nameSet, index) => (
    <div key={nameSet.id} className=' bg-slate-50 rounded border border-blue-200'>
      {index > 0 && (
        <div className='flex w-full justify-between ml-2'>
          <span className="text-gray-500">{`Add Other ${index}`}</span>
          <button
            type="button"
            onClick={() => handleRemove(nameSet.id)}
            className="bg-red-500 text-white px-4 py-2 rounded mt-2 mr-4"
          >
            Remove
          </button>
        </div>
      )}

      {/* Dropdown for Type of Asset */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor={`assetType-${nameSet.id}`} className="text-blue-700">
          Type of Asset: <span className="text-red-500" >*</span>
        </label>
        <select
          id={`assetType-${nameSet.id}`}
          name={`assetType-${nameSet.id}`}
          className='border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-12'
          onChange={(e) => handleInputChange(e, nameSet.id, `assetType-${nameSet.id}`)}
        >
          <option value="">Please Select</option>
          <option value="Checking Account">Checking Account</option>
          <option value="Savings Account">Savings Account</option>
          <option value="Savings Account">Annuities</option>
          <option value="Savings Account">Stock ,Bonds ,Certificate of desposits</option>
          <option value="Savings Account">Retirement  or educational Acoounts</option>
          <option value="Savings Account">Real Estate Holding(net values)</option>
          <option value="Savings Account">Personal Property (Net values)</option>
          {/* Add more options as needed */}
        </select>
      </div>

      {/* Input Field for Full Name of Asset Holder */}
      <div className="mb-4 flex mt-6 ml-2 items-center ">
        <label htmlFor={`assetHolderName-${nameSet.id}`} className="text-blue-700 w-[200px]">
          Full Name of Asset Holder: <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          id={`assetHolderName-${nameSet.id}`}
          name={`assetHolderName-${nameSet.id}`}
          className='w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2'
        />
      </div>

      {/* Input Field for Amount (cash value) */}
      <div className="mb-4 flex mt-6 ml-2 items-center">
        <label htmlFor={`assetAmount-${nameSet.id}`} className="text-blue-700 w-[200px]">
          Amount (cash value): <span className="text-red-500">*</span>
        </label>
        <input
          type="number"
          id={`assetAmount-${nameSet.id}`}
          name={`assetAmount-${nameSet.id}`}
          className='w-[300px] border border-gray-300 hover:border-2 hover:border-indigo-600 focus:border-2 focus:border-indigo-600 focus:border-3 focus:shadow-lg focus:outline-none px-4 py-2 rounded transition-all duration-300 ease-in-out hover:shadow-lg ml-2'
        />
      </div>

      {/* Additional Input Fields */}
      {/* ... (rest of the code remains the same) */}
    </div>
  ))}

  {nameSets.length === 0 && (
    <div>
      <hr className="flex-1 border-t-2 border-dotted border-gray-500 mb-4" />
      <span className="text-gray-500">Add Other</span>
    </div>
  )}
</div>

 }
            


             
     
  





                <div className='flex  text-white mt-32  w-[72px]  ml-auto'>
                        <div className='bg-green-600 flex items-center px-2 py-2 rounded-xl shadow '>
                            <button
                            type="submit"
                            className='text-white   rounded'
                            
                            >
                            Next
                            </button>
                            <BsArrowRight />
                        </div>
                        </div>
                        <div className='flex justify-end mt-2'>
                        <span>or <span className='text-blue-500'>Save Your progress?</span></span>
                        </div>



    
   </form>
   <FormFotter />

</div>
</div>

  )
  }
  
  export default PrepareAndInterpreter