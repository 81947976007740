import React, { useState } from 'react';
import Header from '../components/Header';
import { MdArrowDropDown } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import tikpik from '../Assets/tikpic.png';
import crosspic from '../Assets/crosspic.png';
import FormFotter from '../components/FormFotter';
import { useFormContext } from '../UseContext/UseContext';
import { useNavigate } from 'react-router-dom';

const PageOneForm = () => {
  const navigate = useNavigate();
  const { state, setFormState } = useFormContext();
  const [formValid, setFormValid] = useState(true);

  const checkFormValidity = () => {
    // Check if any condition that makes the form invalid
    if (
      (state.FormOne.Ischecked && state.FormOne.IsnoChecked) ||
      (state.FormOne.Istwochecked && state.FormOne.IsnotwoChecked)
    ) {
      setFormValid(false); // Set form validity to false
    } else {
      setFormValid(true); // Set form validity to true if all conditions are met
    }
  };

  const handleQuestionnumberOneYesChange = (event) => {
    // Update the state when the input value changes
    setFormState('FormOne', 'Ischecked', event.target.checked);
  };

  const handleQuestionnumberOneNoChange = (event) => {
    // Update the state when the input value changes
    setFormState('FormOne', 'IsnoChecked', event.target.checked);
  };

  const handleQuestionnumbertwoYesChange = (event) => {
    // Update the state when the input value changes
    setFormState('FormOne', 'Istwochecked', event.target.checked);
  };

  const handleQuestiontwonoChange = (event) => {
    // Update the state when the input value changes
    setFormState('FormOne', 'IsnotwoChecked', event.target.checked);
  };

  const logFormData = () => {
    console.log('Form Data:', state);
  };
const handleFormSubmit = (e) => {
  e.preventDefault();
  checkFormValidity();

  // Check if the form is valid and at least one radio button is selected
  if (formValid && (state.FormOne.Ischecked || state.FormOne.IsnoChecked || state.FormOne.Istwochecked || state.FormOne.IsnotwoChecked)) {
    logFormData();
    navigate('/pageoneformtwo');
  } else {
    // Update the formValid state to false
    setFormValid(false);
  }
};

  

  return (
    <div className='w-full sm:w-2/3 md:w-3/4 lg:w-4/5 xl:w-full mx-auto'>
 

 

      <Header />
      <div className='flex flex-col justify-between items-center mt-4 w-full mx-auto max-w-screen-xl  shadow   '>
      <div className='flex justify-between w-full items-center bg-blue-500 h-12 rounded-t-lg'>

          <span className='ml-2 bg-white rounded-full px-2 text-green-300 font-thin'>verified and security</span>
          <span className='font-bold text-white bg-blue-600 text-xl'>USCIS Form AR-11 - Alien's Change of Address Card</span>
          <div className='h-full'>
            <button className='text-white px-2 bg-blue-900 h-full border border-black'>Save</button>
            <button className='text-white px-2 bg-blue-900 h-full'>Help</button>
          </div>
        </div>




<div className='flex flex-col border w-full pb-10 bg-slate-100 sm:flex-col lg:flex-col'>
  <div className='flex flex-col-reverse justify-between items-center lg:flex-row  md:'>
    <span className='mt-4 font-bold text-xl ml-2 text-black '>
      First, let's determine your eligibility to prepare Form AR-11, Alien's Change of Address
    </span>
    <div className='flex justify-center bg-blue-700 mr-2 item-center text-white rounded py-1 px-2 sm:mt-4'>
      <button className='mr-2'>Page 1 of 3</button>
      <button><MdArrowDropDown /></button>
    </div>
  </div>
  <span className='mt-2 font-thin ml-2'>Complete the information below to prepare a free Form AR-11. Use the AR-11 to notify USCIS of an address change.</span>
</div>

        <form action=" " className='mt-14 w-11/12  ' onSubmit={handleFormSubmit}>
              <div className={`p-4 rounded text-white ${formValid ? 'bg-blue-700' : 'bg-red-500'}`}>
                  Are you currently in the United States with an A visa or G visa?
                  <span className="text-red-500">*</span>
                </div>
                <div className={`flex gap-10 ${formValid ? 'bg-blue-200' : 'bg-red-200'} p-5`}>
                  <div className='flex gap-2'>
                    <input type="radio" id="yes" name="answer" value="yes" checked={state.FormOne.Ischecked} onChange={handleQuestionnumberOneYesChange} />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className='flex gap-2'>
                    <input type="radio" id="no" name="answer" value="no" checked={state.FormOne.IsnoChecked} onChange={handleQuestionnumberOneNoChange} />
                    <label htmlFor="no">No</label>
                  </div>
                </div>

          <div className='bg-slate-300'>
            <p className='ml-2'>A and G type visa holders are exempt from this requirement and do not have to submit Form AR-11.</p>
          </div>
          {state.FormOne.Ischecked ? (
            <div className={`bg-slate-200 ${formValid ? 'bg-blue-200' : 'bg-red-200'} flex`}>
              <span>
                <img src={crosspic} alt="" />
              </span>
              <p className='ml-2'><span className='font-bold'>Sorry. Based on your answer, you cannot continue.</span> "A" and "G" type visa holders are exempt from this requirement and do not have to submit Form AR-11, Alien’s Change of Address.</p>
            </div>) : ("")}
          {state.FormOne.IsnoChecked ? (
            <>
              <div className={`p-4 bg-blue-500 rounded mt-10 ${formValid ? 'bg-blue-200' : 'bg-red-500'}`}>
                <span className='text-white'>Have you been in the United States for less than 30 days through the Visa Waiver Program (ESTA)?
                <span className="text-red-500">*</span></span>
              </div>
              <div className={`flex gap-10 ${formValid ? 'bg-blue-200' : 'bg-red-100'} p-5`}>
                <div className='flex gap-2'>
                  <input type="radio" id="question2yes" name="question2" value="yes" checked={state.FormOne.Istwochecked} onChange={handleQuestionnumbertwoYesChange} />
                  <label htmlFor="question2yes">Yes</label>
                </div>
                <div className='flex gap-2'>
                  <input type="radio" id="question2no" name="question2" value="no" checked={state.FormOne.IsnotwoChecked} onChange={handleQuestiontwonoChange} />
                  <label htmlFor="question2no">No</label>
                </div>
              </div>
              <div className='bg-slate-300'>
                <p className='ml-2'>Tourists and other visitors using the visa waiver program for trips of fewer than 30 days do not need to file Form AR-11</p>
              </div>
              {state.FormOne.Istwochecked ? (
                <div className={`bg-red-200 ${formValid ? 'bg-blue-200' : 'bg-red-200'} flex`}>
                  <span>
                    <img src={crosspic} alt="" />
                  </span>
                  <p className='ml-2'><span className='font-bold'>Sorry. Based on your answer, you cannot continue.</span> "A" and "G" type visa holders are exempt from this requirement and do not have to submit Form AR-11, Alien’s Change of Address.</p>
                </div>) : ("")}
              {state.FormOne.IsnotwoChecked ? (
                <div className={`bg-green-200 ${formValid ? 'bg-blue-200' : 'bg-red-200'} rounded flex  mt-16`}>
                  <img src={tikpik} alt="" className='px-2 py-2  ' />
                  <p className='mt-4 p-2 rounded '>
                    <span className='font-bold'>Congratulations, you are likely eligible to file AR-11 Alien's Change of Address.</span>
                    <br /><span className='mt-4'>Please click "Next" to continue preparing this form.</span>
                  </p>
                </div>
              ) : ("")}
            </>
          ) : ("")}
          <div className={'flex justify-end text-white mt-32  w-[100px] ml-auto'}>
            <div className={`bg-green-600 flex items-center px-5 py-2 rounded-xl shadow ${formValid ? 'bg-blue-200' : 'bg-red-200'}`}>
              <button
                type="submit"
                className='text-white px-1 py-1 rounded'
                disabled={!formValid}
              >
                Next
              </button>
              <BsArrowRight />
            </div>
          </div>
          <div className='flex justify-end mt-2'>
          <span>or <span className='text-blue-500'>Save Your progress?</span></span>
          </div>
        </form>
        <FormFotter />
      </div>
    </div>
  );
};

export default PageOneForm;
