
import React from 'react'

const Footer = () => {
  return (
    <div className='flex flex-col justify-between items-center w-full mx-auto max-w-screen-xl px-6 text-sm mt-20 text-[#999] mb-8 text-center'>
    <p>© Copyright 2013-2023, CitizenPath, LLC. All rights reserved. Version 2.11.2</p>
    <p>CitizenPath is a private company that provides self-directed immigration services at your direction. We are not affiliated with USCIS or any government agency. The information provided in this</p>
    <p>site is not legal advice, but general information on issues commonly encountered in immigration. CitizenPath is not a law firm and is not a substitute for an attorney or law firm. Your access to</p>
    <p>and use of this site is subject to additional Terms of Use.</p>
</div>
  )
}

export default Footer
